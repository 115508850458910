import React, { useState, useCallback, useEffect, useMemo } from "react";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import { getTodayDateText, dateTimeToString } from "common/utils";
import { AUTH_TYPES } from "common/constants";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import AdminDetailModal from "./AdminDetailModal";
import AdminRegistModal from "./AdminRegistModal";
import { periodItems } from "common/constants";
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LADataTable,
  getTextCell,
  getNormalButton,
  useRadioGroup,
  LATabSelector,
  LADatePicker,
  LACodeRadioGroup,
  useTabSelector,
  useDatePicker,
  useInput,
  useComboBox,
  useModal,
} from "modules/Widgets";
import usePasswordReset from "common/hooks/usePasswordReset";

function AdminList() {
  const todayDateText = getTodayDateText("-");
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const authType = useRadioGroup(AUTH_TYPES.unknown);
  const periodType = useComboBox("2");
  const periodTab = useTabSelector(3);
  const startDate = useDatePicker(todayDateText);
  const endDate = useDatePicker(todayDateText);
  const searchType = useComboBox("1");
  const searchInput = useInput("");

  const { showAlert, showConfirm } = usePopAlert();
  const passwordReset = usePasswordReset();

  const [selectedMemberSeq, setSelectedMemberSeq] = useState(0);
  const adminDetailModal = useModal(false);
  const adminRegistModal = useModal(false);

  const startDateText = useMemo(() => {
    return startDate.state.replaceAll("-", "");
  }, [startDate.state]);
  const endDateText = useMemo(() => {
    return endDate.state.replaceAll("-", "");
  }, [endDate.state]);
  const [rows, setRows] = useState([]);

  const getDateTimeText = (value) => {
    return new Date(value.replaceAll("Z", ""));
  };

  const [tablePage, setTablePage] = useState(0);
  const [lastVisitTablePage, setLastVisitTablePage] = useState(0);

  const dataToRow = (data, index) => {
    return {
      no: getTextCell(`${data.member_seq}`),
      login_id: getTextCell(`${data.login_id ? data.login_id : ""}`),
      user_name: getTextCell(
        `${data.member_name ? data.member_name : ""}`,
        `${data.member_seq}`,
        onAdminDetailClick
      ),
      user_level: getTextCell(
        // `${data.auth_type ? AuthTypeInfo[data.auth_type].text : ''}`,
        data.auth_type_name
      ),
      last_visit: getTextCell(
        `${
          data.signin_dt
            ? dateTimeToString(getDateTimeText(data.signin_dt))
            : ""
        }`
      ),
      login_ip: getTextCell(
        `${data.remote_address ? data.remote_address : ""}`
      ),
      reg_date: getTextCell(
        `${
          data.created_dt
            ? dateTimeToString(getDateTimeText(data.created_dt))
            : ""
        }`
      ),
      detail: getNormalButton("상세", `${data.member_seq}`, onAdminDetailClick),
      setting: getNormalButton("비밀번호 초기화", data, onPasswordReset),
    };
  };

  const { request: requestAccounts } = useCommonQuery({
    query: queries.Account.accounts,
    params: {
      auth_type: authType.state > 0 ? authType.state : undefined,
      period_type: periodType.state,
      start_dt: startDateText,
      end_dt: endDateText,
      query_type: searchType.state,
      query: searchInput.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setRows(
            data.result_data
              ? data.result_data.map((resultData, index) =>
                  dataToRow(resultData, index)
                )
              : []
          );
        } else showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "관리자 목록 조회에 실패했습니다.");
      },
    },
    initEnabled: false,
  });

  function onAdminDetailClick(no) {
    setSelectedMemberSeq(no);
    adminDetailModal.visible(true);
  }

  function onPasswordReset(data) {
    passwordReset(data.login_id, data.member_seq);
  }

  function onSearchClick() {
    setLastVisitTablePage(0);
    requestAccounts();
  }

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  function onRegistClick() {
    adminRegistModal.visible(true);
  }

  const onRegistClose = () => {
    requestAccounts();
    adminRegistModal.handleClose();
  };

  const onChangeTablePage = useCallback((page) => {
    setLastVisitTablePage(page);
  });

  useEffect(() => {
    startDate.selectByPeriod(periodTab.state);
    endDate.reset(periodTab.state);
  }, [periodTab.state, endDate.reset]);

  useEffect(() => {
    if (startDate.state != endDate.state) requestAccounts();
  }, [startDate.state]);

  useEffect(() => {
    if (lastVisitTablePage != 0) {
      setTablePage(lastVisitTablePage);
    }
  }, [rows]);

  function onInitializeClick() {
    authType.reset();
    periodType.reset();
    periodTab.reset();
    startDate.reset();
    endDate.reset();
    searchType.reset();
    searchInput.reset();
  }

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "10%" },
      { Header: "아이디", accessor: "login_id", width: "10%" },
      { Header: "이름", accessor: "user_name", width: "10%" },
      { Header: "권한", accessor: "user_level", width: "15%" },
      { Header: "최근 방문일시", accessor: "last_visit", width: "15%" },
      { Header: "로그인 IP", accessor: "login_ip", width: "10%" },
      { Header: "가입일", accessor: "reg_date", width: "10%" },
      { Header: "상세", accessor: "detail", width: "10%" },
      { Header: "관리", accessor: "setting", width: "10%" },
    ];
  }, []);

  return (
    <>
      <DashboardNavbar title="관리자 관리" />

      <LACard>
        <LAProperty name="권한">
          <LACodeRadioGroup
            codeType="ADMIN_TYPE"
            showAll={true}
            value={authType.state}
            onChange={authType.handleChange}
          />
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: 1, value: "최근 방문일" },
                { key: 2, value: "가입일" },
              ]}
              value={periodType.state}
              onChange={periodType.handleChange}
            />
            <LATabSelector
              wPercent={45}
              items={periodItems}
              layouts={{ pl: 2 }}
              value={periodTab.state}
              onChange={periodTab.handleChange}
            />
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2, mr: 2 }}
              value={startDate.state}
              onChange={startDate.handleChange}
            />
            ~
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2 }}
              value={endDate.state}
              onChange={endDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={[
                { key: 1, value: "통합검색" },
                { key: 2, value: "이름" },
              ]}
              value={searchType.state}
              onChange={searchType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: { sm: 1 } }}
              value={searchInput.state}
              onChange={searchInput.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 1 } }}>
          <LALabel wPercent={26}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ ml: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ ml: { sm: 1 } }}
            variant={"outlined"}
            onClick={onInitializeClick}
          >
            초기화
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ ml: { sm: 1 } }}
            onClick={onRegistClick}
          >
            신규 등록
          </LAButton>
        </LAGridContainer>
      </LACard>
      <LACard layouts={{ pt: { sm: 1 } }}>
        <LADataTable
          columns={columns}
          rows={rows}
          defaultPage={tablePage}
          onChangePage={onChangeTablePage}
        />
      </LACard>

      <AdminDetailModal
        memberSeq={selectedMemberSeq}
        show={adminDetailModal.state}
        handleClose={adminDetailModal.handleClose}
        handleSelect={adminDetailModal}
      />

      <AdminRegistModal
        show={adminRegistModal.state}
        handleClose={onRegistClose}
        handleSelect={adminRegistModal}
      />
    </>
  );
}

export default AdminList;
