import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/ko';

import { LASimpleTable, LADatePickerButton } from 'modules/Widgets';

function TimeTableList({ timetables, onChange }) {
  const getScheduleRows = useCallback(() => {
    const result = [];
    if (timetables && timetables.length > 0) {
      timetables.forEach((timetable, index) => {
        result.push([
          {
            text: `${parseInt(index) + 1}`,
          },
          {
            text: timetable.supplementary_yn == 1 ? '보강' : '정규',
          },
          {
            text: moment(timetable.schedule_date).format('YYYY-MM-DD (dd)'),
          },
          {
            text: moment(
              timetable.schedule_date + ' ' + timetable.schedule_time,
            ).format('A hh:mm'),
          },
          {
            text: timetable.curriculum_name,
            align: 'left',
          },
          {
            text:
              timetable.suspended_yn == 1
                ? '중지'
                : timetable.begin_yn
                ? '진행중'
                : '-',
          },
          {
            text:
              timetable.suspended_yn == 1
                ? '중지'
                : timetable.end_yn
                ? '종료'
                : '-',
          },
        ]);
      });
    }

    return result;
  }, [timetables]);

  const scheduleTableData = useMemo(() => {
    return {
      headers: [
        {
          text: '회차',
        },
        {
          text: '구분',
        },
        {
          text: '클래스 일정',
        },
        {
          text: '수업 시간',
        },
        {
          text: '수업명',
        },
        {
          text: '진행 여부',
        },
        {
          text: '종료 여부',
        },
      ],
      wPercentList: [5, 7, 15, 13, 30, 10, 10, 10],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  return <LASimpleTable tableData={scheduleTableData} />;
}
export default React.memo(TimeTableList);
