import React, { useCallback } from 'react';
import MDTypography from 'components/MDTypography';
import LAGridItem from './LAGridItem';
import { styled } from '@mui/system';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

const ClickableLabel = styled(MDTypography)((props) => {
  return {
    cursor: props.clickable ? 'pointer' : 'default',
    color: props.clickable ? '#1A73E8' : 'inherit',
  };
});

function LALabel({
  wPercent = 100,
  color,
  layouts = {},
  props = {},
  grid = true,
  clickable= "",
  handleClick = undefined,
  content = '',
  children,
}) {
  const { showAlert } = usePopAlert();
  const gridItem = (children) => {
    return grid ? (
      <LAGridItem wPercent={wPercent} layouts={layouts}>
        {children}
      </LAGridItem>
    ) : (
      children
    );
  };

  const onClick = useCallback(() => {
    if (clickable) {
      if (handleClick) {
        handleClick();
      } else {
        showAlert('', content);
      }
    }
  }, [clickable, content]);

  return gridItem(
    <ClickableLabel
      variant="button"
      fontWeight="regular"
      color={clickable ? 'info' : color?color:'dark'}
      {...props}
      border="0px solid #000000"
      onClick={onClick}
      clickable={clickable}
    >
      {children ? children : ''}
    </ClickableLabel>,
  );
}

export default React.memo(LALabel);
