import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAComboBox,
  LAInput,
  LADatePicker,
  LATabSelector,
  LARadioGroup,
  LADataTable2,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useDatePicker,
  useTabSelector,
  getTextCell,
} from "modules/Widgets";
import { periodItems } from "common/constants";
import { getTodayDateText, toCurrency, getDaysInMonth } from "common/utils";
import { useApiContext } from "common/context/ApiContext";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import PointManagementModal from "./PointManagementModal";
import PointReasonModal from "./PointReasonModal";
import AccountDetailModal from "../User/AccountDetailModal";
import moment from "moment";
import { API_BASE_URL } from "api/AppConfig";
import DownloadReasonModal from "pages/Order/components/DownloadReasonModal";
import DownloadListModal from "pages/Order/components/DownloadListModal";

const periodTypeItems = [
  { key: 1, value: "등록일" },
  { key: 2, value: "유효 마감일" },
];

const searchTypeItems = [
  { key: 1, value: "통합검색" },
  // { key: 7, value: '가입자 아이디' },
  // { key: 2, value: '이름' },
  // { key: 3, value: '항목' },
  // { key: 4, value: '결제 번호' },
  // { key: 5, value: '피추천 코드' },
  // { key: 6, value: '등록자' },
];

function PointManagement() {
  const { queries } = useApiContext();
  const todayDateText = getTodayDateText("-");
  const pointType = useRadioGroup(-1);
  const searchDateType = useComboBox(1);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const searchTargetType = useComboBox("1");
  const searchText = useInput("");
  const pointManagementModal = useModal(false);
  const pointReasonModal = useModal(false);
  const memberDetailModal = useModal(false);

  const downloadReasonModal = useModal(false);
  const downloadListModal = useModal(false);

  const thisYear = useMemo(() => moment().format("YYYY"), []);
  const thisMonth = useMemo(() => moment().format("MM"), []);

  const yearTypeDwn = useComboBox(thisYear);
  const monthTypeDwn = useComboBox(thisMonth);

  const [selectedHistorySeq, setSelectedHistorySeq] = useState(0);
  const [selectedMemberSeq, setSelectedMemberSeq] = useState(0);

  const [rows, setRows] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [page, setPage] = useState(0); // 마지막 페이지네이션

  const onPointManagementClick = useCallback(() => {
    pointManagementModal.visible(true);
  }, []);

  const onPointAppylClick = useCallback(() => {
    onSearchClick();
    pointManagementModal.handleClose();
  }, [pointManagementModal]);

  const onPointReasonClose = useCallback(
    (e) => {
      pointReasonModal.handleClose(e);
      setSelectedHistorySeq(0);
    },
    [pointReasonModal]
  );

  const onMemberDetailClose = useCallback(
    (e) => {
      memberDetailModal.handleClose(e);
      setSelectedMemberSeq(0);
    },
    [memberDetailModal]
  );

  const [searchParams, setSearchParams] = useState({});
  const dataToRow = (item, index) => {
    return {
      no: getTextCell(item.history_seq),
      id: getTextCell(item.email || ""),
      name: getTextCell(item.member_name, item.member_seq, onNameClick),
      history_type: getTextCell(
        item.history_type_name
          ? item.history_type_name
          : item.history_description,
        item.history_seq,
        onHistoryTypeClick
      ),
      point_action: getTextCell(
        item.point_type === 1 || item.point_type === 2
          ? "지급"
          : item.point_type === 3 || item.point_type === 6
          ? "차감"
          : item.point_type === 4
          ? "소멸"
          : "환불"
      ),
      type: getTextCell(item.auto_yn ? "자동" : "수동"),
      point: getTextCell(
        item.point
          ? `${item.point > 0 ? "+" : ""}${toCurrency(item.point)}`
          : ""
      ),
      pay_no: getTextCell(`${item.merchant_uid || ""}\n${item.imp_uid || ""}`),
      // pay_no : getTextCell(item.imp_uid || ''),
      valid_date: getTextCell(
        item.expiration_dt?.length > 0
          ? moment(item.expiration_dt).format("YYYY-MM-DD")
          : ""
      ),
      remain_point: getTextCell(
        item.remaining_point ? toCurrency(item.remaining_point) : "0"
      ),
      code: getTextCell(""),
      register: getTextCell(
        item.auto_yn > 0 ? "System" : item.constructor_name
      ),
      regist_date: getTextCell(
        item.created_dt?.length > 0
          ? moment(item.created_dt).format("YYYY-MM-DD (dd) A hh:mm")
          : ""
      ),
    };
  };

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (page != 0) {
      setTablePage(page);
    }
  }, [rows]);

  const onSearchClick = useCallback(() => {
    let searchParams = {
      point_type: pointType.state < 0 ? undefined : pointType.state,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      query_type: searchTargetType.state,
      query: searchText.state,
    };
    setSearchParams(searchParams);
  }, [
    pointType.state,
    searchDateType.state,
    searchStartDate.state,
    searchEndDate.state,
    searchTargetType.state,
    searchText.state,
  ]);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) {
      onSearchClick();
    }
  }, [searchStartDate.state, searchEndDate.state]);

  const onInitClick = useCallback(() => {
    pointType.reset();
    searchDateType.reset();
    searchPeriodType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    pointType.reset,
    searchDateType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "4%" },
      { Header: "가입자 아이디", accessor: "id", width: "12%", align: "left" },
      { Header: "이름", accessor: "name", width: "7%" },
      { Header: "항목", accessor: "history_type", width: "7%", align: "left" },
      { Header: "계정", accessor: "point_action", width: "9%" },
      { Header: "유형", accessor: "type", width: "9%" },
      { Header: "올머니", accessor: "point", width: "7%", align: "right" },
      { Header: "결제번호", accessor: "pay_no", width: "13%" },
      { Header: "유효 마감일", accessor: "valid_date", width: "8%" },
      { Header: "잔여 올머니", accessor: "remain_point", width: "8%" },
      { Header: "피추천 코드", accessor: "code", width: "10%" },
      { Header: "등록자", accessor: "register", width: "7%" },
      { Header: "등록일", accessor: "regist_date", width: "15%" },
    ];
  }, []);

  const onNameClick = useCallback(
    (memberSeq) => {
      setSelectedMemberSeq(memberSeq);
      memberDetailModal.visible(true);
    },
    [memberDetailModal]
  );

  const onHistoryTypeClick = useCallback(
    (historySeq) => {
      setSelectedHistorySeq(historySeq);
      pointReasonModal.visible(true);
    },
    [pointReasonModal]
  );

  const clickDownload = () => {
    downloadReasonModal.visible(true);
  };

  const clickDownloadList = () => {
    downloadListModal.visible(true);
  };

  const onChangeTablePage = useCallback((page) => {
    setPage(page);
  });

  //빈값으로 들어가도 서버쪽에서 거름
  //period_type: 조회 타입
  //start_dt: YYYY-MM-DD
  //end_dt: YYYY-MM-DD
  //query_type: 검색타입
  //query: 검색값
  //point_type: 지급/차감/소멸/환불 등등..
  const params = {
    period_type: searchDateType.state,
    start_dt: `${yearTypeDwn.state}-${monthTypeDwn.state}-01`,
    end_dt: `${yearTypeDwn.state}-${monthTypeDwn.state}-${getDaysInMonth(
      yearTypeDwn.state,
      monthTypeDwn.state
    )}`,
    query_type: searchTargetType.state,
    query: searchText.state,
    point_type: pointType.state < 0 ? "" : pointType.state,
  };

  return (
    <>
      <DownloadReasonModal
        show={downloadReasonModal.state}
        handleClose={downloadReasonModal.handleClose}
        downKey={"allMoneyList"}
        urlQueries={params}
      />

      <DownloadListModal
        show={downloadListModal.state}
        handleClose={downloadListModal.handleClose}
        type={"POINT"}
      />

      <DashboardNavbar title="올머니 관리" />

      {/* Start Search Area */}
      <LACard>
        <LAProperty name="계정">
          <LARadioGroup
            props={[
              {
                text: "전체",
                value: -1,
              },
              {
                text: "지급",
                value: 1,
              },
              {
                text: "차감",
                value: 2,
              },
              {
                text: "소멸",
                value: 3,
              },
              {
                text: "환불",
                value: 4,
              },
            ]}
            value={pointType.state}
            onChange={pointType.handleChange}
          />
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={12}
              items={periodTypeItems}
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
            />
            <LATabSelector
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={20}
              layouts={{ pl: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={2}>~</LALabel>
            <LADatePicker
              wPercent={20}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={12}
              items={searchTypeItems}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              placeholder="검색어 입력"
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 2 } }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LAButton
              wPercent={15}
              layouts={{ pr: { sm: 1 } }}
              onClick={onSearchClick}
            >
              검색
            </LAButton>
            <LAButton
              wPercent={15}
              layouts={{ pr: { sm: 1 } }}
              onClick={onInitClick}
              variant={"outlined"}
            >
              초기화
            </LAButton>
            <LAButton
              wPercent={15}
              layouts={{ pr: { sm: 1 } }}
              onClick={onPointManagementClick}
            >
              올머니 지급/차감
            </LAButton>
          </div>
        </LAGridContainer>
      </LACard>

      <LACard layouts={{ pt: { sm: 1 } }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <LAComboBox
            wPercent={10}
            items={[
              { key: "2022", value: "2022년" },
              { key: "2023", value: "2023년" },
              { key: "2024", value: "2024년" },
              { key: "2025", value: "2025년" },
            ]}
            value={yearTypeDwn.state}
            onChange={yearTypeDwn.handleChange}
          />
          <LAComboBox
            layouts={{ pl: { sm: 1 } }}
            wPercent={10}
            items={[
              { key: "01", value: "1월" },
              { key: "02", value: "2월" },
              { key: "03", value: "3월" },
              { key: "04", value: "4월" },
              { key: "05", value: "5월" },
              { key: "06", value: "6월" },
              { key: "07", value: "7월" },
              { key: "08", value: "8월" },
              { key: "09", value: "9월" },
              { key: "10", value: "10월" },
              { key: "11", value: "11월" },
              { key: "12", value: "12월" },
            ]}
            value={monthTypeDwn.state}
            onChange={monthTypeDwn.handleChange}
          />
          <LAButton wPercent={10} onClick={clickDownload}>
            엑셀 다운로드
          </LAButton>
          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={clickDownloadList}
          >
            다운로드 이력
          </LAButton>
        </div>
        <LAGridContainer>
          <LADataTable2
            columns={columns}
            loadUrl={queries.Point.history}
            params={searchParams}
            dataToRow={dataToRow}
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
          />
        </LAGridContainer>
      </LACard>

      <PointManagementModal
        show={pointManagementModal.state}
        handleClose={pointManagementModal.handleClose}
        handleApply={onPointAppylClick}
      />

      <PointReasonModal
        show={pointReasonModal.state}
        handleClose={onPointReasonClose}
        historySeq={selectedHistorySeq}
      />

      <AccountDetailModal
        show={memberDetailModal.state}
        handleClose={onMemberDetailClose}
        memberSeq={selectedMemberSeq}
      />
    </>
  );
}

export default PointManagement;
