import React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

/**
 * [LATextInputButtonGroup]
 * 
 */
function LATextInputButtonGroup({
  name,
  value,
  placeholder = '',
  props = {},
  readOnly=false,
  disabled=false,
  type, // number
  onCleared,
  onChange,
  onEnterKeyPressed,
  buttonClearUse=true,
  buttonGroup,
}) {
  const inputRef = React.useRef();
  const [isFocused, setIsFocused] = React.useState(false)

  const onFocus = () => setIsFocused(true)
  const onBlur = React.useCallback(() => {
    setTimeout (() =>{
      setIsFocused(false);
    }, 150)
  }, [])

  const clearInput = React.useCallback(() => {
    onCleared();
    inputRef.current.focus();
  })

  const onKeyPressed = (e) => {
    setIsFocused(true);
    if(onEnterKeyPressed && e.key == 'Enter') {
      onEnterKeyPressed();
    }
  };

  const GetIcon = (iconName) => {
    if (!iconName) {
      return null;
    }

    switch(iconName) {
      case 'search': return <SearchIcon />;
      default: return null; 
    }
  };

  return (
    <div className="LATextInputButtonGroup">
      <InputBase
        inputRef={inputRef}
        value={value}
        type={type}
        name={name}
        className="inputWrap"
        placeholder={placeholder}
        inputProps={props}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyPressed}
        onFocus={onFocus} 
        onBlur={onBlur}
      />
      { (buttonClearUse && !readOnly && !disabled && value?.length > 0 && isFocused) && 
        <IconButton type="button" aria-label="clear" disabled={disabled} onClick={clearInput}>
          <ClearIcon />
        </IconButton>
      }
      { buttonGroup && buttonGroup.map ((item) =>
          item.type == 'icon'?
            <IconButton 
              className="btnIcon" 
              color="secondary" 
              aria-label={item.text}
              disabled={disabled && item.disabled}
              onBlur={onBlur}
              onClick={(e) => item.onClick(e, value)}>
              {GetIcon(item.icon)}
            </IconButton>
            :
            <Button 
              className="btnText" 
              aria-label={item.text}
              disabled={disabled && item.disabled}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              {item.text}
            </Button>
        )
      }
      
    </div>
  );
}

export default React.memo(LATextInputButtonGroup);
