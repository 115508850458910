import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useCommonQuery from "common/hooks/useCommonQuery";

import { getTodayDateText } from "common/utils";

import { useLoginContext } from "common/context/MemberContext";
import { useApiContext } from "common/context/ApiContext";
import useCommonMutation from "common/hooks/useCommonMutation";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import { getDuplicateClassSchedules } from "common/utils/EtcUtils";
import pxToRem from "assets/theme/functions/pxToRem";
import moment from "moment";
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LACodeRadioGroup,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADeletableListView,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useModalPopup,
  useKeywords,
  useCheckbox,
  LAImage,
} from "modules/Widgets";
import useTimer from "common/hooks/useTimer";

function SecondAuthModal({ show, handleClose, email, data }) {
  const { showAlert } = usePopAlert();
  const { queries } = useApiContext();
  const { action } = useLoginContext();
  const { timer, timerState } = useTimer("20분 00초");
  const [msg, setMsg] = useState("");

  const codeRef = useRef();

  useEffect(() => {
    if (timerState === "end")
      setMsg("20분을 초과했습니다. 코드를 다시 갱신 받으세요.");
  }, [timerState]);

  const { requestAsync: getAuthCodeAsync } = useCommonMutation(
    {
      query: queries.Auth.getAuthCode,
      callbacks: {
        onSuccess: (data) => {
          const { result_code, result_message } = data;

          if (result_code !== "0000") {
            showAlert("알림", result_message);
            handleClose();
          }
        },
        onError: (error) => {
          console.log(error);
          return showAlert("알림", "문제가 발생했습니다.");
        },
      },
    },
    []
  );

  const { requestAsync: finalLoginAsync } = useCommonMutation(
    {
      query: queries.Auth.finalLogin,
      callbacks: {
        onSuccess: (data) => {
          const { result_code, result_message, result_data } = data;

          if (result_code === "0000") {
            action.setAccessToken(result_data.accessToken);
            localStorage.setItem("accessToken", result_data.accessToken);
            authAsync();
            handleClose();
          } else if (result_code === "0016") {
            //여기 수정 필요
            setMsg("코드가 일치하지 않습니다. 다시 입력해주세요.");
          } else showAlert("알림", result_message);
        },
        onError: (error) => {
          console.log(error);
          return showAlert("알림", "문제가 발생했습니다.");
        },
      },
    },
    []
  );

  const { requestAsync: authAsync } = useCommonMutation(
    {
      query: queries.Auth.authorize,
      callbacks: {
        onSuccess: (data) => {
          try {
            if (data.result_code === "0000") {
              action.setLoginData(data.result_data);

              window.ChannelIO("boot", {
                pluginKey: "86d9261d-0a09-4a51-9d3a-edb61d9e105e",
                customLauncherSelector: ".chat-icon-wrap",
                hideChannelButtonOnBoot: true,
                language: "ko",
                memberId: `${data.result_data.member_seq}`,
                profile: {
                  name: `${
                    data.result_data.member_name
                      ? data.result_data.member_name
                      : ""
                  }`,
                  mobileNumber: `${
                    data.result_data.profile && data.result_data.profile.phone
                      ? data.result_data.profile.phone.replaceAll("+82 ", "0")
                      : ""
                  }`,
                  email: `${
                    data.result_data.profile && data.result_data.profile.email
                      ? data.result_data.profile.email
                      : ""
                  }`,
                },
              });
            } else if (data.result_code === "1010") {
              action.setLoginData(data.result_data);
              showAlert("알림", data.result_message);
            } else {
              showAlert(
                "알림",
                "로그인에 실패하였습니다. 아이디/패스워드를 확인하세요."
              );
            }
          } catch (e) {
            console.error("로그인 실패 =>", e);
            showAlert("알림", "로그인에 실패하였습니다.");
          }
        },
        onError: (error) => {},
      },
    },
    [action]
  );

  const sendEmail = async () => {
    const params = {
      loginId: data.loginId,
      password: data.password,
      email: email,
    };

    await getAuthCodeAsync(params);
  };

  const codeCheck = async () => {
    const inputCode = codeRef.current?.value;

    const params = {
      loginId: data.loginId,
      password: data.password,
      authCode: inputCode,
      email: email,
    };

    await finalLoginAsync(params);
  };

  return (
    <LAModal title="" width="800px" height="300px" show={show}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          fontSize: "1.1rem",
        }}
      >
        2단계 인증을 진행해 주세요.
        <br />
        사용하시는 {email} 이메일로 인증번호를 전송하였습니다. <br />
        아래 입력란에 인증번호를 입력해주세요.
        <br />
        {timer}
        <br />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <input placeholder="4자리 입력해주세요" ref={codeRef} />
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 0.5 } }}
            onClick={codeCheck}
          >
            확인
          </LAButton>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          메일이 안 왔나요?
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 0.5 } }}
            onClick={sendEmail}
          >
            재전송
          </LAButton>
        </div>
        {msg}
      </div>
    </LAModal>
  );
}

export default React.memo(SecondAuthModal);
