import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import CategoryListItem from './CategoryListItem';

function CategoryListView({
    headerTitle,
    selectedIndex,
    child,
    handleListItemClick,
    visibleAdd,
    onMoveUp,
    onMoveDown,
    onDeleteClick
}) {
    return (
        <List
            component="nav"
            aria-label="secondary mailbox folder"
            sx={{
                overflow: 'hidden',
                borderRadius: '5px',
                padding: "0.5rem"
            }}
            subheader={
                <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#d6d6d6", display: !headerTitle ? "none" : "flex" }}>
                    <ListItem 
                        disableGutters
                        sx={{
                            alignItems: 'center',
                            textAlign: 'left',
                            borderBottom: '1px solid #dddddd',
                            height: "3rem",
                        }}
                        secondaryAction={visibleAdd}
                    >
                        <ListItemText primary={headerTitle} primaryTypographyProps={{ sx: { fontSize: "1rem", fontWeight: "700", }}} />
                    </ListItem>
                </ListSubheader>
            }
        >
            {child && child.map((item, index) => {
                return (
                    <CategoryListItem
                        key={index}
                        index={selectedIndex}
                        title={item.category_name}
                        selectedIndex={item}
                        handleListItemClick={handleListItemClick}
                        onMoveUp={onMoveUp}
                        onMoveDown={onMoveDown}
                        onDeleteClick={onDeleteClick}
                    />
                );
            })}

        </List>
    );
}

export default React.memo(CategoryListView);
