import styled from 'styled-components';

export const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
`;

export const DialogBlock = styled.div`
  width: ${(props) => (props.width ? props.width : '35%')};
  padding: 1.5rem;
  background: white;
  border-radius: 2px;
  h3 {
    margin: 0;
    font-size: 1.5rem;
  }
  p {
    font-size: 0.9rem;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 0.3rem;
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
  }
`;

export const Contents = styled.div`
  margin-top: 2rem;
  text-align: left;
`;
