import React, { useState, useCallback, useMemo, useEffect } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import CategoryComboView from 'pages/Class/components/CategoryComboView';
import { getTodayDateText, toCurrency } from 'common/utils';
import MDBox from 'components/MDBox';
import EmptyImage from 'assets/images/empty.png';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  LAFileAttachment,
  useInput,
  useDatePicker,
  useComboBox,
  useFileAttachment,
  useRadioGroup,
  useModal,
  LASimpleTable,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import TeacherSearchModal from 'pages/Class/components/TeacherSearchModal';
import ClassSearchModal from './ClassSearchModal';
import MemberSearchModal from './MemberSearchModal';
import moment from 'moment';

const hourItems = [
  { key: '01', value: '오전 1시' },
  { key: '02', value: '오전 2시' },
  { key: '03', value: '오전 3시' },
  { key: '04', value: '오전 4시' },
  { key: '05', value: '오전 5시' },
  { key: '06', value: '오전 6시' },
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
  { key: '22', value: '오후 10시' },
  { key: '23', value: '오후 11시' },
  { key: '00', value: '오전 12시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '05', value: '05분' },
  { key: '10', value: '10분' },
  { key: '15', value: '15분' },
  { key: '20', value: '20분' },
  { key: '25', value: '25분' },
  { key: '30', value: '30분' },
  { key: '35', value: '35분' },
  { key: '40', value: '40분' },
  { key: '45', value: '45분' },
  { key: '50', value: '50분' },
  { key: '55', value: '55분' },
];

function CouponRegistModal({ show, handleClose }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const todayDateText = getTodayDateText('-');

  const couponType = useRadioGroup(1);
  const couponCodeInput = useInput('', 20, 'code');
  const [vaildCode, setVaildCode] = useState(false);
  const publishStartDate = useDatePicker(todayDateText);
  const publishStartHourValue = useComboBox('09');
  const publishStartMinuteValue = useComboBox('00');
  const publishEndDate = useDatePicker(
    moment().add(1, 'M').format('YYYY-MM-DD'),
  );
  const publishEndHourValue = useComboBox('09');
  const publishEndMinuteValue = useComboBox('00');
  const validStartDate = useDatePicker(todayDateText);
  const validStartHourValue = useComboBox('09');
  const validStartMinuteValue = useComboBox('00');
  const validEndDate = useDatePicker(moment().add(1, 'M').format('YYYY-MM-DD'));
  const validEndHourValue = useComboBox('09');
  const validEndMinuteValue = useComboBox('00');
  const couponName = useInput('', 30);
  const couponDescription = useInput('', 30);
  const couponCondition = useInput('', 30);
  const limitType = useRadioGroup(0);
  const limitValue = useInput('', 6, 'number', 999999);
  const memberType = useRadioGroup(0);
  const [members, setMembers] = useState([]);
  const discountType = useRadioGroup(2);
  const discountPrice = useInput('', 6, 'number', 999999);
  const discountRatio = useInput('', 3, 'number', 100);
  const maxDiscountPrice = useInput('', 6, 'number', 999999);
  const minOrderPrice = useInput('', 6, 'number', 999999);
  const categoryType = useRadioGroup(0);
  const categoryValue = useComboBox(0);
  const classType = useRadioGroup(0);
  const oneDayType = useRadioGroup(0);
  const [selectedOneDayClass, setSelectedOneDayClass] = useState(undefined);
  const oneDayClassInput = useInput('');
  const packageType = useRadioGroup(0);
  const [selectedPackageClass, setSelectedPackageClass] = useState(undefined);
  const packageClassInput = useInput('');
  const teacherType = useRadioGroup(0);
  const [selectedTeacher, setSelectedTeacher] = useState(undefined);
  const teacherInput = useInput('');
  const publishStatus = useRadioGroup(0);
  const teacherSearchModal = useModal(false);
  const classSearchModal = useModal(false);
  const memberSearchModal = useModal(false);
  const [memberTableInit, setMemberTableInit] = useState(0);

  const getMemberRows = useCallback(() => {
    const result = [];
    for (let i = 0; i < members.length; i++) {
      result.push([
        {
          text: `${members.length - i}`,
        },
        {
          text: members[i].email,
        },
        {
          text: members[i].member_name,
        },
        {
          text:
            members[i].order_price !== undefined &&
            members[i].order_price !== null
              ? toCurrency(members[i].order_price)
              : '',
        },
        {
          text:
            members[i].refund_price !== undefined &&
            members[i].refund_price !== null
              ? toCurrency(members[i].refund_price)
              : '',
        },
        {
          text:
            members[i].point !== undefined && members[i].point !== null
              ? toCurrency(members[i].point)
              : '',
        },
        {
          text: members[i].signin_dt
            ? moment(members[i].signin_dt).format('YYYY-MM-DD')
            : '',
        },
        {
          text: members[i].created_dt
            ? moment(members[i].created_dt).format('YYYY-MM-DD')
            : '',
        },
        {
          text: members[i].state ? '정상' : '비정상',
        },
        {
          text: members[i].marketing_agree_yn ? '동의' : '미동의',
        },
      ]);
    }
    return result;
  }, [members]);

  const memberTableData = useMemo(
    () => ({
      headers: [
        {
          text: 'No',
        },
        {
          text: '가입자 아이디',
        },
        {
          text: '이름',
        },
        {
          text: '누적 구매금액',
        },
        {
          text: '누적 환불금액',
        },
        {
          text: '올머니',
        },
        {
          text: '최근 방문일',
        },
        {
          text: '가입일',
        },
        {
          text: '상태',
        },
        {
          text: '마케팅',
        },
      ],
      wPercentList: [7, 17, 10, 10, 10, 8, 12, 12, 7, 7],
      rows: getMemberRows(),
    }),
    [getMemberRows],
  );

  const onCheckCouponCode = useCallback(() => {
    requestCheckCode();
  }, []);

  const onTeacherSelected = useCallback(
    async (newVal) => {
      setSelectedTeacher({
        teacher_seq: newVal.member_seq,
        teacher_name: newVal.member_name,
        teacher_nick_name: newVal.nick_name
      });
      teacherInput.update(`${newVal.member_name}(${newVal.nick_name})`);
      teacherSearchModal.handleClose();
    },
    [teacherSearchModal.handleClose],
  );

  const onClassSelected = useCallback(
    async (newVal) => {
      if (classType.state == 1) {
        setSelectedOneDayClass(newVal);
        oneDayClassInput.update(`${newVal.class_name}(${newVal.class_seq})`);
      } else if (classType.state == 2) {
        setSelectedPackageClass(newVal);
        packageClassInput.update(`${newVal.class_name}(${newVal.class_seq})`);
      }
      classSearchModal.handleClose();
    },
    [classSearchModal.handleClose],
  );

  const onMemberChange = useCallback((item) => {
    setMembers((prev) => {
      if (
        prev.findIndex((member) => member.member_seq === item.member_seq) >= 0
      ) {
        return prev.filter((member) => member.member_seq !== item.member_seq);
      } else {
        return prev.concat(item);
      }
    });

    setMemberTableInit((prev) => prev + 1);
  }, []);

  const onAllMemberChange = useCallback(
    (params, checked) => {
      if (checked) {
        const targetMembers = params.filter(
          (member) =>
            members.findIndex(
              (param) => param.member_seq === member.member_seq,
            ) < 0,
        );
        setMembers((prev) => prev.concat(...targetMembers));
      } else {
        const targetMembers = members.filter(
          (member) =>
            params.findIndex(
              (param) => param.member_seq === member.member_seq,
            ) >= 0,
        );
        setMembers((prev) =>
          prev.filter((item) => !targetMembers.includes(item)),
        );
      }

      setMemberTableInit((prev) => prev + 1);
    },
    [members],
  );

  const isSelectedMember = useCallback(
    (item) => {
      return (
        members.findIndex((member) => member.member_seq === item.member_seq) >=
        0
      );
    },
    [members],
  );

  const onCouponCodeChange = useCallback((e) => {
    couponCodeInput.handleChange(e);
    setVaildCode(false);
  }, []);

  const onClose = useCallback(() => {
    showConfirm('알림', '수정 사항이 저장되지 않습니다.\n닫으시겠습니까?', {
      confirmHandler: () => handleClose(),
    });
  }, [handleClose]);

  const registDownloadCoupon = useCallback(() => {
    const publishStartDt =
      publishStartDate.state.replaceAll('-', '') +
      publishStartHourValue.state +
      publishStartMinuteValue.state;
    const publishEndDt =
      publishEndDate.state.replaceAll('-', '') +
      publishEndHourValue.state +
      publishEndMinuteValue.state;
    const validStartDt =
      validStartDate.state.replaceAll('-', '') +
      validStartHourValue.state +
      validStartMinuteValue.state;
    const validEndDt =
      validEndDate.state.replaceAll('-', '') +
      validEndHourValue.state +
      validEndMinuteValue.state;
    const todayDt = moment().format('YYYYMMDDHHmm');

    if (couponName.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (couponDescription.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (couponCondition.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (limitType.state == 1 && limitValue.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 1 && discountPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 1 && discountPrice.state < 100) {
      showAlert('알림', '할인금액은 최소 100원 이상 입력해주세요.');
      return;
    }
    if (discountType.state == 2 && discountRatio.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && discountRatio.state == 0) {
      showAlert('알림', '할인율은 최소 1% 이상 입력해주세요.');
      return;
    }
    if (minOrderPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && maxDiscountPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && maxDiscountPrice.state == 0) {
      showAlert('알림', '최대 할인금액에 0보다 큰 수를 입력해주세요.');
      return;
    }
    if (categoryType.state == 1 && categoryValue.state == 0) {
      showAlert('알림', '카테고리를 선택해 주세요.');
      return;
    }
    if (classType.state == 1 && oneDayType.state == 1 && !selectedOneDayClass) {
      showAlert('알림', '원데이 클래스를 선택해 주세요.');
      return;
    }
    if (
      classType.state == 2 &&
      packageType.state == 1 &&
      !selectedPackageClass
    ) {
      showAlert('알림', '패키지 클래스를 선택해 주세요.');
      return;
    }
    if (teacherType.state == 1 && !selectedTeacher) {
      showAlert('알림', '선생님을 선택해 주세요.');
      return;
    }
    if (publishStartDt >= publishEndDt) {
      showAlert('알림', '쿠폰 발급 기간을 확인해주세요.');
      return;
    }
    if (validStartDt >= validEndDt) {
      showAlert('알림', '쿠폰 유효 기간을 확인해주세요.');
      return;
    }
    if (publishStartDt > validStartDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }
    if (publishEndDt > validEndDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }
    if (publishEndDt < todayDt) {
      showAlert('알림', '쿠폰 발급 기간이 유효하지 않습니다.');
      return;
    }
    if (validEndDt < todayDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }

    const params = {
      issuance_method_type: couponType.state,
      issuance_period_st_dt: publishStartDt,
      issuance_period_ed_dt: publishEndDt,
      expiration_period_st_dt: validStartDt,
      expiration_period_ed_dt: validEndDt,
      coupon_name: couponName.state,
      coupon_benefit: couponDescription.state,
      terms_use: couponCondition.state,
      limited_issue_quantity: limitType.state == 0 ? 0 : limitValue.state,
      discount_subject_type: 2,
      discount_type: discountType.state,
      discount_amount:
        discountType.state == 1 ? discountPrice.state : discountRatio.state,
      max_discount_amount: maxDiscountPrice.state,
      min_order_amount: minOrderPrice.state,
      issuance_status: publishStatus.state,
      class_type: classType.state == 0 ? undefined : classType.state,
      class_seq:
        classType.state == 0
          ? undefined
          : classType.state == 1
          ? oneDayType.state == 0
            ? undefined
            : selectedOneDayClass?.class_seq || undefined
          : packageType.state == 0
          ? undefined
          : selectedPackageClass?.class_seq || undefined,
      teacher_seq:
        teacherType.state == 1 ? selectedTeacher.teacher_seq : undefined,
      category_seq: categoryType.state == 0 ? undefined : categoryValue.state,
    };
    console.log(params);
    requestRegistCoupon(params);
  }, [
    publishStartDate.state,
    publishStartHourValue.state,
    publishStartMinuteValue.state,
    publishEndDate.state,
    publishEndHourValue.state,
    publishEndMinuteValue.state,
    validStartDate.state,
    validStartHourValue.state,
    validStartMinuteValue.state,
    validEndDate.state,
    validEndHourValue.state,
    validEndMinuteValue.state,
    couponName.state,
    couponCodeInput.state,
    couponDescription.state,
    couponCondition.state,
    limitType.state,
    limitValue.state,
    discountType.state,
    discountPrice.state,
    discountRatio.state,
    maxDiscountPrice.state,
    minOrderPrice.state,
    categoryType.state,
    categoryValue.state,
    classType.state,
    oneDayType.state,
    packageType.state,
    teacherType.state,
    publishStatus.state,
    members,
    selectedOneDayClass,
    selectedPackageClass,
    selectedTeacher,
  ]);

  const registCommonCodeCoupon = useCallback(() => {
    const publishStartDt =
      publishStartDate.state.replaceAll('-', '') +
      publishStartHourValue.state +
      publishStartMinuteValue.state;
    const publishEndDt =
      publishEndDate.state.replaceAll('-', '') +
      publishEndHourValue.state +
      publishEndMinuteValue.state;
    const validStartDt =
      validStartDate.state.replaceAll('-', '') +
      validStartHourValue.state +
      validStartMinuteValue.state;
    const validEndDt =
      validEndDate.state.replaceAll('-', '') +
      validEndHourValue.state +
      validEndMinuteValue.state;
    const todayDt = moment().format('YYYYMMDDHHmm');

    if (couponCodeInput.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (!vaildCode) {
      showAlert('알림', '중복 확인을 해주세요.');
      return;
    }
    if (couponName.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (couponDescription.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (couponCondition.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (memberType.state == 0 && limitType.state == 1 && limitValue.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (memberType.state == 1 && members.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 1 && discountPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 1 && discountPrice.state < 100) {
      showAlert('알림', '할인금액은 최소 100원 이상 입력해주세요.');
      return;
    }
    if (discountType.state == 2 && discountRatio.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && discountRatio.state == 0) {
      showAlert('알림', '할인율은 최소 1% 이상 입력해주세요.');
      return;
    }
    if (minOrderPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && maxDiscountPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && maxDiscountPrice.state == 0) {
      showAlert('알림', '최대 할인금액에 0보다 큰 수를 입력해주세요.');
      return;
    }
    if (categoryType.state == 1 && categoryValue.state == 0) {
      showAlert('알림', '카테고리를 선택해 주세요.');
      return;
    }
    if (classType.state == 1 && oneDayType.state == 1 && !selectedOneDayClass) {
      showAlert('알림', '원데이 클래스를 선택해 주세요.');
      return;
    }
    if (
      classType.state == 2 &&
      packageType.state == 1 &&
      !selectedPackageClass
    ) {
      showAlert('알림', '패키지 클래스를 선택해 주세요.');
      return;
    }
    if (teacherType.state == 1 && !selectedTeacher) {
      showAlert('알림', '선생님을 선택해 주세요.');
      return;
    }
    if (publishStartDt >= publishEndDt) {
      showAlert('알림', '쿠폰 발급 기간을 확인해주세요.');
      return;
    }
    if (validStartDt >= validEndDt) {
      showAlert('알림', '쿠폰 유효 기간을 확인해주세요.');
      return;
    }
    if (publishStartDt > validStartDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }
    if (publishEndDt > validEndDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }
    if (publishEndDt < todayDt) {
      showAlert('알림', '쿠폰 발급 기간이 유효하지 않습니다.');
      return;
    }
    if (validEndDt < todayDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }

    const params = {
      issuance_method_type: couponType.state,
      issuance_period_st_dt: publishStartDt,
      issuance_period_ed_dt: publishEndDt,
      expiration_period_st_dt: validStartDt,
      expiration_period_ed_dt: validEndDt,
      coupon_name: couponName.state,
      coupon_code: couponCodeInput.state,
      coupon_benefit: couponDescription.state,
      terms_use: couponCondition.state,
      limited_issue_quantity: memberType.state == 1 || limitType.state == 0 ? 0 : limitValue.state,
      all_member_yn: memberType.state == 0 ? 1 : 0,
      target_members: members.map((item) => item.member_seq),
      discount_subject_type: 2,
      discount_type: discountType.state,
      discount_amount:
        discountType.state == 1 ? discountPrice.state : discountRatio.state,
      max_discount_amount: maxDiscountPrice.state,
      min_order_amount: minOrderPrice.state,
      issuance_status: publishStatus.state,
      class_type: classType.state == 0 ? undefined : classType.state,
      class_seq:
        classType.state == 0
          ? undefined
          : classType.state == 1
          ? oneDayType.state == 0
            ? undefined
            : selectedOneDayClass?.class_seq || undefined
          : packageType.state == 0
          ? undefined
          : selectedPackageClass?.class_seq || undefined,
      teacher_seq:
        teacherType.state == 1 ? selectedTeacher.teacher_seq : undefined,
      category_seq: categoryType.state == 0 ? undefined : categoryValue.state,
    };
    console.log(params);
    requestRegistCoupon(params);
  }, [
    couponCodeInput.state,
    vaildCode,
    publishStartDate.state,
    publishStartHourValue.state,
    publishStartMinuteValue.state,
    publishEndDate.state,
    publishEndHourValue.state,
    publishEndMinuteValue.state,
    validStartDate.state,
    validStartHourValue.state,
    validStartMinuteValue.state,
    validEndDate.state,
    validEndHourValue.state,
    validEndMinuteValue.state,
    couponName.state,
    couponDescription.state,
    couponCondition.state,
    memberType.state,
    limitType.state,
    limitValue.state,
    discountType.state,
    discountPrice.state,
    discountRatio.state,
    maxDiscountPrice.state,
    minOrderPrice.state,
    categoryType.state,
    categoryValue.state,
    classType.state,
    oneDayType.state,
    packageType.state,
    teacherType.state,
    publishStatus.state,
    members,
    selectedOneDayClass,
    selectedPackageClass,
    selectedTeacher,
  ]);

  const registRandomCodeCoupon = useCallback(() => {
    const publishStartDt =
      publishStartDate.state.replaceAll('-', '') +
      publishStartHourValue.state +
      publishStartMinuteValue.state;
    const publishEndDt =
      publishEndDate.state.replaceAll('-', '') +
      publishEndHourValue.state +
      publishEndMinuteValue.state;
    const validStartDt =
      validStartDate.state.replaceAll('-', '') +
      validStartHourValue.state +
      validStartMinuteValue.state;
    const validEndDt =
      validEndDate.state.replaceAll('-', '') +
      validEndHourValue.state +
      validEndMinuteValue.state;
    const todayDt = moment().format('YYYYMMDDHHmm');

    if (couponName.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (couponDescription.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (couponCondition.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (limitType.state == 1 && limitValue.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 1 && discountPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 1 && discountPrice.state < 100) {
      showAlert('알림', '할인금액은 최소 100원 이상 입력해주세요.');
      return;
    }
    if (discountType.state == 2 && discountRatio.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && discountRatio.state == 0) {
      showAlert('알림', '할인율은 최소 1% 이상 입력해주세요.');
      return;
    }
    if (minOrderPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && maxDiscountPrice.state.length == 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }
    if (discountType.state == 2 && maxDiscountPrice.state == 0) {
      showAlert('알림', '최대 할인금액에 0보다 큰 수를 입력해주세요.');
      return;
    }
    if (categoryType.state == 1 && categoryValue.state == 0) {
      showAlert('알림', '카테고리를 선택해 주세요.');
      return;
    }
    if (classType.state == 1 && oneDayType.state == 1 && !selectedOneDayClass) {
      showAlert('알림', '원데이 클래스를 선택해 주세요.');
      return;
    }
    if (
      classType.state == 2 &&
      packageType.state == 1 &&
      !selectedPackageClass
    ) {
      showAlert('알림', '패키지 클래스를 선택해 주세요.');
      return;
    }
    if (teacherType.state == 1 && !selectedTeacher) {
      showAlert('알림', '선생님을 선택해 주세요.');
      return;
    }
    if (publishStartDt >= publishEndDt) {
      showAlert('알림', '쿠폰 발급 기간을 확인해주세요.');
      return;
    }
    if (validStartDt >= validEndDt) {
      showAlert('알림', '쿠폰 유효 기간을 확인해주세요.');
      return;
    }
    if (publishStartDt > validStartDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }
    if (publishEndDt > validEndDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }
    if (publishEndDt < todayDt) {
      showAlert('알림', '쿠폰 발급 기간이 유효하지 않습니다.');
      return;
    }
    if (validEndDt < todayDt) {
      showAlert('알림', '쿠폰 유효 기간이 유효하지 않습니다.');
      return;
    }

    const params = {
      issuance_method_type: couponType.state,
      issuance_period_st_dt: publishStartDt,
      issuance_period_ed_dt: publishEndDt,
      expiration_period_st_dt: validStartDt,
      expiration_period_ed_dt: validEndDt,
      coupon_name: couponName.state,
      coupon_benefit: couponDescription.state,
      terms_use: couponCondition.state,
      limited_issue_quantity: limitType.state == 0 ? 0 : limitValue.state,
      discount_subject_type: 2,
      discount_type: discountType.state,
      discount_amount:
        discountType.state == 1 ? discountPrice.state : discountRatio.state,
      max_discount_amount: maxDiscountPrice.state,
      min_order_amount: minOrderPrice.state,
      issuance_status: publishStatus.state,
      class_type: classType.state == 0 ? undefined : classType.state,
      class_seq:
        classType.state == 0
          ? undefined
          : classType.state == 1
          ? oneDayType.state == 0
            ? undefined
            : selectedOneDayClass?.class_seq || undefined
          : packageType.state == 0
          ? undefined
          : selectedPackageClass?.class_seq || undefined,
      teacher_seq:
        teacherType.state == 1 ? selectedTeacher.teacher_seq : undefined,
      category_seq: categoryType.state == 0 ? undefined : categoryValue.state,
    };
    console.log(params);
    requestRegistCoupon(params);
  }, [
    publishStartDate.state,
    publishStartHourValue.state,
    publishStartMinuteValue.state,
    publishEndDate.state,
    publishEndHourValue.state,
    publishEndMinuteValue.state,
    validStartDate.state,
    validStartHourValue.state,
    validStartMinuteValue.state,
    validEndDate.state,
    validEndHourValue.state,
    validEndMinuteValue.state,
    couponName.state,
    couponCodeInput.state,
    couponDescription.state,
    couponCondition.state,
    limitType.state,
    limitValue.state,
    discountType.state,
    discountPrice.state,
    discountRatio.state,
    maxDiscountPrice.state,
    minOrderPrice.state,
    categoryType.state,
    categoryValue.state,
    classType.state,
    oneDayType.state,
    packageType.state,
    teacherType.state,
    publishStatus.state,
    members,
    selectedOneDayClass,
    selectedPackageClass,
    selectedTeacher,
  ]);

  const onRegistClick = useCallback(() => {
    if (couponType.state == 1) {
      registDownloadCoupon();
    } else if (couponType.state == 2 || couponType.state == 4) {
      registCommonCodeCoupon();
    } else if (couponType.state == 3) {
      registRandomCodeCoupon();
    }
  }, [registDownloadCoupon, registCommonCodeCoupon, registRandomCodeCoupon]);

  const { request: requestRegistCoupon } = useCommonMutation({
    query: queries.Coupon.registCoupon,
    callbacks: {
      onSuccess: (data) => {
        if (data && data.result_code === '0000') {
          console.log(data.result_data);
          showAlert('알림', '등록이 정상적으로 완료 되었습니다.', {
            confirmHandler: () => handleClose(true),
            cancelHandler: () => handleClose(true),
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '쿠폰 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestCheckCode } = useCommonQuery({
    query: queries.Coupon.checkCouponCode,
    params: {
      code: couponCodeInput.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data && data.result_code === '9005') {
          console.log(data.result_data);
          showAlert('알림', '사용할 수 있는 쿠폰 코드 입니다.');
          setVaildCode(true);
        } else {
          showAlert('알림', '사용할 수 없는 쿠폰 코드 입니다.');
          setVaildCode(false);
        }
      },
      onError: (error) => {
        showAlert('알림', '쿠폰 중복 체크에 실패했습니다.');
        setVaildCode(false);
      },
    },
    initEnabled: false,
  });

  const onCouponTypeChange = useCallback((e) => {
    const value = e.target.value;
    console.log(value);
    if (value == 3) {
      limitType.update(1);
    } else {
      limitType.update(0);
    }
    couponType.handleChange(e);
  }, []);

  const onSearchClassClick = useCallback(() => {
    if (categoryType.state == 1 && categoryValue.state == 0) {
      showAlert('알림', '카테고리를 선택해주세요.');
      return;
    }
    classSearchModal.visible(true);
  }, [classSearchModal, categoryType.state, categoryValue.state]);

  const onCategoryChange = useCallback(
    (e) => {
      if (e.value != selectedOneDayClass?.category_name) {
        oneDayClassInput.reset();
      }
      if (e.value != selectedPackageClass?.category_name) {
        packageClassInput.reset();
      }
      categoryValue.handleChange(e);
    },
    [selectedOneDayClass, selectedPackageClass],
  );

  return (
    <>
      <LAModal
        title="쿠폰 등록"
        width="90%"
        height="95%"
        show={show}
        onClose={onClose}
      >
        <LACard>
          <LACardTitle title="쿠폰 정보" />

          <LAProperty name="발급 타입">
            <LARadioGroup
              props={[
                {
                  text: '다운로드 쿠폰',
                  value: 1,
                },
                {
                  text: '텍스트 쿠폰',
                  value: 2,
                },
                {
                  text: '난수 쿠폰',
                  value: 3,
                },
                {
                  text: '구독 쿠폰',
                  value: 4,
                },
              ]}
              value={couponType.state}
              onChange={onCouponTypeChange}
            />
          </LAProperty>

          {(couponType.state == 2 || couponType.state == 4) && (
            <LAProperty name="쿠폰 코드" required>
              <LAGridContainer>
                <LAInput
                  wPercent={45}
                  value={couponCodeInput.state}
                  onChange={onCouponCodeChange}
                />

                <LAButton
                  wPercent={15}
                  layouts={{ pl: { sm: 1 } }}
                  onClick={onCheckCouponCode}
                >
                  중복 확인
                </LAButton>
              </LAGridContainer>
            </LAProperty>
          )}

          <LAProperty name="쿠폰명" required>
            <LAGridContainer>
              <LAInput
                wPercent={70}
                placeholder="최대 30자 이내로 입력하세요."
                value={couponName.state}
                onChange={couponName.handleChange}
              />
              <LAWarningLabel
                wPercent={30}
                layouts={{ pl: { sm: 1 } }}
                color="error"
              >
                프론트 노출되는 항목입니다.
              </LAWarningLabel>
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="쿠폰 혜택" required>
            <LAGridContainer>
              <LAInput
                wPercent={70}
                value={couponDescription.state}
                onChange={couponDescription.handleChange}
              />
              <LAWarningLabel
                wPercent={30}
                layouts={{ pl: { sm: 1 } }}
                color="error"
              >
                프론트 노출되는 항목입니다.
              </LAWarningLabel>
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="사용 조건" required>
            <LAGridContainer>
              <LAInput
                wPercent={70}
                value={couponCondition.state}
                onChange={couponCondition.handleChange}
              />
              <LAWarningLabel
                wPercent={30}
                layouts={{ pl: { sm: 1 } }}
                color="error"
              >
                프론트 노출되는 항목입니다.
              </LAWarningLabel>
            </LAGridContainer>
          </LAProperty>

          {(couponType.state == 2 || couponType.state == 4) && (
            <LAProperty name="회원 선택" required>
              <LAGridContainer>
                <LARadioGroup
                  wPercent={30}
                  props={[
                    {
                      text: '전체',
                      value: 0,
                    },
                    {
                      text: '직접 선택',
                      value: 1,
                    },
                  ]}
                  value={memberType.state}
                  onChange={memberType.handleChange}
                />
                {memberType.state == 1 && (
                  <LAButton
                    wPercent={15}
                    layouts={{ pl: { sm: 1 } }}
                    onClick={() => memberSearchModal.visible(true)}
                  >
                    회원 검색
                  </LAButton>
                )}
              </LAGridContainer>

              {memberType.state == 1 && members.length > 0 && (
                <LAGridContainer>
                  <LASimpleTable
                    tableData={memberTableData}
                    isPagination
                    initValue={memberTableInit}
                  />
                </LAGridContainer>
              )}
            </LAProperty>
          )}

          {(couponType.state != 2 || memberType.state == 0) && (
            <LAProperty name="발급건수 제한" required>
              <LAGridContainer>
                <LARadioGroup
                  wPercent={30}
                  props={[
                    {
                      text: '제한없음',
                      value: 0,
                    },
                    {
                      text: '제한있음',
                      value: 1,
                    },
                  ]}
                  value={limitType.state}
                  onChange={limitType.handleChange}
                  disabled={couponType.state == 3}
                />
                {limitType.state == 1 && (
                  <>
                    <LAInput
                      wPercent={30}
                      value={limitValue.state}
                      onChange={limitValue.handleChange}
                    />
                    <LALabel wPercent={10} layouts={{ pl: { sm: 1 } }}>
                      개
                    </LALabel>
                  </>
                )}
              </LAGridContainer>
            </LAProperty>
          )}

          <LAProperty name="할인 설정" required>
            <LAGridContainer>
              <LARadioGroup
                wPercent={30}
                props={[
                  {
                    text: '할인율',
                    value: 2,
                  },
                  {
                    text: '할인금액',
                    value: 1,
                  },
                ]}
                value={discountType.state}
                onChange={discountType.handleChange}
              />
              {discountType.state == 1 && (
                <LAInput
                  wPercent={30}
                  value={discountPrice.state}
                  onChange={discountPrice.handleChange}
                />
              )}
              {discountType.state == 2 && (
                <LAInput
                  wPercent={30}
                  value={discountRatio.state}
                  onChange={discountRatio.handleChange}
                />
              )}
              <LALabel wPercent={3} layouts={{ pl: { sm: 1 } }}>
                {discountType.state == 2 ? '%' : '원'}
              </LALabel>
            </LAGridContainer>
          </LAProperty>

          {discountType.state == 2 && (
            <LAProperty name="최대 할인금액" required>
              <LAGridContainer>
                <LAInput
                  wPercent={60}
                  value={maxDiscountPrice.state}
                  onChange={maxDiscountPrice.handleChange}
                />
                <LALabel wPercent={40} layouts={{ pl: { sm: 1 } }}>
                  원
                </LALabel>
              </LAGridContainer>
            </LAProperty>
          )}

          <LAProperty name="최소 주문금액" required>
            <LAGridContainer>
              <LAInput
                wPercent={60}
                value={minOrderPrice.state}
                onChange={minOrderPrice.handleChange}
              />
              <LALabel wPercent={40} layouts={{ pl: { sm: 1 } }}>
                원 이상 결제 시 사용 가능
              </LALabel>
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="클래스 카테고리">
            <LAGridContainer>
              <LARadioGroup
                wPercent={30}
                props={[
                  {
                    text: '전체',
                    value: 0,
                  },
                  {
                    text: '직접 선택',
                    value: 1,
                  },
                ]}
                value={categoryType.state}
                onChange={categoryType.handleChange}
              />
              {categoryType.state == 1 && (
                <CategoryComboView
                  value={categoryValue.state}
                  onChange={onCategoryChange}
                />
              )}
            </LAGridContainer>
          </LAProperty>

          {/* {categoryType.state == 1 && categoryValue.state != 0 && ( */}
          <LAProperty name="클래스 유형">
            <LAGridContainer>
              <LARadioGroup
                wPercent={35}
                props={[
                  {
                    text: '전체',
                    value: 0,
                  },
                  {
                    text: '원데이',
                    value: 1,
                  },
                  {
                    text: '패키지',
                    value: 2,
                  },
                ]}
                value={classType.state}
                onChange={classType.handleChange}
              />
              <LAWarningLabel wPercent={30} color="error">
                프론트 노출되는 항목입니다.
              </LAWarningLabel>
            </LAGridContainer>
            <LAGridContainer>
              <LAWarningLabel wPercent={60}>
                원데이, 패키지 클래스 선택한 경우에는 클래스 당 1개 쿠폰만 사용
                가능합니다.
              </LAWarningLabel>
            </LAGridContainer>
            {classType.state == 1 && (
              <LAGridContainer>
                <LARadioGroup
                  wPercent={35}
                  props={[
                    {
                      text: '전체 원데이',
                      value: 0,
                    },
                    {
                      text: '직접 선택',
                      value: 1,
                    },
                  ]}
                  value={oneDayType.state}
                  onChange={oneDayType.handleChange}
                />
                {oneDayType.state == 1 && (
                  <>
                    <LAButton wPercent={20} onClick={onSearchClassClick}>
                      클래스 검색
                    </LAButton>
                    <LAInput
                      readOnly
                      wPercent={45}
                      layouts={{ pl: { sm: 1 } }}
                      value={oneDayClassInput.state}
                      onChange={oneDayClassInput.handleChange}
                    />
                  </>
                )}
              </LAGridContainer>
            )}
            {classType.state == 2 && (
              <LAGridContainer>
                <LARadioGroup
                  wPercent={35}
                  props={[
                    {
                      text: '전체 패키지',
                      value: 0,
                    },
                    {
                      text: '직접 선택',
                      value: 1,
                    },
                  ]}
                  value={packageType.state}
                  onChange={packageType.handleChange}
                />
                {packageType.state == 1 && (
                  <>
                    <LAButton wPercent={20} onClick={onSearchClassClick}>
                      클래스 검색
                    </LAButton>
                    <LAInput
                      readOnly
                      wPercent={45}
                      layouts={{ pl: { sm: 1 } }}
                      value={packageClassInput.state}
                      onChange={packageClassInput.handleChange}
                    />
                  </>
                )}
              </LAGridContainer>
            )}
          </LAProperty>
          {/*} )} */}

          <LAProperty name="선생님">
            <LAGridContainer>
              <LARadioGroup
                wPercent={30}
                props={[
                  {
                    text: '전체',
                    value: 0,
                  },
                  {
                    text: '직접 선택',
                    value: 1,
                  },
                ]}
                value={teacherType.state}
                onChange={teacherType.handleChange}
              />
              {teacherType.state == 1 && (
                <>
                  <LAButton
                    wPercent={20}
                    onClick={() => teacherSearchModal.visible(true)}
                  >
                    선생님 검색
                  </LAButton>
                  <LAInput
                    readOnly
                    wPercent={45}
                    layouts={{ pl: { sm: 1 } }}
                    value={teacherInput.state}
                    onChange={teacherInput.handleChange}
                  />
                </>
              )}
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="쿠폰 발급 기간" required>
            <LAGridContainer>
              <LADatePicker
                wPercent={15}
                layouts={{ pr: { sm: 1 } }}
                value={publishStartDate.state}
                onChange={publishStartDate.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={hourItems}
                layouts={{ pr: { sm: 1 } }}
                value={publishStartHourValue.state}
                onChange={publishStartHourValue.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={minItems}
                layouts={{ pr: { sm: 1 } }}
                value={publishStartMinuteValue.state}
                onChange={publishStartMinuteValue.handleChange}
              />
              <LALabel wPercent={1}>~</LALabel>
              <LADatePicker
                wPercent={15}
                layouts={{ pl: { sm: 1 } }}
                value={publishEndDate.state}
                onChange={publishEndDate.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={hourItems}
                layouts={{ pl: { sm: 1 } }}
                value={publishEndHourValue.state}
                onChange={publishEndHourValue.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={minItems}
                layouts={{ pl: { sm: 1 } }}
                value={publishEndMinuteValue.state}
                onChange={publishEndMinuteValue.handleChange}
              />
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="쿠폰 유효 기간" required>
            <LAGridContainer>
              <LADatePicker
                wPercent={15}
                layouts={{ pr: { sm: 1 } }}
                value={validStartDate.state}
                onChange={validStartDate.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={hourItems}
                layouts={{ pr: { sm: 1 } }}
                value={validStartHourValue.state}
                onChange={validStartHourValue.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={minItems}
                layouts={{ pr: { sm: 1 } }}
                value={validStartMinuteValue.state}
                onChange={validStartMinuteValue.handleChange}
              />
              <LALabel wPercent={1}>~</LALabel>
              <LADatePicker
                wPercent={15}
                layouts={{ pl: { sm: 1 } }}
                value={validEndDate.state}
                onChange={validEndDate.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={hourItems}
                layouts={{ pl: { sm: 1 } }}
                value={validEndHourValue.state}
                onChange={validEndHourValue.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={minItems}
                layouts={{ pl: { sm: 1 } }}
                value={validEndMinuteValue.state}
                onChange={validEndMinuteValue.handleChange}
              />
            </LAGridContainer>
          </LAProperty>

          {/* <LAProperty name="발행 여부">
            <LALabel>
              {publishStatus.state == 0
                ? '미발행'
                : publishStatus.state == 1
                ? '발행'
                : '발행 중단'}
            </LALabel>
          </LAProperty> */}
        </LACard>

        <LAGridContainer layouts={{ pt: 3 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            variant={'outlined'}
            btnPaddingY={12}
            onClick={onClose}
          >
            목록
          </LAButton>
          <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onRegistClick}>
            등록하기
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LAModal>

      {teacherSearchModal.state && (
        <TeacherSearchModal
          show={teacherSearchModal.state}
          handleClose={teacherSearchModal.handleClose}
          handleSelect={onTeacherSelected}
        />
      )}

      {classSearchModal.state && (
        <ClassSearchModal
          show={classSearchModal.state}
          handleClose={classSearchModal.handleClose}
          handleSelect={onClassSelected}
          classTypeState={classType.state}
          categorySeq={categoryType.state == 0 ? 0 : categoryValue.state}
        />
      )}

      {/* {memberSearchModal.state && ( */}
      <MemberSearchModal
        show={memberSearchModal.state}
        handleClose={memberSearchModal.handleClose}
        handleMemberChange={onMemberChange}
        handleAllMemberChange={onAllMemberChange}
        isSelectedMember={isSelectedMember}
        checkedMembers={members}
      />
      {/* )} */}
    </>
  );
}

export default CouponRegistModal;
