import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useInputs from "common/hooks/useInputs";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "pages/Common/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useApiContext } from "common/context/ApiContext";
import { useLoginContext } from "common/context/MemberContext";
import useCommonMutation from "common/hooks/useCommonMutation";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import { LAInput } from "modules/Widgets";
import { useModal } from "modules/Widgets";
import SecondAuthModal from "./SecondAuthModal";
import PasswordChangeModal from "./PasswordChangeModal";

function AdminSignIn() {
  const { queries } = useApiContext();
  const initialState = {
    loginId: "",
    password: "",
  };
  const navigate = useNavigate();
  const secondAuthModal = useModal(false);
  const passwordChangeModal = useModal(false);

  const { isLogin, hasAccessToken, loginState, action } = useLoginContext();

  const { data, onChange, reset: resetInputs } = useInputs(initialState);

  const [rememberMe, setRememberMe] = useState(false);

  const { showAlert, showConfirm } = usePopAlert();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { pathname } = useLocation();

  const { requestAsync: loginAsync } = useCommonMutation(
    {
      query: queries.Auth.login,
      callbacks: {
        onSuccess: (data) => {
          const { result_code, result_data, result_message } = data;

          if (result_code === "0000") {
            secondAuthModal.visible(true);
          } else if (result_code === "0001") {
            setEmail(result_data.email);
            secondAuthModal.visible(true);
          } else if (result_code === "1013") {
            showAlert("알림", result_message);
            passwordChangeModal.visible(true);
          } else showAlert("알림", result_message);
        },
        onError: (error) => {
          showAlert("알림", "로그인에 실패하였습니다.");
        },
      },
    },
    [action, navigate]
  );

  const handlerLoginClick = () => {
    onLogin();
  };

  const onEnterKeyPressed = (isEntered) => {
    if (isEntered === true) onLogin();
  };

  const [email, setEmail] = useState("");

  const onLogin = () => {
    loginAsync(data);
  };

  const check = async () => {
    secondAuthModal.handleClose();
  };

  useEffect(() => {
    if (isLogin) navigate("/");
  }, [isLogin]);

  return (
    <>
      <SecondAuthModal
        show={secondAuthModal.state}
        handleClose={check}
        email={email}
        data={data}
      />
      <PasswordChangeModal
        show={passwordChangeModal.state}
        handleClose={passwordChangeModal.handleClose}
        data={data}
      />
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={3}
            textAlign="center"
          >
            <MDTypography
              pt={3}
              pb={3}
              variant="h4"
              fontWeight="medium"
              color="white"
              mt={1}
            >
              관리자 로그인
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <LAInput
                  type="loginId"
                  placeholder="로그인 아이디"
                  name="loginId"
                  fontWeight="small"
                  onChange={onChange}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <LAInput
                  type="password"
                  placeholder="패스워드"
                  name="password"
                  fontWeight="small"
                  onChange={onChange}
                  fullWidth
                  onEnterKeyPressed={onEnterKeyPressed}
                />
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handlerLoginClick}
                >
                  로그인
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    </>
  );
}

export default AdminSignIn;
