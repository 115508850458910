import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import ClassRegistContents from '../components/ClassRegistContents';


function ClassRegist() {
  
  return (
    <>
      <DashboardNavbar title="클래스 등록" />
      <ClassRegistContents />
    </>
  );
}

export default ClassRegist;