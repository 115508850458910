import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';

function LAGridItem({ wPercent = 100, layouts = {}, children }) {
  const widthValue = useMemo(() => {
    return 12 * (wPercent / 100);
  }, [wPercent]);
  return (
    <Grid item xs={12} sm={widthValue} {...layouts}>
      {children}
    </Grid>
  );
}

export default React.memo(LAGridItem);
