import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from "moment";
import "moment/locale/ko";

import { LASimpleTable, LADatePickerButton } from "modules/Widgets";

function UrlHistoryTableList({ histories }) {
  const getScheduleRows = useCallback(() => {
    const result = [];
    if (histories && histories.length > 0) {
      histories.forEach((history, index) => {
        result.push([
          {
            text: history.origin_url,
          },
          {
            text: history.modify_url,
          },
          {
            text: moment(`${history.created_dt}`).format(
              "YYYY-MM-DD (dd) A hh:mm"
            ),
          },
          {
            text: history.member_name,
          },
        ]);
      });
    }

    return result;
  }, [histories]);

  const urlTableData = useMemo(() => {
    return {
      headers: [
        { text: "변경 전 URL" },
        { text: "변경 후 URL" },
        { text: "변경일" },
        { text: "변경자" },
      ],
      wPercentList: [30, 30, 20, 20],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  return <LASimpleTable tableData={urlTableData} />;
}
export default React.memo(UrlHistoryTableList);
