// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
// import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Icon from '@mui/material/Icon';

// components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { useMaterialUIController } from 'context';

import { LAGridContainer, LAButton } from 'modules/Widgets';
import CommentBox from './components/CommentBox';
import { useLoginContext } from 'common/context/MemberContext';

function ControllerCard({classmateSeq, classroomSeq}) {
  const {isLogin,hasAccessToken,loginState,action} = useLoginContext();
//   {
//   isReply,
//   isMine,
//   depth,
//   writer,
//   content,
//   datetime,
//   onChange,
// }
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  // let bWidth = depth ? 100 - depth * 5 : 100;

  return (
    <CommentBox classmateSeq={classmateSeq} classroomSeq={classroomSeq} userName={loginState.member_name} isEditable={true} />
    // <MDBox p={0.2} sx={{ height: '100%', overflow: 'hidden' }}>
    //   <MDBox
    //     p={2}
    //     pl={depth ? depth * 2 : 2}
    //     height="100%"
    //     width="100%"
    //     bgColor={isMine ? 'light' : 'white'}
    //     sx={({ palette: { background } }) => ({
    //       background: background.card,
    //       border: 'solid',
    //       borderWidth: 1,
    //       display: 'inline-block',
    //     })}
    //   >
    //     <LAGridContainer>
    //       {isReply && <Icon fontSize="large">subdirectory_arrow_right</Icon>}
    //       <MDBox
    //         width={`${bWidth}%`}
    //         display="flex"
    //         flexDirection="column"
    //         justifyContent="space-between"
    //       >
    //         <MDBox
    //           display="flex"
    //           justifyContent="space-between"
    //           alignItems="center"
    //           mb={0}
    //           lineHeight={1}
    //         >
    //           <MDBox display="flex">
    //             <LAGridContainer vAlign="center">
    //               {!isMine && <Icon fontSize="large">face</Icon>}
    //               {isMine && <Icon fontSize="large">account_circle</Icon>}
    //               <MDTypography ml={1} variant="h6" color={'text'}>
    //                 {writer}
    //               </MDTypography>
    //             </LAGridContainer>
    //           </MDBox>
    //         </MDBox>
    //         <MDBox mt={1} lineHeight={1}>
    //           <MDTypography variant="body2" fontWeight="bold" color={'text'}>
    //             {content}
    //           </MDTypography>
    //         </MDBox>
    //         <MDBox>
    //           <LAGridContainer vAlign="center">
    //             {datetime ? (
    //               <MDTypography variant="caption" color={'text'}>
    //                 {datetime}
    //               </MDTypography>
    //             ) : null}
    //             {/* <LAButton wPercent={8} layouts={{ml:1}}>답글</LAButton> */}
    //             {isMine && (
    //               <LAButton wPercent={8} layouts={{ ml: 1 }}>
    //                 수정
    //               </LAButton>
    //             )}
    //             {isMine && (
    //               <LAButton wPercent={8} layouts={{ ml: 1 }}>
    //                 삭제
    //               </LAButton>
    //             )}
    //           </LAGridContainer>
    //         </MDBox>
    //       </MDBox>
    //     </LAGridContainer>
    //   </MDBox>
    // </MDBox>
  );
}

// // Setting default values for the props of ControllerCard
// ControllerCard.defaultProps = {
//   color: 'white',
//   isReply: '',
//   writer: '',
//   datetime: '',
// };

// // Typechecking props for the ControllerCard
// ControllerCard.propTypes = {
//   color: PropTypes.oneOf([
//     'primary',
//     'secondary',
//     'info',
//     'success',
//     'warning',
//     'error',
//     'dark',
//     'white',
//     'light',
//   ]),
//   icon: PropTypes.node.isRequired,
//   isReply: PropTypes.string,
//   depth: PropTypes.number,
//   writer: PropTypes.string.isRequired,
//   content: PropTypes.string.isRequired,
//   datetime: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
// };

export default ControllerCard;
