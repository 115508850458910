import React, { useCallback } from 'react';
import MDInput from 'components/MDInput';
import LAGridItem from './LAGridItem';

function LAInput({
    wPercent = 100,
    placeholder = '',
    layouts = {},
    props = {},
    readOnly=false,
    disabled=false,
    name,
    value,
    type,
    accept,
    onChange,
    onEnterKeyPressed,
}) {
    const onKeyPressed = useCallback((e) => {
        if(onEnterKeyPressed && e.key == 'Enter') {
            onEnterKeyPressed(true);
        }
    });

    return (
        <LAGridItem wPercent={wPercent} layouts={layouts}>
            <MDInput
                fullWidth
                size="small"
                placeholder={placeholder}
                {...props}
                name={name}
                value={value}
                type={type}
                accept={accept}
                onChange={onChange}
                readOnly={'readOnly'}
                disabled={disabled}
                onKeyPress={onKeyPressed}
                InputProps={{
                    readOnly: readOnly,
                }}
            />
        </LAGridItem>
    );
}

export default React.memo(LAInput);
