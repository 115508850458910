import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';

import {
  LAGridContainer,
  LAButton,
  LADivider,
  LAProperty,
  LALabel,
  LAModal,
  LACard,
  useInput,
  LATextField,
  LASimpleTable,
  useTextField,
  LAFileAttachment,
  useFileAttachment,
} from 'modules/Widgets';

function UserBatchFeedbackModal({ classmate, show, handleClose }) {
  const feedbackMessage = useTextField('');
  const fileAttachment = useFileAttachment();
  const { showAlert, showConfirm } = usePopAlert();

  const { queries } = useApiContext();

  const { request: registFeedbackBatch } = useCommonMutation({
    query: queries.Classroom.registBatchOthers,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '피드백이 등록되었습니다.', {
            confirmHandler: () => {
              // answerInput.update('');
              // requestDetail();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '피드백 등록에 실패했습니다.');
      },
    },
  });

  const getScheduleRows = useCallback(() => {
    const result = [];
    if (classmate && classmate.length > 0) {
      for (const data of classmate) {
        result.push([
          {
            text: data.user_name,
          },
          {
            text: data.class_name,
          },
          {
            text: moment(
              `${data.schedule_date} ${data.schedule_time}`,
              'YYYYMMDD hhmm',
            ).format('YYYY-MM-DD (dd) A hh:mm'),
          },
        ]);
      }
    }

    return result;
  }, [classmate]);

  const tableData = useMemo(() => {
    return {
      headers: [
        {
          text: '수강자',
        },
        {
          text: '클래스 명',
        },
        {
          text: '클래스 시작일',
        },
      ],

      wPercentList: [30, 35, 35],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  const onClickFeedbackBatch = useCallback(() => {
    const formData = new FormData();
    let classmates = classmate.map((am) => am.classmate_seq);
    let classrooms = classmate.map((am) => am.classroom_seq);
    formData.append('classmates', JSON.stringify(classmates));
    formData.append('classrooms', JSON.stringify(classrooms));
    formData.append('contents', feedbackMessage.state);

    var params = {
      data: formData,
    };

    registFeedbackBatch(params);
  }, [registFeedbackBatch, feedbackMessage]);

  useEffect(() => {
    feedbackMessage.update('');
  }, []);

  return (
    <LAModal
      title="일괄 피드백"
      width="80%"
      height="auto"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LAProperty name="신청자 목록 ">
          <LALabel wPercent={40}></LALabel>
        </LAProperty>

        <LASimpleTable tableData={tableData} />

        <LAProperty name="피드백 내용">
          <LALabel wPercent={40}></LALabel>
        </LAProperty>
        <LATextField
          placeholder="보낼 내용을 입력해주세요."
          value={feedbackMessage.state}
          onChange={feedbackMessage.handleChange}
        />
        <LAGridContainer pt={1}>
          <LAFileAttachment
            value={fileAttachment.state.name}
            onChange={fileAttachment.handleChange}
          />
        </LAGridContainer>

        <LAGridContainer pt={3}>
          <LALabel wPercent={34} />
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 0.5 } }}
            variant={'outlined'}
            onClick={handleClose}
          >
            취소
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 0.5 } }}
            onClick={onClickFeedbackBatch}
          >
            보내기
          </LAButton>
        </LAGridContainer>
      </LACard>
    </LAModal>
  );
}

export default UserBatchFeedbackModal;
