import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'UPDATE':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useTextEditor(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const update = useCallback(
    (value) => {
      // if (state === value) {
      //   return;
      // }

      dispatch({
        type: 'UPDATE',
        value,
      });
    },
    [state],
  );

  const handleChange = useCallback(
    (value) => {
      update(getInnerText(value));
    },
    [update],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  const getInnerText = useCallback((contentHtml) => {
    if (contentHtml.search('<') !== 0) {
      return contentHtml;
    }

    const tempElem = document.createElement('div');
    tempElem.innerHTML = contentHtml;
    let innerText = '';
    tempElem.childNodes.forEach((node, index) => {
      innerText += index > 0 ? '\n' : '';
      innerText += node?.innerText || node?.outerHTML || '';
    });

    const excludeStrings = [
      '<div style="font-size: 16px; font-family: 노토산스;"><br></div>',
      '<div style="font-size: 16px; font-family: 노토산스;"><p><br></p></div>',
      '<p><br></p>',
      '<p></p>',
    ];
    excludeStrings.forEach((str) => {
      innerText = innerText.replace(str, '');
    });

    return innerText;
  }, []);

  return {
    state,
    handleChange,
    update,
    reset,
    getInnerText,
  };
}

export default useTextEditor;
