import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/ko';

import { LASimpleTable, LADatePickerButton } from 'modules/Widgets';

function ScheduleHistoryTableList({ histories }) {
  const getScheduleRows = useCallback(() => {
    const result = [];
    if (histories && histories.length > 0) {
      histories.forEach((history, index) => {
        result.push([
          {
            text: history.supplementary_yn == 1 ? '보강' : '변경',
          },
          {
            text: moment(
              `${history.old_schedule_date} ${history.old_schedule_time}`,
            ).format('YYYY-MM-DD (dd) A hh:mm'),
          },
          {
            text: moment(
              `${history.schedule_date} ${history.schedule_time}`,
            ).format('YYYY-MM-DD (dd) A hh:mm'),
          },
          {
            text: history.reason,
          },
          {
            text: moment(history.created_dt).format('YYYY-MM-DD (dd) A hh:mm'),
          },
          {
            text: history.constructor_name,
          },
        ]);
      });
    }

    return result;
  }, [histories]);

  const scheduleTableData = useMemo(() => {
    return {
      headers: [
        {
          text: '구분',
        },
        {
          text: '기존 수업 일정',
        },
        {
          text: '변경 수업 일정',
        },
        {
          text: '변경 사유',
        },
        {
          text: '변경일',
        },
        {
          text: '변경자',
        },
      ],
      wPercentList: [7, 15, 15, 38, 15, 10],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  return (
    <>
      {/* <LACard next>         */}
      <LASimpleTable tableData={scheduleTableData} />
      {/* </LACard> */}
    </>
  );
}
export default React.memo(ScheduleHistoryTableList);
