import React, { useState, useCallback } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  getTextCell,
  getNormalButton,
} from 'modules/Widgets';

function AllianceInquiryDetail() {
  return (
    <>
      <DashboardNavbar title="제휴/협력문의 관리" />

      <LACard>
        <LACardTitle title="제휴/협력 문의 정보" />
        <LAProperty name="업체/기관 명">
          <LALabel>웅진 씽크빅</LALabel>
        </LAProperty>
        <LAProperty name="담당자 명">
          <LALabel>김웅진</LALabel>
        </LAProperty>
        <LAProperty name="연락처">
          <LALabel wPercent={40} clickable="true" content={`010-1234-1234`}>
            010-****-****
          </LALabel>
        </LAProperty>
        <LAProperty name="이메일">
          <LALabel clickable="true" content={`kimw1234@1234`}>
            kimw****@****
          </LALabel>
        </LAProperty>
        <LAProperty name="내용">
          <LALabel>
            아이들에게 특별한 경험을 선사하는 클래스를 제공하고 싶습니다.
          </LALabel>
        </LAProperty>
        <LAProperty name="작성일">
          <LALabel>2022-04-07</LALabel>
        </LAProperty>
      </LACard>

      <LAGridContainer layouts={{ pt: { sm: 1 } }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton wPercent={30} variant={'outlined'}>
          목록
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>
    </>
  );
}

export default AllianceInquiryDetail;
