import React, { useState, useCallback, useEffect ,useMemo} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useParams, useNavigate } from 'react-router-dom';
import { useApiContext } from 'common/context/ApiContext';
import { useLoginContext } from 'common/context/MemberContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import { getTodayDateText, dateTimeToString } from 'common/utils';

import ProfileList from './ProfileList';

import moment from 'moment';

import {
    LACard,
    LACardTitle,
    LAGridContainer,
    LAProperty,
    LALabel,
    LAButton,
    LAInput,
    LASimpleTable,
    useInput,
    LACheckbox,
    useCheckbox,
    LAWarningLabel,
    useModal, 
    LADivider
} from "modules/Widgets";
import UserMemoModal from '../../Class/components/UserMemoModal';
import MembershipSearchModal from "../../Class/components/MembershipSearchModal";
import { toCurrency } from 'common/utils';

function AccountDetailContents({ memberSeq, onClickList }) {
    // let { seq } = useParams();
    const navigate = useNavigate();
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();
    const { action } = useLoginContext();

    const [member, setMember] = useState({});
    const [profiles, setProfiles] = useState({});
    const [comments, setComments] = useState([]);
    const [memberContracts, setMemberContracts] = useState([]);

    const commentInput = useInput('');
    const isTestMode = useCheckbox(false);

    const [selectedUserData, setSelectedUserData] = useState(null);
    const [memoType,setMemoType] = useState(0)
    const memoModal = useModal(false);
    const membershipModal = useModal(false);

    const authMemberType = useMemo(() => {
        return action.getMemberType();
    }, [action]);

    const authAdminAuthType = useMemo(() => {
        return action.getAuthType();
    }, [action]);

    const priceSplitter = (number) =>
        number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    useEffect(() => {
        requestMember();
    }, []);

    const getCommentRows = useCallback(() => {
        const result = [];
        if (comments && comments.length > 0) {

            comments.forEach((comment, index) => {
                result.push([
                    { text: `${comment.comment}`, align: 'left' },
                    { text: moment(comment.created_dt).format('YYYY-MM-DD (dd) A hh:mm') },
                    {
                        text: '삭제',
                        type: 'button',
                        callback: {
                            onClick: () => onClickDeleteComment(comment.comment_id)
                        },
                    }
                ]);
            });
        }
        return result;
    }, [comments]);

    const managementMemoTableData = {
        headers: [],
        wPercentList: [70, 20, 10],
        rows: getCommentRows(),
        rowBackgroundColor: '#eeeeee',
    };

    const setMemberInfo = useCallback((member) => {
        setMember(member);
        if (member.users) setProfiles(member.users);
        if (member.comments) setComments(member.comments);

        // if (member.customer_number) {
        //   requestMemberContractInfo(
        //       {customer_number: member.customer_number}
        //   );
        // }

        isTestMode.reset(false);
        if(member.test_mode_yn){
            isTestMode.toggle();
        }
    },[isTestMode]);

    // const naivgateBack = () => {
    //   navigate(-1, { replace: true });
    // };

    const onClickDeleteComment = useCallback((comment_id) => {
        showConfirm('확인', '관리 메모를 삭제하시겠습니까?', {
            confirmHandler: () => {
                requestDeleteComment({
                    comment_id: comment_id,
                    member_seq: memberSeq,
                });
            },
        });
    });

    const getDateTimeText = (value) => {
        return new Date(value.replaceAll('Z', ''));
    };

    const onMembershipClick = useCallback((e) =>{
        membershipModal.visible(true);
    },[]);

    const onMemoClick = useCallback((e) =>{
        setSelectedUserData({...e,email:member.email});
        setMemoType(0);
        memoModal.visible(true);
    });

    const onClickRegistComment = useCallback(() => {
        if (!commentInput.state || commentInput.state.length < 1) {
            showAlert('알림', '관리 메모를 입력해 주세요.');
            return;
        }

        requestRegistComment({
            comment: commentInput.state,
            member_seq: memberSeq,
        });
    });

    const onChangeTestMode = useCallback(() => {
        if(isTestMode.state){
            // if(member.test_mode_yn == 0){
            showConfirm('확인', '테스트 모드를 활성화 하시겠습니까?', {
                confirmHandler: () => {
                    requestChangeTestMode({member_seq:member.member_seq,body:{test_mode_yn: 1}});
                },
            });
            // }
        
        } else {
            // if(member.test_mode_yn == 1){
            showConfirm('확인', '테스트 모드를 비활성화 하시겠습니까?', {
                confirmHandler: () => {
                    requestChangeTestMode({member_seq:member.member_seq,body:{test_mode_yn: 0}});
                },
            });
            // }
        }
    },[isTestMode.state,member])

    const { request: requestMember } = useCommonQuery({
        query: queries.Member.getProfile,
        params: {
            member_seq: memberSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data && data.result_code === '0000') {
                    if (data.result_data) {
                        setMemberInfo(data.result_data);

                        if (data.result_data.customer_number) {
                            requestMemberContractInfo({
                                customer_number: data.result_data.customer_number,
                                contract_number: data.result_data.contract_number,
                            });
                        }
                    }
                } else {
                    if (data && data.result_message) {
                        showAlert('알림', data.result_message);
                    } else {
                        showAlert('알림', '회원 정보 조회에 실패했습니다.');
                    }
                }
            },
            onError: (error) => {
                showAlert('알림', '회원 정보 조회에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const { request: requestRegistComment } = useCommonMutation({
        query: queries.Member.registComment,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '관리 메모가 등록되었습니다.');
                    setComments(data.result_data);
                    commentInput.update('');
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '관리 메모 등록에 실패했습니다.');
            },
        },
    });

    const { request: requestMemberContractInfo } = useCommonMutation({
        query: queries.Member.requestMemberContractInfo,
        callbacks: {
            onSuccess: (data, params) => {
                console.log('requestMemberContractInfo', data, params);
              /*   if (data.LIST_CNTRT_INFO) {
                    let listContracts = [];
                    data.LIST_CNTRT_INFO.forEach((obj, idx) => {
                        let contractObj = {
                            CNTRT_ID: obj.CNTRT_ID,
                            MTRL_NM: obj.MTRL_NM,
                            CPNT: obj.CPNT
                        }
                        if (obj.CNTRT_ID === params.contract_number) {
                            contractObj['selected'] = 'Y'
                        }

                        listContracts.push(contractObj);
                    });
                    setMemberContracts( listContracts );
                }  */
                if(data.result_code = "0000") {
                    let listContracts = [];
                    data.result_data.forEach((obj, idx) => {
                        let contractObj = {
                            contractNumber: obj.contractNumber,
                            contractName: obj.contractName,
                            contractSite: obj.contractSite,
                            point: obj.point,
                            mileage: obj.mileage
                        }
                        if (obj.contractNumber === params.contract_number) {
                            contractObj['selected'] = 'Y'
                        }

                        listContracts.push(contractObj);
                    });
                    setMemberContracts( listContracts );
                }
            },
            onError: (error) => {
                showAlert('알림', error);
            },
        },
    });

    const { request: requestDeleteComment } = useCommonMutation({
        query: queries.Member.deleteComment,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '관리 메모가 삭제되었습니다.');
                    setComments(data.result_data);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '관리 메모 삭제에 실패했습니다.');
            },
        },
    });

    const { request: requestChangeTestMode } = useCommonMutation({
        query: queries.Member.changeTestMode,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '테스트 모드 정보가 변경되었습니다.');
                    setComments(data.result_data);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '테스트 모드 변경에 실패했습니다.');
            },
        },
    });

    const membershipResultTableData = useMemo(() => {
        if (!member.contract_histories || member?.contract_histories?.length === 0) {
            return {
                headers: [],
                wPercentList: [],
                rows: [],
            }
        } else {
            return {
                headers: [
                    { text: '변경일시' },
                    { text: '변경 전 고객번호' },
                    { text: '변경 후 고객번호' },
                    { text: '처리자' },
                ],
                wPercentList: [30, 20, 20, 20],
                rows: member?.contract_histories?.map((contract) => [
                    { text: moment(contract.created_at).format('YYYY-MM-DD (dd) A hh:mm:ss') },
                    { text: contract?.before_customer_number },
                    { text: contract?.after_customer_number },
                    { text: contract?.constructor_name },
                ]),
            }
        }
    },[member]);

    const refreshMemberData = useCallback(() => {
        requestMember();
    }, [requestMember]);

    return (
        <>
            <LACard>
                <LACardTitle title="가입자 정보" />
                    <LAProperty name="이름">
                    <LALabel wPercent={40}>{member.member_name ? member.member_name : ''}</LALabel>
                </LAProperty>

                <LAProperty name="휴대폰 번호">
                    <LALabel wPercent={40}>{member && member.phone ? member.phone : ''}</LALabel>
                </LAProperty>

                <LAProperty name="이메일">
                    <LALabel wPercent={40}>{member && member.email ? member.email : ''}</LALabel>
                </LAProperty>

                <LAProperty name="생년월일">
                    <LALabel wPercent={40}>{member && member.birthday ? member.birthday : ''}</LALabel>
                </LAProperty>

                <LAProperty name="추천인 코드(본인)">
                    <LALabel wPercent={40}>{member && member.rnd_code ? member.rnd_code : ''}</LALabel>
                </LAProperty>

                <LAProperty name="가입일">
                    <LALabel wPercent={40}>{member.created_dt ? moment(member.created_dt).format('YYYY-MM-DD (dd) A hh:mm') : ''}</LALabel>
                </LAProperty>

                <LAProperty name="가입 추천인">
                    <LALabel wPercent={40}>
                        {member.referral_code + (member && member.referralUser ? ' / ' + member.referralUser.member_name + ' / ' + member.referralUser.email : '')}
                    </LALabel>
                </LAProperty>
                {/* <LAProperty name="아이디">
                <LALabel wPercent={40} clickable="true" content={`kimw1234@1234`}>
                {member&&member.email?member.email:''}
                </LALabel>
                </LAProperty> */}
                {/* <LAProperty name="회원 등급">
                    <LALabel wPercent={40}>어썸 올</LALabel>
                    </LAProperty> */}
                <LAProperty name="마케팅 동의">
                    <LALabel wPercent={40}>
                        {member.marketing_agree_yn ? `${'동의 - '} ${member.marketing_sms_agree_yn ? 'SMS' : ''} ${member.marketing_email_agree_yn ? ',이메일' : ''}` : '미동의'}
                    </LALabel>
                </LAProperty>

                <LAProperty name="회원 상태">
                    <LALabel wPercent={40}>
                        {member.state == 0 ? '정상' : member.dormancy_yn ? '휴면' : member.expired_yn ? '만료' : member.resigned_yn ? '탈퇴' : '정상'}{' '} 회원
                    </LALabel>
                </LAProperty>

                <LAProperty name="최근 방문일시">
                    <LALabel wPercent={40}>{member.signin_dt ? moment(member.signin_dt).format('YYYY-MM-DD (dd) A hh:mm') : ''}</LALabel>
                </LAProperty>
                {/* <LAProperty name="로그인 IP">
                <LALabel wPercent={40}>175.124.154.63</LALabel>
                </LAProperty> */}
                {/* <LAProperty name="최근 방문기기">
                <LALabel wPercent={40}>PC</LALabel>
                </LAProperty> */}
                {/* <LAProperty name="약관 동의">
                <LALabel wPercent={40}>동의 (변경일: 2022-04-15)</LALabel>
                </LAProperty> */}
            </LACard>

            <LACard layouts={{ pt: 1 }}>
                <LACardTitle title="결제 정보" />
                <LAProperty name="누적 구매 금액">
                    <LALabel wPercent={100}>{member.order_price ? priceSplitter(member.order_price) : 0}원</LALabel>
                </LAProperty>
                <LAProperty name="누적 환불 금액">
                    <LALabel wPercent={100}>{member.refund_price ? priceSplitter(member.refund_price) : 0}원</LALabel>
                </LAProperty>
            </LACard>

            <LACard layouts={{ pt: 1}}>
                <LACardTitle title="올머니" />
                <LAProperty name="올머니">
                    <LALabel wPercent={100}>{member.point ? priceSplitter(member.point) : 0} 올</LALabel>
                </LAProperty>
            </LACard>

            {/*`23.04.04 웅진 멤버십 변경 추가*/}
            {/* 23-08-30 명칭 변경 <LACardTitle title="클럽 포인트" /> */}
            <LACard layouts={{ pt:1}}>
                <LACardTitle title="웅진 멤버십" />
                <LAProperty name="고객번호">
                    <LAGridContainer>
                        <LALabel wPercent={10}>{member?.customer_number ? member?.customer_number : ""}</LALabel>
                        <LAButton wPercent={20} onClick={onMembershipClick}>웅진 멤버십 등록 및 변경</LAButton>
                    </LAGridContainer>
                </LAProperty>
                {memberContracts.map((obj, idx) => {
                    return (
                        <>
                            <LAProperty name={`${obj.contractName}`} wNameWidth={20}>
                                <LALabel wPercent={100} key={obj}>{obj.contractSite === "M" ? `${toCurrency(obj.point)} P` : `${toCurrency(obj.mileage)} M`}</LALabel>
                            </LAProperty>
                        </>
                    );
                })}
                {/*변경이력 없을경우 화면 미출력*/}
                {member?.contract_histories?.length !== 0 &&
                    <>
                        <LADivider pt={4} />
                        <LAProperty name="웅진 멤버십 고객번호 변경 이력" wNameWidth={20}></LAProperty>
                        <LASimpleTable tableData={membershipResultTableData} />
                    </>
                }
            </LACard>

            {profiles.length > 0 ? <ProfileList profiles={profiles} onMemoClick={onMemoClick} /> : ''}

            <LACard layouts={{ pt: 1 }}>
                <LACardTitle title="관리 메모" />
                <LAProperty name="관리 메모">
                    <LAGridContainer>
                        <LAInput
                            wPercent={85}
                            placeholder="관리 메모 입력"
                            layouts={{ pr: 1 }}
                            value={commentInput.state}
                            onChange={commentInput.handleChange}
                            onEnterKeyPressed={() => {}}
                        />
                        <LAInput wPercent={1} props={{ sx: { display: 'none' } }} />
                        <LAButton wPercent={9} onClick={onClickRegistComment}>등록</LAButton>
                    </LAGridContainer>
                </LAProperty>
                <LAGridContainer pt={1}>
                    <LASimpleTable tableData={managementMemoTableData} />
                </LAGridContainer>
            </LACard>

{/*            {authMemberType == 1 && authAdminAuthType == 1 ? (
                <LACard layouts={{ pt: 1 }}>
                <LACardTitle title="테스트 모드(마스터 관리자 only)" />
                    <LAProperty name="테스트 모드">
                        <LAGridContainer>
                            <LACheckbox
                                label={'테스트 클래스 사용 가능'}
                                value={isTestMode.state}
                                onClick={isTestMode.handleClick}
                            />
                            <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} variant={'outlined'} onClick={onChangeTestMode}>변경</LAButton>
                            <LAWarningLabel wPercent={80} layouts={{ pl: { sm: 5 } }}>마스터 관리자만 수정 가능합니다.(마스터외 미노출)</LAWarningLabel>
                        </LAGridContainer>
                    </LAProperty>
                </LACard>
            ) : ('')}*/}

            <LAGridContainer layouts={{ pt: { sm: 1 } }}>
                <LALabel wPercent={40}></LALabel>
                <LAButton wPercent={20} layouts={{ pr: { sm: 1 } }} variant={'outlined'} onClick={onClickList}>목록</LAButton>
                {/* <LAButton wPercent={15} layouts={{ pl: { sm: 1 } }}>
                저장
                </LAButton>
                <LALabel wPercent={35}></LALabel> */}
            </LAGridContainer>

            <UserMemoModal 
                data={selectedUserData} 
                type={memoType}
                show={memoModal.state}
                handleClose={memoModal.handleClose}
            />

            <MembershipSearchModal
                show={membershipModal.state}
                handleClose = {membershipModal.handleClose}
                member={member}
                customerNumber={member?.customer_number}
                refreshMemberData={refreshMemberData}
            />
        </>
    );
}

export default AccountDetailContents;
