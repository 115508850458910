import React, { useState, useMemo, useEffect, useCallback } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { getTodayDateText, dateTimeToString } from 'common/utils';

import AllianceRegistModal from './components/AllianceRegistModal';
import AllianceDetailModal from './components/AllianceDetailModal';

import moment from 'moment';
import { periodItems } from 'common/constants';
import {
    LACard,
    LADataTable,
    LAGridContainer,
    LAProperty,
    LALabel,
    LAButton,
    LAComboBox,
    LAInput,
    LADatePicker,
    LATabSelector,
    getTextCell,
    getNormalButton,
    useComboBox,
    useInput,
    useModal,
    useDatePicker,
    useTabSelector,
} from 'modules/Widgets';

// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

function AllianceManagement() {
    const { queries } = useApiContext();

    const todayDateText = getTodayDateText('-');
    const searchPeriodType = useTabSelector(3);
    const searchStartDate = useDatePicker(todayDateText);
    const searchEndDate = useDatePicker(todayDateText);
    const searchTargetType = useComboBox(0);
    const searchText = useInput('');

    const allianceRegistModal = useModal(false);
    const allianceDetailModal = useModal(false);

    const [alliances, setAlliances] = useState([]);

    const [selectedAllianceSeq, setSelectedAllianceSeq] = useState(0);

    const onRegisterClick = useCallback(() => {
        allianceRegistModal.visible(true);
    });

    const onDetailClick = useCallback((alliance_seq) => {
        setSelectedAllianceSeq(alliance_seq);
        allianceDetailModal.visible(true);
    });

    const columns = useMemo(() => {
        return [
            { Header: 'No', accessor: 'no', width: '5%' },
            { Header: '제휴/협력사 코드', accessor: 'alliance_code', width: '10%' },
            { Header: '제휴/협력사 이름', accessor: 'alliance_name', width: '15%' },
            { Header: '기업 코드', accessor: 'corp_code', width: '15%' },
            { Header: '담당자이름', accessor: 'manager_name', width: '10%' },
            { Header: '담당자 전화번호', accessor: 'manager_phone', width: '10%' },
            { Header: '담당자 이메일', accessor: 'manager_email', width: '10%' },
            { Header: '사용여부', accessor: 'use_yn', width: '5%' },
            { Header: '등록일', accessor: 'regist_date', width: '15%' },
            { Header: '상세', accessor: 'detail', width: '10%' },
        ];
    }, []);

    const rows = useMemo(() => {
        if (alliances) {
            let rows = [];
            alliances.map((item) => {
                rows.push({
                    no: getTextCell(`${item.alliance_seq}`),
                    alliance_code: getTextCell(`${item.alliance_code}`, `${item.alliance_seq}`, onDetailClick),
                    alliance_name: getTextCell(`${item.alliance_name}`, `${item.alliance_seq}`, onDetailClick),
                    corp_code: getTextCell(`${item.corp_code}`),
                    manager_name: getTextCell(`${item.manager_name}`),
                    manager_phone: getTextCell(`${item.manager_phone}`),
                    manager_email: getTextCell(`${item.manager_email}`),
                    regist_date: getTextCell(`${moment(item.created_dt).format('YYYY-MM-DD (dd) A hh:mm')}`),
                    use_yn: getTextCell(`${item.use_yn == 1 ? 'Y' : 'N'}`),
                    detail: getNormalButton('상세', `${item.alliance_seq}`, onDetailClick,),
                });
            });
            return rows;
        } else {
            return [];
        }
    }, [alliances]);

    const { status, request: requestAlliances } = useCommonQuery({
        query: queries.Alliance.getList,
        params: {
            start_dt: searchStartDate.state,
            end_dt: moment(searchEndDate.state).add(1, 'd').format('YYYY-MM-DD'),
            query_type: searchTargetType.state,
            query: searchText.state,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setAlliances(data.result_data);
                } else {
                }
            },
            onError: (error) => {
            },
        },
        initEnabled: false,
    });

    useEffect(() => {
        searchEndDate.reset(searchPeriodType.state);
        searchStartDate.selectByPeriod(searchPeriodType.state);
    }, [searchPeriodType.state, searchEndDate.reset]);

    useEffect(() => {
        if (searchStartDate.state != searchEndDate.state) {
            requestAlliances();
        }
    }, [searchStartDate.state]);

    const onSearchClick = useCallback(() => {
        requestAlliances();
    }, []);

    const onEnterKeyAtSearch = useCallback(() => {
        onSearchClick();
    }, []);

    const onRegistClosed = useCallback(() => {
        requestAlliances();
        allianceRegistModal.handleClose();
    });

    const onDetailClosed = useCallback(() => {
        requestAlliances();
        allianceDetailModal.handleClose();
    });

    const onInitClick = useCallback(() => {
        searchPeriodType.reset();
        searchStartDate.reset();
        searchEndDate.reset();
        searchTargetType.reset();
        searchText.reset();
    }, [
        searchPeriodType.reset,
        searchStartDate.reset,
        searchEndDate.reset,
        searchTargetType.reset,
        searchText.reset,
    ]);

    return (
        <>
            <DashboardNavbar title="제휴/협력사 관리" />
            {/* Start Search Area */}
            <LACard>
                <LAProperty name="조회 기간">
                    <LAGridContainer vAlign="center">
                        <LATabSelector wPercent={46} layouts={{ pl: { sm: 1 } }} items={periodItems} value={searchPeriodType.state} onChange={searchPeriodType.handleChange} />
                        <LADatePicker wPercent={17} layouts={{ ml: { sm: 1 } }} value={searchStartDate.state} onChange={searchStartDate.handleChange} />
                        <LALabel wPercent={1}>~</LALabel>
                        <LADatePicker wPercent={17} value={searchEndDate.state} onChange={searchEndDate.handleChange} />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="직접 입력">
                    <LAGridContainer>
                        <LAComboBox 
                            wPercent={15} 
                            items={[
                                { key: 0, value: '통합검색' },
                            ]} 
                            value={searchTargetType.state} 
                            onChange={searchTargetType.handleChange} 
                        />
                        <LAInput wPercent={80} layouts={{ pl: { sm: 1 } }} value={searchText.state} onChange={searchText.handleChange} onEnterKeyPressed={onEnterKeyAtSearch} />
                    </LAGridContainer>
                </LAProperty>

                <LAGridContainer layouts={{ pt: 2 }}>
                    <LALabel wPercent={25}></LALabel>
                    <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onSearchClick}>검색</LAButton>
                    <LAButton wPercent={15} layouts={{ pl: { sm: 1 } }} variant={'outlined'} onClick={onInitClick}>초기화</LAButton>
                    <LAButton wPercent={15} layouts={{ pl: { sm: 1 } }} onClick={onRegisterClick}>신규 등록</LAButton>
                </LAGridContainer>
            </LACard>
            {/* End Search Area */}

            <LACard layouts={{ pt: { sm: 1 } }}>
                <LAGridContainer>
                    <LADataTable columns={columns} rows={rows} />
                </LAGridContainer>
            </LACard>

            <AllianceRegistModal
                show={allianceRegistModal.state}
                handleClose={onRegistClosed}
                handleSelect={allianceRegistModal}
            />
            <AllianceDetailModal
                allianceSeq={selectedAllianceSeq}
                show={allianceDetailModal.state}
                handleClose={onDetailClosed}
                handleSelect={allianceDetailModal}
            />
        </>
    );
}

export default AllianceManagement;
