import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/feedbacks';

const FeedbackApi = (requestFunc) => {
  return {
    Feedback: {
      getFeedbackList: {
        key: 'getFeedbackList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/recent`, params),
      },      
      getFeedbacks : {
        key: 'getClassmateFeedbacks',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      getFeedbackDetail: {
        key: 'getFeedbackDetail',
        queryFn: (feedbackSeq) => requestFunc(METHOD.GET, `${baseURL}/${feedbackSeq}`),
      },  
      classroomFeedbacks: {
        key: 'classroomFeedbacks', // 수업 피드백 목록 조회
        queryFn: (params) =>
          requestFunc(
            METHOD.GET,
            `/classrooms/${params.classroom_seq}/feedbacks`,
            params,
          ),
      },
      addClassroomFeedback: {
        key: 'addClassroomFeedback', // 수업 피드백 작성
        queryFn: (params) =>
          requestFunc(
            METHOD.POST,
            `/classrooms/${params.classroom_seq}/feedbacks`,
            params.data,
          ),
      }, 
      updateFeedback: {
        key: 'updateFeedback', // 수업 피드백 수정
        queryFn: (params) =>
          requestFunc(
            METHOD.PATCH,
            `${baseURL}/${params.feedback_seq}`,
            params.data,
          ),
      },
      deleteFeedback: {
        key: 'deleteFeedback', // 수업 피드백 삭제
        queryFn: (params) =>
          requestFunc(METHOD.DELETE, `${baseURL}/${params.feedback_seq}`),
      },
      addReplyFeedback: {
        key: 'addReplyFeedback', // 수업 피드백 작성
        queryFn: (params) =>
          requestFunc(
            METHOD.POST,
            `${baseURL}/${params.feedback_seq}/reply`,
            params.data,
          ),
      },
      downloadFeedbackFiles: {
        key: 'downloadFeedbackFiles', // 수업 피드백 - 첨부파일 다운로드
        queryFn: (params) =>
          requestFunc(
            METHOD.GET,
            `/feedbacks/${params.feedback_seq}/download-attachment`,
          ),
      },
    },
  };
};

export default FeedbackApi;
