import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import OrderHistoryTable from '../components/OrderHistoryTable';
import OrderRefundHistoryTable from '../components/OrderRefundHistoryTable';
import OrderRefundDetailModal from '../components/OrderRefundDetailModal';

import useCommonQuery from 'common/hooks/useCommonQuery';
import { useApiContext } from 'common/context/ApiContext';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import moment from 'moment';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LASimpleTable,
} from 'modules/Widgets';

function OrderRefundDetail({ orderSeq, onClickList, refundComplete = false }) {
  const { queries } = useApiContext();

  //   const { orderSeq } = useParams();
  const [data, setData] = useState(null);

  const { showAlert, showConfirm } = usePopAlert();

  //   const navigate = useNavigate();
  //   const navigateBack = () => {
  //     navigate(-1, { replace: true });
  //   };

  const { result: resultData, request: requestDetail } = useCommonQuery({
    query: queries.Order.getOrderDetail,
    params: orderSeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setData(data.result_data);
        } else {
          showAlert('알림', data.result_message, {
            confirmHandler: () => onClickList(),
          });
        }
      },
      onError: (error) => {
        showAlert('알림', '결제 정보 조회에 실패하였습니다.', {
          confirmHandler: () => onClickList(),
        });
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    requestDetail();
  }, [data.order.order_status]);

  return (
    <>
      <LACard>
        <LACardTitle title="결제 정보" />

        <LAProperty name="결제번호">
          <LALabel>{data && data.payment ? data.payment.imp_uid : ''}</LALabel>
        </LAProperty>
        <LAProperty name="결제일시">
          <LALabel>
            {data && data.payment
              ? moment(data.payment.created_dt).format(
                  'YYYY-MM-DD (dd) A hh:mm',
                )
              : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제자 명">
          <LALabel>
            {data && data.payment ? data.payment.buyer_name : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제자 연락처">
          <LALabel>
            {data && data.payment ? data.payment.buyer_tel : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="이메일">
          <LALabel>
            {data && data.payment ? data.payment.buyer_email : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제 금액">
          <LALabel>
            {data && data.order ? `${data.order.payment_price}원` : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제 수단">
          <LALabel></LALabel>
        </LAProperty>
      </LACard>
      <LACard next>
        <LACardTitle title="결제 내역2" />
        <OrderHistoryTable
          orderInfo={{
            classType: data && data.class ? data.class.class_type_name : '',
            className: data && data.class ? data.class.class_name : '',
            textbookName: '',
            classStartDate: '',
            classRound: '',
            textbookCount: '',
            textbookYn: '',
            approvalYn: '',
            payPrice: data && data.order ? `${data.order.order_price}원` : '',
            textbookPrice: '-',
            savedMoney:
              data && data.order ? `${data.order.use_earned_point}` : '',
            deliveryFee: '0원',
            totalPrice:
              data && data.order ? `${data.order.payment_price}원` : '',
            payMethod: '',
            payStatus:
              data && data.order
                ? (data.order.order_status === 10)
                  ? '결제완료'
                  : (data.order.order_status === 21)
                  ? '취소대기'
                  : (data.order.order_status === 20)
                  ? '결제취소'
                  : (data.order.order_status === 3)
                  ? '결제실패'
                  : '기타'
                : '정보없음',
            statusButtonText: ' ',
            deliveryButtonText: ' ',
            cancelButtonOnClick: () => {},
            deliveryButtonOnClick: () => {},
          }}
        />
      </LACard>

      {refundComplete && (
        <LACard next>
          <LACardTitle title="결제 취소 내역" />

          {/* <OrderRefundHistoryTable
            refundInfo={refundInfo}
            refundItems={refundItems}
          />
          <LAGridContainer pt={1}>
            <OrderRefundPriceListView
              refundInfo={refundInfo}
              refundItems={refundItems}
            />
          </LAGridContainer> */}
          <LAProperty name="취소 사유">
            <LALabel>단순 변심</LALabel>
          </LAProperty>
          <LAProperty name="취소 상세 사유">
            <LALabel>-</LALabel>
          </LAProperty>
        </LACard>
      )}

      {refundComplete && (
        <LACard next>
          <LACardTitle title="환불 정보" />

          <LAProperty name="환불 수단">
            <LALabel>카카오</LALabel>
          </LAProperty>
        </LACard>
      )}

      <LACard next>
        <LACardTitle title="학습자 정보" />

        <LAProperty name="학습자 명">
          <LALabel>{data && data.user ? data.user.user_name : ''}</LALabel>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="배송지 정보" />

        <LAProperty name="받는 분">
          <LALabel>
            {data && data.delivery ? data.delivery.receiver_name : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="휴대폰 번호">
          <LALabel>
            {data && data.delivery ? data.delivery.receiver_phone_no : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="전화번호">
          <LALabel>
            {data && data.delivery ? data.delivery.receiver_landline_no : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="받는 주소">
          <LALabel>
            {data && data.delivery
              ? `${data.delivery.address_road}, ${data.delivery.address_detail}`
              : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="배송 요청사항">
          <LALabel>
            {data && data.delivery ? data.delivery.remarks : ''}
          </LALabel>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="처리 내역" />
        <LAGridContainer pt={2}>
          {/* <LASimpleTable tableData={processHistoryTableData} /> */}
        </LAGridContainer>
      </LACard>

      {/* <LACard next>
        <LACardTitle title="관리 메모" />

        <LAProperty name="관리 메모">
          <LAGridContainer>
            <LAInput
              wPercent={80}
              placeholder="관리 메모 입력"
              layouts={{ pr: { sm: 1 } }}
            />
            <LAButton wPercent={20}>등록</LAButton>
          </LAGridContainer>
        </LAProperty>
        <LAGridContainer pt={1}>
          <LASimpleTable tableData={managementMemoTableData} />
        </LAGridContainer>
      </LACard> */}

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={43}></LALabel>
        <LAButton
          wPercent={14}
          layouts={{ mr: 1 }}
          variant={'outlined'}
          btnPaddingY={12}
          onClick={onClickList}
        >
          목록
        </LAButton>
      </LAGridContainer>
    </>
  );
}

export default OrderRefundDetail;
