import React, { useCallback, useMemo } from 'react';
import CommentDefatul from './CommentDefatul';
import CommentReply from './CommentReply';

// CommentList.js

function CommentList({
  userName,
  comment,
  //   clickHandler,
  handleRegist,
  handleReply,
  handleUpdate,
  handleDelete,
}) {
  
  return (
    <li>
      {comment && (comment.isReply || comment.isTemp) ? (
        <CommentReply
          userName={userName}
          comment={comment}
          handleRegist={handleRegist}
          handleReply={handleReply}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      ) : (
        <CommentDefatul
          userName={userName}
          comment={comment}
          handleRegist={handleRegist}
          handleReply={handleReply}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      )}
    </li>
  );
}

export default React.memo(CommentList);
