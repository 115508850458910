import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from "moment"
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LALabel,
  LAButton,
  LASimpleTable,
  useModal,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';


import AddBannerListModal from './AddBannerListModal';
import {sendCacheRefresh} from "../../../../common/utils";

function MainBannerManagementModal({ show, handleClose ,bannerType}) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [banners, setBanners] = useState([]);  
  const addBannerListModal = useModal(false);

  
  const moveUp = useCallback((exposureNo) => {
    console.log("Up : ", exposureNo);
    if(exposureNo == 1) return;

    let newBanners;
    if(exposureNo < 3)
      newBanners = [];
    else 
      newBanners = banners.slice(0, exposureNo-2);

    console.log(newBanners);
    
    newBanners.push(banners[exposureNo-1]);
    newBanners.push(banners[exposureNo-2]);

    banners.forEach((banner, index) => {
      if(index > exposureNo-1) {
        newBanners.push(banner);
      }
    });

    console.log(newBanners);
    setBanners(newBanners);

  }, [banners]);

  const moveDown = useCallback((exposureNo) => {
    console.log("Down : ", exposureNo);
    if(exposureNo == banners.length)
      return;
    let newBanners;
    if(exposureNo-1 == 0)
      newBanners = [];
    else 
      newBanners = banners.slice(0, exposureNo-1);

    console.log(newBanners);
    newBanners.push(banners[exposureNo]);
    newBanners.push(banners[exposureNo-1]);

    banners.forEach((banner, index) => {
      if(index > exposureNo) {
        newBanners.push(banner);
      }
    });

    console.log(newBanners);

    setBanners(newBanners);
  }, [banners]);

  const deleteBanner = useCallback((exposureNo) => {
    console.log("Delete : ", exposureNo);
    if(!exposureNo) return;
    let newBanners = banners.slice(0, exposureNo-1);
    console.log(newBanners);
    banners.forEach((banner, index) => {
      if(index > exposureNo-1) {
        newBanners.push(banner);
      }
    });

    setBanners(newBanners);
  }, [banners]);

  const getRows = useCallback(() => {
    let rows = [];
    if(banners && banners.length > 0) {
      banners.forEach((banner, index) => {
        banner.exposure_no = index + 1;
        rows.push([
          {
            text: banner.banner_seq,
          },
          {
            text: banner.banner_title,
            align: 'left',
          },
          {
            text: banner.banner_link_url,
            align: 'left',
          },
          {
            text: getReservationText(banner),            
          },
          {
            text: banner.exposure_no,
          },
          {
            text: '▲',
            type: 'button',
            variant: 'outlined',
            callback: {
              onClick: ()=> moveUp(banner.exposure_no)
            },
          },
          {
            text: '▼',
            type: 'button',
            variant: 'outlined',
            callback: {
              onClick: ()=> moveDown(banner.exposure_no)
            },
          },
          {
            text: '삭제',
            type: 'button',
            callback: {
              onClick: ()=> deleteBanner(banner.exposure_no)
            },
          }
        ])
      });
    }
    return rows;
  }, [banners]);

  const { request: requestBanners } = useCommonQuery({
    query: queries.MainExhibition.getExhibitionBanners,
    params:{bannerType:bannerType},
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setBanners(data.result_data);
        } else {
          setBanners([]);
          if(data.result_code != '9001')
            showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        console.log(error);
        showAlert('알림', '메인 배너 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: modifyBanners } = useCommonMutation({
    query: queries.MainExhibition.modifyExhibitionBanners,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          requestBanners();
          showAlert('알림', '저장되었습니다.', {
            confirmHandler: () => {
              requestBanners();
            }
          })
          if (bannerType == 2)
            /**CACHE REFRESH : 탑배너 갱신*/
            sendCacheRefresh( '/v1/api/main/topBanner' );
          else
            /**CACHE REFRESH : MAIN 갱신*/
            sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '저장에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (show) {
      console.log('MainBannerManagementModal.request:',{bannerType:bannerType})
      requestBanners();
    }
  }, [show]);

  const onAddClick = useCallback(() => {
    addBannerListModal.visible(true);
  }, [ ])

  const onSaveClick = useCallback(() => {
    showConfirm('알림', '변경사항을 저장하시겠습니까?', {
      confirmHandler: () => {
        console.log(banners)
        modifyBanners({bannerType:bannerType,items: banners})
      }
    });
  }, [banners])

  const getReservationText = useCallback((banner) =>{
    if(banner.reservation_yn === 1){
      let startDate = moment(banner.reservation_start_dt).format('YYYY.MM.DD HH:mm')
      let endDate = moment(banner.reservation_end_dt).format('YYYY.MM.DD HH:mm')
      return `${startDate}\n ~ ${endDate}`
    }else{
      return '즉시'
    }
  })
  const bannerData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '링크',
        },
        {
          text: '예약 노출',
        },
        {
          text: '순서',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '삭제',
        },
      ],
      wPercentList: [5, 21, 21,20, 8, 8, 8, 8],
      rows: getRows(),
    };
 
  }, [banners]);
  
  const addMainBanner = useCallback((banner) => {
    console.log(banner);
    let isNew = true;
    if(banners) {
      banners.forEach((oBanner) => {
        if(oBanner.banner_seq == banner.banner_seq) {
          isNew = false;
        }
      })
    }

    if(isNew) {
      let newBanners = JSON.parse(JSON.stringify(banners));
      newBanners.push(banner);
      setBanners(newBanners);
    }

    addBannerListModal.handleClose();
  }, [addBannerListModal.state, banners]);

  const getTitle = useMemo(() => {
    var title = ''
    switch(bannerType){
      case 1:
        title = '상단 배너'
        break;
      case 2:
        title = '상단 띠배너'
        break;
      case 3:
        title = '중간 띠배너'
        break;
      case 4:
        title = '바닥 배너'
        break;
      case 5:
        title = '플로팅 배너'
        break;
      case 6:
          title = '팝업 배너'
          break;
      default:
        title = '배너'
        break;
    }
    
      title += ' 노출 관리'
    
    return title
  }, [bannerType]);

  return (
    <LAModal
      title={getTitle}
      width="70%"
      height="75%"
      show={show}
      onClose={handleClose}
    >
      <LACard>        
        <LAGridContainer layouts={{ pt: 2 }}>
          <LASimpleTable tableData={bannerData} />
        </LAGridContainer>
      </LACard>

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton 
          wPercent={15} 
          layouts={{ ml: 1 }}
          variant={'outlined'}
          onClick={onAddClick}
        >
          배너 추가
        </LAButton>
        <LAButton 
          wPercent={15} 
          layouts={{ ml: 1 }}
          onClick={onSaveClick}
        >
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>

      {/* <addBannerListModal
        show={addBannerListModal.state} 
        handleClose={addBannerListModal.handleClose} 
        addMainBanner={addMainBanner}
        registeredBanner={banners}
        bannerType={bannerType}
      /> */}


      <AddBannerListModal
        show={addBannerListModal.state} 
        handleClose={addBannerListModal.handleClose} 
        selectedItems={addMainBanner}
        registeredBanner={banners}
        bannerType={bannerType}
      />

    </LAModal>
  );  
}

export default MainBannerManagementModal;
