import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LAGridContainer,
  LASimpleTable,
  LAWarningLabel,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';
import moment from 'moment';

function AddMagazineModal({
  show,
  handleClose,
  addMainMagazine,
  registeredMagazine,
  addItemCallback
}) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [magazineInfo, setMagazineInfo] = useState([]);
  const [magazineCount, setMagazienCount] = useState(0);

  
  const getMagazineRows = useCallback(() => {
    let rowData = [];
    if (magazineInfo && magazineInfo.length > 0) {
      
      console.log('AddMagazineModal.registeredMagazine => ',registeredMagazine)

      magazineInfo.forEach((magazine) => {
        let canPush = !registeredMagazine?.some(c => c.magazine_seq == magazine.magazine_seq);      
        if (canPush) {
          rowData.push([
            {
              text: magazine.magazine_seq,
            },
            {
              text: magazine.magazine_title,
            },
            {
              text: moment(magazine.created_dt).format(
                'YYYY-MM-DD (dd) A hh:mm',
              ),
            },
            {
              text: '추가',
              type: 'button',
              callback: {
                onClick: () => addItemCallback(magazine),
              },
            },
          ]);
        }
      });
    }
    setMagazienCount(rowData.length);
    return rowData;
  }, [magazineInfo, registeredMagazine,addItemCallback]);

  const { request: requestMainMagazine } = useCommonMutation({
    query: queries.Magazine.getOnlyExposuredMagazines,
    callbacks: {
      onSuccess: (data) => {
        console.log(data);
        if (data.result_code === '0000') {
          setMagazineInfo(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '배너 조회에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (show) requestMainMagazine();
  }, [show]);

  const onAddClick = useCallback(() => {}, []);

  const onRegistClick = useCallback(() => {}, []);

  const magazineData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '등록일',
        },
        {
          text: '',
        },
      ],
      wPercentList: [10, 60, 20, 10],
      rows: getMagazineRows(),
    };
  }, [magazineInfo,registeredMagazine]);

  return (
    <LAModal
      title="메인 매거진 추가"
      width="70%"
      height="85%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LAGridContainer layouts={{ pt: 2 }}>
          <LASimpleTable tableData={magazineData} />
        </LAGridContainer>
        {!magazineCount ? (
          <LAWarningLabel>추가할 수 있는 매거진이 없습니다.</LAWarningLabel>
        ) : (
          <></>
        )}
      </LACard>
    </LAModal>
  );
}

export default AddMagazineModal;
