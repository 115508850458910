import moment from 'moment';
import 'moment/locale/ko';
import {DOMAIN} from "../../api/AppConfig";

// 클래스 일정 수정화면에서 추가된 일정과의 중복 체크
export function getDuplicateClassSchedules(scheduledList, desireSchedule){
  let duplicatedMessage = '';
  if(scheduledList.length > 0) {
    scheduledList.forEach((schedule) => {
      schedule.dates.forEach((timetable) => {
        let scheduledStartTime = moment((timetable.date||timetable) + (timetable.time||schedule.startTime), 'YYYY-MM-DDHH:mm')
        let scheduledFinishTime = scheduledStartTime.clone().add(schedule.classTime, 'm');
        // 일정 수정시 동일한 스케쥴에 대해서는 체크하지 않음.
        if(desireSchedule.scheduleSeq && (desireSchedule.scheduleSeq === schedule.scheduleSeq)) return; 

        desireSchedule.dates.forEach(desireTimetable => {
          // 일정 수정시 동일한 타임테이블에 대해서는 체크하지 않음.
          if(desireTimetable.timetableSeq && (desireTimetable.timetableSeq === timetable.timetableSeq)) return; 
          let desireStartTime = moment((desireTimetable.date||desireTimetable) + (desireTimetable.time||desireSchedule.startTime), 'YYYY-MM-DDHH:mm');
          let desireFinishTime = desireStartTime.clone().add(desireSchedule.classTime, 'm');
          
          if(desireStartTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]') || desireFinishTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]')){
            duplicatedMessage += `${schedule.className?'['+schedule.className+']\n':''}${moment(scheduledStartTime).format('YYYY-MM-DD HH:mm')} ~ ${moment(scheduledFinishTime).format('YYYY-MM-DD HH:mm')}\n`;
          }
        });
      });
    });    
  }
  return duplicatedMessage;
}

/**
 * CACHE REFRESH
 * @param url
 */
export function sendCacheRefresh(url) {
  let fullUrl = url?.indexOf('?')>0?url + '&cacheRefresh=Y' : url + '?cacheRefresh=Y';
  console.log('sendCacheRefresh fullUrl', fullUrl)
  fetch(DOMAIN+fullUrl)
      .then((res)=>console.log('response', res.status))
      .catch((err)=>console.log('error', err));
}