import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/calendar';

const CalendarApi = (requestFunc) => {
  return {
    Calendar: {
      // 인증 : accessToken 으로 로그인 진행
      getCalendar: {
        key: 'getCalendar',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },      
    },
  };
};

export default CalendarApi;
