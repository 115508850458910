import React, { useCallback } from 'react';

import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
    LACard,
    LAGridContainer,
    LAProperty,
    LALabel,
    LARadioGroup,
    LAButton,
    LAInput,
    useRadioGroup,
    useInput,
} from 'modules/Widgets';

function AllianceRegistContents({ onClickList }) {
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();

    const nameInput = useInput('');
    const codeInput = useInput('');
    const managerInput = useInput('');
    const managerPhoneInput = useInput('', 0, "number");
    const managerEmailInput = useInput('');
    const corpCodeInput = useInput('', 0, "number");
    const corpNameInput = useInput('');
    const corpNumberInput = useInput('', 0, "number"); // 기업용 고객코드
    const proxyCommission = useInput('', 0, "number"); // 대행 수수료
    const corpPresidentInput = useInput('');
    const bankNameInput = useInput('');
    const bankNumberInput = useInput('', 0, "number");
    const bankAccountInput = useInput('');
    const useType = useRadioGroup(1);

    const { request: requestRegistAlliance } = useCommonMutation({
        query: queries.Alliance.regist,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '제휴/협력사가 등록되었습니다.', {confirmHandler: onClickList});
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '제휴/협력사 등록에 실패했습니다.');
            },
        },
    });

    const onSaveClick = useCallback(() => {
        if (!nameInput.state || nameInput.state.length < 2) {
            showAlert('알림', '제휴/협력사 이름을 2자 이상 입력하세요.');
            return;
        }

        if (!codeInput.state || codeInput.state.length < 2) {
            showAlert('알림', '제휴/협력사 코드를 2자 이상 입력하세요.');
            return;
        }

        showConfirm('알림', '제휴/협력사 정보를 저장하시겠습니까?', {
            confirmHandler: () => {
                requestRegistAlliance({
                    alliance_name: nameInput.state,
                    alliance_code: codeInput.state,
                    manager_name: managerInput.state,
                    manager_phone: managerPhoneInput.state,
                    manager_email: managerEmailInput.state,
                    use_yn: useType.state,
                    corp_code: corpCodeInput.state,
                    corp_name: corpNameInput.state,
                    corp_number: corpNumberInput.state,
                    commission_rate: proxyCommission.state, // 대행 수수료
                    corp_president: corpPresidentInput.state,
                    bank_name: bankNameInput.state,
                    bank_number: bankNumberInput.state,
                    bank_account: bankAccountInput.state,
                });
            }, 
        });
    }, [
        nameInput.state,
        codeInput.state,
        managerInput.state,
        managerPhoneInput.state,
        managerEmailInput.state,
        useType.state,
        corpCodeInput.state,
        corpNameInput.state,
        corpNumberInput.state,
        proxyCommission.state,
        corpPresidentInput.state,
        bankNameInput.state,
        bankNumberInput.state,
        bankAccountInput.state,
        showAlert,
    ]);

    return (
        <>
            {/* Start Info Area */}
            <LACard>
                {/* <LACardTitle title="제휴/협력사 정보" content="" /> */}
                <LAProperty name="제휴/협력사 코드" wNameWidth={20} required>
                    <LAInput wPercent={90} placeholder="코드을 입력해주세요." value={codeInput.state} onChange={codeInput.handleChange} />
                </LAProperty>

                <LAProperty name="제휴/협력사 이름" wNameWidth={20} required>
                    <LAInput wPercent={90} placeholder="이름을 입력해주세요." value={nameInput.state} onChange={nameInput.handleChange} />
                </LAProperty>

                <LAProperty name="담당자 이름" wNameWidth={20}>
                    <LAGridContainer>
                        <LAInput wPercent={90} placeholder="담당자 이름을 입력해주세요." value={managerInput.state} onChange={managerInput.handleChange} />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="담당자 전화번호" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="담당자 전화번호를 입력해주세요." value={managerPhoneInput.state} onChange={managerPhoneInput.handleChange} />
                </LAProperty>

                <LAProperty name="담당자 이메일" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="담당자 이메일을 입력해주세요." value={managerEmailInput.state} onChange={managerEmailInput.handleChange} />
                </LAProperty>

                <LAProperty name="상호" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="상호를 입력해주세요." value={corpNameInput.state} onChange={corpNameInput.handleChange} />
                </LAProperty>

                <LAProperty name="사업자 등록 번호" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="사업자 등록 번호를 입력해주세요." value={corpNumberInput.state} onChange={corpNumberInput.handleChange} />
                </LAProperty>

                <LAProperty name="대표자명" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="대표자명을 입력해주세요." value={corpPresidentInput.state} onChange={corpPresidentInput.handleChange} />
                </LAProperty>

                {/*2023-02-24 기업용 고객 코드 추가*/}
                <LAProperty name="기업용 고객 코드" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="기업용 고객 코드를 입력해주세요." value={corpCodeInput.state} onChange={corpCodeInput.handleChange} />
                </LAProperty>

                {/*2023-03-17 대행 수수료 추가*/}
                <LAProperty name="대행 수수료" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="수수료율을 입력하세요. (숫자만 입력)" value={proxyCommission.state} onChange={proxyCommission.handleChange} />
                </LAProperty>

                <LAProperty name="은행명" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="은행명을 입력해주세요." value={bankNameInput.state} onChange={bankNameInput.handleChange} />
                </LAProperty>

                <LAProperty name="계좌번호" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="계좌번호를 입력해주세요." value={bankNumberInput.state} onChange={bankNumberInput.handleChange} />
                </LAProperty>

                <LAProperty name="예금주" wNameWidth={20}>
                    <LAInput wPercent={90} placeholder="예금주를 입력해주세요." value={bankAccountInput.state} onChange={bankAccountInput.handleChange} />
                </LAProperty>

                <LAProperty name="사용 여부" wNameWidth={20}>
                    <LARadioGroup
                        props={[
                            {text: '사용 안함', value: 0},
                            {text: '사용', value: 1},
                        ]}
                        value={useType.state}
                        onChange={useType.handleChange}
                    />
                </LAProperty>
            </LACard>
            {/* End Info Area */}

            <LAGridContainer layouts={{ pt: 3 }}>
                <LALabel wPercent={35}></LALabel>
                <LAButton wPercent={15} layouts={{ mr: 1 }} variant={'outlined'} btnPaddingY={12} onClick={onClickList}>목록</LAButton>
                <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onSaveClick}>저장</LAButton>
            </LAGridContainer>
        </>
    );
}

export default AllianceRegistContents;
