import React, { useState, useCallback, useMemo, useEffect } from "react";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import pxToRem from "assets/theme/functions/pxToRem";

import { useLoginContext } from "common/context/MemberContext";
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import { API_BASE_URL } from "api/AppConfig";

import moment from "moment";

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAComboBox,
  LAWarningLabel,
  useComboBox,
  LADivider,
  LAGridItem,
} from "modules/Widgets";
import DownloadReasonModal from "pages/Order/components/DownloadReasonModal";
import DownloadListModal from "pages/Order/components/DownloadListModal";
import { useModal } from "modules/Widgets";

function addComma(input) {
  if (input) {
    const parts = input.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else return 0;
}

function CalculateSettlementManagement() {
  const { isLogin, isTeacher, isAlliance, loginState, action } =
    useLoginContext();
  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  const downloadReasonModal = useModal(false);
  const downloadListModal = useModal(false);

  const navigate = useNavigate();

  const queryDate = useMemo(() => moment().add(-1, "M"));

  const thisYear = useMemo(() => queryDate.format("YYYY"), []);
  const thisMonth = useMemo(() => queryDate.format("MM"), []);

  const yearType = useComboBox(thisYear);
  const monthType = useComboBox(thisMonth);

  const { showAlert, showConfirm } = usePopAlert();

  const { queries } = useApiContext();

  const [totalScheduleCount, setTotalScheduleCount] = useState(0);

  const [classInfo, setClassInfo] = useState([]);
  const [statementInfo, setStatementInfo] = useState([]);
  const [penaltyInfo, setPenaltyInfo] = useState([]);
  const [addInfo, setAddInfo] = useState([]);
  const [bankInfo, setBankInfo] = useState(undefined);
  const [supplyInfo, setSupplyInfo] = useState(undefined);

  const [addPrice, setAddPrice] = useState(0); // 총 보전 금액
  const [penaltyPrice, setPenaltyPrice] = useState(0); // 총 패널티 금액

  const [isDownloadable, setIsDownloadable] = useState(false);

  const onClickSearch = useCallback(() => {
    requestSettlement();
  }, []);

  const showReceiptPopup = useCallback(() => {
    window.open(
      `${API_BASE_URL}/admin/settlement/statement/receipt/frame/${yearType.state}${monthType.state}?memberSeq=${loginState.member_seq}`,
      "_blank"
    );
  }, [yearType, monthType]);

  const resetData = useCallback(() => {
    setClassInfo(undefined);
    setStatementInfo(undefined);
    setPenaltyInfo(undefined);
    setAddInfo(undefined);
    setBankInfo(undefined);
    setSupplyInfo(undefined);
  }, []);

  useEffect(() => {
    requestSettlement();
  }, []);

  useEffect(() => {
    let _totalScheduleCount = 0;
    if (classInfo) {
      classInfo.forEach((classData) => {
        _totalScheduleCount += classData.cnt;
      });
    }
    setTotalScheduleCount(_totalScheduleCount);
  }, [classInfo]);

  const { result: resultData, request: requestSettlement } = useCommonQuery({
    query: queries.Calculate.getSettlement,
    params: {
      yyyymm: yearType.state + monthType.state,
      memberSeq: loginState.member_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code == "0000") {
          setClassInfo(data.classInfo);
          setStatementInfo(data.statementInfo);
          setPenaltyInfo(data.penaltyInfo);
          setAddInfo(data.addInfo);
          setBankInfo(data.bankInfo);
          setSupplyInfo(data.supplyInfo);
          setIsDownloadable(true);
        } else if (data.result_code == "9001") {
          setIsDownloadable(false);
        } else {
          resetData();
          setIsDownloadable(false);
          if (data.result_code == "401") {
            showAlert("알림", "권한이 없습니다.", {
              confirmHandler: () => {
                navigate("/");
              },
            });
          }

          if (data.result_code.result_message)
            showAlert("알림", data.result_code.result_message);
        }
      },
      onError: (error) => {
        resetData();
        showAlert("알림", "목록 조회에 실패하였습니다.");
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    if (addInfo) {
      addInfo.forEach((addData, idx) => {
        addPrice += addData[idx].amount;
      });
    }
    setAddPrice(addPrice);
  }, [addPrice]);

  useEffect(() => {
    if (penaltyInfo) {
      penaltyInfo.forEach((penaltyData, idx) => {
        penaltyPrice += penaltyData[idx].amount;
      });
    }
    setPenaltyPrice(penaltyPrice);
  }, [penaltyPrice]);

  const [downKey, setDownKey] = useState("");
  const clickDownload = (order) => {
    if (!isDownloadable) {
      showAlert("알림", "거래 명세서 승인 후 확인하실 수 있습니다");
      return;
    }

    if (order === "클래스별 수수료 내역") setDownKey("classDetailPayout");
    else if (order === "선생님별 수수료 내역")
      setDownKey("teacherDetailPayout");

    downloadReasonModal.visible(true);
  };

  const clickDownloadList = () => {
    downloadListModal.visible(true);
  };

  return (
    <>
      <DownloadReasonModal
        show={downloadReasonModal.state}
        handleClose={downloadReasonModal.handleClose}
        downKey={downKey}
        yyyymm={`${yearType.state}${monthType.state}`}
      />
      <DownloadListModal
        show={downloadListModal.state}
        handleClose={downloadListModal.handleClose}
        type={"SETTLEMENT"}
      />

      <DashboardNavbar title="정산 금액 확인" />
      <LACard>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: "2022", value: "2022년" },
                { key: "2023", value: "2023년" },
                { key: "2024", value: "2024년" },
                { key: "2025", value: "2025년" },
              ]}
              value={yearType.state}
              onChange={yearType.handleChange}
            />
            <LAComboBox
              layouts={{ pl: { sm: 1 } }}
              wPercent={15}
              items={[
                { key: "01", value: "1월" },
                { key: "02", value: "2월" },
                { key: "03", value: "3월" },
                { key: "04", value: "4월" },
                { key: "05", value: "5월" },
                { key: "06", value: "6월" },
                { key: "07", value: "7월" },
                { key: "08", value: "8월" },
                { key: "09", value: "9월" },
                { key: "10", value: "10월" },
                { key: "11", value: "11월" },
                { key: "12", value: "12월" },
              ]}
              value={monthType.state}
              onChange={monthType.handleChange}
            />
            <LAButton
              wPercent={10}
              layouts={{ ml: { sm: 1 } }}
              onClick={onClickSearch}
            >
              조회하기
            </LAButton>
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LAGridContainer>
        <Grid sm={8.4} mr={0.5}>
          <MDBox component="form" pt={2}>
            <LACard>
              <LACardTitle title="정산 금액 확인" />
              <LAGridContainer
                layouts={{
                  mt: 1,
                  sx: {
                    border: "1px solid #dddddd",
                    borderRadius: 1,
                    overflow: "auto",
                  },
                }}
              >
                <LAGridContainer
                  vAlign="center"
                  layouts={{
                    sx: {
                      backgroundColor: "#eeeeee",
                      borderBottom: "1px solid #dddddd",
                    },
                  }}
                >
                  <LALabel
                    wPercent={50}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    수업 내역
                  </LALabel>
                  <LALabel
                    wPercent={10}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    건수
                  </LALabel>
                  <LALabel
                    wPercent={15}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    수수료 금액
                    <br />
                    (VAT 포함)
                  </LALabel>
                  <LALabel
                    wPercent={25}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    비고
                  </LALabel>
                </LAGridContainer>

                {classInfo &&
                  classInfo?.map((classData, index) => (
                    <LAGridContainer
                      key={`class-${index}`}
                      vAlign="center"
                      layouts={{
                        sx: { borderBottom: "1px solid #dddddd" },
                        pb: 0.3,
                      }}
                    >
                      <LALabel
                        wPercent={15}
                        layouts={{
                          sx: { textAlign: "center", fontWeight: "regular" },
                        }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        {classData.class_type}
                      </LALabel>
                      <LALabel
                        wPercent={35}
                        layouts={{
                          px: 1,
                          sx: { textAlign: "left", fontWeight: "regular" },
                        }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        {classData.class_name}
                      </LALabel>
                      <LALabel
                        wPercent={10}
                        layouts={{
                          sx: { textAlign: "center", fontWeight: "regular" },
                        }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        {addComma(classData.cnt)}건
                      </LALabel>
                      <LALabel
                        wPercent={15}
                        layouts={{
                          px: 2,
                          sx: { textAlign: "right", fontWeight: "regular" },
                        }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        {addComma(classData.amount)}
                      </LALabel>
                      <LALabel
                        wPercent={25}
                        layouts={{
                          sx: { textAlign: "left", fontWeight: "regular" },
                        }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        {classData.etc}
                      </LALabel>
                    </LAGridContainer>
                  ))}

                <LAGridContainer
                  vAlign="center"
                  layouts={{
                    sx: {
                      backgroundColor: "#eeeeee",
                      borderBottom: "1px solid #dddddd",
                    },
                  }}
                >
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      총 매출액(실수업분)
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={35}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      총 카드 결제 금액
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      총 쿠폰 결제 금액
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      총 올머니 결제 금액
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      총 클럽 포인트 결제 금액
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      총 클럽 마일리지 결제 금액
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={10}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      {statementInfo?.total_pg_amount > 0
                        ? addComma(statementInfo.total_pg_amount)
                        : "-"}
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      {statementInfo?.total_coupon_price_amount > 0
                        ? addComma(statementInfo.total_coupon_price_amount)
                        : "-"}
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      {statementInfo?.total_earned_point_amount > 0
                        ? addComma(statementInfo.total_earned_point_amount)
                        : "-"}
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      {statementInfo?.total_bookclub_point_amount > 0
                        ? addComma(statementInfo.total_bookclub_point_amount)
                        : "-"}
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      {statementInfo?.total_gm_mileage_amount > 0 ||
                      statementInfo?.total_online_mileage_amount > 0
                        ? addComma(
                            statementInfo.total_gm_mileage_amount +
                              statementInfo.total_online_mileage_amount
                          )
                        : "-"}
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={25}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                </LAGridContainer>

                <LAGridContainer
                  vAlign="center"
                  layouts={{
                    sx: {
                      backgroundColor: "#e5eff7",
                      borderBottom: "1px solid #dddddd",
                    },
                  }}
                >
                  <LALabel
                    wPercent={15}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    정산 대상 금액
                  </LALabel>
                  <LALabel
                    wPercent={35}
                    layouts={{ px: 1, sx: { textAlign: "left" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    &nbsp;
                  </LALabel>
                  <LALabel
                    wPercent={10}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    {addComma(totalScheduleCount)}건
                  </LALabel>
                  <LALabel
                    wPercent={15}
                    layouts={{ px: 2, sx: { textAlign: "right" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    {statementInfo?.total_target_amount > 0
                      ? addComma(statementInfo.total_target_amount)
                      : "-"}
                  </LALabel>
                  <LALabel
                    wPercent={25}
                    layouts={{ sx: { textAlign: "left" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    &nbsp;
                  </LALabel>
                </LAGridContainer>

                <LAGridContainer
                  vAlign="center"
                  layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                >
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", vAlign: "middle" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      카드 정산
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={35}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      카드 수수료(-)
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      카드 결제 건 대행 수수료(-)
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 1,
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      카드 결제 건 정산 금액
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={10}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_pg_comm_deamount > 0
                        ? addComma(statementInfo.total_pg_comm_deamount)
                        : "-"}
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_deamount > 0
                        ? addComma(statementInfo.total_liveall_comm_deamount)
                        : "-"}
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 2,
                        sx: { textAlign: "right", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_pg_amount > 0
                        ? addComma(
                            statementInfo.total_pg_amount -
                              statementInfo.total_pg_comm_deamount -
                              statementInfo.total_liveall_comm_deamount
                          )
                        : "-"}
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={25}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      카드사별 수수료 적용
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_rate > 0
                        ? statementInfo.total_liveall_comm_rate
                        : "0"}
                      %
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                </LAGridContainer>

                <LAGridContainer
                  vAlign="center"
                  layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                >
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", vAlign: "middle" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      쿠폰 정산
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={35}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      쿠폰 결제 건 정산 대행 수수료(-)
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 1,
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      쿠폰 결제 건 정산 금액
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={10}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_coupon_price_amount > 0
                        ? addComma(
                            statementInfo.total_coupon_price_amount -
                              statementInfo.total_liveall_comm_coupon_price_deamount
                          )
                        : "-"}
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 2,
                        sx: { textAlign: "right", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_coupon_price_deamount >
                      0
                        ? addComma(
                            statementInfo.total_liveall_comm_coupon_price_deamount
                          )
                        : "-"}
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={25}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_rate > 0
                        ? statementInfo.total_liveall_comm_rate
                        : "0"}
                      %
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                </LAGridContainer>

                <LAGridContainer
                  vAlign="center"
                  layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                >
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", vAlign: "middle" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      포인트 정산
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={35}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      올머니 결제 건 대행 수수료(-)
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 1,
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      올머니 결제 건 정산 금액
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      클럽 포인트 결제 건 대행 수수료(-)
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 1,
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      클럽 포인트 결제 건 정산 금액
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={10}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_earned_point_deamount >
                      0
                        ? addComma(
                            statementInfo.total_liveall_comm_earned_point_deamount
                          )
                        : "-"}
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 2,
                        sx: { textAlign: "right", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_earned_point_amount > 0
                        ? addComma(
                            statementInfo.total_earned_point_amount -
                              statementInfo.total_liveall_comm_earned_point_deamount
                          )
                        : "-"}
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_bookclub_point_deamount >
                      0
                        ? addComma(
                            statementInfo.total_liveall_comm_bookclub_point_deamount
                          )
                        : "-"}
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 2,
                        sx: { textAlign: "right", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_bookclub_point_amount > 0
                        ? addComma(
                            statementInfo.total_bookclub_point_amount -
                              statementInfo.total_liveall_comm_bookclub_point_deamount
                          )
                        : "-"}
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={25}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_rate > 0
                        ? statementInfo.total_liveall_comm_rate
                        : "0"}
                      %
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_rate > 0
                        ? statementInfo.total_liveall_comm_rate
                        : "0"}
                      %
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                </LAGridContainer>

                <LAGridContainer
                  vAlign="center"
                  layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                >
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", vAlign: "middle" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      마일리지 정산
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={35}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      클럽 마일리지 결제 건 대행 수수료(-)
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 1,
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      클럽 마일리지 결제 건 정산 금액
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={10}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "center", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={15}>
                    <LALabel
                      wPercent={100}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_gm_mileage_deamount >
                        0 ||
                      statementInfo?.total_liveall_comm_online_mileage_deamount >
                        0
                        ? addComma(
                            statementInfo.total_liveall_comm_gm_mileage_deamount +
                              statementInfo.total_liveall_comm_online_mileage_deamount
                          )
                        : "-"}
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        px: 2,
                        sx: { textAlign: "right", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_gm_mileage_amount > 0 ||
                      statementInfo?.total_online_mileage_amount > 0
                        ? addComma(
                            statementInfo.total_gm_mileage_amount +
                              statementInfo.total_online_mileage_amount -
                              (statementInfo.total_liveall_comm_gm_mileage_deamount +
                                statementInfo.total_liveall_comm_online_mileage_deamount)
                          )
                        : "-"}
                    </LALabel>
                  </LAGridItem>
                  <LAGridItem wPercent={25}>
                    <LALabel
                      wPercent={100}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      {statementInfo?.total_liveall_comm_rate > 0
                        ? statementInfo.total_liveall_comm_rate
                        : "0"}
                      %
                    </LALabel>
                    <LADivider />
                    <LALabel
                      wPercent={100}
                      layouts={{
                        sx: { textAlign: "left", backgroundColor: "#e5eff7" },
                      }}
                      props={{ fontSize: pxToRem(12) }}
                    >
                      &nbsp;
                    </LALabel>
                  </LAGridItem>
                </LAGridContainer>

                {addInfo ? (
                  <LAGridContainer
                    vAlign="center"
                    layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                  >
                    <LAGridItem wPercent={15}>
                      <LALabel
                        wPercent={100}
                        layouts={{
                          sx: { textAlign: "center", vAlign: "middle" },
                        }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        보전 (+)
                      </LALabel>
                    </LAGridItem>
                    <LAGridItem wPercent={35}>
                      {addInfo &&
                        addInfo?.map((add, index) => (
                          <LALabel
                            key={`add-${index}`}
                            wPercent={100}
                            layouts={{ px: 1, sx: { textAlign: "left" } }}
                            props={{ fontSize: pxToRem(12) }}
                          >
                            {addComma(add.desc)}
                          </LALabel>
                        ))}
                    </LAGridItem>
                    <LAGridItem wPercent={10}>
                      <LALabel
                        wPercent={100}
                        layouts={{ sx: { textAlign: "center" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        &nbsp;
                      </LALabel>
                    </LAGridItem>
                    <LAGridItem wPercent={15}>
                      {addInfo &&
                        addInfo?.map((add, index) => (
                          <LALabel
                            key={`add-amount-${index}`}
                            wPercent={100}
                            layouts={{ px: 2, sx: { textAlign: "right" } }}
                            props={{ fontSize: pxToRem(12) }}
                          >
                            {addComma(add.amount)}
                          </LALabel>
                        ))}
                    </LAGridItem>
                    <LAGridItem wPercent={25}>
                      <LALabel
                        wPercent={100}
                        layouts={{ sx: { textAlign: "left" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        &nbsp;
                      </LALabel>
                    </LAGridItem>
                  </LAGridContainer>
                ) : (
                  <></>
                )}

                {penaltyInfo ? (
                  <LAGridContainer
                    vAlign="center"
                    layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                  >
                    <LAGridItem wPercent={15}>
                      <LALabel
                        wPercent={100}
                        layouts={{
                          sx: { textAlign: "center", vAlign: "middle" },
                        }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        패널티 (-)
                      </LALabel>
                    </LAGridItem>
                    <LAGridItem wPercent={35}>
                      {penaltyInfo &&
                        penaltyInfo?.map((penalty, index) => (
                          <LALabel
                            key={`penalty-${index}`}
                            wPercent={100}
                            layouts={{ px: 1, sx: { textAlign: "left" } }}
                            props={{ fontSize: pxToRem(12) }}
                          >
                            {addComma(penalty.desc)}
                          </LALabel>
                        ))}
                    </LAGridItem>
                    <LAGridItem wPercent={10}>
                      <LALabel
                        wPercent={100}
                        layouts={{ sx: { textAlign: "center" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        &nbsp;
                      </LALabel>
                    </LAGridItem>
                    <LAGridItem wPercent={15}>
                      {penaltyInfo &&
                        penaltyInfo?.map((penalty, index) => (
                          <LALabel
                            key={`penalty-amount-${index}`}
                            wPercent={100}
                            layouts={{ px: 2, sx: { textAlign: "right" } }}
                            props={{ fontSize: pxToRem(12) }}
                          >
                            {addComma(penalty.amount)}
                          </LALabel>
                        ))}
                    </LAGridItem>
                    <LAGridItem wPercent={25}>
                      <LALabel
                        wPercent={100}
                        layouts={{ sx: { textAlign: "left" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        &nbsp;
                      </LALabel>
                    </LAGridItem>
                  </LAGridContainer>
                ) : (
                  <></>
                )}

                <LAGridContainer
                  vAlign="center"
                  layouts={{
                    sx: {
                      borderBottom: "1px solid #dddddd",
                      backgroundColor: "#e5eff7",
                    },
                  }}
                >
                  <LALabel
                    wPercent={15}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    보전 및 패널티 정산 금액
                  </LALabel>
                  <LALabel
                    wPercent={35}
                    layouts={{ px: 1, sx: { textAlign: "left" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    &nbsp;
                  </LALabel>
                  <LALabel
                    wPercent={10}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    &nbsp;
                  </LALabel>
                  <LALabel
                    wPercent={15}
                    layouts={{ px: 2, sx: { textAlign: "right" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    {addPrice > 0 || penaltyPrice > 0
                      ? addComma(addPrice + penaltyPrice)
                      : "-"}
                  </LALabel>
                  <LALabel
                    wPercent={25}
                    layouts={{ sx: { textAlign: "left" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    &nbsp;
                  </LALabel>
                </LAGridContainer>

                <LAGridContainer
                  vAlign="center"
                  layouts={{
                    sx: {
                      borderBottom: "1px solid #dddddd",
                      backgroundColor: "#e5eff7",
                    },
                  }}
                >
                  <LALabel
                    wPercent={15}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    총 정산 금액
                  </LALabel>
                  <LALabel
                    wPercent={35}
                    layouts={{ px: 1, sx: { textAlign: "left" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    &nbsp;
                  </LALabel>
                  <LALabel
                    wPercent={10}
                    layouts={{ sx: { textAlign: "center" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    &nbsp;
                  </LALabel>
                  <LALabel
                    wPercent={15}
                    layouts={{ px: 2, sx: { textAlign: "right" } }}
                    props={{ fontSize: pxToRem(13) }}
                  >
                    {statementInfo?.total_settlement_amount > 0
                      ? addComma(statementInfo.total_settlement_amount)
                      : "-"}
                  </LALabel>
                  <LALabel
                    wPercent={25}
                    layouts={{ sx: { textAlign: "left" } }}
                    props={{ fontSize: pxToRem(12) }}
                  >
                    &nbsp;
                  </LALabel>
                </LAGridContainer>

                {statementInfo && statementInfo.commission_type == 2 ? (
                  <>
                    <LAGridContainer
                      vAlign="center"
                      layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                    >
                      <LALabel
                        wPercent={15}
                        layouts={{ sx: { textAlign: "center" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        총 부가가치세액
                      </LALabel>
                      <LALabel
                        wPercent={35}
                        layouts={{ px: 1, sx: { textAlign: "left" } }}
                        props={{ fontSize: pxToRem(12) }}
                      ></LALabel>
                      <LALabel
                        wPercent={10}
                        layouts={{ sx: { textAlign: "center" } }}
                        props={{ fontSize: pxToRem(12) }}
                      ></LALabel>
                      <LALabel
                        wPercent={15}
                        layouts={{ px: 2, sx: { textAlign: "right" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        {addComma(statementInfo.vat)}
                      </LALabel>
                      <LALabel
                        wPercent={25}
                        layouts={{ sx: { textAlign: "left" } }}
                        props={{ fontSize: pxToRem(12) }}
                      ></LALabel>
                    </LAGridContainer>
                    <LAGridContainer
                      vAlign="center"
                      layouts={{ sx: { borderBottom: "1px solid #dddddd" } }}
                    >
                      <LALabel
                        wPercent={15}
                        layouts={{ sx: { textAlign: "center" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        총 공급가액
                      </LALabel>
                      <LALabel
                        wPercent={35}
                        layouts={{ px: 1, sx: { textAlign: "left" } }}
                        props={{ fontSize: pxToRem(12) }}
                      ></LALabel>
                      <LALabel
                        wPercent={10}
                        layouts={{ sx: { textAlign: "center" } }}
                        props={{ fontSize: pxToRem(12) }}
                      ></LALabel>
                      <LALabel
                        wPercent={15}
                        layouts={{ px: 2, sx: { textAlign: "right" } }}
                        props={{ fontSize: pxToRem(12) }}
                      >
                        {statementInfo?.supply_price > 0
                          ? addComma(statementInfo.supply_price)
                          : "-"}
                      </LALabel>
                      <LALabel
                        wPercent={25}
                        layouts={{ sx: { textAlign: "left" } }}
                        props={{ fontSize: pxToRem(12) }}
                      ></LALabel>
                    </LAGridContainer>
                  </>
                ) : (
                  <></>
                )}

                {statementInfo && statementInfo.commission_type == 1 ? (
                  <LAGridContainer
                    vAlign="center"
                    layouts={{
                      sx: {
                        backgroundColor: "#e5eff7",
                        borderBottom: "1px solid #dddddd",
                      },
                    }}
                  >
                    <LALabel
                      wPercent={15}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      총 입금액
                    </LALabel>
                    <LALabel
                      wPercent={35}
                      layouts={{ px: 1, sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    ></LALabel>
                    <LALabel
                      wPercent={10}
                      layouts={{ sx: { textAlign: "center" } }}
                      props={{ fontSize: pxToRem(13) }}
                    ></LALabel>
                    <LALabel
                      wPercent={15}
                      layouts={{ px: 2, sx: { textAlign: "right" } }}
                      props={{ fontSize: pxToRem(13) }}
                    >
                      {statementInfo?.total_settlement_amount > 0
                        ? addComma(statementInfo.total_settlement_amount)
                        : "-"}
                    </LALabel>
                    <LALabel
                      wPercent={25}
                      layouts={{ sx: { textAlign: "left" } }}
                      props={{ fontSize: pxToRem(13) }}
                    ></LALabel>
                  </LAGridContainer>
                ) : (
                  <></>
                )}
              </LAGridContainer>

              <LAWarningLabel>
                해당 월 기간 내 수업완료 내역에 대해서만 정산됩니다.
              </LAWarningLabel>
              <LAWarningLabel>
                건수는 결제(수업) 회원 단위로 카운팅됩니다.
              </LAWarningLabel>
              <LAWarningLabel>
                입금일이 주말 혹은 공휴일인 경우 입금일 이후의 가장 가까운
                평일에 입금됩니다.
              </LAWarningLabel>
            </LACard>
          </MDBox>
        </Grid>
        <Grid sm={3.5} ml={0.5}>
          <MDBox component="form" pt={2} pl={2} pr={2}>
            <LAGridContainer>
              <LAButton
                wPercent={30}
                layouts={{ pr: { sm: 1 } }}
                onClick={() => clickDownload("선생님별 수수료 내역")}
              >
                선생님별
                <br />
                수수료 내역 다운로드
              </LAButton>
              <LAButton
                wPercent={30}
                layouts={{ pl: { sm: 1 } }}
                onClick={() => clickDownload("클래스별 수수료 내역")}
              >
                클래스별
                <br />
                수수료 내역 다운로드
              </LAButton>
              <LAButton
                wPercent={30}
                layouts={{ pl: { sm: 1 } }}
                onClick={clickDownloadList}
              >
                다운로드 이력
              </LAButton>
            </LAGridContainer>
            <LAButton layouts={{ pt: { sm: 2 } }} onClick={showReceiptPopup}>
              거래 명세서
            </LAButton>
            {!(authMemberType == 1 && authAdminAuthType == 1) && (
              <LACard next>
                <LAProperty wNameWidth={99} name="지급 정보 확인" />
                <LADivider />

                <LAProperty wNameWidth={30} name="은행명">
                  <LALabel>{bankInfo ? bankInfo.bank_name : ""}</LALabel>
                </LAProperty>
                <LADivider />

                <LAProperty wNameWidth={30} name="계좌번호">
                  <LALabel>{bankInfo ? bankInfo.bank_number : ""}</LALabel>
                </LAProperty>
                <LADivider />

                <LAProperty wNameWidth={30} name="예금주">
                  <LALabel>{bankInfo ? bankInfo.bank_account : ""}</LALabel>
                </LAProperty>
                <LADivider />

                <LAWarningLabel>
                  계좌 정보 변경은 담당 MD에게 문의 바랍니다.
                </LAWarningLabel>
              </LACard>
            )}
          </MDBox>
        </Grid>
      </LAGridContainer>
    </>
  );
}

export default CalculateSettlementManagement;
