import React, { Component } from "react";
import LAGridItem from "./LAGridItem";
import LAGridContainer from "./LAGridContainer";
import LAButton from "./LAButton";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import pxToRem from "assets/theme/functions/pxToRem";

const LabelBox = styled("div")(() => {
  return {
    border: "1px solid #dddddd",
    borderRadius: "5px 0px 0px 5px",
    fontSize: pxToRem(13),
    textAlign: "center",
    height: "32px",
    margin: "0px",
    marginTop: "2px",
    paddingTop: "4px",
    backgroundColor: "#eeeeee",
  };
});

const AttachmentInput = styled(Input)((props) => {
  return {
    borderTop: "1px solid #dddddd",
    borderBottom: "1px solid #dddddd",
    borderLeft: `${props.hiddenLabel ? 0 : 1}px solid #dddddd`,
    fontSize: pxToRem(13),
    padding: "0px",
    paddingLeft: pxToRem(13),
    paddingTop: "4px",
    height: "32px",
    margin: "0px",
    borderRadius: props.hiddenLabel ? "0px 0px 0px 0px" : "5px 0px 0px 5px",
  };
});

const FindFileButton = styled(LAButton)(() => {
  return {
    border: "1px solid #ff0000",
    borderRadius: "0px",
  };
});

function LAFileAttachment({
  wPercent = 100,
  layouts = {},
  placeholder = "※ 최대 2MB까지 첨부하실 수 있습니다.",
  hiddenLabel = false,
  hiddenInput = false,
  props = {},
  value,
  onChange,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <LAGridContainer vAlign="center">
        {!hiddenLabel && (
          <Grid item xs={12} sm={2}>
            <LabelBox>첨부파일</LabelBox>
          </Grid>
        )}
        {!hiddenInput && (
          <Grid item xs={12} sm={10}>
            <AttachmentInput
              placeholder={placeholder}
              fullWidth
              readOnly
              disableUnderline
              variant="outlined"
              size="small"
              value={value}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={hiddenInput ? 8 : 2}>
          <FindFileButton
            color="dark"
            hiddenInput
            props={{
              sx: {
                borderRadius:
                  hiddenLabel && hiddenInput
                    ? "5px 5px 5px 5px"
                    : "0px 5px 5px 0px",
                boxShadow: 0,
              },
              component: "label",
            }}
          >
            파일 찾기
            <input type="file" hidden onChange={onChange} {...props} />
          </FindFileButton>
        </Grid>
      </LAGridContainer>
    </LAGridItem>
  );
}

export default React.memo(LAFileAttachment);
