import React from "react";
import DataTableBodyCell from "modules/Tables/DataTable/DataTableBodyCell";
import TableRow from "@mui/material/TableRow";

function DataTableRowItem({ rows, row, rowIndex, noEndBorder }) {
  return (
    <TableRow {...row.getRowProps()}>
      {row.cells.map((cell, index) => (
        <DataTableBodyCell
          key={index}
          noBorder={noEndBorder && rows.length - 1 === rowIndex}
          align={cell.column.align ? cell.column.align : "center"}
          {...cell.getCellProps()}
        >
          {cell.render("Cell")}
        </DataTableBodyCell>
      ))}
    </TableRow>
  );
}

export default React.memo(DataTableRowItem);
