import React, {  useEffect, useContext ,useMemo} from 'react';

import { UNSAFE_NavigationContext } from "react-router-dom";

import { LAModal} from 'modules/Widgets';

import UserMemoContents from './UserMemoContents';

function UserMemoModal({data,type, show, handleClose }) {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  
  useEffect(() => {    
      window.history.pushState(null, null, window.location.pathname);
      if (navigation) {
      navigation.listen((locationListener) => {
          if(locationListener.action == 'POP') {
              handleClose();
          }
      });
    }
  }, []);

  const getTitle = useMemo(() => {
    return type == 1?"클래스 관리 메모":"회원 관리 메모"
  },[type])
    return (
    <LAModal
      title={getTitle}
      width="80%"
      height="70%"
      show={show}
      onClose={handleClose}
    >
        <UserMemoContents user={data} type={type} onClickList={handleClose}/>
    </LAModal>
  );
}

export default React.memo(UserMemoModal);
