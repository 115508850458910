import React, { useState, useCallback ,useEffect,useMemo} from 'react';
import { useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import ClassScheduleDetailContents from '../components/ClassScheduleDetailContents';

function ClassScheduleDetail() {
  const navigate = useNavigate();

  const { scheduleSeq } = useParams();

  const navigateBack = () => {
    navigate(-1, { replace: true });
  };


  return (
    <>
      <DashboardNavbar title="클래스 일정 상세" />
      <ClassScheduleDetailContents scheduleSeq={scheduleSeq} onClickList={navigateBack} />


    </>
  )
}
export default ClassScheduleDetail;