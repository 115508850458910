import React from 'react';
import Grid from '@mui/material/Grid';

function LAGridContainer({ pt = 0, pb = 0, mt = 0, mb = 0, vAlign, children, layouts = {}, }) {
  return (
    <Grid container alignItems={vAlign} pt={pt} pb={pb} mt={mt} mb={mb} {...layouts}>
      {children}
    </Grid>
  );
}

export default React.memo(LAGridContainer);
