import React, { useState, useCallback, useMemo, useEffect } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import {getTodayDateText, sendCacheRefresh} from 'common/utils';
import { periodItems } from 'common/constants';
import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  getTextCell,
  getNormalButton,
  getPrimaryButton,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useDatePicker,
  useTabSelector,
  LASimpleTable,
} from 'modules/Widgets';
import PopupBannerRegistModal from './components/PopupBannerRegistModal';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';
import MainBannerManagementModal from './components/MainBannerManagementModal';
import PopupBannerEditModal from './components/PopupBannerEditModal';

function PopupBannerManagement() {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [selectedBannerSeq, setSelectedBannerSeq] = useState(0);
  const [exposuredBanners, setExposuredBanners] = useState([]);
  const [banners, setBanners] = useState([]);
  const todayDateText = getTodayDateText('-');
  const searchDateType = useComboBox(0);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const searchTargetType = useComboBox(0);
  const searchText = useInput('');
  
  const [bannerType, setBannerType] = useState(6);
  const bannerRegistModal = useModal(false);
  const bannerEditModal = useModal(false);
  const mainBannerModal = useModal(false);

  const { result: resultData, request: requestBanners } = useCommonQuery({
    query: queries.Banner.getBanners,
    params: {
      exposure_yn: undefined,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      query_type: searchTargetType.state,
      query: searchText.state,
      banner_type:bannerType
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setBanners(data.result_data);
          //   setExposuredBanners(data.result_data.exposuredBanners);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '목록 조회에 실패하였습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestDelete } = useCommonMutation({
    query: queries.Banner.deleteBanner,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '배너가 삭제되었습니다.');
          requestBanners();

          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '배너 삭제에 실패했습니다.');
      },
    },
  });

  const { request: requestUpdate } = useCommonMutation({
    query: queries.Event.updateEvent,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '이벤트가 미노출되었습니다.');
          requestBanners();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '이벤트 수정에 실패했습니다.');
      },
    },
  });

  const onBannerUpdateClick = useCallback((bannerSeq) => {
    setSelectedBannerSeq(bannerSeq);
    bannerEditModal.visible(true);
  }, []);

  const onBannerDeleteClick = useCallback((bannerSeq) => {
    showConfirm(
      '알림',
      '정말 삭제하시겠습니까?',
      {
        confirmHandler: () =>
          requestDelete({
            banner_seq: bannerSeq,
          }),
      }
    );
  }, []);
  const getReservationText = useCallback((banner) =>{
    if(banner.reservation_yn === 1){
      let startDate = moment(banner.reservation_start_dt).format('YYYY.MM.DD HH:mm')
      let endDate = moment(banner.reservation_end_dt).format('YYYY.MM.DD HH:mm')
      return `${startDate}\n ~ ${endDate}`
    }else{
      return '즉시'
    }
  })
  const columns = useMemo(() => {
    return [
      { Header: 'No', accessor: 'no', width: '6%', align: 'center' },
      { Header: '제목', accessor: 'title', width: '25%', align: 'left' },
      { Header: '링크', accessor: 'link', width: '25%', align: 'left' },
      { Header: "예약노출", accessor: "reservation", width: "10%", align: "center" },
      { Header: '등록일', accessor: 'regist_date', width: '14%', align: 'center' },
      { Header: '수정', accessor: 'update', width: '10%', align: 'center' },
      { Header: '삭제', accessor: 'delete', width: '10%', align: 'center' },
    ];
  }, []);

  const rows = useMemo(() => {
    return banners.map((item) => {
      return {
        no: getTextCell(`${item.banner_seq}`),
        title: getTextCell(item.banner_title),
        link: getTextCell(item.banner_link_url),
        reservation: getTextCell(getReservationText(item)),
        regist_date: getTextCell(
          item.created_dt
            ? moment(item.created_dt).format('YYYY-MM-DD (dd) A hh:mm')
            : '',
        ),
        update: getPrimaryButton(
          '수정',
          `${item.banner_seq}`,
          onBannerUpdateClick,
        ),
        delete: getNormalButton(
          '삭제',
          `${item.banner_seq}`,
          onBannerDeleteClick,
        ),
      };
    });
  }, [banners]);

  const onChangePage = useCallback((page) => {
  }, []);

  const onInitClick = useCallback(() => {
    searchDateType.reset();
    searchPeriodType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    searchDateType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  const onSearchClick = useCallback(() => {
    requestBanners();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  }, []);

  const onRegistClick = useCallback(() => {
    bannerRegistModal.visible(true);
  });

  const onExposureClick = useCallback(() =>{
    mainBannerModal.visible(true)
  })

  const onRegistered = useCallback(() => {
    bannerRegistModal.handleClose();
    setSelectedBannerSeq(undefined);
    onSearchClick();
  });

  const onEditorClosed = useCallback(() => {
    bannerEditModal.handleClose();
    onSearchClick();
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
    // onSearchClick();
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) {
      requestBanners();
    }
  }, [searchStartDate.state]);

  const deleteExposuredBanner = useCallback(
    (exposureNo, bannerSeq) => {
      showConfirm(
        '확인',
        `${bannerSeq} 배너를 미노출하시겠습니까?\n실시간으로 프론트에 반영됩니다.`,
        {
          confirmHandler: () => {
            const formData = new FormData();
            formData.append('event_seq', bannerSeq);
            formData.append('exposure_status', '0');
            const params = {
              event_seq: bannerSeq,
              data: formData,
            };

            // requestUpdate(params);
          },
        },
      );
    },
    [exposuredBanners],
  );

  const getExposuredRows = useCallback(() => {
    let rows = [];

    if (exposuredBanners && exposuredBanners.length > 0) {
      exposuredBanners.forEach((banner, index) => {
        banner.exposure_no = index + 1;
        rows.push([
          {
            text: banner.event_seq,
          },
          {
            text: banner.event_title,
            align: 'left',
          },
          {
            text: banner.exposure_dt,
          },
          {
            text: '미노출',
            type: 'button',
            callback: {
              onClick: () =>
                deleteExposuredBanner(banner.exposure_no, banner.event_seq),
            },
          },
          {
            text: '수정',
            type: 'button',
            callback: {
              onClick: () => onBannerUpdateClick(banner.event_seq),
            },
          },
        ]);
      });
    }
    return rows;
  }, [exposuredBanners]);

  const exposuredData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '등록일',
        },
        {
          text: '',
        },
        {
          text: '',
        },
      ],
      wPercentList: [10, 50, 20, 10, 10],
      rows: getExposuredRows(),
    };
  }, [exposuredBanners]);

  return (
    <>
      <DashboardNavbar title="팝업 배너 등록 관리" />

      {/* <LACard>
        <LACardTitle title="노출 팝업 배너 관리" />
        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel>총 1건</LALabel>
          <LASimpleTable tableData={exposuredData} />
        </LAGridContainer>
        <LAWarningLabel>최신 등록 게시물이 프론트에 표시됩니다.</LAWarningLabel>
      </LACard> */}

      {/* Start Search Area */}
      <LACard >
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            {/* <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '배너 시작일' },
                { key: 1, value: '배너 종료일' },
              ]}
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
            /> */}
            <LATabSelector
              wPercent={45}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2, mr: 2 }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />{' '}
            ~{' '}
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2 }}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '제목' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={20}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onRegistClick}>
            등록
          </LAButton>
          <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onExposureClick}>
            노출 배너 관리
          </LAButton>
          <LALabel wPercent={20}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard next>
        <LAGridContainer>
          <LADataTable
            columns={columns}
            rows={rows}
            defaultPage={0}
            onChangePage={onChangePage}
          />
        </LAGridContainer>
      </LACard>

      <PopupBannerRegistModal
        show={bannerRegistModal.state}
        handleClose={onRegistered}
        bannerType={bannerType}
      />
      {bannerEditModal.state &&
        <PopupBannerEditModal
          show={bannerEditModal.state}
          handleClose={onEditorClosed}
          bannerSeq={selectedBannerSeq}
          bannerType={bannerType}
        />
      }
      <MainBannerManagementModal
        show={mainBannerModal.state}
        handleClose={mainBannerModal.handleClose}
        bannerType={bannerType}
      />
    </>
  );
}

export default PopupBannerManagement;
