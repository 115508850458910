import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useCommonQuery from "common/hooks/useCommonQuery";

import { getTodayDateText } from "common/utils";

import { useLoginContext } from "common/context/MemberContext";
import { useApiContext } from "common/context/ApiContext";
import useCommonMutation from "common/hooks/useCommonMutation";
import { getDuplicateClassSchedules } from "common/utils/EtcUtils";
import pxToRem from "assets/theme/functions/pxToRem";
import moment from "moment";
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LACodeRadioGroup,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADeletableListView,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useModalPopup,
  useKeywords,
  useCheckbox,
  LAImage,
} from "modules/Widgets";
import useTimer from "common/hooks/useTimer";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

const regex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

function PasswordChangeModal({ show, handleClose, data }) {
  const { showAlert } = usePopAlert();
  const { queries } = useApiContext();

  const ordiPwdRef = useRef("");
  const newPwdRef = useRef("");
  const checkNewPwdRef = useRef("");

  const { requestAsync: passwordChangeAsync } = useCommonMutation(
    {
      query: queries.Auth.changePassword,
      callbacks: {
        onSuccess: (data) => {
          const { result_code, result_message } = data;

          if (result_code === "0000") {
            showAlert("알림", "변경이 완료되었습니다. 재로그인 해주세요.");
            handleClose();
          } else if (result_code === "1007") {
            showAlert("알림", "현재 비밀번호가 일치하지 않습니다.");
          } else if (result_code === "1008") {
            showAlert(
              "알림",
              "비밀번호는 8자 이상이어야 하며, 숫자/대문자/소문자/특수문자를 모두 포함해야 합니다."
            );
          } else showAlert("알림", result_message);
        },
        onError: (error) => {
          console.log(error);
          return showAlert("알림", "문제가 발생했습니다.");
        },
      },
    },
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(newPwdRef.current.value, checkNewPwdRef.current.value);

    if (
      newPwdRef.current.value === checkNewPwdRef.current.value &&
      regex.test(newPwdRef.current.value)
    ) {
      const params = {
        login_id: data.loginId,
        original_password: ordiPwdRef.current.value,
        new_password: newPwdRef.current.value,
      };

      await passwordChangeAsync(params);
    } else
      showAlert(
        "알림",
        "비밀번호는 8자 이상이어야 하며, 숫자/대문자/소문자/특수문자를 모두 포함해야 합니다."
      );
  };

  return (
    <LAModal title="" width="600px" height="300px" show={show}>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          gap: "10px",
          fontSize: "1.1rem",
        }}
      >
        비밀번호 변경 후 이용이 가능합니다.
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "repeat(3, auto)",
            textAlign: "left",
          }}
        >
          현재 패스워드
          <input placeholder="현재 패스워드" required ref={ordiPwdRef} />
          변경 패스워드
          <input placeholder="변경 패스워드" required ref={newPwdRef} />
          변경 패스워드 확인
          <input
            placeholder="변경 패스워드 확인"
            required
            ref={checkNewPwdRef}
          />
        </div>
        <input
          type="submit"
          value="저장"
          style={{
            backgroundColor: "rgb(73, 80, 87)",
            color: "white",
            borderRadius: "10px",
            padding: "10px 50px",
            margin: "10px",
          }}
        />
      </form>
    </LAModal>
  );
}

export default React.memo(PasswordChangeModal);
