import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import Box from '@mui/material/Box';
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LALabel,
  LAButton,
  LAInput,
  LAProperty,
  LAWarningLabel,
  LATextEditor,    
  useInput,
} from 'modules/Widgets';

function MainContentVideo({ videoInfo , mainSeq, onSaveClick}) {
  
  const getTextEditorTitleValue = useRef(null);
  const videoUrl = useInput('');

  console.log('MainContentVideo videoInfo => ',videoInfo)

  useEffect(() => {
    if(videoInfo && videoInfo.video_title) {      
      getTextEditorTitleValue.current.value = videoInfo.video_title
      videoUrl.update(videoInfo.video_link_url||'');
    }
  }, [videoInfo]);

  return (
    
    <>
      <LACardTitle title="메인 영상 정보" />
      <LAProperty name="제목" required>          
        <LATextEditor
            name="description_e_title"
            value={videoInfo?.video_title || ''}
            getEditorValue={getTextEditorTitleValue}
            />

      </LAProperty>
      <LAProperty name="영상 URL" required>
        <LAInput
          placeholder="영상 URL을 입력해 주세요."
          value={videoInfo?.video_link_url}
          // onChange={videoUrl.handleChange}
        />
        <LAWarningLabel>YouTube 동영상만 링크 가능하며 https://www.youtube.com/embed/content_id 형식으로 입력하세요.</LAWarningLabel>
        <Box>
          <iframe
              width={'560'}
              height={'315'}
              src={videoInfo?.video_link_url}
          />
        </Box>

      </LAProperty>  
    </>
      
      
      
  );  
}

export default React.memo(MainContentVideo);
