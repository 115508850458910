import moment from 'moment';
import 'moment/locale/ko';

import React, { useState, useCallback, useEffect } from 'react';

import ClassScheduleView from '../components/ClassScheduleView';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAButton,
} from 'modules/Widgets';
import { LAGridItem } from 'modules/Widgets';



function ClassScheduleModal({ schedules, teacherSchedules, classCount, teacher, className, classTime, onChange, show, handleClose }) {

  const [newSchedules, setNewSchedule] = useState([]);
  const { showAlert, showConfirm } = usePopAlert();

  const onScheduleChanged = useCallback((data) => {
    setNewSchedule(data);
    return true;

    /*
    let duplicatedMessage = '';
    if(schedules && data) {
      schedules.forEach((schedule) => {
        if(schedule.timetables) {
          schedule.timetables.forEach((timetable) =>  {
            let scheduledStartTime = moment(timetable.schedule_date+timetable.schedule_time, 'YYYYMMDDHHmm')
            let scheduledFinishTime = scheduledStartTime.clone().add(schedule.class_time, 'm');

            data.forEach((row) => {
              if(row.dates) {
                row.dates.forEach((date) => {
                  let desiredStartTime = moment(date+row.startTime, 'YYYY-MM-DDHHmm');
                  let desiredFinishTime = desiredStartTime.clone().add(classTime, 'm');
                  
                  if(desiredStartTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]') || desiredFinishTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]')){
                    duplicatedMessage += `[${schedule.class_name}]\n${moment(scheduledStartTime).format('YYYY-MM-DD HH:mm')} ~ ${moment(scheduledFinishTime).format('YYYY-MM-DD HH:mm')}\n`;
                  }

                  // if(date.replaceAll('-', '') == timetable.schedule_date 
                  //   && row.startTime.replaceAll(':', '') == timetable.schedule_time) {
                  //   isDuplicated = true;
                  //   className = schedule.class_name;
                  //   duplicatedDate = date;
                  //   duplicatedTime = row.startTime;
                  // }

                });
              }
            })
          });
        }
      });
    }

    if(duplicatedMessage) {
      showAlert('알림', `기존 일정에 동일한 날짜가 포함되어 있습니다.\n\n${duplicatedMessage}`);
      return false;
    } else {
      setNewSchedule(data);
      return true;
    }
    */
  });

  const onAddClick = useCallback(() => {
    teacherSchedules.push(...newSchedules);
    onChange(newSchedules);
    handleClose();
  });


  return (
    <LAModal
      title=""
      width="80%" 
      height="90%" 
      show={show} 
      >
      <LACard>
      <LACardTitle title="일정 추가" />

      <ClassScheduleView
          schedules={[]}
          teacherSchedules={teacherSchedules}
          teacher={teacher}
          curriCount={classCount}
          className = {className}
          classTime = {classTime}
          onChange={onScheduleChanged}
        />

      <LAGridContainer pt={3}>
        <LAGridItem wPercent={34} />
        <LAButton wPercent={15} layouts={{ pr: { sm: 0.5 } }} onClick={handleClose}>
          취소
        </LAButton>
        <LAButton wPercent={15} layouts={{ pl: { sm: 0.5 } }} onClick={onAddClick}>
          추가
        </LAButton>
      </LAGridContainer>
      
      </LACard>
    </LAModal>
  );
}

export default React.memo(ClassScheduleModal);
