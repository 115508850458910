import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LAGridContainer,
  LALabel,
  LAButton,
  LAGridItem,
  LAProperty,
  LAInput,
  useInput,
} from 'modules/Widgets';


import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import CategoryLinkView from 'pages/Category/component/CategoryLinkView';
import {sendCacheRefresh} from "../../../common/utils";

function MainMenuManagement() {
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();
    const [ menuData, setMenuData ] = useState([]);
    const [ selectedMenu, setSelectedMenu ] = useState({});
    const [ deleteData, setDeleteData ] = useState([]);

    const listRef = useRef(null);

    const menuTitle = useInput('');
    const menuLink = useInput('');

    const { request: requestMainMenuList } = useCommonQuery({
        query: queries.Menu.getMainMenuList,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setMenuData(data.result_data)
                }
            },
            onError: (error) => {
                showAlert('알림');
            },
        },
    });

    const { request: saveMainMenuList } = useCommonMutation({
        query: queries.Menu.saveMainMenuList,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '메인메뉴가 사용자 화면에 정상 반영되었습니다.');
                    requestMainMenuList();
                    /**CACHE REFRESH : TOP메뉴 갱신*/
                    sendCacheRefresh( '/v1/api/main/topMenu' );
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '화면 반영에 실패하였습니다.');
            },
        },
    });

    const onChangedMenu = useCallback((e, item) => {
        setSelectedMenu(item);
        
        menuTitle.update(item.menu_title);
        menuLink.update(item.link_url);

    },[selectedMenu, menuTitle.state, menuLink.state]);

    const addMenuClick = useCallback(() => {
        if(!menuTitle.state) {
            showAlert("알림", "메뉴명을 입력해 주세요.");
        } else if(!menuLink.state) {
            showAlert("알림", "메뉴에 연결할 URL을 입력해 주세요.");
        } else {
            menuData.push({
                menu_title: menuTitle.state,
                link_url: menuLink.state,
            });

            menuTitle.reset();
            menuLink.reset();
        }
    },[menuData.state, menuTitle.state, menuLink.state]);

    const editMenuClick = (e) => {
        if(!e.target.value) return;
        let editList = menuData.map((item) => {
            return item.main_menu_seq === parseInt(e.target.value) ? {...item, menu_title: menuTitle.state, link_url: menuLink.state} : item
        })
        setMenuData(editList);

        menuTitle.reset();
        menuLink.reset();

        setSelectedMenu([]);
    };

    const clickDeleteMenu = (main_menu_seq) => {
        if(!main_menu_seq) return;
        let list = [];
        list.push(...deleteData, main_menu_seq);

        let settingList = menuData.filter((item) => {
            return item.main_menu_seq !== main_menu_seq
        })

        setMenuData(settingList);
        setDeleteData(list);

        menuTitle.reset();
        menuLink.reset();

        setSelectedMenu([]);
    };

    const onSaveClick = useCallback(() => {
        console.log(" SAVE ::", menuData, deleteData);
        showConfirm('알림', '사용자 화면에 반영하시겠습니까?', {
            confirmHandler: () => {
                saveMainMenuList({
                    deleteMenus: deleteData,
                    menus: menuData,
                });
            },
        });
    }, [menuData]);

    useEffect(() => {
        if(!selectedMenu) {
            menuTitle.reset();
            menuLink.reset();
        }
    },[menuTitle.state, menuLink.state]);

    useEffect(() => {
        function clickToOutside(e) {
            console.log(e.target.classList)
            if (!e.target.classList.contains('MuiTypography-root') 
            && !e.target.classList.contains('MuiListItemButton-root') 
            && !e.target.classList.contains('MuiButton-root')
            && !e.target.classList.contains('MuiOutlinedInput-input')
            && !e.target.classList.contains('MuiSelect-select')
            ) {
                setSelectedMenu([]);
                menuTitle.reset();
                menuLink.reset();
            }
        }
        document.addEventListener("mousedown", clickToOutside);
        return  () => {
            document.removeEventListener("mousedown", clickToOutside);
        };
    },[listRef]);

    return (
        <>
            <DashboardNavbar title="메인 메뉴 관리"/>
            <LAGridContainer ref={listRef}>
                <LAGridItem wPercent={50} layouts={{ p: "1rem" }}>
                    <LACard>
                        <LAGridContainer vAlign="center" layouts={{ sx: { backgroundColor: "#d6d6d6"}}}>
                            <LALabel wPercent={2} />
                            <LALabel wPercent={44.5} props={{ sx: { fontWeight: "bold" }}}>메인 메뉴명</LALabel>
                            <LALabel wPercent={30} props={{ sx: { fontWeight: "bold" }}}>연결 URL</LALabel>
                        </LAGridContainer>
                        {menuData && menuData?.map((item, idx) => {
                            return (
                                <CategoryLinkView
                                    key={idx}
                                    selectedIndex={selectedMenu}
                                    child={item}
                                    handleListItemClick={onChangedMenu}
                                    onDeleteClick={() => clickDeleteMenu(selectedMenu.main_menu_seq)}
                                />
                            );
                        })}
                        <LAButton layouts={{ sx: { width: "18rem", pt: "1rem", float: "right"} }} onClick={onSaveClick}>저장(사용자 화면에 반영)</LAButton>
                    </LACard>
                </LAGridItem>
                <LAGridItem wPercent={50} layouts={{ p: "1rem" }}>
                    <LACard>
                        <LAProperty wNameWidth={15} name={selectedMenu.main_menu_seq ? "메인 메뉴 수정 : " : "메인 메뉴 등록 : "} layouts={{ sx: { display: "flex", justifyContent: "center", alignItems: "center" }}}>
                            <LAGridContainer>
                                <LAGridItem wPercent={85}>
                                    <LAInput placeholder="메뉴 명 입력" value={menuTitle.state} onChange={menuTitle.handleChange} />
                                    <LAInput layouts={{ sx: { pt: "0.1rem" }}} placeholder="URL 입력" value={menuLink.state} onChange={menuLink.handleChange} />
                                </LAGridItem>
                                <LAButton wPercent={5} layouts={{ sx: { ml: "1.5rem"} }} props={{ value: selectedMenu.main_menu_seq || '' }} onClick={selectedMenu.main_menu_seq ? editMenuClick : addMenuClick}>{selectedMenu.main_menu_seq ? '수정' : '추가'}</LAButton>
                            </LAGridContainer>
                        </LAProperty>
                    </LACard>
                </LAGridItem>
            </LAGridContainer>
        </>
    );  
}   
export default MainMenuManagement;
