import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LAGridItem, LALabel } from 'modules/Widgets';
import { LAInput } from 'modules/Widgets';
import { useInput } from 'modules/Widgets';
import { toCurrency } from 'common/utils';
import { LAGridContainer } from 'modules/Widgets';
import { LAProperty } from 'modules/Widgets';

function OrderRefundPriceListView({
  refundInfo,
  refundItems,
  cancelAmount,
  cancelReason,
  imputType,
  refundFeedbackType,
  isAdmin = false,
  data
}) {
/*  useEffect(() => {
    if (!data?.returnTextbook) {
      return;
    } else {
      cancelAmount.update(data.returnTextbook?.order_cancel_temp_json.cancel_amount);
      cancelReason.update(data.returnTextbook?.order_cancel_temp_json.cancel_reason);
      //cancelAmount.update(refundInfo?.refundPrice); // 추후 필요시 활용
    }
  }, [refundInfo?.refundPrice, data]);*/

  // useEffect(() => {
  //   // 컴포넌트가 열릴 때 초기값 설정
  //   cancelAmount.reset();
  // }, []);

  useEffect(() => {
    if (!data?.returnTextbook) {
      return;
    }

    const { cancel_amount: cancelAmountValue, cancel_reason: cancelReasonValue } = data.returnTextbook.order_cancel_temp_json;

    if (cancelAmountValue !== cancelAmount.state) {
      cancelAmount.update(cancelAmountValue);
    }

    if (cancelReasonValue !== cancelReason.state) {
      cancelReason.update(cancelReasonValue);
    }
  }, [data?.returnTextbook]);


  const previousType = useRef(imputType);

  useEffect(() => {

    //let adjustedCancelAmount = cancelAmount.state;
    let adjustedCancelAmount = parseInt(cancelAmount.state, 10);

    if (imputType === '3') {
      if (previousType.current === '4') {
        adjustedCancelAmount = adjustedCancelAmount + 3000;
      }
      adjustedCancelAmount = adjustedCancelAmount - 6000;
    } else if (imputType === '4') {
      if (previousType.current === '3') {
        adjustedCancelAmount = adjustedCancelAmount + 6000;
      }
      adjustedCancelAmount = adjustedCancelAmount - 3000;
    } else if (imputType === '1' || imputType === '2' || imputType === '5') {
      if (previousType.current === '3') {
        adjustedCancelAmount = adjustedCancelAmount + 6000;
      } else if (previousType.current === '4') {
        adjustedCancelAmount = adjustedCancelAmount + 3000;
      } else {
        adjustedCancelAmount = adjustedCancelAmount - 0;
      }
    }

    if (adjustedCancelAmount !== cancelAmount.state) {
      cancelAmount.update(adjustedCancelAmount);
    }

    previousType.current = imputType;
  }, [imputType]);

  useEffect(() => {
    cancelAmount.update(refundInfo.refundPrice)
  },[refundInfo.refundPrice])

    return (
        <>
            <LAGridItem layouts={{ sx: { border: '1px solid #dddddd', textAlign: 'center', backgroundColor: '#eeeeee', borderTopLeftRadius: 12, borderTopRightRadius: 12 }}}>
                <LALabel grid={false}>{`환불 금액 계산(카드 결제 금액 기준)${(imputType === "3" || imputType === "4" || imputType === "5") ? " - 배송비" : ''}`}</LALabel>
            </LAGridItem>
        {
            <>
                {refundInfo.selectedOptionCount > 0 
                ? <LAGridItem layouts={{ sx: { border: '1px solid #dddddd', borderTop: '0px solid #dddddd', textAlign: 'center'}}}>
                    <LALabel grid={false}>
                        {`${refundInfo.refundPriceFormula}${imputType === "3" ? " -6,000원" : imputType === "4" ? " -3,000원" : ''} = `}
                    </LALabel>        
                    <LALabel  grid={false} props={{ fontWeight: 'bold' }}>
                        {`${toCurrency(refundInfo.refundPrice - (imputType === "3" ? 6000 : imputType === "4" ? 3000 : 0))}원`}
                    </LALabel>
                </LAGridItem> 
                :''}
            </>
        }

        <LAGridItem layouts={{ sx: { border: '1px solid #dddddd', borderTop: '0px solid #dddddd', backgroundColor: '#99ccff', textAlign: 'center'}}}>
            <LALabel grid={false}>최종 환불금액</LALabel>
        </LAGridItem>
        <LAGridItem layouts={{ sx: { border: '1px solid #dddddd', borderTop: '0px solid #dddddd', borderBottomLeftRadius: 12, borderBottomRightRadius: 12, textAlign: 'center', alignItems: 'center', paddingLeft: '24px', paddingRight: '24px'}}}>
            {/* {isAdmin ? ( */}
            <LAGridContainer pl={2} pb={2} pr={2}>
            <LAGridItem>
                <LAProperty name="환불 금액">
                    <LAInput 
                        wPercent={30}
                        value={parseInt(cancelAmount.state,10)}
                        //value={imputType === "3" ? (cancelAmount.state - 6000) : imputType === "4" ? (cancelAmount.state - 3000) : (cancelAmount.state)}
                        onChange={cancelAmount.handleChange}
                    />
                </LAProperty>
            </LAGridItem>
            <LAProperty name="환불 사유">
                <LAInput value={cancelReason.state} onChange={cancelReason.handleChange} />
            </LAProperty>
            <LAProperty name="">
                <LALabel layouts={{ sx: {textAlign: 'left', fontsize: '0.55rem'}}}>
                    <div>
                        1. 취소 시 클럽 포인트는 취소한 회차에 해당하는 금액만큼 자동으로 환불되며, 올머니는 환불되지 않습니다.<br/>
                        올머니 환불이 필요할 경우 본사와 협의 후 올머니 관리에서 올머니 지급 처리를 합니다.<br/>
                        <br/>
                        2. 교재 왕복 배송비 및 위약금 부과는 환불 금액에서 수기 조절 가능합니다.<br/>
                        (* 단, 총 카드 결제 금액을 초과할 수 없음)
                    </div>
                </LALabel>
            </LAProperty>
            {/* ) : (
            <LALabel
                grid={false}
                props={{
                fontWeight: 'bold',
                }}
            >
                {refundInfo.refundPrice}
            </LALabel>
            )} */}
            </LAGridContainer>
        </LAGridItem>
        </>
    );
}

export default React.memo(OrderRefundPriceListView);
