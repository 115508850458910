import React from 'react';
import LAGridItem from './LAGridItem';
import TextField from '@mui/material/TextField';

function LATextField({
  wPercent = 100,
  layouts = {},
  placeholder = '',
  value,
  onChange,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <TextField
        fullWidth
        multiline
        size="small"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </LAGridItem>
  );
}

export default React.memo(LATextField);
