import React, { useEffect, useState, useMemo, useCallback } from "react";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "common/context/ApiContext";
import { useLoginContext } from "common/context/MemberContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import { getTodayDateText, dateTimeToString } from "common/utils";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import moment, { suppressDeprecationWarnings } from "moment";
import { toCurrency } from "common/utils";

import { API_BASE_URL } from "api/AppConfig";
import { periodItems } from "common/constants";
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  useRadioGroup,
  LAButton,
  LAComboBox,
  useComboBox,
  LAInput,
  useInput,
  LADatePicker,
  useDatePicker,
  LATabSelector,
  useTabSelector,
  LADataTable2,
  LACodeRadioGroup,
  LACodeComboBox,
  useModal,
  getTextCell,
  // getTwoTextCell,
  getNormalButton,
  LAAltRadioGroup,
  LARadioGroup,
} from "modules/Widgets";
import DownloadReasonModal from "pages/Order/components/DownloadReasonModal";
import TogetherOrderDetailModal from "./components/TogetherOrderDetailModal";
import DownloadListModal from "pages/Order/components/DownloadListModal";

function TogetherOrderManagement({}) {
  const navigate = useNavigate();
  const todayDateText = getTodayDateText("-");
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();

  const downloadReasonModal = useModal(false);
  const downloadListModal = useModal(false);

  const [selectedOrderSeq, setSelectedOrderSeq] = useState(0);
  const orderDetailModal = useModal(false);

  const classType = useRadioGroup(9);
  const worksheetType = useRadioGroup(-1);

  const confirmType = useRadioGroup(-1);

  const orderStatusType = useRadioGroup(0);

  const payMethodType = useRadioGroup(0);
  const searchDateType = useComboBox(1);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const searchTargetType = useComboBox(1);
  const searchText = useInput("");
  const testModeType = useRadioGroup("");

  const [rows, setRows] = useState([]);

  const [tablePage, setTablePage] = useState(0);
  const [page, setPage] = useState(0); // 마지막 페이지네이션

  const thisYear = useMemo(() => moment().format("YYYY"), []);
  const thisMonth = useMemo(() => moment().format("MM"), []);

  const yearTypeDwn = useComboBox(thisYear);
  const monthTypeDwn = useComboBox(thisMonth);

  const { action } = useLoginContext();

  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  const getClassType = useCallback((item) => {
    let result =
      item.test_mode_yn === 0
        ? item.class_type_name
        : `${item.class_type_name} [시크릿]`;
    return result;
  });

  const togetherClassCode = useComboBox(-1);

  const [searchParams, setSearchParams] = useState({});

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "4%" },
      { Header: "결제번호", accessor: "order_no", width: "8%" },
      { Header: "경량화 오더번호", accessor: "lep_order_no", width: "8%" },
      { Header: "결제일시", accessor: "payment_dt", width: "8%" },
      { Header: "결제자 명", accessor: "applicant_name", width: "4%" },
      {
        Header: "북패드 / 학습자",
        accessor: "contract",
        width: "16%",
        align: "center",
      },
      {
        Header: "클래스 명",
        accessor: "class_name_book_name",
        width: "16%",
        align: "center",
      },
      { Header: "기간", accessor: "class_period", width: "6%" },
      { Header: "확정 상태", accessor: "confirmation_yn", width: "6%" },
      {
        Header: `결제 금액\n(클럽포인트)`,
        accessor: "bookclub_point",
        width: "6%",
      },
      { Header: `결제 금액\n(카드)`, accessor: "payment_amount", width: "6%" },
      { Header: "결제 상태", accessor: "order_status_name", width: "6%" },
      { Header: "상세", accessor: "detail", width: "6%" },
    ];
  }, []);

  const dataToRow = (data, index) => {
    const date = moment(`${data.schedule_date} ${data.schedule_time}`);

    return {
      no: getTextCell(data.order_seq),
      order_no: getTextCell(
        data.order_no === data.imp_uid
          ? `${data.order_no}`
          : `${data.order_no}\n${data.imp_uid}`,
        `${data.order_seq}`,
        onsDetailClick
      ),
      lep_order_no: getTextCell(data.lep_order_no),
      payment_dt: getTextCell(
        `${moment(data.payment_dt).format("YYYY-MM-DD (dd)")} \n ${moment(
          data.payment_dt
        ).format("A hh:mm:ss")}`
      ),
      applicant_name: getTextCell(data.applicant_name),
      contract: getTextCell(
        `${data.bookclub_contract_name_choose}(${data.bookclub_contract_number_choose}) / ${data.bookclub_child_member_name_choose}(${data.bookclub_child_member_code_choose})`
      ),
      class_name_book_name: getTextCell(
        `${data.class_name} \n 수업 시작일 : ${data.togetherFirstStudySchedule}`
      ),
      class_period: getTextCell(`${data.class_period}개월`),
      confirmation_yn: getTextCell(
        data.confirmation_yn === 1 ? "확정" : "미확정"
      ),
      bookclub_point: getTextCell(`${toCurrency(data.bookclub_point)}P`),
      payment_amount: getTextCell(`${toCurrency(data.payment_amount)}원`),
      // card_payment: getTextCell(`${toCurrency(data.card_payment)}원`),
      order_status_name: getTextCell(data.order_status_name),
      detail: getNormalButton("상세", `${data.order_seq}`, onsDetailClick),
    };
  };

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state !== searchEndDate.state) onSearchClick();
  }, [searchStartDate.state]);

  useEffect(() => {
    if (page != 0) {
      setTablePage(page);
    }
  }, [rows]);

  const onSearchClick = useCallback(() => {
    let searchParams = {
      class_type: 9,
      test_mode_yn: testModeType.state < 0 ? undefined : testModeType.state,
      together_course_code:
        togetherClassCode.state <= 0 ? undefined : togetherClassCode.state,
      worksheet_type: worksheetType.state < 0 ? undefined : worksheetType.state,
      confirmation_yn: confirmType.state < 0 ? undefined : confirmType.state,
      order_status:
        orderStatusType.state == 0 ? undefined : orderStatusType.state,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      query_type: searchTargetType.state,
      query: searchText.state,
    };
    setSearchParams(searchParams);
  }, [
    classType.state,
    togetherClassCode.state,
    testModeType.state,
    worksheetType.state,
    confirmType.state,
    orderStatusType.state,
    searchDateType.state,
    searchStartDate.state,
    searchEndDate.state,
    searchTargetType.state,
    searchText.state,
  ]);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  const onDetailClose = useCallback(() => {
    orderDetailModal.handleClose();
  });

  const onChangeTablePage = useCallback((page) => {
    setPage(page);
  });

  const onInitClick = useCallback(() => {
    classType.reset();
    togetherClassCode.reset();
    worksheetType.reset();
    orderStatusType.reset();
    confirmType.reset();
    payMethodType.reset();
    searchDateType.reset();
    searchPeriodType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
    testModeType.reset();
  }, [
    classType.reset,
    togetherClassCode.reset,
    worksheetType.reset,
    searchDateType.reset,
    orderStatusType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
    testModeType.reset,
  ]);

  function onsDetailClick(no) {
    setSelectedOrderSeq(no);
    orderDetailModal.visible(true);
  }

  const [downKey, setDownKey] = useState("");
  const clickDownload = (order) => {
    if (order === "투게더 결제 내역 다운로드") setDownKey("togetherPayment");
    else if (order === "투게더 매출 내역 다운로드") setDownKey("togetherSales");

    downloadReasonModal.visible(true);
  };

  const clickDownloadList = () => {
    downloadListModal.visible(true);
  };

  return (
    <>
      <DownloadReasonModal
        show={downloadReasonModal.state}
        handleClose={downloadReasonModal.handleClose}
        downKey={downKey}
        yyyymm={`${yearTypeDwn.state}${monthTypeDwn.state}`}
      />
      <DownloadListModal
        show={downloadListModal.state}
        handleClose={downloadListModal.handleClose}
        type={"TOGETHER_ORDER"}
      />

      <DashboardNavbar title="결제 관리" />
      <LACard>
        <LAProperty name="투게더 과목">
          <LAGridContainer>
            <LACodeComboBox
              wPercent={20}
              showAll={true}
              codeType="TOGETHER_COURSE_CODE"
              value={togetherClassCode.state}
              onChange={togetherClassCode.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LARadioGroup
            wPercent={20}
            props={[{ value: 9, text: "투게더" }]}
            value={classType.state}
            onChange={classType.handleChange}
          />
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LAAltRadioGroup
            showAll={true}
            positiveLabel={"시크릿 포함"}
            positiveValue={1}
            negativeLabel={"시크릿 제외"}
            negativeValue={0}
            value={testModeType.state}
            onChange={testModeType.handleChange}
          />
        </LAProperty>
        <LAProperty name="교재 여부">
          <LARadioGroup
            props={[
              { text: "전체", value: -1 },
              { text: "있음", value: 1 },
              { text: "없음", value: 2 },
            ]}
            value={worksheetType.state}
            onChange={worksheetType.handleChange}
          />
        </LAProperty>
        <LAProperty name="확정 여부">
          <LARadioGroup
            props={[
              { text: "전체", value: -1 },
              { text: "확정", value: 1 },
              { text: "미확정", value: 0 },
            ]}
            value={confirmType.state}
            onChange={confirmType.handleChange}
          />
        </LAProperty>
        <LAProperty name="결제 상태">
          <LARadioGroup
            props={[
              { text: "전체", value: 0 },
              { text: "결제완료", value: 10 },
              { text: "결제취소", value: 20 },
              { text: "취소대기(반품검수)", value: 21 },
            ]}
            value={orderStatusType.state}
            onChange={orderStatusType.handleChange}
          />
        </LAProperty>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LACodeComboBox
              wPercent={20}
              codeType="ORDER_QUERY_PERIOD_TYPE"
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
            />
            <LATabSelector
              wPercent={40}
              layouts={{ ml: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={17}
              layouts={{ ml: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={1}>~</LALabel>
            <LADatePicker
              wPercent={17}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LACodeComboBox
              wPercent={20}
              codeType="TOGETHER_ORDER_QUERY_TYPE"
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={77}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={"outlined"}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        {authMemberType == 1 && authAdminAuthType == 1 ? (
          <LAGridContainer vAlign="center">
            <LALabel wPercent={46}></LALabel>
            <LAComboBox
              wPercent={10}
              items={[
                { key: "2022", value: "2022년" },
                { key: "2023", value: "2023년" },
                { key: "2024", value: "2024년" },
                { key: "2025", value: "2025년" },
              ]}
              value={yearTypeDwn.state}
              onChange={yearTypeDwn.handleChange}
            />
            <LAComboBox
              layouts={{ pl: { sm: 1 } }}
              wPercent={10}
              items={[
                { key: "01", value: "1월" },
                { key: "02", value: "2월" },
                { key: "03", value: "3월" },
                { key: "04", value: "4월" },
                { key: "05", value: "5월" },
                { key: "06", value: "6월" },
                { key: "07", value: "7월" },
                { key: "08", value: "8월" },
                { key: "09", value: "9월" },
                { key: "10", value: "10월" },
                { key: "11", value: "11월" },
                { key: "12", value: "12월" },
              ]}
              value={monthTypeDwn.state}
              onChange={monthTypeDwn.handleChange}
            />
            <LAButton
              wPercent={10}
              layouts={{ pl: { sm: 1 } }}
              onClick={() => clickDownload("투게더 결제 내역 다운로드")}
            >
              결제 내역 다운로드
            </LAButton>
            <LAButton
              wPercent={10}
              layouts={{ pl: { sm: 1 } }}
              onClick={() => clickDownload("투게더 매출 내역 다운로드")}
            >
              매출 내역 다운로드
            </LAButton>
            <LAButton
              wPercent={10}
              layouts={{ pl: { sm: 1 } }}
              onClick={clickDownloadList}
            >
              다운로드 이력
            </LAButton>
          </LAGridContainer>
        ) : (
          <></>
        )}
        <LAGridContainer>
          <LADataTable2
            columns={columns}
            loadUrl={queries.Together.getOrderList}
            params={searchParams}
            dataToRow={dataToRow}
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
          />
        </LAGridContainer>
      </LACard>

      <TogetherOrderDetailModal
        orderSeq={selectedOrderSeq}
        show={orderDetailModal.state}
        handleClose={onDetailClose}
      />
    </>
  );
}

export default TogetherOrderManagement;
