import React, { useState, useCallback, useMemo } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  getTextCell,
  getNormalButton,
  getCheckCell,
} from 'modules/Widgets';

function DormantMemberList() {
  const navigate = useNavigate();

  function onActiveUserClick(no) {}

  const columns = useMemo(() => {return [
    { Header: 'No', accessor: 'no', width: '4%' },
    { Header: '아이디', accessor: 'id', width: '4%' },
    { Header: '이름', accessor: 'name', width: '8%' },
    // { Header: '등급', accessor: 'grade', width: '10%' },
    { Header: '누적 구매금액', accessor: 'purchase_price', width: '17%' },
    { Header: '누적 환불금액', accessor: 'refund_price', width: '17%' },
    { Header: '포인트', accessor: 'point', width: '5%' },
    { Header: '최근 방문기기', accessor: 'visit_device', width: '7%' },
    { Header: '최근 방문일', accessor: 'visit_date', width: '5%' },
    { Header: '가입일', accessor: 'regist_date', width: '7%' },
    { Header: '상태', accessor: 'status', width: '8%' },
    { Header: '마케팅', accessor: 'marketing_yn', width: '8%' },
    { Header: '휴면 해제', accessor: 'active', width: '8%' },
  ]}, []);

  const rows = [
    // {
    //   no: getTextCell('3'),
    //   id: getTextCell('minji***'),
    //   name: getTextCell('윤민지'),
    //   // grade: getTextCell('슈퍼 올'),
    //   purchase_price: getTextCell('1,200,000'),
    //   refund_price: getTextCell('400,000'),
    //   point: getTextCell('125,000'),
    //   visit_device: getTextCell('PC'),
    //   visit_date: getTextCell('2022-05-22'),
    //   regist_date: getTextCell('2022-05-22'),
    //   status: getTextCell('정상'),
    //   marketing_yn: getTextCell('Y'),
    //   active: getNormalButton('휴면 해제', '3', onActiveUserClick),
    // },
    // {
    //   no: getTextCell('3'),
    //   id: getTextCell('minji***'),
    //   name: getTextCell('윤민지'),
    //   // grade: getTextCell('슈퍼 올'),
    //   purchase_price: getTextCell('1,200,000'),
    //   refund_price: getTextCell('400,000'),
    //   point: getTextCell('125,000'),
    //   visit_device: getTextCell('PC'),
    //   visit_date: getTextCell('2022-05-22'),
    //   regist_date: getTextCell('2022-05-22'),
    //   status: getTextCell('정상'),
    //   marketing_yn: getTextCell('Y'),
    //   active: getNormalButton('휴면 해제', '3', onActiveUserClick),
    // },
    // {
    //   no: getTextCell('3'),
    //   id: getTextCell('minji***'),
    //   name: getTextCell('윤민지'),
    //   // grade: getTextCell('슈퍼 올'),
    //   purchase_price: getTextCell('1,200,000'),
    //   refund_price: getTextCell('400,000'),
    //   point: getTextCell('125,000'),
    //   visit_device: getTextCell('PC'),
    //   visit_date: getTextCell('2022-05-22'),
    //   regist_date: getTextCell('2022-05-22'),
    //   status: getTextCell('정상'),
    //   marketing_yn: getTextCell('Y'),
    //   active: getNormalButton('휴면 해제', '3', onActiveUserClick),
    // },
  ];

  // useEffect(() => {
  //   startDate.selectByPeriod(periodTab.state);
  //   endDate.reset();
  // }, [periodTab.state, startDate.selectByPeriod, endDate.reset]);

  return (
    <>
      <DashboardNavbar title="휴면 회원" />

      <LACard>
        {/* <LAProperty name="회원 등급">
            <LARadioGroup
              props={[
                {
                  text: '전체',
                  value: 0,
                },
                {
                  text: '라이브 올',
                  value: 1,
                },
                {
                  text: '어썸 올',
                  value: 2,
                },
                {
                  text: '슈퍼 올',
                  value: 3,
                },
              ]}
            />
          </LAProperty> */}

        <LAProperty name="최근 방문기기">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: 'PC',
                value: 1,
              },
              {
                text: 'Mobile web',
                value: 2,
              },
            ]}
          />
        </LAProperty>

        <LAProperty name="마케팅 동의">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '동의',
                value: 1,
              },
              {
                text: '미동의',
                value: 2,
              },
            ]}
          />
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '이름' },
              ]}
              value={0}
              // onChange={onComboValueChanged}
            />
            <LATabSelector
              wPercent={45}
              items={[
                { key: 0, value: '1개월' },
                { key: 1, value: '3개월' },
                { key: 2, value: '6개월' },
                { key: 3, value: '1년' },
              ]}
              layouts={{ pl: 2 }}
              // onChange={onTabChanged}
            />
            <LADatePicker
              wPercent={15}
              defaultValue="2022-06-03"
              layouts={{ ml: 2, mr: 2 }}
            />{' '}
            ~{' '}
            <LADatePicker
              wPercent={15}
              defaultValue="2022-06-03"
              layouts={{ ml: 2 }}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '이름' },
              ]}
              value={0}
              // onChange={onComboValueChanged}
            />
            <LAInput layouts={{ pl: { sm: 1 } }} wPercent={85} />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 2 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }}>
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={'outlined'}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable columns={columns} rows={rows} />
        </LAGridContainer>
      </LACard>
    </>
  );
}

export default DormantMemberList;
