import React, { useEffect,useState, useCallback } from 'react';
import moment from 'moment';
import {  
  LAListView,
} from 'modules/Widgets';
function CurriculumList({curriculums}){

  const headers = [
    'No',
    '커리큘럼명',
    '정상가',
    '할인가',
    '할인율',
    '삭제',
  ]

  const discountRate = useCallback((item) =>{

    if(item.price > 0 && item.discount_price > 0){
      let diffPrice = item.price - item.discount_price
      if(diffPrice > 0)
      if (diffPrice > 0) return ((diffPrice / item.price) * 100).toFixed(0);
      else return 0
    }else{
      return 0
    }
  },[])

  var items = []
  if(curriculums){
    items = []
    curriculums.forEach((item,index) => {

      items.push([++index
        ,item.curriculum_name        
        ,`${item.price}원`
        ,`${item.discount_price}원`
        ,`${discountRate(item)}%`
        ,'삭제'
      ])
    });
      
  }
  return (
    <>
      <LAListView
        headers={headers}
        items = {items}
        />
    </>
  );
}
export default React.memo(CurriculumList);