import React, { useState, useCallback, useMemo } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';
import LAGridItem from './LAGridItem';
import Icon from '@mui/material/Icon';

function LAPagination({
  wPercent = 100,
  layouts = {},
  items = [],
  pagePerCount = 10,
  page,
  onPageChange,
}) {
  const totalPageCount = useMemo(() => {
    const page = parseInt(items.length / pagePerCount);
    return page + (items.length % pagePerCount > 0 ? 1 : 0);
  }, [items, pagePerCount]);

  const canPreviousPage = useMemo(() => {
    return page <= 1 ? false : true;
  }, [page]);

  const canNextPage = useMemo(() => {
    return page >= totalPageCount ? false : true;
  }, [page, totalPageCount]);

  const onPageChangeClick = useCallback(
    (index) => {
      if (onPageChange) {
        onPageChange(index);
      }
    },
    [onPageChange],
  );

  const onPageInputChange = useCallback(
    (e) => {
      if (onPageChange) {
        const value = parseInt(e.target.value);
        if (e.target.value.length > 0 && value > 0 && value <= totalPageCount) {
          onPageChange(value);
        }
      }
    },
    [onPageChange, totalPageCount],
  );

  const onPrevPageClick = useCallback(() => {
    if (onPageChange) {
      onPageChange(page - 1);
    }
  }, [page, onPageChange]);

  const onNextPageClick = useCallback(() => {
    if (onPageChange) {
      onPageChange(page + 1);
    }
  }, [page, onPageChange]);

  const renderPagination = useMemo(() => {
    const result = [];
    for (let i = 0; i < totalPageCount; i++) {
      result.push(
        <MDPagination
          item
          key={i}
          onClick={() => onPageChangeClick(i + 1)}
          active={page === i + 1}
        >
          {i + 1}
        </MDPagination>,
      );
    }
    return result;
  }, [page, totalPageCount, onPageChangeClick]);

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <MDPagination variant="gradient" color="info">
        {canPreviousPage && (
          <MDPagination item onClick={onPrevPageClick}>
            <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
          </MDPagination>
        )}
        {renderPagination.length > 10 ? (
          <MDBox width="5rem" mx={1}>
            <MDInput
              inputProps={{
                type: 'number',
                min: 1,
                max: totalPageCount,
              }}
              value={page}
              onChange={onPageInputChange}
            />
          </MDBox>
        ) : (
          renderPagination
        )}
        {canNextPage && (
          <MDPagination item onClick={onNextPageClick}>
            <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
          </MDPagination>
        )}
      </MDPagination>
    </LAGridItem>
  );
}

export default LAPagination;
