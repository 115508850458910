import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { getTodayDateText, dateTimeToString } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import { useLoginContext } from 'common/context/MemberContext';

import ClassReviewDetailModal from './components/ClassReviewDetailModal';

import moment from 'moment';
import { periodItems } from 'common/constants';
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  useRadioGroup,
  LAButton,
  LAComboBox,
  useComboBox,
  LAInput,
  useInput,
  LADatePicker,
  useDatePicker,
  LATabSelector,
  LADataTable2,
  getTextCell,
  getNormalButton,
  LACodeRadioGroup,
  useTabSelector,
  useModal,
} from 'modules/Widgets';

// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

function ClassReviewManagement() {
  const { isLogin, isTeacher, action } = useLoginContext();
  const navigate = useNavigate();
  const todayDateText = getTodayDateText('-');
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();

  const exposureYn = useRadioGroup(-1);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);

  const searchTargetType = useComboBox(0);
  const searchText = useInput('');

  const [selectedReviewSeq, setSelectedReviewSeq] = useState(0);
  const classReviewDetailModal = useModal(false);

  const [tablePage, setTablePage] = useState(0);
  const [page, setPage] = useState(0); // 마지막 페이지네이션

  const [rows, setRows] = useState([]);

  const [ searchParams, setSearchParams ] = useState({});

  const columns = useMemo(() => {
    return [
      { Header: 'No', accessor: 'no', width: '10%' },
      {
        Header: '클래스 명',
        accessor: 'class_name',
        width: '25%',
        align: 'left',
      },
      {
        Header: '후기 내용',
        accessor: 'review_contents',
        width: '25%',
        align: 'left',
      },
      { Header: '작성자', accessor: 'writer', width: '10%' },
      { Header: '노출', accessor: 'view_yn', width: '8%' },
      { Header: '작성일', accessor: 'regist_date', width: '12%' },
      { Header: '상세', accessor: 'detail', width: '10%' },
    ];
  }, []);

  const dataToRow = (data, index) => {
    const date = moment(`${data.schedule_date} ${data.schedule_time}`);
    return {
      no: getTextCell(data.row_no),
      class_name: getTextCell(data.class_name),
      review_contents: getTextCell(
        data.review.length > 20
          ? data.review.slice(0, 20) + ' ...'
          : data.review,
        `${data.review_seq}`,
        onDetailClick,
      ),
      writer: getTextCell(data.user_name),
      view_yn: getTextCell(data.exposure_yn ? '노출' : '미노출'),
      regist_date: getTextCell(
        moment(data.created_dt).format('YYYY-MM-DD (dd) A hh:mm:ss'),
      ),

      detail: getNormalButton('상세', `${data.review_seq}`, onDetailClick),
      // detail: getNormalButton('상세', `${item.schedule_seq}`, onsDetailClick),
    };
  };

  function onDetailClick(no) {
    // showAlert('알림', '준비중입니다.');
    // navigate(`detail/${no}`);
    setSelectedReviewSeq(no);
    classReviewDetailModal.visible(true);
  }

  const onInitClick = useCallback(() => {
    exposureYn.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    exposureYn.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) onSearchClick();
  }, [searchStartDate.state]);

  useEffect(() => {
    if (page != 0) {
      setTablePage(page);
    }
  }, [rows]);

  const onSearchClick = useCallback(() => {
    let searchParams = {
      exposure_yn: exposureYn.state < 0 ? undefined : exposureYn.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      query_type: searchTargetType.state,
      query: searchText.state,
    }
    setSearchParams(searchParams);
  }, [
    exposureYn.state,
    searchStartDate.state,
    searchEndDate.state,
    searchTargetType.state,
    searchText.state,
  ]);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  const onDetailClose = useCallback(() => {
    onSearchClick();
    classReviewDetailModal.handleClose();
  });

  const onChangeTablePage = useCallback((page) => {
    setPage(page);
  });

  return (
    <>
      <DashboardNavbar title="클래스 후기 관리" />

      {/* Start Search Area */}
      <LACard>
        {!isTeacher ? (
          <LAProperty name="노출 여부">
            <LACodeRadioGroup
              codeType="EXPOSURE_BOOLEAN_TYPE"
              showAll={true}
              value={exposureYn.state}
              onChange={exposureYn.handleChange}
            />
          </LAProperty>
        ) : (
          ''
        )}

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LATabSelector
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={20}
              layouts={{ pl: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={2}>~</LALabel>
            <LADatePicker
              wPercent={20}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '작성자' },
                { key: 2, value: '후기 내용' },
                { key: 3, value: '클래스 명' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={50}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable2
            columns={columns}
            loadUrl={ queries.Review.getReviewList }
            params={ searchParams }
            dataToRow={ dataToRow }
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
          />
        </LAGridContainer>
      </LACard>

      <ClassReviewDetailModal
        reviewSeq={selectedReviewSeq}
        show={classReviewDetailModal.state}
        handleClose={onDetailClose}
        handleSelect={classReviewDetailModal}
      />
    </>
  );
}

export default ClassReviewManagement;
