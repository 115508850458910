import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'TOGGLE':
        return !state;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}
function useCheckbox(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const toggle = useCallback(() => {    
    dispatch({
      type: 'TOGGLE'
    });
  }, []);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    toggle();
  }, [state, toggle]);

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  return {
    state,
    handleClick,
    toggle,
    reset,
  };
}

export default useCheckbox;
