import { METHOD } from 'common/hooks/useAxios';
const baseURL = '/admin/default-images';
const ImageApi = (requestFunc) => {
  return {
    Image: {
      getDefaultImages: {
        key: 'getDefaultImages',
        queryFn: (imageType) => requestFunc(METHOD.GET, `${baseURL}?image_type=${imageType}`),
      },
      registDefaultImage: {
        key: 'registDefaultImage',
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}`,data),
      },
      modifyDefaultImage: {
        key: 'modifyDefaultImage',
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}/${data.image_seq}`,{...data.use_yn}),
      },
    },
  };
};

export default ImageApi;
