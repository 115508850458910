import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/coupon-policy';

const CouponApi = (requestFunc) => {
  return {
    Coupon: {
      getCouponList: {
        key: 'getCouponList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      getCouponDetail: {
        key: 'getCouponDetail',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.id}`),
      },
      getUsedCouponList: {
        key: 'getUsedCouponList',
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/${params.id}/used-coupons`),
      },
      checkCouponCode: {
        key: 'checkCouponCode',
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/check-duplicate/${params.code}?coupon_policy_seq=${params.coupon_policy_seq}`),
      },
      registCoupon: {
        key: 'registCoupon',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
      },
      updateCoupon: {
        key: 'updateCoupon',
        queryFn: (params) =>
          requestFunc(METHOD.PUT, `${baseURL}/${params.id}`, params),
      },
      deleteCoupon: {
        key: 'deleteCoupon',
        queryFn: (params) =>
          requestFunc(METHOD.DELETE, `${baseURL}/${params.id}`),
      },
      downloadCouponExcel: {
        key: 'downloadCouponExcel',
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/download/csv`, params),
      },
      downloadUsedCouponExcel: {
        key: 'downloadUsedCouponExcel',
        queryFn: (params) =>
          requestFunc(
            METHOD.GET,
            `${baseURL}/${params.id}/used-coupons/download/csv`,
          ),
      },
      publishCoupon: {
        key: 'publishCoupon',
        queryFn: (params) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${params.id}/issuance`),
      },
      cancelPublishCoupon: {
        key: 'cancelPublishCoupon',
        queryFn: (params) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${params.id}/un-issuance`),
      },
      changePublishCoupon: {
        key: 'changePublishCoupon',
        queryFn: (params) =>
          requestFunc(
            METHOD.PATCH,
            `${baseURL}/${params.id}/change-issuance`,
            params,
          ),
      },
    },
  };
};

export default CouponApi;
