import React, {useMemo} from 'react';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import LAGridContainer from './LAGridContainer';
import LAGridItem from './LAGridItem';

import MDTypography from 'components/MDTypography';
import LAWarningLabel from './LAWarningLabel';

function LAModalCardTitle({ 
  modalPopupState,
  title = 'title',
  content,
  warning,
  optionButtons,
}) {
  if (!modalPopupState) {
    throw new Error("modalPopupState is undefined")
  }

  const style = useMemo(
    () => ({
        bgcolor: '#ffffff',
        border: '2px solid #757575',
    }),
    [],
  );
      
  return (
    <MDBox className="laModalCardTitle">
      <LAGridContainer>
        <LAGridItem pr={{ xs: 0, sm: 0.5 }} pb={{ xs: 1 }}>
          <Card variant="outlined" sx={style}>
            <MDBox pt={2} pb={3} pl={5} pr={3}>
              <Grid container>
                <Grid item sm={5}>
                  <MDTypography variant="h5" pt={1}>
                    {title}
                  </MDTypography>
                </Grid>
                <Grid item sm={7}>
                  {content && content.length > 0 && (
                    <MDTypography textAlign="right" variant="h6" pt={1} pr={2}>
                      {content ? content : ''}
                    </MDTypography>
                  )}
                  {warning && warning.length > 0 && (
                    <LAWarningLabel props={{ textAlign: 'right' }}>
                      {warning ? warning : ''}
                    </LAWarningLabel>
                  )}

                  { (optionButtons && optionButtons.length > 0) &&
                    <div style={{ display:"flex", justifyContent: "end" }}>
                      { 
                        optionButtons?.map((item) => 
                          <MDButton
                            className="btnOption"
                            variant={ item.variant? item.variant : "outlined" }
                            size={ item.size? item.size : "small" }
                            color={ item.theme? item.theme : "info" }
                            onClick={item.onClick }
                            startIcon={ item.startIcon }
                            endIcon={ item.endIcon }
                          >
                            {item.text}
                          </MDButton>
                        )
                      }
                    </div>
                  }
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </LAGridItem>
      </LAGridContainer>
    </MDBox>
  );
}

export default React.memo(LAModalCardTitle);
