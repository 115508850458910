/** 
 Once you add a new route on this file it will be visible automatically on
the Sidenav.

For adding a new route you can follow the existing routes in the routes array.
1. The `type` key with the `collapse` value is used for a route.
2. The `type` key with the `title` value is used for a title inside the Sidenav. 
3. The `type` key with the `divider` value is used for a divider between Sidenav items.
4. The `name` key is used for the name of the route on the Sidenav.
5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
8. The `route` key is used to store the route location which is used for the react router.
9. The `href` key is used to store the external links location.
10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
10. The `component` key is used to store the component of its route.
*/

// layouts
// import TermsManagement from 'pages/MallManagement/ServiceInfo/TermsManagement';
// import PrivacyManagement from 'pages/MallManagement/ServiceInfo/PrivacyManagement';
// import RefundManagement from 'pages/MallManagement/ServiceInfo/RefundManagement';

import CategoryManagement from 'pages/Category/CategoryManagement';
import ClassSearch from 'pages/Class/ClassManagement/ClassSearch';
import ClassSchedule from 'pages/Class/ClassManagement/ClassSchedule';
import TogetherClass from 'pages/Together/TogetherClass';
import TogetherOrderManagement from 'pages/Together/TogetherOrderManagement';

// import ClassApplicationRegist from 'pages/Class/ClassManagement/ClassApplicationRegist';
// import ClassApplicationList from 'pages/Class/ClassManagement/ClassApplicationList';
// import ClassRegist from 'pages/Class/ClassManagement/ClassRegist';
import ClassCalendar from 'pages/Class/ClassManagement/ClassCalendar';
import OrderManagement from 'pages/Order/OrderManagement';
import OrderRefundManagement from 'pages/Order/ClaimManagement/OrderRefundManagement';
import OrderExchangeManagement from 'pages/Order/ClaimManagement/OrderExchangeManagement';
import DeliveryManagement from 'pages/Order/DeliveryManagement';
import AllianceDeliveryManagement from "./pages/Order/AllianceDeliveryManagement";

import MainContentsManagement from 'pages/ExhibitionManagement/Home/MainContentsManagement';
import TopMainBannerManagement from 'pages/ExhibitionManagement/Home/TopMainBannerManagement';
import MagazineContents from 'pages/ExhibitionManagement/Home/MagazineContents';
import BestClass from 'pages/ExhibitionManagement/Home/BestClass';
import DiscountClass from 'pages/ExhibitionManagement/Home/DiscountClass';
// import BeltBanner from 'pages/ExhibitionManagement/Home/BeltBanner';
import RecommendClass from 'pages/ExhibitionManagement/Home/RecommendClass';
import VedioBanner from 'pages/ExhibitionManagement/Home/VedioBanner';
import KeywordManagement from 'pages/ExhibitionManagement/Home/KeywordManagement';
import MiddleLineBannerManagement from 'pages/ExhibitionManagement/Home/MiddleLineBannerManagement';
import TopLineBannerManagement from 'pages/ExhibitionManagement/Home/TopLineBannerManagement';
import FloatingBannerManagement from 'pages/ExhibitionManagement/Home/FloatingBannerManagement';
import PopupBannerManagement from 'pages/ExhibitionManagement/Home/PopupBannerManagement';

// import TeacherIntroduce from 'pages/ExhibitionManagement/Home/TeacherIntroduce';
import EventSearch from 'pages/Event/EventManagement/EventSearch';
import MagazineSearch from 'pages/Event/MagazineManagement/MagazineSearch';

import CouponSearch from 'pages/Coupon/CouponSearch';

// import AdminNotice from 'pages/Board/Notice/AdminNotice';
// import NoticeRegist from 'pages/Board/Notice/NoticeRegist';

import ClassReviewManagement from 'pages/InquiryManagement/ClassReviewManagement';
import ClassInquiryManagement from 'pages/InquiryManagement/ClassInquiryManagement';
import ClassFeedbackManagement from 'pages/InquiryManagement/ClassFeedbackManagement';
import RecentFeedbackManagement from 'pages/InquiryManagement/RecentFeedbackManagement';
import FooterBoardManagement from 'pages/InquiryManagement/FooterBoardManagement';
import NoticeManagement from 'pages/InquiryManagement/NoticeManagement';

import TeacherSupportManagement from 'pages/AllianceManagement/TeacherSupportManagement';
import AllianceManagement from 'pages/AllianceManagement/AllianceManagement';
import AllianceInquiryManagement from 'pages/AllianceManagement/AllianceInquiryManagement';
import GroupInquiryManagement from 'pages/AllianceManagement/GroupInquiryManagement';

import AdminList from 'pages/AccountManagement/Admin/AdminList';
import TeacherList from 'pages/AccountManagement/Teacher/TeacherList';
import AccountList from 'pages/AccountManagement/User/AccountList';
import WithdrawalMemberList from 'pages/AccountManagement/User/WithdrawalMemberList';
import DormantMemberList from 'pages/AccountManagement/User/DormantMemberList';
import PointManagement from 'pages/AccountManagement/PointManagement/PointManagement';

import SignIn from 'pages/Authorization/SignIn';

import CalculateCommissionManagement from 'pages/Calculate/CalculateCommissionManagement';
import CalculateSettlementManagement from 'pages/Calculate/CalculateSettlementManagement';
import SapCostHandlingManagement from 'pages/Calculate/SapCostHandlingManagement';

// @mui icons
import Icon from '@mui/material/Icon';
import MainMenuManagement from 'pages/ExhibitionManagement/Home/MainMenuManagement';

import TogetherDeliveryManagement from 'pages/Together/TogetherDeliveryManagement';
import TogetherClassInquiryManagement from 'pages/Together/TogetherClassInquiryManagement';
import TogetherClassCancelManagement from 'pages/Together/TogetherClassCancelManagement';

const routes = [
  // {
  //   type: 'collapse',
  //   name: '몰 관리',
  //   key: 'mall',
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   auth: [{ memberType: 1, authType: 1 }],
  //   collapse: [
  //     {
  //       name: '서비스 이용약관',
  //       key: 'terms-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/1',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  //     {
  //       name: '개인정보 처리방침',
  //       key: 'privacy-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/2',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  //     {
  //       name: '환불규정',
  //       key: 'refund-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/3',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  //     {
  //       name: '선생님 서비스 이용약관',
  //       key: 'teacher-terms-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/4',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  // {
  //   name: '서비스 정보',
  //   key: 'service',
  //   icon: <Icon fontSize="medium">info</Icon>,
  //   auth: [{ memberType: 1, authType: 2 }],
  //   collapse: [
  //     {
  //       name: '서비스 이용약관',
  //       key: 'terms-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/1',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  //     {
  //       name: '개인정보 처리방침',
  //       key: 'privacy-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/2',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  //     {
  //       name: '환불규정',
  //       key: 'refund-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/3',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  //     {
  //       name: '선생님 서비스 이용약관',
  //       key: 'teacher-terms-management',
  //       icon: <Icon fontSize="medium">check_circle_outline</Icon>,
  //       href: '/mall/service/terms/4',
  //       auth: [{ memberType: 1, authType: 2 }],
  //     },
  //   ],
  // },
  //   ],
  // },

  {
    type: 'collapse',
    name: '클래스 관리',
    key: 'class',
    icon: <Icon fontSize="medium">class</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 2 },
      { memberType: 1, authType: 5 },
    ],
    collapse: [
      {
        name: '카테고리 관리',
        key: 'category-management',
        icon: <Icon fontSize="medium">dns</Icon>,
        route: '/class/category',
        component: <CategoryManagement />,
        auth: [{ memberType: 1, authType: 4 }],
      },
      // {
      //   name: '신규 클래스',
      //   key: 'class-new',
      //   icon: <Icon fontSize="medium">list</Icon>,
      //   route: '/class/applications',
      //   component: <ClassApplicationList />,
      //   auth: [{ memberType: 1, authType: 4 }, { memberType: 2 }],
      // },
      {
        name: '클래스 개설 및 등록 관리',
        key: 'class-list',
        icon: <Icon fontSize="medium">fact_check</Icon>,
        route: '/class/list',
        component: <ClassSearch />,
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 2 },
          { memberType: 1, authType: 5 },
        ],
      },
      {
        name: '클래스 일정 및 판매 변경',
        key: 'class-schedule',
        icon: <Icon fontSize="medium">fact_check</Icon>,
        route: '/class/schedules',
        component: <ClassSchedule />,
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 2 },
          { memberType: 1, authType: 5 },
        ],
      },
      {
        name: '화상방 수업 관리',
        key: 'class-calendar',
        icon: <Icon fontSize="medium">calendar_month</Icon>,
        route: '/class/calendar',
        component: <ClassCalendar />,
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 2 },
          { memberType: 1, authType: 5 },
        ],
      },
    ],
  },
  /*
  {
    type: 'collapse',
    name: '투게더관리',
    key: 'together',
    icon: <Icon fontSize="medium">article</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 2 },
      { memberType: 1, authType: 5 },
    ],
    collapse: [
      {
        name: '투게더 클래스 관리',
        key: 'together-class',
        icon: <Icon fontSize="medium">checklist_rtl</Icon>,
        route: '/together/classList',
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 2 },
          { memberType: 1, authType: 5 },
        ],
        component: <TogetherClass />,
      },
      {
        name: '투게더 주문 현황',
        key: 'together-order-management',
        icon: <Icon fontSize="medium">local_shipping_outlined</Icon>,
        route: '/together/order/management',
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 2 },
          { memberType: 1, authType: 5 },
        ],
        component: <TogetherOrderManagement />,
      }
    ]

  },
*/

    /* {
        type: 'collapse',
        name: '카테고리 관리',
        key: 'category-management',
        icon: <Icon fontSize="medium">dns</Icon>,
        route: '/category',
        noCollapse: true,
        component: <CategoryManagement />,
        auth: [{ memberType: 1, authType: 4 }],
    }, */
    {
        type: 'collapse',
        name: '주문 관리',
        key: 'order',
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        auth: [
            { memberType: 1, authType: 4 },
            { memberType: 1, authType: 5 },
            { memberType: 2, teacherType: 2 },
        ],
        collapse: [
            {
                name: '결제 관리',
                key: 'order-management',
                icon: <Icon fontSize="medium">checklist_rtl</Icon>,
                route: '/order/list',
                auth: [
                    { memberType: 1, authType: 4 },
                    { memberType: 1, authType: 5 },
                    { memberType: 2, teacherType: 2 },
                ],
                component: <OrderManagement />,
            },
            {
              name: '배송 관리(자사)',
              key: 'order-delivery-management',
              icon: <Icon fontSize="medium">local_shipping_outlined</Icon>,
              route: '/order/delivery/list',
              auth: [
                  { memberType: 1, authType: 4 },
                  { memberType: 1, authType: 5 },
                  { memberType: 2, teacherType: 2 },
              ],
              component: <DeliveryManagement />,
          },
          {
              name: '배송 관리(제휴)',
              key: 'order-AllianceDelivery-management',
              icon: <Icon fontSize="medium">local_shipping_outlined</Icon>,
              route: '/order/AllianceDelivery/list',
              auth: [
                  { memberType: 1, authType: 4 },
                  { memberType: 1, authType: 5 },
                  { memberType: 2, teacherType: 2 },
              ],
              component: <AllianceDeliveryManagement />,
          },
            {
                name: '클레임 관리',
                key: 'claim',
                icon: <Icon fontSize="medium">support_agent</Icon>,
                auth: [{ memberType: 1, authType: 4 }],
                collapse: [
                    {
                        name: '취소 관리',
                        key: 'order-refund-management',
                        icon: <Icon fontSize="medium">event_busy</Icon>,
                        route: '/order/claim/refund',
                        component: <OrderRefundManagement />,
                        auth: [{ memberType: 1, authType: 4 }],
                    },
                    {
                        name: '교환 관리',
                        key: 'order-exchange-management',
                        icon: <Icon fontSize="medium">change_circle</Icon>,
                        route: '/order/claim/exchange',
                        component: <OrderExchangeManagement />,
                        auth: [{ memberType: 1, authType: 4 }],
                    },
                ],
            },
        ],
    },

    {
        type: 'collapse',
        name: '문의 관리',
        key: 'inquiry',
        icon: <Icon fontSize="medium">live_help</Icon>,
        auth: [
            { memberType: 1, authType: 4 },
            { memberType: 2 },
            { memberType: 1, authType: 5 },
        ],
        collapse: [
            {
                name: '클래스 후기 관리',
                key: 'class-review-management',
                icon: <Icon fontSize="medium">rate_review</Icon>,
                route: '/inquiry/review',
                component: <ClassReviewManagement />,
            },
            {
                name: '클래스 문의 관리',
                key: 'class-inquiry-management',
                icon: <Icon fontSize="medium">quiz</Icon>,
                route: '/inquiry/inquiry',
                component: <ClassInquiryManagement />,
            },
            {
                type: 'collapse',
                name: '피드백 관리',
                key: 'feedback',
                icon: <Icon fontSize="medium">forum</Icon>,
                collapse: [
                    {
                        name: '클래스 피드백 관리',
                        key: 'class-feedback-management',
                        icon: <Icon fontSize="medium">forum</Icon>,
                        route: '/inquiry/feedback/classfeedback',
                        component: <ClassFeedbackManagement />,
                    },
                    {
                        name: '최신 피드백 관리',
                        key: 'recent-feedback-management',
                        icon: <Icon fontSize="medium">announcement</Icon>,
                        route: '/inquiry/feedback/recentfeedback',
                        component: <RecentFeedbackManagement />,
                    },
                ],
            },
            {
                name: '공지사항 관리',
                key: 'footer-board-management',
                icon: <Icon fontSize="medium">quiz</Icon>,
                route: '/inquiry/footer/board',
                component: <NoticeManagement />,
            },
        ],
    },
// {
//   type: 'collapse',
//   name: '게시판',
//   key: 'board',
//   icon: <Icon fontSize="medium">assignment</Icon>,
//   auth: [{ memberType: 1, authType: 2 }],
//   collapse: [
//     // {
//     //   name: '공지사항',
//     //   key: 'notice',
//     //   icon: <Icon fontSize="medium">manage_search</Icon>,
//     //   collapse: [
//     //     {
//     //       name: '관리자 공지사항',
//     //       key: 'admin-notice',
//     //       icon: <Icon fontSize="medium">speaker_notes</Icon>,
//     //       route: '/board/notice/admin',
//     //       component: <AdminNotice />,
//     //       auth: [{ memberType: 1, authType: 2 }],
//     //     },
//     //     {
//     //       name: '공지사항 등록',
//     //       key: 'notice-regist',
//     //       icon: <Icon fontSize="medium">playlist_add</Icon>,
//     //       route: '/board/notice/regist',
//     //       component: <NoticeRegist />,
//     //       auth: [{ memberType: 1, authType: 1 }],
//     //     },
//     //   ],
//     // },
//   ],
// },

    {
        type: 'collapse',
        name: '사용자 관리',
        key: 'user',
        icon: <Icon fontSize="medium">person</Icon>,
        auth: [
            { memberType: 1, authType: 1 },
            { memberType: 1, authType: 5 },
        ],
        collapse: [
            {
                name: '회원 관리',
                key: 'member-list',
                icon: <Icon fontSize="medium">group</Icon>,
                route: '/user/members/list',
                component: <AccountList />,
                auth: [{ memberType: 1, authType: 4 }],
            },
            {
                name: '선생님 관리',
                key: 'teacher-list',
                icon: <Icon fontSize="medium">school</Icon>,
                route: '/user/teacher/list',
                component: <TeacherList />,
                auth: [
                    { memberType: 1, authType: 2 },
                    { memberType: 1, authType: 5 },
                ],
            },
            {
                name: '관리자 관리',
                key: 'admin-list',
                icon: <Icon fontSize="medium">manage_accounts</Icon>,
                route: '/user/admin/list',
                component: <AdminList />,
                auth: [{ memberType: 1, authType: 1 }],
            }
        ],
    },
    {
      type: 'collapse',
      name: '제휴 관리',
      key: 'alliance',
      icon: <Icon fontSize="medium">hail</Icon>,
      auth: [{ memberType: 1, authType: 2 }],
      collapse: [
          {
              name: '제휴/협력사 관리',
              key: 'alliance-management',
              icon: <Icon fontSize="medium">contact_emergency</Icon>,
              route: '/alliance/aliance',
              component: <AllianceManagement />,
          },
          {
              name: '선생님 지원 승인',
              key: 'teacher-support-management',
              icon: <Icon fontSize="medium">contact_phone</Icon>,
              route: '/alliance/teacher',
              component: <TeacherSupportManagement />,
          },
          // {
          //   name: '제휴/협력 문의 관리',
          //   key: 'alliance-inquiry-management',
          //   icon: <Icon fontSize="medium">contact_support</Icon>,
          //   route: '/alliance/alliance_inquriy',
          //   component: <AllianceInquiryManagement />,
          // },
          // {
          //   name: '단체 교육 문의 관리',
          //   key: 'group-inquiry-management',
          //   icon: <Icon fontSize="medium">contact_support</Icon>,
          //   route: '/alliance/group_inquiry',
          //   component: <GroupInquiryManagement />,
          // },
      ],
  },


  {
    type: 'collapse',
    name: '전시 관리',
    key: 'main',
    icon: <Icon fontSize="medium">view_list</Icon>,
    auth: [{ memberType: 1, authType: 2 }],
    collapse: [
      {
        name: '메인 메뉴 관리',
        key: 'main-menu',
        icon: <Icon fontSize="medium">view_agenda</Icon>,
        route: '/main/menu',
        component: <MainMenuManagement />,
      },
      {
        name: '키워드 관리',
        key: 'keyword-management',
        icon: <Icon fontSize="medium">videocam</Icon>,
        route: '/main/home/keyword',
        component: <KeywordManagement />,
      },

      {
        name: '전시 노출 관리',
        key: 'event-search',
        icon: <Icon fontSize="medium">preview</Icon>,
        route: '/main/contents',
        component: <MainContentsManagement />,
      },
      {
        name: '상단 띠배너 등록 관리',
        key: 'top-line-banner',
        icon: <Icon fontSize="medium">view_carousel</Icon>,
        route: '/main/home/top/banner',
        component: <TopLineBannerManagement />,
      },
      {
        name: '상단 배너 등록 관리',
        key: 'top-main-banner',
        icon: <Icon fontSize="medium">view_carousel</Icon>,
        route: '/main/home/banner/1',
        component: <TopMainBannerManagement />,
      },
      // {
      //   name: '홈(메인)',
      //   key: 'home',
      //   icon: <Icon fontSize="medium">home</Icon>,
      //   collapse: [
      {
        name: '중간 띠배너 등록 관리',
        key: 'middle-line-banner',
        icon: <Icon fontSize="medium">view_carousel</Icon>,
        route: '/main/home/middle/banner',
        component: <MiddleLineBannerManagement />,
      },           
      {
        name: '팝업 배너 등록 관리',
        key: 'popup-banner',
        icon: <Icon fontSize="medium">view_carousel</Icon>,
        route: '/main/home/popup/banner',
        component: <PopupBannerManagement />,
      },
      {
        name: '플로팅 배너 등록 관리',
        key: 'floating-banner',
        icon: <Icon fontSize="medium">view_carousel</Icon>,
        route: '/main/home/floating/banner',
        component: <FloatingBannerManagement />,
      },
      // {
      //   name: '영상 배너 등록 관리',
      //   key: 'vedio-banner',
      //   icon: <Icon fontSize="medium">videocam</Icon>,
      //   route: '/main/home/vedio',
      //   component: <VedioBanner />,
      // },
      
      //   ],
      // },
    ],
  },
  {
    type: 'collapse',
    name: '이벤트/매거진',
    key: 'event',
    icon: <Icon fontSize="medium">event</Icon>,
    auth: [{ memberType: 1, authType: 2 }],
    collapse: [
      {
        name: '이벤트 관리',
        key: 'event-search',
        icon: <Icon fontSize="medium">celebration</Icon>,
        route: '/event/search',
        component: <EventSearch />,
      },
      {
        name: '매거진 관리',
        key: 'event-regist',
        icon: <Icon fontSize="medium">newspaper</Icon>,
        route: '/event/regist',
        component: <MagazineSearch />,
      },
    ],
  },

  // {
  //     type: 'collapse',
  //     name: '쿠폰 관리',
  //     key: 'coupon',
  //     icon: <Icon fontSize="medium">event</Icon>,
  //     auth: [{ memberType: 1, authType: 2 }],
  //     collapse: [
  //         {
  //             name: '쿠폰 목록',
  //             key: 'coupon-list',
  //             icon: <Icon fontSize="medium">local_activity</Icon>,
  //             route: '/coupon/search',
  //             component: <CouponSearch />,
  //         },
  //     ],
  // },
  {
    type: 'collapse',
    name: '혜택 관리',
    key: 'benefit',
    icon: <Icon fontSize="medium">redeem</Icon>,
    auth: [{ memberType: 1, authType: 4 }],
    collapse: [
      // {
      //     name: '탈퇴 회원',
      //     key: 'withdrawal-member-list',
      //     icon: <Icon fontSize="medium">group_remove</Icon>,
      //     route: '/members/withdrawal/list',
      //     component: <WithdrawalMemberList />,
      //     auth: [{ memberType: 1, authType: 4 }],
      // },
      // {
      //   name: '휴면 회원',
      //   key: 'dormant-member-list',
      //   icon: <Icon fontSize="medium">people</Icon>,
      //   route: '/members/dormant/list',
      //   component: <DormantMemberList />,
      //   auth: [{ memberType: 1, authType: 4 }],
      // },
      {
        name: '올머니 관리',
        key: 'point-management',
        icon: <Icon fontSize="medium">add_card</Icon>,
        route: '/benefit/point/list',
        component: <PointManagement />,
        auth: [
          { memberType: 1, authType: 2 },
          { memberType: 1, authType: 4 },
        ],
      },
      {
        name: '쿠폰 관리',
        key: 'coupon-management',
        icon: <Icon fontSize="medium">local_activity</Icon>,
        route: '/benefit/coupon/list',
        component: <CouponSearch />,
        auth: [{ memberType: 1, authType: 4 }],
      },
    ],
  },
  {
    type: 'collapse',
    name: '정산 관리',
    key: 'calculate',
    noCollapse: true,
    icon: <Icon fontSize="medium">modey</Icon>,
    auth: [
      { memberType: 1, authType: 2 },
      { memberType: 1, authType: 5 },
    ],
    collapse: [
      {
        name: '수수료 관리',
        key: 'calculate-commission',
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        route: '/calculate/commission',
        component: <CalculateCommissionManagement />,
        auth: [{ memberType: 1, authType: 1 }],
      },
      {
        name: '정산 금액 확인',
        key: 'calculate-settlement',
        icon: <Icon fontSize="medium">point_of_sale</Icon>,
        route: '/calculate/settlement',
        component: <CalculateSettlementManagement />,
        auth: [
          { memberType: 1, authType: 2 },
          { memberType: 1, authType: 5 },
        ],
      },
      {
        name: 'SAP 비용 처리',
        key: 'calculate-cost',
        icon: <Icon fontSize="medium">price_change</Icon>,
        route: '/calculate/cost',
        component: <SapCostHandlingManagement />,
        auth: [{ memberType: 1, authType: 1 }],
      },
    ],
  },

  {
    type: 'collapse',
    name: '로그인',
    key: 'sign-in',
    noCollapse: true,
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: '/auth',
    component: <SignIn />,
  },
  // {
  //   type: 'collapse',
  //   name: '강사등록',
  //   key: 'teacher-join',
  //   noCollapse: true,
  //   icon: <Icon fontSize="small">account_circle</Icon>,
  //   route: '/auth/terms',
  //   component: <TeacherJoinTerms />,
  // },
  /* {
    type: "collapse",
    name: "투게더 클래스 관리",
    key: "together-class-management",
    icon: <Icon fontSize="medium">class</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 2 },
      { memberType: 1, authType: 5 }
    ],
    collapse: [
      {
        name: "투게더 개설 및 등록 관리",
        key: "together-class",
        icon: <Icon fontSize="medium">dns</Icon>,
        route: "/together/class",
        component: <TogetherClass />,
        auth: [{ memberType: 1, authType: 4 }]
      }
    ],
  },
  {
    type: "collapse",
    name: "투게더 주문 관리",
    key: "together-order-management",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 1, authType: 5 },
      { memberType: 2, teacherType: 2 },
    ],
    collapse: [
      {
        name: "결제 관리",
        key: "together-order",
        icon: <Icon fontSize="medium">checklist_rtl</Icon>,
        route: "/together/order",
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 1, authType: 5 },
          { memberType: 2, teacherType: 2 },
        ],
        component: <TogetherOrderManagement />,
      },
      {
        name: "배송 관리",
        key: "together-delivery",
        icon: <Icon fontSize="medium">local_shipping_outlined</Icon>,
        route: "/together/order/delivery",
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 1, authType: 5 },
          { memberType: 2, teacherType: 2 },
        ],
        component: <TogetherDeliveryManagement />
      }
    ]
  },
  {
    type: "collapse",
    name: "투게더 문의 관리",
    key: "together-inquiry-management",
    icon: <Icon fontSize="medium">live_help</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 2 },
      { memberType: 1, authType: 5 },
    ],
    collapse: [
      {
        name: "단순 문의",
        key: "together-inquiry",
        icon: <Icon fontSize="medium">rate_review</Icon>,
        route: "/together/inquiry",
        component: <TogetherClassInquiryManagement />
      },
      {
        name: "취소/환불 문의",
        key: "together-cancel",
        icon: <Icon fontSize="medium">quiz</Icon>,
        route: "/together/inquiry/cancel",
        component: <TogetherClassCancelManagement />
      }
    ],
  } */
];

export default routes;
