import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'UPDATE':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useTextField(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const update = useCallback(
    (value) => {
      if (state === value) {
        return;
      }

      dispatch({
        type: 'UPDATE',
        value,
      });
    },
    [state],
  );

  const handleChange = useCallback(
    (e) => {
      update(e.target.value);
    },
    [update],
  );

  const reset = useCallback(() => {
    console.log("reset");
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  return {
    state,
    handleChange,
    update,
    reset,
  };
}

export default useTextField;
