import React, { useMemo, useState, useCallback } from "react";

// import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
// import { useNavigate } from 'react-router-dom';
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import { useLoginContext } from "common/context/MemberContext";

import {
  isValidId,
  isValidPassword,
  isValidPasswordConfirm,
} from "common/utils";

import { AUTH_TYPES } from "common/constants";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAComboBox,
  LAButton,
  LAInput,
  LACodeRadioGroup,
  useRadioGroup,
  useInput,
  useComboBox,
} from "modules/Widgets";

function AdminRegist({ onClickList }) {
  const { queries } = useApiContext();

  const { showAlert, showConfirm } = usePopAlert();

  const nameInput = useInput("");
  const idInput = useInput("");
  const emailInput = useInput("");
  const passwordInput = useInput(null);
  const passwordConfirmInput = useInput("");
  const authType = useRadioGroup(AUTH_TYPES.master);
  const [alliances, setAlliances] = useState([]);

  const selectedAlliance = useComboBox(0);

  const onClickCheckId = () => {
    if (idInput.state.length < 6) {
      showAlert("알림", "아이디는 6자 이상 입력하세요.");
      return;
    }
    requestCheckLoginId();
  };

  const { request: requestAlliances } = useCommonQuery({
    query: queries.Alliance.getAllianceCodes,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code == "0000") {
          setAlliances(data.result_data);
          return;
        }

        showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "업체 정보 조회에 실패하였습니다.");
      },
    },
  });

  const { request: requestCheckLoginId } = useCommonQuery({
    query: queries.Account.checkLoginId,
    params: {
      login_id: idInput.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000")
          showAlert("알림", "사용 가능한 아이디입니다.");
        else showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "아이디 중복 체크에 실패하였습니다.");
      },
    },
    initEnabled: false,
  });

  const getAllianceList = useMemo(() => {
    var list = [];
    if (alliances && alliances.length > 0) {
      list = alliances.map((a) => {
        return { key: a.alliance_seq, value: a.alliance_name };
      });
    }
    return list;
  }, [alliances]);

  const { request: requestRegistAccount } = useCommonMutation({
    query: queries.Account.registAccount,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "관리자가 등록되었습니다.", {
            confirmHandler: onClickList,
          });
        } else showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "관리자 등록에 실패했습니다.");
      },
    },
  });

  const onSaveClick = useCallback(() => {
    console.log(authType.state, passwordInput.state);

    // if (passwordInput.state !== passwordConfirmInput.state) {
    //   showAlert("알림", "비밀번호가 일치하지 않습니다.");
    //   return;
    // }

    // let checkPwd = isValidPassword(passwordInput.state);
    // if (!checkPwd.valid) {
    //   showAlert("알림", "비밀번호는 " + checkPwd.message);
    //   return;
    // }

    showConfirm("알림", "관리자 정보를 저장하시겠습니까?", {
      confirmHandler: () => {
        requestRegistAccount({
          auth_type: authType.state,
          login_id: idInput.state,
          member_name: nameInput.state,
          email: emailInput.state,
          // login_password: null, // null로 보내면 최초 비밀번호는 1111로 설정 // DB 컬럼 삭제로 인해 주석처리
          expired_yn: 0,
          alliance_seq: selectedAlliance.state,
        });
      },
    });
  }, [
    authType.state,
    idInput.state,
    nameInput.state,
    emailInput.state,
    passwordConfirmInput.state,
    passwordInput.state,
    selectedAlliance.state,
    requestRegistAccount,
    showAlert,
  ]);

  return (
    <>
      <LACard>
        <LACardTitle title="관리자 기본 정보" content="" />
        <LAProperty name="이름">
          <LAInput
            wPercent={30}
            placeholder="이름을 입력해주세요."
            onChange={nameInput.handleChange}
          />
        </LAProperty>
        <LAProperty name="아이디">
          <LAGridContainer>
            <LAInput
              wPercent={30}
              placeholder="아이디를 입력해주세요."
              onChange={idInput.handleChange}
            />
            <LAButton
              wPercent={10}
              layouts={{ pl: { sm: 1 } }}
              onClick={onClickCheckId}
            >
              중복체크
            </LAButton>
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="이메일">
          <LAInput
            wPercent={30}
            placeholder="이메일을 입력해주세요."
            onChange={emailInput.handleChange}
          />
        </LAProperty>

        {/* <LAProperty name="비밀번호">
          <LAInput
            type="password"
            wPercent={40}
            placeholder="비밀번호를 입력해주세요."
            onChange={passwordInput.handleChange}
          />
        </LAProperty>
        <LAProperty name="비밀번호 확인">
          <LAInput
            type="password"
            wPercent={40}
            placeholder="비밀번호를 입력해주세요."
            onChange={passwordConfirmInput.handleChange}
          />
        </LAProperty> */}

        <LAProperty name="권한">
          <LACodeRadioGroup
            codeType="ADMIN_TYPE"
            showAll={false}
            value={authType.state}
            onChange={authType.handleChange}
          />
          {authType.state == 5 ? (
            <LAGridContainer>
              <LALabel wPercent={10}>업체 선택</LALabel>
              <LAComboBox
                wPercent={30}
                items={getAllianceList}
                value={selectedAlliance.state}
                onChange={selectedAlliance.handleChange}
              />
            </LAGridContainer>
          ) : (
            <></>
          )}
        </LAProperty>

        <div style={{ fontSize: 12, marginTop: 12, color: "#ff4747" }}>
          * 관리자 계정 생성 시, 최초 비밀번호는 1111로 설정됩니다.
        </div>
      </LACard>
      {/* End Info Area */}

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ mr: 1 }}
          variant={"outlined"}
          btnPaddingY={12}
          onClick={onClickList}
        >
          목록
        </LAButton>
        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onSaveClick}>
          저장
        </LAButton>
      </LAGridContainer>
    </>
  );
}

export default AdminRegist;
