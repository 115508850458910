import { useCallback, useMemo } from 'react';
import axios from 'axios';
import { useLoginContext } from 'common/context/MemberContext';
import { AppConfig } from 'api/AppConfig';

import { useNavigate } from 'react-router-dom';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import { useSpinnerContext } from 'common/components/spinner/context/SpinnerContext';

export const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

const headers = {
  Accept: 'application/json',
  Authorization: '',
};

function useAxios() {
  // const { isLogin, loginState, action } = useLoginContext();
  const { showAlert } = usePopAlert();
  const { action } = useLoginContext();
  const navigate = useNavigate();

  const { hideLoading } = useSpinnerContext().action;

  const accessToken = useMemo(() => {
    return action.getAccessToken();
  }, [action]);

  const client = axios.create();

  client.interceptors.response.use(
    (response) => {
      if (response && response.data && response.data.newToken) {
        action.setAccessToken(response.data.newToken);
        // sessionStorage.setItem('accessToken', response.data.newToken);
        localStorage.setItem('accessToken', response.data.newToken);
      }
      return response;
    },
    (err) => {
      if (err.response && err.response.status && (err.response.status == 401 || err.response.status == 419)) {
        hideLoading();
        showAlert('알림', '더이상 로그인 정보가 유효하지 않습니다.\n다시 로그인하세요');
        onLogout();
      }

      return Promise.reject(err);
    },
  );

  const onLogout = useCallback(() => {
    // localStorage.setItem("accessToken", data.result_data.accessToken);
    localStorage.removeItem('accessToken');
    // sessionStorage.removeItem('accessToken');
    action.resetLoginData();
    navigate('/', { replace: true });
  }, [navigate]);

  const request = useCallback((method, url, args) => {
    let params = {};

    if (method === METHOD.GET) {
      params = { params: args };
    } else {
      params = { data: args };
    }

    if (!args) {
      params = {};
    }

    console.log(method, url, params);
    // if (accessToken) console.log('useAxios.request => accessToken : ', accessToken);

    return client({
      headers: {...headers, Authorization: `Bearer ${accessToken}`},
      method,
      url,
      timeout: 60000,
      ...params,
    });
  },[accessToken]);

  const requestFunc = useCallback(async (method, url, args) => {
    const response = await request(method, `${AppConfig.API_URL}${url}`, args);
    console.log(response);
    return response.data;
  },[request]);

  return { requestFunc };
}

export default useAxios;
