import { useCallback, useEffect, useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSpinnerContext } from 'common/components/spinner/context/SpinnerContext';

// GET
export function useCommonQuery({
  query,
  callbacks = {},
  spinner = false,
  initEnabled = true,
}) {
  const { action } = useSpinnerContext();
  const [enabled, setEnabled] = useState(initEnabled);
  const { showLoading, hideLoading } = action;
  const [ params, setParams ] = useState({});
  const [ spinnerUseStatus, setSpinnerUseStatus ] = useState(false);

  useEffect(() => {
    setSpinnerUseStatus(spinner);
  }, [])

  const result = useQuery([query.key, params], () => query.queryFn(params), {
    ...callbacks,
    enabled,
  });

  const status = useMemo(() => {
    return {
      isLoading: result.isLoading,
      isFetching: result.isFetching,
      isRefetching: result.isRefetching,
      isSuccess: result.isSuccess,
      isError: result.isError,
      isLoadingError: result.isLoadingError,
    };
  }, [
    result.isLoading,
    result.isFetching,
    result.isRefetching,
    result.isSuccess,
    result.isError,
    result.isLoadingError,
  ]);

  const { isLoading, isFetching, isRefetching } = result;

  useEffect(() => {
    if (!spinnerUseStatus) {
      return;
    }

    if (isLoading || isFetching || isRefetching) {
      showLoading();
    } else { 
      hideLoading();
    }
  }, [spinnerUseStatus, isLoading, showLoading, hideLoading]);

  const request = useCallback((params, spinnerUseStatus) => {
      setParams(params);

      if (spinnerUseStatus !== undefined) {
        setSpinnerUseStatus(spinnerUseStatus);
      }
      
      result.refetch();
    },[],
  );

  return { result, status, request };
}

export default useCommonQuery;
