import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { getTodayDateText, dateTimeToString } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';
import FeedbackContentsModal from './components/FeedbackContentsModal';
// import CategoryComboView from './CategoryComboView';
import CategoryComboView from 'pages/Class/components/CategoryComboView';
import UserBatchFeedbackModal from './components/UserBatchFeedbackModal';
import { periodItems } from 'common/constants';
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  useRadioGroup,
  LAButton,
  LAComboBox,
  useComboBox,
  LAInput,
  useInput,
  LADatePicker,
  useDatePicker,
  LATabSelector,
  LADataTable,
  getTextCell,
  getCheckCell,
  getPrimaryButton,
  LACodeRadioGroup,
  LACodeComboBox,
  useTabSelector,
  LARadioGroup,
  useModal, LAAltRadioGroup
} from "modules/Widgets";

// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

function RecentFeedbackManagement() {
  const batchFeedbackModal = useModal(false);

  const navigate = useNavigate();
  const todayDateText = getTodayDateText('-');
  const { queries } = useApiContext();
  // const { showAlert } = usePopAlert();

  const [data, setData] = useState([]);

  const [category, setCategory] = useState(0);
  const classType = useRadioGroup(-1);

  const attachmentYn = useRadioGroup(-1);
  const answerYn = useRadioGroup(-1);

  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);

  const searchTargetType = useComboBox(0);
  const searchText = useInput('');
  const testModeType = useRadioGroup('')

  const [selectedClassmateSeq, setSelectedClassmateSeq] = useState(0);
  const [selectedClassroomSeq, setSelectedClassroomSeq] = useState(0);

  const feedbackContentModal = useModal(false);

  const [addedClassmate, setAddedClassmate] = useState([]);


  const addClassmateClick = useCallback(
    (classmate) => {
      let newClassmate = [];
      let hasClassmate = false;
      addedClassmate.forEach((data) => {
        if (data.classmate_seq != classmate.classmate_seq) {
          newClassmate.push(data);
        } else {
          hasClassmate = true;
        }
      });

      if (!hasClassmate) {
        newClassmate.push(classmate);
      }

      setAddedClassmate(newClassmate);
    },
    [addedClassmate],
  );

  const onFeedbackClick = useCallback((no) => {
    setSelectedClassroomSeq(no.classroomSeq);
    setSelectedClassmateSeq(no.classmateSeq);
    feedbackContentModal.visible(true);
  });

  const columns = useMemo(() => {
    return [
      { Header: '#checkbox#', accessor: 'checkbox', width: '4%' },
      // { Header: 'No', accessor: 'no', width: '4%' },
      // { Header: '코드', accessor: 'code', width: '4%' },
      { Header: '카테고리', accessor: 'category', width: '8%' },
      { Header: '클래스 유형', accessor: 'class_type', width: '10%' },
      {
        Header: '클래스 명',
        accessor: 'class_name',
        width: '17%',
        align: 'left',
      },
      {
        Header: '최신 피드백 내용',
        accessor: 'feedback_contents',
        width: '17%',
        align: 'left',
      },
      { Header: '첨부파일', accessor: 'attach', width: '5%' },
      { Header: '답변', accessor: 'reply_yn', width: '5%' },
      { Header: '작성자', accessor: 'writer', width: '7%' },
      { Header: '선생님 명', accessor: 'teacher_name', width: '7%' },
      { Header: '작성일', accessor: 'regist_date', width: '8%' },
      { Header: '피드백', accessor: 'detail', width: '8%' },
    ];
  }, []);

  const getClassType = useCallback((item) => {
    let result;
    switch(item.progress_type){
      case 0:
        result = '원데이'
        break;
      case 1:
        result = '순환'
        break;
      case 2:
        result = '패키지'
        break;
    }

    if(item.test_mode_yn) {
      result += '[시크릿]'
    }
    return result
  });

  const listItems = useMemo(() => {
    var list = [];
    if (data && data.length > 0) {
      return data.map((row) => {
        const date = moment(`${row.schedule_date} ${row.schedule_time}`);
        return {
          // no: getTextCell(row.row_no),
          // class_name : getTextCell(row.class_name),
          // inquiry_title: getTextCell(row.subject),
          // writer: getTextCell(row.member_name),
          // teacher_name: getTextCell(row.teacher_name),
          // answer_yn : getTextCell(row.answer_yn?'답변':'미답변'),
          // regist_date: getTextCell(moment(row.created_dt).format('YYYY-MM-DD (dd) HH:mm:ss')),

          // detail: getNormalButton('상세', `${row.review_seq}`, onDetailClick),
          // // detail: getNormalButton('상세', `${item.schedule_seq}`, onsDetailClick),

          checkbox: getCheckCell(
            '',
            {
              classroom_seq: row.classroom_seq,
              classmate_seq: row.classmate_seq,
              class_name: row.class_name,
              schedule_date: row.schedule_date,
              schedule_time: row.schedule_time,
              user_name: row.writer_name,
            },
            addClassmateClick,
          ),
          // no: getTextCell(row.row_no),
          // code: getTextCell('126'),
          category: getTextCell(row.category_name),
          class_type: getTextCell(getClassType(row)),
          class_name: getTextCell(
            `${row.class_name}\n클래스 시작일 : ${date.format(
              'YYYY-MM-DD (dd) A hh:mm',
            )}`,
          ),
          feedback_contents: getTextCell(
            row.contents.length > 20
              ? row.contents.slice(0, 20) + ' ...'
              : row.contents,
            {
              classroomSeq: row.classroom_seq,
              classmateSeq: row.classmate_seq,
            },
            onFeedbackClick,
          ),
          attach: getTextCell(row.file_yn == 1 ? 'Y' : 'N'),
          writer: getTextCell(row.writer_name),
          reply_yn: getTextCell(row.child_count > 0 ? 'Y' : 'N'),
          teacher_name: getTextCell(row.teacher_name),
          regist_date: getTextCell(
            moment(row.created_dt).format('YYYY-MM-DD (dd) A hh:mm'),
          ),
          detail: getPrimaryButton(
            '피드백',
            {
              classroomSeq: row.classroom_seq,
              classmateSeq: row.classmate_seq,
            },
            onFeedbackClick,
          ),
        };
      });
    }
    return list;
  }, [data, addClassmateClick]);

  const { result, request: requestFeedbacks } = useCommonQuery({
    query: queries.Feedback.getFeedbackList,
    params: {
      category_seq: category ? category.key : undefined,
      class_type: classType.state < 0 ? undefined : classType.state,
      test_mode_yn: testModeType.state < 0 ? undefined : testModeType.state,
      attachment_yn: attachmentYn.state < 0 ? undefined : attachmentYn.state,

      answer_yn: answerYn.state < 0 ? undefined : answerYn.state,

      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,

      query_type: searchTargetType.state,
      query: searchText.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setData(data.result_data);
        } else {
        }
      },
      onError: (error) => {
      },
    },
    initEnabled: false,
  });

  const onCategoryChanged = useCallback((item) => {
    setCategory(item);
  }, []);

  const onFeedbackContntsClose = useCallback(() => {
    requestFeedbacks();
    feedbackContentModal.handleClose();
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) requestFeedbacks();
  }, [searchStartDate.state]);

  const onSearchClick = useCallback(() => {
    requestFeedbacks();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  }, []);

  const onBatchFeedbackClick = useCallback(() => {
    batchFeedbackModal.visible(true);
  });

  const onInitClick = useCallback(() => {
    classType.reset();
    attachmentYn.reset();
    answerYn.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
    testModeType.reset();
  }, [
    classType.reset,
    attachmentYn.reset,
    answerYn.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
    testModeType.reset,
  ]);

  return (
    <>
      <DashboardNavbar title="최신 피드백 관리" />

      {/* Start Search Area */}
      <LACard>
        <LAProperty name="카테고리">
          <LAGridContainer>
            <CategoryComboView value={category} onChange={onCategoryChanged} />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LACodeRadioGroup
            codeType="CLASS_TYPE"
            showAll={true}
            value={classType.state}
            onChange={classType.handleChange}
          />
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LAAltRadioGroup
            showAll={true}
            positiveLabel={'시크릿 포함'}
            positiveValue={1}
            negativeLabel={'시크릿 제외'}
            negativeValue={0}
            value={testModeType.state}
            onChange={testModeType.handleChange}
          />
        </LAProperty>
        <LAProperty name="첨부 파일">
          <LARadioGroup
            props={[
              { value: -1, text: '전체' },
              { value: true, text: '첨부' },
              { value: false, text: '미첨부' },
            ]}
            value={attachmentYn.state}
            onChange={attachmentYn.handleChange}
          />
        </LAProperty>
        <LAProperty name="답변 여부">
          <LACodeRadioGroup
            codeType="ANSWER_BOOLEAN_TYPE"
            showAll={true}
            value={answerYn.state}
            onChange={answerYn.handleChange}
          />
        </LAProperty>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LATabSelector
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={20}
              layouts={{ pl: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={2}>~</LALabel>
            <LADatePicker
              wPercent={20}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '작성자' },
                { key: 2, value: '클래스 명' },
                { key: 3, value: '피드백 내용' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={50}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 1 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAButton wPercent={15} onClick={onBatchFeedbackClick}>
          일괄 피드백
        </LAButton>
        <LAGridContainer>
          <LADataTable columns={columns} rows={listItems} />
        </LAGridContainer>
      </LACard>

      <FeedbackContentsModal
        classmateSeq={selectedClassmateSeq}
        classroomSeq={selectedClassroomSeq}
        show={feedbackContentModal.state}
        handleClose={onFeedbackContntsClose}
        handleSelect={feedbackContentModal}
      />

      <UserBatchFeedbackModal
        classmate={addedClassmate}
        show={batchFeedbackModal.state}
        handleClose={batchFeedbackModal.handleClose}
      />
    </>
  );
}

export default RecentFeedbackManagement;
