import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { getTodayDateText } from 'common/utils';
import MDBox from 'components/MDBox';
import EmptyImage from 'assets/images/empty.png';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  LAFileAttachment,
  LASimpleTable,
  useInput,
  useDatePicker,
  useComboBox,
  useFileAttachment,
  useRadioGroup,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import moment from 'moment';

function AddBannerModal({
  show,
  bannerType,
  handleClose,
  addItemCallback,  
  registeredBanner
}) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [bannerInfo, setBannerInfo] = useState([]);
  const [bannerCount, setBannerCount] = useState(0);

  const getReservationText = useCallback((banner) =>{
    if(banner.reservation_yn === 1){
      let startDate = moment(banner.reservation_start_dt).format('YYYY.MM.DD HH:mm')
      let endDate = moment(banner.reservation_end_dt).format('YYYY.MM.DD HH:mm')
      return `${startDate}\n ~ ${endDate}`
    }else{
      return '즉시'
    }
  })

  const getBannerRows = useCallback(() => {
    let rowData = [];
    if (bannerInfo && bannerInfo.length > 0) {
      bannerInfo.forEach((banner) => {
        let canPush = !registeredBanner?.some(b => b.banner_seq == banner.banner_seq);              
        if (canPush) {
          rowData.push([
            {
              text: banner.banner_seq,
            },
            {
              text: banner.banner_title,
            },
            {
              text: banner.banner_link_url,
            },
            {
              text: getReservationText(banner),            
            },
            {
              text: moment(banner.created_dt).format('YYYY-MM-DD (dd) A hh:mm'),
            },
            {
              text: '추가',
              type: 'button',
              callback: {
                onClick: () => addItemCallback(banner),
              },
            },
          ]);
        }
      });
    }
    setBannerCount(rowData.length);
    return rowData;
  }, [bannerInfo, registeredBanner]);

  const { request: requestMainBanner } = useCommonMutation({
    query: queries.Banner.getBanners,
    callbacks: {
      onSuccess: (data) => {
        console.log(data);
        if (data.result_code === '0000') {
          setBannerInfo(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '배너 조회에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (show) requestMainBanner({banner_type:bannerType,middle_yn:bannerType == 3?true:false});
  }, [show,bannerType]);

  const onAddClick = useCallback(() => {}, []);

  const onRegistClick = useCallback(() => {}, []);


  const bannerData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '링크',
        },
        {
          text: '예약 노출',
        },
        {
          text: '등록일',
        },
        {
          text: '',
        },
      ],
      wPercentList: [8, 25, 15 , 20, 24, 8],
      rows: getBannerRows(),
    };
  }, [bannerInfo,registeredBanner]);

  //'배너 타입(1:메인,2:상단띠,3,중간띠,4:바닥,5:플로팅,6:팝업)'
  const getTitle = useMemo(() => {
    switch(bannerType){
      case 1:
        return '상단 배너 추가'
      case 2:
        return '상단 띠배너 추가'
      case 3:
        return '중간 띠배너 추가'
      case 4:
        return '바닥 배너 추가'
      case 5:
        return '플로팅 배너 추가'
      case 6:
        return '팝업 배너 추가'
    }
  }, [bannerType]);

  return (
    <LAModal
      title={getTitle}
      width="70%"
      height="85%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LAGridContainer layouts={{ pt: 2 }}>
          <LASimpleTable tableData={bannerData} />
        </LAGridContainer>
        {!bannerCount ? (
          <LAWarningLabel>추가할 수 있는 배너가 없습니다.</LAWarningLabel>
        ) : (
          <></>
        )}
      </LACard>
    </LAModal>
  );
}

export default AddBannerModal;
