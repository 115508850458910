import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { UNSAFE_NavigationContext } from "react-router-dom";
import DeliveryTrackingSimpleTable from './components/DeliveryTrackingSimpleTable';

import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import useCommonQuery from "common/hooks/useCommonQuery";
import {
    LACard,
    LAGridContainer,
    LAInput,
    LAModal,
    LAModalCardTitle,
    LACardTitle,
    LACodeComboBox,
    useInput,
    useComboBox
} from 'modules/Widgets';

function DeliveryTrackingModal( { context, show, handleClose }){
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();

    const [waybillNo, setWaybillNo] = useState('');
    const [deliveryCompanyName, setDeliveryCompanyName] = useState('');
    const [deliveryOptionTypeName, setDeliveryOptionTypeName] = useState('');

    const [deliveryTrackingInfo, setDeliveryTrackingInfo] = useState('');
    const [trackingHistoryList, setTrackingHistoryList] = useState([]);

    const navigation = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        if (navigation) {
            navigation.listen((locationListener) => {
                if(locationListener.action == 'POP') {
                    handleClose();
                }
            });
        }
    }, []);

    //배송 추적 api
    const { request : getDeliveryTrackingInfo } = useCommonQuery({
        query: queries.Delivery.getDeliveryTrackingInfo,
        params: {
            waybill_seq: context?.waybill_seq
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === "0000") {
                    let dataList = [];

                    data.result_data?.tracking_info?.map((item, index) => {
                        dataList.push([
                            { text: item.timeString },
                            { text: item.where },
                            { text: item.kind },
                        ])
                    });
                    setTrackingHistoryList(dataList);
                    setDeliveryTrackingInfo(data.result_data);
                } else {
                    showAlert('알림', '배송지 조회를 하지 못했습니다.');
                    setTrackingHistoryList([]);
                    setDeliveryTrackingInfo(null);
                    // modalPopupState.hide()
                    //handleClose();
                }
            },
            onError: (error) => {
                showAlert('알림', '배송지 조회를 하지 못했습니다.');
                // handleClose();
            },
        }, 
        initEnabled: false,
    });

    useEffect(() => {
        if (!context || context.length === 0) {
            return;
        }

        //setWaybillSeq(context.waybill_seq);
        // waybillNo.update(context?.waybill_no || context?.merge_waybill_no);
        // deliveryCompanyName.update(context?.delivery_company_type_name || context?.merge_delivery_company_type_name || context?.waybill_company_name);
        // deliveryOptionTypeName.update(context?.delivery_option_type_name); //배송상태가 없으면 없음 처리

            getDeliveryTrackingInfo();

        return () => {
            context = null;
            // setWaybillSeq('');
        /*    waybillNo.update('');
            deliveryCompanyName.update('');
            deliveryOptionTypeName.update('');*/
            setTrackingHistoryList([]);
            setDeliveryTrackingInfo(null);
        };
    }, [context]);


    useEffect(() => {
        setWaybillNo(deliveryTrackingInfo !== null ? deliveryTrackingInfo.waybill_no : '');
        setDeliveryCompanyName(deliveryTrackingInfo !== null ? deliveryTrackingInfo.delivery_company_type_name : '');
        setDeliveryOptionTypeName(deliveryTrackingInfo !== null ? deliveryTrackingInfo.delivery_option_type_name : '');
    }, [deliveryTrackingInfo]);


    /* const titleHtml = ()=>  {
        return (<LAModalCardTitle title = "송장 정보 조회" modalPopupState = { modalPopupState.state } />);
    } */

    return (
        <LAModal title={'송장 정보 조회'} width="60%" height="80%" show={show} onClose={handleClose}>
            <LACard next>
                <LAGridContainer mb={1}>
                    {/*<LACodeComboBox*/}
                    {/*    wPercent={20}*/}
                    {/*    layouts={{ pr: { sm: 0.5 } }}*/}
                    {/*    dbCodeStatus={true}*/}
                    {/*    codeType="DELIVERY_COMPANY_TYPE"*/}
                    {/*    value={deliveryCompanyType.state}*/}
                    {/*    onChange={deliveryCompanyType.handleChange}*/}
                    {/*    readOnly={true}*/}
                    {/*/>*/}
                    <LAInput
                      wPercent={20}
                      layouts={{ pr: { sm: 0.5 }} }
                      value={deliveryCompanyName}
                      readOnly={true}
                      placeholder="택배사명"
                    />

                    <LAInput
                      wPercent={20}
                      value={deliveryOptionTypeName}
                      readOnly={true}
                      placeholder="없음"
                    />
                    <LAInput
                        layouts={{ pt: 1 }}
                        wPercent={80} 
                        value={waybillNo}
                        readOnly={true}
                        placeholder="송장 번호"
                    />
                </LAGridContainer>
                <DeliveryTrackingSimpleTable data={trackingHistoryList} />
            </LACard>
        </LAModal>
    )
}
export default React.memo(DeliveryTrackingModal);