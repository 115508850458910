import React, { useState, useCallback ,useEffect,useMemo} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import TeacherSupportDetailContents from './components/TeacherSupportDetailContents';

function TeacherSupportDetail() {


  return (
    <>
      <DashboardNavbar title="선생님 지원서 상세" />
      <TeacherSupportDetailContents />
    </>
  );
}

export default TeacherSupportDetail;
