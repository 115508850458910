import React, {useMemo} from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import LAGridContainer from './LAGridContainer';
import LAGridItem from './LAGridItem';

function LAModalTitle({ wPercent = 100, children }) {
  const style = useMemo(
    () => ({
        bgcolor: '#ffffff',
        border: '2px solid #757575',
    }),
    [],
    );
      
  return (
    <MDBox>
      <LAGridContainer>
        <LAGridItem wPercent={wPercent} pr={{ xs: 0, sm: 0.5 }} pb={{ xs: 1 }}>
          <Card variant="outlined" sx={style}>
            <MDBox pt={2} pb={3} pl={5} pr={3}>
              {children}
            </MDBox>
          </Card>
        </LAGridItem>
      </LAGridContainer>
    </MDBox>
  );
}

export default React.memo(LAModalTitle);
