import React, { useEffect, useRef } from 'react';
import ImageList from '@mui/material/ImageList';
import LAImageListItem from './LAImageListItem';
import LAGridItem from './LAGridItem';
import LAButton from './LAButton';
import { styled } from '@mui/system';
import $ from 'jquery';

const CustomImageList = styled(ImageList)(() => {
  return {
    '.MuiImageList-root': {
      'grid-template-columns': 'repeat(10, 1fr)',
      'background-color': '#ff0000',
    },
  };
});

function LAImageList({
  wPercent = 100,
  layouts = {},
  showDelete = false,
  maxCount = 10,
  images,
  onClick,
  onRemoveClick={},
}) {
  const rootRef = useRef();
  $(rootRef.current).ready(
    // () => (rootRef.current.styleProps.gridTemplateColumns = 'repeat(10, 1fr)'),
    () => ($( this ).css('grid-template-columns', 'repeat(10, 1fr)'))
  );

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <CustomImageList
        rows={1}
        cols={images.length}
        rowHeight={160}
        gap={10}
        ref={rootRef}
      >
        {images &&
          images.map((image, index) => {
            return (
              <LAImageListItem
                imageUrl={image.file}
                key={index}
                index={index}
                seq={image.image_seq}
                showDelete={showDelete}
                onClick={onClick}
                onRemoveClick={onRemoveClick}
              ></LAImageListItem>
            );
          })}
      </CustomImageList>
    </LAGridItem>
  );
}

export default React.memo(LAImageList);
