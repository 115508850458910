import React from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import LAGridContainer from './LAGridContainer';
import LAGridItem from './LAGridItem';

function LACard({ next = false, wPercent = 100, layouts = {}, children, className, component="form" }) {
  return (
    <MDBox pt={next ? 1 : 0} {...layouts} className={className}>
      <LAGridContainer>
        <LAGridItem wPercent={wPercent} pr={{ xs: 0, sm: 0.5 }} pb={{ xs: 1 }}>
          <Card>
            <MDBox component={component} p={3}>
              {children}
            </MDBox>
          </Card>
        </LAGridItem>
      </LAGridContainer>
    </MDBox>
  );
}

export default React.memo(LACard);
