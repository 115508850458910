import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/members';

const MemberApi = (requestFunc) => {
    return {
        Member: {
            getList: {
                key: 'getMemberList',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
            },

            getProfile: {
                key: 'getMemberProfile',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.member_seq}`),
            },

            modifyProfile: {
                key: 'modifyMemberProfile',
                queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.user_seq}/profile`, params,),
            },

            // 웅진 멤버십 조회(고객번호 입력)
            getMembership: {
                key: 'getMembership',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.customer_number}/membership`, params),
            },

            // 북클럼 멤버십 등록 및 변경
            changeMembership: {
                key: "changeMembership",
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/${params.member_seq}/membership`, params)
            },

            registComment : {
                key: 'registMemberComment',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/${params.member_seq}/comment`, params),
            },

            deleteComment : {
                key: 'deleteMemberComment',
                queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.member_seq}/comment/${params.comment_id}`),
            },

            // 블랙리스트 조회
            getBlackList: {
                key: 'getBlackList',
                queryFn: () => requestFunc(METHOD.GET, `${baseURL}/blacklist`),
            },

            // 탈퇴 사용자 조회
            getSecessionList: {
                key: 'getSecessionList',
                queryFn: () => requestFunc(METHOD.GET, `${baseURL}/secessions`),
            },

            // 탈퇴 사용자 조회
            getResignedList: {
                key: 'getResignedList',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/resigned`,params),
            },

            // 사용자 비밀번호 변경
            changePwd: {
                key: 'changePwd',
                queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.user_seq}/changePwd`, params),
            },

            // 사용자 테스트 모드 변경
            changeTestMode:{
                key: 'changeTestMode',
                queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.member_seq}/change-test-mode`, params.body,),
            },

            // 사용자 탈퇴처리
            secession: {
                key: 'secession',
                queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.user_seq}/secession`, params,),
            },

            /* requestMemberContractInfo : {
                key: 'requestMemberContractInfo',
                queryFn: (params) => requestFunc(METHOD.POST, `/external/lep/SelectEbtUserCntrtInfo`, params),
            }, */
            
            requestMemberContractInfo: {
                key: 'requestMemberContractInfo', 
                queryFn: (params) => requestFunc(METHOD.POST, '/external/lep/userCntrtInfosByAdmin', params)
            },
        },
    };
};

export default MemberApi;

// export const UserQueries = {

// //사용자 비밀번호 ㅂ녀경
// changePwd : async (params) => {
//   return await axios.patch(`${baseURL}/${params.user_seq}/changePwd`,{...params});
// },

// //사용자 탈퇴처리
// secession : async (user_seq) => {
//   return await axios.patch(`${baseURL}/${user_seq}/secession`);
// }

// getProfile : async (id) => {
//   return await axios.get(`${baseURL}/${id}/profile`);
// },

// //블랙리스트 조회
// getBlackList : async () => {
//   return await axios.get(`${baseURL}/blacklist`);
// },

// //탈퇴 사용자 조회
// getSecessionList : async () => {
//   return await axios.get(`${baseURL}/secessions`);
// },

// //프로필 수정
// modifyProfile : async (params) => {
//   return await axios.patch(`${baseURL}/${params.user_seq}`, { ...params });
// },

// //사용자 비밀번호 ㅂ녀경
// changePwd : async (params) => {
//   return await axios.patch(`${baseURL}/${params.user_seq}/changePwd`,{...params});
// },

// //사용자 탈퇴처리
// secession : async (user_seq) => {
//   return await axios.patch(`${baseURL}/${user_seq}/secession`);
// }
// };

// export const getList = async () => {
//   return await axios.get(`${baseURL}`);
// };

// export const getProfile = async (id) => {
//   return await axios.get(`${baseURL}/${id}/profile`);
// };

// //블랙리스트 조회
// export const getBlackList = async () => {
//   return await axios.get(`${baseURL}/blacklist`);
// };

// //탈퇴 사용자 조회
// export const getSecessionList = async () => {
//   return await axios.get(`${baseURL}/secessions`);
// };

// //프로필 수정
// export const modifyProfile = async (params) => {
//   return await axios.patch(`${baseURL}/${params.user_seq}`, { ...params });
// };

// //사용자 비밀번호 ㅂ녀경
// export const changePwd = async (id) => {
//   return await axios.patch(`${baseURL}/${id}/changePwd`);
// };

// //사용자 탈퇴처리
// export const secession = async (id) => {
//   return await axios.patch(`${baseURL}/${id}/secession`);
// };
