import React, { useState, useCallback, useEffect, useMemo } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import TeacherSupportDetailModal from './components/TeacherSupportDetailModal';
import TeacherSupportFilter from './components/TeacherSupportFilter';
import TeacherSupportList from './components/TeacherSupportList';
import MDBox from 'components/MDBox';

import { useApiContext } from 'common/context/ApiContext';
import { useNavigate } from 'react-router-dom';
import useCommonQuery from 'common/hooks/useCommonQuery';

import { getTodayDateText, dateTimeToString } from 'common/utils';

import moment from 'moment';
import { periodItems } from 'common/constants';
import {
    LACard,
    LAGridContainer,
    LAProperty,
    LALabel,
    LACodeRadioGroup,
    LAButton,
    LACodeComboBox,
    // LAComboBox,
    LAInput,
    LADatePicker,
    LATabSelector,
    LADataTable,
    getTextCell,
    getNormalButton,
    useRadioGroup,
    useComboBox,
    useInput,
    useModal,
    useDatePicker,
    useTabSelector,
} from 'modules/Widgets';

// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

function TeacherSupportManagement() {
    const [selectedTeacherSeq, setSelectedTeacherSeq] = useState(0);
    const teacherSupportDetailModal = useModal(false);

    const { queries } = useApiContext();
    const todayDateText = getTodayDateText('-');

    const targetType = useRadioGroup(-1);
    const approveType = useRadioGroup(-1);

    const searchDateType = useComboBox(1);
    const searchPeriodType = useTabSelector(3);
    const searchStartDate = useDatePicker(todayDateText);
    const searchEndDate = useDatePicker(todayDateText);
    const searchTargetType = useComboBox(1);
    const searchText = useInput('');

    const [tablePage, setTablePage] = useState(0);
    const [lastVisitTablePage, setLastVisitTablePage] = useState(0);

    const [rows, setRows] = useState([]);

    const getDate = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD (dd) A hh:mm');
        }
        return '0000-00-00';
    };

    const columns = useMemo(() => {
        return [
            { Header: '', accessor: 'row_no', width: '5%', align: 'center' },
            { Header: '신청자', accessor: 'teacher_name', align: 'center' },
            { Header: '소속코드', accessor: 'affiliation_code', align: 'center' },
            // { Header: '개설분야', accessor: 'class_topic', align: 'center' },
            // { Header: "클래스 특징", accessor: "class_features", align: "center" },
            // { Header: "수강대상", accessor: "target_ages", align: "left" },
            // { Header: '수강대상', accessor: 'target_ages_value', align: 'left' },

            { Header: '신청일', accessor: 'created_dt', align: 'center' },

            { Header: '승인/거절', accessor: 'process_state', align: 'center' },
            { Header: '처리일자', accessor: 'process_dt', align: 'center' },
            { Header: '처리자', accessor: 'processer_name', align: 'center' },

            // { Header: "승인여부", accessor: "approved_yn", align: "center" },
            // { Header: "승인일자", accessor: "approved_dt", align: "center" },

            // { Header: "거절여부", accessor: "rejected_yn", align: "center" },
            // { Header: "거절일자", accessor: "rejected_dt", align: "center" },
            { Header: '상세', accessor: 'detail', width: '12%' },
        ];
    }, []);

    const dataToRow = (item, index) => {
        return {
            row_no: getTextCell(`${item.application_seq}`),
            affiliation_code: getTextCell(`${item.affiliation_code}`),
            // class_topic: getTextCell(`${item.class_topic}`),
            // class_features: getTextCell(`${item.class_features}`),
            // target_ages: getTextCell(`${item.target_ages}`),
            // target_ages_value: getTextCell(`${item.target_ages_value}`),

            teacher_name: getTextCell(
                `${item.teacher_name}`,
                `${item.application_seq}`,
                onDetail,
            ),

            created_dt: getTextCell(`${getDate(item.created_dt)}`),

            process_state: getTextCell(`${getProcessState(item)}`),
            process_dt: getTextCell(`${getProcessDate(item)}`),
            processer_name: getTextCell(`${getProcessorName(item)}`),

            // approved_yn: getTextCell(`${item.approved_yn?'Y':'N'}`),
            // approved_dt: getTextCell(`${getDate(item.approved_dt)}`),

            // rejected_yn: getTextCell(`${item.rejected_yn?'Y':'N'}`),
            // rejected_dt: getTextCell(`${getDate(item.rejected_dt)}`),

            detail: getNormalButton('상세', `${item.application_seq}`, onDetail),
        };
    };

    function onDetail(no) {
        // navigate(`detail/${no}`);
        onDetailClick(no);
    }

    const getProcessState = (item) => {
        if (item.approved_yn == 1) {
            return '승인완료';
        } else if (item.rejected_yn == 1) {
            return '거절완료';
        } else {
            return '미처리';
        }
    };
    const getProcessDate = (item) => {
        if (item.approved_yn == 1) {
            return getDate(item.approved_dt);
        } else if (item.rejected_yn == 1) {
            return getDate(item.rejected_dt);
        } else {
            return '-';
        }
    };

    const getProcessorName = (item) => {
        if (item.approved_yn == 1) {
            return item.approver_name;
        } else if (item.rejected_yn == 1) {
            return item.rejector_name;
        } else {
            return '-';
        }
    };

    const { result, request: requestTeacherApplications } = useCommonQuery({
        query: queries.Teachers.getSupportList,
        params: {
            target_type: targetType.state < 0 ? undefined : targetType.state,
            approved_yn:
                approveType.state < 0
                ? undefined
                : approveType.state == 1
                ? 1
                : undefined,
            rejected_yn:
                approveType.state < 0
                ? undefined
                : approveType.state == 2
                ? 1
                : undefined,
            period_type: searchDateType.state,
            start_dt: searchStartDate.state,
            end_dt: searchEndDate.state,
            query_type: searchTargetType.state,
            query: searchText.state,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setRows(data.result_data ? data.result_data.map((resultData, index) => dataToRow(resultData, index)) : []);
                } else {
                }
            },
            onError: (error) => {
            },
        },
        initEnabled: false,
    });

    useEffect(() => {
        searchStartDate.selectByPeriod(searchPeriodType.state);
        searchEndDate.reset(searchPeriodType.state);
    }, [searchPeriodType.state, searchEndDate.reset]);

    useEffect(() => {
        if (lastVisitTablePage != 0) {
            setTablePage(lastVisitTablePage);
        }
    }, [rows]);

    useEffect(() => {
        if (searchStartDate.state != searchEndDate.state)
        requestTeacherApplications();
    }, [searchStartDate.state]);

    const onSearchClick = useCallback(() => {
        setLastVisitTablePage(0);
        requestTeacherApplications();
    }, []);

    const onEnterKeyAtSearch = useCallback(() => {
        onSearchClick();
    });

    const onDetailClick = useCallback((no) => {
        setSelectedTeacherSeq(no);
        teacherSupportDetailModal.visible(true);
    });

    const onDetailClose = useCallback(() => {
        teacherSupportDetailModal.handleClose();
        requestTeacherApplications();
    });

    const onChangeTablePage = useCallback((page) => {
        setLastVisitTablePage(page);
    });

    const onInitClick = useCallback(() => {
        targetType.reset();
        approveType.reset();

        searchDateType.reset();
        searchPeriodType.reset();
        searchStartDate.reset();
        searchEndDate.reset();
        searchTargetType.reset();
        searchText.reset();
    }, [
        targetType.reset,
        searchDateType.reset,
        searchPeriodType.reset,
        searchStartDate.reset,
        searchEndDate.reset,
        searchTargetType.reset,
        searchText.reset,
    ]);

    return (
        <>
            <DashboardNavbar title="선생님 지원 승인" />
            <LACard>
                {/* <LAProperty name="수강 대상">
                    <LACodeRadioGroup
                        wPercent={100}
                        codeType="TEACHER_APPLICATION_TARGET_TYPE"
                        showAll={true}
                        value={targetType.state}
                        onChange={targetType.handleChange}
                    />
                </LAProperty> */}

                <LAProperty name="승인 여부">
                    <LACodeRadioGroup
                        codeType="APPROVE_TYPE"
                        showAll={true}
                        value={approveType.state}
                        onChange={approveType.handleChange}
                        // onChange={onExposureChanged}
                    />
                </LAProperty>

                <LAProperty name="조회 기간">
                    <LAGridContainer vAlign="center">
                        <LATabSelector
                            wPercent={45}
                            items={periodItems}
                            value={searchPeriodType.state}
                            onChange={searchPeriodType.handleChange}
                        />
                        <LADatePicker
                            wPercent={20}
                            layouts={{ pr: { sm: 1 }, pl: { sm: 1 } }}
                            value={searchStartDate.state}
                            onChange={searchStartDate.handleChange}
                        />
                        <LALabel wPercent={1}>~</LALabel>
                        <LADatePicker
                            wPercent={20}
                            layouts={{ pl: { sm: 1 } }}
                            value={searchEndDate.state}
                            onChange={searchEndDate.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="직접 검색">
                    <LAGridContainer>
                        <LACodeComboBox
                            wPercent={20}
                            codeType="TEACHER_APPLICATION_QUERY_TYPE"
                            value={searchTargetType.state}
                            onChange={searchTargetType.handleChange}
                        />
                        <LALabel wPercent={1} layouts={{ pr: { sm: 1 } }}></LALabel>
                        <LAInput
                            placeholder="검색어 입력"
                            wPercent={65}
                            value={searchText.state}
                            onChange={searchText.handleChange}
                            onEnterKeyPressed={onEnterKeyAtSearch}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAGridContainer layouts={{ mt: { sm: 1.5 } }}>
                    <LALabel wPercent={35}></LALabel>
                    <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onSearchClick}>검색</LAButton>
                    <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onInitClick} variant={'outlined'}>초기화</LAButton>
                </LAGridContainer>
            </LACard>
            {/* <TeacherSupportFilter onSearch={onSearch} /> */}
            <MDBox>
                <LACard next>
                    <LAGridContainer>
                        <LADataTable columns={columns} rows={rows} defaultPage={tablePage} onChangePage={onChangeTablePage} />
                    </LAGridContainer>
                </LACard>
            </MDBox>
            <TeacherSupportDetailModal
                teacherSeq={selectedTeacherSeq}
                show={teacherSupportDetailModal.state}
                handleClose={onDetailClose}
                handleSelect={teacherSupportDetailModal}
            />
        </>
    );
}

export default TeacherSupportManagement;
