import React, { useState, useCallback, useMemo, useEffect } from "react";
import moment from "moment";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import {
  LACard,
  LAProperty,
  LAGridContainer,
  LARadioGroup,
  LAComboBox,
  LAInput,
  LAButton,
  LALabel,
  LADataTable,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  getTextCell,
  getNormalButton,
  getPrimaryButton,
} from "modules/Widgets";

import { API_BASE_URL } from "api/AppConfig";

import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import { useApiContext } from "common/context/ApiContext";
import AllianceDetailModal from "pages/AllianceManagement/components/AllianceDetailModal";

function addComma(input) {
  if (input) {
    const parts = input.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else return 0;
}

function SapCostHandlingManagement() {
  const costType = useRadioGroup(""); // 전체 '' | 송금대기 'N' | 송금완료 'Y' | 미수금 'A'
  const queryDate = useMemo(() => moment());
  const thisYear = useMemo(() => queryDate.format("YYYY"), []);
  const thisMonth = useMemo(() => queryDate.format("MM"), []);
  const yearType = useComboBox(thisYear);
  const monthType = useComboBox(thisMonth);
  const searchTargetType = useComboBox(0); // 통합검색 | 업체코드 | 업체이름 | 대표자이름
  const searchText = useInput("");

  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const [data, setData] = useState([]);

  const allianceDetailModal = useModal(false);
  const [selectedAllianceSeq, setSelectedAllianceSeq] = useState(0); // 선택한 업체코드 팝업Modal로 전송
  const [page, setPage] = useState(0); // 마지막 페이지네이션

  // const allianceDetailModal = useModal(false);
  const [isTransfer, setTransfer] = useState(0);
  const [isCancel, setCancel] = useState(0);

  const onSearchClick = useCallback(() => {
    setPage(0);
    requestSapCost();
  }, []);
  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  /* 비용금액계산 클릭 시, 거래명세서 */
  const onClicDescription = useCallback((query) => {
    window.open(
      `${API_BASE_URL}/admin/settlement/statement/receipt/frame/${query.yyyymm}?memberSeq=${query.memberSeq}`,
      "_blank"
    );
  });

  /* 업체코드 또는 업체명 클릭 시, 상세팝업 */
  const onDetailClick = useCallback((alliance_seq) => {
    setSelectedAllianceSeq(alliance_seq);
    allianceDetailModal.visible(true);
  });

  const onDetailClosed = useCallback(() => {
    allianceDetailModal.handleClose();
  });

  /* 취소 클릭 시, 당일 송금 취소 */
  // getNormalButton('취소',`${row.cost_seq}`, onTransferCancleClick)
  const onTransferCancleClick = useCallback((cost_seq) => {
    setCancel(cost_seq);
    showConfirm("알림", `송금 요청을\n취소하시겠습니까?`, {
      confirmHandler: () => {
        requestCancel({ cost_seq: cost_seq });
      },
    });
  });

  /* 송금 클릭 시, is_cost_trans = Y / 당일 내에만 취소 버튼 활성화 */
  const onTransferClick = useCallback((cost_seq) => {
    setTransfer(cost_seq);
    showConfirm(
      "알림",
      `송금하시겠습니까?\n 송금 시 당일 취소만 가능하며, \n 다음날 고객 계좌로 비용 금액이 이체됩니다.`,
      {
        confirmHandler: () => {
          requestTransfer({ cost_seq: cost_seq });
        },
      }
    );
  });

  const columns = useMemo(() => {
    return [
      // { Header: 'No', accessor: 'no', width: '3%' },
      { Header: "업체코드", accessor: "alliance_code", width: "7%" },
      { Header: "업체이름", accessor: "alliance_name", width: "8%" },
      { Header: "공급업체", accessor: "corp_number", width: "8%" },
      { Header: "대표자이름", accessor: "corp_president", width: "8%" },
      {
        Header: "비용 금액 계산(공제액 제외)",
        accessor: "cost_desc",
        width: "38%",
      },
      { Header: "입금 금액", accessor: "cost_price", width: "7%" },
      { Header: "송금 요청", accessor: "transfer", width: "8%" },
      {
        Header: "전기 일시",
        accessor: "lep_processed_ctrd_ymd_to",
        width: "8%",
      },
      { Header: "전표번호", accessor: "lep_processed_belnr", width: "8%" },
    ];
  }, []);

  const rows = useMemo(() => {
    return data.map((row) => {
      return {
        // no: getTextCell(row.cost_seq),
        alliance_code: getTextCell(
          `${row.alliance_code}`,
          `${row.alliance_seq}`,
          onDetailClick
        ),
        alliance_name: getTextCell(
          `${row.alliance_name}`,
          `${row.alliance_seq}`,
          onDetailClick
        ),
        corp_number: getTextCell(row.corp_number),
        corp_president: getTextCell(row.corp_president),
        cost_desc: getTextCell(
          `${row.cost_desc}`,
          { yyyymm: row.yyyymm, memberSeq: row.alliance_member_seq },
          onClicDescription
        ),
        cost_price: getTextCell(addComma(row.cost_price)),
        transfer:
          row.cost_price < 0
            ? getTextCell("미수금")
            : row.is_cost_trans === "N"
            ? getNormalButton("송금", `${row.cost_seq}`, onTransferClick)
            : moment(queryDate).format("YYYY-MM-DD") ===
              moment(row.is_cost_dt).format("YYYY-MM-DD")
            ? getPrimaryButton("취소", `${row.cost_seq}`, onTransferCancleClick)
            : getTextCell(
                `${moment(row.is_cost_dt).format("YYYY-MM-DD (dd) A hh:mm")}`
              ),
        lep_processed_ctrd_ymd_to: row.lep_processed_ctrd_ymd_to
          ? getTextCell(
              moment(row.lep_processed_ctrd_ymd_to).format("YYYY-MM-DD")
            )
          : "",
        lep_processed_belnr: getTextCell(row.lep_processed_belnr),
      };
    });
  }, [data]);

  useEffect(() => {
    requestSapCost();
  }, []);

  /* SAP비용처리 목록 API */
  const { result: resultData, request: requestSapCost } = useCommonQuery({
    query: queries.Calculate.getSapCostList,
    params: {
      yyyymm: yearType.state + monthType.state,
      stype: searchTargetType.state,
      svalue: searchText.state,
      sap_cost_type: costType.state,
    },
    callbacks: {
      onSuccess: (data) => {
        console.log(data);
        if (data.result_code == "0000") {
          setData(data.result_data);
        } else {
          if (data.result_code.result_message)
            showAlert("알림", data.result_code.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "목록 조회에 실패하였습니다.");
      },
    },
    initEnabled: false,
  });

  /* 송금 API */
  const { request: requestTransfer } = useCommonMutation({
    query: queries.Calculate.transferCost,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "송금이 완료되었습니다.");
          requestSapCost();
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", error);
      },
    },
  });

  /* 당일 취소 API */
  const { request: requestCancel } = useCommonMutation({
    query: queries.Calculate.cancelCost,
    callbacks: {
      onSuccess: (data) => {
        console.log(data);
        if (data.result_code === "0000") {
          showAlert("알림", "송금이 취소되었습니다.");
          requestSapCost();
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", error);
      },
    },
  });

  return (
    <>
      <DashboardNavbar title="SAP 비용 처리" />
      <LACard>
        <LAProperty name="SAP 비용 처리">
          <LAGridContainer vAlign="center">
            <LARadioGroup
              props={[
                { text: "전체", value: "" },
                { text: "송금 대기", value: "N" },
                { text: "송금 완료", value: "Y" },
                { text: "미수금", value: "A" },
              ]}
              value={costType.state}
              onChange={costType.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: "2022", value: "2022년" },
                { key: "2023", value: "2023년" },
                { key: "2024", value: "2024년" },
                { key: "2025", value: "2025년" },
              ]}
              value={yearType.state}
              onChange={yearType.handleChange}
            />
            <LAComboBox
              layouts={{ pl: { sm: 1 } }}
              wPercent={15}
              items={[
                { key: "01", value: "1월" },
                { key: "02", value: "2월" },
                { key: "03", value: "3월" },
                { key: "04", value: "4월" },
                { key: "05", value: "5월" },
                { key: "06", value: "6월" },
                { key: "07", value: "7월" },
                { key: "08", value: "8월" },
                { key: "09", value: "9월" },
                { key: "10", value: "10월" },
                { key: "11", value: "11월" },
                { key: "12", value: "12월" },
              ]}
              value={monthType.state}
              onChange={monthType.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: "통합검색" },
                { key: 1, value: "업체 코드" },
                { key: 2, value: "업체 이름" },
                { key: 3, value: "대표자 이름" },
                { key: 4, value: "전표 번호" },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={"outlined"}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>

      <LAGridContainer>
        <LADataTable columns={columns} rows={rows} />
      </LAGridContainer>

      <AllianceDetailModal
        allianceSeq={selectedAllianceSeq}
        show={allianceDetailModal.state}
        handleClose={onDetailClosed}
        handleSelect={allianceDetailModal}
      />
    </>
  );
}

export default SapCostHandlingManagement;
