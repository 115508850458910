import React, {
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useState,
} from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import {
  LAModal,
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAComboBox,
  LACodeComboBox,
  LACodeRadioGroup,
  LAInput,
  LADatePicker,
  LATabSelector,
  LARadioGroup,
  LADataTable,
  LADivider,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useDatePicker,
  useTabSelector,
  getTextCell,
  getCheckCell,
  LAGridItem,
  useFileAttachment,
  LASimpleTable,
} from 'modules/Widgets';
import { periodItems } from 'common/constants';
import { getTodayDateText, toCurrency } from 'common/utils';
import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import moment from 'moment';
import { API_BASE_URL } from 'api/AppConfig';
import CheckIcon from '@mui/icons-material/Check';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

function PointExcelMemberModal({ show, handleClose, handleApply }) {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [step, setStep] = useState(0);
  const [nonPayableMembers, setNonPayableMembers] = useState([]);
  const [payableMembers, setPayableMembers] = useState([]);
  const excelFile = useFileAttachment(5120, {
    name: '',
  });

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    if (navigation) {
      navigation.listen((locationListener) => {
        if (locationListener.action == 'POP') {
          handleClose();
        }
      });
    }
  }, []);

  const { request: requestUploadExcel } = useCommonMutation({
    query: queries.Point.uploadExcel,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setNonPayableMembers(data.result_data.nonPayableMembers);
          setPayableMembers(data.result_data.payableMembers);
          setStep(1);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '등록 실패하였습니다.\n다시 시도해주세요.');
      },
    },
  });

  const onSelectFileClick = useCallback(() => {}, []);

  const onDeleteFileClick = useCallback(() => {
    excelFile.reset();
  }, []);

  const onDownloadFileClick = useCallback(() => {
    const url = `${API_BASE_URL}/admin/points/download-excel`;
    const link = document.createElement('a');
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  }, [API_BASE_URL]);

  const onCloseClick = useCallback(
    (e) => {
      if (excelFile.state.file?.length > 0) {
        showConfirm(
          '확인',
          '업로드 한 파일이 있습니다.\n일괄 등록을 취소하시겠습니까?',
          {
            confirmHandler: () => handleClose(e),
          },
        );
        return;
      }
      handleClose(e);
    },
    [excelFile.state],
  );

  const onRegistClick = useCallback(() => {
    if (!excelFile?.state?.file || excelFile.state.file.length === 0) {
      showAlert(
        '확인',
        '업로드 한 파일이 없습니다.\n확인 후 다시 등록해주세요.',
      );
      return;
    }

    const formData = new FormData();
    formData.append('membersFile', excelFile.state.info);
    requestUploadExcel(formData);
  }, [excelFile.state]);

  const onBackClick = useCallback(() => {
    setStep(0);
  }, []);

  const onRegistCompleteClick = useCallback(
    (e) => {
      handleApply(e, payableMembers);
    },
    [payableMembers],
  );

  const getNonPayableMemberRows = useCallback(() => {
    return nonPayableMembers.map((item) => [      
      { text: item.email },
      { text: item.name },
      { text: item.reason },
    ]);
  }, [nonPayableMembers]);

  const nonPayableMemberTableData = useMemo(() => {
    return {
      headers: [{ text: '가입자 아이디' }, { text: '이름' }, { text: '사유' }],
      wPercentList: [40, 30 , 30],
      rows: getNonPayableMemberRows(),
    };
  }, [getNonPayableMemberRows]);

  return (
    <LAModal
      title="엑셀 회원 일괄 등록"
      width="50%"
      height={step === 0 ? '52%' : '80%'}
      show={show}
      onClose={handleClose}
    >
      {step === 0 ? (
        <LACard>
          <LAGridContainer>
            <LAButton
              wPercent={30}
              layouts={{ pr: { sm: 3 } }}
              props={{
                component: 'label',
              }}
              // onClick={onSelectFileClick}
            >
              파일 선택
              <input
                type="file"
                hidden
                accept=".xlsx"
                onChange={excelFile.handleChange}
              />
            </LAButton>
            {excelFile.state.name?.length > 0 && (
              <>
                <LALabel wPercent={40} color="info">
                  {excelFile.state.name.substr(
                    excelFile.state.name.lastIndexOf('\\') + 1,
                  )}
                </LALabel>
                <LAButton
                  wPercent={5}
                  color="secondary"
                  props={{
                    sx: {
                      minHeight: '26px',
                      height: '26px',
                      minWidth: '20px',
                      width: '20px',
                    },
                  }}
                  onClick={onDeleteFileClick}
                >
                  X
                </LAButton>
              </>
            )}
          </LAGridContainer>

          <LAGridContainer layouts={{ pt: { sm: 2 } }}>
            <LAGridItem>
              <MDBox
                sx={{
                  backgroundColor: '#eeeeee',
                  borderRadius: '4px',
                  padding: '0px 20px 0px 20px',
                }}
              >
                <LAGridContainer>
                  <LAGridItem wPercent={5} layouts={{ mt: { sm: 1 } }}>
                    <CheckIcon sx={{ pt: 1, mr: 0.2, width: 18, height: 18 }} />
                  </LAGridItem>
                  <LALabel wPercent={90} layouts={{ pt: { sm: 1 } }}>
                    엑셀 파일만 업로드 할 수 있습니다. (확장자: xlsx)
                  </LALabel>
                </LAGridContainer>
                <LAGridContainer>
                  <LAGridItem wPercent={5} layouts={{ mt: { sm: -1 } }}>
                    <CheckIcon sx={{ pt: 1, mr: 0.2, width: 18, height: 18 }} />
                  </LAGridItem>
                  <LALabel wPercent={90} layouts={{ mt: { sm: -1 } }}>
                    파일 양식 다운로드 후 양식에 맞춰 수정 후 업로드 해주세요.
                  </LALabel>
                </LAGridContainer>
                <LAGridContainer>
                  <LAGridItem wPercent={5} />
                  <LAButton
                    wPercent={50}
                    variant={'outlined'}
                    layouts={{ pt: { sm: 0 }, pb: { sm: 2 } }}
                    props={{
                      sx: {
                        backgroundColor: '#ffffff',
                        minHeight: '22px',
                        height: '22px',
                      },
                    }}
                    onClick={onDownloadFileClick}
                  >
                    파일 양식 다운받기
                  </LAButton>
                </LAGridContainer>
              </MDBox>
            </LAGridItem>
          </LAGridContainer>

          <LAGridContainer layouts={{ pt: { sm: 3 } }}>
            <LALabel wPercent={35}></LALabel>
            <LAButton wPercent={15} variant={'outlined'} onClick={onCloseClick}>
              취소
            </LAButton>
            <LAButton
              wPercent={15}
              layouts={{ pl: { sm: 1 } }}
              onClick={onRegistClick}
            >
              등록
            </LAButton>
            <LALabel wPercent={35}></LALabel>
          </LAGridContainer>
        </LACard>
      ) : (
        <LACard>
          <LAGridContainer>
            <LAGridItem wPercent={5}>
              <ErrorTwoToneIcon
                sx={{ mt: 1.1, mr: 1, width: 18, height: 18 }}
              />
            </LAGridItem>
            <LALabel wPercent={15} layouts={{ ml: { sm: 1 } }}>
              등록 가능
            </LALabel>
            <LALabel
              wPercent={15}
              props={{ sx: { color: '#0066ff', fontWeight: 'bold' } }}
            >
              {payableMembers?.length || 0}건,
            </LALabel>
            <LALabel wPercent={15}>등록 불가</LALabel>
            <LALabel
              wPercent={15}
              props={{ sx: { color: '#ff0000', fontWeight: 'bold' } }}
            >
              {nonPayableMembers?.length || 0}건
            </LALabel>
          </LAGridContainer>

          <LAGridContainer>
            <LAGridItem wPercent={5} layouts={{ mt: { sm: 1 } }}>
              <CheckIcon sx={{ pt: 1, mr: 0.2, width: 18, height: 18 }} />
            </LAGridItem>
            <LALabel wPercent={90} layouts={{ pt: { sm: 1 } }}>
              등록 불가 목록
            </LALabel>
            <LASimpleTable isPagination tableData={nonPayableMemberTableData} />
          </LAGridContainer>

          <LAGridContainer layouts={{ pt: { sm: 3 } }}>
            <LALabel wPercent={35}></LALabel>
            <LAButton wPercent={15} variant={'outlined'} onClick={onBackClick}>
              이전
            </LAButton>
            <LAButton
              wPercent={15}
              layouts={{ pl: { sm: 1 } }}
              onClick={onRegistCompleteClick}
            >
              등록
            </LAButton>
            <LALabel wPercent={35}></LALabel>
          </LAGridContainer>
        </LACard>
      )}
    </LAModal>
  );
}

export default React.memo(PointExcelMemberModal);
