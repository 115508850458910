import React, { useState, useEffect, useMemo, useCallback } from "react";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { useApiContext } from "common/context/ApiContext";
import CouponDetailModal from "./CouponDetailModal";
import CouponRegistModal from "./CouponRegistModal";
import CouponHistoryModal from "./CouponHistoryModal";
import { getTodayDateText } from "common/utils";
import { periodItems } from "common/constants";
import { API_BASE_URL } from "api/AppConfig";
import {
  LACard,
  LADataTable2,
  LAGridContainer,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LADatePicker,
  LATabSelector,
  getTextCell,
  getNormalButton,
  useRadioGroup,
  useTabSelector,
  useDatePicker,
  useComboBox,
  useModal,
  useInput,
} from "modules/Widgets";
import moment from "moment";
import { LAAltRadioGroup } from "modules/Widgets";
import DownloadReasonModal from "pages/Order/components/DownloadReasonModal";
import DownloadListModal from "pages/Order/components/DownloadListModal";

function CouponSearch() {
  const priceSplitter = (number) =>
    number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const todayDateText = getTodayDateText("-");

  const { queries } = useApiContext();
  const [rows, setRows] = useState([]);

  const [tablePage, setTablePage] = useState(0);
  const [page, setPage] = useState(0); // 마지막 페이지네이션

  const couponType = useRadioGroup(-1);
  const classType = useRadioGroup(-1);
  const publishStatus = useRadioGroup(-1);
  const validYn = useRadioGroup("");
  const periodType = useComboBox(1);
  const periodTab = useTabSelector(3);
  const startDate = useDatePicker(todayDateText);
  const endDate = useDatePicker(todayDateText);
  const searchType = useComboBox(0);
  const searchInput = useInput("");
  const downloadReasonModal = useModal(false);
  const downloadListModal = useModal(false);

  const [selectedCoupon, setSelectedCoupon] = useState(undefined);
  const couponDetailModal = useModal(false);
  const couponRegistModal = useModal(false);
  const couponHistoryModal = useModal(false);

  const [searchParams, setSearchParams] = useState({});

  const getPublishCountText = (data) => {
    if (data.issuance_method_type == 2) {
      if (data.all_member_yn == 1) {
        return `${data.limited_issue_quantity || "제한없음"}`;
      } else {
        return `${data.member_count}`;
      }
    } else {
      return `${data.limited_issue_quantity || "제한없음"}`;
    }
  };

  const dataToRow = (data, index) => {
    return {
      no: getTextCell(`${data.coupon_policy_seq}`),
      type: getTextCell(data.issuance_method_type_name || ""),
      name: getTextCell(data.coupon_name, data, onCouponDetailClick),
      class_type: getTextCell(
        data.class_type == 0 || !data.class_type
          ? "전체"
          : data.class_type == 1
          ? "원데이"
          : "패키지"
      ),
      publish_count: getTextCell(
        // priceSplitter(`${data.limited_issue_quantity || '제한없음'}`),
        priceSplitter(getPublishCountText(data))
      ),
      download_count: getTextCell(priceSplitter(`${data.download_count || 0}`)),
      use_count: getTextCell(
        `${data.used_count || 0}`,
        data,
        onCouponUseCountClick
      ),
      // visit_device:'',
      publish_period: getTextCell(
        data.issuance_period_st_dt && data.issuance_period_ed_dt
          ? moment(data.issuance_period_st_dt.substr(0, 8)).format(
              "YYYY-MM-DD(dd)"
            ) +
              "\n~" +
              moment(data.issuance_period_ed_dt.substr(0, 8)).format(
                "YYYY-MM-DD(dd)"
              )
          : ""
      ),
      valid_period: getTextCell(
        data.expiration_period_st_dt && data.expiration_period_ed_dt
          ? moment(data.expiration_period_st_dt.substr(0, 8)).format(
              "YYYY-MM-DD(dd)"
            ) +
              "\n~" +
              moment(data.expiration_period_ed_dt.substr(0, 8)).format(
                "YYYY-MM-DD(dd)"
              )
          : ""
      ),
      publish_status: getTextCell(
        data.issuance_status == 0
          ? "미발행"
          : data.issuance_status == 1
          ? "발행"
          : "발행 중단"
      ),
      detail: getNormalButton("상세", data, onCouponDetailClick),
    };
  };

  useEffect(() => {
    endDate.selectByPeriodAfter(periodTab.state);
    startDate.reset(periodTab.state);
  }, [periodTab.state, startDate.reset]);

  /*     useEffect(() => {
        if (startDate.state != endDate.state) onSearchClick();
    }, [startDate.state, endDate.state]); */

  useEffect(() => {
    if (page != 0) {
      setTablePage(page);
    }
  }, [rows]);

  const onSearchClick = useCallback(() => {
    let searchParams = {
      issuance_method_type: couponType.state < 0 ? undefined : couponType.state,
      class_type: classType.state < 0 ? undefined : classType.state,
      issuance_status:
        publishStatus.state < 0 ? undefined : publishStatus.state,
      expiration_code: validYn.state == -1 ? undefined : validYn.state,
      period_type: periodType.state,
      start_dt: startDate.state.replaceAll("-", ""),
      end_dt: endDate.state.replaceAll("-", ""),
      query_type: searchType.state,
      query: `${searchInput.state}`,
    };
    setSearchParams(searchParams);
  }, [
    couponType.state,
    classType.state,
    publishStatus.state,
    validYn.state,
    periodType.state,
    startDate.state,
    endDate.state,
    searchType.state,
    searchInput.state,
  ]);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
    onInitializeClick();
  }, []);

  const onInitializeClick = useCallback(() => {
    couponType.reset();
    classType.reset();
    publishStatus.reset();
    periodType.reset();
    periodTab.reset();
    startDate.reset();
    // endDate.reset();
    searchType.reset();
    searchInput.reset();
    endDate.selectByPeriodAfter(3);
  }, [
    couponType.reset,
    classType.reset,
    publishStatus.reset,
    periodType.reset,
    periodTab.reset,
    startDate.reset,
    // endDate.reset();
    searchType.reset,
    searchInput.reset,
    endDate.selectByPeriodAfter(3),
  ]);

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "4%" },
      { Header: "타입", accessor: "type", width: "8%" },
      { Header: "쿠폰명", accessor: "name", width: "20%" },
      { Header: "대상", accessor: "class_type", width: "6%" },
      { Header: "발행 수", accessor: "publish_count", width: "8%" },
      { Header: "다운로드 수", accessor: "download_count", width: "10%" },
      { Header: "사용수", accessor: "use_count", width: "6%" },
      { Header: "발행 기간", accessor: "publish_period", width: "12%" },
      { Header: "유효 기간", accessor: "valid_period", width: "12%" },
      { Header: "발행 상태", accessor: "publish_status", width: "7%" },
      { Header: "상세", accessor: "detail", width: "7%" },
    ];
  }, []);

  const onCouponHistoryClose = useCallback(() => {
    setSelectedCoupon(undefined);
    couponHistoryModal.handleClose();
  }, [couponHistoryModal.handleClose]);

  const onCouponRegistClick = useCallback(() => {
    couponRegistModal.visible(true);
  }, [couponRegistModal.visible]);

  const onCouponDetailClick = useCallback(
    (data) => {
      console.log(data);
      setSelectedCoupon(data);
      couponDetailModal.visible(true);
    },
    [couponDetailModal.visible]
  );

  const onCouponUseCountClick = useCallback(
    (data) => {
      console.log(data);
      setSelectedCoupon(data);
      couponHistoryModal.visible(true);
    },
    [couponHistoryModal.visible]
  );

  const onCouponDetailClose = useCallback(
    (reload = false) => {
      setSelectedCoupon(undefined);
      if (reload) {
        onSearchClick();
      }
      couponDetailModal.handleClose();
    },
    [couponDetailModal.handleClose]
  );

  const onCouponRegistClose = useCallback(
    (reload = false) => {
      if (reload) {
        onSearchClick();
      }
      couponRegistModal.handleClose();
    },
    [onSearchClick, couponRegistModal.handleClose]
  );

  const onChangeTablePage = useCallback((page) => {
    setPage(page);
  });

  const clickDownload = () => {
    downloadReasonModal.visible(true);
  };

  const clickDownloadList = () => {
    downloadListModal.visible(true);
  };

  return (
    <>
      <DashboardNavbar title="쿠폰 목록" />

      <LACard>
        <LAProperty name="쿠폰 타입">
          <LARadioGroup
            props={[
              {
                text: "전체",
                value: -1,
              },
              {
                text: "다운로드 쿠폰",
                value: 1,
              },
              {
                text: "텍스트 쿠폰",
                value: 2,
              },
              {
                text: "난수 쿠폰",
                value: 3,
              },
              {
                text: "구독 쿠폰",
                value: 4,
              },
            ]}
            value={couponType.state}
            onChange={couponType.handleChange}
          />
        </LAProperty>

        <LAProperty name="클래스 대상">
          <LARadioGroup
            props={[
              {
                text: "전체",
                value: -1,
              },
              {
                text: "원데이",
                value: 1,
              },
              {
                text: "패키지",
                value: 2,
              },
            ]}
            value={classType.state}
            onChange={classType.handleChange}
          />
        </LAProperty>

        <LAProperty name="사용 여부">
          <LARadioGroup
            props={[
              {
                text: "전체",
                value: -1,
              },
              {
                text: "발행",
                value: 1,
              },
              {
                text: "미발행",
                value: 0,
              },
              {
                text: "발행 중단",
                value: 2,
              },
            ]}
            value={publishStatus.state}
            onChange={publishStatus.handleChange}
          />
        </LAProperty>

        <LAProperty name="유효 기간">
          <LAAltRadioGroup
            showAll={true}
            positiveLabel={"사용가능"}
            positiveValue={1}
            negativeLabel={"만료"}
            negativeValue={2}
            value={validYn.state}
            onChange={validYn.handleChange}
          />
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            {/* <LAComboBox
              wPercent={15}
              items={[
                { key: 1, value: '발행 기간' },
                { key: 2, value: '유효 기간' },
              ]}
              value={periodType.state}
              onChange={periodType.handleChange}
            /> */}
            <LATabSelector
              wPercent={45}
              items={periodItems}
              //   layouts={{ pl: 2 }}
              value={periodTab.state}
              onChange={periodTab.handleChange}
            />
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2, mr: 2 }}
              value={startDate.state}
              onChange={startDate.handleChange}
            />
            ~
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2 }}
              value={endDate.state}
              onChange={endDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: "통합검색" },
                { key: 1, value: "쿠폰명" },
              ]}
              value={searchType.state}
              onChange={searchType.handleChange}
            />
            <LAInput
              wPercent={85}
              layouts={{ pl: { sm: 1 } }}
              value={searchInput.state}
              onChange={searchInput.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 2 } }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LAButton
              wPercent={15}
              layouts={{ pr: { sm: 1 } }}
              onClick={onSearchClick}
            >
              검색
            </LAButton>
            <LAButton
              wPercent={15}
              layouts={{ pr: { sm: 1 } }}
              variant={"outlined"}
              onClick={onInitializeClick}
            >
              초기화
            </LAButton>
            <LAButton
              wPercent={15}
              layouts={{ pr: { sm: 1 } }}
              onClick={onCouponRegistClick}
            >
              쿠폰 등록하기
            </LAButton>
          </div>
        </LAGridContainer>
      </LACard>

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable2
            columns={columns}
            loadUrl={queries.Coupon.getCouponList}
            params={searchParams}
            dataToRow={dataToRow}
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
          />
        </LAGridContainer>
      </LACard>

      {couponDetailModal.state && (
        <CouponDetailModal
          show={couponDetailModal.state}
          handleClose={onCouponDetailClose}
          couponSeq={selectedCoupon.coupon_policy_seq}
        />
      )}

      {couponRegistModal.state && (
        <CouponRegistModal
          show={couponRegistModal.state}
          handleClose={onCouponRegistClose}
        />
      )}

      {couponHistoryModal.state && (
        <CouponHistoryModal
          show={couponHistoryModal.state}
          handleClose={onCouponHistoryClose}
          couponSeq={selectedCoupon.coupon_policy_seq}
          couponName={selectedCoupon.coupon_name}
        />
      )}
    </>
  );
}

export default CouponSearch;
