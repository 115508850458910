import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'TOGGLE':
        const value = parseInt(action.value);
        const index = state.findIndex((key) => key === value);
        const result = [...state];
        if (index >= 0) {
          !action.force && draft.splice(index, 1);
          !action.force && result.splice(index, 1);
        } else {
          draft.push(value);
          result.push(value);
        }

        if (action.hasAll && action.items.length > 1) {
          if (action.items[0].value === value) {
            const allItemIndex = result.findIndex((item) => item === value);
            if (allItemIndex >= 0) {
              const includedItems = action.items
                .slice(1)
                .map((item) => item.value);
              draft.push(...includedItems);
            } else {
              draft.splice(0);
            }
          } else {
            const includedItems = action.items
              .slice(1)
              .map((item) => item.value);
            const isCheckAll = includedItems.reduce(
              (acc, item) =>
                acc && result.findIndex((value) => item === value) >= 0,
              true,
            );

            if (isCheckAll) {
              draft.push(action.items[0].value);
            } else {
              const allItemIndex = result.findIndex(
                (item) => item === action.items[0].value,
              );
              if (allItemIndex >= 0) {
                draft.splice(allItemIndex, 1);
              }
            } 
          }
        }
        break;
      case 'UPDATE':
        return action.values.map((item) => parseInt(item));
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useMultiCheckbox(initialData = [], items = [], hasAll = true) {
  const [state, dispatch] = useReducer(reducer, initialData);
  const sortedState = useMemo(
    () =>
      state.slice().sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      }),
    [state],
  );

  const toggle = useCallback(
    (value, force = false) => {
      dispatch({
        type: 'TOGGLE',
        value,
        force,
        items,
        hasAll,
      });
    },
    [items, hasAll],
  );

  const handleChange = useCallback(
    (e) => {
      toggle(e.target.value);
    },
    [toggle],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  const isSelected = useCallback(
    (value) => {
      if (!state) {
        return false;
      }
      return state.findIndex((key) => key === value) >= 0;
    },
    [state],
  );

  return {
    state: sortedState,
    handleChange,
    toggle,
    reset,
    isSelected,
  };
}

export default useMultiCheckbox;
