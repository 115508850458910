import React, { useMemo } from 'react';
import Modal from '@mui/material/Modal';
import MDBox from 'components/MDBox';

function LAModalDimmed({
  padding=0,
  show,
  width = '50%',
  height = 'auto',
  onClose,
  children,
}) {
  const style = useMemo(
    () => ({
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { width },
      height: { height },
      bgcolor: '#eeeeee',
      border: '1px solid #dddddd',
      boxShadow: 24,
      overflow: 'auto',
    }),
    [width, height],
  );

  return (
    <Modal open={show} onClose={onClose}>
      <MDBox p={padding} sx={style}>
        {children}
      </MDBox>
    </Modal>
  );
}

export default React.memo(LAModalDimmed);
