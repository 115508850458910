import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  useInput,
  LAInput,
  LASimpleTable,
  LADataTable,
  LAButton,
  getNormalButton,
  LAWarningLabel,
} from 'modules/Widgets';
import { getTextCell } from 'modules/Widgets';

const columns = [
  { Header: '순번', accessor: 'idx', width: '5%' },
  { Header: '메모', accessor: 'memo', width: '60%', align: 'left' },
  { Header: '작성일', accessor: 'created', width: '20%' },
  { Header: '작성자', accessor: 'creator', width: '10%' },
  { Header: '삭제', accessor: 'delete', width: '10%' },
];

function UserMemoContents({ user, type = 0, show, handleClose }) {
  const { showAlert, showConfirm } = usePopAlert();
  const { queries } = useApiContext();
  const [memoSeq, setMemoSeq] = useState();

  const memo = useInput('');
  const [data, setData] = useState();
  // const [rows, setRows] = useState([]);

  const { action, loginState } = useLoginContext();
  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  useEffect(() => {
    requestList();
  }, []);

  const getTitle = useMemo(() => {
    return type == 1 ? '클래스 관리 메모' : '회원 관리 메모';
  }, [type]);

  const { request: requestList } = useCommonQuery(
    {
      query: queries.UserMemo.getList,
      params: {
        user_seq: user.user_seq,
        class_seq: type == 1 ? user.class_seq : undefined,
      },
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            setData(data.result_data);
            // setRows(data.result_data);

            // setRows(
            //     data.result_data
            //       ? data.result_data.map((item,index) =>
            //           dataToRow(item,data.result_data.length - index),
            //         )
            //       : [],
            //   );
          } else {
            showAlert('알림', data.result_message);
          }
        },
        onError: (err) => {
          showAlert('알림', '회원 관리 메모 조회에 실패하였습니다.');
        },
      },
      initEnabled: true,
    },
    [data, type],
  );

  const { request: registMemo } = useCommonMutation(
    {
      query: queries.UserMemo.regist,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            showAlert('알림', '관리 메모가 등록었습니다.');
            requestList();
            memo.reset('');
          } else {
            showAlert('알림', data.result_message);
          }
        },
        onError: (error) => {
          showAlert('알림', '관리 메모 등록에 실패했습니다.');
        },
      },
      initEnabled: false,
    },
    [memo],
  );

  const { request: deleteMemo } = useCommonMutation(
    {
      query: queries.UserMemo.delete,
      // params: memoSeq,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            showAlert('알림', '관리 메모가 삭제되었습니다.');
            requestList();
          } else {
            showAlert('알림', data.result_message);
          }
        },
        onError: (error) => {
          console.log(error);
          showAlert('알림', '관리 메모 삭제에 실패했습니다.');
        },
      },
      initEnabled: false,
    },
    [memoSeq],
  );

  const isDeletable = useCallback(
    (item) => {
      return item.constructor_seq === loginState.member_seq;
    },
    [loginState],
  );

  const onDelete = useCallback(
    (memoSeq) => {
      showConfirm('알림', '정말 삭제하시겠습니까?', {
        confirmHandler: () => {
          deleteMemo(memoSeq);
        },
      });
    },
    [deleteMemo],
  );

  const onRegist = useCallback(() => {
    if (memo.state.length == 0) {
      showAlert('알림', '메모를 입력해주세요');
      return;
    }
    let payload = {
      user_seq: user.user_seq,
      member_seq: user.member_seq,
      class_seq: type == 1 ? user.class_seq : undefined,
      memo: memo.state,
    };
    registMemo(payload);
  }, [data, memo.state]);

  const rows = useMemo(() => {
    let result = [];
    if (data && data.length > 0) {
      data.map((item, index) =>
        result.push({
          idx: getTextCell(`${data.length - index}`),
          memo: getTextCell(item.memo),
          created: getTextCell(
            moment(item.created_dt).format('ll (dd) HH:mm:ss'),
          ),
          creator: getTextCell(item.constructor_name),
          delete: isDeletable(item)
            ? getNormalButton('삭제', item.memo_seq, onDelete)
            : '',
        }),
      );
    }
    return result;
  }, [data]);

  // const dataToRow = (item,idx) =>{

  //     return {
  //         idx : getTextCell(`${idx}`),
  //         memo : getTextCell(item.memo),
  //         created : moment(item.created_dt).format('ll (dd) HH:mm:ss'),
  //         creator : item.constructor_name,
  //         delete:isDeletable(item)?getNormalButton('삭제',item.memo_seq,onDelete):''
  //     }
  // }

  return (
    <>
      <LAWarningLabel wPercent={100} layouts={{ pl: { sm: 0 } }}>
        관리메모는 선생님/관리자 참고용으로 학생 및 학부모에게 노출되지
        않습니다.
      </LAWarningLabel>
      <LACard next>
        <LACardTitle title={getTitle} />

        <LAProperty name="가입자 아이디">
          <LALabel wPercent={100}>{!!user && user.email}</LALabel>
        </LAProperty>
        <LAProperty name="학습자명">
          <LALabel wPercent={100}>{!!user && user.user_name}</LALabel>
        </LAProperty>
        <LAProperty name="관리 메모">
          <LAGridContainer>
            <LAInput
              wPercent={80}
              placeholder="메모를 입력하세요."
              value={memo.state}
              onChange={memo.handleChange}
            />
            <LALabel wPercent={5}></LALabel>
            <LAButton
              wPercent={10}
              layouts={{ pr: { sm: 1 } }}
              variant={'outlined'}
              onClick={onRegist}
            >
              등록
            </LAButton>
          </LAGridContainer>
        </LAProperty>
        <LAGridContainer layouts={{ mt: { sm: 1 } }}>
          {/* <LASimpleTable isPagination tableData={dataSource} />   */}
          <LADataTable columns={columns} rows={rows} />
        </LAGridContainer>
      </LACard>
    </>
  );
}

export default React.memo(UserMemoContents);
