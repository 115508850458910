import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import FeeadbackCard from 'modules/Cards/FeedbackCard';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAModal,
} from 'modules/Widgets';
import moment from 'moment';

function FeedbackContentsModal({
  classmateSeq,
  classroomSeq,
  show,
  handleClose,
}) {
  const { isLogin, loginState, action } = useLoginContext();
  const navigate = useNavigate();

  const { showAlert, showConfirm } = usePopAlert();

  const { queries } = useApiContext();
  //   const { feedbackSeq } = useParams();
  const [data, setData] = useState(null);
  const [classmate, setClassmate] = useState(null);
  const [feedbacks, setFeedbacks] = useState(null);

  const { result: resultData, request: requestClassmateInfo } = useCommonQuery({
    query: queries.Classroom.getClassmateInfo,
    params: { classroomSeq: classroomSeq, classmateSeq: classmateSeq },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setClassmate(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '학습자 정보 조회에 실패하였습니다.');
      },
    },
    initEnabled: false,
  });

  const { result: resultFeedbackData, request: requestClassmateFeedbacks } =
    useCommonQuery({
      query: queries.Feedback.getFeedbacks,
      params: { classmate_seq: classmateSeq },
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            setFeedbacks(data.result_data);
          } else {
            showAlert('알림', data.result_message);
          }
        },
        onError: (error) => {
          showAlert('알림', '학습자 정보 조회에 실패하였습니다.');
        },
      },
      initEnabled: false,
    });

  const { request: registRegistFeedback } = useCommonMutation({
    query: queries.Classroom.regist,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '피드백이 등록되었습니다.', {
            confirmHandler: () => {
              // answerInput.update('');
              // requestDetail();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '피드백 답글 등록에 실패했습니다.');
      },
    },
  });

  // useEffect(() =>{
  //   // requestDetail();
  // },[])

  const getScheduleDate = useMemo(() => {
    if (data && data.start_dt) {
      return moment(`${data.start_dt} ${data.schedule_time}`).format(
        'YYYY-MM-DD (dd) A hh:mm',
      );
    } else return '0000-00-00';
  }, [data]);

  const getUserDate = useMemo(() => {
    if (data) {
      // 홍사랑 / 8세 / 여자
      let date = moment(data.birth);
      let age = moment().diff(date.format('YYYYMMDD'), 'years');

      return `${data.user_name} / ${age} / ${data.gender_type_name}`;
    } else return '';
  }, [data]);

  const onRegistReplyClick = useCallback(() => {
    // registRegist({feedbackSeq : feedbackSeq, data:{subject:'',contents:''}})
  }, []);

  useEffect(() => {
    if (classroomSeq && classmateSeq) {
      requestClassmateInfo();
      requestClassmateFeedbacks();
    }
  }, [
    classroomSeq,
    classmateSeq,
    requestClassmateInfo,
    requestClassmateFeedbacks,
  ]);
  return (
    <LAModal
      title="클래스 피드백"
      width="90%"
      height="85%"
      show={show}
      onClose={handleClose}
    >
      {/* Start Info Area */}
      {/* <LACard>
        <LACardTitle title="클래스 정보" />
        <LAProperty name="카테고리">
          <LALabel wPercent={40}>{!!data && data.category_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LALabel wPercent={40}>{!!data && data.class_type_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 명">
          <LALabel wPercent={40}>{!!data && data.class_name}</LALabel>
        </LAProperty>
        <LAProperty name="선생님 명">
          <LALabel wPercent={40}>{!!data && data.teacher_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 일정">
          <LALabel wPercent={40}>{getScheduleDate}</LALabel>
        </LAProperty>
      </LACard> */}
      {/* End Info Area */}

      {/* Start Detail Info Area */}
      <LACard layouts={{ pt: 1 }}>
        <LACardTitle title="학습자 정보" />
        {/* <LAProperty name="아이디">
          <LALabel wPercent={40} clickable="true" content={`woongjin`}>
            woog***
          </LALabel>
        </LAProperty> */}
        <LAProperty name="가입자">
          <LALabel wPercent={40}>
            {!!classmate && classmate.member_name}
          </LALabel>
        </LAProperty>
        <LAProperty name="학습자">
          <LALabel wPercent={40}>{!!classmate && classmate.user_name}</LALabel>
        </LAProperty>
        <LAProperty name="특이 사항">
          <LALabel wPercent={40}>{(!!data && data.remark) || '없음'}</LALabel>
        </LAProperty>
      </LACard>
      {/* End Detail Info Area */}

      <LACard layouts={{ pt: 1 }}>
        <LACardTitle title="피드백 정보" />
        <LAGridContainer pt={2}>
          <FeeadbackCard
            classmateSeq={classmateSeq}
            classroomSeq={classroomSeq}
          />
        </LAGridContainer>
      </LACard>

      <LAGridContainer layouts={{ pt: { sm: 2 } }}>
        <LALabel wPercent={40}></LALabel>
        <LAButton
          wPercent={20}
          layouts={{ pr: { sm: 1 } }}
          onClick={handleClose}
        >
          닫기
        </LAButton>
        <LALabel wPercent={40}></LALabel>
      </LAGridContainer>
    </LAModal>
  );
}

export default FeedbackContentsModal;
