import { useEffect } from 'react';
import { useApiContext } from 'common/context/ApiContext';
import { useLoginContext } from 'common/context/MemberContext';
import useCommonMutation from 'common/hooks/useCommonMutation';

function AutoLogin() {
  const { queries } = useApiContext();
  const { action, accessTokenData } = useLoginContext();
  const accessToken = localStorage.getItem('accessToken');
  // const accessToken = sessionStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      action.setAccessToken(accessToken);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && accessTokenData.length > 0) {
      authAsync();
    }
  }, [accessTokenData]);

  const { requestAsync: authAsync } = useCommonMutation(
    {
      query: queries.Auth.authorize,
      callbacks: {
        onSuccess: (data) => {
          try {

            if (data.result_code === '0000') {
              action.setLoginData(data.result_data);

              window.ChannelIO(
                'boot',
                {
                //   pluginKey: 'b1b2fb02-3d12-4b71-b160-fdf971314b1b',
                    pluginKey: "86d9261d-0a09-4a51-9d3a-edb61d9e105e",
                  customLauncherSelector: '.chat-icon-wrap',
                  hideChannelButtonOnBoot: true,
                  language: 'ko',
                  memberId: `${data.result_data.member_seq}`, //fill with user id
                  profile: {
                    name: `${
                      data.result_data.member_name
                        ? data.result_data.member_name
                        : ''
                    }`, //fill with user name
                    mobileNumber: `${
                      data.result_data.profile&&data.result_data.profile.phone
                        ? data.result_data.profile.phone.replaceAll('+82 ', '0')
                        : ''
                    }`,
                    email: `${
                      data.result_data.profile&&data.result_data.profile.email
                        ? data.result_data.profile.email
                        : ''
                    }`,
                  },
                },
                function onBoot(error, user) {
                  if (error) {
                    console.error(error);
                  } else {
                  }
                },
              );
            } else if (data.result_code === '1010') {
              action.setLoginData(data.result_data);
            } else {
              //   alert(data);
            }
          } catch (e) {
            console.error('로그인 실패 =>', e);
          }
        },
        onError: (error) => {
        },
      },
    },
    [action],
  );

  return null;
}

export default AutoLogin;
