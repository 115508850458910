import React, { useState, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { LAButton, LAGridContainer } from 'modules/Widgets';

function CategoryLinkItem({
    index,
    title,
    selectedIndex,
    handleListItemClick,
    onDeleteClick,
}) {

    return (
        <ListItemButton 
            selected={selectedIndex.main_menu_seq === index.main_menu_seq} 
            onClick={(event) => handleListItemClick(event, selectedIndex)}
            sx={{ 
                '&:hover': { 
                    backgroundColor: '#eeeeee' 
                },
                '&.Mui-focused, &.Mui-selected:hover, &.Mui-selected, &.Mui-selected.Mui-focused': {
                    backgroundColor: '#e8f0fe',
                },
                height: "3rem",
            }}
        >
            <ListItemText primary={title} primaryTypographyProps={{ sx: { fontSize: "0.8rem", width: "30rem", }}} />
            <ListItemText primary={selectedIndex.link_url} primaryTypographyProps={{ sx: { fontSize: "0.8rem", width: "30rem", }}} />
            <LAButton layouts={{ fontSize: "0.8rem", maxWidth: "5rem !important", pr: "0.2rem", display: onDeleteClick ? "" : "none"}} onClick={(event) => onDeleteClick(event, selectedIndex.main_menu_seq)}>삭제</LAButton>
        </ListItemButton>
    );
}

export default React.memo(CategoryLinkItem);
