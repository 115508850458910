import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'TOGGLE':
        // const value = parseInt(action.value);
        const index = state.findIndex((key) => key === action.value);
        index >= 0 ? draft.splice(index, 1) : draft.push(action.value);
        break;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useKeywords(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const toggle = useCallback((value) => {
    dispatch({
      type: 'TOGGLE',
      value,
    });
  }, []);

  const handleClick = useCallback(
    (e) => {
      toggle(e.target.value);
    },
    [toggle],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  const isSelected = useCallback(
    (value) => {
      return state.findIndex((key) => key === value) >= 0;
    },
    [state],
  );

  return {
    state,
    handleClick,
    toggle,
    reset,
    isSelected,
  };
}

export default useKeywords;
