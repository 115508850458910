import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { getTodayDateText } from 'common/utils';
import MDBox from 'components/MDBox';
import EmptyImage from 'assets/images/empty.png';
import MagazineClassSearchModal from './MagazineClassSearchModal';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  LAFileAttachment,
  LASimpleTable,
  useInput,
  useDatePicker,
  useComboBox,
  useFileAttachment,
  useRadioGroup,
  useModal,
  LAImage
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import moment from 'moment';

const hourItems = [
  { key: '01', value: '오전 1시' },
  { key: '02', value: '오전 2시' },
  { key: '03', value: '오전 3시' },
  { key: '04', value: '오전 4시' },
  { key: '05', value: '오전 5시' },
  { key: '06', value: '오전 6시' },
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
  { key: '22', value: '오후 10시' },
  { key: '23', value: '오후 11시' },
  { key: '00', value: '오전 12시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '05', value: '05분' },
  { key: '10', value: '10분' },
  { key: '15', value: '15분' },
  { key: '20', value: '20분' },
  { key: '25', value: '25분' },
  { key: '30', value: '30분' },
  { key: '35', value: '35분' },
  { key: '40', value: '40분' },
  { key: '45', value: '45분' },
  { key: '50', value: '50분' },
  { key: '55', value: '55분' },
];

function MagazineRegistModal({ show, handleClose, magazineSeq }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [magazineInfo, setMagazineInfo] = useState(undefined);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [srcSelectedClasses, setSrcSelectedClasses] = useState([]);
  const [addedClasses, setAddedClasses] = useState([]);
  const [deletedClasses, setDeletedClasses] = useState([]);
  const todayDateText = getTodayDateText('-');
  const magazineTitle = useInput('');
  const magazineSubTitle = useInput('');

  const [modalTitle, setModalTitle] = useState('매거진 등록');

  const eventExposureDate = useDatePicker(todayDateText);
  const eventExposureHourValue = useComboBox('09');
  const eventExposureMinuteValue = useComboBox('00');

  const pcLink = useInput('');
  const thumbnailFile = useFileAttachment(5120, {
    name: '',
  });
  const subThumbnailFile = useFileAttachment(5120, {
    name: '',
  });
  const getContentsEditorValue = useRef();
  const getContentsMoEditorValue = useRef();
  const visibleType = useRadioGroup(0);
  const contentType = useRadioGroup(0);
  const visibleStartDate = useDatePicker(todayDateText);
  const visibleStartHourValue = useComboBox('09');
  const visibleStartMinuteValue = useComboBox('00');
  const visibleEndDate = useDatePicker(
    moment().add(6, 'd').format('YYYY-MM-DD'),
  );
  const visibleEndHourValue = useComboBox('09');
  const visibleEndMinuteValue = useComboBox('00');
  const classSearchModal = useModal(false);

  const updateInputs = useCallback((data) => {
    magazineTitle.update(data.magazine_title);
    magazineSubTitle.update(data.magazine_sub_title);
    pcLink.update(data.magazine_link_url);

    if (data.exposure_dt) {
      let exposureDate = moment(data.exposure_dt);
      if (exposureDate.isValid()) {
        eventExposureDate.select(exposureDate.format('YYYY-MM-DD'));
        eventExposureHourValue.select(exposureDate.format('hh'));
        eventExposureMinuteValue.select(exposureDate.format('mm'));
      }
    }

    thumbnailFile.update({
      file: data.main_image_url,
    });
    subThumbnailFile.update({
      file: data.sub_image_url,
    });
    if (data.content_type) {
      contentType.update(data.content_type);
    }

    visibleType.update(data.exposure_status);
    if (data.exposure_status == 2) {
      const startMoment = moment(data.reserved_exposure_start_dt);
      const endMoment = moment(data.reserved_exposure_end_dt);
      visibleStartDate.select(startMoment.format('YYYY-MM-DD'));
      visibleStartHourValue.select(startMoment.format('HH'));
      visibleStartMinuteValue.select(startMoment.format('mm'));
      visibleEndDate.select(endMoment.format('YYYY-MM-DD'));
      visibleEndHourValue.select(endMoment.format('HH'));
      visibleEndMinuteValue.select(endMoment.format('mm'));
    }

    setSelectedClasses(
      data.classes.map((item) => {
        return {
          seq: item.class_seq,
          data: item,
        };
      }),
    );
    setSrcSelectedClasses(
      data.classes.map((item) => {
        return {
          seq: item.class_seq,
          data: item,
        };
      }),
    );
  }, []);

  const { request: requestMagazineInfo } = useCommonQuery({
    query: queries.Magazine.getMagazineInfo,
    params: {
      magazine_seq: magazineSeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setMagazineInfo(data.result_data);
          updateInputs(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '매거진 상세 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestRegist } = useCommonMutation({
    query: queries.Magazine.registMagazine,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '매거진이 등록되었습니다.', {
            confirmHandler: handleClose,
            cancelHandler: handleClose,
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '매거진 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestUpdate } = useCommonMutation({
    query: queries.Magazine.updateMagazine,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '매거진이 수정되었습니다.', {
            confirmHandler: handleClose,
            cancelHandler: handleClose,
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '매거진 수정에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (!magazineSeq) {
      return;
    }
    setModalTitle('매거진 상세');
    requestMagazineInfo();
  }, [magazineSeq]);

  const onThumbnailFileChange = useCallback(
    (e) => {
      // setDeletedAdditionalImages((prev) => [...prev, thumbnailFile.state.image_seq]);
      thumbnailFile.handleChange(e);
    },
    [thumbnailFile],
  );

  const onSubThumbnailFileChange = useCallback(
    (e) => {
      // setDeletedAdditionalImages((prev) => [...prev, thumbnailFile.state.image_seq]);
      subThumbnailFile.handleChange(e);
    },
    [subThumbnailFile],
  );

  const onSearchClassClick = useCallback((e) => {
    classSearchModal.visible(true);
  }, []);

  const onClassSearched = useCallback(
    (e) => {
      classSearchModal.handleClose(e);
    },
    [classSearchModal.handleClose],
  );

  const isSelectedClass = useCallback(
    (classSeq) => {
      return selectedClasses.find((item) => item.seq == classSeq)
        ? true
        : false;
    },
    [selectedClasses],
  );

  const isDeletedClass = useCallback(
    (classSeq) => {
      return deletedClasses.find((item) => item.seq == classSeq) ? true : false;
    },
    [deletedClasses],
  );

  const isAddedClass = useCallback(
    (classSeq) => {
      return addedClasses.find((item) => item.seq == classSeq) ? true : false;
    },
    [addedClasses],
  );

  const isSrcSelectedClass = useCallback(
    (classSeq) => {
      return srcSelectedClasses.find((item) => item.seq == classSeq)
        ? true
        : false;
    },
    [srcSelectedClasses],
  );

  const onClassSelectClick = useCallback(
    (item) => {
      if (isSelectedClass(item.class_seq)) {
        setSelectedClasses((prev) =>
          prev.filter((data) => data.seq != item.class_seq),
        );

        if (magazineSeq && isAddedClass(item.class_seq)) {
          setAddedClasses((prev) =>
            prev.filter((data) => data.seq != item.class_seq),
          );
        }
        if (
          magazineSeq &&
          isSrcSelectedClass(item.class_seq) &&
          !isDeletedClass(item.class_seq)
        ) {
          setDeletedClasses((prev) =>
            prev.concat({
              seq: item.class_seq,
              data: item,
            }),
          );
        }
      } else {
        setSelectedClasses((prev) =>
          prev.concat({
            seq: item.class_seq,
            data: item,
          }),
        );

        if (
          magazineSeq &&
          !isSrcSelectedClass(item.class_seq) &&
          !isAddedClass(item.class_seq)
        ) {
          setAddedClasses((prev) =>
            prev.concat({
              seq: item.class_seq,
              data: item,
            }),
          );
        }
        if (magazineSeq && isDeletedClass(item.class_seq)) {
          setDeletedClasses((prev) =>
            prev.filter((data) => data.seq != item.class_seq),
          );
        }
      }
    },
    [
      magazineSeq,
      isSelectedClass,
      isDeletedClass,
      isAddedClass,
      isSrcSelectedClass,
    ],
  );

  const onRegistClick = useCallback(() => {
    if (!magazineTitle?.state || magazineTitle.state.length === 0) {
      showAlert('알림', '매거진 제목을 입력해주세요.');
      return;
    }
    // if (!magazineSubTitle?.state || magazineSubTitle.state.length === 0) {
    //   showAlert('알림', '매거진 부제목을 입력해주세요');
    //   return;
    // }
    // if (!pcLink?.state || pcLink.state.length === 0) {
    //   showAlert('알림', 'PC 링크를 입력해주세요');
    //   return;
    // }

    let contentHtml = '';
    if (
      !getContentsEditorValue.current ||
      getContentsEditorValue.current().length == 0
    ) {
      showAlert('알림', 'PC 내용을 입력해주세요.');
      return;
    } else {
      if (getContentsEditorValue.current().length * 0.7 > 5000000) {
        showAlert(
          '알림',
          'PC 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.',
        );
        return;
      }
      contentHtml = `<div style="font-size: 16px; font-family: 노토산스;">${getContentsEditorValue.current()}</div>`;
    }

    let contentMoHtml = '';
    if (
      !getContentsMoEditorValue.current ||
      getContentsMoEditorValue.current().length == 0
    ) {
      showAlert('알림', 'Mobile 내용을 입력해주세요.');
      return;
    } else {
      if (getContentsMoEditorValue.current().length * 0.7 > 5000000) {
        showAlert(
          '알림',
          'Mobile 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.',
        );
        return;
      }
      contentMoHtml = `<div style="font-size: 16px; font-family: 노토산스;">${getContentsMoEditorValue.current()}</div>`;
    }

    if (!thumbnailFile?.state?.file || thumbnailFile.state.file.length === 0) {
      showAlert('알림', '썸네일(메인)을 선택해주세요.');
      return;
    }
    if (
      !subThumbnailFile?.state?.file ||
      subThumbnailFile.state.file.length === 0
    ) {
      showAlert('알림', '썸네일(서브)을 선택해주세요.');
      return;
    }
    if (!selectedClasses || selectedClasses.length === 0) {
      showAlert('알림', '클래스를 선택해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('magazine_title', magazineTitle.state);
    formData.append('magazine_sub_title', magazineSubTitle.state);

    formData.append(
      'exposure_dt',
      `${eventExposureDate.state} ${eventExposureHourValue.state}:${eventExposureMinuteValue.state}`,
    );
    formData.append('content_type', contentType.state);

    formData.append('magazine_contents', contentHtml);
    formData.append('magazine_contents_mo', contentMoHtml);
    // formData.append('magazine_link_url', pcLink.state);
    if (thumbnailFile.state.info) {
      formData.append('main_image_file', thumbnailFile.state.info);
    }
    if (subThumbnailFile.state.info) {
      formData.append('sub_image_file', subThumbnailFile.state.info);
    }
    formData.append('exposure_status', visibleType.state);
    formData.append(
      'reserved_exposure_start_dt',
      `${visibleStartDate.state} ${visibleStartHourValue.state}:${visibleStartMinuteValue.state}`,
    );
    formData.append(
      'reserved_exposure_end_dt',
      `${visibleEndDate.state} ${visibleEndHourValue.state}:${visibleEndMinuteValue.state}`,
    );

    if (magazineSeq) {
      formData.append('magazine_seq', magazineSeq);
      formData.append(
        'add_classes',
        JSON.stringify(addedClasses.map((item) => item.seq)),
      );
      formData.append(
        'delete_classes',
        JSON.stringify(deletedClasses.map((item) => item.seq)),
      );
      const params = {
        magazine_seq: magazineSeq,
        data: formData,
      };
      requestUpdate(params);
    } else {
      formData.append(
        'classes',
        JSON.stringify(selectedClasses.map((item) => item.seq)),
      );
      requestRegist(formData);
    }
  }, [
    magazineSeq,
    magazineTitle.state,
    magazineSubTitle.state,
    pcLink.state,
    eventExposureDate.state,
    eventExposureHourValue.state,
    eventExposureMinuteValue.state,
    contentType.state,
    getContentsEditorValue,
    getContentsMoEditorValue,
    thumbnailFile.state,
    subThumbnailFile.state,
    visibleType.state,
    visibleStartDate.state,
    visibleStartHourValue.state,
    visibleStartMinuteValue.state,
    visibleEndDate.state,
    visibleEndHourValue.state,
    visibleEndMinuteValue.state,
    selectedClasses,
    addedClasses,
    deletedClasses,
  ]);

  const getClassRows = useCallback(() => {
    const result = [];
    selectedClasses.forEach((item, index) => {
      result.push([
        {
          text: item.data.class_seq,
        },
        {
          text: item.data.category_name,
        },
        {
          text: item.data.class_type_name,
        },
        {
          text: item.data.class_name,
        },
        {
          text: `${item.data.times}회차`,
        },
        {
          text: item.data.all_age_yn
            ? '전연령'
            : `${item.data.min_target_age}~${item.data.max_target_age}세`,
        },
        {
          text: item.data.worksheet_yn ? 'Y' : 'N',
        },
        {
          text: item.data.teacher_name,
        },
        {
          text: item.data.created_dt
            ? `${moment(item.data.created_dt).format('YYYY-MM-DD')}`
            : '',
        },
        {
          text: '삭제',
          type: 'button',
          callback: {
            onClick: (e) => onClassSelectClick(item.data),
          },
        },
      ]);
    });
    return result;
  }, [selectedClasses]);

  const classTableData = useMemo(() => {
    return {
      headers: [
        {
          text: '코드',
        },
        {
          text: '카테고리',
        },
        {
          text: '유형',
        },
        {
          text: '클래스명',
        },
        {
          text: '회차',
        },
        {
          text: '연령',
        },
        {
          text: '교재',
        },
        {
          text: '선생님',
        },
        {
          text: '등록일',
        },
        {
          text: '삭제',
        },
      ],
      wPercentList: [5, 12, 10, 26, 7, 7, 5, 9, 12, 7],
      rows: getClassRows(),
    };
  }, [getClassRows]);

  return (
    <>
      <LAModal
        title={modalTitle}
        width="90%"
        height="95%"
        show={show}
        onClose={handleClose}
      >
        <LACard>
          <LACardTitle title="매거진 정보" />

          <LAProperty name="제목" required>
            <LAInput
              placeholder="제목을 입력해주세요."
              value={magazineTitle.state}
              onChange={magazineTitle.handleChange}
            />
          </LAProperty>

          <LAProperty name="부제목" required>
            <LAInput
              placeholder="부제목을 입력해주세요."
              value={magazineSubTitle.state}
              onChange={magazineSubTitle.handleChange}
            />
          </LAProperty>

          {/* <LAProperty name="PC 링크" required>
            <LAInput
              placeholder="PC 링크를 입력해주세요."
              value={pcLink.state}
              onChange={pcLink.handleChange}
            />
          </LAProperty> */}

          <LAProperty name="게시 일시" required>
            <LAGridContainer>
              <LADatePicker
                wPercent={15}
                layouts={{ pr: { sm: 1 } }}
                value={eventExposureDate.state}
                onChange={eventExposureDate.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={hourItems}
                layouts={{ pr: { sm: 1 } }}
                value={eventExposureHourValue.state}
                onChange={eventExposureHourValue.handleChange}
              />
              <LAComboBox
                wPercent={15}
                items={minItems}
                layouts={{ pr: { sm: 1 } }}
                value={eventExposureMinuteValue.state}
                onChange={eventExposureMinuteValue.handleChange}
              />
            </LAGridContainer>
            {/* <LAInput
              placeholder="게시 일시를 입력해주세요."
              value={magazineRegisterDate.state}
              onChange={magazineRegisterDate.handleChange}
            /> */}
            <LAWarningLabel>
              프론트에 표시되는 게시 일시를 입력하세요.
            </LAWarningLabel>
          </LAProperty>

          <LAProperty name="내용 (PC)" required>
            <LATextEditor
              name="contents_e"
              value={magazineInfo?.magazine_contents || ''}
              getEditorValue={getContentsEditorValue}
            />
          </LAProperty>

          <LAProperty name="내용 (Mobile)" required>
            <LATextEditor
              name="contents_e_mo"
              value={magazineInfo?.magazine_contents_mo || ''}
              getEditorValue={getContentsMoEditorValue}
            />
          </LAProperty>

          <LAProperty name="컨텐츠 타입" required>
            <LAGridContainer>
              <LARadioGroup
                props={[
                  {
                    text: '텍스트 에디터',
                    value: 0,
                  },
                  {
                    text: '커스텀 컨텐츠',
                    value: 1,
                  },
                ]}
                value={contentType.state}
                onChange={contentType.handleChange}
              />
              <LAWarningLabel>
                컨텐츠 타입은 이벤트 상세 페이지에 표시되는 상세 설명의
                종류입니다.
              </LAWarningLabel>
              <LAWarningLabel>
                * 텍스트 에디터 : "상세 설명"의 내용을 표시합니다. * 커스텀
                컨텐츠 : 별도 퍼블리싱한 내용을 표시합니다.
              </LAWarningLabel>
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="썸네일(메인)" required>
            <LAGridContainer>
              <LAImage wPercent={30} file={thumbnailFile.state.file} />
              <LAGridItem wPercent={60} layouts={{ ml: 2 }}>
                <LAGridContainer layouts={{ ml: 1, pl: 1 }}>
                  <LAFileAttachment
                    hiddenLabel
                    placeholder=""
                    props={{
                      accept: 'image/jpg,image/png,image/jpeg,image/gif',
                    }}
                    value={thumbnailFile.state.name}
                    onChange={onThumbnailFileChange}
                  />
                </LAGridContainer>
                <MDBox color="text" fontSize="1rem" lineHeight={1}>
                  <LALabel
                    layouts={{ pl: 1, pt: 1 }}
                    props={{
                      variant: 'caption',
                      color: 'error',
                      fontWeight: 'regular',
                      verticalAlign: 'middle',
                      sx: {
                        color: '#ff0000',
                      },
                    }}
                  >
                    - 이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로
                    등록됩니다.
                    <br />
                    - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)
                    <br />
                    - 이미지 사이즈 : 1196 * 572 / 형식 : jpg, jpeg, png (jpeg
                    등록 권장)
                    <br />- 5MB 이하로 등록가능
                  </LALabel>
                </MDBox>
              </LAGridItem>
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="썸네일(서브)" required>
            <LAGridContainer>
              <LAImage wPercent={30} file={subThumbnailFile.state.file} />
              <LAGridItem wPercent={60} layouts={{ ml: 2 }}>
                <LAGridContainer layouts={{ ml: 1, pl: 1 }}>
                  <LAFileAttachment
                    hiddenLabel
                    placeholder=""
                    props={{
                      accept: 'image/jpg,image/png,image/jpeg,image/gif',
                    }}
                    value={subThumbnailFile.state.name}
                    onChange={onSubThumbnailFileChange}
                  />
                </LAGridContainer>
                <MDBox color="text" fontSize="1rem" lineHeight={1}>
                  <LALabel
                    layouts={{ pl: 1, pt: 1 }}
                    props={{
                      variant: 'caption',
                      color: 'error',
                      fontWeight: 'regular',
                      verticalAlign: 'middle',
                      sx: {
                        color: '#ff0000',
                      },
                    }}
                  >
                    - 이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로
                    등록됩니다.
                    <br />
                    - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)
                    <br />
                    - 이미지 사이즈 : 1196 * 572 / 형식 : jpg, jpeg, png (jpeg
                    등록 권장)
                    <br />- 5MB 이하로 등록가능
                  </LALabel>
                </MDBox>
              </LAGridItem>
            </LAGridContainer>
          </LAProperty>

          <LAProperty name="노출 여부" required>
            <LAGridContainer>
              <LARadioGroup
                props={[
                  {
                    text: '미노출',
                    value: 0,
                  },
                  {
                    text: '노출',
                    value: 1,
                  },
                  // {
                  //   text: '예약 노출',
                  //   value: 2,
                  // },
                ]}
                value={visibleType.state}
                onChange={visibleType.handleChange}
              />
            </LAGridContainer>
            {visibleType.state == 2 && (
              <LAGridContainer>
                <LADatePicker
                  wPercent={15}
                  layouts={{ pr: { sm: 1 } }}
                  value={visibleStartDate.state}
                  onChange={visibleStartDate.handleChange}
                />
                <LAComboBox
                  wPercent={15}
                  items={hourItems}
                  layouts={{ pr: { sm: 1 } }}
                  value={visibleStartHourValue.state}
                  onChange={visibleStartHourValue.handleChange}
                />
                <LAComboBox
                  wPercent={15}
                  items={minItems}
                  layouts={{ pr: { sm: 1 } }}
                  value={visibleStartMinuteValue.state}
                  onChange={visibleStartMinuteValue.handleChange}
                />
                <LALabel wPercent={1}>~</LALabel>
                <LADatePicker
                  wPercent={15}
                  layouts={{ pl: { sm: 1 } }}
                  value={visibleEndDate.state}
                  onChange={visibleEndDate.handleChange}
                />
                <LAComboBox
                  wPercent={15}
                  items={hourItems}
                  layouts={{ pl: { sm: 1 } }}
                  value={visibleEndHourValue.state}
                  onChange={visibleEndHourValue.handleChange}
                />
                <LAComboBox
                  wPercent={15}
                  items={minItems}
                  layouts={{ pl: { sm: 1 } }}
                  value={visibleEndMinuteValue.state}
                  onChange={visibleEndMinuteValue.handleChange}
                />
              </LAGridContainer>
            )}
          </LAProperty>
        </LACard>

        <LACard next>
          <LACardTitle title="매거진 클래스 정보" />

          <LAProperty name="클래스 등록" required>
            <LAButton wPercent={15} onClick={onSearchClassClick}>
              클래스 검색
            </LAButton>
            {selectedClasses.length > 0 && (
              <LAGridContainer layouts={{ pt: 2 }}>
                <LASimpleTable tableData={classTableData} />
              </LAGridContainer>
            )}
          </LAProperty>
        </LACard>

        <LAGridContainer layouts={{ pt: 3 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ mr: 1 }}
            variant={'outlined'}
            btnPaddingY={12}
            onClick={handleClose}
          >
            목록
          </LAButton>
          <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onRegistClick}>
            저장
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LAModal>

      <MagazineClassSearchModal
        show={classSearchModal.state}
        handleClose={onClassSearched}
        handleClassSelect={onClassSelectClick}
        isSelectedClass={isSelectedClass}
      />
    </>
  );
}

export default MagazineRegistModal;
