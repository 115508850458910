import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import MDTypography from 'components/MDTypography';

function LARadioButton({ text, value }) {
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={
        <MDTypography variant="button" fontWeight="regular">
          {text}
        </MDTypography>
      }
    />
  );
}

export default React.memo(LARadioButton);
