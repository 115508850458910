import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/inquires';

const InquireApi = (requestFunc) => {
  return {
    Inquire: {
      getInquireList: {
        key: 'getInquireList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },      
      getInquireDetail: {
        key: 'getInquireDetail',
        queryFn: (inquirySeq) => requestFunc(METHOD.GET, `${baseURL}/${inquirySeq}`),
      },      
      registAnswer: {
        key: 'registAnswer',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/${params.inquirySeq}/answer`,params.data),
      },
      deleteAnswer: {
        key: 'deleteAnswer',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.inquirySeq}/answer/${params.answerSeq}/${params.delete_yn}`),
      },
      modifyAnswer: {
        key: 'modifyAnswer',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.inquirySeq}/answer/${params.answerSeq}`,params.data),
      },            
    },
  };
};

export default InquireApi;
