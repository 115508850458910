import React, { useState, useCallback, useEffect, useContext } from 'react';

import { UNSAFE_NavigationContext } from "react-router-dom";

import { LAModal, LACard, LACardTitle } from 'modules/Widgets';

import TogetherClassRegistContents from './TogetherClassRegistContents';

function TogetherClassRegistModal({show, handleClose}) {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
          window.history.pushState(null, null, window.location.pathname);
          if (navigation) {
          navigation.listen((locationListener) => {
              if(locationListener.action == 'POP') {
                  handleClose();
              }
          });
      }
  }, []);

    return (
      <LAModal
        title="투게더 클래스 신규 개설"
        width="90%"
        height="95%"
        show={show}
        onClose={handleClose}
        >
          <TogetherClassRegistContents onClickList={handleClose}/>
      </LAModal>
  );
}

export default React.memo(TogetherClassRegistModal);
