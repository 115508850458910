import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
    LAModal,
    LACard,
    LACardTitle,
    LAGridContainer,
    LAProperty,
    LALabel,
    LAButton,
    LAInput,
    LASimpleTable,
    useInput,
    LADivider,
} from 'modules/Widgets';
import useCommonMutation from "../../../common/hooks/useCommonMutation";

function MembershipSearchModal({ show, handleClose, member, customerNumber, refreshMemberData }) {
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();
    const [searchClicked, setSearchClicked] = useState(false);
    const [membershipData, setMembershipData] = useState([]);
    //   const searchInput = useInput(customerNumber?? '');
    const searchInput = useInput('');


    useEffect(() => {
        if (customerNumber !== undefined) {
            searchInput.update(customerNumber);
        }
    }, [customerNumber]);

    useEffect(() => {
        setSearchClicked(false);
        refreshMemberData();
        setMembershipData([]);
        // searchInput.reset();
    },[handleClose])

    const handleModalClose = useCallback(() => {
        handleClose();
    }, []);

    const { request: requestMembership } = useCommonQuery({
        query: queries.Member.getMembership,
        params: {
            customer_number:searchInput.state ? searchInput.state : ''
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setMembershipData(data.result_data);
                } else {
                    console.log(data.result_message);
                }
            },
            onError: (error) => {
                console.log(error);
                showAlert('알림', '고객번호 조회에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const { request: requestChangeMembership } = useCommonMutation({
        query: queries.Member.changeMembership,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '변경에 성공하였습니다.');
                } else {
                    showAlert('알림', '멤버십 변경에 실패하였습니다.');
                }
            },
            onError: (error) => {
                showAlert('알림', '변경에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    const onSearchClick = useCallback(() => {
        requestMembership();
        setSearchClicked(true);
    }, [requestMembership, searchClicked]);

    const toPhone = (value) => {
        if(!value) return '';

        if(value.length === 11) {
            value =`${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
        } else if (value.length === 9) {
            value =`${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
        } else {
            value =""
        }
        return value;
    }

    const onMembershipSelect = useCallback(() => {
        if(customerNumber === membershipData?.customer_number) {
            return showAlert('알림', '변경하려는 고객번호가 동일합니다.')
        }
        showConfirm('알림', '멤버십 정보를 반영하시겠습니까?\n변경 즉시 사용자 화면에 반영 되므로 \n 신중히 변경해주세요.',{
            confirmHandler: () => {
                requestChangeMembership({
                    member_seq: member?.member_seq,
                    original_customer_number: customerNumber,
                    new_customer_number: membershipData ? membershipData?.customer_number : null
                });
            },
        });
    }, [requestChangeMembership,customerNumber,membershipData]);

    const membershipResultTableData = useMemo(() => {
        if (!membershipData) {
            return {
                headers: [],
                wPercentList: [],
                rows: [[ {text : '※ 조회 결과가 없습니다.' }]]
            };
        } else {
            return {
                headers: [
                    { text: '고객번호' },
                    { text: '계약자명' },
                    { text: '휴대폰 번호' },
                    { text: '생년월일' },
                    { text: '멤버십 정보' },
                    { text: '고객정보 변경' },
                ],
                wPercentList: [15, 10, 15, 15, 30, 15],
                rows: [[
                        { text: `${membershipData?.customer_number}`},
                        { text: `${membershipData?.name}` },
                        { text: `${toPhone(membershipData?.phone_number)}` },
                        { text: `${membershipData?.birthday}` },
                        { text: membershipData.contract_list ? membershipData?.contract_list.map((contract) => contract.contractName).join(', ') : '' },
                        { text: membershipData && <LAButton onClick={() => onMembershipSelect() }>{'선택'}</LAButton>},
                    ]],
            };
        }
    }, [
        membershipData, 
        membershipData?.customer_number, 
        membershipData?.name,
        membershipData?.phone_number, 
        membershipData?.birthday, 
        membershipData?.contract_list
    ]);

    return (
        <LAModal title="" width="60%" height={searchClicked ? "50%" : "50%"} show={show} onClose={handleModalClose}>
            <LACard>
                <LACardTitle title="웅진 멤버십 등록 및 변경" />
                <LAProperty name="고객번호 입력" wNameWidth={15}>
                <LAGridContainer>
                    <LAInput
                        wPercent={40}
                        layouts={{ pl: 1 }}
                        value={searchInput.state}
                        onChange={searchInput.handleChange}
                        placeholder="고객번호를 입력해 주세요."
                    />
                    <LALabel wPercent={5} />
                    <LAButton wPercent={20} layouts={{ pr: { sm: 0.5 } }} onClick={onSearchClick}>고객번호 조회</LAButton>
                </LAGridContainer>
                </LAProperty>
                
                <LADivider pt={2} pb={4} />
                <LACardTitle title="고객번호 조회 결과" pb={2}/>
                {searchClicked && ( 
                    <LASimpleTable tableData={membershipResultTableData} props={{ mt: 2 }} /> 
                )}
            </LACard>
            <LAGridContainer pt={4}>
                <LALabel wPercent={40} />
                <LAButton wPercent={20} layouts={{ pr: { sm: 0.5 } }} onClick={handleClose}>닫기</LAButton>
            </LAGridContainer>
        </LAModal>
    );
}

export default React.memo(MembershipSearchModal);
