import React, { useCallback, useEffect, useMemo, useState } from "react";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";

import { useNavigate } from "react-router-dom";
import { useApiContext } from "common/context/ApiContext";

import { periodItems } from "common/constants";
import AccountDetailModal from "./AccountDetailModal";

import { getTodayDateText, getDaysInMonth } from "common/utils";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import {
  getNormalButton,
  getTextCell,
  LAButton,
  LACard,
  LACodeComboBox,
  LACodeRadioGroup,
  LAComboBox,
  LADataTable2,
  LADatePicker,
  LAGridContainer,
  LAInput,
  LALabel,
  LAProperty,
  LARadioGroup,
  LATabSelector,
  useComboBox,
  useDatePicker,
  useInput,
  useModal,
  useRadioGroup,
  useTabSelector,
} from "modules/Widgets";
import moment from "moment";
import { API_BASE_URL } from "../../../api/AppConfig";
import { useLoginContext } from "../../../common/context/MemberContext";
import DownloadReasonModal from "pages/Order/components/DownloadReasonModal";
import DownloadListModal from "pages/Order/components/DownloadListModal";

function AccountList() {
  const { loginState } = useLoginContext();
  const pointModal = useModal();
  const navigate = useNavigate();
  const priceSplitter = (number) =>
    number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const { showAlert } = usePopAlert();
  const { queries } = useApiContext();
  const downloadReasonModal = useModal(false);
  const downloadListModal = useModal(false);

  const [rows, setRows] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [page, setPage] = useState(0); // 마지막 페이지네이션

  const memberStatus = useRadioGroup(-1);
  // const visitType = useRadioGroup(0);
  const marketingYn = useRadioGroup(0);
  const subscriptionType = useRadioGroup(0);
  const periodType = useComboBox("1");
  const periodTab = useTabSelector(3);

  const searchType = useComboBox("0");
  const searchInput = useInput("");

  const todayDateText = getTodayDateText("-");
  const startDate = useDatePicker(todayDateText);
  const endDate = useDatePicker(todayDateText);

  const startDateText = useMemo(() => {
    return startDate.state.replaceAll("-", "");
  }, [startDate.state]);
  const endDateText = useMemo(() => {
    return endDate.state.replaceAll("-", "");
  }, [endDate.state]);

  const [selectedMemberSeq, setSelectedMemberSeq] = useState(0);
  const accountDetailModal = useModal(false);

  const thisYear = useMemo(() => moment().format("YYYY"), []);
  const thisMonth = useMemo(() => moment().format("MM"), []);

  const yearTypeDwn = useComboBox(thisYear);
  const monthTypeDwn = useComboBox(thisMonth);

  const onPointClick = () => {
    pointModal.visible(true);
  };

  const onUserDetailClick = useCallback((no) => {
    setSelectedMemberSeq(no);
    accountDetailModal.visible(true);
  });

  const [searchParams, setSearchParams] = useState({});
  const dataToRow = (data, index) => {
    return {
      no: getTextCell(data.member_seq),
      // id: getTextCell(`${data.login_id ? data.login_id : ''}`),
      name: getTextCell(getName(data), `${data.member_seq}`, onUserDetailClick),
      purchase_count: getTextCell(
        priceSplitter(`${data.purchase_count ? data.purchase_count : 0}`)
      ),
      purchase_price: getTextCell(
        priceSplitter(`${data.order_price ? data.order_price : 0}`)
      ),
      refund_price: getTextCell(
        priceSplitter(`${data.refund_price ? data.refund_price : 0}`)
      ),
      point: getTextCell(priceSplitter(`${data.point ? data.point : 0}`)),
      // bookClub: getTextCell(data.subscription_type == 1 ? '미래회원': data.subscription_type == 2 ?'교문/온라인 회원' : '비회원'),
      bookClub: getTextCell(contractType(data, index)),
      // visit_device:'',
      visit_date: getTextCell(
        `${
          data.signin_dt
            ? moment(data.signin_dt).format("YYYY-MM-DD (dd) A hh:mm")
            : ""
        }`
      ),
      regist_date: getTextCell(
        `${
          data.created_dt
            ? moment(data.created_dt).format("YYYY-MM-DD (dd) A hh:mm")
            : ""
        }`
      ),
      referral_code: getTextCell(data.referral_code),
      referral_member_seq: getTextCell(
        `${data.referral_member_seq ? data.referral_member_name : ""}`
      ),
      status: getTextCell(
        `${
          data.dormancy_yn
            ? "휴면"
            : data.expired_yn
            ? "휴면"
            : data.resigned_yn
            ? "탈퇴"
            : "정상"
        }`
      ),
      marketing_sms_yn: getTextCell(
        `${data.marketing_sms_agree_yn ? "동의" : "미동의"}`
      ),
      marketing_email_yn: getTextCell(
        `${data.marketing_email_agree_yn ? "동의" : "미동의"}`
      ),
      detail: getNormalButton("상세", `${data.member_seq}`, onUserDetailClick),
    };
  };

  useEffect(() => {
    startDate.selectByPeriod(periodTab.state);
    endDate.reset(periodTab.state);
  }, [periodTab.state, endDate.reset]);

  useEffect(() => {
    if (page != 0) {
      setTablePage(page);
    }
  }, [rows]);

  const onSearchClick = useCallback(() => {
    let searchParams = {
      member_state_type: memberStatus.state,
      subscription_type: subscriptionType.state,
      marketing_yn: marketingYn.state,
      period_type: periodType.state,
      start_dt: startDateText,
      end_dt: endDateText,
      query_type: searchType.state,
      query: searchInput.state,
    };
    setSearchParams(searchParams);
  }, [
    memberStatus.state,
    subscriptionType.state,
    marketingYn.state,
    periodType.state,
    startDateText,
    endDateText,
    searchType.state,
    searchInput.state,
  ]);
  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  const getDateTimeText = (value) => {
    return new Date(value.replaceAll("Z", ""));
  };

  const onInitializeClick = useCallback(() => {
    memberStatus.reset();
    // visitType.reset();
    subscriptionType.reset();
    marketingYn.reset();
    periodType.reset();
    periodTab.reset();
    startDate.reset();
    endDate.reset();
    searchType.reset();
    searchInput.reset();
  }, [
    memberStatus.reset,
    subscriptionType.reset,
    marketingYn.reset,
    periodType.reset,
    periodTab.reset,
    startDate.reset,
    endDate.reset,
    searchType.reset,
    searchInput.reset,
  ]);

  const columns = useMemo(() => {
    return [
      { Header: "회원코드", accessor: "no", width: "4%" },
      { Header: "이름", accessor: "name", width: "12%" },
      { Header: "누적 구매 건수", accessor: "purchase_count", width: "5%" },
      { Header: "누적 구매금액", accessor: "purchase_price", width: "6%" },
      { Header: "누적 환불금액", accessor: "refund_price", width: "6%" },
      { Header: "올머니", accessor: "point", width: "7%" },
      { Header: "북클럽 회원", accessor: "bookClub", width: "5%" },
      { Header: "가입일", accessor: "regist_date", width: "10%" },
      { Header: "최근 방문일", accessor: "visit_date", width: "10%" },
      { Header: "피추천 코드", accessor: "referral_code", width: "7%" },
      { Header: "추천인", accessor: "referral_member_seq", width: "7%" },
      { Header: "상태", accessor: "status", width: "5%" },
      { Header: "SMS", accessor: "marketing_sms_yn", width: "5%" },
      { Header: "E-Mail", accessor: "marketing_email_yn", width: "5%" },
      { Header: "상세", accessor: "detail", width: "7%" },
    ];
  }, []);

  const getName = useCallback((item) => {
    let result = item.member_name ? item.member_name : "";
    return result;
  });

  const contractType = useCallback((item, idx) => {
    let contractList = "";

    if (
      item.contract_gyomun_yn === 0 &&
      item.contract_mire_yn === 0 &&
      item.contract_online_yn === 0
    ) {
      contractList = "비회원";
    }

    if (item.contract_gyomun_yn === 1) {
      contractList += contractList.length > 0 ? ", 교문회원" : "교문회원";
    }
    if (item.contract_mire_yn === 1) {
      contractList += contractList.length > 0 ? ", 미래회원" : "미래회원";
    }
    if (item.contract_online_yn === 1) {
      contractList += contractList.length > 0 ? ", 온라인회원" : "온라인회원";
    }

    return contractList;
  });

  const clickDownload = () => {
    downloadReasonModal.visible(true);
  };

  const clickDownloadList = () => {
    downloadListModal.visible(true);
  };

  const onChangeTablePage = useCallback((page) => {
    setPage(page);
  });

  return (
    <>
      <DashboardNavbar title="회원 목록" />
      <LACard>
        <LAProperty name="회원 상태">
          <LACodeRadioGroup
            codeType="MEMBER_STATE_TYPE"
            showAll={true}
            value={memberStatus.state}
            onChange={memberStatus.handleChange}
          />
        </LAProperty>

        <LAProperty name="북클럽 회원">
          <LARadioGroup
            props={[
              { text: "전체", value: 0 },
              { text: "미래회원", value: 1 },
              { text: "교문회원", value: 2 },
              { text: "온라인회원", value: 4 },
              { text: "비회원", value: 99 },
            ]}
            value={subscriptionType.state}
            onChange={subscriptionType.handleChange}
          />
        </LAProperty>

        <LAProperty name="마케팅 동의">
          <LARadioGroup
            props={[
              { text: "전체", value: 0 },
              { text: "SMS", value: 1 },
              { text: "E-Mail", value: 2 },
              { text: "미동의", value: 3 },
            ]}
            value={marketingYn.state}
            onChange={marketingYn.handleChange}
          />
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LACodeComboBox
              wPercent={15}
              codeType="MEMBER_QUERY_PERIOD_TYPE"
              value={periodType.state}
              onChange={periodType.handleChange}
            />
            <LATabSelector
              wPercent={45}
              items={periodItems}
              layouts={{ pl: 2 }}
              value={periodTab.state}
              onChange={periodTab.handleChange}
            />
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2, mr: 2 }}
              value={startDate.state}
              onChange={startDate.handleChange}
            />
            ~
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2 }}
              value={endDate.state}
              onChange={endDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: "통합검색" },
                { key: 1, value: "이름" },
                { key: 2, value: "피추천코드" },
                { key: 3, value: "추천인" },
              ]}
              value={searchType.state}
              onChange={searchType.handleChange}
            />
            <LAInput
              wPercent={85}
              layouts={{ pl: { sm: 1 } }}
              value={searchInput.state}
              onChange={searchInput.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 2 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={"outlined"}
            onClick={onInitializeClick}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>

      <LACard layouts={{ pt: { sm: 1 } }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <LAComboBox
            wPercent={10}
            items={[
              { key: "2022", value: "2022년" },
              { key: "2023", value: "2023년" },
              { key: "2024", value: "2024년" },
              { key: "2025", value: "2025년" },
            ]}
            value={yearTypeDwn.state}
            onChange={yearTypeDwn.handleChange}
          />
          <LAComboBox
            layouts={{ pl: { sm: 1 } }}
            wPercent={10}
            items={[
              { key: "01", value: "1월" },
              { key: "02", value: "2월" },
              { key: "03", value: "3월" },
              { key: "04", value: "4월" },
              { key: "05", value: "5월" },
              { key: "06", value: "6월" },
              { key: "07", value: "7월" },
              { key: "08", value: "8월" },
              { key: "09", value: "9월" },
              { key: "10", value: "10월" },
              { key: "11", value: "11월" },
              { key: "12", value: "12월" },
            ]}
            value={monthTypeDwn.state}
            onChange={monthTypeDwn.handleChange}
          />
          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={clickDownload}
          >
            회원 내역 다운로드
          </LAButton>
          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={clickDownloadList}
          >
            다운로드 이력
          </LAButton>
        </div>

        <LAGridContainer>
          <LADataTable2
            columns={columns}
            loadUrl={queries.Member.getList}
            params={searchParams}
            dataToRow={dataToRow}
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
          />
        </LAGridContainer>
      </LACard>

      <AccountDetailModal
        memberSeq={selectedMemberSeq}
        show={accountDetailModal.state}
        handleClose={accountDetailModal.handleClose}
      />
      <DownloadReasonModal
        show={downloadReasonModal.state}
        handleClose={downloadReasonModal.handleClose}
        downKey={"memberList"}
        urlQueries={{
          start_dt: `${yearTypeDwn.state}-${monthTypeDwn.state}-01`,
          end_dt: `${yearTypeDwn.state}-${monthTypeDwn.state}-${getDaysInMonth(
            yearTypeDwn.state,
            monthTypeDwn.state
          )}`,
        }}
      />
      <DownloadListModal
        show={downloadListModal.state}
        handleClose={downloadListModal.handleClose}
        type={"MEMBER"}
      />
    </>
  );
}

export default React.memo(AccountList);
