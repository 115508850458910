import React, { useState, useMemo, useCallback } from "react";
// import { useParams } from 'react-router-dom';
// import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useNavigate } from "react-router-dom";
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import { getHiddenNameText, getDateText, getDateTimeText } from "common/utils";
import { useLoginContext } from "common/context/MemberContext";
import { AUTH_TYPES } from "common/constants";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import usePasswordReset from "common/hooks/usePasswordReset";
import {
  LACard,
  LACardTitle,
  LAComboBox,
  LAGridContainer,
  LAProperty,
  LALabel,
  LACodeRadioGroup,
  LAButton,
  LAInput,
  useRadioGroup,
  useInput,
  useComboBox,
} from "modules/Widgets";
import useCombo from "common/hooks/useCombo";

const AuthTypeInfo = [
  {
    text: "마스터",
    value: AUTH_TYPES.master,
  },
  {
    text: "웅진 관리자",
    value: AUTH_TYPES.woongjin_admin,
  },
  {
    text: "CS 관리자",
    value: AUTH_TYPES.cs_admin,
  },
  {
    text: "일반 관리자",
    value: AUTH_TYPES.general_admin,
  },
];

function AdminDetail({ memberSeq, onClickList }) {
  //   let { seq } = useParams();
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { action } = useLoginContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [adminInfo, setAdminInfo] = useState({});
  const passwordInput = useInput("");
  const passwordConfirmInput = useInput("");
  const authType = useRadioGroup(AUTH_TYPES.unknown);

  const [alliances, setAlliances] = useState([]);
  const passwordReset = usePasswordReset();

  const selectedAlliance = useComboBox(0);

  const { request: requestAlliances } = useCommonQuery({
    query: queries.Alliance.getAllianceCodes,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code == "0000") {
          setAlliances(data.result_data);
          return;
        }

        showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "업체 정보 조회에 실패하였습니다.");
      },
    },
  });

  const { request: requestAccountInfo } = useCommonQuery({
    query: queries.Account.accountInfo,
    params: {
      member_seq: memberSeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setAdminInfo(data.result_data);
          authType.update(data.result_data.auth_type);
          selectedAlliance.select(data.result_data.alliance_seq);
        } else {
          showAlert("알림", data.result_message, {
            confirmHandler: onClickList,
          });
        }
      },
      onError: (error) => {
        showAlert("알림", "관리자 정보 조회에 실패했습니다.", {
          confirmHandler: onClickList,
        });
      },
    },
  });

  const { request: requestUpdateAccount } = useCommonMutation({
    query: queries.Account.updateAccount,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "관리자 정보가 변경되었습니다.");
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "관리자 정보 변경에 실패했습니다.");
      },
    },
  });

  const getAllianceList = useMemo(() => {
    var list = [];
    if (alliances && alliances.length > 0) {
      list = alliances.map((a) => {
        return { key: a.alliance_seq, value: a.alliance_name };
      });
    }
    return list;
  }, [alliances]);

  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  console.log("authMemberType =>", authMemberType);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  const onBackClick = () => {
    onClickList();
  };

  const onSaveClick = useCallback(() => {
    showConfirm("알림", "관리자 정보를 저장하시겠습니까?", {
      confirmHandler: () => {
        requestUpdateAccount({
          member_seq: memberSeq,
          auth_type: authType.state,
          alliance_seq:
            adminInfo.alliance_seq == selectedAlliance.state
              ? undefined
              : selectedAlliance.state,
        });
      },
    });
  }, [
    adminInfo,
    authType.state,
    passwordConfirmInput.state,
    passwordInput.state,
    selectedAlliance.state,
    requestUpdateAccount,
    memberSeq,
    showAlert,
  ]);

  return (
    <>
      {/* Start Info Area */}
      <LACard>
        <LACardTitle title="관리자 기본 정보" content="" />
        <LAProperty name="이름">
          <LALabel wPercent={40}>
            {adminInfo.member_name ? adminInfo.member_name : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="아이디">
          <LALabel
            wPercent={40}
            clickable="true"
            content={`${adminInfo.login_id}`}
          >
            {adminInfo.login_id ? getHiddenNameText(adminInfo.login_id, 4) : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="이메일">
          <LALabel wPercent={40}>{adminInfo?.email}</LALabel>
        </LAProperty>
        <LAProperty name="비밀번호 변경">
          <LAButton
            onClick={() => {
              passwordReset(adminInfo?.login_id, memberSeq);
            }}
            wPercent={15}
          >
            비밀번호 초기화
          </LAButton>
        </LAProperty>

        <LAProperty name="권한">
          {authAdminAuthType === AUTH_TYPES.master ? (
            <LACodeRadioGroup
              codeType="ADMIN_TYPE"
              showAll={false}
              value={authType.state}
              onChange={authType.handleChange}
            />
          ) : authType.state == 5 ? (
            <LALabel wPercent={10}>업체 관리자</LALabel>
          ) : (
            <LALabel wPercent={10}>CS 관리자</LALabel>
          )}
        </LAProperty>
        {authType.state == 5 ? (
          authAdminAuthType === AUTH_TYPES.master ||
          authAdminAuthType === AUTH_TYPES.woongjin_admin ? (
            <LAProperty name="업체명">
              <LAGridContainer>
                <LALabel wPercent={10}>업체 선택</LALabel>
                <LAComboBox
                  wPercent={30}
                  items={getAllianceList}
                  value={selectedAlliance.state}
                  onChange={selectedAlliance.handleChange}
                />
              </LAGridContainer>
            </LAProperty>
          ) : (
            <LAProperty name="업체명">
              <LALabel wPercent={10}>{adminInfo.alliance_name}</LALabel>
            </LAProperty>
          )
        ) : (
          <></>
        )}

        {authAdminAuthType === AUTH_TYPES.master && (
          <LAProperty name="승인">
            <LAGridContainer>
              <LALabel wPercent={10}>
                {adminInfo.expired_yn ? "승인" : "미승인"}
              </LALabel>
              <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }}>
                승인 취소
              </LAButton>
            </LAGridContainer>
          </LAProperty>
        )}
        <LAProperty name="최근 방문일시">
          <LALabel wPercent={40}>
            {adminInfo.signin_dt ? getDateTimeText(adminInfo.signin_dt) : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="가입일">
          <LALabel wPercent={40}>
            {adminInfo.created_dt ? getDateText(adminInfo.created_dt) : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="로그인 IP">
          <LALabel wPercent={40}>
            {adminInfo.remote_address ? adminInfo.remote_address : ""}
          </LALabel>
        </LAProperty>
      </LACard>
      {/* End Info Area */}

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={35}></LALabel>
        {authMemberType == 1 && authAdminAuthType == 1 ? (
          <LAButton
            wPercent={15}
            layouts={{ mr: 1 }}
            variant={"outlined"}
            btnPaddingY={12}
            onClick={onBackClick}
          >
            목록
          </LAButton>
        ) : (
          <></>
        )}
        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onSaveClick}>
          저장
        </LAButton>
      </LAGridContainer>
    </>
  );
}

export default AdminDetail;
