import React, { useState, useCallback, useEffect, useMemo } from 'react';
// import LALoading from 'modules/Widgets/LALoading';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
// import { getTodayDateText, dateTimeToString } from 'common/utils';
import { useNavigate, useParams } from 'react-router';
import {
  getTextCell,
  getNormalButton,
  getPrimaryButton,
  LAModal,
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  // LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LADataTable,
  // useRadioGroup,
  // LATabSelector,
  // LADatePicker,
  // useTabSelector,
  // useDatePicker,
  useInput,
  useComboBox,
  LADivider,
} from 'modules/Widgets';

const columns = [
  { Header: '회차', accessor: 'class_time', width: '10%' },
  { Header: '회차 과정명', accessor: 'curriculum_name', width: '30%' },
  { Header: '정상가', accessor: 'price', width: '25%' },
  { Header: '할인가', accessor: 'discount_price', width: '20%' },  
];

function CurriculumModal({ show, handleClose, handleSelect }) {
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();
  const searchType = useComboBox(1);
  const searchInput = useInput('');

  const [rows, setRows] = useState([]);

  const onTeacherDetail = ((id) => {
    navigate(`/user/teacher/list/detail/${id}`)
  });

  const onTeacherSelect = ((id) => {
      handleSelect(id);
    });

  const dataToRow = (data, index) => {
    return {
      class_time: getTextCell(`${data.class_time}회차`),
      curriculum_name: getTextCell(data.curriculum_name),
      price: getTextCell(data.price),
      discount_price: getTextCell(data.discount_price),
    };
  };

  const { request: requestCurriculumns } = useCommonQuery({
    query: queries.Curriculum.curriculums,
    params: {
      query_type: searchType.state,
      query: searchInput.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setRows(
            data.result_data
              ? data.result_data.map((resultData, index) =>
                  dataToRow(resultData, index),
                )
              : [],
          );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '선생님 목록 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  return (
    <LAModal
      title="선생님 검색"
      width="70%"
      height="90%"
      show={show}
    >
      <LACard>
        <LAProperty name="직접 검색" wNameWidth={16}>
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={[
                { key: 1, value: '통합검색' },
                { key: 2, value: '아이디' },
                { key: 3, value: '이름' },
              ]}
              value={searchType.state}
              onChange={searchType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: 1 }}
              value={searchInput.state}
              onChange={searchInput.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LADivider pt={4} />
        {/* <LADataTable columns={columns} rows={rows} /> */}
        <LADataTable columns={columns} rows={rows} />
      </LACard>
      <LAGridContainer pt={2}>
          <LALabel wPercent={80} />
          <LAButton
            wPercent={20}
            layouts={{ pr: { sm: 0.5 } }}
            onClick={handleClose}
          >
            닫기
          </LAButton>
        </LAGridContainer>
    </LAModal>
  );
}

export default React.memo(CurriculumModal);
