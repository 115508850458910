import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/curriculums';

const CurriculumApi = (requestFunc) => {
  return {
    Curriculum: {
      curriculums: {
        key: 'curriculums',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },            
    },
  };
};

export default CurriculumApi;
