import React, {forwardRef} from 'react';
import LAGridItem from './LAGridItem';
import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import CalendarToday from "@mui/icons-material/CalendarToday";

import moment from 'moment'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { ko } from 'date-fns/esm/locale'
import { position } from 'stylis';

function LADatePicker({
  wPercent = 100,
  layouts = {},
  value,
  defaultValue,
  minDate='',
  maxDate='',
  onChange,
}) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField 
      fullWidth
      size="small"
      onClick={onClick} 
      ref={ref}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarToday />
          </InputAdornment>
          )
      }}/>
  ));

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <DatePicker 
        dateFormat="yyyy-MM-dd"
        className="react-datepicker"
        locale={ko}
        value={value}
        selected={value?moment(value).toDate():new Date()}
        onChange={date => onChange(moment(date).format('YYYY-MM-DD'))}
        minDate={minDate?moment(minDate).toDate():''}
        maxDate={maxDate?moment(maxDate).toDate():''}
        customInput={<CustomInput />}
        />

      {/* <TextField
        fullWidth
        id="date"
        type="date"
        size="small"
        locale="ko"
        value={value}
        // defaultValue={defaultValue}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange}
      /> */}

    </LAGridItem>
  );
}

export default React.memo(LADatePicker);
