import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { getTodayDateText } from 'common/utils';
import CategoryComboView from 'pages/Class/components/CategoryComboView';
import moment from 'moment';
import { periodItems } from 'common/constants';
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAComboBox,
  LACodeComboBox,
  LACodeRadioGroup,
  LAInput,
  LADatePicker,
  LATabSelector,
  LADataTable,
  useRadioGroup,
  useComboBox,
  useInput,
  useDatePicker,
  useTabSelector,
  getTextCell,
  getCheckCell,
  LARadioGroup,
  getNormalButton,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

function getAgeItems() {
  const result = [];
  for (let i = 0; i < 20; i++) {
    result.push({ key: i + 1, value: `${i + 1}세` });
  }
  return result;
}
const targetAgeItems = getAgeItems();
// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

function ClassSearchModal({
  show,
  handleClose,
  handleSelect,
  classTypeState,
  categorySeq,
}) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const todayDateText = getTodayDateText('-');
  const [currPage, setCurrPage] = useState(0);
  const [classes, setClasses] = useState([]);
  const [lastVisitTablePage, setLastVisitTablePage] = useState(0);

  const categoryValue = useComboBox(categorySeq);
  const classType = useRadioGroup(classTypeState || -1);
  // const classStatus = useRadioGroup(0);
  const ageType = useRadioGroup(-1);
  const minTargetAge = useComboBox(1);
  const maxTargetAge = useComboBox(1);
  const activateType = useRadioGroup(-1);
  const searchDateType = useComboBox(1);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const searchTargetType = useComboBox(1);
  const searchText = useInput('');

  const { result: resultData, request: requestClasses } = useCommonQuery({
    query: queries.Class.getList,
    params: {
      category_seq: categoryValue ? categoryValue.state : undefined,
      class_type: classType.state < 0 ? undefined : classType.state,
      all_age_yn: ageType.state,
      min_target_age: ageType.state === "0" ? minTargetAge.state : undefined,
      max_target_age: ageType.state === "0" ? maxTargetAge.state : undefined,
      activate_yn: activateType.state < 0 ? undefined : activateType.state == 1,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      // confirmation_yn: classStatus.state,
      query_type: searchTargetType.state,
      query: searchText.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setClasses(data.result_data.data);
          setCurrPage(0);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '목록 조회에 실패하였습니다.');
      },
    },
    initEnabled: false,
  });

  const onClassSelect = (id) => {
    handleSelect(id);
  };

  const columns = useMemo(() => {
    return [
      { Header: 'No', accessor: 'no', width: '4%', align: 'center' },
      {
        Header: '클래스 번호',
        accessor: 'class_seq',
        width: '10%',
        align: 'center',
      },
      {
        Header: '카테고리',
        accessor: 'category',
        width: '10%',
        align: 'center',
      },
      {
        Header: '유형',
        accessor: 'class_type',
        width: '10%',
        align: 'center',
      },
      {
        Header: '주체',
        accessor: 'brand_type',
        width: '5%',
        align: 'center',
      },
      {
        Header: '클래스명',
        accessor: 'class_name',
        width: '15%',
        align: 'left',
      },
      {
        Header: '회차',
        accessor: 'curriculum_times',
        width: '5%',
        align: 'center',
      },
      {
        Header: '연령',
        accessor: 'recommended_age',
        width: '7%',
        align: 'center',
      },
      { Header: '선생님', accessor: 'teacher', width: '8%', align: 'center' },
      {
        Header: '수업시작일',
        accessor: 'start_dt',
        width: '12%',
        align: 'center',
      },
      {
        Header: '시작시간',
        accessor: 'start_time',
        width: '12%',
        align: 'center',
      },
      {
        Header: '종료시간',
        accessor: 'end_time',
        width: '12%',
        align: 'center',
      },
      {
        Header: '수업종료일',
        accessor: 'end_dt',
        width: '12%',
        align: 'center',
      },
      {
        Header: '상태',
        accessor: 'status',
        width: '7%',
        align: 'center',
      },
      {
        Header: '노출',
        accessor: 'activate',
        width: '7%',
        align: 'center',
      },
      { Header: '선택', accessor: 'select', width: '8%' },
    ];
  }, []);

  const rows = useMemo(() => {
    return !!classes && classes?.map((item, index) => {
      return {
        no: getTextCell(`${index + 1}`),
        class_seq: getTextCell(`${item.class_seq}`),
        category: getTextCell(`${item.category_name}`),
        class_type: getTextCell(`${item.class_type_name}`),
        brand_type: getTextCell(item.brand_type_name || ''),
        class_name: getTextCell(`${item.class_name}`),
        curriculum_times: getTextCell(`${item.times}`),
        recommended_age: getTextCell(
            item.all_age_yn === 1 ?
            `전연령`
            : item.all_age_yn === 2 ?
                `학부모`
            : `${item.min_target_age}~${item.max_target_age}`,
        ),
        teacher: getTextCell(`${item.teacher_name ? item.teacher_name : ''}`),
        start_dt: getTextCell(
          item.start_dt
            ? `${moment(item.start_dt).format('YYYY-MM-DD (dd)')}`
            : '',
        ),
        start_time: getTextCell(item.start_time),
        end_time: getTextCell(item.end_time),
        end_dt: getTextCell(
          item.end_dt ? `${moment(item.end_dt).format('YYYY-MM-DD (dd)')}` : '',
        ),
        status: getTextCell(`${item.status ? '확정' : '미확정'}`),
        activate: getTextCell(`${item.activate_yn ? '노출' : '미노출'}`),
        select: getNormalButton('선택', item, onClassSelect),
      };
    });
  }, [classes]);

  useEffect(() => {
  }, []);

  const onCategoryChanged = useCallback((item) => {
    // setCategory(item);
  }, []);

  const onInitClick = useCallback(() => {
    // setCategory(0);
    classType.reset();
    // classStatus.reset();
    ageType.reset();
    minTargetAge.reset();
    maxTargetAge.reset();
    activateType.reset();
    searchDateType.reset();
    searchPeriodType.reset();
    // searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    classType.reset,
    // classStatus.reset,
    ageType.reset,
    minTargetAge.reset,
    maxTargetAge.reset,
    activateType.reset,
    searchDateType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  const onSearchClick = useCallback(() => {
    requestClasses();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  }, []);

  const onChangePage = useCallback((page) => {
    setCurrPage(page);
  }, []);

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    // searchEndDate.reset();
    searchEndDate.reset(searchPeriodType.state);
    // onSearchClick();
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) requestClasses();
  }, [searchStartDate.state]);

  return (
    <LAModal
      title="클래스 검색"
      width="90%"
      height="90%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LACardTitle title="클래스 정보" />

        <LAProperty name="카테고리">
          <LAGridContainer>
            <CategoryComboView
              readOnly
              value={categoryValue.state}
              onChange={categoryValue.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="클래스 유형">
          <LACodeRadioGroup
            readOnly
            codeType="CLASS_TYPE"
            showAll={true}
            value={classType.state}
            onChange={classType.handleChange}
          />
        </LAProperty>

        {/* <LAProperty name="클래스 상태">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '확정',
                value: 2,
              },
              {
                text: '미확정',
                value: 1,
              },
              {
                text: '폐강',
                value: 3,
              },
            ]}
            value={classStatus.state}
            onChange={classStatus.handleChange}
          />
        </LAProperty> */}

        <LAProperty name="대상 연령">
          <LAGridContainer>
            <LACodeRadioGroup
              codeType="TARGET_AGE_QUERY_TYPE"
              showAll={true}
              value={ageType.state}
              onChange={ageType.handleChange}
            />
            {ageType.state === "0" && (
              <LAGridContainer>
                <LAComboBox
                  wPercent={20}
                  // value={!!minTargetAge && minTargetAge.state}
                  items={targetAgeItems}
                  layouts={{ pr: { sm: 1 } }}
                  value={minTargetAge.state}
                  onChange={minTargetAge.handleChange}
                  // onChange={onMinAgeChanged}
                />
                <LALabel wPercent={5} layouts={{ pr: { sm: 1 } }}>
                  ~
                </LALabel>
                <LAComboBox
                  wPercent={20}
                  items={targetAgeItems}
                  value={maxTargetAge.state}
                  onChange={maxTargetAge.handleChange}
                  // value={!!maxTargetAge && maxTargetAge.state}
                  // onChange={onMaxAgeChanged}
                />
              </LAGridContainer>
            )}
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="노출 여부">
          <LACodeRadioGroup
            codeType="EXPOSURE_TYPE"
            showAll={true}
            value={activateType.state}
            onChange={activateType.handleChange}
            // onChange={onExposureChanged}
          />
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LACodeComboBox
              wPercent={12}
              codeType="QUERY_PERIOD_TYPE"
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
              // code={queryPeriodType}
              // onChange={onQueryPeriodTypeChanged}
            />
            <LATabSelector
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={20}
              layouts={{ pl: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={2}>~</LALabel>
            <LADatePicker
              wPercent={20}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LACodeComboBox
              wPercent={12}
              codeType="CLASS_QUERY_TYPE"
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              placeholder="검색어 입력"
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer pt={2}>
          <LALabel wPercent="35" />
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LALabel wPercent="35" />
        </LAGridContainer>

        <LADataTable
          columns={columns}
          rows={rows}
          currPage={currPage}
          onChangePage={onChangePage}
        />
      </LACard>
    </LAModal>
  );
}

export default ClassSearchModal;
