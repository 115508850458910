import React, {useMemo} from 'react';
import { LARadioGroup } from 'modules/Widgets';

/**
 * [LAAltRadioGroup]
 * true/false(기본), '있음'/'없음'으로 구성되는 라디오 선택 옵션에 대한 위젯
 */

function LAAltRadioGroup({
  showAll = false,
  allLabel = '전체', 
  allValue = '', 
  positiveLabel = '있음',
  positiveValue = 'Y',
  negativeLabel = '없음', 
  negativeValue = 'N',
  orderReverse = false,
  wPercent=100,
  value,
  onChange}){

  const comboOptions = useMemo(() =>{
    let optionList = [],
      positiveOption = { text: positiveLabel, value: positiveValue },
      negativeOption = { text: negativeLabel, value: negativeValue };

    optionList.push(positiveOption); // positive 옵션 세팅  
    orderReverse? optionList.unshift(negativeOption) : optionList.push(negativeOption); // negative 옵션 세팅  

    // 전체 항목이 있을 경우만 세팅
    if (showAll) {
      optionList.unshift({ text: allLabel, value: allValue })
    }

    return optionList;
  }, [showAll]);

  return (
    <>
      <LARadioGroup
          wPercent = { wPercent }
          props = { comboOptions }
          value = { value }
          onChange = { onChange }
        />
    </>
  )
}

export default React.memo(LAAltRadioGroup);