import React, { useMemo } from 'react';
import { useCodeContext } from 'common/context/CodeContext';
import { LAMultiCheckbox } from 'modules/Widgets';

function LACodeMultiCheckbox({
  wPercent = 100,
  layouts = {},
  props = {},
  codeType,
  showAll = false,
  isSelected,
  onChange,
}) {
  const { action } = useCodeContext();
  const codes = useMemo(() => {
    let data = action.getCheckboxCodeList(codeType, showAll);
    return data;
  }, [action, codeType, showAll]);

  return (
    <LAMultiCheckbox
      wPercent={wPercent}
      layouts={layouts}
      props={props}
      items={codes}
      isSelected={isSelected}
      onChange={onChange}
    />
  );
}

export default React.memo(LACodeMultiCheckbox);
