import React, { useState, useCallback, useEffect, useMemo  } from 'react';
import LAGridItem from './LAGridItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import ccss from 'assets/css/LAStyle.scss';
import { item } from 'modules/Sidenav/styles/sidenavItem';

const Placeholder = styled('div')({
  color: '#aaaaaa',
});

function LAMultiSelect({
  placeholder = '선택',
  valueSetter,
  items,
  width = '100%',
  display, // block, inline-block
  readOnly = false,
  disabled = false,
  allOption = true
}) {
  const [checkItems, setCheckItems] = useState([]);

  const switchAllItem = useCallback(() => {
    if (checkItems.length == 0 || checkItems.length != items.length ) {
      setCheckItems([...items]);
      return;
    }

    setCheckItems([]);
  }, [checkItems, items]);

  const onItemClick = useCallback((item) => {
    let pos = checkItems.findIndex((sss) => sss.key == item.key);
    
    if (pos >= 0) {
      setCheckItems(checkItems.filter((item, $index) => {
        return $index != pos;
      }));
      return;
    }

    setCheckItems([...checkItems, item]);
  }, [checkItems]);

  const render = useCallback(() => {
    if ( checkItems.length == 0 ) {
      return <Placeholder>{placeholder}</Placeholder>;
    }

    let labelList = checkItems.map((item)=> item.value);
    return <div class="text">{labelList.join(', ')}</div>;  
  }, [checkItems]);

  useEffect(() => {
    valueSetter([...checkItems]);
  }, [checkItems]);

  return (
    <div className="LaMultiSelect" style={{width: width}}>
      <FormControl fullWidth size="small">
        <Select
          value={checkItems}
          multiple
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={ render }
          displayEmpty
          readOnly={ readOnly? 'readOnly' : null }
          disabled={ disabled? 'disabled' : null }
          sx={{ fontSize: 25 }}
        >
          { allOption && 
            <MenuItem
              onClick={() => switchAllItem()}
            >
              <Checkbox 
                checked={ items.length == checkItems.length }/>
              전체
            </MenuItem>
          }
          
          {!!items &&
            items.map((item) => (
              <MenuItem
                key={item.key}
                value={item}
                onClick={() => onItemClick(item)}
              >
                <Checkbox checked={checkItems.findIndex((checkItem) => checkItem.key == item.key) > -1} />
                {item.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default React.memo(LAMultiSelect);
