// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";
import React, { useMemo, useCallback } from 'react';

import { LACard, LACardTitle, LAProperty, LALabel } from 'modules/Widgets';
import { LAGridContainer, LAButton } from 'modules/Widgets';

function ProfileList({ profiles, onMemoClick }) {
  const renderItems = profiles.map((profile) => (
    <LACard layouts={{ pt: 1 }}>
      <LACardTitle title="프로필" />
      <LAProperty name="학습자 정보">
        <LAGridContainer>
          <LALabel wPercent={10}>{profile.user_name}</LALabel>
          <LAButton
            wPercent={10}
            layouts={{ pr: { sm: 1 } }}
            variant={profile.memo_count > 0 ? 'gradient' : 'outlined'}
            onClick={() => onMemoClick(profile)}
          >
            회원메모
          </LAButton>
        </LAGridContainer>
      </LAProperty>
      <LAProperty name="생년월일">
        <LALabel wPercent={100}>{profile.birth}</LALabel>
      </LAProperty>
      <LAProperty name="성별">
        <LALabel wPercent={100}>{profile.gender_type_name}</LALabel>
      </LAProperty>
      {/* <LAProperty name="가입자와의 관계">
        <LALabel wPercent={100}>{profile.relation_type_name}</LALabel>
      </LAProperty> */}
      <LAProperty name="특이 사항">
        <LALabel wPercent={100}>{profile.remark}</LALabel>
      </LAProperty>
    </LACard>
  ));

  return <>{renderItems}</>;
}

// Typechecking props for the ProfileList
// ProfileList.propTypes = {
//   title: PropTypes.string.isRequired,
//   profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

export default React.memo(ProfileList);
