import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';

// import FeeadbackCard from 'modules/Cards/FeedbackCard'
import ClassFeedbackApplicationList from './ClassFeedbackApplicationList';
import ScheduleTimeTableList from './ScheduleTimeTableList';
import FeedbackContentsModal from './FeedbackContentsModal';
import ClassBatchFeedbackModal from './ClassBatchFeedbackModal';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAWarningLabel,
  useModal,
} from 'modules/Widgets';
import moment from 'moment';

function ClassFeedbackDetailContents({ classroomSeq, onClickList }) {
  const { isLogin, loginState, action } = useLoginContext();
  const navigate = useNavigate();

  const { showAlert, showConfirm } = usePopAlert();

  console.log('classroomSeq : ', classroomSeq);
  const { queries } = useApiContext();
  //   const { feedbackSeq } = useParams();
  const [data, setData] = useState(null);

  const [selectedClassmateSeq, setSelectedClassmateSeq] = useState(0);
  const feedbackContentModal = useModal(false);
  const batchFeedbackModal = useModal(false);

  const getTime = useCallback((schedule) => {
    if (schedule) {
      if (schedule.timetables && schedule.timetables.length > 0)
        return moment(
          schedule.start_dt + ' ' + schedule.timetables[0].schedule_time,
        ).format('A hh:mm');
    } else {
      return '00:00';
    }
  });

  const getDate = useCallback((schedule) => {
    if (schedule) {
      return moment(schedule.start_dt).format('YYYY-MM-DD (dd)');
    }
    return '0000-00-00';
  });

  const { request: requestScheduleDetail } = useCommonQuery({
    query: queries.Classroom.getDetail,
    params: classroomSeq,
    callbacks: {
      onSuccess: (data) => {
        console.log('ClassScheduleDetail.requestScheduleDetail =>', data);
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setData(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (err) => {
        showAlert('알림', '클래스 일정 조회에 실패하였습니다.');
      },
    },
  });

  useEffect(() => {
    requestScheduleDetail();
  }, [classroomSeq]);

  const onFeedbackClick = useCallback((classmateSeq) => {
    setSelectedClassmateSeq(classmateSeq);
    feedbackContentModal.visible(true);
  });

  const onFeedbackContntsClose = useCallback(() => {
    feedbackContentModal.visible(false);
  });

  const onBatchFeedbackClick = useCallback(() => {
    batchFeedbackModal.visible(true);
  });

  const canFeedbackBatch = useMemo(() => {
    if (data) {
      return (
        data.classroom_seq != undefined &&
        (data.timetables.some((t) => t.done_yn == 1 || t.abnormal_yn == 1) ||
          data.applications.some(
            (a) => a.confirmation_yn == 1 && a.order_status == 10,
          ))
      );
    }
    return false;
  });

  return (
    <>
      {/* Start Info Area */}
      <LACard>
        <LACardTitle title="클래스 정보" />
        <LAProperty name="카테고리">
          <LALabel wPercent={100}>{!!data && data.class.category_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LALabel wPercent={100}>
            {!!data && data.class.class_type_name}
          </LALabel>
        </LAProperty>
        <LAProperty name="클래스 명">
          <LALabel wPercent={100}>{!!data && data.class.class_name}</LALabel>
        </LAProperty>
        <LAProperty name="선생님 명">
          <LALabel wPercent={100}>{!!data && data.class.teacher_name}</LALabel>
        </LAProperty>
        {/* <LAProperty name="클래스 일정">
          <LALabel wPercent={40}>{getScheduleDate}</LALabel>
        </LAProperty> */}
      </LACard>
      {/* End Info Area */}

      <LACard next>
        <LACardTitle title="클래스 일정 상세 정보" />

        <LAProperty name="일정 생성일">
          <LALabel wPercent={40}>
            {data && data.created_dt
              ? moment(data.created_dt).format('YYYY-MM-DD (dd) A hh:mm')
              : ''}
          </LALabel>
        </LAProperty>

        <LAProperty name="일정 시작일">
          <LALabel wPercent={40}>{getDate(data)}</LALabel>
        </LAProperty>

        <LAProperty name="수업 시간">
          <LALabel wPercent={40}>{getTime(data)}</LALabel>
        </LAProperty>
        <LAProperty name="상세 수업 일정">
          <ScheduleTimeTableList timetables={!!data && data.timetables} />
          <LAWarningLabel>
            이미 지난 클래스 변경 시 ’보강’, 아직 지나지 않은 클래스 변경 시
            ‘변경’으로 구분됩니다. 프론트는 보강, 변경 구분없이 보강으로
            표시됩니다.
          </LAWarningLabel>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="일정 신청자 목록" />
        <LAGridContainer layouts={{ mt: { sm: 1 } }}>
          <ClassFeedbackApplicationList
            applications={!!data && data.applications}
            onFeedbackClick={onFeedbackClick}
          />
        </LAGridContainer>
        <LAWarningLabel>
          피드백은 수업확정된 후 작성할 수 있습니다.
        </LAWarningLabel>

        {canFeedbackBatch ? (
          <LAGridContainer layouts={{ mt: { sm: 1 } }}>
            <LALabel wPercent={84} />
            <LAButton wPercent={15} onClick={onBatchFeedbackClick}>
              일괄 피드백
            </LAButton>
          </LAGridContainer>
        ) : (
          ''
        )}
      </LACard>

      <LAGridContainer layouts={{ pt: { sm: 2 } }}>
        <LALabel wPercent={40}></LALabel>
        <LAButton
          wPercent={20}
          layouts={{ pr: { sm: 1 } }}
          onClick={onClickList}
        >
          목록
        </LAButton>
        <LALabel wPercent={40}></LALabel>
      </LAGridContainer>

      <FeedbackContentsModal
        classmateSeq={selectedClassmateSeq}
        classroomSeq={classroomSeq}
        show={feedbackContentModal.state}
        handleClose={onFeedbackContntsClose}
        handleSelect={feedbackContentModal}
      />

      <ClassBatchFeedbackModal
        applications={!!data && data.applications}
        classroomSeq={classroomSeq}
        show={batchFeedbackModal.state}
        handleClose={batchFeedbackModal.handleClose}
      />
    </>
  );
}

export default ClassFeedbackDetailContents;
