

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  // const { href, name } = company;
  const { size } = typography;

  // const renderLinks = () =>
  //   links.map((link) => (
  //     <MDBox key={link.name} component="li" px={2} lineHeight={1}>
  //       <Link href={link.href} target="_blank">
  //         <MDTypography variant="button" fontWeight="regular" color="text">
  //           {link.name}
  //         </MDTypography>
  //       </Link>
  //     </MDBox>
  //   ));

  return (
    <MDBox
      pt={2}
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="right"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        COPYRIGHT &copy; WOONGJIN LIVEALL {new Date().getFullYear()}. ALL RIGHTS RESERVED
      </MDBox>
    </MDBox>
  );
}

// // Setting default values for the props of Footer
// Notice.defaultProps = {
//   company: { href: "https://www.creative-tim.com/", name: "Creative Tim" },
//   links: [
//     { href: "https://www.creative-tim.com/", name: "Creative Tim" },
//     { href: "https://www.creative-tim.com/presentation", name: "About Us" },
//     { href: "https://www.creative-tim.com/blog", name: "Blog" },
//     { href: "https://www.creative-tim.com/license", name: "License" },
//   ],
// };

// // Typechecking props for the Footer
// Notice.propTypes = {
//   company: PropTypes.objectOf(PropTypes.string),
//   links: PropTypes.arrayOf(PropTypes.object),
// };

export default Footer;
