import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/settlement';

const CalculateApi = (requestFunc) => {
    return {
        Calculate: {
            getSettlement: {
                key: 'getSettlement',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/statement/${params.yyyymm}`, params),
            },
            getCommissionDetail: {
                key: 'getCommissionDetail',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/commission/${params.yyyymm}/${params.memberSeq}`, params),
            },
            getCommissionCalculate: {
                key: 'getCommissionCalculate',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/commissionCalc/${params.yyyymm}/${params.memberSeq}`, params),
            },
            getCommissionList: {
                key: 'getCommissionList',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/commission`, params),
            },
            saveCommission: {
                key: 'saveCommission',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/commission/${params.yyyymm}/${params.memberSeq}`, params),
            },
            commissionApprove: {
                key: 'commissionApprove',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/commissionApprove/${params.yyyymm}/${params.memberSeq}`, params),
            },
            commissionApproveCancel: {
                key: 'commissionApproveCancel',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/commissionApproveCancle/${params.yyyymm}/${params.memberSeq}`, params),
            },  
            getCvsFileForClass: {
                key: 'getCvsFileForClass',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/csv/classdetail/${params.yyyymm}/${params.memberSeq}`, params),
            },
            getSapCostList: {
                key: 'getSapCostList',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/commissionApprovedCost`, params),
            },
            transferCost: {
                key: 'transferCost',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/commissionApprovedCost/${params.cost_seq}/transfer`, params),
            },
            cancelCost: {
                key: 'cancelCost',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/commissionApprovedCost/${params.cost_seq}/cancel`, params),
            }               
        }
    };
};

export default CalculateApi;