import React, { useState, useCallback, useRef, useEffect } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { getTodayDateText } from 'common/utils';
import MDBox from 'components/MDBox';
import EmptyImage from 'assets/images/empty.png';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LAWarningLabel,
  LAFileAttachment,
  useInput,
  useFileAttachment,
  LARadioGroup,
  useRadioGroup,
  LATextEditor,
  useTextEditor,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';

function NoticeRegistModal({ show, handleClose, footerSeq }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [modified, setModified] = useState(false);
  const [footerInfo, setFooterInfo] = useState(undefined);
  const [modalTitle, setModalTitle] = useState('공지사항 등록');
  const boardTitle = useInput('', 150);
  const getContentsEditorValue = useRef();
  const getContentsMoEditorValue = useRef();
  const visibleType = useRadioGroup(0);
  const contentPc = useTextEditor('');
  const contentMobile = useTextEditor('');

  const updateInputs = useCallback((data) => {
    boardTitle.update(data.title);
    visibleType.update(data.exposure_yn);
  }, []);

  const { request: requestBannerInfo } = useCommonQuery({
    query: queries.Notice.getNoticeInfo,
    params: {
      noticeSeq: footerSeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setFooterInfo(data.result_data);
          updateInputs(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '공지사항 상세 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestRegist } = useCommonMutation({
    query: queries.Notice.registNotice,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '등록이 정상적으로 완료 되었습니다.', {
            confirmHandler: () => {
              // boardTitle.reset();
              handleClose();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '공지사항 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestUpdate } = useCommonMutation({
    query: queries.Notice.updateNotice,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '저장이 정상적으로 완료 되었습니다.', {
            confirmHandler: () => {
              // boardTitle.reset();
              handleClose();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '공지사항 수정에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    boardTitle.reset();

    setModalTitle('공지사항 등록');

    if (!footerSeq) {
      return;
    }
    setModalTitle('공지사항 상세');
    requestBannerInfo();
  }, [footerSeq]);

  console.log(
    getContentsEditorValue.current ? getContentsEditorValue.current() : '',
  );
  console.log(contentPc.state);
  const onRegistClick = useCallback(() => {
    if (!boardTitle?.state || boardTitle.state.length === 0) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    }

    let contentHtml = '';
    if (
      !getContentsEditorValue.current ||
      getContentsEditorValue.current().length == 0
    ) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    } else {
      if (getContentsEditorValue.current().length * 0.7 > 5000000) {
        showAlert(
          '알림',
          'PC 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.',
        );
        return;
      }
      const content = getContentsEditorValue.current();
      if (content.search('<div') < 0) {
        contentHtml = `<div style="font-size: 16px; font-family: 노토산스;">${content}</div>`;
      } else {
        contentHtml = content;
      }

      const innerText = contentPc.getInnerText(contentHtml);
      if (innerText.length === 0) {
        showAlert('알림', '필수 입력 항목을 확인해 주세요.');
        return;
      }
    }

    let contentMoHtml = '';
    if (
      !getContentsMoEditorValue.current ||
      getContentsMoEditorValue.current().length == 0
    ) {
      showAlert('알림', '필수 입력 항목을 확인해 주세요.');
      return;
    } else {
      if (getContentsMoEditorValue.current().length * 0.7 > 5000000) {
        showAlert(
          '알림',
          'Mobile 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.',
        );
        return;
      }
      const content = getContentsMoEditorValue.current();
      if (content.search('<div') < 0) {
        contentMoHtml = `<div style="font-size: 16px; font-family: 노토산스;">${content}</div>`;
      } else {
        contentMoHtml = content;
      }

      const innerText = contentMobile.getInnerText(contentMoHtml);
      if (innerText.length === 0) {
        showAlert('알림', '필수 입력 항목을 확인해 주세요.');
        return;
      }
    }

    const params = {
      title: boardTitle.state,
      content_pc: contentHtml,
      content_mobile: contentMoHtml,
      exposure_yn: visibleType.state,
    };

    if (footerSeq) {
      requestUpdate({
        data: params,
        noticeSeq: footerSeq,
      });
    } else {
      requestRegist(params);
    }
  }, [
    footerSeq,
    boardTitle.state,
    getContentsEditorValue,
    getContentsMoEditorValue,
    visibleType.state,
    contentPc,
    contentMobile,
  ]);

  const onCloseClick = useCallback(
    (e) => {
      const pc = footerInfo?.content_pc || '';
      const mo = footerInfo?.content_mobile || '';
      const contentModified =
        pc !== getContentsEditorValue.current() ||
        mo !== getContentsMoEditorValue.current();

      if (modified || contentModified) {
        showConfirm('알림', '수정 사항이 저장되지 않습니다.\n닫으시겠습니까?', {
          confirmHandler: () => handleClose(e),
        });
      } else {
        handleClose(e);
      }
    },
    [
      modified,
      footerInfo?.content_pc,
      footerInfo?.content_mobile,
      getContentsEditorValue,
      getContentsMoEditorValue,
    ],
  );

  const onBackClick = useCallback(
    (e) => {
      const pc = footerInfo?.content_pc || '';
      const mo = footerInfo?.content_mobile || '';
      const contentModified =
        pc !== getContentsEditorValue.current() ||
        mo !== getContentsMoEditorValue.current();

      if (modified || contentModified) {
        showConfirm(
          '알림',
          '수정 사항이 저장되지 않습니다.\n목록으로 돌아 가시겠습니까?',
          {
            confirmHandler: () => handleClose(e),
          },
        );
      } else {
        handleClose(e);
      }
    },
    [
      modified,
      footerInfo?.content_pc,
      footerInfo?.content_mobile,
      getContentsEditorValue,
      getContentsMoEditorValue,
    ],
  );

  const onModified = useCallback((e, handler) => {
    if (handler) {
      handler(e);
      setModified(true);
    }
  }, []);

  return (
    <LAModal
      title={modalTitle}
      width="80%"
      height="90%"
      show={show}
      onClose={onCloseClick}
    >
      <LACard>
        <LACardTitle title="공지사항 정보" />

        <LAProperty name="제목" required>
          <LAInput
            placeholder="제목을 입력해 주세요."
            value={boardTitle.state}
            onChange={(e) => onModified(e, boardTitle.handleChange)}
          />
        </LAProperty>

        <LAProperty name="내용 (PC)" required>
          <LATextEditor
            name="contents_e"
            value={footerInfo?.content_pc || ''}
            getEditorValue={getContentsEditorValue}
            onChange={contentPc.handleChange}
          />
        </LAProperty>

        <LAProperty name="내용 (Mobile)" required>
          <LATextEditor
            name="contents_e_mo"
            value={footerInfo?.content_mobile || ''}
            getEditorValue={getContentsMoEditorValue}
            onChange={contentMobile.handleChange}
          />
        </LAProperty>

        <LAProperty name="노출 여부">
          <LARadioGroup
            props={[
              {
                text: '미노출',
                value: 0,
              },
              {
                text: '노출',
                value: 1,
              },
            ]}
            value={visibleType.state}
            onChange={(e) => onModified(e, visibleType.handleChange)}
          />
        </LAProperty>
      </LACard>

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ mr: 1 }}
          variant={'outlined'}
          btnPaddingY={12}
          onClick={onBackClick}
        >
          목록
        </LAButton>
        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onRegistClick}>
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>
    </LAModal>
  );
}

export default NoticeRegistModal;
