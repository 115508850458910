import React from 'react';
import MDButton from 'components/MDButton';
import LAGridItem from './LAGridItem';

function LAButton({
  wPercent = 100,
  layouts = {},
  variant = 'contained',
  color = 'info',
  disabled = false,
  props = {},
  onClick,
  children,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <MDButton
        variant={variant}
        size="small"
        color={color}
        onClick={onClick}
        fullWidth
        disabled={disabled}
        {...props}
      >
        {children}
      </MDButton>
    </LAGridItem>
  );
}

export default React.memo(LAButton);
