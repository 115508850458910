export const AUTH_TYPES = {
  unknown: 0,
  master: 1,
  woongjin_operator: 2,
  woongjin_manager: 3,
  cs_admin: 3,
  general_admin: 4,
};
export const periodItems = [
  { key: 0, value: '1개월' },
  { key: 1, value: '3개월' },
  { key: 2, value: '6개월' },
  { key: 3, value: '1년' },
  { key: 4, value: '전체' },
];

