import React, {useCallback} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';
import pxToRem from 'assets/theme/functions/pxToRem';
import LALabel from './LALabel';
import LAGridContainer from './LAGridContainer';

function LADeletableListItem({
  seq, 
  index, 
  item, 
  onDeleteItem }) {


  const onDeleteClick = (() => {
    onDeleteItem(index, seq);
  });

  return (
    <ListItemButton
      sx={{
        height: pxToRem(30),
        padding: 0,
      }}
      >
      <LAGridContainer vAlign="center" layouts={{pl:{sm:1}}}>
      <IconButton 
          sx={{
            fontSize: pxToRem(12),
            background: '#e8f0fe',
            opacity: 0.5,
            width: 22,
            height: 22,
            marginRight:1,
            alignSelf: "right", "& *": { verticalAlign: "middle" } 
          }}
          onClick={onDeleteClick}
          >
          <Clear sx={{ color: '#000000' }} />
        </IconButton>
        <LALabel wPercent={85}>{item}</LALabel>
      </LAGridContainer>
    </ListItemButton>
  );
}

export default React.memo(LADeletableListItem);
