import React, { useState, useCallback, useEffect, useMemo } from 'react';
// import LALoading from 'modules/Widgets/LALoading';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
// import { getTodayDateText, dateTimeToString } from 'common/utils';
import { useNavigate, useParams } from 'react-router';
import {
  getTextCell,
  getNormalButton,
  getPrimaryButton,
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  // LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LADataTable,
  // useRadioGroup,
  // LATabSelector,
  // LADatePicker,
  // useTabSelector,
  // useDatePicker,
  useInput,
  useComboBox,
  LADivider,
} from 'modules/Widgets';

const columns = [
  { Header: 'No', accessor: 'no', width: '10%' },
  { Header: '이메일', accessor: 'email', width: '30%' },
  { Header: '자재명', accessor: 'name', width: '15%' },
  { Header: '닉네임', accessor: 'nick_name', width: '15%' },
  { Header: '소속', accessor: 'alliance_name', width: '10%' },
  { Header: '소속코드', accessor: 'affiliation_code', width: '10%' },
  // { Header: '상세보기', accessor: 'detail', width: '20%' },
  { Header: '선택', accessor: 'select', width: '10%' },
];

function ClassMaterialCodeModal({ show, handleClose, handleSelect }) {
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();
  const searchType = useComboBox(1);
  const searchInput = useInput('');
  const [rows, setRows] = useState([]);

  const onTeacherDetail = (id) => {
    // navigate(`/mall/teacher/list/${id}`);
    navigate(`/user/teacher/list/detail/${id}`);
  };

  const onTeacherSelect = (id) => {
    handleSelect(id);
  };

  const dataToRow = (data, index) => {
    return {
      no: getTextCell(`${index + 1}`),
      email: getTextCell(`${data.email ? data.email : ''}`),
      name: getTextCell(`${data.member_name ? data.member_name : ''}`),
      nick_name: getTextCell(`${data.nick_name ? data.nick_name : ''}`),
      alliance_name: getTextCell(`${data.alliance_name ? data.alliance_name : ''}`),
      affiliation_code: getTextCell(`${data.affiliation_code ? data.affiliation_code : ''}`),
      // detail: getPrimaryButton(
      //   '상세보기',
      //   `${data.member_seq}`,
      //   onTeacherDetail,
      // ),
      select: getNormalButton('선택', data, onTeacherSelect),
    };
  };
  
  const { request: requestTeachers } = useCommonQuery({
    query: queries.Teachers.teachers,
    params: {
      query_type: searchType.state,
      query: searchInput.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log('data ::', data);
          setRows(
            data.result_data
              ? data.result_data.data.map((resultData, index) =>
                  dataToRow(resultData, index),
                )
              : [],
          );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '선생님 목록 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const onSearchClick = useCallback(() => {
    requestTeachers();
  }, [requestTeachers]);

  const onInitClick = useCallback(() => {
    searchType.reset();
    searchInput.reset();
  }, [searchType.reset, searchInput.reset]);

  return (
    <LAModal title="" width="70%" height="90%" show={show}>
      <LACard>
        <LACardTitle title="클래스 자재 검색" />
        <LAProperty name="직접 검색" wNameWidth={16}>
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={[
                { key: 1, value: '통합검색' },
                { key: 2, value: '이름' },
                { key: 3, value: '닉네임' },
                { key: 4, value: '소속' },
                { key: 5, value: '소속코드' },
              ]}
              value={searchType.state}
              onChange={searchType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: 1 }}
              value={searchInput.state}
              onChange={searchInput.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer pt={2}>
          <LALabel wPercent={30} />
          <LAButton
            wPercent={20}
            layouts={{ pr: { sm: 0.5 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={20}
            layouts={{ pl: { sm: 0.5 } }}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
        </LAGridContainer>

        <LADivider pt={4} />
        {/* <LADataTable columns={columns} rows={rows} /> */}
        <LADataTable columns={columns} rows={rows} />
      </LACard>
      <LAGridContainer pt={2}>
        <LALabel wPercent={80} />
        <LAButton
          wPercent={20}
          layouts={{ pr: { sm: 0.5 } }}
          onClick={handleClose}
        >
          닫기
        </LAButton>
      </LAGridContainer>
    </LAModal>
  );
}

export default React.memo(ClassMaterialCodeModal);
