import React, { useState, useCallback, useMemo, useEffect } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { getTodayDateText } from 'common/utils';
import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LASimpleTable,
  LATabSelector,
  getTextCell,
  getNormalButton,getPrimaryButton,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useDatePicker,
  useTabSelector,
} from 'modules/Widgets';
import EventRegistModal from './EventRegistModal';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';
import { periodItems } from 'common/constants';
import { API_BASE_URL } from "../../../api/AppConfig";
import { useLoginContext } from "../../../common/context/MemberContext";

function EventSearch() {
  const { loginState } = useLoginContext();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [selectedEventSeq, setSelectedEventSeq] = useState(undefined);

  const [exposuredEvents, setExposuredEvents] = useState([]);
  const [events, setEvents] = useState([]);
  
  const todayDateText = getTodayDateText('-');

  const searchDateType = useComboBox(0);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const visibleType = useRadioGroup(-1);
  const searchTargetType = useComboBox(0);
  const searchText = useInput('');
  const eventRegistModal = useModal(false);
  //엑셀 다운로드 날짜 조회
  const downloadStartDate = useDatePicker(todayDateText);
  const downloadEndDate = useDatePicker(todayDateText);


  const { result : resultData, request: requestEvents } = useCommonQuery({
    query: queries.Event.getExposuredEvents,
    params: {
      exposure_yn: undefined,
      // period_type:searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      query_type: searchTargetType.state,
      query: searchText.state,
      exposure_status: visibleType.state < 0 ? undefined : visibleType.state
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setEvents(data.result_data.searchEvents);
          setExposuredEvents(data.result_data.exposuredEvents);
          // setEvents(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '목록 조회에 실패하였습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestDelete } = useCommonMutation({
    query: queries.Event.deleteEvent,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '이벤트가 삭제되었습니다.');
          requestEvents();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '이벤트 삭제에 실패했습니다.');
      },
    },
  });

  const onEventUpdateClick = useCallback((eventSeq) => {
    setSelectedEventSeq(eventSeq);
    eventRegistModal.visible(true);
  }, []);

  const onEventDeleteClick = useCallback((eventSeq) => {
    showConfirm("알림", "정말 삭제하시겠습니까?", {
      confirmHandler: () => requestDelete({
        event_seq: eventSeq
      })
    })
  }, []);

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "7%", align: "center" },
      { Header: "제목", accessor: "title", width: "30%", align: "left" },
      { Header: "이벤트 기간", accessor: "period", width: "18%", align: "center" },    
      { Header: "조회수", accessor: "views", width: "7%", align: "center" },
      { Header: "노출", accessor: "visible", width: "7%", align: "center" },
      { Header: "등록자", accessor: "regist_user", width: "7%", align: "center" },
      { Header: "등록일", accessor: "regist_date", width: "10%", align: "center" },
      { Header: "수정", accessor: "update", width: "7%", align: "center" },
      { Header: "삭제", accessor: "delete", width: "7%", align: "center" },
    ]
  }, []);

  const rows = useMemo(() => {
    return events.map((item) => {
      let visibleText = "미노출";
      if (item.exposure_status == 1) {
        visibleText = "노출";
      } else if (item.exposure_status == 2) {
        visibleText = "예약노출";
      } 

      return {        
        no: getTextCell(`${item.event_seq}`),
        title: getTextCell(`${item.event_title}`),
        period: getTextCell(item.period_start_dt && item.period_end_dt ? 
          moment(item.period_start_dt).format("YYYY-MM-DD") + " ~ " + moment(item.period_end_dt).format("YYYY-MM-DD") : ""),
        views: getTextCell(item.view_count || 0),
        visible: getTextCell(visibleText),
        regist_user: getTextCell(item.constructor_name || ""),
        regist_date: getTextCell(item.created_dt ? moment(item.created_dt).format("YYYY-MM-DD") : ""),
        update: getPrimaryButton("수정", `${item.event_seq}`, onEventUpdateClick),
        delete: getNormalButton("삭제", `${item.event_seq}`, onEventDeleteClick),
      }
    })
  },[events])

  const onChangePage = useCallback((page) => {
  }, []);

  const onInitClick = useCallback(() => {
    searchDateType.reset();
    searchPeriodType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    searchDateType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  const onSearchClick = useCallback(() => {
    requestEvents();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  }, []);

  const onRegistClick = useCallback(() => {
    eventRegistModal.visible(true);
  });

  const onRegistered = useCallback(() => {
    eventRegistModal.handleClose();
    setSelectedEventSeq(undefined);
    onSearchClick();
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
    // onSearchClick();
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(()=> {
    if(searchStartDate.state != searchEndDate.state)    
      requestEvents();
  }, [searchStartDate.state, searchEndDate.state, visibleType.state]);



  const { request: requestUpdate } = useCommonMutation({
    query: queries.Event.updateEvent,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '이벤트가 미노출되었습니다.');
          requestEvents();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '이벤트 수정에 실패했습니다.');
      },
    },
  });

  const deleteExposuredEvent = useCallback((exposureNo, eventSeq) => {
    showConfirm('확인', `${eventSeq} 이벤트를 미노출하시겠습니까?\n실시간으로 프론트에 반영됩니다.`, {
      confirmHandler: () => {
        const formData = new FormData();
        formData.append('event_seq', eventSeq);
        formData.append('exposure_status', '0');
        const params = {
          event_seq: eventSeq,
          data: formData
        }
  
        requestUpdate(params);
      }

    });
  }, [exposuredEvents]);

  const getExposuredRows = useCallback(() => {
    let rows = [];

    if(exposuredEvents && exposuredEvents.length > 0) {
      exposuredEvents.forEach((event, index) => {
        event.exposure_no = index + 1;
        rows.push([
          {
            text: event.event_seq,
          },
          {
            text: event.event_title,
            align: 'left',
          },
          {
            text: event.exposure_dt,
          },
          {
            text: '미노출',
            type: 'button',
            callback: {
              onClick: ()=> deleteExposuredEvent(event.exposure_no, event.event_seq)
            },
          },
          {
            text: '수정',
            type: 'button',
            callback: {
              onClick: ()=> onEventUpdateClick(event.event_seq)
            },
          }
        ]);
      });
    }
    return rows;
  }, [exposuredEvents]);

  const exposuredData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '게시 일시',
        },
        {
          text: '',
        },
        {
          text: '',
        },
      ],
      wPercentList: [10, 50, 20, 10, 10],
      rows: getExposuredRows(),
    };
  }, [exposuredEvents]);

  // 참여내역 다운로드 기간설정
  useEffect(() => {
    downloadStartDate.select(downloadStartDate.state);
  }, [downloadStartDate.state]);

  useEffect(() => {
    downloadEndDate.select(downloadEndDate.state);
  }, [downloadEndDate.state]);

  //csv다운로드
  const clickCsvFileForEvent = useCallback(() => {
    const token = localStorage.getItem('accessToken');
    const memberType = loginState.auth?.member_type;

    if(!token) {
      showAlert('안내', '로그인이 필요합니다.');
      return;
    };
    if (!memberType) {
      showAlert('안내', '로그인이 필요합니다.');
      return;
    };

    if (token && loginState.auth && loginState.auth.member_type === 1) {
      const headers = { Authorization: `Bearer ${token}` };
      const startDate = downloadStartDate.state;
      const endDate = downloadEndDate.state;

      const dayDiff = moment(endDate).diff(startDate, 'days') <= 90;
      if (dayDiff) {
        const url = `${API_BASE_URL}/admin/events/csv?start_dt=${startDate}&end_dt=${endDate}`;

        fetch(url, {  // api에서 응답받아와서 result처리 해주기 위한 요청
          method: 'GET',
          headers: headers,
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
              return response.json(); // JSON 데이터로 변환
            } else if (contentType && contentType.includes('text/csv')) {
              return response.blob(); // CSV 데이터를 Blob 형태로 가져옴
            } else {
              throw new Error('Invalid response format');
            }
          })
          .then(data => {  // text중 result_code값 체크
            if (typeof data === 'object' && data.result_code) { // json data응답값 처리 로직
              if (data.result_code === '2000') {
                showAlert('안내', '데이터가 없습니다.');
              } else if (data.result_code === '4000') {
                showAlert('안내', '날짜를 정확히 기입해주세요.');
              } else {
                showAlert('안내', '알 수 없는 응답 코드입니다.');
              }
            } else if (data instanceof Blob) {
              const url = window.URL.createObjectURL(data);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', '이벤트 참여 내역 다운로드.csv');
              document.body.appendChild(link);
              link.click();
            } else {
              throw new Error('Invalid response data');
            }
          })
          .catch(error => {
            console.error('Error while downloading CSV file:', error);
            showAlert('안내', '잘못된 응답 형식입니다.');
          });
      } else {
        showAlert('안내', '다운로드 기간은 최대 3개월까지만 설정 가능합니다.');
      }
    }
  }, [downloadStartDate.state, downloadEndDate.state, loginState.auth, loginState.auth.member_type]);



  return (
    <>
      <DashboardNavbar title="이벤트 관리" />

      <LACard>
        <LACardTitle title="노출 이벤트 관리" />
        <LAGridContainer layouts={{ pt: 2 }}>
          <LASimpleTable tableData={exposuredData} />
        </LAGridContainer>
        <LAWarningLabel>게시일시 역순으로 프론트에 표시됩니다.</LAWarningLabel>
      </LACard>

      {/* Start Search Area */}
      <LACard next>
      <LACardTitle title="이벤트 검색" />
        <LAProperty name="노출 여부">
          <LAGridContainer>
            <LARadioGroup
              props={[
                {
                  text: '전체',
                  value: -1,
                },
                {
                  text: '미노출',
                  value: 0,
                },
                {
                  text: '노출',
                  value: 1,
                },
                // {
                //   text: '예약 노출',
                //   value: 2,
                // },
              ]}
              value={visibleType.state}
              onChange={visibleType.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            {/* <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '이벤트 시작일' },
                { key: 1, value: '이벤트 종료일' },
              ]}
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
            /> */}
            <LATabSelector
              wPercent={45}
              items={periodItems}
              // layouts={{ pl: 2 }}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2, mr: 2 }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />{' '}
            ~{' '}
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2 }}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '통합검색' },
                // { key: 1, value: '제목' },
                // { key: 2, value: '등록자 명' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput wPercent={80} layouts={{ pl: { sm: 1 } }} 
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={28}></LALabel>
          <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}>
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LAButton
            wPercent={15}
            onClick={onRegistClick}
          >
            등록
          </LAButton>
          <LALabel wPercent={27}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      {/* 이벤트 참여 내역 다운로드*/}
      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer vAlign="center">
          <LALabel wPercent={50}></LALabel>
          <LADatePicker
            wPercent={15}
            layouts={{ ml: 2, mr: 2 }}
            value={downloadStartDate.state}
            onChange={downloadStartDate.handleChange}
          />{' '}
          ~{' '}
          <LADatePicker
            wPercent={15}
            layouts={{ ml: 2 }}
            value={downloadEndDate.state}
            onChange={downloadEndDate.handleChange}
          />
          <LAButton wPercent={15} layouts={{ pl: { sm: 1 } }} onClick={clickCsvFileForEvent}>이벤트 참여 내역 다운로드</LAButton>
        </LAGridContainer>

        <LAGridContainer>
          <LADataTable columns={columns} rows={rows} defaultPage={0} onChangePage={onChangePage}/>
        </LAGridContainer>
      </LACard>

      {eventRegistModal.state && 
        <EventRegistModal show={eventRegistModal.state} handleClose={onRegistered} eventSeq={selectedEventSeq} />
      }
    </>
  );
}

export default EventSearch;
