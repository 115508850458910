import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';

import {
  LAGridContainer,
  LAButton,
  LADivider,
  LAProperty,
  LALabel,
  LAModal,
  LACard,
  useInput,
  LATextField,
  LASimpleTable,
  useTextField,
  LAFileAttachment,
  useFileAttachment,
} from 'modules/Widgets';

function ClassBatchFeedbackModal({
  applications,
  classroomSeq,
  show,
  handleClose,
}) {
  const feedbackMessage = useTextField('');
  const fileAttachment = useFileAttachment(2048, {
    name: '',
  });
  const { showAlert, showConfirm } = usePopAlert();

  const { queries } = useApiContext();

  const onDeleteClick = () => {};

  const getDate = useCallback((date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD (dd) A hh:mm:SS');
    }
    return '0000-00-00';
  });

  const { request: registFeedbackBatch } = useCommonMutation({
    query: queries.Classroom.registBatch,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '피드백이 등록되었습니다.', {
            confirmHandler: () => {
              // answerInput.update('');
              // requestDetail();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '피드백 등록에 실패했습니다.');
      },
    },
  });

  const getScheduleRows = useCallback(() => {
    const result = [];
    if (applications && applications.length > 0) {
      // for (let i = 0; i < schedules.length; i++) {

      for (const application of applications) {
        let cancelStatus =
          application.cancel_yn == 1 || application.order_status > 10;
        let cancelReason = cancelStatus
          ? application.cancel_reason != undefined
            ? application.cancel_reason
            : application.payment_cancel_reason
          : '';
        result.push([
          {
            text: `${application.row_no}`,
          },
          {
            text: application.applicant_name,
          },
          {
            text: application.user_name,
          },
          {
            text: getDate(application.created_dt),
          },
          {
            text: application.confirmation_yn == 1 ? '확정' : '미확정',
          },
          {
            text: cancelStatus ? '취소' : '결제완료',
          },
          {
            text: getDate(application.payment_cancel_dt),
          },
        ]);
      }
    }

    return result;
  }, [applications]);

  const tableData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '가입자',
        },
        {
          text: '수강자',
        },
        {
          text: '신청일자',
        },
        {
          text: '확정 상태',
        },
        {
          text: '결제 상태',
        },
        {
          text: '취소 일자',
        },
      ],

      wPercentList: [8, 16, 16, 20, 10, 10, 20],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  const onClickFeedbackBatch = useCallback(() => {
    const formData = new FormData();
    var classmates = applications
      .filter((a) => a.order_status == 10)
      .map((am) => am.classmate_seq);
    formData.append('classmates', JSON.stringify(classmates));
    formData.append('contents', feedbackMessage.state);
    formData.append('attachment', fileAttachment.state.info);

    var params = {
      classroomSeq: classroomSeq,
      data: formData,
    };

    registFeedbackBatch(params);
  }, [, classroomSeq, applications, registFeedbackBatch, feedbackMessage]);

  return (
    <LAModal
      title="일괄 피드백"
      width="80%"
      height="80%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LAProperty name="클래스 신청자 목록 ">
          <LALabel wPercent={40}></LALabel>
        </LAProperty>

        <LASimpleTable tableData={tableData} />

        <LAProperty name="피드백 내용">
          <LALabel wPercent={40}></LALabel>
        </LAProperty>
        <LATextField
          placeholder="보낼 내용을 입력해주세요."
          value={feedbackMessage.state}
          onChange={feedbackMessage.handleChange}
        />
        <LAGridContainer pt={1}>
          <LAFileAttachment
            value={fileAttachment.state.name}
            onChange={fileAttachment.handleChange}
          />
        </LAGridContainer>

        <LAGridContainer pt={3}>
          <LALabel wPercent={34} />
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 0.5 } }}
            variant={'outlined'}
            onClick={handleClose}
          >
            취소
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 0.5 } }}
            onClick={onClickFeedbackBatch}
          >
            보내기
          </LAButton>
        </LAGridContainer>
      </LACard>
    </LAModal>
  );
}

export default ClassBatchFeedbackModal;
