import React, { useState, useCallback, useEffect ,useRef} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";

import TeacherDetailContents from './TeacherDetailContents';

function TeacherDetail() {
  const { seq } = useParams();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate('/');
  });

  return (
    <>
      <DashboardNavbar title="선생님 상세" />
      <TeacherDetailContents teacherSeq={seq} onClickList={handleClose}/>
    </>
  );
}

export default TeacherDetail;
