import React, { useState, useCallback, useEffect, useContext } from 'react';

import { UNSAFE_NavigationContext } from "react-router-dom";

import CommissionDetailContents from './CommissionDetailContents';

import { 
    LAModal, 
 } from 'modules/Widgets';


function CommissionDetailModal({commSeq, memberSeq, yyyymm, show, handleClose }) {

    const navigation = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
            window.history.pushState(null, null, window.location.pathname);
            if (navigation) {
            navigation.listen((locationListener) => {
                if(locationListener.action == 'POP') {
                    handleClose();
                }
            });
        }
    }, []);

    return (
    <LAModal
      title="수수료 상세"
      width="70%"
      height="95%"
      show={show}
      onClose={handleClose}
    >
        <CommissionDetailContents commSeq={commSeq} memberSeq={memberSeq} yyyymm={yyyymm} />
    </LAModal>
  );
}

export default React.memo(CommissionDetailModal);
