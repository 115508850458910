import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import pxToRem from 'assets/theme/functions/pxToRem';

function LAKeywordItem({ keyword, isSelected, onClick }) {
  return (
    <ToggleButton
      color="info"
      size="small"
      value={keyword}
      // selected={!!isSelected && isSelected(keyword)}      
      selected={isSelected}
      onClick={onClick}
      sx={{ marginRight: pxToRem(10) }}
    >
      {keyword} {isSelected}
    </ToggleButton>
  );
}

export default React.memo(LAKeywordItem);
