import React from 'react';
import MDBox from 'components/MDBox';
import LAGridContainer from './LAGridContainer';
import LAGridItem from './LAGridItem';

function LADivider({ pt = 0, pb = 0, layouts = {} }) {
  return (
    <LAGridContainer pt={pt} pb={pb} layouts={layouts}>
      <LAGridItem>
        <MDBox sx={{ backgroundColor: '#dddddd', minHeight: '1px' }} />
      </LAGridItem>
    </LAGridContainer>
  );
}

export default React.memo(LADivider);
