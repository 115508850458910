import React from 'react';
import LAGridItem from './LAGridItem';
import pxToRem from 'assets/theme/functions/pxToRem';
import ToggleButton from '@mui/material/ToggleButton';
import useDayOfTheWeek from './hooks/useDayOfTheWeek';

const days = [
  { value: 0, text: '일' },
  { value: 1, text: '월' },
  { value: 2, text: '화' },
  { value: 3, text: '수' },
  { value: 4, text: '목' },
  { value: 5, text: '금' },
  { value: 6, text: '토' },
];

function LADayOfTheWeek({
  wPercent = 100,
  layouts = {},
  value,
  isSelected,
  onClick,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      {days.map((day) => (
        <ToggleButton
          key={day.value}
          color="info"
          selected={isSelected(day.value)}
          size="small"
          value={day.value}
          onClick={onClick}
          sx={{ marginRight: pxToRem(10), width: 50 }}
        >
          {day.text}
        </ToggleButton>
      ))}
    </LAGridItem>
  );
}

export default React.memo(LADayOfTheWeek);
