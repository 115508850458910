import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/schedules';

const ScheduleApi = (requestFunc) => {
  return {    
    Schedule: {
      /**
       * 클래스 일정 등록
       */
      regist: {
        key: 'registSchedule',
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}`, data),
      },

      /**
       * 클래스 일정 목록
       */
      getList: {
        key: 'getScheduleList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/search`,params),
      },

      /**
       * 클래스 일정 목록 for 정산
       */
       getListForTeacher: {
        key: 'getScheduleListForTeacher',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/search-teacher`,params),
      },      

      getCalendar: {
        key: 'getScheduleCalendar',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/calendar`,params),
      },
      

      /**
       * 클래스 일정 상세
       */
      getDetail: {
        key: 'getScheduleDetail',
        queryFn: (scheduleSeq) => requestFunc(METHOD.GET, `${baseURL}/${scheduleSeq}`),
      },

      /**
       * 클래스 일정 일괄 수정
       */
      updateTimetables: {
        key: 'updateTimetables',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.schedule_seq}/timetables`, params),
      },

      /**
       * 클래스 일정 삭제
       */
       deleteCleanSchedule: {
        key: 'deleteCleanSchedule',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.schedule_seq}/clean`),
      },
      

      /**
       * 클래스 일정 확정
       */
      confirmSchedule: {
        key: 'confirmSchedule',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.schedule_seq}/confirm`, params.data),
      },

      /**
       * 클래스 일정 폐강
       */
       closeSchedule: {
        key: 'closeSchedule',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.schedule_seq}/close`, params.data),
      },
      
      /**
       * 클래스 일정 종강
       */
       finishSchedule: {
        key: 'finishSchedule',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.schedule_seq}/finish`, params.data),
      },

      /**
       * 일정 접수 상태 변경
       */
        changeReceptionState: {
        key: 'changeReceptionState',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.schedule_seq}/changeState`, params.data),
      },
      
      /**
       * 일정 삭제
       */
      deleteSchedule: {
        key: 'deleteSchedule',
        queryFn: (schedule_seq) => requestFunc(METHOD.DELETE, `${baseURL}/${schedule_seq}`),
      },
      /**
       * 일정 접수 상태 변경
       */
        changeCurrentTimetable: {
          key: 'changeCurrentTimetable',
          queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.schedule_seq}/change-timetable`, params.data),
        },

    },
  };
};

export default ScheduleApi;
