import React, { useState, useEffect, useCallback, useMemo } from 'react';

// react-router components
import { useLocation, Link ,useNavigate} from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Menu from "@mui/material/Menu";
import Icon from '@mui/material/Icon';

// components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import MDInput from "components/MDInput";
// import MDBadge from "components/MDBadge";

// examples
import Breadcrumbs from 'modules/Breadcrumbs';
// import NotificationItem from "modules/Items/NotificationItem";

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from 'modules/Navbars/DashboardNavbar/styles';

// context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from 'context';

//로그인 컨텍스트
import { useLoginContext } from 'common/context/MemberContext';
import { LAButton } from 'modules/Widgets';
import { LAGridContainer } from 'modules/Widgets';
import { LAGridItem } from 'modules/Widgets';

function DashboardNavbar({ title, absolute, light, isMini }) {
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    // openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);

  //로그인 컨텍스트
  const { isLogin,isTeacher, loginState, action } = useLoginContext();

  const { showAlert, showConfirm } = usePopAlert();

  const authMemberType = useMemo(()=>{
    return action.getMemberType()
  },[action])

  if (!title) {
    title = route[route.length - 1];
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      const value = (fixedNavbar && window.scrollY === 0) || !fixedNavbar;
      if (transparentNavbar !== value) setTransparentNavbar(dispatch, value);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar, transparentNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  // const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  // const renderMenu = () => (
  //   <Menu
  //     anchorEl={openMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
  //     <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
  //     <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
  //   </Menu>
  // );

  // Styles for the navbar icons
  const iconsStyle = useCallback(
    ({ palette: { dark, white, text }, functions: { rgba } }) => {
      return {
        color: () => {
          let colorValue = light || darkMode ? white.main : dark.main;

          if (transparentNavbar && !light) {
            colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
          }

          return colorValue;
        },
      };
    },
    [darkMode, transparentNavbar],
  );

  const onLogout = useCallback(() => {
    showConfirm('확인', '로그아웃 하시겠습니까?', {
      confirmHandler: () => {
        // localStorage.setItem("accessToken", data.result_data.accessToken);
        localStorage.removeItem('accessToken');
        // sessionStorage.removeItem('accessToken');
        action.resetLoginData();
        navigate('/',  { replace: true});
      },
    });
  }, [navigate]);

  const goProfileClick = useCallback(() =>{

    if(isTeacher){      
      navigate(`/user/teacher/list/detail/${loginState.member_seq}`);      
    }else{
      navigate(`/user/admin/list/${loginState.member_seq}`);
    }
  })

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          alignItems="center"
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <IconButton
            mr={{ xs: 2, md: 2 }}
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? 'menu_open' : 'menu'}
            </Icon>
          </IconButton>
          <Breadcrumbs icon="home" title={title} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? 'white' : 'inherit'}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon pt={1} sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              {isLogin ? (
                loginState.auth ? (
                  <LAGridContainer
                    vAlign="center"
                    p={1}
                    layouts={{ width: 300 }}
                  >
                    <LAGridItem wPercent={70}>
                      <MDTypography
                        variant="body2"
                        color={light ? 'white' : 'dark'}
                        verticalAlign="bottom"
                      >
                        <Icon fontSize="small" sx={{ mr: 1 }} onClick={goProfileClick}>
                          account_circle
                        </Icon>
                        <span>
                          {loginState.member_name} [
                          {/* {loginState.auth.member_type_name} |{' '} */}
                          {loginState.auth.auth_type_name}]
                        </span>
                      </MDTypography>
                    </LAGridItem>
                    <LAGridItem wPercent={30}>
                      <LAButton onClick={onLogout}>Logout</LAButton>
                    </LAGridItem>
                  </LAGridContainer>
                ) : (
                  <MDTypography
                    variant="body2"
                    color={light ? 'white' : 'dark'}
                    verticalAlign="baseline"
                  >
                    <Icon fontSize="small" sx={{ mr: 1 }}>
                      account_circle
                    </Icon>
                    <span>{loginState.member_name} [일반회원]</span>
                  </MDTypography>
                )
              ) : (
                <Link to="/auth">
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton>
                </Link>
              )}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default React.memo(DashboardNavbar);
