import { METHOD } from "common/hooks/useAxios";

const baseURL = "/admin/schedules";

const UserMemoApi = (requestFunc) => {
  return {
    Url: {
      getUrlData: {
        key: "getUrlData",
        queryFn: (scheduleSeq) =>
          requestFunc(METHOD.GET, `${baseURL}/${scheduleSeq}`),
      },
      editUrl: {
        key: "editUrl",
        queryFn: (params) =>
          requestFunc(
            METHOD.POST,
            `${baseURL}/${params.schedule_seq}/changeUrl`,
            params
          ),
      },
    },
  };
};

export default UserMemoApi;
