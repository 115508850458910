import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/keywords';

const KeywordApi = (requestFunc) => {
  return {
    Keyword: {
      getAll: {
        key: 'getKeywordAll',
        queryFn: () => requestFunc(METHOD.GET, `${baseURL}/allinfo`),
      },
      getList: {
        key: 'getKeywordList',
        queryFn: () => requestFunc(METHOD.GET, `${baseURL}/all`),
      },
      addKeyword: {
        key: 'addKeyword',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
      },
      modifyKeyword: {
        key: 'modifyKeyword',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.keyword_seq}`, params),
      },
      deleteKeyword: {
        key: 'deleteKeyword',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.keyword_seq}`, params),
      },

      modifyMainInfo: {
        key: 'modifyMainInfo',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/updatemain`, params),
      },

    },
  };
};

export default KeywordApi;
