import React, { useState, useCallback ,useEffect} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import ClassInquiryDetailContents from './components/ClassInquiryDetailContents';

function TeacherDetail() {
  

  return (
    <>
      <DashboardNavbar title="클레스 문의 상세" />

      <ClassInquiryDetailContents />

    </>
  );
}

export default TeacherDetail;
