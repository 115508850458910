import React, { useEffect, useCallback, useContext, useMemo, useState } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import {
    LAModal,
    LACard,
    LACardTitle,
    LAGridContainer,
    LALabel,
    LAButton,
    LAInput,
    useInput
} from "modules/Widgets";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import useCommonMutation from "common/hooks/useCommonMutation";
import { useApiContext } from "../../../common/context/ApiContext";
import { LADivider } from "modules/Widgets";
import { LAProperty } from "modules/Widgets";

function OrderChangeTextbookModal({ data, show, handleClose }) {
    const { class: classInfo, order, orderOptions } = data || {};
    const navigation = useContext(UNSAFE_NavigationContext).navigator;
    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        if (navigation) {
            navigation.listen((locationListener) => {
                if (locationListener.action == "POP") {
                    handleClose();
                }
            });
        }
    }, []);

    const { showAlert, showConfirm } = usePopAlert();
    const changeBookReason = useInput("", 3000);
    const { queries } = useApiContext();
    const [textBookName, setTextBookName] = useState("");

    const { request: requestExchangeTextbook } = useCommonMutation({
        query: queries.Delivery.exchangeTextbook,
        callbacks: {
            onSuccess: (data) => {
                if (data && data.result_code === "0000") {
                    showAlert("안내", "등록이 완료 되었습니다.");
                    handleClose();
                    changeBookReason.reset();
                } else {
                    showAlert("안내", data.result_message);
                }
            },
            onError: (error) => {
                showAlert("알림", "교환 발송등록에 실패하였습니다.");
            }
        },
        initEnabled: false
    });

    const requestChange = useCallback(() => {
        // if(data.order_status !== 10) {
        //   showAlert('알림', '결제취소 및 취소대기 상태입니다.');
        //   return ;
        // }

        if (changeBookReason.state === "") {
            showAlert("알림", "사유를 입력해 주세요.");
            return;
        }
        showConfirm("확인", `교환 상품을 발송하시겠습니까?\n상품 발송 시 당일 취소만 가능합니다.`,
            {
                confirmHandler: () => {
                    // API Request
                    requestExchangeTextbook({
                        order_seq: order.order_seq,
                        data: {
                            return_reason: changeBookReason.state,
                            exchange_options: [textBookName?.option_seq]
                        }
                    });
                }
            }
        );
    }, [changeBookReason.state, data]);

    useEffect(() => {
        if (!data) {
            return;
        }

        setTextBookName(orderOptions?.find((o) => {
            return o.option_type === 2 ? o.option_name : "";
        }));
    }, [data]);

    const handleClosedReset = useCallback(() => {
        changeBookReason.reset();
        handleClose();
    }, [changeBookReason, handleClose]);

    const appendixNames = useMemo(() => {  // 부록 이름
        if (!classInfo?.appendixs) {
            return "";
        }


        let result = "";
        classInfo.appendixs.forEach((data, index) => {
            result += index > 0 ? ", " + data.appendix_name : data.appendix_name;
        });
        return result;
    }, [classInfo?.appendixs]);

    console.log("appendixNames :: ", appendixNames);

    return (
        <LAModal title="교환 발송 등록" width="40%" height="50%" show={show} onClose={handleClosedReset}>
            <LACard next>
                <LAGridContainer layouts={{ sx: { display: "flex", alignItems: "center" } }}>
                    <LALabel grid={false} wPercent={50} props={{ sx: { fontWeight: 800, fontSize: 16 } }}>교재명
                        |&nbsp;</LALabel>
                    <LALabel grid={false} wPercent={50}>{textBookName?.option_name}</LALabel>
                </LAGridContainer>

                <LAGridContainer layouts={{ mt: { sm: 3 }, mb: { sm: 1 } }}>
                    <LACardTitle title="교환 사유 입력" showDivider={true} required fontSize={16} />
                    <LAInput
                        layouts={{ mt: 2 }}
                        placeholder={"교환 사유를 입력해주세요."}
                        value={changeBookReason.state}
                        onChange={changeBookReason.handleChange}
                    />
                </LAGridContainer>
                <LAGridContainer layouts={{ mt: { sm: 1 } }}>
                    <LALabel wPercent={35}></LALabel>
                    <LAButton wPercent={30} layouts={{ pl: { sm: 0.5 } }} onClick={requestChange}>교환 발송 등록</LAButton>
                    <LALabel wPercent={35}></LALabel>
                </LAGridContainer>
            </LACard>
        </LAModal>
    );
}

export default React.memo(OrderChangeTextbookModal);