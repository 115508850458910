import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";

import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import { useApiContext } from "common/context/ApiContext";

import { useLoginContext } from "common/context/MemberContext";
import { toCurrency } from "common/utils";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import OrderRefundModal from "./OrderRefundModal";
import ChangeDeliveryAddressModal from "./ChangeDeliveryAddressModal";
import OrderDeliveryHistoryTable from "./OrderDeliveryHistoryTable";
import OrderHistoryTable from "./OrderHistoryTable";
import OrderChangeTextbookModal from "./OrderChangeTextbookModal";
import DeliveryRegistModal from "./DeliveryRegistModal";

import { getAge } from "common/utils";

import moment from "moment";

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  useInput,
  useModal,
  useTextField,
} from "modules/Widgets";

function OrderDetailContents({
  orderSeq,
  onClickList,
  orderDetailContentsShow,
}) {
  const { action } = useLoginContext();
  const { queries } = useApiContext();
  const changeAddressModal = useModal(false);

  const receiverName = useInput("");
  const receiverMobile = useInput("");
  const receiverPhone = useInput("");
  const receiverZipCode = useInput("");
  const receiverAddress1 = useInput("");
  const receiverAddress2 = useInput("");
  const receiverMessage = useInput("");
  const refundModal = useModal(false);

  const changeModal = useModal(false);
  const cancelAmount = useInput(0);

  const refundReason = useTextField("");

  const { showAlert, showConfirm } = usePopAlert();
  const [data, setData] = useState(null);
  const [exchangeStatus, setExchangeStatus] = useState(false);
  const [returnStatus, setReturnStatus] = useState(false);
  const [isAlertClosed, setAlertClosed] = useState(false); // 교환/반품 취소얼랏창 닫힘동작 제어
  const [salesCompleted, setSalesCompleted] = useState(false); // 매출처리 상태값

  /* TODO 20230614
   *    exchangeStatus,returnStatus 상태관리 OrderDeliveryHistoryTable에서 상태 제어하는데 사용했으나
   *  전체적인 삭제로직 변경하면서 필요 없을 수 있음. 추후 기능상 문제없다면 두 컴포넌트에서 삭제 해도무방
   * */
  // const {application} = data

  const navigate = useNavigate();

  //   const navigateBack = () => {
  //     navigate(-1, { replace: true });
  //   };

  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  useEffect(() => {
    if (orderDetailContentsShow) {
      refundModal.visible(true);
    }
  }, [orderDetailContentsShow]);

  useEffect(() => {
    requestDetail();
  }, []);

  useEffect(() => {
    setExchangeStatus(false);
  }, [exchangeStatus]);

  useEffect(() => {
    setReturnStatus(false);
  }, [returnStatus]);

  useEffect(() => {
    setSalesCompleted(false);
  }, [salesCompleted]);

  useEffect(() => {
    if (isAlertClosed) {
      setAlertClosed(false);
      requestDetail(); // 취소된 후에 최신화된 데이터를 가져오기 위해 호출
    }
  }, [isAlertClosed]);

  /*
  useEffect(() => {
    if (data) {
      if (data.delivery) {
        receiverName.update(data.delivery.receiver_name);
        receiverMobile.update(data.delivery.receiver_phone_no);
        receiverPhone.update(data.delivery.receiver_landline_no);
        receiverZipCode.update(data.delivery.address_zip);
        receiverAddress1.update(data.delivery.address_road);
        receiverAddress2.update(data.delivery.address_detail);
        receiverMessage.update(data.delivery.remarks);
      }
    }
  }, [data]);
*/

  /* 반품 삭제 요청 로직 */
  const onRequestReturnCancelClick = useCallback(
    (seq) => {
      showConfirm("안내", "교재 회수 요청을 취소하시겠습니까?", {
        confirmHandler: () => {
          requestCancelRecallOrder({
            order_seq: data?.order.order_seq,
            return_textbook_seq: seq,
          });
        },
      });
    },
    [data?.order.order_seq]
  );

  const { request: requestCancelRecallOrder } = useCommonMutation({
    query: queries.Delivery.cancelRecallOrder,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "교재 회수 요청을 취소했습니다.", {
            confirmHandler: () => {
              setAlertClosed(true); // 알림 닫힘 상태로 변경
            },
          });
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "회수 요청취소 실패.");
      },
    },
    initEnabled: false,
  });
  /* 반품 삭제 요청 로직 end */

  /* 교환 삭제 요청 로직 */
  const onRequestExchangeCancelClick = useCallback(
    (seq) => {
      showConfirm("알림", "배송 요청을 취소하시겠습니까?", {
        confirmHandler: () =>
          requestDelete({
            return_textbook_seq: seq,
            order_seq: data?.order.order_seq,
          }),
      });
    },
    [data?.order.order_seq]
  );

  const { request: requestDelete } = useCommonMutation({
    query: queries.Delivery.cancelExchangeTextbook,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("안내", "취소되었습니다.", {
            confirmHandler: () => {
              setAlertClosed(true); // 알림 닫힘 상태로 변경
              requestDetail();
            },
          });
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", data.result_message);
      },
    },
  });
  /* 교환 삭제 요청 로직 end*/

  const { result: resultData, request: requestDetail } = useCommonQuery({
    query: queries.Order.getOrderDetail,
    params: orderSeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setData(data.result_data);
        } else {
          showAlert("알림", data.result_message, {
            confirmHandler: () => onClickList(),
          });
        }
      },
      onError: (error) => {
        showAlert("알림", "결제 정보 조회에 실패하였습니다.", {
          confirmHandler: () => onClickList(),
        });
      },
    },
    initEnabled: false,
  });

  const { request: requestCancelOrder } = useCommonMutation({
    query: queries.Order.cancelOrder,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "결제가 취소되었습니다.");
          requestDetail();
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "결제 취소에 실패하였습니다.");
      },
    },
    initEnabled: false,
  });

  const onCancelClick = useCallback(() => {
    if (authMemberType != 1 || authAdminAuthType != 1) {
      showAlert(
        "알림",
        `취소 권한이 없습니다.\n채널톡의 '주문 문의 > 취소문의'를 통해서 문의 주세요.`
      );
      return;
    }

    if (data && data.payment && data.order) {
      if (data.order.order_status != 10) {
        showAlert("알림", "취소 가능한 상태가 아닙니다.");
        return;
      }

      if (
        (cancelAmount.state > data && data.payment.amount) ||
        cancelAmount.state > data.cancelable.cancelable_amount
      ) {
        showAlert(
          "알림",
          `취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${data.cancelable.cancelable_amount}원\n - 취소 요청금액 : ${cancelAmount.state}원`
        );
        return;
      }

      const isAll = cancelAmount.state == data.payment.amount;

      showConfirm(
        "확인",
        `결제 취소를 하시겠습니까?\n - 취소 요청금액 : ${
          cancelAmount.state
        }원(${isAll ? "전액" : "부분"})`,
        {
          confirmHandler: () => {
            requestCancelOrder({
              order_seq: orderSeq,
              data: {
                cancel_reason: "관리자 취소",
                cancel_amount: cancelAmount.state,
              },
            });
          },
        }
      );
    } else {
      showAlert("알림", "결제정보가 없습니다.");
    }
  }, [data, cancelAmount.state]);

  const onPartRefundClick = useCallback(() => {
    refundModal.visible(true);
  }, []);

  const onCloseCancelModal = useCallback(() => {
    requestDetail();
    refundModal.handleClose();
    setReturnStatus(true);
  }, [refundModal]);

  const renderTextbookNames = useCallback((textbooks) => {
    // 교재 이름
    let textbookNames = "";
    if (textbooks && textbooks.length > 0) {
      textbooks.forEach((textbook, index) => {
        if (index == 0) {
          textbookNames = textbook.option_name;
        } else {
          textbookNames += ", " + textbook.option_name;
        }
      });
    }
    return textbookNames;
  });

  const renderAppendixNames = useCallback((appendixs) => {
    // 부록 이름
    let appendixNames = "";
    if (appendixs && appendixs.length > 0) {
      appendixs.forEach((appendix, index) => {
        if (index == 0) {
          appendixNames = appendix.appendix_name;
        } else {
          appendixNames += ", " + appendix.appendix_name;
        }
      });
    }
    return appendixNames;
  });

  const renderSchedules = useCallback((schedules) => {
    let scheduleDates = "";

    if (schedules) {
      if (schedules.length > 0) {
        const getTimes = moment(
          schedules[0].schedule_date + " " + schedules[0].schedule_time
        ).format("A hh:mm");

        schedules.forEach((item, index) => {
          if (item.schedule_date) {
            let getDates = moment(item.schedule_date).format("MM/DD(dd)");
            if (index === 0) {
              scheduleDates = getDates;
            } else {
              scheduleDates += ", " + getDates;
            }
          }
        });
        return scheduleDates + " " + getTimes;
      }
    } else {
      return "없음";
    }
  });

  const onDeliveryClick = useCallback((invoice_number) => {
    if (invoice_number) {
      window.open(
        `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${invoice_number}`
      );
    }
  }, []);

  //해당 함수 사용 확인필요
  /*  const onChangeDeliveryInfoClick = useCallback(() => {
        if (data && data.application && data.application.confirmation_yn == 1) {
        showAlert('알림', '확정된 주문은 배송지를 변경 할 수 없습니다.');
        return;
        }
        changeAddressModal.visible(true);
    }, [data, changeAddressModal]);*/

  // 교환 발송 버튼
  const changeButtonOnClick = useCallback(() => {
    if (data?.application.confirmation_yn === 0) {
      showAlert("안내", `확정되지 않은 클래스의 교재는 교환할 수 없습니다.`);
      changeModal.visible(false);
    } else if (data?.delivery.waybill_delivery_status !== 6) {
      showAlert(
        "안내",
        `배송되지 않은 상품이므로 교환할 수 없습니다. \n 배송 완료 후 진행해주세요.`
      );
      changeModal.visible(false);
    } else {
      if (!!data && data?.exchangeTextbooks?.length > 0) {
        showConfirm(
          "안내",
          `교환 요청 내역이 이미 있습니다.\n재발송 하시겠습니까?`,
          {
            confirmHandler: () => {
              changeModal.visible(true);
            },
          }
        );
        // showAlert('안내', `교환 요청 내역이 이미 있습니다. \n 재발송을 하시려면 아래 교환 요청 삭제 후 재요청 해주세요.`);
        // changeModal.visible(false);
      } else {
        changeModal.visible(true);
      }
    }
  }, [changeModal]);

  const payMethodText = useMemo(() => {
    let result = "";
    if (data?.payment?.pay_method) {
      result +=
        `${data.payment.pay_method}` +
        (data.payment.card_name?.length > 0 &&
        data.payment.card_number?.length > 0
          ? ` - ${data.payment.card_name}(${data.payment.card_number})`
          : "");
    }

    if (data?.order?.coupon) {
      result += result.length > 0 ? ", 쿠폰" : "쿠폰";

      if (data?.order?.coupon?.coupon_name)
        result += ` - ${data?.order?.coupon?.coupon_name} (${data?.order?.coupon?.coupon_no})`;
    }

    if (data?.order?.use_earned_point > 0) {
      result += result.length > 0 ? ", 올머니" : "올머니";
    }

    if (data?.order?.use_bookclub_point > 0) {
      result += result.length > 0 ? ", 클럽포인트" : "클럽포인트";
    }

    if (data?.order?.use_club_mileage_sum > 0) {
      result += result.length > 0 ? ", 클럽마일리지" : "클럽마일리지";
    }

    return result;
  }, [data]);

  return (
    // 결제 상세 보기
    <>
      <LACard next>
        <LACardTitle title="결제 정보" />
        <LAProperty name="결제번호">
          <LALabel>
            {data && data.payment ? data.payment.merchant_uid : ""}{" "}
            {data && data.payment && data.payment.imp_uid
              ? `(${data.payment.imp_uid})`
              : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제일시">
          <LALabel>
            {data && data.payment
              ? moment(data.payment.created_dt).format(
                  "YYYY-MM-DD (dd) A hh:mm"
                )
              : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제자 명">
          <LALabel>
            {data && data.payment ? data.payment.buyer_name : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제자 연락처">
          <LALabel>
            {data && data.payment ? data.payment.buyer_tel : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="이메일">
          <LALabel>
            {data && data.payment ? data.payment.buyer_email : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="카드 결제 금액">
          <LALabel>
            {data && data.order ? `${data.order.payment_price}원` : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="결제 수단">
          <LALabel>{payMethodText}</LALabel>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="결제 내역" />
        <LAGridContainer pt={1} pb={2}>
          <OrderHistoryTable
            orderInfo={{
              // classType: data && data.class ? data.class.class_type_name : '',
              // className: data && data.class ? data.class.class_name : '',
              // textbookName: data && data.class ? renderTextbookNames(data.class.textbooks) : '',
              // classStartDate: data && data.class ? `${data.class.schedule_date} ${data.class.schedule_time}` : '',
              classRound:
                data && data.class ? data.class.curriculum_count : "0",
              textbookCount:
                data && data.class
                  ? data.class.textbooks.length > 0
                    ? data.class.textbooks.length
                    : "없음"
                  : "없음",
              textbookYn:
                data && data.class
                  ? data.class.textbooks?.length > 0
                    ? true
                    : false
                  : false,
              confirmationYn:
                data && data.application
                  ? data.application.confirmation_yn == 1
                  : false,
              payPrice:
                data && data.order
                  ? `${toCurrency(data.order.order_price)}원`
                  : "",
              textbookPrice:
                data && data.orderOptions && data.orderOptions.length > 0
                  ? () => {
                      let textbookPrice = data.orderOptions.reduce(
                        (a, b) =>
                          a + (b.option_type === 2 ? b.option_price : 0),
                        0
                      );
                      return textbookPrice + " 원";
                    }
                  : "-",

              // textbookPrice: data&&data.class&&data.class.textbooks&&data.class.textbooks.length > 0 ? (()=> {
              //   let textbookPrice = 0;
              //   data.class.textbooks.forEach((textbook) => {
              //     textbookPrice += data.class.textbooks.discount_price;
              //   })
              //   return textbookPrice + ' 원';
              // }) : '-',
              couponDiscountPrice:
                data && data.order && data.order.use_coupon_price
                  ? `-${toCurrency(data.order.use_coupon_price || 0)}원`
                  : "0원",
              useEarnedPoint:
                data && data.order && data.order.use_earned_point > 0
                  ? `-${toCurrency(data.order.use_earned_point)}원`
                  : "0원",
              useBookclubPoint:
                data && data.order && data.order.use_bookclub_point > 0
                  ? `-${toCurrency(data.order.use_bookclub_point)}원`
                  : "0원",
              useBookclubMilage:
                data && data.order && data.order.use_club_mileage_sum > 0
                  ? `-${toCurrency(data.order.use_club_mileage_sum)}원`
                  : "0원",
              deliveryFee:
                data && data.order
                  ? `${toCurrency(data.order.delivery_price)}원`
                  : "0원",
              totalPrice:
                data && data.payment
                  ? `${toCurrency(data.payment.amount)}원`
                  : "",
              payMethod: data && data.payment ? data.payment.pay_method : "",
              totalCalculator:
                data && data.order
                  ? `${toCurrency(data.order.order_price)}원` +
                    ` - ${toCurrency(data.order.use_coupon_price)}원` +
                    ` - ${toCurrency(data.order.use_earned_point)}원` +
                    ` - ${toCurrency(data.order.use_bookclub_point)}원` +
                    ` - ${toCurrency(data.order.use_club_mileage_sum)}원` +
                    ` + ${toCurrency(data.order.delivery_price)}원`
                  : "",
              payStatus:
                data && data.order
                  ? data.order.order_status === 10
                    ? "결제완료"
                    : data.order.order_status === 21
                    ? "취소대기"
                    : data.order.order_status === 20
                    ? "결제취소"
                    : data.order.order_status === 3
                    ? "결제실패"
                    : "기타"
                  : "정보없음",
              orderStatus: data && data.order.order_status,
              cancelAmount:
                data && data.paymentCancel && data.paymentCancel.cancel_amount,
              statusButtonText:
                data && data.order
                  ? data.order.order_status === 10 || 21
                    ? `결제 취소<`
                    : ""
                  : "",
              deliveryButtonText: "배송 조회",
              deliveryInfoText:
                data && data.deliveryInfo && data.deliveryInfo.status_nm
                  ? data.deliveryInfo.status_nm
                  : "",
              deliveryInfoInvoice:
                data && data.deliveryInfo && data.deliveryInfo.invoice_number
                  ? data.deliveryInfo.invoice_number
                  : "",
              cancelButtonOnClick: () => onPartRefundClick(),
              deliveryButtonOnClick: () =>
                onDeliveryClick(data.deliveryInfo.invoice_number),
              changeButtonOnClick: () => changeButtonOnClick(),
            }}
          />
        </LAGridContainer>

        {data && data.paymentCancel ? (
          <>
            <LACardTitle title="취소 내역" />
            <LAProperty name="취소 일시">
              <LALabel>
                {moment(data.paymentCancel.created_dt).format(
                  "YYYY-MM-DD (dd) A hh:mm"
                )}
              </LALabel>
            </LAProperty>

            <LAProperty name="카드 환불 금액">
              <LALabel>{data.order.cancel_payment_price} 원</LALabel>
            </LAProperty>

            <LAProperty name="쿠폰 환불 금액">
              <LALabel>
                {data?.application.confirmation_yn === 0 ? (
                  <>
                    {data?.order?.coupon?.discount_price
                      ? toCurrency(data.order.coupon.discount_price)
                      : 0}{" "}
                    원
                  </>
                ) : (
                  "0원"
                )}
              </LALabel>
            </LAProperty>

            <LAProperty name="올머니 환불 금액">
              <LALabel>
                {data.application.confirmation_yn === 0
                  ? `${data.order.use_earned_point} 원`
                  : "0원"}
              </LALabel>
            </LAProperty>

            <LAProperty name="클럽 포인트 환불 금액">
              <LALabel>
                {data.order.use_bookclub_point -
                  data.order.refund_bookclub_point}{" "}
                원
              </LALabel>
            </LAProperty>

            <LAProperty name="클럽 마일리지 환불 금액">
              <LALabel>
                {data.order.use_club_mileage_sum -
                  data.order.refund_club_mileage_sum}{" "}
                원
              </LALabel>
            </LAProperty>

            <LAProperty name="취소 사유">
              <LALabel>
                {data.paymentCancel.cancel_reason}{" "}
                {data.paymentCancel.cancel_reason_detail
                  ? ` : ${data.paymentCancel.cancel_reason_detail}`
                  : ``}
              </LALabel>
            </LAProperty>

            {data.payment &&
            data.payment.amount &&
            data.payment.amount !== data.paymentCancel.cancel_amount ? (
              <LAProperty name="차감 금액">
                <LALabel>
                  {toCurrency(
                    data.payment.amount - data.paymentCancel.cancel_amount
                  )}{" "}
                  원
                </LALabel>
              </LAProperty>
            ) : (
              <></>
            )}

            {/*즉시 반품*/}
            {data.exchangeTextbooks
              ?.filter(
                (item) =>
                  item.process_type === "RETURN" && item.return_option === 1
              )
              .map((item) => (
                <React.Fragment key={item.id}>
                  <LAProperty name="반품 옵션">
                    <LALabel>즉시 반품</LALabel>
                  </LAProperty>
                  <LAProperty name="귀책 대상">
                    <LALabel>
                      {item.faults_target === 1
                        ? "회사 귀책"
                        : item.faults_target === 2
                        ? "강사 귀책"
                        : item.faults_target === 3
                        ? "고객 귀책(왕복 배송비 고객 부담)"
                        : item.faults_target === 4
                        ? "고객 귀책(배송비 고객 부담)"
                        : item.faults_target === 5
                        ? "고객 귀책(배송비 부담 없음)"
                        : ""}
                    </LALabel>
                  </LAProperty>
                  <LAProperty name="반품 사유">
                    <LALabel>{item.return_reason}</LALabel>
                  </LAProperty>
                  <LAProperty name="처리자">
                    <LALabel>{item.member_name}</LALabel>
                  </LAProperty>
                </React.Fragment>
              ))}

            {/*교재 회수 후 취소 */}
            {data.exchangeTextbooks
              ?.filter(
                (item) =>
                  item.process_type === "RETURN" && item.return_option === 2
              )
              .map((item) => (
                <React.Fragment key={item.id}>
                  <LAProperty name="반품 옵션">
                    <LALabel>교재 회수 후 취소</LALabel>
                  </LAProperty>
                  <LAProperty name="귀책 대상">
                    <LALabel>
                      {item.faults_target === 1
                        ? "회사 귀책"
                        : item.faults_target === 2
                        ? "강사 귀책"
                        : item.faults_target === 3
                        ? "고객 귀책(왕복 배송비 고객 부담)"
                        : item.faults_target === 4
                        ? "고객 귀책(배송비 고객 부담)"
                        : item.faults_target === 5
                        ? "고객 귀책(배송비 부담 없음)"
                        : ""}
                    </LALabel>
                  </LAProperty>
                  <LAProperty name="반품 회수 송장">
                    <LALabel>{`${item.waybill_company_name}(${item.waybill_no})`}</LALabel>
                  </LAProperty>
                  <LAProperty name="반품 검수 결과">
                    <LALabel>
                      {item.return_feedback_result === 1
                        ? "반품 가능"
                        : item.return_feedback_result === 2
                        ? "반품 불가"
                        : ""}
                    </LALabel>
                  </LAProperty>
                  <LAProperty name="반품 검수 피드백">
                    <LALabel>{item.return_feedback_desc}</LALabel>
                  </LAProperty>
                  <LAProperty name="처리자">
                    <LALabel>{item.member_name}</LALabel>
                  </LAProperty>
                </React.Fragment>
              ))}
          </>
        ) : (
          <></>
        )}
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 신청 정보" />
        <LAProperty name="클래스 명">
          <LALabel>{data && data.class ? data.class.class_name : ""}</LALabel>
        </LAProperty>
        <LAProperty name="교재">
          <LALabel>
            {data && data.class.textbook_delivery_type === 1
              ? renderTextbookNames(data.class.textbooks)
              : data && data.class.textbook_delivery_type === 2
              ? renderAppendixNames(data.class.appendixs)
              : "없음"}
          </LALabel>
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LALabel>
            {data && data.class ? data.class.class_type_name : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="회차">
          <LALabel>
            {data && data.class ? data.class.curriculum_count : "0"}회
          </LALabel>
        </LAProperty>
        <LAProperty name="수업일">
          <LALabel>
            {data && data.orderOptions
              ? renderSchedules(data.orderOptions)
              : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="학습자 명">
          <LALabel>
            {" "}
            {data && data.user ? `${data.user.user_name}` : ""}{" "}
            {data && data.user ? `(${getAge(data.user.birth)}세)` : ""}
          </LALabel>
        </LAProperty>
        {data && data.order && data.order.bookclub_contract_number ? (
          <LAProperty name="결제 계약(멤버십)">
            <LALabel>
              {" "}
              {`${data.order.bookclub_contract_name} (${data.order.bookclub_contract_number})`}{" "}
            </LALabel>
          </LAProperty>
        ) : (
          ""
        )}
        {data && data.order && data.order.bookclub_child_member_code ? (
          <LAProperty name="자녀정보(자녀 번호)">
            <LALabel>
              {" "}
              {`${data.order.bookclub_child_member_name} (${data.order.bookclub_child_member_code})`}{" "}
            </LALabel>
          </LAProperty>
        ) : (
          ""
        )}
      </LACard>

      {data && data.delivery ? (
        <LACard>
          <OrderDeliveryHistoryTable
            data={data}
            exchangeStatus={exchangeStatus}
            returnStatus={returnStatus}
            onDeleteReturnRequest={onRequestReturnCancelClick}
            onDeleteExchangeRequest={onRequestExchangeCancelClick}
          />
        </LACard>
      ) : (
        ""
      )}

      {/* <LAProperty name="받는 분">
                    <LAInput wPercent={20} value={receiverName.state} onChange={receiverName.handleChange} />
                </LAProperty>
                <LAProperty name="휴대폰 번호">
                    <LAInput wPercent={20} value={receiverMobile.state} onChange={receiverMobile.handleChange} />
                </LAProperty>
                <LAProperty name="전화번호">
                    <LAInput wPercent={20} value={receiverPhone.state} onChange={receiverPhone.handleChange} />
                </LAProperty>
                <LAProperty name="받는 주소">
                    <LAGridContainer>
                        <LAInput wPercent={20} layouts={{ pr: { sm: 0.5 } }} value={receiverZipCode.state} onChange={receiverZipCode.handleChange} />
                        <LAButton wPercent={15} layouts={{ pl: { sm: 0.5 } }}>주소 찾기</LAButton>
                    </LAGridContainer>
                    <LAGridContainer layouts={{ pt: 1 }}>
                        <LAInput wPercent={50} value={receiverAddress1.state} onChange={receiverAddress1.handleChange} />
                    </LAGridContainer>
                    <LAGridContainer layouts={{ pt: 1 }}>
                        <LAInput wPercent={50} value={receiverAddress2.state} onChange={receiverAddress2.handleChange} />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="배송 요청사항">
                    <LAInput value={receiverMessage.state} onChange={receiverMessage.handleChange} />
                </LAProperty>
                <LAGridContainer layouts={{mt:{sm:1}}}>
                    <LALabel wPercent={35}></LALabel>
                    <LAButton wPercent={15} layouts={{ pr: { sm: 0.5 } }}>취소</LAButton>
                    <LAButton wPercent={15} layouts={{ pl: { sm: 0.5 } }}>변경</LAButton>
                </LAGridContainer> */}

      {/* <LACard next>
                <LACardTitle title="처리 내역" />
                <LAGridContainer pt={2}>
                <LASimpleTable tableData={processHistoryTableData} />
                </LAGridContainer>
            </LACard> */}

      {/* <LACard next>
                <LACardTitle title="관리 메모" />

                <LAProperty name="관리 메모">
                <LAGridContainer>
                    <LAInput
                    wPercent={80}
                    placeholder="관리 메모 입력"
                    layouts={{ pr: { sm: 1 } }}
                    />
                    <LAButton wPercent={20}>등록</LAButton>
                </LAGridContainer>
                </LAProperty>
                <LAGridContainer pt={1}>
                <LASimpleTable tableData={managementMemoTableData} />
                </LAGridContainer>
            </LACard> */}

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={40}></LALabel>
        <LAButton
          wPercent={20}
          variant={"outlined"}
          btnPaddingY={12}
          onClick={onClickList}
        >
          목록
        </LAButton>
      </LAGridContainer>

      <OrderRefundModal
        show={refundModal.state}
        handleClose={onCloseCancelModal}
        isAdmin
        data={data}
        refreshData={requestDetail}
      />

      <ChangeDeliveryAddressModal
        show={changeAddressModal.state}
        handleClose={() => {
          changeAddressModal.handleClose();
          requestDetail();
        }}
        deliveryInfo={!!data && { ...data.delivery, order_seq: orderSeq }}
      />

      {data && data.class?.textbooks?.length > 0 ? (
        <OrderChangeTextbookModal
          data={data}
          show={changeModal.state}
          handleClose={() => {
            changeModal.handleClose();
            //requestDetail();
            setExchangeStatus(true);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default OrderDetailContents;
