import { useState, useEffect, useMemo } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import 'assets/css/LAStyle.scss';
import Sidenav from 'modules/Sidenav';
import theme from 'assets/theme';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
// import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import routes from 'routes';
import togetherRoutes from 'togetherRoutes';

// contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  // setOpenConfigurator,
} from 'context';

// Images
import favicon from 'assets/images/favicon.png';

import ApiProvider from 'common/context/ApiContext';
import SpinnerProvider from 'common/components/spinner/context/SpinnerContext';
import MemberProvider from 'common/context/MemberContext';
import CodeProvider from 'common/context/CodeContext';
import PopAlertProvider from 'common/components/popAlert/context/PopAlertContext';

import Main from 'pages/Main/Main';
import NotFound from 'pages/Main/NotFound';

import ClassDetail from 'pages/Class/ClassManagement/ClassDetail';
import ClassRegist from 'pages/Class/ClassManagement/ClassRegist';

import ClassApplicationRegist from 'pages/Class/ClassManagement/ClassApplicationRegist';
import ClassApplicationDetail from 'pages/Class/ClassManagement/ClassApplicationDetail';

import OrderDetail from 'pages/Order/OrderDetail';
import OrderRefundDetail from 'pages/Order/ClaimManagement/OrderRefundDetail';

import ClassReviewDetail from 'pages/InquiryManagement/ClassReviewDetail';
import ClassInquiryDetail from 'pages/InquiryManagement/ClassInquiryDetail';
import ClassFeedbackDetail from 'pages/InquiryManagement/ClassFeedbackDetail';

import TeacherSupportDetail from 'pages/AllianceManagement/TeacherSupportDetail';
import AllianceInquiryDetail from 'pages/AllianceManagement/AllianceInquiryDetail';
import GroupInquiryDetail from 'pages/AllianceManagement/GroupInquiryDetail';

import AdminRegist from 'pages/AccountManagement/Admin/AdminRegist';
import AdminDetail from 'pages/AccountManagement/Admin/AdminDetail';
import TeacherDetail from 'pages/AccountManagement/Teacher/TeacherDetail';
import AccountListDetail from 'pages/AccountManagement/User/AccountListDetail';
// import WithdrawalMemberDetail from 'pages/AccountManagement/User/WithdrawalMemberDetail';

import Auth from 'pages/Auth';
import PopAlert from 'common/components/popAlert/PopAlert';

import AutoLogin from 'common/components/auth/AutoLogin';
import DashboardLayout from 'modules/LayoutContainers/DashboardLayout';
import Footer from 'modules/Footer';
import AdminSignIn from 'pages/Authorization/AdminSignIn';
// import TeacherJoin from 'pages/Authorization/TeacherJoin';

import TermsManagement from 'pages/MallManagement/ServiceInfo/TermsManagement';
import ClassScheduleDetail from 'pages/Class/ClassManagement/ClassScheduleDetail';

import ScrollTopButton from 'common/components/ScrollTopButton';
import BannerManagement from 'pages/ExhibitionManagement/Home/BannerManagement';

/* 2024-02-21 Together 추가 */
import TogetherMain from 'pages/Main/TogetherMain';
import TogetherClass from 'pages/Together/TogetherClass';
import TogetherOrderManagement from 'pages/Together/TogetherOrderManagement';
import TogetherDeliveryManagement from 'pages/Together/TogetherDeliveryManagement';
import TogetherClassInquiryManagement from 'pages/Together/TogetherClassInquiryManagement';
import TogetherClassCancelManagement from 'pages/Together/TogetherClassCancelManagement';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    // layout,
    // openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });


  return (
    <MemberProvider>
      <PopAlertProvider>
        <SpinnerProvider>
          <ApiProvider>
            <CodeProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AutoLogin />
                <Sidenav
                  color={sidenavColor}
                  brand={
                    (transparentSidenav && !darkMode) || whiteSidenav
                      ? favicon
                      : favicon
                  }
                  brandName1={'라이브올'}
                  brandName2={'투게더'}
                  routes={routes}
                  togetherRoutes={togetherRoutes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                  className={'gnb'}
                />
                <ScrollTopButton />
                <DashboardLayout>
                  <Routes>
                    {getRoutes(routes)}
                    {getRoutes(togetherRoutes)}

                    <Route path="/" element={<Main />} />

                    <Route path="/auth/callback" element={<Auth />} />
                    <Route path="/auth/admin" element={<AdminSignIn />} />

                    <Route path="/class/list/regist" element={<ClassRegist />} />
                    <Route path="/class/applications/regist" element={<ClassApplicationRegist />} />
                    <Route path="/class/applications/detail/:applicationSeq" element={<ClassApplicationDetail />} />
                    <Route path="/class/list/detail/:classSeq" element={<ClassDetail />} />
                    {/* <Route path="/class/list/detail" render={(props) => <ClassDetail props />}/> */}
                    {/* <Route path="/class/list/detail" render={(props) => (<ClassDetail props={false}{...props} />)} /> */}
                    <Route path="/class/schedules/detail/:scheduleSeq" element={<ClassScheduleDetail />} />

                    <Route path="/order/list/detail/:orderSeq" element={<OrderDetail />} />
                    <Route path="/order/claim/refund/detail/:orderSeq" element={<OrderRefundDetail />} />

                    <Route path="/inquiry/review/detail/:reviewSeq" element={<ClassReviewDetail />} />
                    <Route path="/inquiry/inquiry/detail/:inquirySeq" element={<ClassInquiryDetail />} />
                    <Route path="/inquiry/feedback/detail/:feedbackSeq" element={<ClassFeedbackDetail />} />

                    {/* <Route path="/board/alliance/teacher/:seq" element={<TeacherSupportDetail />} /> */}
                    <Route path="/board/alliance/sales/:seq" element={<AllianceInquiryDetail />} />
                    <Route path="/board/alliance/group/:seq" element={<GroupInquiryDetail />} />

                    <Route path="/members/list/:seq" element={<AccountListDetail />} />
                    <Route path="/members/withdrawal/list/:seq" element={<AccountListDetail />} />
                    <Route path="/members/dormant/list/:seq" element={<AccountListDetail />} />

                    <Route path="/user/admin/list/regist" element={<AdminRegist />} />
                    <Route path="/user/admin/list/:seq" element={<AdminDetail />} />
                    <Route path="/user/teacher/list/detail/:seq" element={<TeacherDetail />} />

                    <Route path="/alliance/teacher/detail/:applicationSeq" element={<TeacherSupportDetail />} />
                    <Route path="/mall/service/terms/:termsType" element={<TermsManagement />} />

                    <Route path="/together" element={<TogetherMain />} />
                    <Route path="/togetherClass/management" element={<TogetherClass />} />
                    <Route path="/togetherOrder/management" element={<TogetherOrderManagement />} />
                    <Route path="/togetherOrder/delivery" element={<TogetherDeliveryManagement />} />
                    <Route path="/togetherInquiry/inquiry" element={<TogetherClassInquiryManagement />} />
                    <Route path="/togetherInquiry/cancel" element={<TogetherClassCancelManagement />} />
                    
                    {/* <Route path="/auth/join" element={<TeacherJoin />} /> */}
                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                  <Footer />
                </DashboardLayout>
                <PopAlert />
              </ThemeProvider>
            </CodeProvider>
          </ApiProvider>
        </SpinnerProvider>
      </PopAlertProvider>
    </MemberProvider>
  );
}
