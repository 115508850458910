import React, { useEffect, useState } from "react";
import { useNavigate,useLocation, Link } from "react-router-dom";
import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from "common/hooks/useCommonMutation";
import useQueryParams from 'common/hooks/useQueryParams'

function Auth(){
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { accessToken ,isNew,errMessage} = useQueryParams();
  const {isLogin,loginState,accessTokenData, action} = useLoginContext()
  
  useEffect(() => {    
    if(isLogin){
      navigate('/');
    }
    return () =>{
    }
  },[isLogin, navigate])

  const {requestAsync:authAsync} = useCommonMutation({
    query : queries.Auth.authorize,
    callbacks:{
      onSuccess: (data) => {
        try{
          if (data.result_code === "0000") {
            
            if(data.result_data.auth == undefined){
              if(window.confirm('관리자 및 강사용 사이트입니다. 강사로 등록하시겠습니까?')){
                action.setLoginData(data.result_data)
                navigate('/auth/terms');  
              }
            }else{
              action.setLoginData(data.result_data)
              navigate('/');  
            }
          } else if (data.result_code === "1010") {
            action.setLoginData(data.result_data)
            navigate('/');
          } else {
            // if(data.result_message)
            //   alert(data.result_message)       
            // navigate('/auth');  
            
          }
        } catch (e) {
            console.error('로그인 실패 =>',e);
            alert('로그인에 실패하였습니다.')
          }
        
      },
      onError: (error) => {
      },
    }
  },[action,loginState]);

  useEffect(() => {

    if(errMessage){
      navigate('/');  
      // alert(errMessage)      
      return
    }
    if(accessToken){
      action.setAccessToken(accessToken)    
    }
    return () => {
    };
  }, [accessToken, action, isNew,errMessage]);

  useEffect(() => {    
    console.info(`Auth => begin isLogin:${isLogin}, accessTokenData : `,accessTokenData) 
    // if(isLogin){
    //   console.info('Auth => navigate(/)') 
    //   navigate('/');
    // }
    if(accessTokenData && !isLogin){
      console.info('Auth => request : authAsync() , isLogin : ',isLogin) 
      authAsync()
      // // TODO : 추후 로직 수정해야함.
      // navigate('/');
    }
    
    return () =>{
      console.info(`Auth => end isLogin:${isLogin}, accessTokenData : `,accessTokenData) 
    }
  },[isLogin,authAsync, accessTokenData,  navigate])

  
  return (
    <>
    로그인중...
    </>
  )

}

export default Auth