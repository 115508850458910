import React, {useMemo} from 'react';
import Modal from '@mui/material/Modal';
import 'assets/css/LAModalPopup.scss';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function LAModalPopup({
      modalPopupState,
      titleHtml,
      width = '50%',
      height = '50%',
      className = '',
      children,
      footerButtons
  }) {

    const style = useMemo(
      () => ({
          width: { width },
          height: { height },
          bgcolor: '#eeeeee',
          border: '1px solid #dddddd',
          borderRadius: 3,
          boxShadow: 24,
          paddingBottom: 2,
      }),
      [width, height],
    );
    return (
      <Modal
        open={modalPopupState.isVisible}
        className={`laModalPopup ${className}`}>
          <MDBox className="container" p={1} sx={style}>
              <IconButton className={"btnModalClose"} type="button" aria-label="close" onClick={modalPopupState.hide}>
                  <CloseIcon />
              </IconButton>
              {titleHtml && titleHtml}
              <MDBox className="modalBody" p={3} sx={{ overflow: 'auto', height: titleHtml? '90%' : '100%' }}>
                  <div>
                      { children }
                  </div>
                  { footerButtons &&
                    <footer className='modalFooter'>
                        {
                            footerButtons?.map((item) =>
                              <MDButton
                                className={ item.className? item.className : 'wide' }
                                variant={ item.variant? item.variant : "contained" }
                                size={ item.size? item.size : "medium" }
                                color={ item.theme? item.theme : "info" }
                                startIcon={ item.startIcon }
                                endIcon={ item.endIcon }
                                onClick={ item.onClick }
                              >
                                  {item.text}
                              </MDButton>
                            )
                        }
                    </footer>
                  }
              </MDBox>

          </MDBox>
      </Modal>
    );
}

export default React.memo(LAModalPopup);
