import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
    LACard,
    LACardTitle,
    LAGridContainer,
    LAGridItem,
    LAProperty,
    LAButton,
    LAInput,
    useInput,
    LADivider,
    LAComboBox,
    useComboBox,
    useModal,
    LALabel
} from 'modules/Widgets';

import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import CategoryListView from './component/CategoryListView';
import CategoryLinkView from './component/CategoryLinkView';
import {sendCacheRefresh} from "../../common/utils";

const parentCateItem = [
    { key: 0, value: '선택' },
    { key: 10, value: '라이브올 클래스' },
    { key: 20, value: '투게더 클래스' },
    { key: 30, value: '이벤트/기획전' },
    { key: 41, value: '메뉴 추가(링크 이동)' }
];

function CategoryManagement() {
    const { queries } = useApiContext();
    const parentCate = useComboBox(0);
    const { showAlert, showConfirm } = usePopAlert();
    const [ cateList, setCateList ] = useState([]);
    const [ menuList, setMenuList ] = useState([]);
    const [ delCate, setDelCate ] = useState([]);
    const [ delMenu, setDelMenu ] = useState([]);

    const [ selectedCate, setSelectedCate ] = useState({});
    const [ selectedMenu, setSelectedMenu ] = useState({});

    const listRef = useRef(null);

    const cateName = useInput('');
    const menuName = useInput('');
    const menuLink = useInput('');

    const { request: onRequestList } = useCommonQuery({
        query: queries.Category.main,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setCateList(data.result_data.categories);
                    setMenuList(data.result_data.menus);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '카테고리 목록 조회에 실패했습니다.');
            },
        },
    });
    const { request: requestSaveAll } = useCommonMutation({
        query: queries.Category.saveAll,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '사용자 화면에 반영되었습니다.');
                    onRequestList();
                    /**CACHE REFRESH : LEFT메뉴 갱신*/
                    sendCacheRefresh( '/v1/api/main/leftMenu' );
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '사용자 화면에 반영이 실패했습니다.');
            },
        },
    });
    const { request: requestMoveUp } = useCommonMutation({
        query: queries.Category.moveUp,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    // setCateList(data.result_data);
                    setTimeout(() => {
                        onRequestList();
                    }, 65)
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '카테고리 이동에 실패하였습니다.');
            },
        },
    });
    const { request: requestMoveDown } = useCommonMutation({
        query: queries.Category.moveDown,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    // setCateList(data.result_data);
                    setTimeout(() => {
                        onRequestList();
                    }, 65)
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '카테고리 이동에 실패하였습니다.');
            },
        },
    });
    
    /* 아이템 위로 이동 */
    const onMoveUp = useCallback(() => {
        if (!selectedCate.category_seq && !selectedMenu.main_menu_seq) {
            showAlert('알림', '순서 변경은 카테고리 저장 후 가능합니다.');
            return;
        } else if (!selectedCate.category_seq && selectedMenu.main_menu_seq) {
            showAlert('알림', '메뉴는 순서 변경이 불가합니다.');
            return;
        }

        requestMoveUp(selectedCate.category_seq);
    }, [selectedCate]);

    /* 아이템 아래로 이동 */
    const onMoveDown = useCallback(() => {
        if (!selectedCate.category_seq || selectedCate.category_seq.length === 0) {
            showAlert('알림', '순서 변경은 카테고리 저장 후 가능합니다.');
            return;
        } else if (!selectedCate.category_seq && selectedMenu.main_menu_seq) {
            showAlert('알림', '메뉴는 순서 변경이 불가합니다.');
            return;
        }

        requestMoveDown(selectedCate.category_seq);
    }, [selectedCate]);

    const onChangedCate = useCallback((e, item) => {
        setSelectedCate(item);
        setSelectedMenu([]);
        cateName.update(item.category_name);
        parentCate.select(item.parent_category_seq);
    },[selectedCate, cateName.state]);

    const onChangedMenu = useCallback((e, item) => {
        setSelectedMenu(item);
        setSelectedCate([]);
        menuName.update(item.menu_title);
        menuLink.update(item.link_url);
        parentCate.select(41);
    },[selectedMenu, menuName.state, menuLink.state]);

    const addCateClick = useCallback(() => {
        let idx = parentCate.state === 10 ? 0 : (parentCate.state === 20) ? 1 : 2 ;
        if(cateName.state && parentCate.state) {
            cateList[idx].childs.push({
                parent_category_seq: parentCate.state,
                category_name: cateName.state
            });

            cateName.reset();
            parentCate.reset();
        } else if(!parentCate.state) {
            showAlert("알림", "추가하려는 카테고리의 상위 카테고리를 선택해 주세요.")
        } else {
            showAlert("알림", "추가하려는 카테고리 명을 입력해 주세요.");
        }
        
    },[cateList, cateName.state, parentCate.state]);

    const addMenuClick = useCallback(() => {
        if(!menuName.state) {
            showAlert("알림", "메뉴명을 입력해 주세요.");
        } else if(!menuLink.state) {
            showAlert("알림", "메뉴에 연결할 URL을 입력해 주세요.");
        } else {
            menuList.push({
                menu_title: menuName.state,
                link_url: menuLink.state,
            });
        }

        menuName.reset();
        menuLink.reset();
        parentCate.reset();
    },[menuList.state, menuName.state, menuLink.state, parentCate.state]);
      
    const editMenuClick = (e) => {
        if(!e.target.value) return;
        let editList = menuList.map((item) => {
            return item.main_menu_seq === parseInt(e.target.value) ? {...item, menu_title: menuName.state, link_url: menuLink.state} : item
        })
        setMenuList(editList);

        menuName.reset();
        menuLink.reset();
        parentCate.reset();

        setSelectedCate([]);
        setSelectedMenu([]);
    };

    const editCateClick = (e) => {
        if(!e.target.value && !e.target.name) return;
        let idx = parseInt(e.target.name) === 10 ? 0 : (parseInt(e.target.name) === 20) ? 1 : 2 ;
        let findChild = cateList.map((item) => item.childs).map((cate, num) => {
            return num === idx ? cate.map((list) => { 
                return list.category_seq === parseInt(e.target.value) ? {...list, category_name: cateName.state, parent_category_seq: parentCate.state} : list
            }) : cate
        });
        let update = cateList.map((item, num) => {
            return num === idx ? {...item, childs: findChild[num]} : item;
        })
        setCateList(update);

        cateName.reset();
        parentCate.reset();

        setSelectedCate([]);
        setSelectedMenu([]);
    };

    const clickDelete = useCallback(() => {
      if(!selectedCate.category_seq && selectedMenu.main_menu_seq) {
            let list = [];
            let settingList = menuList.filter((item) => {
                return item.main_menu_seq !== selectedMenu.main_menu_seq
            })
            list.push(...delMenu, selectedMenu.main_menu_seq);
            setMenuList(settingList);
            setDelMenu(list);

            menuName.reset();
            menuLink.reset();
            parentCate.reset();

            setSelectedCate([]);
            setSelectedMenu([]);
        } else if(selectedCate.category_seq && !selectedMenu.main_menu_seq) {
            let list = [];
            list.push(...delCate, selectedCate.category_seq);
            setDelCate(list);
    
            let idx = parseInt(selectedCate.parent_category_seq) === 10 ? 0 : (parseInt(selectedCate.parent_category_seq) === 20) ? 1 : 2 ;
            let settingList = cateList.map((item) => item.childs).map((cate) => {
                return cate.filter((list) => {
                    return list.category_seq !== selectedCate.category_seq
                })
            })
            let update = cateList.map((item, num) => {
                return num === idx ? {...item, childs: settingList[num]} : item;
            })
            setCateList(update);
    
            cateName.reset();
            parentCate.reset();
    
            setSelectedCate([]);
            setSelectedMenu([]);
        } else {
            showAlert("알림", "삭제할 카테고리를 선택해 주세요.");
            return;
        } 
    },[selectedCate, selectedMenu, cateName.state, parentCate.state, menuName.state, menuLink.state, cateList, menuList, delCate, delMenu]);

    const onSaveClick = useCallback(() => {
        console.log(cateList, menuList, delCate, delMenu)
        showConfirm('알림', '사용자 화면에 반영하시겠습니까?', {
            confirmHandler: () => {
                requestSaveAll({
                    deleteCategories: delCate,
                    deleteMenus: delMenu,
                    categories: cateList,
                    menus: menuList,
                });
            },
        });
    }, [cateList, menuList, delCate, delMenu]);
    
    useEffect(() => {
        if(!selectedCate || !selectedMenu) {
            parentCate.reset();
            cateName.reset();
            menuName.reset();
            menuLink.reset();
        }
    },[parentCate.state, cateName.state, menuName.state, menuLink.state])


    useEffect(() => {
        function clickToOutside(e) {
            console.log(e.target.classList)
            if (!e.target.classList.contains('MuiTypography-root') 
            && !e.target.classList.contains('MuiListItemButton-root') 
            && !e.target.classList.contains('MuiButton-root')
            && !e.target.classList.contains('MuiOutlinedInput-input')
            && !e.target.classList.contains('MuiSelect-select')
            ) {
                setSelectedCate([]);
                setSelectedMenu([]);
                parentCate.reset();
                cateName.reset();
                menuName.reset();
                menuLink.reset();
            }
        }
        document.addEventListener("mousedown", clickToOutside);
        return  () => {
            document.removeEventListener("mousedown", clickToOutside);
        };
    },[listRef]);

    return (
        <>
        <DashboardNavbar title="카테고리 관리" />
        <LAGridContainer ref={listRef}>
            <LAGridItem wPercent={30} layouts={{ p: "1rem" }}>
                <LACard>
                    {cateList && cateList?.map((item, idx) => {
                        return (
                            <CategoryListView
                                headerTitle={item.parent_category_seq === 0 && item.category_name}
                                selectedIndex={selectedCate.category_seq}
                                child={item.childs}
                                handleListItemClick={onChangedCate}
                            />
                        );
                    })}
                    <LADivider pt={2}/>
                    {menuList && menuList?.map((item, idx) => {
                        return (
                            <CategoryLinkView
                                key={idx}
                                selectedIndex={selectedMenu}
                                child={item}
                                handleListItemClick={onChangedMenu}
                            />
                        );
                    })}
                    <LAGridContainer layouts={{ sx : { display: "flex", alignItems: "center", justifyContent: "space-between", pt: "0.8rem" }}}>
                        <LAGridContainer layouts={{ sx : { width: "40rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}}>
                            <LAButton wPercent={30} onClick={onMoveUp}>UP</LAButton>
                            <LAButton wPercent={30} onClick={onMoveDown}>DOWN</LAButton>
                            <LAButton wPercent={30} onClick={clickDelete}>삭제</LAButton>
                        </LAGridContainer>
                        <LAButton wPercent={100} layouts={{ pt: "0.8rem" }} onClick={onSaveClick}>저장(사용자 화면에 반영)</LAButton>
                    </LAGridContainer>
                    <LALabel fontSize="0.2rem" layouts={{ pt: "1.5rem"}}>* 신규 카테고리를 저장하지 않은 상태에서 순서 변경 시, 신규 카테고리가 삭제됩니다.</LALabel>
                </LACard>
                
            </LAGridItem>
            <LAGridItem wPercent={70} layouts={{ p: "1rem" }}>
                <LACard>
                    <LAProperty wNameWidth={10} name={(selectedCate.category_seq || selectedMenu.main_menu_seq) ? "카테고리 수정 : " : "카테고리 등록 : "} layouts={{ sx: { display: "flex", justifyContent: "center", alignItems: "center" }}}>
                        <LAGridContainer>
                            <LAComboBox
                                wPercent={20}
                                layouts={{ sx: { mr: "0.5rem", ml: "1rem" }}}
                                items={parentCateItem}
                                value={parentCate.state}
                                onChange={parentCate.handleChange}
                                readOnly={(selectedCate.category_seq || selectedMenu.main_menu_seq) ? true : false}
                                disabled={(selectedCate.category_seq || selectedMenu.main_menu_seq) ? true : false}
                            />
                            {parentCate.state !== 41 ? (
                                <>
                                    <LAInput wPercent={65} placeholder="카테고리 명 입력" value={cateName.state} onChange={cateName.handleChange} />
                                    <LAButton wPercent={5} layouts={{ sx: { ml: "1rem"} }} props={{ value: selectedCate.category_seq || '', name: selectedCate.parent_category_seq || '' }} onClick={selectedCate.category_seq ? editCateClick : addCateClick}>{selectedCate.category_seq ? '수정' : '추가'}</LAButton>
                                </>
                            ) : (
                                <>
                                    <LAGridItem wPercent={65}>
                                        <LAInput placeholder="메뉴 명 입력" value={menuName.state} onChange={menuName.handleChange} />
                                        <LAInput layouts={{ sx: { pt: "0.1rem" }}} placeholder="URL 입력" value={menuLink.state} onChange={menuLink.handleChange} />
                                    </LAGridItem>
                                    <LAButton wPercent={5} layouts={{ sx: { ml: "1rem"} }} props={{ value: selectedMenu.main_menu_seq || '' }} onClick={selectedMenu.main_menu_seq ? editMenuClick : addMenuClick}>{selectedMenu.main_menu_seq ? '수정' : '추가'}</LAButton>
                                </>
                            )}
                        </LAGridContainer>
                    </LAProperty>
                </LACard>
            </LAGridItem>
        </LAGridContainer>
        </>
    );
}

export default CategoryManagement;
