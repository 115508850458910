import { useEffect, useMemo, useCallback } from 'react';
import { useMutation } from 'react-query';
import { useSpinnerContext } from 'common/components/spinner/context/SpinnerContext';

// POST, PUT, PATCH, DELETE
export function useCommonMutation({ query, callbacks = {} }) {
  const { action } = useSpinnerContext();
  const { showLoading, hideLoading } = action;

  const mutation = useMutation((params) => query.queryFn(params), callbacks);
  const { mutateAsync, mutate } = mutation;

  const status = useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      isSuccess: mutation.isSuccess,
      isError: mutation.isError,
      isLoadingError: mutation.isLoadingError,
    };
  }, [
    mutation.isLoading,
    mutation.isSuccess,
    mutation.isError,
    mutation.isLoadingError,
  ]);

  const { isLoading } = mutation;

  useEffect(() => {
    if (isLoading) showLoading();
    else hideLoading();
  }, [isLoading, showLoading, hideLoading]);

  const requestAsync = useCallback(
    async (params) => {
      return await mutateAsync(params);
    },
    [mutateAsync],
  );

  const request = useCallback(
    (params) => {
      return mutate(params);
    },
    [mutate],
  );

  return { request, status, requestAsync };
}

export default useCommonMutation;
