import React, { useState, useCallback, useReducer, useMemo } from 'react';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'UPDATE':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

const emptyData = {
  name: '',
  file: undefined,
  info: {},
};

function useFileAttachment(maxKByteSize = 2048, initialData = emptyData) {
  const [state, dispatch] = useReducer(reducer, initialData);
  const { showAlert } = usePopAlert();

  const update = useCallback(
    (value) => {
      if (!state || !value || state.name === value.name) {
        return;
      }

      dispatch({
        type: 'UPDATE',
        value,
      });
    },
    [state],
  );

  const handleChange = useCallback(
    (e) => {

      if (e.target.files.length === 0) {
        return;
      }
      if (e.target.files[0].size / 1024 > maxKByteSize) {
        showAlert(
          '알림',
          `${parseInt(maxKByteSize / 1024)}MB 이하의 파일만 선택 가능합니다.`,
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = function (event) {
        update({
          name: e.target.value,
          file: event.target.result,
          info: e.target.files[0],
        });
        e.target.value = '';
      };
      reader.readAsDataURL(e.target.files[0]);
    },
    [update],
  );

  const reset = useCallback(
    (isEmpty = false) => {
      dispatch({
        type: 'RESET',
        initialData: isEmpty ? emptyData : initialData,
      });
    },
    [initialData],
  );

  return {
    state,
    handleChange,
    update,
    reset,
  };
}

export default useFileAttachment;
