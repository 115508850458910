
import React, { useCallback , useEffect, useState } from "react";
import { useNavigate,useLocation, Link } from "react-router-dom";
import BasicLayout from "pages/Common/BasicLayout";
import { useApiContext } from 'common/context/ApiContext';
import { useLoginContext } from 'common/context/MemberContext';
import useCommonMutation from "common/hooks/useCommonMutation";

import KakaoLogin from "react-kakao-login";
import { AppConfig } from "api/AppConfig";
import kakaoImage from '../../assets/images/kakao_login_medium_narrow.png'
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LAWarningLabel,
  LAButton,
} from 'modules/Widgets';

function Basic() {
  const { queries } = useApiContext();
  // const { Member } = store();
  const {isLogin,hasAccessToken,loginState,action} = useLoginContext()
  const navigate = useNavigate();
  const { showAlert } = usePopAlert();

  useEffect(() => {
    if(isLogin){      
      navigate('/together');
    } else if(isLogin) {
      navigate('/');
    }
    return () => {
    }
    
  },[isLogin, navigate])

  const {requestAsync:authAsync} = useCommonMutation({
    query : queries.Auth.authorize,
    callbacks:{
      onSuccess: (data) => {
        try{
          if (data.result_code === "0000") {
            
            if(data.result_data.auth == undefined){
              action.setAccessToken('')
              localStorage.removeItem("accessToken");
              // sessionStorage.removeItem("accessToken");
              showAlert('알림', '라이브올 선생님으로 등록, 승인된 회원이 아닙니다.\n승인되었으나 로그인이 되지 않을 경우\n홈페이지 채널톡을 통해 문의해 주세요.', {
                confirmHandler: (() => {navigate('/');}),
              });
            }else{
              action.setLoginData(data.result_data);
              window.ChannelIO(
                'boot',
                {
                    pluginKey: "86d9261d-0a09-4a51-9d3a-edb61d9e105e",
                  customLauncherSelector: '.chat-icon-wrap',
                  hideChannelButtonOnBoot: true,
                  language: 'ko',
                  memberId: `${data.result_data.member_seq}`, //fill with user id
                  profile: {
                    name: `${
                      data.result_data.member_name
                        ? data.result_data.member_name
                        : ''
                    }`, //fill with user name
                    mobileNumber: `${
                      data.result_data.profile&&data.result_data.profile.phone
                        ? data.result_data.profile.phone.replaceAll('+82 ', '0')
                        : ''
                    }`,
                    email: `${
                      data.result_data.profile&&data.result_data.profile.email
                        ? data.result_data.profile.email
                        : ''
                    }`,
                  },
                },
                function onBoot(error, user) {
                  if (error) {
                    console.error(error);
                  } else {
                  }
                },
              );

              navigate('/');  
            }
          } else if (data.result_code === "1010") { //접속권한이 없습니다.
            showAlert('알림', data.result_message)          
            action.setAccessToken('')
            localStorage.removeItem("accessToken");
            // sessionStorage.removeItem("accessToken");
            navigate('/');
          } else {
            if(data.result_message)
              showAlert('알림', data.result_message)       
            // navigate('/auth');  
            
          }
        } catch (e) {
            console.error('로그인 실패 =>',e);
            showAlert('알림', '로그인에 실패하였습니다.')
          }
        
      },
      onError: (error) => {
      },
    }
  },[action,loginState]);

  const {requestAsync:kakaoLoginAsync} = useCommonMutation({
    query : queries.Auth.kakaoLogin,
    callbacks:{
      onSuccess: (data) => {

        if (data.result_code === "0000") {
          try {          
            const {accessToken , isNew} = data.result_data
            // action.setLoginData(data.result_data)
            action.setAccessToken(accessToken)
            localStorage.setItem("accessToken", accessToken);
            // sessionStorage.setItem("accessToken", accessToken);

            // if(isNew){

            // }
            authAsync()
          } catch (e) {
            console.error('로그인 실패 =>',e);
            showAlert('알림', '로그인에 실패하였습니다.')
          }      
        } else {
          showAlert('알림', data.result_message)
        }
      },
      onError: (error) => {
      },
    }
  },[action,navigate]);

  const kakaoResponse = ((res) => {

    kakaoLoginAsync({profile:res.profile,access_token:res.response.access_token})
    // this.setState({
    //   id: res.profile.id,
    //   nickName: res.profile.properties.nickName
    // });
  });
  const kakaoFail = ((err) => {
    console.error('kakaoFail => ',err);
    // this.setState({
    //   id: res.profile.id,
    //   nickName: res.profile.properties.nickName
    // });
  });

  const onManagerLoginClick = useCallback(() => {
    navigate('/auth/admin');
  });



  return (
    <BasicLayout>
      <LACard>
        <LACardTitle title="LiveAll Admin" />
        <LAProperty name="선생님 로그인" pt={3} pb={1}>
          <LAGridContainer wPercent={30}>
            <KakaoLogin 
              token={AppConfig.KAKAO_JAVASCRIPT_KEY}
              onSuccess={kakaoResponse}
              onFail={kakaoFail}
              getProfile={true}>
                <img src={kakaoImage} alt="Kakao" />
            </KakaoLogin>
          </LAGridContainer>
        </LAProperty>
        <LAProperty name=" " pt={1} pb={2}>
          <LAWarningLabel pt={3}>
            선생님은 카카오를 통해서만 로그인 가능하며, 사전에 등록 신청 후 승인을 받은 사용자만 로그인 가능합니다.
          </LAWarningLabel>
        </LAProperty>
        <LAProperty name="관리자 로그인" pt={3} pb={2}>
          <LAGridContainer>
            <LAButton 
              wPercent={37}
              onClick={onManagerLoginClick}>
              이동
            </LAButton>
          </LAGridContainer>
        </LAProperty>
      </LACard>
    </BasicLayout>
  );
}

export default Basic;
