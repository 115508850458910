import React, { useState, useCallback } from 'react';

import LAGridItem from './LAGridItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import pxToRem from 'assets/theme/functions/pxToRem';

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: pxToRem(14),
}));

function LATabSelector({
  value,
  onChange,
  items,
  layouts = {},
  wPercent = 100,
}) {
  if(!value) value = 0;

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <Tabs value={value} onChange={onChange}>
        {!!items &&
          items.map((item) => (
            <StyledTab key={item.key} value={item.key} label={item.value} />
          ))}
      </Tabs>
    </LAGridItem>
  );
}
export default React.memo(LATabSelector);
