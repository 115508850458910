import { METHOD } from "common/hooks/useAxios";

const baseURL = "/admin/accounts";

const AccountApi = (requestFunc) => {
  return {
    Account: {
      accounts: {
        key: "accounts",
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      registAccount: {
        key: "registAccount",
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
      },
      checkLoginId: {
        key: "checkLoginId",
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/checkId`, params),
      },
      accountInfo: {
        key: "accountInfo",
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/${params.member_seq}`),
      },
      updateAccount: {
        key: "updateAccount",
        queryFn: (params) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${params.member_seq}`, params),
      },
      deleteAccount: {
        key: "deleteAccount",
        queryFn: (params) =>
          requestFunc(METHOD.DELETE, `${baseURL}/${params.member_seq}`),
      },
      initAccountPassword: {
        key: "initAccountPassword",
        queryFn: (params) =>
          requestFunc(
            METHOD.PATCH,
            `${baseURL}/${params.member_seq}/initPassword`
          ),
      },
      masters: {
        key: "masters",
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/authtype/masters`),
      },
    },
  };
};

export default AccountApi;
