

import { useEffect, useState ,useMemo, useCallback} from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// examples
import SidenavCollapse from "modules/Sidenav/SidenavCollapse";
import SidenavList from "modules/Sidenav/SidenavList";
import SidenavItem from "modules/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "modules/Sidenav/SidenavRoot";

//로그인 컨텍스트
import { useLoginContext } from 'common/context/MemberContext';

// context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

import { LADivider } from 'modules/Widgets';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

// const AdminType = {
//   Master : {code:1,name:'마스터',group:'ADMIN_TYPE'},
//   WoongjinManager : {code:2,name:'웅진관리자',group:'ADMIN_TYPE',isDisplay:true},
//   ConsultManager : {code:3,name:'CS관리자',group:'ADMIN_TYPE',isDisplay:true},
//   Manager : {code:4,name:'일반관리자',group:'ADMIN_TYPE',isDisplay:true},
  
// }

function Sidenav({ color, brand, brandName1, brandName2, routes, togetherRoutes, ...rest }) {
  
  //로그인 컨텍스트
  const {isLogin,loginState,action} = useLoginContext()

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const navigate = useNavigate();

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const authMemberType = useMemo(()=>{
    return action.getMemberType()
  },[action])
  const authType = useMemo(()=>{
    return action.getAuthType()
  },[action])
  
  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // navigate('/');
  },[]);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);

  }, [ collapseName,itemParentName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);

    
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  // 하위 메뉴
  const renderNestedCollapse = (collapse) => {
    // 권한에 따른 처리
    const template = collapse.filter(m => {
      if (isLogin) {
        if (m.auth) {
          return m.auth.some((ms) => {
            if (authMemberType === 1)
              return ms.memberType === 1 && ms.authType >= authType;          
            else if (authMemberType === 2)
              return ms.memberType === 2 //&& ms.authType === authType            
            else return false;
          });
        } else return true;
      } else if (!m.auth) return true;
      else return false;
    }).map(({ icon, name, route, key, href}) =>
    // const template = collapse.map(({ icon, name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          // target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem 
            icon={icon} 
            name={name} 
            nested />
        </Link>
      ) : ( // depth 3 menu in LiveAll
        <NavLink 
          to={route} 
          key={key} 
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem
            icon={icon} 
            name={name} 
            active={pathname.indexOf(route)>-1} 
            nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  // 최상단 메뉴
  const renderCollapse = (collapses) =>

    collapses.filter(m => {
      if (isLogin) {
        if (m.auth) {
          return m.auth.some((ms) => {
            
            if (authMemberType === 1)
              return ms.memberType === 1 && ms.authType >= authType;          
            else if (authMemberType === 2)
              return ms.memberType === 2 //&& ms.authType === authType            
            else return false;
          });
        } else return true;
      } else if (!m.auth) return true;
      else return false;
    }).map(({ icon, name, collapse, route, href, key }) => {
      // collapses.map(({ icon, name, collapse, route, href, key }) => {
      let returnValue;
      if (collapse) { // depth 2 menu with collapse in LiveAll
        returnValue = (
          <SidenavItem
            icon={icon}
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            // target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem 
              icon={icon}
              color={color} 
              name={name} 
              active={key === itemName} />
          </Link>
        ) : ( // depth 2 menu in LiveAll
          <NavLink 
            to={route} 
            key={key} 
            sx={{ textDecoration: "none" }}>
            <SidenavItem 
              icon={icon}
              color={color} 
              name={name} 
              active={pathname.indexOf(route)>-1} />
          </NavLink>
        );
      }

      return <SidenavList key={key}>{returnValue}</SidenavList>;
  });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  // const renderRoutes = routes.map(
  //   최상단 메뉴
    const renderRoutes = routes.filter(m => {
      if (isLogin) {
        if(m.key === 'sign-in') 
          return false
        if(m.key === 'teacher-join' && authMemberType && authType)
          return false;
        if (m.auth) {
          return m.auth.some((ms) => {
            if (authMemberType === 1)
              return ms.memberType === 1 && ms.authType >= authType;          
            else if (authMemberType === 2)
              return ms.memberType === 2 //&& ms.authType === authType            
            else return false;
          });
        } else {
          return true;
        }
      }else{
        if(m.key === 'teacher-join') return false
        else if (!m.auth) return true;      
        else return false;
      }
      // } else if (!m.auth) return true;      
      // else return false;
    })
    // const renderRoutes = routes
    .map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              // target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) { // depth 1 menu with noCollapse in LiveAll
          returnValue = (
            <NavLink 
              to={route} 
              key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={pathname.indexOf(route)>-1}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else { // depth 1 menu in LiveAll
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    });

    const renderTogetherRoutes = togetherRoutes.filter(m => {
      if (isLogin) {
        if(m.key === 'sign-in') 
          return false
        if(m.key === 'teacher-join' && authMemberType && authType)
          return false;
        if (m.auth) {
          return m.auth.some((ms) => {
            if (authMemberType === 1)
              return ms.memberType === 1 && ms.authType >= authType;          
            else if (authMemberType === 2)
              return ms.memberType === 2 //&& ms.authType === authType            
            else return false;
          });
        } else {
          return true;
        }
      }else{
        if(m.key === 'teacher-join') return false
        else if (!m.auth) return true;      
        else return false;
      }
      // } else if (!m.auth) return true;      
      // else return false;
    })
    // const renderRoutes = routes
    .map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              // target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) { // depth 1 menu with noCollapse in LiveAll
          returnValue = (
            <NavLink 
              to={route} 
              key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={pathname.indexOf(route)>-1}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else { // depth 1 menu in LiveAll
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    });


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center" display="flex" flexDirection="column" alignItems="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
        <MDBox width="100%" display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" margin="10px 0">
          <ToggleButton 
            value="liveall"
            sx={{ 
              border: "none",
              color: !pathname.includes("together") ? "#2f99f4" : "#ffffff",
              fontWeight: "bold",
              whiteSpace:"nowrap",
              display: "flex",
              alignItems: "center",

            }}
            onClick={() => navigate('/')}
          >
            {brandName1}
          </ToggleButton>
          <MDBox width="1px" height="18px" backgroundColor="rgba(255, 255, 255, 0.35) !important"  />
          <ToggleButton 
            value="together"
            sx={{ 
              border: "none",
              color: pathname.includes("together") ? "#2f99f4" : "#ffffff",
              fontWeight: "bold",
              whiteSpace:"nowrap",
              display: "flex",
              alignItems: "center",

            }}
            onClick={() => navigate('/together')}
          >
            &nbsp;{brandName2}&nbsp;
          </ToggleButton>
        </MDBox>
      </MDBox>
      {/* <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      /> */}
      <List sx={{ display: !pathname.includes("together") ? "" : "none"}}>{renderRoutes}</List>
      <List sx={{ display: pathname.includes("together") ? "" : "none"}}>{renderTogetherRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName1: PropTypes.string.isRequired,
  brandName2: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
