import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import chat from 'assets/images/chat.png';

// ScrollTopButton.js

function ScrollTopButton() {
	const [visible, setVisible] = useState(false);
	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300){
		  setVisible(true)
		} 
		else if (scrolled <= 300){
		  setVisible(false)
		}
	  };

	const scrollToTop = () =>{
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		});
	};
	window.addEventListener('scroll', toggleVisible);

  return (
    <div className="footer-navi-icon" 	>
      <Box className="chat-icon-wrap">
        <img src={chat} />
      </Box>
    </div>
  );
}

export default React.memo(ScrollTopButton);
