import { METHOD } from "common/hooks/useAxios";

const baseURL = "/admin/teachers";

const TeacherApi = (requestFunc) => {
  return {
    Teachers: {
      teachers: {
        key: "teachers",
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      registTeacher: {
        key: "registTeacher",
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
      },
      teacherSchedules: {
        key: "teacherSchedules",
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/${params.teacher_seq}/schedules`),
      },
      teacherInfo: {
        key: "teacherInfo",
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/${params.teacher_seq}`),
      },
      updateTeacher: {
        key: "updateTeacher",
        queryFn: (params) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${params.teacher_seq}`, params),
      },
      deleteTeacher: {
        key: "deleteTeacher",
        queryFn: (params) =>
          requestFunc(METHOD.DELETE, `${baseURL}/${params.teacher_seq}`),
      },
      getSupportList: {
        key: "getSupportList",
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}-applications`, params),
      },
      getSupportDetail: {
        key: "getSupportDetail",
        queryFn: (applicationSeq) =>
          requestFunc(METHOD.GET, `${baseURL}-applications/${applicationSeq}`),
      },
      approve: {
        key: "approveSupport",
        queryFn: (applicationSeq) =>
          requestFunc(
            METHOD.PATCH,
            `${baseURL}-applications/${applicationSeq}/approve`,
            applicationSeq
          ),
      },
      reject: {
        key: "rejectSupport",
        queryFn: (params) =>
          requestFunc(
            METHOD.PATCH,
            `${baseURL}-applications/${params.applicationSeq}/reject`,
            params
          ),
      },
      addKeyword: {
        key: "addTeacherKeyword",
        queryFn: (params) =>
          requestFunc(
            METHOD.POST,
            `${baseURL}/${params.teacher_seq}/keywords`,
            params
          ),
      },
      removeKeywordBySeq: {
        key: "removeTeacherKeywordBySeq",
        queryFn: (params) =>
          requestFunc(
            METHOD.DELETE,
            `${baseURL}/${params.teacher_seq}/keywords/${params.keyword_seq}`,
            params
          ),
      },
      removeKeyword: {
        key: "removeTeacherKeyword",
        queryFn: (params) =>
          requestFunc(
            METHOD.DELETE,
            `${baseURL}/${params.teacher_seq}/keywords`,
            params
          ),
      },
      changeThumbnailImage: {
        key: "changeTeacherThumbnailImage",
        queryFn: (params) =>
          requestFunc(
            METHOD.POST,
            `${baseURL}/${params.teacher_seq}/thumbnail`,
            params.data
          ),
      },
    },
  };
};

export default TeacherApi;
