import React, { useState } from 'react';
import LAGridItem from './LAGridItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

const Placeholder = styled('div')({
  color: '#aaaaaa',
});

function LAComboBox({
  placeholder = '선택',
  value = '',
  onChange,
  items,
  layouts = {},
  wPercent = 100,
  readOnly = false,
  disabled = false,
}) {
  // const disableProp = useState(null);
  // const readOnlyProp = useState(null);
  // const disableProp = disabled? 'disabled' : null;
  // const readOnlyProp = readOnly? 'readOnly' : null;

  // disableProp.setDisableProp(disabled? 'disabled' : null);
  // readOnlyProp.setReadOnlyProp(readOnly? 'readOnly' : null);
  
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <FormControl fullWidth size="small">
        <Select
          value={value}
          // onChange={onChange}
          readOnly={ readOnly? 'readOnly' : null }
          disabled={ disabled? 'disabled' : null }
          sx={{ fontSize: 25 }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={
            //value? () => <Placeholder>{placeholder}</Placeholder> : undefined
            value !== ''
              ? undefined
              : () => <Placeholder>{placeholder}</Placeholder>
          }
        >
          {!!items &&
            items.map((item) => (
              <MenuItem
                key={item.key}
                value={item.key}
                onClick={() => onChange(item)}
              >
                {item.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </LAGridItem>
  );
}

export default React.memo(LAComboBox);
