import React, { useState, useEffect, useRef, useCallback } from 'react';

import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useParams } from "react-router-dom";
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
// import { useLoginContext } from 'common/context/MemberContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LAGridContainer,
  LAGridItem,
  LAButton,
  LATextEditor,
} from 'modules/Widgets';

function TermsManagement() {
  const { termsType } = useParams();

  const getEditorValue = useRef();

  // const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const [terms, setTerms] = useState({});

  const { request: requestTerms } = useCommonQuery({
    query: queries.Terms.getTerms,
    params: {
      terms_type: termsType,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // updateTerms(data.result_data);
          setTerms(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '약관 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  let { request: requestUpdateTerms } = useCommonMutation({
    query: queries.Terms.updateTerms,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '약관 내용이 변경되었습니다.');
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '약관 내용 변경에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    requestTerms();
  }, [requestTerms, termsType]);

  const onClickSave = useCallback(() => {
    showConfirm(
      '알림',
      '약관 내용을 저장하시겠습니까?\n프론트에 즉시 반영됩니다.',
      {
        confirmHandler: () => {
          requestUpdateTerms(
            {
              terms_type: termsType,
              terms_seq: terms?terms.terms_seq:'',
              terms_html: (getEditorValue.current?getEditorValue.current():''),
            }
          );
        },
      },
    );
  }, [
    terms,
    showConfirm,
    requestUpdateTerms,
    termsType
  ]);

  return  (
    <>
      <DashboardNavbar  title={
        termsType==1?'서비스 이용약관':termsType==2?'개인정보 처리방침':termsType==3?'환불규정':termsType==4?'선생님 서비스 이용약관':''
      }/>
      <LACard>
        {
          terms&&terms.terms_html
          ?
          (
            <LATextEditor height="500"  getEditorValue={getEditorValue} value={terms.terms_html}/>
          )
          :
          (
            <></>
          )
        }
        <LAGridContainer pt={2}>
          <LAGridItem wPercent={90}> </LAGridItem>
          <LAGridItem wPercent={10}>
            <LAButton onClick={onClickSave}>저장</LAButton>
          </LAGridItem>
        </LAGridContainer>
      </LACard>
    </>
  );  

}

export default TermsManagement;
