import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import MdSearchModal from '../components/MdSearchModal';
import TeacherSearchModal from '../components/TeacherSearchModal';
import TextbookSearchModal from '../components/TextbookSearchModal';
import ArrowForward from '@mui/icons-material/ArrowForward';

import MDBox from 'components/MDBox';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import { useImageContext } from 'common/context/CodeContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import { useKeywordContext } from 'common/context/CodeContext';

import { toCurrency } from 'common/utils';

import CategoryComboView from '../components/CategoryComboView';
import OneDayScheduleList from '../components/OneDayScheduleList';
import ClassScheduleDetailModal from '../components/ClassScheduleDetailModal';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import EmptyImage from 'assets/images/empty.png';

import {
    LACard,
    LACardTitle,
    LAGridContainer,
    LAGridItem,
    LAProperty,
    LALabel,
    LACodeRadioGroup,
    LARadioGroup,
    LAButton,
    LAComboBox,
    LAInput,
    LAWarningLabel,
    LATextEditor,
    LAKeywords,
    LADeletableListView,
    LASimpleTable,
    LAImageList,
    LAFileAttachment,
    LACheckbox,
    useFileAttachment,
    useRadioGroup,
    useComboBox,
    useInput,
    useModal,
    useModalPopup,
    useKeywords,
    useCheckbox,
    LAImage
} from 'modules/Widgets';

import PackageScheduleList from '../components/PackageScheduleList';
import ScheduleDetail from '../components/ScheduleDetail';
import ClassScheduleModal from '../components/ClassScheduleModal';
import moment from 'moment';


function ClassDetailContents({ classSeq, onClickList }) {
    const { action } = useLoginContext();

    const { defaultClassImages } = useImageContext();
    const navigate = useNavigate();

    const { showAlert, showConfirm } = usePopAlert();

    const { queries } = useApiContext();

    const [data, setData] = useState(null);

    const [md, setMd] = useState({});
    const [teacher, setTeacher] = useState({});

    // const [teacherSearchShow, setTeacherSearchShow] = useState(false);
    const [classScheduleShow, setClassScheduleShow] = useState(false);
    const [classScheduleAddShow, setClassScheduleAddShow] = useState(false);
    // const [textbookSearchShow, setTextbookSearchShow] = useState(false);

    // const [approveDialogShow , setApproveDialogShow] = useState=(false);
    // const [approveWithExposure, setApproveWithExposure] = useState=(false);
    const isTestMode = useCheckbox(false);
    const category = useComboBox(0);

    const className = useInput('');
    const classSummary = useInput('');
    const [clubPoint, setClubPoint] = useState(20);

    const classDuration = useComboBox(30);

    const allAgeYn = useRadioGroup(1);

    const minTargetAge = useComboBox(1);
    const maxTargetAge = useComboBox(1);
    const minUserCount = useComboBox(1);
    const maxUserCount = useComboBox(1);

    const favoriteCount = useInput(0); // useComboBox(0)

    const [schedules, setSchedule] = useState([]);
    const classPrice = useInput(0);
    const classDiscountPrice = useInput(0);

    const [curriculumSeq, setCurriculumSeq] = useState([0]);
    const [curriculumName, setCurriculumName] = useState(['']);
    const [price, setPrice] = useState([0]);
    const [discountPrice, setDiscountPrice] = useState([0]);

    const discountType = useRadioGroup(0);

    const [newSchedules, setNewSchedules] = useState([]);
    const [addedSchedules, setAddedSchedules] = useState([]);

    const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
    const thumbnailType = useRadioGroup(4);
    const thumbnailFile = useFileAttachment(2048, { name: '' });
    const additionalFile = useFileAttachment(2048);
    const [additionalImages, setAdditionalImages] = useState([]);

    const [deletedAdditionalImages, setDeletedAdditionalImages] = useState([]);
    const [addedAdditionalImages, setAddedAdditionalImages] = useState([]);

    const classFile = useFileAttachment(100 * 1024);
    const [savedClassFiles, setSavedClassFiles] = useState([]);
    const [classFiles, setClassFiles] = useState([]);
    const [classFileInfos, setClassFileInfos] = useState([]);

    const [deletedClassFiles, setDeletedClassFiles] = useState([]);
    const [addedClassFiles, setAddedClassFiles] = useState([]);

    const [textbookDeliveryType, setTextbookDeliveryType] = useState(0); // 0: 없음, 1: 웅진씽크빅, 2: 선생님 개별 교재
    const [deleteOptions, setDeleteOptions] = useState([]);
    const [deleteAppendixs, setDeleteAppendixs] = useState([]);
    const [textbooks, setTextbooks] = useState([]); //교재(최대3개)
    const [srcTextbooks, setSrcTextbooks] = useState([]); //교재(최대3개)
    const [updatedTextbooks, setUpdatedTextbooks] = useState([]);
    const [deletedTextbooks, setDeletedTextbooks] = useState([]);
    const [addedTextbooks, setAddedTextbooks] = useState([]);

    const included = useInput(''); //준비물
    const materials = useInput(''); //준비물

    const { keywordList } = useKeywordContext();
    const keywordsSelector = useKeywords([]);
    const keywordInput = useInput('');
    const [selectedKeywords, setSelectedKeywords] = useState([]);

    const [deletedKeywords, setDeletedKeywords] = useState([]);
    const [addedKeywords, setAddedKeywords] = useState([]);

    const [activateState, setActivateState] = useState(false);

    // const [exposureState,setExposureState] = useState(false);

    const [currentSchedule, setCurrentSchedule] = useState(undefined);
    // const [bookclubPointMaxRate, setBookclubPointMaxRate] = useState(20);
    const [miraePointRate, setMiraePointRate] = useState(20); // 미래본부 클럽 포인트
    const [gyomunMileageRate, setGyomunMileageRate] = useState(20); // 교문본부 클럽 마일리지
    const [onlineMileageRate, setOnlineMileageRate] = useState(20); // 온라인본부 클럽 마일리지

    const getInformEditorValue = useRef();
    const getCurriculumEditorValue = useRef();
    const getNoticeEditorValue = useRef();

    const teacherSearchModal = useModal(false);
    const textbookSearchModal = useModal(false);
    const scheduleDetailModal = useModal(false);
    const mdSearchModal = useModal(false);

    const authMemberType = useMemo(() => {
        return action.getMemberType();
    }, [action]);

    const authAdminAuthType = useMemo(() => {
        return action.getAuthType();
    }, [action]);

    const userCounts = useMemo(() => {
        let list = [];
        for (let i = 1; i < 61; i++) {
            list.push({ key: i, value: `${i}명` });
        }
        list.push({key: 100, value: '100명'});
        list.push({key: 200, value: '200명'});
        list.push({key: 300, value: '300명'});
        list.push({key: 400, value: '400명'});
        list.push({key: 500, value: '500명'});
        list.push({key: 600, value: '600명'});
        list.push({key: 700, value: '700명'});
        list.push({key: 800, value: '800명'});
        list.push({key: 900, value: '900명'});
        list.push({key: 1000, value: '1000명'});
        return list;
    }, []);

    const targetAgeItems = useMemo(() => {
        var list = [];
        for (let i = 1; i < 20; i++) {
            list.push({ key: i, value: `${i}세` });
        }
        return list;
    }, []);

    const classDurationItems = useMemo(() => {
        var list = [];
        for (let i = 2; i <= 72; i++) {
            list.push({ key: i * 5, value: `${i * 5}분` });
        }
        return list;
    }, []);

    const handleNameChange = useCallback((e, i) => {
        setCurriculumName((prev) => prev.map((data, index) => (index === i ? e.target.value : data)));
    }, []);

    const handlePriceChange = useCallback((e, i) => {
        let value = 0;
        if (e.target.value.length > 0) {
            value = parseInt(e.target.value);
        }

        e.target.value = toCurrency(value);
        setPrice((prev) => prev.map((data, index) => (index === i ? value : data)));
        setDiscountPrice((prev) => prev.map((data, index) => (index === i ? value : data)));
    }, []);

    const handleDiscountPriceChange = useCallback((e, i) => {
        let value = 0;
        if (e.target.value.length > 0) {
            value = parseInt(e.target.value);
        }

        if (value > price[i]) {
            value = price[i];
        }

        e.target.value = toCurrency(value);
        setDiscountPrice((prev) => prev.map((data, index) => (index === i ? value : data)));
    }, [price]);

    const handleTextbookDiscountPriceChange = useCallback((e, materialNo) => {
        setTextbooks((prev) =>
            prev.map((item) => {
                if( item.sap_material_code == materialNo ) {
                    if (item.dataState == 'N') {
                        item.dataState = 'U';
                    }
                    item.discount_price = parseInt(e.target.value);
                }
                return item;
            })
        );
    }, []);

    const handleTextbookDeliveryFeeChange = useCallback((e, materialNo) => {
        setTextbooks((prev) =>
            prev.map((item) => {
                if (item.sap_material_code == materialNo) {
                    if (item.dataState == 'N') {
                        item.dataState = 'U';
                    }

                    item.delivery_price(parseInt(e.target.value));
                }

                return item;
            }),
        );
    }, []);

    const handleTextbookNameChange = useCallback((e, i) => {
        let ctxTextbook = textbooks[i];
        if (ctxTextbook.dataState == 'N') {
            ctxTextbook.dataState = 'U';
        }
        ctxTextbook.appendix_name = e.target.value;
        setTextbooks([...textbooks]);
    }, [textbooks]);

    const getDiscountRate = useCallback((srcPrice, discountPrice) => {
        if (isNaN(srcPrice) || isNaN(discountPrice) || srcPrice <= 0 || discountPrice < 0 || srcPrice < discountPrice) {
            return 0;
        }
        if (srcPrice == discountPrice) {
            return 100;
        }
        let diffPrice = srcPrice - discountPrice;
        return ((diffPrice / srcPrice) * 100).toFixed(0);
    }, []);

    const getClassPriceRows = useCallback(() => {
        const result = [];

        curriculumName.forEach((name, index) => {
            result.push([
                { text: `${index + 1}회차` },
                {
                    text: '',
                    type: 'input',
                    placeholder: '커리큘럼 이름을 입력해주세요.',
                    callback: {
                        value: name,
                        onChange: (e) => handleNameChange(e, index),
                    },
                },
                {
                    text: '',
                    type: 'input',
                    placeholder: '정상가를 입력해주세요.',
                    callback: {
                        value: price[index],
                        type: 'number',
                        onChange: (e) => handlePriceChange(e, index),
                    },
                },
                {
                    text: '',
                    type: 'input',
                    placeholder: '판매가를 입력해주세요.',
                    callback: {
                        value: discountPrice[index],
                        type: 'number',
                        onChange: (e) => handleDiscountPriceChange(e, index),
                    },
                },
                {
                    text: `${(!price[index] || !discountPrice[index] || price[index] == 0 || discountPrice[index] == 0 || discountPrice[index] > price[index]) 
                        ? 0 : Math.round((1.0 - discountPrice[index] / price[index]) * 100)}%`
                },
            ]);
        });
        return result;
    }, [curriculumName, price, discountPrice]);

    const priceTableData = useMemo(() => {
        return {
            headers: [
                { text: '회차' },
                { text: '이름' },
                { text: '정상가' },
                { text: '판매가' },
                { text: '할인율' }
            ],
            wPercentList: [15, 30, 20, 20, 15],
            rows: getClassPriceRows(),
        };
    }, [getClassPriceRows]);


    const getRateRows = useCallback(() => {
        const result = [];

        result.push([
            { text: `클럽 포인트` },
            {
                text: '%',
                type: 'rate',
                style: {
                    margin: "2.4px 5px 0 0",
                    width: "29.5%",
                },
                callback: {
                    name: 'M',
                    value: miraePointRate,
                    type: 'number',
                    onChange: (e) => numberHandle(e)
                },
            },
            { text: "-" },
            { text: "-" },
        ],
        [
            { text: `클럽 마일리지` },
            { text: "-" },
            {
                text: '%',
                type: 'rate',
                style: {
                    margin: "2.4px 5px 0 0",
                    width: "29.5%",
                },
                callback: {
                    name: 'G',
                    value: gyomunMileageRate,
                    type: 'number',
                    onChange: (e) => numberHandle(e)
                },
            },
            {
                text: '%',
                type: 'rate',
                style: {
                    margin: "2.4px 0 0 5px",
                    width: "29.5%",
                },
                callback: {
                    name: 'O',
                    value: onlineMileageRate,
                    type: 'number',
                    onChange: (e) => numberHandle(e)
                },
            },
        ]);

        return result;        
    },[miraePointRate, gyomunMileageRate, onlineMileageRate]);

    const rateTableData = useMemo(() => {
        return {
            headers: [
                { text: '구분' },
                { text: '미래본부' },
                { text: '교문본부' },
                { text: '온라인본부' },
            ],
            wPercentList: [10, 30, 30, 30],
            rows: getRateRows(),
        };
    }, [getRateRows]);

    const getTextbookRows = useCallback(() => {
        const result = [];

        if (!textbooks || textbooks.length == 0) {
            return result;
        }

        if (textbookDeliveryType == 1) {
            textbooks.map((item, index) => {
                result.push([
                    { text: item.option_name },
                    { text: item.price?.toLocaleString() },
                    {
                        text: '',
                        type: 'input',
                        placeholder: '할인가를 입력해주세요.',
                        callback: {
                            value: item.discount_price,
                            type: 'number',
                            onChange: (e) => { handleTextbookDiscountPriceChange(e, item.sap_material_code); }
                        },
                    },
                    { text: `${getDiscountRate(item.price, item.discount_price)}%` },
                    {
                        text: '',
                        type: 'input',
                        placeholder: '배송비를 입력해주세요.',
                        disabled: true,
                        callback: {
                            value: item.delivery_price,
                            type: 'number',
                            onChange: (e) => {
                                handleTextbookDeliveryFeeChange(e, item.sap_material_code);
                            }
                        },
                    },
                    {
                        text: '삭제',
                        type: 'button',
                        callback: {
                            onClick: () => onTextbookDelete(index, item.option_seq),
                        },
                    },
                ]);
            });
        } else {
            textbooks.map((item, index) => {
                console.log('item.', item);
                result.push([
                    {
                        text: '',
                        type: 'input',
                        placeholder: '교재(상품)명을 입력해주세요',
                        callback: {
                            value: item.appendix_name,
                            type: 'text',
                            onChange: (e) => handleTextbookNameChange(e, index),
                        },
                    },
                    {
                        text: '삭제',
                        type: 'button',
                        callback: {
                            onClick: () => onTextbookDelete(index),
                        },
                    },
                ]);
            });
        }

        return result;
    }, [textbooks]);

    const textbookTableData = useMemo(() => {
        let tableDef = null;

        if (textbookDeliveryType == 1) {
            tableDef = {
                headers: [
                    { text: '제목' },
                    { text: '정상가' },
                    { text: '할인가' },
                    { text: '할인율' },
                    { text: '배송비' },
                    { text: '삭제' }
                ],
                wPercentList: [30, 15, 15, 15, 15, 10],
                rows: getTextbookRows(),
            };
        } else {
            tableDef = {
                headers: [
                    { text: '교재(상품)명' },
                    { text: '삭제' }
                ],
                wPercentList: [90, 10],
                rows: getTextbookRows(),
            };
        }
        return tableDef;
    }, [getTextbookRows]);

    // const totalPrice = useMemo(() =>{
    //   if(schedules && schedules.length > 0)
    //     // return schedules.reduce((s, o) => s.subject_price + o.subject_price)
    //     return schedules.reduce((acc,item) => acc + item.subject_price,0)
    //   else return 0
    // },[schedules])

    const discountRate = useMemo(() => {
        if (classPrice.state > 0 && classDiscountPrice.state > 0) {
            let diffPrice = classPrice.state - classDiscountPrice.state;
            if (diffPrice > 0) return ((diffPrice / classPrice.state) * 100).toFixed(0);
            else return 0;
        } else {
            return 0;
        }
    }, [classPrice.state, classDiscountPrice.state]);

    const onChangeClassDiscountPrice = useCallback((e) => {

        // if(parseInt(classPrice.state) < parseInt(e.target.value)){
        //   alert('정상가보다 할인가가 클수 없습니다.')
        //   return
        // }
        discountPrice.handleChange(e);
    }, [classPrice.state, discountPrice]);

    // 일정 추가 버튼 클릭
    const onScheduleAddClick = useCallback(() => {
        // 선생님이 지정되어 있지 않으면 일정 추가 불가
        if (data.teacher_seq || teacher.member_seq) {
            setClassScheduleAddShow(true);
        } else {
            showAlert('알림', '선생님을 먼저 지정하세요.');
        }
    }, [data, teacher]);

    const onThumbnailChange = useCallback((e) => {
        if (e.target.value == 4) {
                if (!thumbnailFile.state.file) {
                thumbnailFile.reset(true);
                }

            // setThumbnailImageUrl('');
        } else {
            setThumbnailImageUrl(defaultClassImages[e.target.value].file_url);
        }

        thumbnailType.handleChange(e);
    }, [thumbnailFile, thumbnailType.handleChange]);

    useEffect(() => {
        if (additionalFile.state.name.length === 0) {
            return;
        }

        setAdditionalImages((prev) => [...prev, additionalFile.state]);
        setAddedAdditionalImages((prev) => [...prev, additionalFile.state]);
    }, [additionalFile.state]);

    const onRemoveAdditionalImage = useCallback((imageIndex, image_seq) => {
        let newAdditionalImages = [];
        let imageName;
        additionalImages.forEach((image, index) => {
            if (imageIndex != index) {
                newAdditionalImages.push(image);
            } else {
                imageName = image.name;
            }
        });
        setAdditionalImages(newAdditionalImages);

        if (image_seq) {
            setDeletedAdditionalImages((prev) => [...prev, image_seq]);
        } else {
            let newAddedAdditionalImages = addedAdditionalImages.filter((data, index) => data.name != imageName);
            setAddedAdditionalImages(newAddedAdditionalImages);
        }
    }, [additionalImages]);

    const onThumbnailFileChange = useCallback((e) => {
            setDeletedAdditionalImages((prev) => [...prev, thumbnailFile.state.image_seq]);
            thumbnailFile.handleChange(e);
    }, [thumbnailFile]);

    const onAdditionalImageChange = useCallback((e) => {
        if (additionalImages.length >= 10) {
            showAlert('알림', '최대 10개까지 등록 가능합니다.');
            return;
        }
        additionalFile.handleChange(e);
    }, [additionalImages]);

    const navigateBack = useCallback(() => {
        navigate(-1, { replace: true });
    }, []);

    const onDeleteClick = useCallback(() =>{
        showConfirm('확인', `클래스 [${data.class_name}]를 삭제하시겠습니까?\n결제 이력이 있는 경우 경우 실제로 삭제되지 않고,비공개 처리 됩니다.`,
            {
                confirmHandler: () => {
                    deleteClass(classSeq)
                }
            }
        );
    })

    const onSaveClick = useCallback(() => {
        let thumbnailImages = [];

        let thumbnailImageFile = {};
        let additionalImageFiles = [];

        let deletedThumbnailImages = deletedAdditionalImages.map((data) => data);

        if (thumbnailType.state == 4) {
            if (thumbnailImageUrl != thumbnailFile.state.file) {
                // thumbnailImages.push(thumbnailFile.state.info);
                thumbnailImageFile = thumbnailFile.state.info;
                setThumbnailImageUrl('');
            }
        } else {
            if (thumbnailFile.state.image_seq) {
                deletedThumbnailImages.push(thumbnailFile.state.image_seq);
            }
        }

        addedAdditionalImages.forEach((data) => {
            // thumbnailImages.push(data.info);
            additionalImageFiles.push(data.info);
        });

        // 삭제될 자료
        // if (deletedClassFiles.length > 0){

        // }
        const materialFiles = addedClassFiles.map((data) => data.info);
        const classFileInfosSize = classFileInfos.reduce((acc, item) => acc + item.size, 0);

        if ((classFileInfosSize / (1024 * 1024)).toFixed(2) > 100) {
            showAlert('알림', '전체 용량 100MB까지 첨부 가능합니다.');
            return;
        }

        const curriculums = price.map((data, index) => {
            return {
                curriculum_seq: curriculumSeq[index],
                curriculum_name: curriculumName[index],
                price: data,
                discount_price: discountPrice[index]
            };
        });

        // 일정 추가 화면에서 받은 최근 일정만 반영하게 되어있어서 최근 일정만 추가되는 문제
        // let tempSchedules = JSON.parse(JSON.stringify(addedSchedules));
        // tempSchedules.forEach((schedule) => {
        //   schedule.dates = schedule.dates.sort();
        // });
        // const schedulesData = tempSchedules.map((schedule) =>
        // schedule.dates.map((date) => {
        //   return {
        //     schedule_date: date.replaceAll('-', ''),
        //     schedule_time: schedule.startTime.replaceAll(':', ''),
        //     duration_min: classDuration.state,
        //   };
        // }),
        // );

        // 일정 추가 화면에서 여러번 등록한 모든 일정을 추가
        let tempSchedules = JSON.parse(JSON.stringify(newSchedules));
        const schedulesData = tempSchedules
            .filter((schedule) => schedule.schedule_seq == 0)
            .map((schedule) =>
                schedule.timetables.map((timetables) => {
                    return {
                        schedule_date: timetables.schedule_date,
                        schedule_time: timetables.schedule_time,
                        duration_min: classDuration.state,
                    };
                }),
            );

        // .map((data) => {
        //   return {
        //     ...data,
        //     option_price: data.price
        //   };
        // });
        if (!md.member_seq) {
            showAlert('알림', '담당 MD를 선택해 주세요.');
            return;
        }

        if (!className?.state || className.state.length === 0) {
            showAlert('알림', '클래스 명을 입력해주세요.');
            return;
        }

        if (!classSummary?.state || classSummary.state.length === 0) {
            showAlert('알림', '클래스 요약을 입력해주세요');
            return;
        }

        let valid = true;
        curriculumName.forEach((data) => {
            valid = data.length === 0 ? false : valid;
        });

        if (!valid) {
            showAlert('알림', '커리큘럼 이름을 입력해주세요.');
            return;
        }

        valid = true;
        price.forEach((data) => {
            /*`23.06.21 이벤트 클래스 0원 강좌를 위해 해당 로직 임시 통과처리*/
            valid = data === 0 ? valid : valid;
        });

        if (!valid) {
            showAlert('알림', '정상가를 입력해주세요.');
            return;
        }

        valid = true;
        discountPrice.forEach((data) => {
            /*`23.06.21 이벤트 클래스 0원 강좌를 위해 해당 로직 임시 통과처리*/
            valid = data === 0 ? valid : valid;
        });

        if (!valid) {
            showAlert('알림', '판매가를 입력해주세요.');
            return;
        }

        if (thumbnailType.state == 4 && (!thumbnailFile?.state?.name || thumbnailFile.state.name.length === 0)) {
            showAlert('알림', '클래스 이미지를 선택해주세요.');
            return;
        }

        if (selectedKeywords.length === 0) {
            showAlert('알림', '키워드를 선택해주세요.');
            return;
        }

        const formData = new FormData();

        formData.append('class_seq', data.class_seq);
        formData.append('category_seq', category.state);
        formData.append('admin_seq', md ? md.member_seq : undefined);
        formData.append('teacher_seq', teacher && teacher.member_seq ? teacher.member_seq : data.teacher_seq);
        formData.append('class_name', className.state);
        formData.append('class_description', classSummary.state);
        formData.append('class_time', classDuration.state);
        // formData.append('class_type', classType.state);

        // formData.append('brand_type', 1);
        formData.append('min_user_count', minUserCount.state);
        formData.append('max_user_count', maxUserCount.state);
        formData.append('all_age_yn', allAgeYn.state);
        formData.append('min_target_age', minTargetAge.state);
        formData.append('max_target_age', maxTargetAge.state);
        formData.append('inform_html', getInformEditorValue.current ? getInformEditorValue.current() : null);
        // formData.append('inform_html', getInformEditorValue.current());
        formData.append('curriculum_html', getCurriculumEditorValue.current ? getCurriculumEditorValue.current() : null);
        formData.append('notice_html', getNoticeEditorValue.current ? getNoticeEditorValue.current() : null);
        formData.append('discount_type', discountType.state);
        // for(const file of thumbnailImages){
        //   formData.append('image_files',file)
        // }
        if (thumbnailImageFile) {
            formData.append('thumbnail_image_file', thumbnailImageFile);
        }
        for (const file of additionalImageFiles) {
            formData.append('image_files', file);
        }

        for (const file of materialFiles) {
            formData.append('material_files', file);
        }

        formData.append('included', included.state);
        formData.append('favorite_count', favoriteCount.state);
        formData.append('materials', materials.state);
        formData.append('curriculums', JSON.stringify(curriculums));
        formData.append('add_keywords', JSON.stringify(addedKeywords));

        formData.append('add_schedules', JSON.stringify(schedulesData));
        formData.append('test_mode_yn', isTestMode.state?1:0);

        formData.append('bookclub_point_max_rate', miraePointRate);
        formData.append('gm_mileage_max_rate', gyomunMileageRate);
        formData.append('online_mileage_max_rate', onlineMileageRate);

        if (deletedThumbnailImages.length > 0) formData.append('delete_image_files', JSON.stringify(deletedThumbnailImages));
        if (deletedClassFiles.length > 0) formData.append('delete_material_files', JSON.stringify(deletedClassFiles));
        formData.append('textbook_delivery_type', textbookDeliveryType);

        if (textbookDeliveryType == 1) {
            let addList = [];
            let updateList = [];
            for (let i = 0; i < textbooks.length; i++) {
                let ctxItem = textbooks[i];

                switch (ctxItem.dataState) {
                    case 'I':
                        addList.push(ctxItem);
                        break;
                    case 'U':
                        updateList.push(ctxItem);
                        break;
                }
            }

            formData.append('update_options', JSON.stringify(updateList));
            formData.append('add_options', JSON.stringify(addList));
            formData.append('delete_options', JSON.stringify(deleteOptions));
        } else if (textbookDeliveryType == 2) {
            let addList = [];
            let updateList = [];
            for (let i = 0; i < textbooks.length; i++) {
                let ctxItem = textbooks[i];

                switch (ctxItem.dataState) {
                    case 'I':
                        addList.push(ctxItem);
                        break;
                    case 'U':
                        updateList.push(ctxItem);
                        break;
                }
            }

            formData.append('add_appendixs', JSON.stringify(addList));
            formData.append('update_appendixs', JSON.stringify(updateList));
            formData.append('delete_appendixs', JSON.stringify(deleteAppendixs));
        }

        if (deletedKeywords.length > 0) formData.append('delete_keywords', JSON.stringify(deletedKeywords));

        if (thumbnailImageUrl) formData.append('thumbnail_image_url', thumbnailImageUrl);


        showConfirm('알림', '변경된 클래스 정보를 저장하시겠습니까?', {
            confirmHandler: () => modifyClass({ class_seq: data.class_seq, data: formData })
        });

        // modifyClass({
        //   class_seq: data.class_seq,
        //   category_seq: category.state,
        //   teacher_seq: teacher && teacher.member_seq ? teacher.member_seq : data.teacher_seq,
        //   class_name: className.state,
        //   class_description: classSummary.state,
        //   class_time : classDuration.state,
        //   min_user_count: minUserCount.state,
        //   max_user_count: maxUserCount.state,
        //   all_age_yn: Boolean(allAgeYn.state),
        //   min_target_age: minTargetAge.state,
        //   max_target_age: maxTargetAge.state,
        //   discount_type: discountType.state,
        //   curriculums: curriculums,
        //   inform_html: getInformEditorValue.current?getInformEditorValue.current():'',
        //   curriculum_html: getCurriculumEditorValue.current?getCurriculumEditorValue.current():'',
        //   notice_html: getNoticeEditorValue.current?getNoticeEditorValue.current():'',
        //   included : included.state,
        //   materials : materials.state,
        //   favorite_count : favoriteCount.state,
        //   // options: options,
        //   // keywords: selectedKeywords,
        // });
    }, [
        category.state,
        md,
        teacher,
        className.state,
        classSummary.state,
        classDuration.state,
        minUserCount.state,
        maxUserCount.state,
        allAgeYn.state,
        minTargetAge.state,
        maxTargetAge.state,
        discountType.state,
        included.state,
        materials.state,
        curriculumSeq,
        curriculumName,
        price,
        discountPrice,
        favoriteCount.state,
        thumbnailFile.state,
        thumbnailImageUrl,
        addedSchedules,
        addedAdditionalImages,
        addedClassFiles,
        textbooks,
        addedTextbooks,
        addedKeywords,
        deletedAdditionalImages,
        deletedClassFiles,
        deletedTextbooks,
        deleteOptions,
        deletedKeywords,
        classFileInfos,
        isTestMode.state,
        miraePointRate,
        gyomunMileageRate,
        onlineMileageRate,
        getInformEditorValue,
        getCurriculumEditorValue,
        getNoticeEditorValue
    ]);

    const onClassCopyClick = useCallback(() => {
        showConfirm('알림', '클래스를 전체 복사하시겠습니까?', {
            confirmHandler: () => {
                copyClass({ class_seq: data.class_seq });
            },
        });
    }, [data]);

    useEffect(() => {
        requestDetail();
    }, []);

    // 데이터 세팅 !!!!!
    useEffect(() => {
        if (data) {
            console.log(data)
            category.select(data.category_seq);

            className.update(data.class_name);
            classSummary.update(data.class_description);

            // classType.update(data.class_type);

            classDuration.select(data.class_time);

            minUserCount.select(data.min_user_count);
            maxUserCount.select(data.max_user_count);

            allAgeYn.update(data.all_age_yn);

            minTargetAge.select(data.min_target_age);
            maxTargetAge.select(data.max_target_age);

            setTextbookDeliveryType(data.textbook_delivery_type);

            // setBookclubPointMaxRate(data.bookclub_point_max_rate);
            setMiraePointRate(data.bookclub_point_max_rate);
            setGyomunMileageRate(data.gm_mileage_max_rate);
            setOnlineMileageRate(data.online_mileage_max_rate);

            // setCurriculums(data.curriculums);
            let _curriSeq = [];
            let _curriNames = [];
            let _prices = [];
            let _discountPrices = [];
            data.curriculums.forEach((curri) => {
                _curriSeq.push(curri.curriculum_seq);
                _curriNames.push(curri.curriculum_name);
                _prices.push(curri.price);
                _discountPrices.push(curri.discount_price);
            });

            setCurriculumSeq(_curriSeq);
            setCurriculumName(_curriNames);
            setPrice(_prices);
            setDiscountPrice(_discountPrices);

            classPrice.update(data.price);
            classDiscountPrice.update(data.discount_price);
            discountType.update(data.discount_type ? data.discount_type : 0);

            included.update(data.included);
            materials.update(data.materials);

            favoriteCount.update(data.favorite_count ? data.favorite_count : 0);
            isTestMode.reset(false);
            if(data.test_mode_yn){
                isTestMode.toggle();
            }
                
            setActivateState(data.activate_yn);

            let newSch = data.schedules.map((schedule) => schedule);
        // .sort((a, b) => {
        //     const t1 = moment(a.date + ' ' + a.startTime);
        //     const t2 = moment(b.date + ' ' + b.startTime);
        //     if (t1.isSame(t2)) return 0;
        //     if (t2.isAfter(t1)) return -1;
        //     return 1;
        //   });
            setNewSchedules(newSch);

            //thumbnailType
        defaultClassImages.forEach((image, index) => {
            if (image.file_url == data.thumbnail_image_url) {
                thumbnailType.update(index);
            }
        });

            let newThumbnailFile;

            let newAdditionalImages = [];
            data.thumbnail_images.forEach((image) => {
                // if(image.file_url != data.thumbnail_image_url) {
                newAdditionalImages.push({
                    image_seq: image.image_seq,
                    file: image.file_url,
                    name: image.file_original_name,
                });
                // } else {
                //   newThumbnailFile = {
                //     image_seq:image.image_seq,
                //     file: image.file_url,
                //     name:image.file_original_name,
                //   }
                // }
            });

            let defaultImage = defaultClassImages.find(
                (di) => di.file_url == data.thumbnail_image_url,
            );
            if (defaultImage == undefined) {
                newThumbnailFile = {
                    image_seq: -1,
                    file: data.thumbnail_image_url,
                    name: data.thumbnail_image_url,
                };
            }
            thumbnailFile.update({});

            if (newThumbnailFile) thumbnailFile.update(newThumbnailFile);

            if (newAdditionalImages) setAdditionalImages(newAdditionalImages);

            let newKeywords = [];
            data.keywords.forEach((keyword) => {
                newKeywords.push({
                    seq: keyword.keyword_seq,
                    value: keyword.keyword,
                });
            });
            setSelectedKeywords(newKeywords);

            let newClassFileInfos = [];
            let newSavedClassFiles = [];
            data.material_files.forEach((file) => {
                newClassFileInfos.push({
                    seq: `${file.material_file_seq}`,
                    value: `${file.file_original_name}`,
                    size: file.file_size,
                });
                newSavedClassFiles.push({
                    file_seq: file.material_file_seq,
                    file_url: file.file_url,
                    file_name: file.file_original_name,
                    size: file.size,
                });
            });

            setClassFileInfos(newClassFileInfos);
            setSavedClassFiles(newSavedClassFiles);

            if (data.textbook_delivery_type == 1) {
                let options = data.options.map((item) => {
                    item.dataState = 'N';
                    return item;
                });

                setTextbooks(options);
            }
            if (data.textbook_delivery_type == 2) {
                let appendixes = data.appendixs.map((item) => {
                    item.dataState = 'N';
                    return item;
                });

                setTextbooks(appendixes);
            }
            setSrcTextbooks(data.options);

            setDeletedAdditionalImages([]);
            setAddedAdditionalImages([]);
            setDeletedClassFiles([]);
            setAddedClassFiles([]);
            setDeletedTextbooks([]);
            setAddedTextbooks([]);
            setDeletedKeywords([]);
            setAddedKeywords([]);
        }
    }, [data, defaultClassImages]);

    const { result: resultData, request: requestDetail } = useCommonQuery({
        query: queries.Class.getDetail,
        params: classSeq,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setData(data.result_data);

                    let {admin_name: adminName, admin_seq: adminSeq} = data.result_data;
                    if (adminSeq > 0) {
                        setMd({
                            member_seq : adminSeq,
                            member_name : adminName
                        });
                    }
                } else {
                    showAlert('알림', data.result_message, {
                        confirmHandler: () => navigateBack(),
                    });
                }
            },
            onError: (error) => {
            },
        },
        initEnabled: false,
    });

    const { request: requestScheduleDetail } = useCommonQuery({
        query: queries.Schedule.getDetail,
        params: currentSchedule?.schedule_seq,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setNewSchedules((prev) =>
                        prev.map((item) =>
                            item.schedule_seq === data.result_data.schedule_seq
                                ? {
                                    ...item,
                                    start_dt: data.result_data.start_dt,
                                    end_dt: data.result_data.end_dt,
                                    close_yn: data.result_data.close_yn,
                                    confirmation_yn: data.result_data.confirmation_yn,
                                    reception_status: data.result_data.reception_status,
                                    finish_yn: data.result_data.finish_yn,
                                    timetables: data.result_data.timetables,
                                }
                                : item,
                        ),
                    );
                }
            },
        },
        initEnabled: false,
    });

  // useEffect(() => {
  //   console.log('requestDetail -> isLoading : ', resultData.isLoading);
  //   // if(resultData.isLoading) {

  //   // }

  // }, [resultData]);

  //   const {requestAsync:modifyAsync} = useCommonMutation({
  //     query:queries.Class.modify,
  //     callbacks:{
  //       onSuccess: (data) => {
  //         if (data.result_code === '0000') {
  //           console.log(data.result_data);
  //           requestDetail();
  //         } else {
  //           console.log(data.result_message);
  //         }
  //       },
  //       onError: (error) => {
  //       },
  //     }
  //   },[requestDetail]);

    const { request: requestActivate } = useCommonMutation({
        query: queries.Class.changeActivate,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setActivateState(data.result_data.activate_yn);
                    // setExposureState(data.result_data.activate_yn)
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '클래스 노출 처리에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    const { request: modifyClass } = useCommonMutation({
        query: queries.Class.modifyAll,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    // showAlert('알림', '클래스가 수정되었습니다.', {
                    //     confirmHandler: () => requestDetail(),
                    // });

                    showAlert('알림', '클래스가 수정되었습니다.');
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '클래스 수정에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const { request: deleteClass } = useCommonMutation({
        query: queries.Class.delete,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '클래스가 삭제되었습니다.', {
                        confirmHandler: () => onClickList(),
                    });
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '클래스 삭제에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const { request: copyClass } = useCommonMutation({
        query: queries.Class.copyAll,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '클래스가 복사되었습니다.', {
                        confirmHandler: () =>
                        navigate(`/class/list/detail/${data.result_data.class_seq}`),
                    });
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '클래스 복사에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const { request: deleteSchedule } = useCommonMutation({
        query: queries.Schedule.deleteSchedule,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '일정이 삭제되었습니다.', {
                        confirmHandler: () => requestDetail(),
                    });
                } else {
                    showAlert('알림', `새로 추가된 일정은 삭제가 불가합니다.\n (저장하지 않거나, 저장 후 삭제를 요청해 주세요.)`);
                }
            },
            onError: (error) => {
                showAlert('알림', '일정 삭제에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const onActivate = useCallback(() => {
        if (activateState.state && !data.teacher_seq && !teacher.member_seq) {
            showAlert('알림', '노출로 변경 시 선생님을 지정해야 합니다.');
            return;
        }

        let changeState = '노출';
        if (activateState) {
            changeState = '미노출';
        }

        showConfirm('확인', `노출 상태를 "${changeState}"로 변경하시겠습니까?`, {
            confirmHandler: () => {
                requestActivate({
                class_seq: data.class_seq,
                activate_yn: !activateState,
                });
            },
        });
    }, [data, teacher, activateState]);

    const onTeacherSelected = useCallback((newVal) => {
        teacherSearchModal.handleClose();
        setTeacher(newVal);
    }, [teacherSearchModal.handleClose]);

    const onScheduleChange = useCallback((e) => {
    }, []);

    const onScheduleAdd = useCallback((data) => {
        let newSch = newSchedules.map((schedule) => schedule);

        data.forEach((data) => {
            let timetables = [];

            data.dates.forEach((date) => {
                timetables.push({
                    schedule_date: date.replaceAll('-', ''),
                    timetable_seq: 0,
                    schedule_seq: 0,
                    schedule_time: data.startTime.replaceAll(':', ''),
                });
            });

            newSch.push({
                schedule_seq: 0,
                start_dt: data.dates.sort()[0].replaceAll('-', ''),
                end_dt: data.dates.reverse()[0].replaceAll('-', ''),
                timetables: timetables,
            });
        });

            setAddedSchedules(data);
            setNewSchedules(newSch);
    }, [newSchedules]);

    const onScheduleDetail = useCallback(
        (schedule) => {
        if (schedule.schedule_seq == 0) {
            showAlert('알림', '새로 추가된 일정은 수정이 불가능합니다.');
            return;
        }
        setCurrentSchedule(schedule);
        // setClassScheduleShow(true);
        scheduleDetailModal.visible(true);
        },
        [scheduleDetailModal],
    );

    const onScheduleDelete = useCallback((schedule) =>{
        if(schedule.application_count > 0){
        showAlert('알림', '수업 신청자가 있는 경우 삭제할 수 없습니다.');
        return
        }
        showConfirm(
        '확인',
        `[${moment(schedule.start_dt).format('YYYY-MM-DD')}]일정을 삭제하시겠습니까?\n결제 이력이 있는 경우 경우 삭제되지 않습니다.`,
        {
            confirmHandler: () => {
            deleteSchedule(schedule.schedule_seq)
            },
        },
        );
    })

    const onScheduleDetailClose = useCallback(
        (newTimetables) => {
        scheduleDetailModal.visible(false);
        requestScheduleDetail();
        // setCurrentSchedule(undefined);

        // setClassScheduleShow(false);
        // if (newTimetables?.length > 0) {
        //   setNewSchedules((prev) =>
        //     prev.map((item) =>
        //       item.schedule_seq === currentSchedule.schedule_seq
        //         ? {
        //             ...item,
        //             start_dt: newTimetables[0].schedule_date,
        //             end_dt: newTimetables[newTimetables.length - 1].schedule_date,
        //             timetables: newTimetables,
        //           }
        //         : item,
        //     ),
        //   );
        // }
    }, [currentSchedule]);

    const onKeywordListChange = useCallback((e) => {
        // keywordsSelector.handleClick(e);
        let checker = selectedKeywords.filter(
            (data) => e.target.value != data.value,
        );

        if (checker.length == selectedKeywords.length) {
            checker.push({
                value: e.target.value
            });
            setSelectedKeywords(checker);
            setAddedKeywords((prev) => [...prev, e.target.value]);
        }
    }, [keywordsSelector.state, selectedKeywords]);

    const onAddKeywordClick = useCallback(() => {
        if (!keywordInput.state || keywordInput.state.length < 1) {
            showAlert('알림', '키워드는 한자 이상 입력하세요.');
            return;
        }

        let checker = selectedKeywords.filter(
            (data) => keywordInput.state != data.value,
        );

        if (checker.length == selectedKeywords.length) {
            checker.push({ value: keywordInput.state });
            setSelectedKeywords(checker);
            setAddedKeywords((prev) => [...prev, keywordInput.state]);
            keywordInput.update('');
        }
    }, [keywordInput.state, selectedKeywords]);

    const onDeleteKeyword = useCallback((keywordIndex, seq) => {
        let result = [];
        let deletedWord = '';
        selectedKeywords.forEach((data, index) => {
            if (index != keywordIndex) result.push(data);
            else deletedWord = data.value;
        });

        setSelectedKeywords(result);
        if (seq) {
            setDeletedKeywords((prev) => [...prev, seq]);
        } else {
            let addResult = addedKeywords.filter(
            (data, index) => data != deletedWord,
            );
            setAddedKeywords(addResult);
        }
    }, [selectedKeywords, addedKeywords]);

    const onEnterKeyAtKeyword = useCallback(() => {
        onAddKeywordClick();
    }, []);

    useEffect(() => {
        if (classFile.state.name.length === 0) {
            return;
        }

        let isOverlap = false;
        classFileInfos.forEach((name) => {
            isOverlap = name === classFile.state.name ? true : isOverlap;
        });

        if (isOverlap) {
            return;
        }

        setClassFiles((prev) => [...prev, classFile.state]);
        setClassFileInfos((prev) => [...prev, { value: classFile.state.name, size: classFile.state.info.size }]);
        setAddedClassFiles((prev) => [...prev, classFile.state]);
    }, [classFile.state]);

    const onClassFileChange = useCallback((e) => {
        if (classFiles.length >= 5) {
            showAlert('알림', '최대 5개까지 등록 가능합니다.');
            return;
        }

        var classFileInfosSize = classFileInfos.reduce((acc, item) => acc + item.size, 0);
        classFileInfosSize += e.target.files[0].size;
        if ((classFileInfosSize / (1024 * 1024)).toFixed(2) > 100) {
            showAlert('알림', '전체 용량 100MB까지 첨부 가능합니다.');
            return;
        }

        classFile.handleChange(e);
    }, [classFiles, classFileInfos]);

    const onClassFileDelete = useCallback((fileIndex, seq) => {
        let result = [];
        let deletedFile;
        classFiles.forEach((data, index) => {
            if (index == fileIndex) {
                deletedFile = data;
            } else {
                result.push(data);
            }
        });

        setClassFiles(result);
        setClassFileInfos((prev) => prev.filter((file, index) => index !== fileIndex));

        if (seq) {
            setDeletedClassFiles((prev) => [...prev, seq]);
        } else {
            let addedResult = addedClassFiles.filter(
            (data, index) => data.name != deletedFile.name,
            );
            setAddedClassFiles(addedResult);
        }

    }, [classFiles, addedClassFiles]);

    const [tempSelectTextbook, setTempSelectTextbook] = useState({});
    const [btnDisable, setBtnDisable] = useState(false);

    useEffect(() => {
        if (!tempSelectTextbook || !tempSelectTextbook.material_no) {
            return;
        }

        let newTextbook = {
            dataState: 'I',
            delivery_price: tempSelectTextbook?.delivery_price,
            discount_price: tempSelectTextbook?.price,
            option_description: '',
            option_name: tempSelectTextbook?.material_name,
            price: tempSelectTextbook?.price,
            require_yn: 0,
            sap_material_code: tempSelectTextbook?.material_no,
        };

        setTextbooks([...textbooks, newTextbook])
        setTempSelectTextbook({});
    }, [tempSelectTextbook]);

    const addTextbook = useCallback((data) => {
        setTextbookDeliveryType(1);

        if (!data) {
            return;
        }

        if(textbooks.length > 0) {
            console.log(" TEXTBOOK DATA :: ", textbooks)
            return;
        }

        if (textbooks.find((item) => item.sap_material_code === data.material_no)) {
            return;
        }

        setTempSelectTextbook(data);
        textbookSearchModal.handleClose();
        setBtnDisable(true);
    }, [textbooks, textbookSearchModal.state, btnDisable]);

    const addAppendix = useCallback(() => {
        if (textbooks.length > 0) {
            showAlert('알림', '최대 1개까지 등록 가능합니다.');
            return;
        }
        setTextbookDeliveryType(2);

        let newTextbook = { 
            dataState: 'I', 
            appendix_name: '',
        };
        setBtnDisable(true);
        setTextbooks([...textbooks, newTextbook]);
    }, [textbooks, btnDisable]);

    const onTextbookDelete = useCallback((textbookIndex) => {
        let result = [];

        if (textbookDeliveryType == 1) {
            const { option_seq: ctxOptionSeq } = textbooks[textbookIndex];
            if (ctxOptionSeq) {
                setDeleteOptions([...deleteOptions, ctxOptionSeq]);
            }
        } else {
            // textbookDeliveryType = 2 일때
            const { appendix_seq: ctxAppendixSeq } = textbooks[textbookIndex];
            if (ctxAppendixSeq) {
                setDeleteAppendixs([...deleteAppendixs, ctxAppendixSeq]);
            }
        }

        result = textbooks.filter((item, $index) => $index != textbookIndex);
        setTextbooks(result);

        if (result.length == 0) {
            setTextbookDeliveryType(0);
        }
    }, [textbooks]);

    /* const mdSearchModalPopup = {
        state: useModalPopup(false, (data) => {
            setMd(data);
            mdSearchModalPopup.state.hide();
        }),
        show: (data) => {
            mdSearchModalPopup.state.show();
        }
    } */

/*    function mdSearchModalClick(data) { // 상세보기
        setMd(data);
        mdSearchModal.visible(true);
    }

    const mdSearchModalClose = useCallback(() => {
        mdSearchModal.handleClose();
    });*/
    const onMdSelected = useCallback(async (newVal) => {
        mdSearchModal.handleClose();
        await setMd(newVal);
    }, [mdSearchModal.handleClose]);

    const pointHandleChange = useCallback((e) => {
        let value = e.target.value;
        const maxLength = 100;
        setClubPoint(value);
    
        if(clubPoint >= maxLength) {
            setClubPoint(100);
        } else if(clubPoint <= 0) {
            setClubPoint(0);
        }
    });
  
    const numberHandle = useCallback((e) => {
        let currentInput = e.target.name;
        let currentNum = e.target.value;

        if(currentInput === "G") {
            if(!currentNum) {
                return setGyomunMileageRate(0);
            }
    
            setGyomunMileageRate(toCurrency(currentNum));
    
            if(currentNum > 100) {return setGyomunMileageRate(100)};
            if(currentNum < 0) {return setGyomunMileageRate(0)};

        } else if(currentInput === "O") {
            if(!currentNum) {
                return setOnlineMileageRate(0);
            }
    
            setOnlineMileageRate(toCurrency(currentNum));
    
            if(currentNum > 100) {return setOnlineMileageRate(100)};
            if(currentNum < 0) {return setOnlineMileageRate(0)};
        } else {
            if(!currentNum) {
                return setMiraePointRate(0);
            }
    
            setMiraePointRate(toCurrency(currentNum));
    
            if(currentNum > 100) {return setMiraePointRate(100)};
            if(currentNum < 0) {return setMiraePointRate(0)};
        }
    },[miraePointRate, gyomunMileageRate, onlineMileageRate]);

    /*console.log(" textbookDeliveryType === 1 ? ", textbookDeliveryType, textbookTableData.rows.length)
    console.log(textbookDeliveryType === 2 && textbookTableData.rows.length > 0)*/

    return (
        <>
            <LACard next>
                <LACardTitle title="클래스 기본 정보" />

                <LAProperty name="담당 MD" required>
                    <LAGridContainer>
                        {(md && md.member_name) && (<LALabel wPercent={8}>{md.member_name}</LALabel>)}
                        {/*<LAButton wPercent={15} onClick={mdSearchModalClick}>MD 검색</LAButton>*/}
                        <LAButton wPercent={15} onClick={() => mdSearchModal.visible(true)}>MD 검색</LAButton>
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="선생님">
                    <LAGridContainer>
                        <LALabel wPercent={8}>
                            {teacher && teacher.member_name
                                ? teacher.member_name
                                : data
                                ? data.teacher_name
                                ? data.teacher_name
                                : data.teacher_nickname
                                ? data.teacher_nickname
                                : ''
                                : ''}
                        </LALabel>
                        <LAButton wPercent={15} onClick={() => teacherSearchModal.visible(true)}>선생님 검색</LAButton>
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="클래스 코드">
                    <LALabel wPercent={8}>{data && data.class_seq}</LALabel>
                </LAProperty>

                <LAProperty name="카테고리">
                    <LAGridContainer>
                        {/* <CategoryComboView category={!!data && data.category_seq} onChange={onCategoryChanged}/>  */}
                        <CategoryComboView value={category.state} onChange={category.handleChange} />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="클래스 명">
                    <LAInput value={className.state} onChange={className.handleChange} />
                </LAProperty>

                <LAProperty name="클래스 요약">
                    <LAInput
                        value={classSummary.state}
                        onChange={classSummary.handleChange}
                    />
                </LAProperty>

                <LAProperty name="클래스 유형">
                    <LALabel>{!!data && data.class_type_name}</LALabel>
                </LAProperty>

                {data && data.class_type == 2 && (
                    <LAProperty name="클래스 회차">
                        <LALabel>{data && data.curriculums && data.curriculums.length} 회차</LALabel>
                    </LAProperty>
                )}

                <LAProperty name="클래스 시간">
                    <LAComboBox
                        wPercent={25}
                        items={classDurationItems}
                        value={classDuration.state}
                        onChange={classDuration.handleChange}
                    />
                </LAProperty>

                <LAProperty name="정원">
                    <LAGridContainer>
                        <LALabel wPercent={5}>최소</LALabel>
                        <LAComboBox
                            wPercent={20}
                            items={userCounts}
                            value={minUserCount.state}
                            onChange={minUserCount.handleChange}
                        />
                        <LALabel wPercent={5} layouts={{ sx: { textAlign: 'center' } }}>~</LALabel>
                        <LALabel wPercent={5}>최대</LALabel>
                        <LAComboBox
                            wPercent={20}
                            items={userCounts}
                            value={maxUserCount.state}
                            onChange={maxUserCount.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="대상 연령">
                    <LAGridContainer>
                        <LACodeRadioGroup
                            codeType="TARGET_AGE_QUERY_TYPE"
                            showAll={false}
                            value={allAgeYn.state}
                            onChange={allAgeYn.handleChange}
                        />

                        {allAgeYn.state === "0" && (
                            <LAGridContainer>
                                <LALabel wPercent={5}>최소</LALabel>
                                <LAComboBox
                                    wPercent={20}
                                    items={targetAgeItems}
                                    value={minTargetAge.state}
                                    onChange={minTargetAge.handleChange}
                                />
                                <LALabel wPercent={5} layouts={{ sx: { textAlign: 'center' } }}>~</LALabel>
                                <LALabel wPercent={5}>최대</LALabel>
                                <LAComboBox
                                    wPercent={20}
                                    items={targetAgeItems}
                                    value={maxTargetAge.state}
                                    onChange={maxTargetAge.handleChange}
                                />
                            </LAGridContainer>
                        )}
                    </LAGridContainer>
                </LAProperty>
            </LACard>

            {authMemberType == 1 && authAdminAuthType == 1 ? (
                <LACard next component="div">
                    <LACardTitle title="시크릿 모드(마스터 관리자 only)" />
                    <LAProperty name="시크릿 모드">
                        <LAGridContainer>
                            {/* <LAInput
                                wPercent={20}
                                value={favoriteCount.state}
                                onChange={favoriteCount.handleChange}
                            /> */}
                            <LACheckbox
                                label={'시크릿 클래스 설정'}
                                value={isTestMode.state}
                                onClick={isTestMode.handleClick}
                            />
                            <LAWarningLabel wPercent={80} layouts={{ pl: { sm: 5 } }}>마스터 관리자만 수정 가능합니다.(마스터외 미노출)</LAWarningLabel>
                        </LAGridContainer>
                    </LAProperty>
                </LACard>
            ) : ('')}

            <LACard next>
                <LACardTitle title="클래스 금액" />
                <LAProperty name="전체 금액">
                    <LAWarningLabel pt={2} layouts={{ pt: { sx: 1 } }}>할인율은 자동 계산되며 소수점인 경우, 버림으로 계산되어 프런트에 노출됩니다.</LAWarningLabel>
                    <LAGridContainer>
                        <LAGridItem wPercent={20}>
                            <LALabel layouts={{ sx: { textAlign: 'center' } }}>정상가</LALabel>
                            <LAInput
                                type="number"
                                placeholder="정상가"
                                name="price"
                                value={classPrice.state}
                                onChange={classPrice.handleChange}
                                layouts={{ sx: { textAlign: 'right' } }}
                                disabled={true}
                            />
                        </LAGridItem>
                        <LAGridItem pt={2} wPercent={15} layouts={{ sx: { textAlign: 'center' } }}>
                            <ArrowForward color="inherit" />
                            <LALabel>할인율 {discountRate}%</LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={20}>
                            <LALabel layouts={{ sx: { textAlign: 'center' } }}>할인가</LALabel>
                            <LAInput
                                type="number"
                                placeholder="할인가"
                                name="price"
                                value={classDiscountPrice.state}
                                onChange={onChangeClassDiscountPrice}
                                disabled={true}
                            />
                        </LAGridItem>
                    </LAGridContainer>
                    <LAGridContainer>
                        <LAGridItem wPercent={40}>
                            <LARadioGroup
                                props={[
                                    { text: '할인 없음', value: 0 },
                                    { text: '선생님 할인', value: 1 },
                                    { text: '라이브올 할인', value: 2 },
                                ]}
                                value={discountType.state}
                                onChange={discountType.handleChange}
                            />
                        </LAGridItem>
                        <LAWarningLabel>‘선생님 할인’ 선생님이 부담하여 할인 진행, ‘라이브올 할인’ 라이브올에서 부담하여 할인 진행하는 것을 의미합니다.</LAWarningLabel>
                        {/* <LAGridItem wPercent={58}>
                        <LAWarningLabel>
                            ‘선생님 할인’ 선생님이 부담하여 할인 진행, ‘라이브올 할인’
                            라이브올에서 부담하여 할인 진행하는 것을 의미합니다.
                        </LAWarningLabel>
                        </LAGridItem> */}
                    </LAGridContainer>
                </LAProperty>

                {/* 2023-08-18 마일리지 UI 추가 */}
                <LAProperty name="웅진 멤버십 사용">
                    <LASimpleTable tableData={rateTableData} />
                </LAProperty>

                <LAProperty name="클래스 금액">
                    <LAGridContainer pt={2}>
                        <LASimpleTable tableData={priceTableData} />
                    </LAGridContainer>
                </LAProperty>
            </LACard>
            <LACard next>
                {data && data.class_type == 1 ? (
                    <>
                        <LACardTitle
                            title="클래스 일정 정보(원데이)"
                            customUi={
                                authMemberType == 1 && authAdminAuthType == 1 ? (
                                    <LAGridContainer>
                                        <LAGridItem wPercent={79} />
                                        <LAButton wPercent={20} layouts={{ mt: { sm: 1 } }} onClick={onScheduleAddClick}>일정 추가</LAButton>
                                    </LAGridContainer>
                                ) : ('')
                        }
                        />
                        <OneDayScheduleList
                            schedules={newSchedules}
                            onChange={onScheduleChange}
                            onDetail={onScheduleDetail}
                            onDelete={onScheduleDelete}
                        />
                    </>
                ) : (
                    <>
                        <LACardTitle
                            title="클래스 일정 정보(다회차)"
                            customUi={
                                authMemberType == 1 && authAdminAuthType == 1 ? (
                                    <LAGridContainer>
                                        <LAGridItem wPercent={79} />
                                        <LAButton wPercent={20} layouts={{ mt: { sm: 1 } }} onClick={onScheduleAddClick}>일정 추가</LAButton>
                                    </LAGridContainer>
                                ) : ('')
                            }
                        />
                        <PackageScheduleList
                            schedules={newSchedules}
                            onChange={onScheduleChange}
                            onDetail={onScheduleDetail}
                            onDelete={onScheduleDelete}
                        />
                    </>
                )}
            </LACard>
            {/* 클래스 이미지 정보 */}
            <LACard next>
                <LACardTitle title="클래스 이미지 정보" />
                <LAProperty name="클래스 이미지 정보(섬네일)">
                    <LARadioGroup
                        props={[
                            { text: '기본 이미지(손)', value: 0 },
                            { text: '기본 이미지(우주)', value: 1 },
                            { text: '기본 이미지(무지개)', value: 2 },
                            { text: '기본 이미지(클래스)', value: 3 },
                            { text: '직접 등록', value: 4 }
                        ]}
                        value={thumbnailType.state}
                        onChange={onThumbnailChange}
                    />
                    <LAGridContainer>
                        <LAImage wPercent={30} file={thumbnailType.state != 4
                                    ? defaultClassImages[thumbnailType.state]
                                    ? defaultClassImages[thumbnailType.state].file_url
                                    : EmptyImage
                                    : thumbnailFile.state.file
                                    ? thumbnailFile.state.file
                                    : EmptyImage} />
                        {thumbnailType.state == 4 && (
                        <LAGridItem wPercent={60} layouts={{ ml: 2 }}>
                            <LAGridContainer layouts={{ ml: 1, pl: 1 }}>
                                <LAFileAttachment
                                    hiddenLabel
                                    placeholder=""
                                    props={{ accept: 'image/jpg,image/png,image/jpeg,image/gif' }}
                                    value={thumbnailFile.state.name}
                                    onChange={onThumbnailFileChange}
                                />
                            </LAGridContainer>
                            <MDBox color="text" fontSize="1rem" lineHeight={1}>
                                <LALabel
                                    layouts={{ pl: 1, pt: 1 }}
                                    props={{
                                        variant: 'caption',
                                        color: 'error',
                                        fontWeight: 'regular',
                                        verticalAlign: 'middle',
                                        sx: { color: '#ff0000' }
                                    }}
                                >
                                    - 이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로 등록됩니다. <br />
                                    - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가) <br />
                                    - 이미지 사이즈 : 1000 * 1000 / 형식 : jpg, jpeg, png (jpeg 등록 권장) <br />
                                    - 2MB 이하로 등록가능
                                </LALabel>
                            </MDBox>
                        </LAGridItem>
                        )}
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="추가 이미지">
                    <LAGridContainer>
                        <LAFileAttachment
                            wPercent={20}
                            hiddenLabel
                            hiddenInput
                            placeholder=""
                            props={{ accept: 'image/jpg,image/png,image/jpeg,image/gif' }}
                            value={additionalFile.state.name}
                            onChange={onAdditionalImageChange}
                        />

                        <LAWarningLabel
                            wPercent={80}
                            layouts={{ pl: 5 }}
                            props={{
                                variant: 'caption',
                                color: 'error',
                                fontWeight: 'regular',
                                verticalAlign: 'middle',
                                sx: {
                                fontSize: '1px',
                                color: '#ff0000',
                                },
                            }}
                        >
                        대표 이미지와 동일한 스펙으로 업로드 가능하며 최대 10개까지 등록 가능합니다.
                        </LAWarningLabel>
                    </LAGridContainer>
                    <LAImageList
                        images={additionalImages}
                        layouts={{ pt: 1 }}
                        onRemoveClick={onRemoveAdditionalImage}
                        showDelete
                    />
                </LAProperty>
            </LACard>

            {/* 클래스 소개 */}
            <LACard next>
                <LACardTitle title="클래스 소개" />

                <LAProperty name="클래스 소개">
                        <LATextEditor
                        name="inform"
                        value={data?.inform_html || ''}
                        getEditorValue={getInformEditorValue}
                        />
                </LAProperty>
            </LACard>

            <LACard next>
                <LACardTitle title="준비물 & 유의사항" />
                <LAProperty name="준비물 & 유의사항">
                        <LATextEditor
                        name="curriculum"
                        value={data?.curriculum_html || ''}
                        getEditorValue={getCurriculumEditorValue}
                        />

                </LAProperty>

                <LAProperty name="클래스에 포함되어 있어요">
                <LAInput
                    placeholder="클래스에 포함된 내역(ex. 교재)"
                    value={included.state}
                    onChange={included.handleChange}
                />
                </LAProperty>

                <LAProperty name="따로 챙겨주세요">
                <LAInput
                    placeholder="따로 챙겨야할 준비물 입력 (ex. 가위, 칼, 풀, 스케치북)"
                    value={materials.state}
                    onChange={materials.handleChange}
                />
                </LAProperty>

                <LAProperty name="클래스 전 꼭 확인해주세요">
                        <LATextEditor
                        name="notice"
                        value={data?.notice_html || ''}
                        getEditorValue={getNoticeEditorValue}
                        />
                </LAProperty>
            </LACard>

            <LACard next>
                <LACardTitle title="클래스 키워드" />

                <LAProperty name="키워드 선택">
                    <LAKeywords
                        keywords={keywordList}
                        selectedKeywords={keywordsSelector.state}
                        onClick={onKeywordListChange}
                    />
                </LAProperty>

                <LAProperty name="키워드 직접 입력">
                <LAGridContainer>
                    <LALabel wPercent={2}>#</LALabel>
                    <LAInput
                        wPercent={30}
                        placeholder="키워드 텍스트 입력 (ex. #라이브올 #섬세한 #재밌는 등)"
                        layouts={{ pr: { sm: 1 } }}
                        value={keywordInput.state}
                        onChange={keywordInput.handleChange}
                        onEnterKeyPressed={onEnterKeyAtKeyword}
                    />
                    <LAInput wPercent={1} props={{ sx: { display: 'none' } }} />
                    <LAButton wPercent={10} onClick={onAddKeywordClick}>추가</LAButton>
                </LAGridContainer>
                {selectedKeywords && selectedKeywords.length > 0 && (
                    <LAGridItem wPercent={30}>
                        <LADeletableListView
                            layouts={{ pt: 1 }}
                            items={selectedKeywords}
                            onDeleteItem={onDeleteKeyword}
                        />
                    </LAGridItem>
                )}
                </LAProperty>
            </LACard>

            <LACard next>
                <LACardTitle title="클래스 학습자 다운로드 자료 정보" />

                <LAProperty name="자료 등록">
                <LAGridContainer>
                    <LAFileAttachment
                        wPercent={20}
                        hiddenLabel
                        hiddenInput
                        placeholder=""
                        props={{ accept: '.pdf', }}
                        value={classFile.state.name}
                        onChange={onClassFileChange}
                    />
                    <LAWarningLabel
                        wPercent={80}
                        props={{
                            variant: 'caption',
                            color: 'error',
                            fontWeight: 'regular',
                            verticalAlign: 'middle',
                            sx: {
                                fontSize: '1px',
                                color: '#ff0000',
                            },
                        }}
                    >
                    pdf 확장자만 등록 가능하며, 최대 5건 / 전체 용량 100MB까지 첨부 가능합니다.
                    </LAWarningLabel>

                    {classFileInfos && classFileInfos.length > 0 && (
                    <LAGridItem wPercent={70} pt={1}>
                        <LADeletableListView
                            layouts={{ pt: 1 }}
                            items={classFileInfos}
                            onDeleteItem={onClassFileDelete}
                        />
                    </LAGridItem>
                    )}
                </LAGridContainer>
                </LAProperty>
            </LACard>

            <LACard next>
                <LACardTitle title="클래스 교재 정보" />

                <LAProperty name="교재">
                <LAGridContainer>
                    <LAButton wPercent={15} onClick={() => textbookSearchModal.visible(true)} props={{ disabled: (textbookDeliveryType === 2 || textbookTableData.rows.length > 0)}}>웅진씽크빅 교재 발송</LAButton>
                    <LAButton wPercent={15} onClick={addAppendix} layouts={{ ml: { sm: 1 } }} props={{ disabled: (textbookDeliveryType === 1 || textbookTableData.rows.length > 0)}}>선생님 개별 교재 발송</LAButton>
                    <LAWarningLabel wPercent={60} layouts={{ pl: { sm: 5 } }}>최대 1개까지 등록 가능합니다.</LAWarningLabel>
                </LAGridContainer>

                {textbooks && textbooks.length > 0 && (
                    <LAGridContainer layouts={{ pt: 1 }}>
                        <LASimpleTable tableData={textbookTableData} />
                    </LAGridContainer>
                )}
                {/* {textbookDeliveryType == 2 && (
                    <LAButton wPercent={10} onClick={addAppendix} props={{ disabled: textbooks.length >= 10 }}>추가</LAButton>
                )} */}
                </LAProperty>
            </LACard>

            <LACard next>
                <LACardTitle title="클래스 노출" />
                <LAProperty name="노출 여부">
                    <LAGridContainer>
                        {activateState ? (
                            <>
                                <LAGridItem wPercent={15}>
                                <LALabel>노출 상태</LALabel>
                                </LAGridItem>
                            </>
                            ) : (
                            <>
                                <LAGridItem wPercent={15}>
                                <LALabel>미노출 상태</LALabel>
                                </LAGridItem>
                            </>
                        )}
                        {authMemberType == 1 && authAdminAuthType == 1 ? (
                            <>
                                <LAGridItem wPercent={15}>
                                <LAButton onClick={onActivate}>
                                    {activateState ? '미노출' : '노출'}
                                </LAButton>
                                </LAGridItem>
                                <LAWarningLabel wPercent={55} layouts={{ pl: { sm: 1 } }}>
                                변경 시 실시간으로 반영됩니다. / 마스터 관리자만 수정 가능합니다.(마스터외 미노출)
                                </LAWarningLabel>
                            </>
                        ) : ('')}
                    </LAGridContainer>
                </LAProperty>
            </LACard>

            {authMemberType == 1 && authAdminAuthType == 1 ? (
                <LACard next>
                    <LACardTitle title="클래스 즐겨찾기(마스터 관리자 only)" />
                    <LAProperty name="클래스 즐겨찾기">
                        <LAGridContainer>
                            <LAInput
                                wPercent={20}
                                value={favoriteCount.state}
                                onChange={favoriteCount.handleChange}
                            />
                            <LAWarningLabel wPercent={80} layouts={{ pl: { sm: 5 } }}>마스터 관리자만 수정 가능합니다.(마스터외 미노출)</LAWarningLabel>
                        </LAGridContainer>
                    </LAProperty>
                </LACard>
            ) : ('')}
            
            <LAGridContainer layouts={{ pt: 3 }}>
                {authMemberType == 1 && authAdminAuthType == 1 ? (
                    <>
                        <LALabel wPercent={20}></LALabel>
                        <LAButton wPercent={15} layouts={{ mr: 1 }} variant={'outlined'} btnPaddingY={12} onClick={onClickList}>목록</LAButton>
                        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onSaveClick}>저장</LAButton>            
                        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onClassCopyClick}>클래스 복사하기</LAButton>
                        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onDeleteClick}>클래스 삭제</LAButton>
                        <LALabel wPercent={20}></LALabel>
                    </>
                    ) : (
                    <>
                        <LALabel wPercent={40}></LALabel>
                        <LAButton wPercent={20} layouts={{ mr: 1 }} variant={'outlined'} btnPaddingY={12} onClick={onClickList}>목록</LAButton>
                    </>
                )}
            </LAGridContainer>

            <MdSearchModal
                show={mdSearchModal.state}
                handleClose={mdSearchModal.handleClose}
                handleSelect={onMdSelected}
            />

            <TeacherSearchModal
                show={teacherSearchModal.state}
                handleClose={teacherSearchModal.handleClose}
                handleSelect={onTeacherSelected}
            />

            <ScheduleDetail
                show={classScheduleShow}
                schedules={!!data && data.schedules}
                schedule={currentSchedule}
                teacherSchedules={data && data.teacherSchedules}
                className={data && data.class_name}
                classTime={data && data.class_time}
                handleClose={onScheduleDetailClose}
            />

            <ClassScheduleModal
                show={classScheduleAddShow}
                schedules={!!data && data.schedules}
                teacherSchedules={data && data.teacherSchedules}
                teacher={{ member_seq: (data && data.teacher_seq) || (teacher && teacher.member_seq)}}
                classCount={data && data.curriculums ? data.curriculums.length : 1}
                className={data && data.class_name}
                classTime={data && data.class_time}
                onChange={onScheduleAdd}
                handleClose={() => setClassScheduleAddShow(false)}
            />

            <TextbookSearchModal
                show={textbookSearchModal.state}
                handleClose={textbookSearchModal.handleClose}
                handleSelect={addTextbook}
            />

            <ClassScheduleDetailModal
                scheduleSeq={currentSchedule?.schedule_seq}
                show={scheduleDetailModal.state}
                handleClose={onScheduleDetailClose}
            />
        </>
    );
}

export default ClassDetailContents;
