import React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import LAGridItem from './LAGridItem';
import Clear from '@mui/icons-material/Clear';
import MDBox from 'components/MDBox';
import { styled } from '@mui/system';

const CustomImageListItem = styled(ImageListItem)(() => {
  return {
    '.MuiImageListItem-img': {
      width: '174px',
      height: '160px',
      'object-fit': 'fill',
    },
  };
});

function LAImageListItem({
  wPercent = 100,
  layouts = {},
  showDelete = false,
  index,
  seq,
  imageUrl,
  onClick,
  onRemoveClick,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <CustomImageListItem>
        {imageUrl && imageUrl.length > 0 ? (
          <img
            src={imageUrl}
            // srcSet={`${imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
            loading="lazy"
            width="174px"
            height="160px"
            onClick={() => onClick(index)}
          />
        ) : (
          <MDBox
            sx={{
              border: '1px dashed #cccccc',
              backgroundColor: '#eeeeee',
              width: 120,
              height: 80,
            }}
          ></MDBox>
        )}
        {showDelete && (
          <ImageListItemBar
            sx={{
              background: 'transparent',
            }}
            position="top"
            actionIcon={
              <IconButton
                sx={{
                  background: '#222222',
                  opacity: 0.5,
                  width: 22,
                  height: 22,
                }}
                onClick={() => onRemoveClick(index, seq)}
              >
                <Clear sx={{ color: '#ffffff' }} />
              </IconButton>
            }
            actionPosition="right"
          />
        )}
      </CustomImageListItem>
    </LAGridItem>
  );
}

export default React.memo(LAImageListItem);
