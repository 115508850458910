import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SELECT':
        const index = state.selected.findIndex(
          (cell) =>
            cell.rowIndex === action.rowIndex &&
            cell.colIndex === action.colIndex,
        );
        index >= 0
          ? draft.selected.splice(index, 1)
          : draft.selected.push({
              rowIndex: action.rowIndex,
              colIndex: action.colIndex,
            });
        break;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useSimpleTable(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);
  const select = useCallback((rowIndex, colIndex) => {
    dispatch({
      type: 'SELECT',
      rowIndex,
      colIndex,
    });
  }, []);

  const handleClick = useCallback(
    (rowIndex, colIndex) => {
      select(rowIndex, colIndex);
    },
    [select],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  const isSelectedCell = useCallback(
    (rowIndex, colIndex) => {
      const index = state.selected.findIndex(
        (cell) => cell.rowIndex === rowIndex && cell.colIndex === colIndex,
      );
      return index >= 0;
    },
    [state.selected],
  );

  return {
    state,
    handleClick,
    select,
    reset,
    isSelectedCell,
  };
}

export default useSimpleTable;
