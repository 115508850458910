import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useCategoryContext } from 'common/context/CodeContext';
import { LAComboBox } from 'modules/Widgets';

function CategoryComboView({
  value,
  onChange,
  showSecond = true,
  showThird = true,
  readOnly = false,
}) {
  const { categoryList } = useCategoryContext();
  const [firstCategory, setFirstCategory] = useState(null);
  const [secondCategory, setSecondCategory] = useState(null);
  const [thirdCategory, setThirdCategory] = useState(null);

  const categories = useMemo(() => {
    return categoryList;
  }, [categoryList]);

  const firstCategories = useMemo(() => {
    if (categories && categories.length > 0) {
      return categories.map((c) => {
        return { key: c.category_seq, value: c.category_name };
      });
    } else {
      return [];
    }
  }, [categories]);

  const secondCategories = useMemo(() => {
    if (firstCategory) {
      return categories
        .filter((c) => c.category_seq === firstCategory.key)
        .map((cc) =>
          cc.childs.map((ccc) => {
            return { key: ccc.category_seq, value: ccc.category_name };
          }),
        )[0];
    } else return [];
  }, [categories, firstCategory]);

  const thirdCategories = useMemo(() => {
    if (secondCategory) {
      return categories
        .filter((c) => c.category_seq === secondCategory.key)
        .map((cc) =>
          cc.childs.map((ccc) => {
            return { key: ccc.category_seq, value: ccc.category_name };
          }),
        )[0];
    } else return [];
  }, [categories, secondCategory]);

  const hasSecond = useMemo(() => {
    if (categories && categories.length > 0) {
      return categories.map((c) => c.childs.length > 0).find((cc) => cc);
    } else {
      return undefined;
    }
  }, [categories]);

  const hasThird = useMemo(() => {
    if (categories && categories.length > 0) {
      return categories
        .map((c) => c.childs.some((cc) => cc.childs.length > 0))
        .some((ccc) => ccc);
    } else return undefined;
  }, [categories]);

  const getFirstItem = useCallback(
    (category_seq) => {
      if (categories && categories.length > 0) {
        return categories.find((c) => c.category_seq === category_seq);
      } else return undefined;
    },
    [categories],
  );

  const getSecondItem = useCallback(
    (category_seq) => {
      if (categories && categories.length > 0) {
        return categories
          .filter((c) =>
            c.childs.some((cc) => cc.category_seq === category_seq),
          )
          .map((c) => Object.assign({}, c))
          .map((c) => c.childs.find((c) => c.category_seq === category_seq))
          .find((c) => c.category_seq === category_seq);
      } else return undefined;
    },
    [categories],
  );

  const getThirdItem = useCallback(
    (category_seq) => {
      if (categories && categories.length > 0) {
        return categories
          .filter((c) =>
            c.childs.some((cc) =>
              cc.childs.some((ccc) => ccc.category_seq === category_seq),
            ),
          )
          .map((c) => Object.assign({}, c))
          .map((c) =>
            c.childs.find((c) =>
              c.childs.some((c) => c.category_seq === category_seq),
            ),
          )
          .map((c) => c.childs.find((c) => c.category_seq === category_seq))
          .find((c) => c.category_seq === category_seq);
      } else return undefined;
    },
    [categories],
  );

  useEffect(() => {
    let category = value;
    if (category) {
      // 3단계 카테고리처리
      let thirdItem = getThirdItem(category);
      let secondItem = getSecondItem(category);
      let firstItem = getFirstItem(category);
      if (thirdItem) {
        let secondItem = getSecondItem(thirdItem.parent_category_seq);
        let firstItem = getFirstItem(secondItem.category_seq);
        setFirstCategory({
          key: firstItem?.category_seq,
          value: firstItem?.category_name,
        });
        setSecondCategory({
          key: secondItem.category_seq,
          value: secondItem.category_name,
        });
        setThirdCategory({
          key: thirdItem.category_seq,
          value: thirdItem.category_name,
        });
      } else if (secondItem) {
        // 2단계 카테고리 처리
        let parentItem = getFirstItem(secondItem.parent_category_seq);
        if (parentItem) {
          setFirstCategory({
            key: parentItem.category_seq,
            value: parentItem.category_name,
          });
          setSecondCategory({
            key: secondItem.category_seq,
            value: secondItem.category_name,
          });
        }
      } else {
        if (firstItem) {
          setFirstCategory({
            key: firstItem.category_seq,
            value: firstItem.category_name,
          });
        }
      }
    }

    return () => {
    };
  }, [categories, getFirstItem, getSecondItem, getThirdItem, value]);

  // useEffect(() => {
  //   return () => {
  //   };
  // }, []);

  const onFirstCategoryChanged = useCallback(
    (item) => {
      //   `CategoryComboView.onFirstCategoryChanged => item :  `,
      //   item,
      //   hasSecond,
      // );
      setFirstCategory(item);
      setSecondCategory(null);
      setThirdCategory(null);
      // if(!hasSecond) {
      onChange(item);
      // }else{
      //   onChange(null)
      // }
    },
    [onChange],
  );

  const onSecondCategoryChanged = useCallback(
    (item) => {
      setSecondCategory(item);
      setThirdCategory(null);
      // if(!hasThird) {
      onChange(item);
      // }else{
      //   onChange(null)
      // }
    },
    [onChange],
  );

  const onThirdCategoryChanged = useCallback((item) => {
      setThirdCategory(item);
      onChange(item);
  }, [onChange]);

  return (
    <>
      <LAComboBox
        wPercent={20}
        layouts={{ pr: { sm: 1 } }}
        items={firstCategories}
        value={!!firstCategory && firstCategory.key}
        onChange={onFirstCategoryChanged}
        readOnly={readOnly}
      />

      <>
        {showSecond &&
        hasSecond &&
        firstCategory &&
        secondCategories.length > 0 ? (
          <LAComboBox
            wPercent={20}
            layouts={{ pr: { sm: 1 } }}
            items={secondCategories}
            value={!!secondCategory && secondCategory.key}
            onChange={onSecondCategoryChanged}
            readOnly={readOnly}
          />
        ) : (
          ''
        )}
      </>

      <>
        {showThird &&
        hasThird &&
        secondCategory &&
        thirdCategories &&
        thirdCategories.length > 0 ? (
          <LAComboBox
            wPercent={20}
            layouts={{ pr: { sm: 1 } }}
            items={thirdCategories}
            value={!!thirdCategory && thirdCategory.key}
            onChange={onThirdCategoryChanged}
            readOnly={readOnly}
          />
        ) : (
          ''
        )}
      </>
    </>
  );
}

export default React.memo(CategoryComboView);
