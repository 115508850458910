import React, {useCallback, useEffect, useState} from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function LALoading({isOpen=false}) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  });

  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => 9999 }}
        open={open?open:false}
        // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default React.memo(LALoading);