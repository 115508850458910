import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Card from '@mui/material/Card';

import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Authentication layout components
import BasicLayout from 'pages/Common/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { useLoginContext } from 'common/context/MemberContext';
import { LACard, LAGridContainer, LAGridItem } from 'modules/Widgets';
import ClassImage from 'assets/images/class.png';
import ScheduleImage from 'assets/images/schedule.png';
import OrderImage from 'assets/images/order.png';
import { LAButton } from 'modules/Widgets';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

function Main() {
  const navigate = useNavigate();
  const { showAlert } = usePopAlert();
  const { isLogin, loginState } = useLoginContext();

  useEffect(() => {
    if (isLogin) {
      if (loginState.auth == undefined) {
        alert();
      }

      navigate('/');
    }
    return () => {};
  }, [isLogin, navigate]);

  const onGoClassClick = useCallback(() => {
    if (!isLogin) {
      showAlert('알림', '로그인 후 이용가능합니다.');
      return;
    }
    navigate('/class/list');
  }, [isLogin]);

  const onGoScheduleClick = useCallback(() => {
    if (!isLogin) {
      showAlert('알림', '로그인 후 이용가능합니다.');
      return;
    }
    navigate('/class/calendar');
  }, [isLogin]);

  const onGoOrderDeliveryClick = useCallback(() => {
    if (!isLogin) {
      showAlert('알림', '로그인 후 이용가능합니다.');
      return;
    }
    navigate('/order/delivery/list');
  }, [isLogin]);

  return (
    <>
      <DashboardNavbar />
      <BasicLayout image={bgImage}>
        <LAGridContainer>
          <LAGridItem wPercent={12.5} />
          <LAGridItem wPercent={25} layouts={{ pl: { sm: 1 }, pr: { sm: 1 } }}>
            <LACard>
              <img
                src={ClassImage}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%',
                }}
              />
              <MDBox textAlign="center">
                <LAGridItem>
                  <MDTypography variant="h5">클래스 일정 및 판매 변경</MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ mb: -2 }}>
                  <MDTypography variant="button">
                    ·클래스 날짜, 시간 변경
                  </MDTypography>
                </LAGridItem>
                <LAGridItem>
                  <MDTypography variant="button">
                  ·판매 상태 변경
                  </MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ pt: 2 }}>
                  <LAButton onClick={onGoClassClick}>바로 가기</LAButton>
                </LAGridItem>
              </MDBox>
            </LACard>
          </LAGridItem>

          <LAGridItem wPercent={25} layouts={{ pl: { sm: 1 }, pr: { sm: 1 } }}>
            <LACard>
              <img
                src={ScheduleImage}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%',
                }}
              />
              <MDBox textAlign="center">
                <LAGridItem>
                  <MDTypography variant="h5">화상방 수업 관리</MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ mb: -2 }}>
                  <MDTypography variant="button">
                  · 화상방 개설 클래스 확인
                  </MDTypography>
                </LAGridItem>
                <LAGridItem>
                  <MDTypography variant="button">
                  · 화상 수업방 학습자 및 상태 확인
                  </MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ pt: 2 }}>
                  <LAButton onClick={onGoScheduleClick}>바로 가기</LAButton>
                </LAGridItem>
              </MDBox>
            </LACard>
          </LAGridItem>

          <LAGridItem wPercent={25} layouts={{ pl: { sm: 1 }, pr: { sm: 1 } }}>
            <LACard>
              <img
                src={OrderImage}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%',
                }}
              />
              <MDBox textAlign="center">
                <LAGridItem>
                  <MDTypography variant="h5">배송 관리</MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ mb: -2 }}>
                  <MDTypography variant="button">
                  · 고객별 운송장 등록 및 확인
                  </MDTypography>
                </LAGridItem>
                <LAGridItem>
                  <MDTypography variant="button">
                    &nbsp;
                  </MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ pt: 2 }}>
                  <LAButton onClick={onGoOrderDeliveryClick}>바로 가기</LAButton>
                </LAGridItem>
              </MDBox>
            </LACard>
          </LAGridItem>
          <LAGridItem wPercent={12.5} />
        </LAGridContainer>
      </BasicLayout>
    </>
  );
}

export default Main;
