import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/categories';

const CategoryApi = (requestFunc) => {
    return {
        Category: {
            /* 카테고리 등록 */
            regist: {
                key: 'regist',
                queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}`, data),
            },
            /* 전체카테고리 목록 */
            all: {
                key: 'all',
                queryFn: () => requestFunc(METHOD.GET, `${baseURL}/all`),
            },

            getList: {
                key: 'getList',
                queryFn: () => requestFunc(METHOD.GET, `${baseURL}`),
            },

            main: {
                key: 'main',
                queryFn: () => requestFunc(METHOD.GET, `${baseURL}/main`),
            },

            /* 카테고리 수정 */
            modify: {
                key: 'modify',
                queryFn: (data) => requestFunc(METHOD.PATCH, `${baseURL}/${data.category_seq}`, data),
            },

            /* 카테고리 삭제 */
            delete: {
                key: 'delete',
                queryFn: (category_seq) => requestFunc(METHOD.DELETE, `${baseURL}/${category_seq}`),
            },

            /* 카테고리 순위 업 */
            moveUp: {
                key: 'moveUp',
                queryFn: (category_seq) => requestFunc(METHOD.PATCH, `${baseURL}/${category_seq}/up`),
            },

            /* 카테고리 순위 다운 */
            moveDown: {
                key: 'moveDown',
                queryFn: (category_seq) => requestFunc(METHOD.PATCH, `${baseURL}/${category_seq}/down`),
            },

            saveAll: {
                key: 'saveAll',
                queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}/saveAll`, data),
            }
            
        },
    };
};

export default CategoryApi;
