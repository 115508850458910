import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/ko';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ko } from 'date-fns/esm/locale';

import { getTodayDateText } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import MDSnackbar from 'components/MDSnackbar';

import { getDuplicateClassSchedules } from 'common/utils/EtcUtils';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LAButton,
  LAComboBox,
  LASimpleTable,
  LAWarningLabel,
  useComboBox,
} from 'modules/Widgets';
import { LAGridItem } from 'modules/Widgets';

const hourItems = [
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '05', value: '05분' },
  { key: '10', value: '10분' },
  { key: '15', value: '15분' },
  { key: '20', value: '20분' },
  { key: '25', value: '25분' },
  { key: '30', value: '30분' },
  { key: '35', value: '35분' },
  { key: '40', value: '40분' },
  { key: '45', value: '45분' },
  { key: '50', value: '50분' },
  { key: '55', value: '55분' },
];

function ClassScheduleView({
  schedules,
  teacherSchedules = [],
  curriCount,
  className,
  classTime,
  teacher,
  onChange,
}) {
  const { showAlert, showConfirm } = usePopAlert();
  const tempScheduleStartHour = useComboBox('07');
  const tempScheduleStartMin = useComboBox('00');

  const [curriData, setCurriData] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderSuccessSB = (message) => {
    return (
      <MDSnackbar
        color="info"
        icon="check"
        title="Information!"
        content={message}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
      />
    );
  };

  useEffect(() => {
    setCurriData(() => {
      const result = [];
      for (let i = 0; i < curriCount; i++) {
        result.push({
          index: i,
          date: '',
        });
      }
      return result;
    });

    setScheduleList(schedules);
  }, [curriCount]);

  const initCurriData = useCallback(() => {
    setCurriData(() => {
      const result = [];
      for (let i = 0; i < curriCount; i++) {
        result.push({
          index: i,
          date: '',
        });
      }
      return result;
    });
  }, [curriCount]);

  const onInitDate = useCallback(
    (curri) => {
      if (curri.date.length === 0) {
        return;
      }
      let result = curriData.filter((data, i) => {
        return curri.date != data.date;
      });
      let index = 0;
      result.forEach((data) => {
        data.index = index;
        index++;
      });
      result.push({
        index: curriData.length - 1,
        date: '',
      });
      setCurriData(result);
    },
    [curriData],
  );

  const today = moment(moment().format('YYYY-MM-DD'));
  const availableDay = moment(moment().format('YYYY-MM-DD'))
    .add(7, 'days')
    .format('YYYY-MM-DD');

  const onSelectedDate = useCallback(
    (selectedDate) => {
      let result = [];
      let inserted = 0;
      let index = 0;

      let emptyList = curriData.filter((curri) => curri.date == '');
      if (emptyList.length > 0) {
        const selDay = moment(selectedDate);
        if (selDay.diff(today, 'days') < 7) {
          showAlert('알림', `날짜는 ${availableDay} 부터 선택 가능합니다.`);
          return;
        }
      } else {
        openSuccessSB();
      }

      curriData.forEach((curri) => {
        if (!curri.date && !inserted) {
          inserted = 1;
          result.push(selectedDate);
        } else {
          if (curri.date) {
            if (curri.date == selectedDate) inserted = 1;
            result.push(curri.date);
          } else {
            result.push('9999-99-99');
          }
        }
        index++;
      });

      if (result.length > 0) {
        result.sort();
        let newCurriData = [];
        index = 0;
        result.forEach((date) => {
          if (date != '9999-99-99') {
            newCurriData.push({ index: index, date: date });
          } else {
            newCurriData.push({ index: index, date: '' });
          }
        });
        setCurriData(newCurriData);
      }
    },
    [curriData, showAlert],
  );

  const onDeleteSchedule = useCallback(
    (schedule) => {
      let newSchedule = scheduleList.filter(
        (data, index) => index != scheduleList.indexOf(schedule),
      );
      setScheduleList(newSchedule);
      onChange(newSchedule);
    },
    [scheduleList],
  );

  const getCurriRows = useCallback(() => {
    const result = [];
    let index = 1;
    curriData.forEach((curri) => {
      result.push([
        {
          text: `${index}회차`,
        },
        {
          text: '',
          type: 'input',
          callback: {
            value: curri.date,
            // onChange: (e) => handleNameChange(e, i),
          },
        },
        {
          text: '초기화',
          type: 'button',
          callback: {
            onClick: () => onInitDate(curri),
          },
        },
      ]);
      index++;
    });

    return result;
  }, [curriCount, curriData]);

  const curriTableData = useMemo(() => {
    return {
      headers: [
        {
          text: '회차',
        },
        {
          text: '날짜',
        },
        {
          text: '초기화',
        },
      ],
      wPercentList: [20, 55, 25],
      rows: getCurriRows(),
    };
  }, [curriData, getCurriRows]);

  const getScheduleRows = useCallback(() => {
    const result = [];
    let index = 1;
    scheduleList.forEach((schedule) => {
      let sDates = '';
      schedule.dates.forEach((date) => {
        if (sDates == '') {
          sDates = moment(date).format('MM-DD(dd)');
        } else {
          sDates += ', ' + moment(date).format('MM-DD(dd)');
        }
      });
      result.push([
        {
          text: `${index}`,
        },
        {
          text: `${schedule.dates.length} 회`,
        },
        {
          text: `${sDates}`,
        },
        {
          text: `${moment('2022-01-01 ' + schedule.startTime).format(
            'a hh:mm',
          )}`,
        },
        {
          text: '삭제',
          type: 'button',
          callback: {
            onClick: () => onDeleteSchedule(schedule),
          },
        },
      ]);
      index++;
    });

    return result;
  }, [scheduleList]);

  const scheduleTableData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '총 회차',
        },
        {
          text: '클래스 일정',
        },
        {
          text: '클래스 시간',
        },
        {
          text: '삭제',
        },
      ],
      wPercentList: [8, 12, 50, 20, 10],
      rows: getScheduleRows(),
    };
  }, [scheduleList, getScheduleRows]);

  // const getDuration = useCallback((startTime, endTime) => {
  //   const date = getTodayDateText('-');
  //   const t1 = moment(date + ' ' + startTime);
  //   const t2 = moment(date + ' ' + endTime);
  //   return Math.round(moment.duration(t2.diff(t1)).asMinutes());
  // }, []);

  const onAddSchedule = useCallback(() => {
    // 선생님이 지정되어 있지 않으면 일정 추가 불가
    if (!teacher.member_seq) {
      showAlert('알림', '선생님을 먼저 지정하세요.');
      return false;
    }

    let isEmptyDate = false;
    let dates = [];
    curriData.forEach((curri) => {
      if (!curri.date) {
        isEmptyDate = true;
        return;
      }
      dates.push(curri.date);
    });

    if (isEmptyDate) {
      showAlert('알림', '모든 회차에 날짜를 지정하세요.');
      return;
    }


    const startTime =
      tempScheduleStartHour.state + ':' + tempScheduleStartMin.state;

    // let classTime = 30;

    // let desireCurri = {};
    // curriData.forEach(curri => {
    //   curri.className = className;
    //   curri.classTime = classTime;
    // });

    const newData = {
      dates,
      startTime,
      className,
      classTime,
    };

    let duplicatedMessage = getDuplicateClassSchedules(
      [...teacherSchedules, ...scheduleList],
      newData,
    );
    if (duplicatedMessage) {
      showAlert('알림', `중복되는 일정이 있습니다.\n\n${duplicatedMessage}`);
      return;
    }

    /*
    let duplicatedMessage = getDuplicateClassSchedules(scheduleList, curriData, startTime, classTime, className);
    if (duplicatedMessage) {      
      showAlert('알림', `기존 일정에 동일한 날짜가 포함되어 있습니다.\n\n${duplicatedMessage}`);
      return;
    }else{
      let duplicatedTeacherScheduleMessage = getDuplicateTeacherSchedules(teacherSchedules, curriData, startTime, classTime);
      if(duplicatedTeacherScheduleMessage){
        showAlert('알림', `다른 일정에 동일한 일정이 포함되어 있습니다.\n\n${duplicatedTeacherScheduleMessage}`);
        return;
      }
    }
    */

    let tempSchedule = scheduleList.filter(() => true);

    tempSchedule.push(newData);

    if (onChange(tempSchedule)) {
      setScheduleList(tempSchedule);
      initCurriData();
    }
  }, [
    curriData,
    scheduleList,
    tempScheduleStartHour,
    tempScheduleStartMin,
    showAlert,
    teacher,
  ]);

  return (
    <>
      <LACard next>
        <LACardTitle
          title="클래스 일정 정보"
          content="회차에 해당하는 날짜를 선택하세요"
        />
        <LAProperty name="클래스 일정 선택">
          <LAWarningLabel pb={2} layouts={{ pb: { sx: 2 } }}>
            클래스 일정은 일주일 이후부터 선택 가능합니다.
          </LAWarningLabel>

          <LAGridContainer>
            <LAGridItem wPercent={35}>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                className="react-datepicker"
                locale={ko}
                selected={new Date()}
                onChange={(date) =>
                  onSelectedDate(moment(date).format('YYYY-MM-DD'))
                }
                minDate={availableDay ? moment(availableDay).toDate() : ''}
                inline
              />
            </LAGridItem>
            <LAGridItem wPercent={60}>
              <LASimpleTable tableData={curriTableData} />
              <LAGridContainer layouts={{ mt: { sm: 1 } }}>
                <LAGridItem wPercent={60}></LAGridItem>
                <LAGridItem wPercent={40}>
                  <LAButton onClick={initCurriData}>전체 초기화</LAButton>
                </LAGridItem>
              </LAGridContainer>
            </LAGridItem>
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="클래스 시간" required>
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              layouts={{ pr: { sm: 0.5 } }}
              items={hourItems}
              value={tempScheduleStartHour.state}
              onChange={tempScheduleStartHour.handleChange}
            />
            <LAComboBox
              wPercent={20}
              layouts={{ pr: { sm: 0.5 } }}
              items={minItems}
              value={tempScheduleStartMin.state}
              onChange={tempScheduleStartMin.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="일정" required>
          <LAGridContainer>
            <LAButton wPercent={15} onClick={onAddSchedule}>
              일정 추가
            </LAButton>
          </LAGridContainer>
          {renderSuccessSB('모든 회차에 날짜가 지정되었습니다.')}
          {scheduleList && scheduleList.length > 0 && (
            <LAGridContainer pt={1}>
              <LASimpleTable tableData={scheduleTableData} />
            </LAGridContainer>
          )}
          {/* <LAWarningLabel>
            패키지(표준순환, 개별 맞춤) : 클래스 종료일은 클래스 기본 정보에서
            선택한 클래스 회차에 따라 자동 계산되어 표시됩니다.
          </LAWarningLabel> */}
        </LAProperty>
      </LACard>
    </>
  );
}

// 선생님의 기존 모든 일정과의 중복 체크
/*
function getDuplicateTeacherSchedules(scheduledList, desireList, startTime, classTime){
  let duplicatedMessage = '';
  if(scheduledList.length > 0) {
    
    scheduledList.forEach((schedule) => {
      let scheduledStartTime = moment(schedule.schedule_start_time, 'YYYYMMDDHHmm');
      let scheduledFinishTime = moment(schedule.schedule_finish_time, 'YYYYMMDDHHmm');

      desireList.forEach(desire => {
        let desireStartTime = moment(desire.date + startTime, 'YYYY-MM-DDHH:mm');
        let desireFinishTime = desireStartTime.clone().add(classTime, 'm');
        
        if(desireStartTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]') || desireFinishTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]')){
          duplicatedMessage += `[${schedule.class_name}] \n${moment(scheduledStartTime).format('YYYY-MM-DD HH:mm')} ~ ${moment(scheduledFinishTime).format('YYYY-MM-DD HH:mm')}\n`;
        }
      });
    });
  }
  return duplicatedMessage;
}
*/

// // 클래스 일정 수정화면에서 추가된 일정과의 중복 체크
// function getDuplicateClassSchedules(scheduledList, desireSchedule){
//   let duplicatedMessage = '';
//   if(scheduledList.length > 0) {
//     console.log(scheduledList);
//     scheduledList.forEach((schedule) => {

//       schedule.dates.forEach((date) => {
//         let scheduledStartTime = moment(date + schedule.startTime, 'YYYY-MM-DDHH:mm')
//         let scheduledFinishTime = scheduledStartTime.clone().add(schedule.classTime, 'm');

//         desireSchedule.dates.forEach(date => {
//           let desireStartTime = moment(date + desireSchedule.startTime, 'YYYY-MM-DDHH:mm');
//           let desireFinishTime = desireStartTime.clone().add(desireSchedule.classTime, 'm');

//           if(desireStartTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]') || desireFinishTime.isBetween(scheduledStartTime, scheduledFinishTime, null, '[]')){
//             duplicatedMessage += `[${schedule.className}]\n${moment(scheduledStartTime).format('YYYY-MM-DD HH:mm')} ~ ${moment(scheduledFinishTime).format('YYYY-MM-DD HH:mm')}\n`;
//           }
//         });
//       });
//     });
//   }
//   return duplicatedMessage;
// }
export default React.memo(ClassScheduleView);
