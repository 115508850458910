import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { getTodayDateText } from 'common/utils';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import { getDuplicateClassSchedules } from 'common/utils/EtcUtils';
import pxToRem from 'assets/theme/functions/pxToRem';
import moment from 'moment';

import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAButton,
  LAInput,
  LALabel,
  LAProperty,
  LACheckbox,
  LARadioGroup,
  useCheckbox,
  useRadioGroup,
  useInput,
} from 'modules/Widgets';

const hourItems = [
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '05', value: '05분' },
  { key: '10', value: '10분' },
  { key: '15', value: '15분' },
  { key: '20', value: '20분' },
  { key: '25', value: '25분' },
  { key: '30', value: '30분' },
  { key: '35', value: '35분' },
  { key: '40', value: '40분' },
  { key: '45', value: '45분' },
  { key: '50', value: '50분' },
  { key: '55', value: '55분' },
];

const doneTypes = [
  {
    text: '정상 종료',
    value: 0,
  },
  {
    text: '비정상 종료',
    value: 1,
  },
];

function TimetableTerminatationModal({
  classType,
  timetable,
  onChange,
  show,
  handleClose,
}) {
  const { action } = useLoginContext();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const abnormalReason = useInput('');
  const nextTimetableYn = useCheckbox(true);
  const doneType = useRadioGroup(0);

  const { request: termination } = useCommonMutation({
    query: queries.Timetable.termination,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', data.result_message);
          onChange();
          handleReset()
          handleClose();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
      },
    },
  });

  const { request: abnormalTermination } = useCommonMutation({
    query: queries.Timetable.abnormalTermination,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', data.result_message);
          onChange();
          handleReset()
          handleClose();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
      },
    },
  });

  const handleReset = useCallback(() => {
    // 취소 처리 로직
    abnormalReason.reset();
    nextTimetableYn.reset();
    doneType.reset();
  },[abnormalReason.reset,nextTimetableYn.reset,doneType.reset]);


  // 다음수업 매핑 default checked 수정불가
  const onHandlleNextTimetable = useCallback((e) =>{
    
  },[abnormalReason.state])

  // 종료 처리
  const onTermination = useCallback(() => {
    if (timetable.done_yn) {
      showAlert('알림', '이미 정상 종료 처리된 일정입니다.');
      return;
    }
    console.log(nextTimetableYn.state);
    var confirmMessage = '';
    if (timetable.abnormal_yn) {
      confirmMessage = `비정상 종료 처리된 수업을 종료 처리로 변경하시겠습니까?\n\n다음 수업이 있는 경우 자동으로 지정됩니다.`;
      // confirmMessage = `비정상 종료 처리된 수업을 종료 처리로 변경하시겠습니까?\n\n다음 수업 매핑 : ${
      //   nextTimetableYn.state ? '예' : '아니오'
      // }`;
    } else {
      confirmMessage = `종료 처리를 진행 하시겠습니까?\n\n다음 수업이 있는 경우 자동으로 지정됩니다.`;
      // confirmMessage = `종료 처리를 진행 하시겠습니까?\n\n다음 수업 매핑 : ${
      //   nextTimetableYn.state ? '예' : '아니오'
      // }`;
    }

    showConfirm('알림', confirmMessage, {
      confirmHandler: () => {
        termination({
          timetable_seq: timetable.timetable_seq,
          data: { next_yn: nextTimetableYn.state ? 1 : 0 },
        });
      },
    });
  }, [timetable, nextTimetableYn.state]);

  // 비저상 종료 처리
  const onAbNormalTermination = useCallback(() => {
    if (timetable.abnormal_yn) {
      showAlert('알림', '이미 비정상 종료 처리된 일정입니다.');
      return;
    }

    if (abnormalReason.state === '') {
      showAlert('알림', '비정상 종료 사유를 입력하세요.');
      return;
    }
    console.log(nextTimetableYn.state);
    var confirmMessage = '';
    if (timetable.done_yn) {
      confirmMessage = `종료 처리된 수업을 비정상 종료 처리로 변경하시겠습니까?\n\n다음 수업 매핑 : ${
        nextTimetableYn.state ? '예' : '아니오'
      }`;
    } else {
      confirmMessage = `비정상 처리를 진행 하시겠습니까?\n\n다음 수업 매핑 : ${
        nextTimetableYn.state ? '예' : '아니오'
      }`;
    }

    showConfirm('알림', confirmMessage, {
      confirmHandler: () => {
        abnormalTermination({
          timetable_seq: timetable.timetable_seq,
          data: {
            abnormal_reason: abnormalReason,
            next_yn: nextTimetableYn.state ? 1 : 0,
          },
        });
      },
    });
  }, [timetable, abnormalReason.state, nextTimetableYn.state]);

  return (
    <LAModal
      title=""
      width="70%"
      height={doneType.state == 1 ? '450px' : '400px'}
      show={show}
    >
      <LACard>
        <LACardTitle title="클래스 일정 종료" />
        <LAProperty name="수업 일자">
          <LALabel wPercent={85}>
            {!!timetable &&
              moment(timetable.schedule_date).format('YYYY-MM-DD (dd) A hh:mm')}
          </LALabel>
        </LAProperty>
        <LAProperty name="수업 시간">
          <LALabel wPercent={85}>
            {!!timetable &&
              moment(
                `${timetable.schedule_date} ${timetable.schedule_time}`,
              ).format('A hh:mm')}
          </LALabel>
        </LAProperty>
        <LAProperty name="종료 구분" required>
          <LARadioGroup
            props={doneTypes}
            value={doneType.state}
            onChange={doneType.handleChange}
          />
        </LAProperty>
        {doneType.state == 1 ? (
          <LAProperty name="사유" required>
            <LAInput
              placeholder="비정상 종료 사유를 입력하세요."
              value={abnormalReason.state}
              onChange={abnormalReason.handleChange}
            />
          </LAProperty>
        ) : (
          ''
        )}

        {/* <LAProperty name="현재 수업에 적용" required> */}
        {/* {
          classType == 2 ?
          <LACheckbox
          label={'다음 수업 매핑'}
          value={nextTimetableYn.state}
          onClick={onHandlleNextTimetable}
          
          // onClick={nextTimetableYn.handleClick}
          // props={{
          //   fontSize: pxToRem(12),
          //   disabled: disabled,
          // }}
          // layouts={{
          //   px: 2,
          // }}
        />
        :''
        } */}
        
        {/* </LAProperty>       */}
      </LACard>

      <LAGridContainer pt={3}>
        <LAGridItem wPercent={34} />
        <LAButton
          wPercent={15}
          layouts={{ pr: { sm: 0.5 } }}
          onClick={handleClose}
        >
          취소
        </LAButton>
        {doneType.state == 0 ? (
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 0.5 } }}
            onClick={onTermination}
          >
            종료 처리
          </LAButton>
        ) : (
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 0.5 } }}
            onClick={onAbNormalTermination}
          >
            비정상 종료 처리
          </LAButton>
        )}
      </LAGridContainer>
    </LAModal>
  );
}

export default React.memo(TimetableTerminatationModal);
