import React, { useState, useCallback, useEffect, useMemo } from 'react';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

function useComments({ classroomSeq, classmateSeq, userName }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [comments, setComments] = useState([]);

  const { request: requestFeedbacks } = useCommonQuery({
    query: queries.Feedback.classroomFeedbacks,
    params: {
      classroom_seq: classroomSeq,
      classmate_seq: classmateSeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setComments(data.result_data);
        } else {
          //   showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        //   showAlert('알림', '스케줄 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestRegistFeedback } = useCommonMutation({
    query: queries.Feedback.addClassroomFeedback,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // const cleanComments = getCleanComments();
          // setComments(cleanComments.concat({ ...data.result_data, owner_yn: true }));
          requestFeedbacks();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        console.log(error);
        showAlert('알림', '피드백 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestReplyFeedback } = useCommonMutation({
    query: queries.Feedback.addReplyFeedback,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === '0000') {
          // setCommentStates(
          //   emptyComment.feedback_seq,
          //   variables.feedback_seq,
          //   { ...data.result_data, owner_yn: true, isTemp: false, isReply: false },
          //   true,
          // );
          requestFeedbacks();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        console.log(error);
        showAlert('알림', '피드백 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestDeleteFeedback } = useCommonMutation({
    query: queries.Feedback.deleteFeedback,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === '0000') {
          // setCommentStates(variables.feedback_seq, variables.parent_seq, {
          //   delete_yn: 1,
          // });
          requestFeedbacks();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        console.log(error);
        showAlert('알림', '피드백 삭제에 실패했습니다.');
      },
    },
  });

  const { request: requestUpdateFeedback } = useCommonMutation({
    query: queries.Feedback.updateFeedback,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === '0000') {
          // setCommentStates(variables.feedback_seq, variables.parent_seq, {
          //   ...data.result_data,
          //   isReply: false,
          // });
          requestFeedbacks();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        console.log(error);
        showAlert('알림', '피드백 수정에 실패했습니다.');
      },
    },
  });

  const emptyComment = useMemo(() => {
    return {
      birth: '',
      childs: [],
      classmate_seq: 0,
      classroom_seq: 0,
      constructor_name: userName || '',
      constructor_seq: 0,
      contents: '',
      created_dt: '',
      deleted_dt: '',
      deleted_yn: 0,
      feedback_seq: 0,
      file_key: '',
      file_original_name: '',
      file_url: '',
      modified_dt: '',
      modifier_seq: 0,
      parent_seq: 0,
      profile_image_url: '',
      subject: '',
      isReply: true,
      isTemp: true,
    };
  }, [userName]);

  const getCleanComments = useCallback(
    (includeTemp = false) => {
      const result = [];
      for (let i = 0; i < comments.length; i++) {
        if (!includeTemp && comments[i].isTemp) {
          continue;
        }

        const childs = [];
        for (let j = 0; j < comments[i].childs.length; j++) {
          if (!includeTemp && comments[i].childs[j].isTemp) {
            continue;
          }

          childs.push({ ...comments[i].childs[j], isReply: false });
        }

        result.push({
          ...comments[i],
          isReply: false,
          childs,
        });
      }
      return result;
    },
    [comments],
  );

  const setCommentStates = useCallback(
    (id, parentId, props, includeTemp = false) => {
      const cleanComments = getCleanComments(includeTemp);
      if (parentId === undefined) {
        setComments(
          cleanComments.map((comment) =>
            comment.feedback_seq === id ? { ...comment, ...props } : comment,
          ),
        );
      } else {
        setComments(
          cleanComments.map((comment) => {
            return comment.feedback_seq === parentId
              ? {
                  ...comment,
                  childs: comment.childs.map((childComment) =>
                    childComment.feedback_seq === id
                      ? { ...childComment, ...props }
                      : childComment,
                  ),
                }
              : comment;
          }),
        );
      }
    },
    [getCleanComments],
  );

  const handleRegist = useCallback(
    (e, data, id, parentId, isUpdate) => {
      if (!data.text || data.text.trim().length === 0) {
        showAlert('알림', '내용을 입력해 주세요.');
        return;
      }

      if (isUpdate) {
        const formData = new FormData();
        formData.append('contents', data.text);
        if (data.file) {
          formData.append('attachment', data.file.info);
        }
        requestUpdateFeedback({
          feedback_seq: id,
          parent_seq: parentId,
          data: formData,
        });
        return;
      }

      if (parentId) {
        const formData = new FormData();
        formData.append('classmate_seq', classmateSeq);
        if (data.file) {
          formData.append('attachment', data.file.info);
        }
        formData.append('contents', data.text);
        requestReplyFeedback({ feedback_seq: parentId, data: formData });
      } else {
        const formData = new FormData();
        formData.append('classroom_seq', classroomSeq);
        formData.append('classmate_seq', classmateSeq);
        if (data.file) {
          formData.append('attachment', data.file.info);
        }
        formData.append('contents', data.text);
        requestRegistFeedback({ classroom_seq: classroomSeq, data: formData });
      }
    },
    [
      classmateSeq,
      classroomSeq,
      requestUpdateFeedback,
      requestReplyFeedback,
      requestRegistFeedback,
      showAlert,
    ],
  );

  const handleReply = useCallback(
    (e, parentId) => {
      const cleanComments = getCleanComments();
      setComments(
        cleanComments.map((comment) => {
          return comment.feedback_seq === parentId
            ? {
                ...comment,
                childs: comment.childs.concat({
                  ...emptyComment,
                }),
              }
            : comment;
        }),
      );
    },
    [emptyComment, getCleanComments],
  );

  const handleUpdate = useCallback(
    (e, id, parentId) => {
      setCommentStates(id, parentId, { isReply: true });
    },
    [setCommentStates],
  );

  const handleDelete = useCallback(
    (e, id, parentId) => {
      showConfirm('알림', '정말 삭제하시겠습니까?', {
        confirmHandler: () => {
          requestDeleteFeedback({
            feedback_seq: id,
            parent_seq: parentId,
          });
        },
      });
    },
    [requestDeleteFeedback, showConfirm],
  );

  return {
    comments,
    handleRegist,
    handleReply,
    handleUpdate,
    handleDelete,
  };
}

export default useComments;
