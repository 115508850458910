import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import { getDateTimeText, dateTimeToString } from "common/utils";
import { useLoginContext } from "common/context/MemberContext";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import { useKeywordContext } from "common/context/CodeContext";
import MDBox from "components/MDBox";
import EmptyImage from "assets/images/empty.png";
// import DefaultTeacherImage from 'assets/images/teacher_default_thumbnail_image.png';
import DefaultNoImage from "assets/images/no_image.jpeg";
import moment from "moment";
import { checkValidName } from "common/utils";
import {
  LACard,
  LACardTitle,
  LAComboBox,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LATextEditor_forTeacherDetailContents,
  LAKeywords,
  LAWarningLabel,
  LAGridItem,
  LADeletableListView,
  LAFileAttachment,
  useComboBox,
  useInput,
  useKeywords,
  useFileAttachment,
  LAImage,
} from "modules/Widgets";

function TeacherDetail({ teacherSeq, onClickList }) {
  // let { seq } = useParams();
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { action } = useLoginContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [teacherInfo, setTeacherInfo] = useState();
  // const [teacherKeywords, setTeacherKeywords] = useState({});

  const nickName = useInput("", 0, "name");

  const { keywordList } = useKeywordContext();
  const keywordsSelector = useKeywords([]);
  const keywordInput = useInput("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const thumbnailFile = useFileAttachment(2048);

  const indroInput = useInput("");
  const getHistoryEditorValue = useRef();

  const [alliances, setAlliances] = useState([]);
  const selectedAlliance = useComboBox("");
  const erpCodeInput = useInput("");
  const bankNameInput = useInput("");
  const bankNumberInput = useInput("");
  const bankAccountInput = useInput("");

  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  // const navigateBack = () => {
  //   navigate(-1, { replace: true });
  // };

  useEffect(() => {
    requestTeacherInfo();
  }, []);

  useEffect(() => {
    alliances.forEach((alliance) => {
      if (
        teacherInfo &&
        teacherInfo.affiliation_code == alliance.alliance_code
      ) {
        selectedAlliance.select(teacherInfo.affiliation_code);
      }
    });
  }, [teacherInfo, alliances]);

  const setTeacher = (data) => {
    // setTeacherKeywords(data.keywords);
    setTeacherInfo(data);
  };

  useEffect(() => {
    if (teacherInfo) {

      indroInput.update(teacherInfo.introduction);

      nickName.update(teacherInfo.nick_name);

      let newKeywords = [];
      if (teacherInfo && teacherInfo.keywords) {
        teacherInfo.keywords.forEach((keyword, ind) => {
          newKeywords.push({ index: ind, value: keyword.keyword });
        });
        setSelectedKeywords(newKeywords);
      }

      if (teacherInfo.profile_image_url) {
        thumbnailFile.update({
          file: teacherInfo.profile_image_url,
        });
      } else {
        thumbnailFile.update({
          file: DefaultNoImage,
          info: new File([DefaultNoImage], "no_image.jpeg", {
            type: "image/jpeg",
          }),
        });
      }

      erpCodeInput.update(teacherInfo.sap_erp_code || "");
      bankNameInput.update(teacherInfo.bank_name || "");
      bankNumberInput.update(teacherInfo.bank_number || "");
      bankAccountInput.update(teacherInfo.bank_account || "");
    }
  }, [teacherInfo]);

  const onKeywordListChange = useCallback(
    (e) => {
      // keywordsSelector.handleClick(e);

      let checker = selectedKeywords.filter(
        (data) => e.target.value != data.value
      );

      if (checker.length == selectedKeywords.length) {
        checker.push({ index: checker.length, value: e.target.value });

        requestAddKeyword({ teacher_seq: teacherSeq, keyword: e.target.value });

        setSelectedKeywords(checker);
      }
    },
    [keywordsSelector.state, selectedKeywords]
  );

  const { request: requestTeacherInfo } = useCommonQuery({
    query: queries.Teachers.teacherInfo,
    params: {
      teacher_seq: teacherSeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setTeacherInfo(data.result_data);
          requestAlliances();
        } else {
          showAlert("알림", data.result_message, {
            confirmHandler: onClickList,
          });
        }
      },
      onError: (error) => {
        showAlert("알림", "선생님 정보 조회에 실패했습니다.", {
          confirmHandler: onClickList,
        });
      },
    },
  });

  const { request: requestAlliances } = useCommonQuery({
    query: queries.Alliance.getAllianceCodes,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code == "0000") {
          setAlliances(data.result_data);
          return;
        }

        // showAlert('알림', data.result_message)
      },
      onError: (error) => {
        showAlert("알림", "업체 정보 조회에 실패하였습니다.");
      },
    },
  });

  const onAddKeywordClick = useCallback(() => {
    if (!keywordInput.state || keywordInput.state.length < 1) {
      showAlert("알림", "키워드는 한자 이상 입력하세요.");
      return;
    }

    let checker = selectedKeywords.filter(
      (data) => keywordInput.state != data.value
    );

    if (checker.length == selectedKeywords.length) {
      checker.push({ index: checker.length, value: keywordInput.state });
      requestAddKeyword({
        teacher_seq: teacherSeq,
        keyword: keywordInput.state,
      });
      setSelectedKeywords(checker);
      keywordInput.update("");
    }
  }, [keywordInput.state, selectedKeywords]);

  const onDeleteKeyword = useCallback(
    (keywordIndex) => {

      const keyword = selectedKeywords.find(
        (data, index) => index == keywordIndex
      );
      if (keyword) {
        requestRemoveKeyword({
          teacher_seq: teacherSeq,
          keyword: keyword.value,
        });
        let result = selectedKeywords.filter(
          (data, index) => index != keywordIndex
        );
        setSelectedKeywords(result);
      }
    },
    [selectedKeywords]
  );

  const { request: requestUpdateTeacher } = useCommonMutation({
    query: queries.Teachers.updateTeacher,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "선생님 정보가 변경되었습니다.");
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "선생님 정보 변경에 실패했습니다.");
      },
    },
  });

  const { request: requestAddKeyword } = useCommonMutation({
    query: queries.Teachers.addKeyword,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "키워드 추가에 실패했습니다.");
      },
    },
  });

  const { request: requestRemoveKeyword } = useCommonMutation({
    query: queries.Teachers.removeKeyword,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "키워드 삭제에 실패했습니다.");
      },
    },
  });

  const { request: requestUpdateThumbnail } = useCommonMutation({
    query: queries.Teachers.changeThumbnailImage,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "선생님 썸네일 이미지가 변경되었습니다.");
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "선생님 썸네일 이미지 변경에 실패했습니다.");
      },
    },
  });

  const onChangeThumbnailClick = useCallback(() => {
    if (!thumbnailFile.state.info) {
      showAlert("알림", "선생님 썸네일 이미지를 선택하세요.");
      return;
    }
    showConfirm(
      "알림",
      "선생님 썸네일 이미지를 변경하시겠습니까?\n프론트에 즉시 반영됩니다.",
      {
        confirmHandler: () => {
          const formData = new FormData();
          formData.append("thumbnail_image", thumbnailFile.state.info);
          requestUpdateThumbnail({ teacher_seq: teacherSeq, data: formData });
          // requestUpdateTeacher({
          //   teacher_seq:seq,
          //   introduction:indroInput.state,history_html:getHistoryEditorValue.current?getHistoryEditorValue.current():''
          // }
          // )},
        },
      },
      [showConfirm, teacherSeq]
    );
  });

  const onBackClick = useCallback(() => {
    onClickList();
  }, []);

  const onSaveClick = useCallback(() => {

    if (!checkValidName(nickName.state)) {
      showAlert("알림", "선생님 닉네임은 특수문자를 입력할 수 없습니다.");
      return;
    }

    showConfirm(
      "알림",
      "선생님 정보를 저장하시겠습니까?\n프론트에 즉시 반영됩니다.",
      {
        confirmHandler: () => {
          requestUpdateTeacher({
            teacher_seq: teacherSeq,
            introduction: indroInput.state,
            history_html: getHistoryEditorValue.current.innerHTML,
            nick_name: nickName.state,
            affiliation_code: selectedAlliance.state,
            sap_erp_code: erpCodeInput.state,
            bank_name: bankNameInput.state,
            bank_number: bankNumberInput.state,
            bank_account: bankAccountInput.state,
          });
        },
      }
    );
  }, [
    showConfirm,
    teacherSeq,
    indroInput,
    getHistoryEditorValue,
    erpCodeInput.state,
    bankNameInput.state,
    bankNumberInput.state,
    bankAccountInput.state,
  ]);

  const getAllianceList = useMemo(() => {
    var list = [];
    if (alliances && alliances.length > 0) {
      list = alliances.map((a) => {
        return { key: a.alliance_code, value: a.alliance_name };
      });
    }
    return list;
  }, [alliances]);

  return (
    <>
      {/* Start Info Area */}
      <LACard>
        <LACardTitle title="선생님 기본 정보" />
        <LAProperty name="이름">
          <LALabel wPercent={30}>
            {teacherInfo && teacherInfo.teacher_name
              ? teacherInfo.teacher_name
              : ""}
          </LALabel>
        </LAProperty>

        <LAProperty name="닉네임">
          <LAInput
            wPercent={30}
            value={nickName.state}
            onChange={nickName.handleChange}
          />
        </LAProperty>

        <LAProperty name="소속">
          {authMemberType == 1 && authAdminAuthType == 1 ? (
            <LAComboBox
              wPercent={30}
              items={getAllianceList}
              value={selectedAlliance.state}
              onChange={selectedAlliance.handleChange}
            />
          ) : (
            <LALabel wPercent={10}>
              {teacherInfo && teacherInfo.alliance_name
                ? teacherInfo.alliance_name
                : ""}
            </LALabel>
          )}
        </LAProperty>

        <LAProperty name="웅진씽크빅 사번">
          <LAInput
            wPercent={30}
            value={erpCodeInput.state}
            onChange={erpCodeInput.handleChange}
          />
        </LAProperty>
        <LAProperty name="은행명">
          {" "}
          <LAInput
            wPercent={30}
            value={bankNameInput.state}
            onChange={bankNameInput.handleChange}
          />
        </LAProperty>
        <LAProperty name="계좌번호">
          <LAInput
            wPercent={30}
            value={bankNumberInput.state}
            onChange={bankNumberInput.handleChange}
          />
        </LAProperty>
        <LAProperty name="예금주">
          <LAInput
            wPercent={30}
            value={bankAccountInput.state}
            onChange={bankAccountInput.handleChange}
          />
        </LAProperty>
        {/* <LAProperty name="아이디">
          <LALabel wPercent={10} clickable="true" content={``}></LALabel>
        </LAProperty> */}
        <LAProperty name="연락처">
          <LALabel wPercent={50}>
            {teacherInfo && teacherInfo.mobile_no}
          </LALabel>
        </LAProperty>
        <LAProperty name="이메일">
          <LALabel wPercent={50}>{teacherInfo && teacherInfo.email}</LALabel>
        </LAProperty>
        {/* <LAProperty name="승인">
          <LAGridContainer>
            <LALabel wPercent={10}>{teacherInfo.approved_yn?'승인':'미승인'}</LALabel>
            <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }}>
              승인
            </LAButton>
          </LAGridContainer>
        </LAProperty> */}
        <LAProperty name="최근 방문일">
          <LALabel wPercent={40}>
            {teacherInfo && teacherInfo.signlog && teacherInfo.signlog.signin_dt
              ? moment(teacherInfo.signlog.signin_dt).format(
                  "YYYY-MM-DD (dd) a hh:mm"
                )
              : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="로그인 IP">
          <LALabel wPercent={40}>
            {teacherInfo &&
            teacherInfo.signlog &&
            teacherInfo.signlog.remote_address
              ? teacherInfo.signlog.remote_address
              : ""}
          </LALabel>
        </LAProperty>
        <LAProperty name="가입일">
          <LALabel wPercent={40}>
            {teacherInfo && teacherInfo.created_dt
              ? moment(teacherInfo.created_dt).format("YYYY-MM-DD (dd) A hh:mm")
              : ""}
          </LALabel>
        </LAProperty>
      </LACard>
      {/* End Info Area */}

      <LACard next>
        <LACardTitle title="선생님 키워드" />

        <LAProperty name="키워드 선택">
          <LAKeywords
            keywords={keywordList}
            selectedKeywords={keywordsSelector.state}
            onClick={onKeywordListChange}
          />
        </LAProperty>

        <LAProperty name="키워드 직접 입력">
          <LAGridContainer>
            <LALabel wPercent={2}>#</LALabel>
            <LAInput
              wPercent={30}
              placeholder="키워드 텍스트 입력 (ex. #라이브올 #섬세한 #재밌는 등)"
              layouts={{ pr: { sm: 1 } }}
              value={keywordInput.state}
              onChange={keywordInput.handleChange}
              onEnterKeyPressed={onAddKeywordClick}
            />
            <LAInput wPercent={1} props={{ sx: { display: "none" } }} />
            <LAButton wPercent={10} onClick={onAddKeywordClick}>
              추가
            </LAButton>
            <LAWarningLabel wPercent={55} layouts={{ pl: { sm: 1 } }}>
              추가 삭제 시 실시간으로 반영됩니다.
            </LAWarningLabel>
          </LAGridContainer>
          {selectedKeywords && selectedKeywords.length > 0 && (
            <LAGridItem wPercent={30}>
              <LADeletableListView
                layouts={{ pt: 1 }}
                headers={[]}
                items={selectedKeywords}
                onDeleteItem={onDeleteKeyword}
              />
            </LAGridItem>
          )}
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle
          title="선생님 이미지 정보"
          content="이미지 변경 시 실시간으로 반영됩니다."
        />

        <LAProperty name="선생님 이미지 정보(섬네일)">
          <LAGridContainer>
            <LAImage wPercent={25} file={thumbnailFile.state.file} />
            <LAGridItem wPercent={67} layouts={{ ml: 2 }}>
              <LAGridContainer layouts={{ ml: 1, pl: 1 }}>
                <LAFileAttachment
                  hiddenLabel
                  placeholder=""
                  props={{
                    accept: "image/jpg,image/png,image/jpeg,image/gif",
                  }}
                  value={thumbnailFile.state.name}
                  onChange={thumbnailFile.handleChange}
                />
              </LAGridContainer>
              <MDBox color="text" fontSize="1rem" lineHeight={1}>
                <LALabel
                  layouts={{ pl: 1, pt: 1 }}
                  props={{
                    variant: "caption",
                    color: "error",
                    fontWeight: "regular",
                    verticalAlign: "middle",
                    sx: {
                      color: "#ff0000",
                    },
                  }}
                >
                  - 이미지 등록 시, 리스트 및 클래스 상세의 선생님 이미지로
                  자동으로 등록됩니다.
                  <br />
                  - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)
                  <br />
                  - 이미지 사이즈 : 1000 * 1000 / 형식 : jpg, jpeg, png (jpeg
                  등록 권장)
                  <br />- 2MB 이하로 등록가능
                </LALabel>
                <br />
                <LAButton
                  wPercent={15}
                  layouts={{ pl: { sm: 1 } }}
                  onClick={onChangeThumbnailClick}
                >
                  변경하기
                </LAButton>
              </MDBox>
            </LAGridItem>
          </LAGridContainer>
        </LAProperty>
      </LACard>
      <LACard layouts={{ pt: 1 }}>
        <LACardTitle
          title="선생님 소개 정보"
          content="⊛ 프론트 화면에 노출되는 항목입니다. 신중히 입력해 주세요."
        />
        <LAProperty name="선생님 한줄 소개" required>
          <LAInput
            wPercent={100}
            layouts={{ pr: { sm: 1 } }}
            value={indroInput.state}
            onChange={indroInput.handleChange}
          />
        </LAProperty>

        <LAProperty name="선생님 소개 & 이력사항" required>
          <LATextEditor_forTeacherDetailContents
            name={teacherInfo?.history_html ? "history_html" : "history_html_e"}
            value={teacherInfo?.history_html}
            getEditorValue={getHistoryEditorValue}
          />
        </LAProperty>
      </LACard>
      {/* End Detail Info Area */}

      <LAGridContainer layouts={{ pt: { sm: 1 } }}>
        <LALabel wPercent={35}></LALabel>
        {authMemberType == 1 && authAdminAuthType == 1 ? (
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={"outlined"}
            onClick={onBackClick}
          >
            목록
          </LAButton>
        ) : (
          <></>
        )}
        <LAButton
          wPercent={15}
          layouts={{ pl: { sm: 1 } }}
          onClick={onSaveClick}
        >
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>
    </>
  );
}

export default TeacherDetail;
