// =============================
// 단축 url
// =============================
const digits0 = [
  "L",
  "6",
  "y",
  ")",
  "o",
  "Z",
  "j",
  "c",
  "i",
  "C",
  "S",
  "M",
  "~",
  "_",
  "H",
  "G",
  "s",
  "D",
  "J",
  "r",
  "b",
  "7",
  "m",
  "d",
  "W",
  "a",
  "`",
  "R",
  "(",
  "F",
  "-",
  "f",
  "9",
  "q",
  "k",
  "p",
  "U",
  "A",
  "P",
  "Y",
  "0",
  "w",
  "+",
  "n",
  "2",
  "X",
  "3",
  "8",
  "h",
  "l",
  "T",
  "u",
  "E",
  "t",
  "I",
  "5",
  "x",
  "4",
  "N",
  "O",
  "1",
  "v",
  "g",
  "K",
  "e",
  "z",
  "B",
  "Q",
];
const digits1 = [
  "8",
  "m",
  "6",
  "D",
  "~",
  "E",
  "z",
  "_",
  "o",
  "G",
  ")",
  "K",
  "h",
  "p",
  "`",
  "Y",
  "L",
  "r",
  "q",
  "S",
  "M",
  "!",
  "w",
  "1",
  "n",
  "W",
  "C",
  "u",
  "s",
  "B",
  "Q",
  "U",
  "F",
  "i",
  "N",
  "9",
  "5",
  "-",
  "T",
  "Z",
  "A",
  "e",
  "g",
  "k",
  "b",
  "v",
  "H",
  "7",
  "d",
  "3",
  "x",
  "j",
  "J",
  "0",
  "O",
  "I",
  "a",
  "(",
  "R",
  "f",
  "4",
  "t",
  "l",
  "X",
  "+",
  "2",
  "y",
  "V",
];
const digits2 = [
  "r",
  "y",
  "n",
  "e",
  "b",
  "f",
  "D",
  "x",
  "q",
  "_",
  "p",
  "-",
  "l",
  "z",
  "!",
  "~",
  "a",
  "k",
  "6",
  "H",
  "O",
  "t",
  "C",
  "N",
  "L",
  "8",
  "A",
  "`",
  "1",
  "W",
  "m",
  "o",
  "w",
  "U",
  "J",
  "s",
  "Y",
  "R",
  "X",
  "v",
  "5",
  "7",
  "M",
  "i",
  "+",
  "d",
  "h",
  "Z",
  "g",
  "3",
  "j",
  "c",
  "E",
  "V",
  ")",
  "0",
  "I",
  "Q",
  "P",
  "F",
  "S",
  "(",
  "4",
  "K",
  "G",
  "u",
  "9",
  "2",
];
const digits3 = [
  "X",
  "f",
  "R",
  "S",
  "M",
  "O",
  "I",
  "P",
  "x",
  "Z",
  "+",
  "u",
  "`",
  "G",
  "r",
  "q",
  "b",
  "0",
  "y",
  "d",
  "D",
  "k",
  "T",
  "L",
  "6",
  "J",
  "a",
  "-",
  "o",
  "U",
  "K",
  "1",
  "2",
  "l",
  "t",
  "_",
  "Y",
  "W",
  "8",
  "c",
  "g",
  "(",
  ")",
  "p",
  "s",
  "B",
  "V",
  "A",
  "v",
  "Q",
  "5",
  "i",
  "e",
  "9",
  "!",
  "n",
  "h",
  "m",
  "3",
  "j",
  "7",
  "H",
  "C",
  "F",
  "E",
  "z",
  "~",
  "N",
];
const digits4 = [
  "M",
  "q",
  "k",
  "b",
  "f",
  "r",
  "o",
  "Y",
  "2",
  "w",
  "u",
  "+",
  "J",
  "h",
  "X",
  "`",
  "H",
  "x",
  "z",
  "9",
  "d",
  "B",
  "P",
  "_",
  "L",
  "C",
  "i",
  "V",
  "G",
  "A",
  "8",
  "(",
  "I",
  "g",
  "~",
  "-",
  "n",
  "1",
  "W",
  "4",
  "a",
  "6",
  "D",
  "U",
  "Z",
  "R",
  "O",
  "K",
  "c",
  "!",
  "S",
  "s",
  "m",
  "Q",
  "5",
  "3",
  "j",
  ")",
  "T",
  "t",
  "7",
  "l",
  "y",
  "p",
  "v",
  "E",
  "e",
  "0",
];
const digits5 = [
  "4",
  "O",
  "N",
  "1",
  "(",
  "W",
  "h",
  "k",
  "u",
  "c",
  "d",
  "x",
  "q",
  "V",
  "+",
  "J",
  "H",
  "3",
  "A",
  "C",
  "n",
  "`",
  "E",
  "w",
  "z",
  "!",
  "~",
  "6",
  "0",
  "X",
  "I",
  "D",
  "y",
  "o",
  "i",
  "v",
  "_",
  "2",
  "5",
  "P",
  "G",
  "f",
  "B",
  "j",
  "7",
  "r",
  "-",
  "L",
  "U",
  ")",
  "Y",
  "Q",
  "t",
  "K",
  "l",
  "b",
  "R",
  "Z",
  "F",
  "p",
  "S",
  "8",
  "e",
  "m",
  "a",
  "g",
  "M",
  "s",
];
const digits6 = [
  "f",
  "T",
  "P",
  "0",
  "p",
  "N",
  "!",
  "3",
  "G",
  "Z",
  "(",
  "R",
  "B",
  "D",
  "M",
  "_",
  "+",
  "9",
  "y",
  "g",
  "5",
  "W",
  "l",
  "j",
  "2",
  "4",
  "A",
  "a",
  "i",
  ")",
  "c",
  "r",
  "s",
  "C",
  "K",
  "8",
  "u",
  "Q",
  "E",
  "z",
  "n",
  "6",
  "F",
  "U",
  "b",
  "I",
  "e",
  "H",
  "S",
  "t",
  "7",
  "d",
  "-",
  "o",
  "h",
  "Y",
  "L",
  "X",
  "`",
  "O",
  "m",
  "~",
  "k",
  "x",
  "J",
  "q",
  "v",
  "V",
];
const digits7 = [
  "a",
  "m",
  "k",
  "M",
  "T",
  "n",
  "x",
  "l",
  ")",
  "J",
  "O",
  "y",
  "p",
  "5",
  "b",
  "H",
  "D",
  "E",
  "u",
  "!",
  "A",
  "7",
  "W",
  "Z",
  "o",
  "I",
  "9",
  "0",
  "f",
  "G",
  "F",
  "c",
  "h",
  "4",
  "8",
  "j",
  "i",
  "P",
  "1",
  "K",
  "6",
  "S",
  "Y",
  "X",
  "s",
  "g",
  "w",
  "`",
  "V",
  "2",
  "C",
  "L",
  "e",
  "U",
  "z",
  "q",
  "t",
  "3",
  "(",
  "-",
  "r",
  "v",
  "+",
  "~",
  "d",
  "_",
  "N",
  "R",
  "Q",
];
const digits8 = [
  "p",
  "l",
  "D",
  "7",
  "k",
  "a",
  "H",
  "S",
  "f",
  "W",
  "A",
  "r",
  "Y",
  "1",
  "J",
  "!",
  "E",
  "d",
  "g",
  "T",
  "y",
  "i",
  "w",
  "4",
  "5",
  "X",
  "P",
  "t",
  "L",
  "e",
  "R",
  "c",
  "m",
  "I",
  "+",
  "h",
  "9",
  "`",
  "0",
  "z",
  "C",
  "-",
  "F",
  "6",
  "B",
  "j",
  "q",
  "U",
  "V",
  "G",
  "(",
  ")",
  "K",
  "8",
  "n",
  "b",
  "3",
  "Q",
  "u",
  "s",
  "Z",
  "v",
  "M",
  "N",
  "_",
  "x",
  "O",
  "o",
];
const digits9 = [
  "0",
  "L",
  "e",
  "f",
  "c",
  "y",
  "V",
  "o",
  "p",
  "!",
  "A",
  "W",
  "r",
  "J",
  "b",
  "8",
  "u",
  "q",
  "1",
  "2",
  "Q",
  "C",
  "j",
  ")",
  "7",
  "Z",
  "F",
  "t",
  "D",
  "N",
  "B",
  "-",
  "E",
  "a",
  "9",
  "s",
  "3",
  "4",
  "k",
  "G",
  "6",
  "z",
  "x",
  "w",
  "(",
  "m",
  "l",
  "K",
  "H",
  "U",
  "g",
  "5",
  "_",
  "T",
  "P",
  "n",
  "h",
  "`",
  "M",
  "~",
  "d",
  "O",
  "i",
  "R",
  "+",
  "S",
  "Y",
  "X",
  "I",
];
export const digits = [
  digits0,
  digits1,
  digits2,
  digits3,
  digits4,
  digits5,
  digits6,
  digits7,
  digits8,
  digits9,
];
