import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BasicLayout from "pages/Common/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useLoginContext } from "common/context/MemberContext";
import { LACard, LAGridContainer, LAGridItem } from "modules/Widgets";
import { LAButton } from "modules/Widgets";

import ClassImg from "assets/images/together_class.png";
import OrderImg from "assets/images/together_order.png";
import DeliveryImg from "assets/images/together_delivery.png";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

function TogetherMain() {
  const navigate = useNavigate();
  const { showAlert } = usePopAlert();
  const { isLogin, loginState } = useLoginContext();

  useEffect(() => {
    if (isLogin) {
      if (loginState.auth == undefined) {
        alert();
      }

      navigate("/together");
    }
    return () => {};
  }, [isLogin, navigate]);

  const onGoClassClick = useCallback(() => {
    if (!isLogin) {
      showAlert("알림", "로그인 후 이용가능합니다.");
      return;
    }
    navigate("/togetherClass/management");
  }, [isLogin]);

  const onGoScheduleClick = useCallback(() => {
    if (!isLogin) {
      showAlert("알림", "로그인 후 이용가능합니다.");
      return;
    }
    navigate("/togetherOrder/management");
  }, [isLogin]);

  const onGoOrderDeliveryClick = useCallback(() => {
    if (!isLogin) {
      showAlert("알림", "로그인 후 이용가능합니다.");
      return;
    }
    navigate("/togetherOrder/delivery");
  }, [isLogin]);

  return (
    <>
      <DashboardNavbar />
      <BasicLayout image={bgImage}>
        <LAGridContainer>
          <LAGridItem wPercent={20} />
          <LAGridItem wPercent={20} layouts={{ pl: { sm: 1 }, pr: { sm: 1 } }}>
            <LACard>
              <img
                src={ClassImg}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "20px",
                  width: "50%",
                }}
              />
              <MDBox textAlign="center">
                <LAGridItem>
                  <MDTypography variant="h5">투게더 클래스 관리</MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ pt: 2 }}>
                  <LAButton onClick={onGoClassClick}>바로 가기</LAButton>
                </LAGridItem>
              </MDBox>
            </LACard>
          </LAGridItem>

          <LAGridItem wPercent={20} layouts={{ pl: { sm: 1 }, pr: { sm: 1 } }}>
            <LACard>
              <img
                src={OrderImg}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "20px",
                  width: "50%",
                }}
              />
              <MDBox textAlign="center">
                <LAGridItem>
                  <MDTypography variant="h5">투게더 주문 관리</MDTypography>
                </LAGridItem>
                <LAGridItem layouts={{ pt: 2 }}>
                  <LAButton onClick={onGoScheduleClick}>바로 가기</LAButton>
                </LAGridItem>
              </MDBox>
            </LACard>
          </LAGridItem>

          <LAGridItem wPercent={20} layouts={{ pl: { sm: 1 }, pr: { sm: 1 } }}>
            <LACard>
              <img
                src={DeliveryImg}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "20px",
                  width: "50%",
                }}
              />
              <MDBox textAlign="center">
                <LAGridItem>
                  <MDTypography variant="h5">투게더 배송 관리</MDTypography>
                </LAGridItem>

                <LAGridItem layouts={{ pt: 2 }}>
                  <LAButton onClick={onGoOrderDeliveryClick}>
                    바로 가기
                  </LAButton>
                </LAGridItem>
              </MDBox>
            </LACard>
          </LAGridItem>
          <LAGridItem wPercent={20} />
        </LAGridContainer>
      </BasicLayout>
    </>
  );
}

export default TogetherMain;
