import React, { useState, useCallback, useEffect, useMemo } from "react";
// import LALoading from 'modules/Widgets/LALoading';
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
// import { getTodayDateText, dateTimeToString } from 'common/utils';
import { useNavigate, useParams } from "react-router";
import { getAge } from "common/utils";
import moment from "moment";
import { DOMAIN } from "api/AppConfig";
import {
  getTextCell,
  getPrimaryButton,
  getNormalButton,
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LALabel,
  LAButton,
  LADataTable,
  useModal,
} from "modules/Widgets";
import { LAWarningLabel } from "modules/Widgets";
import UserMemoModal from "./UserMemoModal";
import { digits } from "./digists";
import useCommonMutation from "common/hooks/useCommonMutation";

function getEncodeNumber(base, val) {
  if (base > 69) throw new Error();
  let randomInteger = Math.floor(Math.random() * 10);
  let digitsDetail = digits[randomInteger];

  let rtnValue = "";

  if (val == 0) return "0";

  while (val > 0) {
    var tmpVal = val % base;
    rtnValue = digitsDetail[tmpVal] + rtnValue;
    val = Math.floor(val / base);
  }
  rtnValue = String(randomInteger) + "" + rtnValue;

  return rtnValue;
}

function getDecodeNumber(base, val) {
  if (base > 69) throw new Error();
  let tempVal = val.substring(1, String(val).length);
  let digitsNumber = 0;
  try {
    digitsNumber = parseInt(String(val).substring(0, 1));
  } catch (e) {
    return 0;
  }

  let rtnValue = 0;

  let digitsDetail = digits[digitsNumber];

  for (var iCnt = 0; iCnt < tempVal.length; iCnt++) {
    let tempString = tempVal.substring(
      tempVal.length - iCnt - 1,
      tempVal.length - iCnt
    );
    let tempInt = digitsDetail.indexOf(tempString);

    if (iCnt == 0) {
      rtnValue += tempInt;
    } else {
      rtnValue += Math.pow(base, iCnt) * tempInt;
    }
  }
  return rtnValue;
}

const columns = [
  { Header: "No", accessor: "no", width: "3%" },
  { Header: "가입자 아이디", accessor: "id", width: "7%" },
  { Header: "학습자명", accessor: "name", width: "5%" },
  { Header: "성별", accessor: "gender", width: "3%" },
  { Header: "나이", accessor: "age", width: "3%" },
  { Header: "결제자명", accessor: "member_name", width: "5%" },
  { Header: "결제자 휴대폰 번호", accessor: "phone", width: "10%" },
  { Header: "특이 사항", accessor: "remarks", width: "20%" },
  { Header: "상세정보", accessor: "details", width: "5%" },
  { Header: "회원 관리메모", accessor: "userMemo", width: "5%" },
  { Header: "단축url", accessor: "url", width: "15%" },
  { Header: "클래스 관리메모", accessor: "classMemo", width: "5%" },
  { Header: "피드백", accessor: "feedback", width: "5%" },
  { Header: "출석", accessor: "attendance", width: "5%" },
];

function ClassmateModal({ classroomSeq, show, handleClose, onClickFeedback }) {
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();

  const [selectedUserData, setSelectedUserData] = useState(null);
  const [memoType, setMemoType] = useState(0);
  const memoModal = useModal(false);
  const [rows, setRows] = useState([]);

  const onFeedback = (id) => {
    onClickFeedback(id, classroomSeq);
  };

  const onMemoClick = (data) => {
    setSelectedUserData(data);
    setMemoType(0);
    memoModal.visible(true);
  };

  const onClassMemoClick = (data) => {
    setSelectedUserData(data);
    setMemoType(1);
    memoModal.visible(true);
  };

  const onDetailsClick = (data) => {
    const params = {
      classroomSeq: classroomSeq,
      classmateSeq: data.classmate_seq,
    };

    requestClassmateDetail(params);
  };

  const { requestAsync: requestClassmateDetail } = useCommonMutation(
    {
      query: queries.Classroom.getClassmateInfo,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === "0000") {
            const { result_data } = data;
            console.log("onDetailsClick", result_data);
            showAlert(
              "",
              `
            가입자 아이디: ${result_data.email}\n
            학습자명: ${result_data.user_name}\n
            결제자명: ${result_data.member_name}\n
            결제자 휴대폰 번호: ${result_data.phone}
            `
            );
          } else showAlert("알림", data.result_message);
        },
        onError: (error) => {
          showAlert("알림", "조회에 실패했습니다.");
        },
      },
    },
    []
  );

  const dataToRow = (data, index) => {
    let ageString = "";
    if (data.birth) {
      let today = moment(new Date());
      let birthday = moment(data.birth);
      ageString = ` ${getAge(data.birth)}세`;
    }

    let urls = `${DOMAIN}/room/us/`;
    if (process.env.NODE_ENV == "development") {
      //로컬 일 경우
      urls = "localhost:7174/room/us/";
    }

    let encodeTimetableSeq = getEncodeNumber(65, data.timetable_seq);
    let encodeClassmateSeq = getEncodeNumber(65, data.classmate_seq);

    urls = urls + encodeTimetableSeq + "/" + encodeClassmateSeq;

    return {
      no: getTextCell(`${index + 1}`),
      id: getTextCell(`${data.email ? data.email : ""}`),
      name: getTextCell(`${data.user_name}`),
      gender: getTextCell(`${data.gender_type_name}`),
      age: getTextCell(`${ageString}`),
      member_name: getTextCell(`${data.member_name}`),
      phone: getTextCell(`${data.phone}`.replace("+82 ", "0")),
      remarks: getTextCell(`${data.remark ? data.remark : ""}`),
      details: getNormalButton("상세정보", data, onDetailsClick),
      userMemo:
        data.memo_count > 0
          ? getPrimaryButton("회원메모", data, onMemoClick)
          : getNormalButton("회원메모", data, onMemoClick),
      url: getTextCell(`${urls}`),
      classMemo:
        data.class_memo_count > 0
          ? getPrimaryButton("관리메모", data, onClassMemoClick)
          : getNormalButton("관리메모", data, onClassMemoClick),
      feedback: data.classmate_seq
        ? getPrimaryButton("피드백", `${data.classmate_seq}`, onFeedback)
        : getTextCell("작성불가"),
      attendance: getTextCell(data.attendance_yn ? "Y" : "N"),
      // select: getNormalButton('선택', data, onTeacherSelect),
    };
  };

  const { request: requestClassmates } = useCommonQuery({
    query: queries.Classroom.getClassmates,
    params: classroomSeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setRows(
            data.result_data
              ? data.result_data.map((resultData, index) =>
                  dataToRow(resultData, index)
                )
              : []
          );
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "학습자 목록 조회에 실패했습니다.");
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    if (classroomSeq > 0) {
      requestClassmates();
    }
  }, [classroomSeq]);

  return (
    <>
      <LAModal title="" width="80%" height="60%" show={show}>
        <LACard>
          <LACardTitle title="학습자 목록" />
          <LADataTable columns={columns} rows={rows} />
          <LAWarningLabel>
            피드백은 일정 확정 이후 작성 가능합니다.
          </LAWarningLabel>
        </LACard>
        <LAGridContainer pt={2}>
          <LALabel wPercent={80} />
          <LAButton
            wPercent={20}
            layouts={{ pr: { sm: 0.5 } }}
            onClick={handleClose}
          >
            닫기
          </LAButton>
        </LAGridContainer>
      </LAModal>

      <UserMemoModal
        data={selectedUserData}
        type={memoType}
        show={memoModal.state}
        handleClose={memoModal.handleClose}
      />
    </>
  );
}

export default React.memo(ClassmateModal);
