import React, { useState, useCallback, useEffect, useMemo } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useApiContext } from 'common/context/ApiContext';
import { useNavigate } from 'react-router-dom';
import LACalendar from 'modules/Widgets/LACalendar';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import { useLoginContext } from 'common/context/MemberContext';

import { DOMAIN } from 'api/AppConfig';

import {
    LACard,
    LADataTable,
    LALabel,
    LAProperty,
    getTextCell,
    getNormalButton,
    getLinkTextCell,
    useModal,
} from 'modules/Widgets';

import moment from 'moment';
import ClassmateModal from '../components/ClassmateModal';
import ClassmateAttendanceModal from '../components/ClassmateAttendanceModal';
import FeedbackContentsModal from '../../InquiryManagement/components/FeedbackContentsModal';
import MDTypography from 'components/MDTypography';

const columns = [
    { Header: 'No', accessor: 'no', width: '3%' },
    { Header: '수업번호', accessor: 'timetableSeq', width: '6%' },
    { Header: '카테고리', accessor: 'category', width: '6%', align: 'left' },
    { Header: '진도 유형', accessor: 'type', width: '6%' },
    { Header: '클래스 명', accessor: 'name', width: '14%', align: 'left' },
    { Header: '수업 시간', accessor: 'time', width: '8%' },
    { Header: '수업 종료시간', accessor: 'endtime', width: '8%' },
    { Header: '수업 회차', accessor: 'round', width: '8%' },
    { Header: '정규/보강', accessor: 'supplementary', width: '6%' },
    { Header: '학습 인원', accessor: 'studentCount', width: '7%' },
    { Header: '선생님', accessor: 'teacher', width: '4%' },
    { Header: '학습자', accessor: 'students', width: '6%' },
    { Header: '화상방 접속', accessor: 'classroom', width: '6%' },
    { Header: '수업현황', accessor: 'attendance', width: '6%' },
    { Header: '매출여부', accessor: 'SaleConfirm', width: '6%' },
];

function ClassCalendar() {
    const navigate = useNavigate();
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();
    const { isLogin, isTeacher, action, loginState } = useLoginContext();
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    // const [queryMonth,setQueryMonth] = useState(moment().format('YYYYMM'))

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [selectedClassroomSeq, setSelectedClassroomSeq] = useState(-1);
    const [selectedTimetableSeq, setSelectedTimetableSeq] = useState(-1);
    const classmateModal = useModal(false);
    const attendanceModal = useModal(false);

    const [selectedClassmateSeq, setSelectedClassmateSeq] = useState(0);

    const feedbackContentModal = useModal(false);

/*  useEffect(() => {
    requestCalendar();
  }, []);*/

    const accessToken = useMemo(() => {
        return action.getAccessToken();
    }, [action]);

    const memberType = useMemo(() => {
        return action.getMemberType();
    }, [action]);

    const authType = useMemo(() => {
        return action.getAuthType();
    }, [action]);

    const [calendarLoadInterval, setCalendarLoadInterval] = useState(0);

  const startInterval = useCallback(() => {
    const currentUrl = window.location.href;
    console.log('currentUrl ::', currentUrl);

    if (currentUrl.includes("/class/calendar")) {
      if (calendarLoadInterval) {
        return;
      }

      let interval = setInterval(() => {
        requestCalendar();
      }, 60000);

      setCalendarLoadInterval(interval);
    }
  }, [calendarLoadInterval]);


  const stopInterval = useCallback(() => {
    if (calendarLoadInterval) {
      clearInterval(calendarLoadInterval);
      setCalendarLoadInterval(0);
    }
  }, [calendarLoadInterval]);


  useEffect(() => {
    // 페이지 진입 시 타이머 시작
    startInterval();

    // 페이지를 벗어날 때 타이머 중지
    return () => {
      stopInterval();
    };
  }, [startInterval, stopInterval]);

    // const enterRoomClick = useCallback((e)=>{
    //   window.open(`https://dev.liveall.co.kr/room/u/${classroomInfo.classroom_seq}?accessToken=${loginState.accessToken}`, '_blank')
    // },[classroomInfo])

    const { result, request: requestCalendar } = useCommonQuery({
        query: queries.Calendar.getCalendar,
        params: {
            // query_month: queryMonth ? queryMonth : undefined,
            start_dt: startDate,
            end_dt: endDate,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    console.log('onSuccess', selectedDate);
                    setData(data.result_data);
                    if (!selectedDate || selectedDate == '') {
                        setSelectedDate(new Date());
                    }

          //startInterval();
        } else {
        }
      },
      onError: (error) => {
      },
    },
  });

    const { request: requestEnterRoom } = useCommonMutation({
        query: queries.Classroom.enterClassRoom,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    // showAlert('알림', '클래스 접수 상태가 변경되었습니다.');
                    showAlert('알림', '화상방 입장 요청에 성공하였습니다.');
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '화상방 입장 요청에 실패하였습니다.');
            },
        },
    });

    const { request: requestUpdateRoomStatus } = useCommonMutation({
        query: queries.Room.updateRoomStatus,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', data.result_message);
                    requestCalendar();
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                console.log(error);
            },
        },
    });

    function groupByKey(array, key) {
        return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)});
        }, {});
    }

    const calendarItems = useMemo(() => {
        var list = [];
        if (data && data.length > 0) {
            if (isTeacher) {
                list = data.map((row) => {
                    const date = moment(`${row.schedule_date} ${row.schedule_time}`);
                    return {
                        id: row.classroom_seq,
                        title: row.class_name,
                        date: date.format('YYYY-MM-DD'),
                        time: date.format('HH:mm:ss'),
                    };
                });
            } else {
                let grouped = groupByKey(data, 'schedule_date');
                list = Object.keys(grouped).map((key, index) => {
                    const date = moment(`${key}`);
                    return {
                        id: key,
                        title: `${grouped[key].length} class`,
                        date: date.format('YYYY-MM-DD'),
                        // ,time : '00:00'
                    };
                });
            }
        }
        return list;
    }, [data]);

    const onEnterRoomClick = useCallback((e) => {
        const today = moment().format('YYYY-MM-DD');

        if(moment(selectedDate).format('YYYY-MM-DD') === today) {
            showConfirm('확인', '화상방에 입장하시겠습니까?', {
                confirmHandler: () => {
                    // onClick={enterRoomClick}
                    //requestEnterRoom({classroomSeq:e.classroom_seq,data:{room_id:e.timetable_seq}})
    
                    window.open(`${DOMAIN}/room/t/${e.classroom_seq}?accessToken=${accessToken}&ts=${e.timetable_seq}`, '_blank');
                },
            });
        } else {
            showConfirm('확인', `※ 수업 날짜가 오늘이 아닙니다.\n 화상방에 입장하시겠습니까?`, {
                confirmHandler: () => {
                    window.open(`${DOMAIN}/room/t/${e.classroom_seq}?accessToken=${accessToken}&ts=${e.timetable_seq}`, '_blank');
                }
            });
        }
        
    });

    const onEnterRoomMonitorClick = useCallback((e) => {
        showConfirm('안내', `수업 모니터링(옵서버) 모드로 입장합니다.\n마이크/카메라/진행자/참여자 권한이 모두 없으며,\n입장하더라도 수업 환경에 일체 관여되지 않습니다.
            \n(선생님용 수업방 URL) ${DOMAIN}/room/t/${e.classroom_seq}?ts=${e.timetable_seq}\n위의 URL은 선생님 권한으로 입장되므로 유의해주세요.`, {
            confirmHandler: () => {
                // onClick={enterRoomClick}
                //requestEnterRoom({classroomSeq:e.classroom_seq,data:{room_id:e.timetable_seq}})
                // TODO : 2022-09-15 : 김갑석 -> 모니터링 모드로 입장할 경로 추가 해야함.
                window.open(`${DOMAIN}/room/ov/${e.classroom_seq}?accessToken=${accessToken}&ts=${e.timetable_seq}`, '_blank');
            },
        });
    });

    const onClassmatesClick = useCallback((e) => {
        setSelectedClassroomSeq(e);
        classmateModal.visible(true);
    });

    const onAttandanceClick = useCallback((e) => {
        setSelectedTimetableSeq(e);
        setSelectedClassroomSeq(e.classroom_seq);
        attendanceModal.visible(true);
    });

    const onDateSelected = useCallback((e) => {
        setSelectedDate(e.startStr);
    });

    const onMonthChanged = useCallback((e, b) => {
        stopInterval();
        setStartDate(e.startStr);
        setEndDate(e.endStr);

        //timezone오류로 없는 경우는 new Date()활용
        let ctxDate = selectedDate?e.view.getCurrentData().currentDate:new Date();
        setSelectedDate(ctxDate);
    });

    const onClickFeedback = useCallback((classmate_seq, classroom_seq) => {
        setSelectedClassmateSeq(classmate_seq);
        setSelectedClassroomSeq(classroom_seq);
        feedbackContentModal.visible(true);
    });

    const getClassType = useCallback((item) => {
        var result;
        switch (item.progress_type) {
            case 0:
                result = '원데이';
                break;
            case 1:
                result = '순환';
                break;
            case 2:
                result = '패키지';
                break;
        }

        if (item.test_mode_yn) {
            result += '[시크릿]';
        }
        return result;
    });

    /* 2023-07-31 수업방 상태 초기화 추가 */
    const resetConfirm = useCallback((data) => {
        showConfirm('알림', `수업 상태를 초기화 하시겠습니까?`, {
            confirmHandler: () => requestUpdateRoomStatus({ classroom_seq: data.classroom_seq, timetable_seq: data.timetable_seq, roomStatus: "R" })
        });
    },[requestCalendar]);

    const listItems = useMemo(() => {
        let list = [];
        
        if (selectedDate && selectedDate.length == 0) return list;

        if (data && data.length > 0) {
            const filtered = data.filter((row) => row.schedule_date == moment(selectedDate).format('YYYYMMDD'));
            
            return filtered.map((row, index) => {
                const date = moment(`${row.schedule_date} ${row.schedule_time}`);
                console.log('row ::',row);
                return {
                    no: getTextCell(index + 1),
                    timetableSeq: getTextCell(row.timetable_seq),
                    category: getTextCell(row.category_name),
                    type: getTextCell(getClassType(row)),
                    name: getTextCell(row.class_name),
                    time: getTextCell(date.format('A hh:mm')),
                    endtime: getTextCell(date.add(row.class_time, 'minutes').format('A hh:mm')),
                    round: getTextCell(`${row.curriculum_no} / ${row.curriculum_count}회`),
                    supplementary: getTextCell(row.supplementary_yn == 1 ? '보강' : '정규'),
                    studentCount: getTextCell(`${row.classmate_count}명`),
                    teacher: getTextCell(row.teacher_name),
                    students: getNormalButton('목록', `${row.classroom_seq}`, onClassmatesClick),
                    // classroom: row.is_teacher?(row.done_yn == 1)?getTextCell('수업종료') : getNormalButton('접속',{classroom_seq:row.classroom_seq,timetable_seq:row.timetable_seq}, onEnterRoomClick):getTextCell('권한없음'),
                    classroom: row.done_yn == 1 ? getTextCell('수업종료') : row.is_teacher ? getNormalButton('접속', {classroom_seq: row.classroom_seq, timetable_seq: row.timetable_seq}, onEnterRoomClick) : getNormalButton('모니터링', {classroom_seq: row.classroom_seq, timetable_seq: row.timetable_seq}, onEnterRoomMonitorClick),
                    //attendance: (row.begin_yn || row.done_yn) ? getNormalButton('결과',{timetable_seq:`${row.timetable_seq}`,class_name:row.class_name}, onAttandanceClick) : '',
                    attendance: row.done_yn == 1 || row.room_status == 'E' ? getNormalButton('결과', {timetable_seq: `${row.timetable_seq}`, class_name: row.class_name, classroom_seq: `${row.classroom_seq}`}, onAttandanceClick)
                        : (row.room_status == 'R' || row.room_status == '0') ? '-' 
                            : ((row.room_status == 'W' || row.room_status == '1') && row.open_type == 'S') ? (isTeacher ? getTextCell('회원대기') : getLinkTextCell('회원대기', {}, resetConfirm, {classroom_seq: row.classroom_seq, timetable_seq: row.timetable_seq}))
                            : ((row.room_status == 'W' || row.room_status == '1') && row.open_type == 'T') ? (isTeacher ? getTextCell('수업열기') : getLinkTextCell('수업열기', {}, resetConfirm, {classroom_seq: row.classroom_seq, timetable_seq: row.timetable_seq}))
                        : (isTeacher ? getTextCell('수업시작') : getLinkTextCell('수업시작', {}, resetConfirm, {classroom_seq: row.classroom_seq, timetable_seq: row.timetable_seq})),
                    SaleConfirm: getTextCell(row.SaleConfirm)
                    // attendance: getNormalButton('상세', `${row.schedule_seq}`, onsDetailClick),
                };
            });
        }
        return list;
    }, [data, selectedDate]);

    function onsDetailClick(no) {
        showAlert('알림', '준비중입니다.');
        // navigate(`detail/${no}`);
    }

    return (
        <>
            <DashboardNavbar title="화상방 수업 관리" />
            <LACard>
                <LACalendar
                contentHeight="540px"
                events={calendarItems}
                onSelected={onDateSelected}
                onMonthChanged={onMonthChanged}
                />
            </LACard>
            <LACard next>
                <LALabel wPercent={100} props={{fontSize: "1.2rem", fontWeight: "bold", cursor: "default"}} clickable={"#3788d8"} children={selectedDate ? `선택 날짜 : ${moment(selectedDate).format('ll')}(${moment(selectedDate).format('dddd')})` : ''} />
                <LADataTable columns={columns} rows={listItems} />
            </LACard>
            <ClassmateModal
                classroomSeq={selectedClassroomSeq}
                show={classmateModal.state}
                handleClose={classmateModal.handleClose}
                onClickFeedback={onClickFeedback}
            />
            <ClassmateAttendanceModal
                classroomSeq={selectedClassroomSeq}
                timetableSeq={selectedTimetableSeq.timetable_seq}
                className={selectedTimetableSeq.class_name}
                show={attendanceModal.state}
                handleClose={attendanceModal.handleClose}
                onClickFeedback={onClickFeedback}
                selectedDate={selectedDate}
            />
            <FeedbackContentsModal
                classmateSeq={selectedClassmateSeq}
                classroomSeq={selectedClassroomSeq}
                show={feedbackContentModal.state}
                handleClose={feedbackContentModal.handleClose}
                handleSelect={feedbackContentModal}
            />
        </>
    );
}

export default ClassCalendar;
