import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/reviews';

const ReviewApi = (requestFunc) => {
  return {
    Review: {
      getReviewList: {
        key: 'getReviewList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },      
      getReviewDetail: {
        key: 'getReviewDetail',
        queryFn: (reviewSeq) => requestFunc(METHOD.GET, `${baseURL}/${reviewSeq}`),
      },      
      changeExposure: {
        key: 'changeExposure',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.reviewSeq}/exposure`,params.data),
      },      
    },
  };
};

export default ReviewApi;
