import { METHOD } from 'common/hooks/useAxios';

const baseMainURL = '/admin/main/banners';
const baseURL = '/admin/banners';
const baseVideoURL = '/admin/bannersvideo';

const MainBannerApi = (requestFunc) => {
  return {
    MainBanner: {
      registBanner: {
        key: 'registBanner',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
      },
      updateBanner: {
        key: 'updateBanner',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.banner_seq}`, params.data),
      },
      getBanners: {
        key: 'getBanners',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      getBannerInfo: {
        key: 'getBannerInfo',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.banner_seq}`, params),
      },
      deleteBanner: {
        key: 'deleteBanner',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.banner_seq}`),
      },

      registVideoBanner: {
        key: 'registVideoBanner',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseVideoURL}`, params),
      },
      updateVideoBanner: {
        key: 'updateVideoBanner',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseVideoURL}/${params.banner_seq}`, params.data),
      },
      getVideoBanners: {
        key: 'getVideoBanners',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseVideoURL}`, params),
      },
      getVideoBannerInfo: {
        key: 'getVideoBannerInfo',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseVideoURL}/${params.banner_seq}`, params),
      },
      deleteVideoBanner: {
        key: 'deleteVideoBanner',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseVideoURL}/${params.banner_seq}`),
      },

      getMainVideo: {
        key: 'getMainVideo',
        queryFn: (params) => requestFunc(METHOD.GET, `/admin/main/video`, params),
      },

      // getMainBanners: {
      //   key: 'getMainBanners',
      //   queryFn: (params) => requestFunc(METHOD.GET, `${baseMainURL}`, params),
      // },

      modifyMainAllBanner: {
        key: 'modifyMainAllBanner',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseMainURL}/modify`, params),
      },

      registMainBanner: {
        key: 'registMainBanner',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseMainURL}`, params),
      },
      updateMainBanner: {
        key: 'updateMainBanner',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainURL}/${params.banner_seq}`, params.data),
      },
      deleteMainBanner: {
        key: 'deleteMainBanner',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseMainURL}/${params.banner_seq}`),
      },
      upMainBanner: {
        key: 'upMainBanner',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainURL}/${params.banner_seq}/up`),
      },
      downMainBanner: {
        key: 'downMainBanner',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainURL}/${params.banner_seq}/down`),
      },
    },
  };
};

export default MainBannerApi;
