import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LAGridItem from './LAGridItem';
import useCheckbox from './hooks/useCheckbox';

function LACheckbox({
  wPercent = 100,
  layouts = {},
  props = {},
  label = '',
  value,
  onClick,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      {label.length > 0 ? (
        <FormGroup>
          <FormControlLabel control={<Checkbox {...props} checked={value} />} label={label} onClick={onClick} />
        </FormGroup>
      ) : (
        <Checkbox {...props} checked={value} onClick={onClick} />
      )}
    </LAGridItem>
  );
}

export default React.memo(LACheckbox);
