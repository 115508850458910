import React, { useState, useCallback, useEffect, useMemo } from 'react';
// import LALoading from 'modules/Widgets/LALoading';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { useLoginContext } from 'common/context/MemberContext';
import { AUTH_TYPES } from 'common/constants';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
// import { getTodayDateText, dateTimeToString } from 'common/utils';
import { useNavigate, useParams } from 'react-router';
import { getAge } from 'common/utils';
import moment from 'moment';

import {
  getTextCell,
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LALabel,
  LAButton,
  LADataTable,
  LASimpleTable,
} from 'modules/Widgets';
import { ContentHook } from '@fullcalendar/react';
import { API_BASE_URL } from 'api/AppConfig';

const columns = [
  { Header: 'No', accessor: 'no', width: '10%' },
  { Header: '가입자 아이디', accessor: 'id', width: '10%' },
  { Header: '학습자명', accessor: 'name', width: '5%' },
  { Header: '성별', accessor: 'gender', width: '5%' },
  { Header: '나이', accessor: 'age', width: '5%' },

  { Header: '입장 시간', accessor: 'enter_time', width: '15%' },
  { Header: '퇴장 시간', accessor: 'leave_time', width: '15%' },
  { Header: '참여 시간', accessor: 'participation_time', width: '15%' },
  { Header: '연락처', accessor: 'phone', width: '5%' },
];

const dataToRow = (data, index) => {
  let ageString = '';
  if (data.birth) {
    ageString = ` ${getAge(data.birth)}세`;
  }
  return {
    no: getTextCell(`${index + 1}`),
    id: getTextCell(`${data.email ? data.email : ''}`),
    name: getTextCell(`${data.user_name}`),
    gender: getTextCell(`${data.gender_type_name}`),
    age: getTextCell(`${ageString}`),

    enter_time: getTextCell(
      `${
        data.entry_dt
          ? moment(data.entry_dt).format('YYYY-MM-DD (dd) HH:mm')
          : ''
      }`,
    ),
    leave_time: getTextCell(
      `${
        data.leave_dt
          ? moment(data.leave_dt).format('YYYY-MM-DD (dd) HH:mm')
          : ''
      }`,
    ),
    participation_time: getTextCell(
      `${data.study_time ? data.study_time : '0'}분`,
    ),

    phone: getTextCell(
      `${data.phone ? data.phone.replaceAll('+82 ', '0') : ''}`,
    ),
  };
};

const columns2 = [
  { Header: '수업 열기', accessor: 'study_gubun', width: '15%' },
  { Header: '수업 열기 시간', accessor: 'open_dt', width: '20%' },
  { Header: '수업 시작 시간', accessor: 'begin_dt', width: '20%' },
  { Header: '수업 종료 시간', accessor: 'done_dt', width: '20%' },
  { Header: '총 수업 시간', accessor: 'time_stamp', width: '10%' },
];

const dataToRow2 = (data, index) => {
  return {
    study_gubun: `${data.open_type == 'T' ? '교사' : '회원'}`,
    open_dt: getTextCell(
      `${
        data.open_dt ? moment(data.open_dt).format('YYYY-MM-DD (dd) HH:mm') : ''
      }`,
    ),
    begin_dt: getTextCell(
      `${
        data.begin_dt
          ? moment(data.begin_dt).format('YYYY-MM-DD (dd) HH:mm')
          : ''
      }`,
    ),
    done_dt: getTextCell(
      `${
        data.done_dt ? moment(data.done_dt).format('YYYY-MM-DD (dd) HH:mm') : ''
      }`,
    ),
    //time_stamp : `${data.time_stamp ? Math.round(data.time_stamp/1000/60) : '0'}분`,
    time_stamp: getTextCell(data.time_stamp + '분'),
  };
};

const columns3 = [
  { Header: '일시', accessor: 'study_time', width: '40%' },
  { Header: '수업 이력', accessor: 'study_history', width: '45%' },
];

const dataToRow3 = (data, index) => {
  return {
    study_time: getTextCell(
      `${
        data.study_time
          ? moment(data.study_time).format('YYYY-MM-DD (dd) HH:mm:ss')
          : ''
      }`,
    ),
    study_history: getTextCell(
      data.teacher_yn == 1
        ? data.user_name + ' 선생님 - ' + data.study_history
        : data.teacher_yn == 2
        ? data.study_history
        : data.user_name + ' - ' + data.study_history,
    ),
  };
};

function ClassmateAttendanceModal({
  classroomSeq,
  timetableSeq,
  className,
  show,
  handleClose,
  handleSelect,
  selectedDate,
}) {

  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();
  const { action } = useLoginContext();

  const [rows, setRows] = useState([]);

  const [rows2, setRows2] = useState([]);
  const [rows3, setRows3] = useState([]);

  const [fileRows, setFileRows] = useState([]);
  const [selectFile, setSelectFile] = useState({});

  const authType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const onFeedback = (id) => {
    // navigate(`/mall/teacher/list/${id}`);
    // navigate(`/user/teacher/detail/${id}`)
  };

  const { request: requestAttebdances } = useCommonQuery({
    query: queries.Timetable.attendances,
    params: timetableSeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setRows(
            data.result_data
              ? data.result_data.map((resultData, index) =>
                  dataToRow(resultData, index),
                )
              : [],
          );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '학습자 목록 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestRecords } = useCommonQuery({
    query: queries.Room.recordList,
    params: timetableSeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.Contents && data.Contents.length > 0) {
          setFileRows(data.Contents);

          // }else{
          //   data.Contents = []
          //   data.Contents.push({
          //     ETag: "969b1bafb03f7014f7b8690dc3a7f2e1"
          //     ,Key: "20220904/6f4c5cb38e2d47d18c38ec9db8f58ccf/235825.webm"
          //   })
        }
        // if (data.result_code === '0000') {
        setFileRows(data.Contents ? data.Contents : []);
        // } else {
        // showAlert('알림', data.result_message);
        // }
      },
      onError: (error) => {
        showAlert('알림', '수업 녹화 목록 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  // const { request: requestPlay } = useCommonQuery({
  //   query: queries.Room.playRecordFile,
  //   params: encodeURIComponent(selectFile.Key),
  //   callbacks: {
  //     onSuccess: (data) => {

  //     },
  //     onError: (error) => {
  //       showAlert('알림', '클래스 재생에 실패했습니다.');
  //     },
  //   },
  //   initEnabled: false,
  // },[selectFile]);

  // const { request: requestDownload } = useCommonQuery({
  //   query: queries.Room.downloadRecordFile,
  //   params: {fileName:encodeURIComponent(selectFile.Key),  downFileName:encodeURIComponent(`${moment().format('YYYYMMDDHHmmss')}`)},
  //   callbacks: {
  //     onSuccess: (data) => {
  //       console.log('requestDownload : ',data)
  //       const url = URL.createObjectURL(new Blob([data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       const fileName = `${className}-${moment().format('YYYYMMDD')}-${timetableSeq}.webm`;
  //       link.setAttribute('download', fileName);
  //       // document.body.appendChild(link);
  //       link.click();
  //       // document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     },
  //     onError: (error) => {
  //       showAlert('알림', '클래스 다운로드에 실패했습니다.');
  //     },
  //   },
  //   initEnabled: false,
  // },[selectFile]);

  const { request: requestStudyResult } = useCommonQuery({
    query: queries.Timetable.studyResult,
    params: { timetableSeq, classroomSeq },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log('수업 결과표 조회 성공 : ', data.result_data);

          setRows2(
            data.result_data
              ? data.result_data.map((resultData, index) =>
                  dataToRow2(resultData, index),
                )
              : [],
          );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '수업 결과표 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const selectDate = moment(selectedDate).format('YYYY-MM-DD');
  const { request: requestStudyHistory } = useCommonQuery({
    query: queries.Timetable.studyHistory,
    params: { timetableSeq, classroomSeq, selectDate },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log('수업 이력 조회 성공 : ', data.result_data);

          setRows3(
            data.result_data
              ? data.result_data.map((resultData, index) =>
                  dataToRow3(resultData, index),
                )
              : [],
          );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '수업 이력 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    if (timetableSeq > 0) {
      requestAttebdances();
      requestRecords();

      requestStudyResult();
      requestStudyHistory();
    }
  }, [timetableSeq]);

  const onPlay = useCallback((e) => {
    window.open(
      `${API_BASE_URL}/room/record/file/${encodeURIComponent(e.Key)}`,
      '_blank',
    );
  }, []);

  const onDownload = useCallback((e) => {
    window.open(
      `${API_BASE_URL}/room/record/fileDown/${encodeURIComponent(
        e.Key,
      )}/${encodeURIComponent(`${moment().format('YYYYMMDDHHmmss')}`)}`,
      '_blank',
    );
  }, []);

  const getFileDatas = useCallback(
    (x) => {
      let result = [];
      if (fileRows && fileRows.length > 0) {
        result = fileRows.map((content) => {

          let arrKey = content.Key.split('/');
          let fileName = arrKey[arrKey.length - 1];
          return [
            {
              text: fileName,
            },
            {
              text: '재생',
              type: 'button',
              callback: {
                onClick: () => onPlay(content),
              },
            },
            {
              text:
                authType == '1' && authMemberType == AUTH_TYPES.master
                  ? '다운로드'
                  : '',
              type:
                authType == '1' && authMemberType == AUTH_TYPES.master
                  ? 'button'
                  : 'text',
              callback: {
                onClick: () => {
                  if (authType == '1' && authMemberType == AUTH_TYPES.master)
                    onDownload(content);
                },
              },
            },
            {
              //text: content.Size ? ContentHook.Size : '',
              text:
                Math.round((content.Size / 1024 / 1024) * 100) / 100 + ' MB',
            },
          ];
        });
      }

      return result;
    },
    [fileRows],
  );

  const fileTableData = useMemo(() => {
    return {
      Headers: [
        {
          text: '파일명',
        },
        {
          text: '재생',
        },
        {
          text:
            authType == '1' && authMemberType == AUTH_TYPES.master
              ? '다운로드'
              : '',
        },
        {
          text: '파일 사이즈',
        },
      ],
      wPercentList: [40, 20, 20, 20],
      rows: getFileDatas(),
    };
  }, [fileRows]);

  return (
    <LAModal title="" width="70%" height="80%" show={show}>
      <LACard>
        <LACardTitle title="수업 결과표" />
        <LADataTable columns={columns} rows={rows} />
      </LACard>

      <LACard next>
        <LACardTitle title="수업 녹화 목록" />
        <LASimpleTable tableData={fileTableData} />
      </LACard>

      <LACard next>
        <LACardTitle title="수업 결과표" />
        <LADataTable columns={columns2} rows={rows2} />
      </LACard>

      <LACard next>
        <LACardTitle title="수업 이력" />
        <LADataTable columns={columns3} rows={rows3} />
      </LACard>

      <LAGridContainer pt={2}>
        <LALabel wPercent={80} />
        <LAButton
          wPercent={20}
          layouts={{ pr: { sm: 0.5 } }}
          onClick={handleClose}
        >
          닫기
        </LAButton>
      </LAGridContainer>
    </LAModal>
  );
}

export default React.memo(ClassmateAttendanceModal);
