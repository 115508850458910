import React, { useState, useCallback } from 'react';
import moment from 'moment';
import ScheduleList from './ScheduleList';
import TimeTableList from './TimeTableList';
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  useComboBox,
  useInput,
  useDatePicker,
} from 'modules/Widgets';

const hourItems = [
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '05', value: '05분' },
  { key: '10', value: '10분' },
  { key: '15', value: '15분' },
  { key: '20', value: '20분' },
  { key: '25', value: '25분' },
  { key: '30', value: '30분' },
  { key: '35', value: '35분' },
  { key: '40', value: '40분' },
  { key: '45', value: '45분' },
  { key: '50', value: '50분' },
  { key: '55', value: '55분' },
];

function ScheduleView({ schedules, curriculums, onChange }) {
  const tempScheduleDate = useDatePicker(
    moment().add(7, 'day').format('YYYY-MM-DD'),
  );
  const tempScheduleHour = useComboBox('09');
  const tempScheduleMin = useComboBox('00');

  const [tempTimeTables, setTempTimeTables] = useState([]);

  const onAddSchedule = useCallback(() => {
    if (!curriculums || curriculums.length == 0) {
      alert('커리큘럼을 먼저 추가해주십시요');
      return;
    }

    // // if(curriculums.length <= (schedules.length) ){
    // //   alert('지정된 회차 이상 추가할 수 없습니다.')
    // //   return
    // // }

    // const schedule_date = moment(tempScheduleDate.state).format('YYYYMMDD')
    // const schedule_time = `${tempScheduleHour.state}${tempScheduleMin.state}`

    // var list = []

    // // if(schedules.length > 0){

    // //   const overLapsDate = schedules.filter(s => s.schedule_date === schedule_date).some(s => {
    // //     let a = moment(s.schedule_time,'HHmm')
    // //     let b = moment(schedule_time,'HHmm')

    // //     let diffMinutes = b.diff(a,'minutes')
    // //     if(diffMinutes < s.duration_min){
    // //       return false
    // //     }
    // //     return true
    // //   })
    // //   console.log('overLapsDate : ',overLapsDate)
    // //   if(overLapsDate){
    // //     alert('중복된 날짜 및 시간입니다.')
    // //     return
    // //   }

    // //   const preDays = schedules.some(s => {
    // //     let a = moment(s.schedule_date,'YYYYMMDD')
    // //     let b = moment(schedule_date,'YYYYMMDD')
    // //     console.log(`a:${a} , b:${b}`)
    // //     if(b.diff(a,'minutes') > 0){
    // //       return false
    // //     }
    // //     return true
    // //   })
    // //   console.log('preDays : ',preDays)
    // //   if(preDays){
    // //     alert('기존 일정보다 전 날짜를 선택 할 수 없습니다.')
    // //     return
    // //   }

    // //   for(const s of schedules){
    // //     console.log('schedules =>',s)
    // //     list.push(Object.assign({}, s))
    // //   }
    // // }

    // if(tempTimeTables.length > 0){

    //   const overLapsDate = tempTimeTables.filter(s => s.schedule_date === schedule_date).some(s => {
    //     let a = moment(s.schedule_time,'HHmm')
    //     let b = moment(schedule_time,'HHmm')

    //     let diffMinutes = b.diff(a,'minutes')
    //     if(diffMinutes < s.duration_min){
    //       return false
    //     }
    //     return true
    //   })
    //   console.log('overLapsDate : ',overLapsDate)
    //   if(overLapsDate){
    //     alert('중복된 날짜 및 시간입니다.')
    //     return
    //   }

    //   const preDays = tempTimeTables.some(s => {
    //     let a = moment(s.schedule_date,'YYYYMMDD')
    //     let b = moment(schedule_date,'YYYYMMDD')
    //     console.log(`a:${a} , b:${b}`)
    //     if(b.diff(a,'minutes') > 0){
    //       return false
    //     }
    //     return true
    //   })
    //   console.log('preDays : ',preDays)
    //   if(preDays){
    //     alert('기존 일정보다 전 날짜를 선택 할 수 없습니다.')
    //     return
    //   }

    // //   for(const s of tempTimeTables){
    // //     console.log('schedules =>',s)
    // //     list.push(Object.assign({}, s))
    // //   }
    // // }else{

    // }
    // // tempTimeTables.push({
    // //   schedule_date:schedule_date
    // //   ,schedule_time:schedule_time
    // // })

    // // list.push(schedule)
    // // tempTimeTables = [...tempTimeTables,]
    // onChange(list)
  }, [schedules, tempScheduleDate, tempScheduleHour, tempScheduleMin]);

  return (
    <>
      <LACard next>
        <LACardTitle title="클래스 일정 정보" />

        <LAProperty name="수업 일정(날짜)">
          <LAGridContainer>
            <LADatePicker
              wPercent={30}
              value={tempScheduleDate.state}
              onChange={tempScheduleDate.handleChange}
            />
            <LAWarningLabel wPercent={30}>
              최대 3개월까지 선택 가능합니다.
            </LAWarningLabel>
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="수업 일정(시간)">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              layouts={{ pr: { sm: 0.5 } }}
              items={hourItems}
              value={tempScheduleHour.state}
              onChange={tempScheduleHour.handleChange}
            />
            <LAComboBox
              wPercent={15}
              layouts={{ pr: { sm: 0.5 } }}
              items={minItems}
              value={tempScheduleMin.state}
              onChange={tempScheduleMin.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="일정">
          <LAGridContainer>
            <LAButton wPercent={15} onClick={onAddSchedule}>
              일정 추가
            </LAButton>
          </LAGridContainer>
          <LAGridContainer pt={1}>
            <TimeTableList schedules={schedules} timetables={tempTimeTables} />
          </LAGridContainer>
          <LAGridContainer pt={1}>
            <ScheduleList schedules={schedules} curriculums={curriculums} />
          </LAGridContainer>
          <LAWarningLabel>
            패키지(표준순환, 개별 맞춤) : 클래스 종료일은 클래스 기본 정보에서
            선택한 클래스 회차에 따라 자동 계산되어 표시됩니다.
          </LAWarningLabel>
        </LAProperty>
      </LACard>
    </>
  );
}
export default React.memo(ScheduleView);
