import React, { useState, useCallback, useMemo, useEffect } from "react";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";

import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import CommissionDetailModal from "./CommissionDetailModal";

import { API_BASE_URL } from "api/AppConfig";
import { useLoginContext } from "common/context/MemberContext";

import moment from "moment";

import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  getTextCell,
  getNormalButton,
} from "modules/Widgets";
import DownloadReasonModal from "pages/Order/components/DownloadReasonModal";
import DownloadListModal from "pages/Order/components/DownloadListModal";

function addComma(input) {
  if (input) {
    // return input.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    const parts = input.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else return 0;
}

function CalculateCommissionManagement() {
  const { loginState } = useLoginContext();

  const navigate = useNavigate();
  const commissionType = useRadioGroup(0);

  // const queryDate = useMemo(() => moment().add(-1, 'M'));
  const queryDate = useMemo(() => moment());

  const thisYear = useMemo(() => queryDate.format("YYYY"), []);
  const thisMonth = useMemo(() => queryDate.format("MM"), []);

  const yearType = useComboBox(thisYear);
  const monthType = useComboBox(thisMonth);

  const yearTypeDwn = useComboBox(thisYear);
  const monthTypeDwn = useComboBox(thisMonth);

  const searchTargetType = useComboBox(0);
  const searchText = useInput("");

  const commissionDetailModal = useModal(false);
  const downloadReasonModal = useModal(false);
  const downloadListModal = useModal(false);

  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const [data, setData] = useState([]);
  const [selectedCommSeq, setSelectedCommSeq] = useState(0);
  const [selectedMemberSeq, setSelectedMemberSeq] = useState(0);
  const [selectedYyyymm, setSelectedYyyymm] = useState(0);

  const onSearchClick = useCallback(() => {
    requestCommission();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  const onClickCommission = useCallback((row) => {
    setSelectedCommSeq(row.commSeq);
    setSelectedMemberSeq(row.memberSeq);
    setSelectedYyyymm(row.yyyymm);
    commissionDetailModal.visible(true);
  });

  const onDetailClosed = useCallback(() => {
    commissionDetailModal.handleClose();
    requestCommission();
  });

  const onClicDescription = useCallback((query) => {
    window.open(
      `${API_BASE_URL}/admin/settlement/statement/receipt/frame/${query.yyyymm}?memberSeq=${query.memberSeq}`,
      "_blank"
    );
  });

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "4%" },
      { Header: "정산년월", accessor: "year_month", width: "8%" },
      { Header: "소속", accessor: "alliance", width: "8%" },
      { Header: "선생님", accessor: "teacher", width: "8%" },
      { Header: "선생님 Email", accessor: "email", width: "8%" },
      { Header: "총 매출액", accessor: "sales", width: "8%" },
      { Header: "총 정산 대상 금액", accessor: "sattlement", width: "8%" },
      { Header: "총 공제 금액", accessor: "deduction", width: "8%" },
      { Header: "총 보전 금액", accessor: "conservation", width: "8%" },
      { Header: "총 패널티 금액", accessor: "penalty", width: "8%" }, // 2023-02-27 총 패널티 금액 추가
      { Header: "총 정산 금액", accessor: "sattlement_last", width: "8%" },
      { Header: "수수료 상세", accessor: "commission", width: "8%" },
      { Header: "거래명세서", accessor: "description", width: "8%" },
    ];
  }, []);

  const rows = useMemo(() => {
    return data.map((row) => {
      return {
        no: getTextCell(row.com_seq),
        year_month: getTextCell(moment(row.yyyymm).format("YYYY-MM")),
        alliance: getTextCell(row.alliance_name),
        teacher: getTextCell(row.member_name),
        email: getTextCell(row.email),
        sales: getTextCell(addComma(row.total_amount)),
        sattlement: getTextCell(addComma(row.total_target_amount)),
        deduction: getTextCell(addComma(row.total_deamount)),
        conservation: getTextCell(addComma(row.total_conservation_amount)),
        penalty: getTextCell(addComma(row.total_penalty_amount)), // 2023-02-27 총 패널티 금액 추가
        sattlement_last: getTextCell(addComma(row.total_settlement_amount)),
        commission: getNormalButton(
          "상세",
          {
            commSeq: row.com_seq,
            memberSeq: row.member_seq,
            yyyymm: row.yyyymm,
          },
          onClickCommission
        ),
        description:
          row.isApproved === "Y"
            ? getNormalButton(
                "조회",
                { yyyymm: row.yyyymm, memberSeq: row.member_seq },
                onClicDescription
              )
            : getTextCell("미승인"),
      };
    });
  }, [data]);

  useEffect(() => {
    requestCommission();
  }, []);

  const { result: resultData, request: requestCommission } = useCommonQuery({
    query: queries.Calculate.getCommissionList,
    params: {
      yyyymm: yearType.state + monthType.state, // 조회기간
      approve_type: commissionType.state, // 거래명세서 승인 여부 (전체, 승인, 미승인)
      search_type: searchTargetType.state, // 직접 입력부분 (통합검색, 선생님, 소속)
      ...(searchText.state && { search_value: searchText.state }), // 직접 입력부분 (검색어)
    },
    callbacks: {
      onSuccess: (data) => {
        console.log(data);
        if (data.result_code == "0000") {
          console.log(data.result_data);
          setData(data.result_data);
        } else {
          if (data.result_code.result_message)
            showAlert("알림", data.result_code.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "목록 조회에 실패하였습니다.");
      },
    },
    initEnabled: false,
  });

  const [downKey, setDownKey] = useState("");
  const clickDownload = (order) => {
    console.log(order);

    if (order === "클래스별 수수료 내역") setDownKey("classDetailFee");
    else if (order === "선생님별 수수료 내역") setDownKey("teacherDetailFee");

    downloadReasonModal.visible(true);
  };

  const clickDownloadList = () => {
    downloadListModal.visible(true);
  };

  return (
    <>
      <DashboardNavbar title="수수료 관리" />

      {/* Start Search Area */}
      <LACard>
        {/* <LAProperty name="거래명세서">
          <LAGridContainer vAlign="center">
            <LARadioGroup
              props={[
                { text: "전체", value: 0 },
                { text: "승인", value: 1 },
                { text: "미승인", value: 2 },
              ]}
              value={commissionType.state}
              onChange={commissionType.handleChange}
            />
          </LAGridContainer>
        </LAProperty> */}

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: "2022", value: "2022년" },
                { key: "2023", value: "2023년" },
                { key: "2024", value: "2024년" },
                { key: "2025", value: "2025년" },
              ]}
              value={yearType.state}
              onChange={yearType.handleChange}
            />
            <LAComboBox
              layouts={{ pl: { sm: 1 } }}
              wPercent={15}
              items={[
                { key: "01", value: "1월" },
                { key: "02", value: "2월" },
                { key: "03", value: "3월" },
                { key: "04", value: "4월" },
                { key: "05", value: "5월" },
                { key: "06", value: "6월" },
                { key: "07", value: "7월" },
                { key: "08", value: "8월" },
                { key: "09", value: "9월" },
                { key: "10", value: "10월" },
                { key: "11", value: "11월" },
                { key: "12", value: "12월" },
              ]}
              value={monthType.state}
              onChange={monthType.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: "통합검색" },
                { key: 1, value: "선생님" },
                { key: 2, value: "소속" },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={"outlined"}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "right" }}
        >
          <LALabel wPercent={70}></LALabel>
          <LAComboBox
            wPercent={10}
            items={[
              { key: "2022", value: "2022년" },
              { key: "2023", value: "2023년" },
              { key: "2024", value: "2024년" },
              { key: "2025", value: "2025년" },
            ]}
            value={yearTypeDwn.state}
            onChange={yearTypeDwn.handleChange}
          />
          <LAComboBox
            layouts={{ pl: { sm: 1 } }}
            wPercent={10}
            items={[
              { key: "01", value: "1월" },
              { key: "02", value: "2월" },
              { key: "03", value: "3월" },
              { key: "04", value: "4월" },
              { key: "05", value: "5월" },
              { key: "06", value: "6월" },
              { key: "07", value: "7월" },
              { key: "08", value: "8월" },
              { key: "09", value: "9월" },
              { key: "10", value: "10월" },
              { key: "11", value: "11월" },
              { key: "12", value: "12월" },
            ]}
            value={monthTypeDwn.state}
            onChange={monthTypeDwn.handleChange}
          />
          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={() => clickDownload("선생님별 수수료 내역")}
          >
            선생님/업체별
            <br />
            수수료 내역 다운로드
          </LAButton>
          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={() => clickDownload("클래스별 수수료 내역")}
          >
            클래스별
            <br />
            수수료 내역 다운로드
          </LAButton>

          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={clickDownloadList}
          >
            다운로드 이력
          </LAButton>
        </div>

        <LAGridContainer>
          <LADataTable columns={columns} rows={rows} />
        </LAGridContainer>
      </LACard>

      <CommissionDetailModal
        commSeq={selectedCommSeq}
        memberSeq={selectedMemberSeq}
        yyyymm={selectedYyyymm}
        show={commissionDetailModal.state}
        handleClose={onDetailClosed}
        handleSelect={commissionDetailModal}
      />
      <DownloadReasonModal
        show={downloadReasonModal.state}
        handleClose={downloadReasonModal.handleClose}
        downKey={downKey}
        // urlQueries={"?tp=comm"}
        yyyymm={`${yearTypeDwn.state}${monthTypeDwn.state}`}
      />
      <DownloadListModal
        show={downloadListModal.state}
        handleClose={downloadListModal.handleClose}
        type={"SETTLEMENT"}
      />
    </>
  );
}

export default CalculateCommissionManagement;
