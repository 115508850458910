import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/points';

const PointApi = (requestFunc) => {
  return {
    Point: {
      history: {
        key: 'history',
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/history`, params),
      },
      earnedHistory: {
        key: 'earnedHistory',
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/earned-history`, params),
      },
      historyInfo: {
        key: 'historyInfo',
        queryFn: (params) =>
          requestFunc(METHOD.GET, `${baseURL}/history/${params.id}`),
      },
      sendPoint: {
        key: 'sendPoint',
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/accumulate`, params),
      },
      deductPoint: {
        key: 'deductPoint',
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/deduct`, params),
      },
      uploadExcel: {
        key: 'uploadExcel',
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/upload-excel`, params),
      },
    },
  };
};

export default PointApi;
