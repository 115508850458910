import React, { useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import 'moment/locale/ko';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LASimpleTable,
} from 'modules/Widgets';

function OneDayScheduleList({ schedules, onChange, onDetail ,onDelete}) {
  // const getButton = useCallback((schedule) => {
  //   if (
  //     schedule.confirmation_yn == 0 ||
  //     schedule.close_yn == 0 ||
  //     schedule.finish_yn == 0
  //   ) {
  //     return {
  //       text: '삭제',
  //       type: 'button',
  //       callback: {
  //         onClick: () => onChange(schedule),
  //       },
  //     };
  //   } else {
  //     return {
  //       text: '삭제',
  //       type: 'button',
  //       callback: {
  //         onClick: () => onChange(schedule),
  //       },
  //     };
  //   }
  // });

  const getTime = useCallback((timetables) => {
    if (timetables && timetables.length > 0) return timetables[0].schedule_time;
    else return '0000';
  });

  const sortedSchedules = useMemo(() => {
    if (!schedules) {
      return [];
    }

    return schedules.sort((a, b) => {
      if (!a.timetables || a.timetables.length === 0) {
        return 1;
      } else if (!b.timetables || b.timetables.length === 0) {
        return -1;
      }

      const t1 = moment(
        a.timetables[0].schedule_date + ' ' + a.timetables[0].schedule_time,
      );
      const t2 = moment(
        b.timetables[0].schedule_date + ' ' + b.timetables[0].schedule_time,
      );
      if (t1.isSame(t2)) return 0;
      if (t2.isAfter(t1)) return 1;
      return -1;
    });
  }, [schedules]);

  const getScheduleRows = useCallback(() => {
    const result = [];
    if (sortedSchedules && sortedSchedules.length > 0) {
      // for (let i = 0; i < schedules.length; i++) {
      for (const [index, schedule] of Object.entries(sortedSchedules)) {

        // for (const timetable of schedule.timetables) {
          result.push([
            {
              text:
                schedule.schedule_seq != 0 ? `${parseInt(index) + 1}` : '신규',
            },
            {
              text: schedule.schedule_seq || '',
            },
            {
              text: moment(schedule.start_dt).format('YYYY-MM-DD (dd)'),
            },
            {
              // text: `${schedules[i].startTime} ~ ${schedules[i].endTime}`,
              text: moment(
                schedule.start_dt + ' ' + getTime(schedule.timetables),
              ).format('A hh:mm'),
            },
            {
              text: moment(
                schedule.start_dt + ' ' + schedule.timetables[0].schedule_time,
              )
                .add(schedule.class_time, 'minutes')
                .format('A hh:mm'),
            },
            {
              text:
                schedule.close_yn == 1
                  ? '폐강'
                  : schedule.confirmation_yn == 1
                  ? '확정'
                  : '미확정',
            },
            {
              text:
                schedule.reception_status == 2
                  ? '접수마감'
                  : schedule.reception_status == 1
                  ? '접수중'
                  : '대기',
            },
            {
              text: schedule.applicant_count || '0',
            },
            // {
            //   text: schedule.delete_yn == 1 ? 'Y' : 'N',
            // },
            {
              text: '상세',
              type: 'button',
              callback: {
                onClick: () => onDetail(schedule),
              },
            },
            {
              text: '삭제',
              type: 'button',
              callback: {
                onClick: () => onDelete(schedule),
              },
            },
          ]);
        // }
      }
    }

    return result;
  }, [sortedSchedules]);

  const scheduleTableData = {
    headers: [
      {
        text: 'No',
      },
      {
        text: '스케줄 번호',
      },
      {
        text: '클래스 일정',
      },
      {
        text: '시작 시간',
      },
      {
        text: '종료 시간',
      },
      {
        text: '확정 여부',
      },
      {
        text: '접수 상태',
      },
      {
        text: '신청자수',
      },
      // {
      //   text: '삭제 여부',
      // },
      {
        text: '상세',
      },
      {
        text: '삭제',
      },
    ],
    wPercentList: [5, 11, 20, 12, 12, 10, 7, 7, 8 , 8],
    rows: getScheduleRows(),
  };

  return (
    <>
      <LAGridContainer pt={2}>
        <LASimpleTable isPagination tableData={scheduleTableData} />
      </LAGridContainer>
    </>
  );
}
export default React.memo(OneDayScheduleList);
