import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'VISIBLE':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useModal(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const visible = useCallback(
    (value) => {
      if (state === value) {
        return;
      }

      dispatch({
        type: 'VISIBLE',
        value,
      });
    },
    [state],
  );

  const handleClose = useCallback(
    (e) => {
      visible(false);
    },
    [visible],
  );

  // return {
  //   [`state${name}`]: state,
  //   [`handle${name}`]: handle,
  //   [`select${name}`]: select,
  //   [`reset${name}`]: reset,
  // };

  return {
    state,
    handleClose,
    visible,
  };
}

export default useModal;
