import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'TOGGLE':
        const value = parseInt(action.value);
        const index = state.findIndex((key) => key === value);
        index >= 0
          ? !action.force && draft.splice(index, 1)
          : draft.push(value);
        break;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useDayOfTheWeek(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const sortedState = useMemo(
    () =>
      state.slice().sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      }),
    [state],
  );

  const toggle = useCallback((value, force = false) => {
    dispatch({
      type: 'TOGGLE',
      value,
      force,
    });
  }, []);

  const handleClick = useCallback(
    (e) => {
      toggle(e.target.value);
    },
    [toggle],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  const isSelected = useCallback(
    (value) => {
      return state.findIndex((key) => key === value) >= 0;
    },
    [state],
  );

  return {
    state: sortedState,
    handleClick,
    toggle,
    reset,
    isSelected,
  };
}

export default useDayOfTheWeek;
