import React, { useState, useCallback, useEffect, useContext } from "react";

import { UNSAFE_NavigationContext } from "react-router-dom";


import { LAModal, LACard, LACardTitle } from "modules/Widgets";
import TogetherOrderDetailContents from "./TogetherOrderDetailContents";

function TogetherOrderDetailModal({ orderSeq, show, handleClose, orderDetailContentsShow }) {
    const navigation = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        if (navigation) {
            navigation.listen((locationListener) => {
                if (locationListener.action == "POP") {
                    handleClose();
                }
            });
        }
    }, []);

    return (
        <LAModal
            title="결제 상세"
            width="90%"
            height="95%"
            show={show}
            onClose={handleClose}
        >
            <TogetherOrderDetailContents 
                orderSeq={orderSeq} 
                onClickList={handleClose}
                orderDetailContentsShow={orderDetailContentsShow} 
            />
        </LAModal>
    );
}

export default React.memo(TogetherOrderDetailModal);
