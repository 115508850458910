import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import moment from 'moment';
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LASimpleTable,  
} from 'modules/Widgets';

function MainContentBannerTable({ items, moveUpCallback,moveDownCallback, deleteItemCallback }) {
  
  const getReservationText = useCallback((item) =>{
    if(item.reservation_yn === 1){
      let startDate = moment(item.reservation_start_dt).format('YYYY.MM.DD HH:mm')
      let endDate = moment(item.reservation_end_dt).format('YYYY.MM.DD HH:mm')
      return `${startDate}\n ~ ${endDate}`
    }else{
      return '즉시'
    }
  })

  const getTableRows = useCallback(() => {
    let rows = [];
    

    if(items && items.length > 0) {
      items.forEach((item, index) => {
        item.exposure_no = index + 1;
        rows.push([
          {
            text: item.banner_seq,
          },
          {
            text: item.banner_title,
            align: 'left',
          },
          {
            text: item.banner_link_url,
            align: 'left',
          },
          {
            text: getReservationText(item),            
          },
          {
            text: item.exposure_no,
          },
          {
            text: '▲',
            type: 'button',
            variant: 'outlined',
            callback: {
              onClick: ()=> moveUpCallback(item.exposure_no)
            },
          },
          {
            text: '▼',
            type: 'button',
            variant: 'outlined',
            callback: {
              onClick: ()=> moveDownCallback(item.exposure_no)
            },
          },
          {
            text: '삭제',
            type: 'button',
            callback: {
              onClick: ()=> deleteItemCallback(item.exposure_no, item.banner_seq)
            },
          }
        ]);
      });
    }
    return rows;
  }, [items]);

  const getTableData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '링크',
        },
        {
          text: '예약 노출',
        },
        {
          text: '순서',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '삭제',
        },
      ],
      wPercentList: [10, 25, 10, 15, 10, 10, 10, 10],
      rows: getTableRows(),
    };
  }, [items]);


  return (
    <>
        <LACardTitle title="현재 노출 목록" />
        <LAGridContainer layouts={{ pt: 1 }}>
          <LASimpleTable tableData={getTableData} />
        </LAGridContainer>
      
    </>
  );  
}

export default React.memo(MainContentBannerTable);
