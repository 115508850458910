import React from 'react';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';

import LADivider from './LADivider';
import LAWarningLabel from './LAWarningLabel';

function LACardTitle({ title, content, warning, customUi=undefined, showDivider=true, required = false, fontSize }) {
  
    return (
        <>
            <Grid container>
                <Grid item sm={5}>
                    <MDTypography variant="h5" pt={1} fontSize={fontSize || "inherit"}>
                        {title}{required === true && (<span style={{color: "#ff0000", margin: 1}}>*</span>)}
                    </MDTypography>
                </Grid>
                <Grid item sm={7}>
                    {content && content.length > 0 && (
                        <MDTypography textAlign="right" variant="h6" pt={1} pr={2}>{content ? content : ''}</MDTypography>
                    )}
                    {warning && warning.length > 0 && (
                        <LAWarningLabel props={{ textAlign: 'right' }}>{warning ? warning : ''}</LAWarningLabel>
                    )}
                    {customUi && customUi}
                </Grid>
            </Grid>
            {showDivider? (<LADivider pt={1} />) : (<></>)}
        </>
    );
}

export default React.memo(LACardTitle);
