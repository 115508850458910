import React, { useState, useCallback, useEffect, useMemo } from 'react';
import useFindAddress from 'common/hooks/useFindAddress';
import useCommonMutation from "common/hooks/useCommonMutation";
import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
    LACard,
    LACardTitle,
    LAGridContainer,
    // LAGridItem,
    LAProperty,
    LALabel,
    LAButton,
    LAInput,
    LAModal,
    LASimpleTable,
    useInput,
    useModal,
} from 'modules/Widgets';


function ChangeDeliveryAddressModal({deliveryInfo,show, handleClose}){
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();

    const receiverName = useInput('');
    const receiverMobile = useInput('');
    const receiverPhone = useInput('');
    const receiverZipCode = useInput('');
    const receiverAddress1 = useInput('');
    const receiverAddress2 = useInput('',50);
    const receiverMessage = useInput('',50);

    const handleAddressFound = useCallback(
        (result) => {
            receiverZipCode.update(result.zipCode);
            receiverAddress1.update(result.address);
        },
        [receiverZipCode, receiverAddress1],
    );
    const { handleAddressPopup } = useFindAddress(handleAddressFound);


    useEffect(() => {
        if(deliveryInfo) {
            receiverName.update(deliveryInfo.receiver_name);
            receiverMobile.update(deliveryInfo.receiver_phone_no);
            receiverPhone.update(deliveryInfo.receiver_landline_no);
            receiverZipCode.update(deliveryInfo.address_zip);
            receiverAddress1.update(deliveryInfo.address_road);
            receiverAddress2.update(deliveryInfo.address_detail);
            receiverMessage.update(deliveryInfo.remarks);
        }
        
    }, [deliveryInfo]);

    const { request : requestChangeAddress} = useCommonMutation({ 
        query: queries.Delivery.changeAddress,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === "0000") {
                    showAlert('알림', '배송지 주소가 변경되었습니다.');    
                    handleClose()
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '배송지 주소 변경에 실패하였습니다.');
            },
        },initEnabled:false
    });  

    const onChangeAddressClick = useCallback(() => {
        // 배송 준비중인 경우에만 변경 가능
        const params = {
            order_seq:deliveryInfo.order_seq,
            data: {
                    receiver_name : receiverName.state,
                    receiver_phone_no : receiverMobile.state,
                    receiver_landline_no : receiverPhone.state,
                    address_zip : receiverZipCode.state,
                    address_jibun :receiverAddress1.state,
                    address_road : receiverAddress1.state,
                    address_detail : receiverAddress2.state,
                    remarks : receiverMessage.state
                }
            }
        showConfirm('확인', `배송지 정보를 변경 하시겠습니까?`, {
            confirmHandler: () => {
                requestChangeAddress(params)
            },
        });
    },[
        deliveryInfo,
        receiverName.state,
        receiverMobile.state,
        receiverPhone.state,
        receiverZipCode.state,
        receiverAddress1.state,
        receiverAddress2.state,
        receiverMessage.state
    ])

    return (
        <LAModal title="배송지 주소 변경" width="60%" height="80%" show={show} onClose={handleClose}>
            <LACard next>
                <LACardTitle title="기존 배송지 정보" />
                <LALabel wPercent={100}>{`받는분 : ${!!deliveryInfo && deliveryInfo.receiver_name}`}</LALabel>
                <LAGridContainer>
                    <LALabel wPercent={50}>{`휴대폰 번호 : ${!!deliveryInfo && deliveryInfo.receiver_phone_no}`}</LALabel>
                    <LALabel wPercent={50}>
                        {`전화번호 : ${!!deliveryInfo && deliveryInfo.receiver_landline_no !== '' ? deliveryInfo.receiver_landline_no : ''}`}
                    </LALabel>
                </LAGridContainer>
                <LALabel wPercent={100}>{`주소 : ${!!deliveryInfo && deliveryInfo.address_zip} ${!!deliveryInfo && deliveryInfo.address_road} ${!!deliveryInfo && deliveryInfo.address_detail}`}</LALabel>                
                <LALabel wPercent={100}>{`배송 요청사항 : ${!!deliveryInfo && deliveryInfo.remarks}`}</LALabel>
            </LACard>
            <LACard next>
                <LACardTitle title="신규 배송지 정보" />

                <LAProperty name="받는 분">
                    <LAInput wPercent={20} value={receiverName.state} onChange={receiverName.handleChange} />
                </LAProperty>

                <LAProperty name="휴대폰 번호">
                    <LAInput wPercent={20} value={receiverMobile.state} onChange={receiverMobile.handleChange} />
                </LAProperty>

                <LAProperty name="전화번호">
                    <LAInput wPercent={20} value={receiverPhone.state} onChange={receiverPhone.handleChange} />
                </LAProperty>

                <LAProperty name="받는 주소">
                    <LAGridContainer>
                        <LAInput wPercent={20} layouts={{ pr: { sm: 0.5 } }} value={receiverZipCode.state} onChange={receiverZipCode.handleChange} />
                        <LAButton wPercent={15} layouts={{ pl: { sm: 0.5 } }} onClick={handleAddressPopup}>주소 찾기</LAButton>
                    </LAGridContainer>
                    <LAGridContainer layouts={{ pt: 1 }}>
                        <LAInput wPercent={50} value={receiverAddress1.state} onChange={receiverAddress1.handleChange} />
                    </LAGridContainer>
                    <LAGridContainer layouts={{ pt: 1 }}>
                        <LAInput wPercent={50} value={receiverAddress2.state} onChange={receiverAddress2.handleChange} />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="배송 요청사항">
                    <LAInput value={receiverMessage.state} onChange={receiverMessage.handleChange} placeholder={"50자 내로 입력해주세요."} />
                </LAProperty>

                <LAGridContainer layouts={{mt:{sm:1}}}>
                    <LALabel wPercent={40}></LALabel>
                    <LAButton wPercent={20} layouts={{ pl: { sm: 0.5 } }} onClick={onChangeAddressClick}>변경</LAButton>
                    <LALabel wPercent={40}></LALabel>
                </LAGridContainer>
            </LACard>
        </LAModal>
    )
}
export default React.memo(ChangeDeliveryAddressModal);