import React, { useState, useCallback, useEffect } from 'react';

import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LASimpleTable,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  useInput,
} from 'modules/Widgets';
import moment from 'moment';
import KeywordRegistModal from './components/KeywordRegistModal';
import { useModal } from 'modules/Widgets';
import {sendCacheRefresh} from "../../../common/utils";

function KeywordManagement() {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const frontTitle = useInput('');
  const [keywords, setKeywords] = useState([]);

  const keywordRegistModal = useModal(false);

  const { result: resultData, request: requestKeywords } = useCommonQuery({
    query: queries.Keyword.getAll,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          if (data.result_data.main && data.result_data.main.length > 0)
            frontTitle.update(data.result_data.main[0].main_title);
          setKeywords(data.result_data.keywords);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '목록 조회에 실패하였습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestDelete } = useCommonMutation({
    query: queries.Keyword.deleteKeyword,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '키워드가 삭제되었습니다.', {
            confirmHandler: () => {
              requestKeywords();
            },
          });
          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '키워드 삭제에 실패했습니다.');
      },
    },
  });

  const { request: requestModify } = useCommonMutation({
    query: queries.Keyword.modifyKeyword,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '키워드가 수정되었습니다.', {
            confirmHandler: () => {
              requestKeywords();
            },
          });
          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '키워드 수정에 실패했습니다.');
      },
    },
  });

  const { request: requestMainModify } = useCommonMutation({
    query: queries.Keyword.modifyMainInfo,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '제목이 수정되었습니다.', {
            confirmHandler: () => {
              requestKeywords();
            },
          });
          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '제목 수정에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    requestKeywords();
  }, []);

  const disposureKeyword = useCallback((displayYn, keywordSeq) => {
    let display = displayYn ? '"미노출"' : '"노출"';
    showConfirm(
      '확인',
      `해당 키워드를 ${display} 하시겠습니까?\n프론트에 즉시 반영됩니다.`,
      {
        confirmHandler: () => {
          requestModify({
            display_yn: displayYn ? '0' : '1',
            keyword_seq: keywordSeq,
          });
        },
      },
    );
  }, []);

  const deleteKeyword = useCallback((keywordSeq) => {
    showConfirm(
      '확인',
      `해당 키워드를 삭제하시겠습니까?\n프론트에 즉시 반영됩니다.`,
      {
        confirmHandler: () => {
          requestDelete({ keyword_seq: keywordSeq });
        },
      },
    );
  }, []);

  const getKeywordRows = useCallback(() => {
    let rows = [];
    if (keywords && keywords.length > 0) {
      keywords.forEach((keyword) => {
        rows.push([
          {
            text: keyword.keyword_seq,
          },
          {
            text: keyword.keyword,
            align: 'left',
          },
          {
            text: keyword.display_yn ? 'Y' : 'N',
          },
          {
            text: keyword.display_yn ? '미노출' : '노출',
            type: 'button',
            variant: keyword.display_yn ? 'outlined' : 'contained',
            callback: {
              onClick: () =>
                disposureKeyword(keyword.display_yn, keyword.keyword_seq),
            },
          },
          // {
          //   text: '',
          // },
          {
            text: moment(keyword.modified_dt).format('YYYY-MM-DD (dd) A hh:mm'),
            align: 'left',
          },
          {
            text: '삭제',
            type: 'button',
            callback: {
              onClick: () => deleteKeyword(keyword.keyword_seq),
            },
          },
        ]);
      });
    }
    return rows;
  }, [keywords]);

  const managementTableData = {
    headers: [
      {
        text: 'No',
      },
      {
        text: '키워드',
      },
      {
        text: '노출',
      },
      {
        text: '노출 버튼',
      },
      // {
      //   text: '등록자',
      // },
      {
        text: '등록일(수정일)',
      },
      {
        text: '삭제',
      },
    ],
    wPercentList: [10, 30, 15, 15, 20, 10],
    rows: getKeywordRows(),
  };

  const onAddClick = useCallback(() => {
    keywordRegistModal.visible(true);
  }, []);

  const onRegistClick = useCallback(() => {
    if (!frontTitle?.state || frontTitle.state.length === 0) {
      showAlert('알림', '프론트 제목을 입력해주세요.');
      return;
    }
    requestMainModify({ main_title: frontTitle.state });
  }, [frontTitle.state]);

  const registClosed = useCallback(() => {
    keywordRegistModal.handleClose();
    requestKeywords();
  });

  return (
    <>
      <DashboardNavbar title="키워드 관리" />

      {/* Start Search Area */}
      <LACard>
        <LACardTitle title="키워드 정보" />

        <LAProperty name="프론트 제목" required>
          <LAInput
            placeholder="프론트 제목을 입력해 주세요."
            value={frontTitle.state}
            onChange={frontTitle.handleChange}
          />
        </LAProperty>

        <LAGridContainer layouts={{ pt: 3 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ mr: 1 }}
            variant={'outlined'}
            btnPaddingY={12}
            onClick={onAddClick}
          >
            키워드 추가
          </LAButton>
          <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onRegistClick}>
            저장
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>

      <LACard next>
        <LAGridContainer layouts={{ pt: 2 }}>
          <LASimpleTable tableData={managementTableData} />
        </LAGridContainer>
        <LAWarningLabel>
          노출/미노출, 삭제 시 실시간으로 반영됩니다.
        </LAWarningLabel>
      </LACard>

      <KeywordRegistModal
        show={keywordRegistModal.state}
        handleClose={registClosed}
      />
    </>
  );
}

export default KeywordManagement;
