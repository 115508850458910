import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LACard,
  LACardTitle,
  LAComboBox,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LARadioGroup,
  LADatePicker,
  LASimpleTable,
  useInput,
  useDatePicker,
  useRadioGroup,
  useModal,
  LAWarningLabel,
} from 'modules/Widgets';
import moment from 'moment';
import PointMemberModal from './PointMemberModal';
import PointExcelMemberModal from './PointExcelMemberModal';
import { useComboBox } from 'modules/Widgets';
import { toCurrency } from 'common/utils';
import { item } from 'modules/Sidenav/styles/sidenavItem';

function PointManagementContents({ handleApply }) {
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [members, setMembers] = useState([]);
  const [history, setHistory] = useState([]);
  const [deductMember, setDeductMember] = useState(undefined);
  const pointType = useRadioGroup(1);
  const pointAmount = useInput('', 10, 'phone');
  const historyType = useComboBox(5);
  const pointReason = useInput('');
  const historyDescription = useInput('');
  const validDate = useDatePicker(
    moment().add(1, 'months').format('YYYY-MM-DD'),
  );
  const pointMemberModal = useModal(false);
  const pointExcelMemberModal = useModal(false);

  const historyTypeItems = useMemo(() => {
    if (pointType.state == 1) {
      return [
        {
          key: 5,
          value: '라이브올 관리자 지급',
        },
        // {
        //   key: 6,
        //   value: '라이브올 관리자 환불',
        // },
        {
          key: 7,
          value: '이벤트 리워드',
        },
        {
          key: 9,
          value: '제휴 리워드',
        },
        {
          key: 10,
          value: '특정인 리워드',
        },
        {
          key: 11,
          value: '광고 리워드',
        },
        {
          key: 99,
          value: '직접 입력',
        },
      ];
    } else {
      return [
        {
          key: 8,
          value: '라이브올 관리자 차감',
        },
        {
          key: 99,
          value: '직접 입력',
        },
      ];
    }
  }, [pointType.state]);

  const totalDeductPoint = useMemo(() => {
    return history.reduce((acc, item) => acc + parseInt(item.deduct_point), 0);
  }, [history]);

  useEffect(() => {
    if (pointType.state == 1) {
      historyType.select(5);
    } else {
      historyType.select(8);
    }
  }, [pointType.state]);

  const { request: requestPointHistory } = useCommonQuery({
    query: queries.Point.earnedHistory,
    params: {
      member_seq: deductMember?.member_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setHistory(
            data.result_data.map((item) => ({ ...item, deduct_point: 0 })),
          );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '포인트 적립이력 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestUpdatePoint } = useCommonMutation({
    query: queries.Point.sendPoint,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '포인트 등록이 완료되었습니다.', {
            confirmHandler: () => handleApply(),
            cancelHandler: () => handleApply(),
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '포인트 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestDeductPoint } = useCommonMutation({
    query: queries.Point.deductPoint,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '포인트 차감이 완료되었습니다.', {
            confirmHandler: () => handleApply(),
            cancelHandler: () => handleApply(),
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '포인트 차감에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (pointType.state == 2 && deductMember?.member_seq) {
      requestPointHistory();
    }
  }, [pointType.state, deductMember]);

  const onMemberChange = useCallback(
    (item) => {
      if (pointType.state == 1) {
        setMembers((prev) => {
          if (
            prev.findIndex((member) => member.member_seq == item.member_seq) >=
            0
          ) {
            return prev.filter(
              (member) => member.member_seq != item.member_seq,
            );
          } else {
            return prev.concat(item);
          }
        });
      } else {
        setDeductMember(item);
      }
    },
    [pointType.state],
  );

  const isSelectedMember = useCallback(
    (item) => {
      if (pointType.state == 1) {
        return (
          members.findIndex((member) => member.member_seq == item.member_seq) >=
          0
        );
      } else {
        return deductMember?.member_seq == item.member_seq;
      }
    },
    [pointType.state, members, deductMember],
  );

  const onDeductPointChange = useCallback((e, item) => {
    let value = parseInt(
      e.target.value.length > 0 ? e.target.value.replace(/\D/g, '') : 0,
    );
    value = value > item.remaining_point ? item.remaining_point : value;
    setHistory((prev) =>
      prev.map((historyItem) =>
        historyItem.history_seq === item.history_seq
          ? {
              ...historyItem,
              deduct_point: `${value}`.slice(0, 10),
            }
          : historyItem,
      ),
    );
  }, []);

  const getMemberRows = useCallback(() => {
    return members
      .sort((a, b) => {
        if (a.member_seq > b.member_seq) return -1;
        if (a.member_seq < b.member_seq) return 1;
        return 0;
      })
      .map((item) => [
        {
          text: `${item.member_seq}`,
        },
        {
          text: item.member_name,
        },
        {
          text: item.email,
        },
        {
          text: '삭제',
          type: 'button',
          callback: {
            onClick: () => onMemberChange(item),
          },
        },
      ]);
  }, [members]);

  const membersTableData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '이름',
        },
        {
          text: '가입자 아이디',
        },
        {
          text: '삭제',
        },
      ],

      wPercentList: [15, 35, 35, 15],
      rows: getMemberRows(),
    };
  }, [getMemberRows]);

  const getHistoryRows = useCallback(() => {
    return history.map((item) => [
      {
        text: item.history_type_name,
      },
      {
        text: item.memo,
      },
      {
        text: `${item.point}`,
      },
      {
        text: `${item.remaining_point}`,
      },
      {
        type: 'input',
        callback: {
          value: item.deduct_point,
          onChange: (e) => onDeductPointChange(e, item),
        },
      },
      {
        text:
          item.expiration_dt?.length > 0
            ? moment(item.expiration_dt).format('YYYY-MM-DD')
            : '',
      },
      {
        text:
          item.created_dt?.length > 0
            ? moment(item.created_dt).format('YYYY-MM-DD (dd) A hh:mm')
            : '',
      },
      {
        text: item.auto_yn > 0 ? 'System' : item.constructor_name,
      },
    ]);
  }, [history]);

  const historyTableData = useMemo(() => {
    return {
      headers: [
        {
          text: '항목',
        },
        {
          text: '지급 사유',
        },
        {
          text: '지급 포인트',
        },
        {
          text: '차감 가능 포인트',
        },
        {
          text: '차감 포인트',
        },
        {
          text: '유효마감일',
        },
        {
          text: '등록일',
        },
        {
          text: '등록자',
        },
      ],

      wPercentList: [15, 15, 10, 10, 10, 10, 20, 10],
      rows: getHistoryRows(),
    };
  }, [getHistoryRows]);

  const onAddByExcelClick = useCallback(() => {

  }, [])

  const onAddMemberClick = useCallback(() => {
    pointMemberModal.visible(true);
  }, [pointMemberModal]);

  const onDeleteMemberClick = useCallback(() => {
    setDeductMember(undefined);
    setHistory([]);
  }, []);

  const onRegistClick = useCallback(
    (e) => {
      if (pointType.state == 1) {
        if (!pointAmount?.state || pointAmount.state.length === 0) {
          showAlert('알림', '포인트 금액을 입력해주세요.');
          return;
        }
        if (pointAmount.state == 0) {
          showAlert('알림', '포인트는 1 이상 입력해주세요.');
          return;
        }
        if (members.length === 0) {
          showAlert('알림', '회원을 선택해주세요.');
          return;
        }

        if(historyType.state == 99 && (!historyDescription?.state || historyDescription?.state.length === 0)){
          showAlert('알림', '항목을 입력해주세요');
          return;
        }

        showConfirm('알림', '포인트를 등록하시겠습니까?', {
          confirmHandler: () =>
            requestUpdatePoint({
              members: members.map((item) => item.member_seq),
              history_type: historyType.state,
              point: pointAmount.state,
              point_deadline: validDate.state.replaceAll('-', ''),
              memo: pointReason.state,
              history_description:historyDescription.state
            }),
        });
      } else {
        if (!deductMember?.member_seq) {
          showAlert('알림', '회원을 선택해주세요.');
          return;
        }
        if (history.length === 0) {
          showAlert('알림', '차감 항목이 없습니다.');
          return;
        }
        if (totalDeductPoint === 0) {
          showAlert('알림', '차감 포인트를 입력해주세요.');
          return;
        }

        showConfirm('알림', '포인트를 차감하시겠습니까?', {
          confirmHandler: () =>
            requestDeductPoint({
              member_seq: deductMember.member_seq,
              deducts: history.map((item) => ({
                earned_seq: item.earned_seq,
                point: parseInt(item.deduct_point),
              })),
              history_type: historyType.state,
              memo: pointReason.state,              
              history_description:historyDescription.state
            }),
        });
      }
    },
    [
      members,
      historyType.state,
      pointAmount.state,
      validDate.state,
      pointReason.state,
      deductMember,
      history,
      requestUpdatePoint,
      requestDeductPoint,
      historyDescription.state
    ],
  );

  const onExcelBatchRegistClick = useCallback(() => {
    pointExcelMemberModal.visible(true);
  }, [pointExcelMemberModal]);

  const onExcelMemberApplyClick = useCallback(
    (e, appliedMembers) => {
      setMembers(
        appliedMembers.map((item) => ({
          ...item,
          member_name: item.name,
        })),
      );
      pointExcelMemberModal.handleClose(e);
      showAlert('알림', '등록되었습니다.');
    },
    [pointExcelMemberModal.handleClose],
  );

  return (
    <>
      {/* Start Info Area */}
      <LACard>
        <LACardTitle title="올머니 정보 입력" />
        <LAProperty name="지급/차감" required>
          <LARadioGroup
            props={[
              {
                text: '지급',
                value: 1,
              },
              {
                text: '차감',
                value: 2,
              },
            ]}
            value={pointType.state}
            onChange={pointType.handleChange}
          />
        </LAProperty>

        {pointType.state == 1 && (
          <LAProperty name="올머니" required>
            <LAInput
              wPercent={30}
              placeholder="올머니는 숫자만 입력해주세요."
              value={pointAmount.state}
              onChange={pointAmount.handleChange}
            />
          </LAProperty>
        )}

        <LAProperty name="항목" required>
        <LAGridContainer>
          <LAComboBox
            wPercent={15}
            items={historyTypeItems}
            value={historyType.state}
            onChange={historyType.handleChange}
          />
          <LALabel wPercent={1}></LALabel>
          {historyType.state == 99 ?(      
            
              <LAInput
              wPercent={50}
                placeholder="입력한 내용은 고객에게 노출되는 항목입니다.(고객용)"
                value={historyDescription.state}
                onChange={historyDescription.handleChange}
              />
          
        ):''}
        </LAGridContainer>
        </LAProperty>

        
        


        <LAProperty name="지급/차감 사유 (관리메모)">
          <LAInput
            placeholder="입력한 내용은 고객에게 노출되지 않습니다. (관리자용)"
            value={pointReason.state}
            onChange={pointReason.handleChange}
          />
        </LAProperty>

        {pointType.state == 1 ? (
          <LAProperty name="유효 마감일" required>
            <LADatePicker
              wPercent={15}
              value={validDate.state}
              onChange={validDate.handleChange}
            />
          </LAProperty>
        ) : (
          <LAProperty name="회원 추가" required>
            <LAGridContainer>
              {!deductMember ? (
                <LAButton
                  variant={'outlined'}
                  wPercent={15}
                  onClick={onAddMemberClick}
                >
                  회원 선택
                </LAButton>
              ) : (
                <>
                  <LALabel wPercent={20}>
                    {`${deductMember?.email} (${deductMember?.member_name})`}
                  </LALabel>
                  <LAButton wPercent={10} onClick={onDeleteMemberClick}>
                    삭제
                  </LAButton>
                </>
              )}
            </LAGridContainer>
          </LAProperty>
        )}
      </LACard>
      {/* End Info Area */}

      {pointType.state == 1 ? (
        <LACard next>
          <LACardTitle title={`올머니 적용 회원 목록 (${members.length})명`} />
          <LAGridContainer pt={1}>
            <LASimpleTable isPagination tableData={membersTableData} />
          </LAGridContainer>
        </LACard>
      ) : (
        <LACard next>
          <LACardTitle title={`올머니 차감 항목 선택`} />
          <LAGridContainer pt={1}>
            <LASimpleTable isPagination tableData={historyTableData} />
          </LAGridContainer>
          <LAWarningLabel pt={1}>
            {
              '올머니 차감은 지급 내역별로 차감됩니다.\n여러 내역 차감 시, 항목은 여러 번 노출 됩니다.'
            }
          </LAWarningLabel>
          <LAProperty name="차감 올머니">
            <LALabel>{toCurrency(totalDeductPoint)}원</LALabel>
          </LAProperty>
        </LACard>
      )}

      {pointType.state == 1 ? (
        <LAGridContainer layouts={{ pt: { sm: 2 } }}>
          <LALabel wPercent={27.5}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onExcelBatchRegistClick}
          >
            엑셀 회원 일괄 등록
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onAddMemberClick}
          >
            회원 추가
          </LAButton>
          <LAButton
            wPercent={15}
            onClick={onRegistClick}
          >
            저장
          </LAButton>
          <LALabel wPercent={27.5}></LALabel>
        </LAGridContainer>
      ) : (
        <LAGridContainer layouts={{ pt: { sm: 2 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onAddByExcelClick}
          >
            엑셀 회원 일괄등록
          </LAButton>
          <LAButton
            wPercent={15}
            onClick={onRegistClick}
          >
            저장
          </LAButton>
          <LALabel wPercent={27.5}></LALabel>
        </LAGridContainer>
      )}

      {pointMemberModal.state && (
        <PointMemberModal
          show={pointMemberModal.state}
          handleClose={pointMemberModal.handleClose}
          handleMemberChange={onMemberChange}
          isSelectedMember={isSelectedMember}
        />
      )}

      {pointExcelMemberModal.state && (
        <PointExcelMemberModal
          show={pointExcelMemberModal.state}
          handleClose={pointExcelMemberModal.handleClose}
          handleApply={onExcelMemberApplyClick}
        />
      )}
    </>
  );
}

export default React.memo(PointManagementContents);
