import React, { useCallback } from 'react';
import moment from 'moment';
import CurriculumList from './CurriculumList';
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  useComboBox,
  useInput,
  useDatePicker,
} from 'modules/Widgets';
import { LALabel } from 'modules/Widgets';

const hourItems = [
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '10', value: '10분' },
  { key: '20', value: '20분' },
  { key: '30', value: '30분' },
  { key: '40', value: '40분' },
  { key: '50', value: '50분' },
];

function CurriculumView({ classTime, curriculums, onChange }) {
  const tempCurriculumName = useInput('커리큘럼');
  const tempPrice = useInput(10000);
  const tempDiscountPrice = useInput(9000);

  const onAddCurriculum = useCallback(() => {
    if (classTime <= curriculums.length) {
      alert('지정된 회차 이상 추가할 수 없습니다.');
      return;
    }

    if (!tempCurriculumName.state) {
      alert('커리큘럼명을 입력해주세요');
      return;
    }

    if (tempCurriculumName.state.length < 3) {
      alert('커리큘럼명은 3글자 이상 넣어주세요');
      return;
    }

    if (!tempPrice.state || tempPrice.state == 0) {
      alert('금액을 입력해주세요');
      return;
    }

    if (tempPrice.state < 1000) {
      alert('커리큘럼 금액이 1,000원 보다 작습니다.');
      return;
    }

    // if(!tempDiscountPrice.state || tempDiscountPrice.state == 0){
    //   alert('할인 금액을 입력해주세요.')
    //   return
    // }

    let curriculum = {
      curriculum_name: tempCurriculumName.state,
      price: tempPrice.state,
      discount_price: tempDiscountPrice.state,
    };

    onChange([...curriculums, curriculum]);

    // tempCurriculumName.update('')
    // tempPrice.update(0)
    // tempDiscountPrice.update(0)
  }, [curriculums, tempCurriculumName, tempPrice, tempDiscountPrice]);

  return (
    <>
      <LACard next>
        <LACardTitle title="클래스 커리큘럼 정보" />
        <LAProperty name="커리큘럼 명칭">
          <LAGridContainer>
            <LAInput
              placeholder="커리큘럼 명칭"
              value={tempCurriculumName.state}
              onChange={tempCurriculumName.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="커리큘럼 금액">
          <LAGridContainer>
            <LALabel wPercent={10}>정상가</LALabel>
            <LAInput
              wPercent={20}
              type="number"
              placeholder="정상가"
              name="price"
              value={tempPrice.state}
              onChange={tempPrice.handleChange}
            />
            <LALabel wPercent={10}> </LALabel>
            <LALabel wPercent={10}>할인가</LALabel>
            <LAInput
              wPercent={20}
              type="number"
              placeholder="정상가"
              name="price"
              value={tempDiscountPrice.state}
              onChange={tempDiscountPrice.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="일정">
          <LAGridContainer>
            <LAButton wPercent={15} onClick={onAddCurriculum}>
              커리큘럼 추가
            </LAButton>
          </LAGridContainer>
          <LAGridContainer pt={1}>
            <CurriculumList curriculums={curriculums} />
          </LAGridContainer>
          <LAWarningLabel>
            패키지(표준순환, 개별 맞춤) : 클래스 종료일은 클래스 기본 정보에서
            선택한 클래스 회차에 따라 자동 계산되어 표시됩니다.
          </LAWarningLabel>
        </LAProperty>
      </LACard>
    </>
  );
}
export default React.memo(CurriculumView);
