import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/classApplications';

const ClassApplicationApi = (requestFunc) => {
  return {
    ClassApplication : {
        /**
       * 전체 수업 조회
       */
      getList: {
        key: 'getClassApplicationList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },

      /**
       * 신규 수업 등록
       */
      regist: {
        key: 'registClassApplication',
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}`, data),
      },

      
      /**
       * 수업 상세 조회
       */
      getDetail: {
        key: 'getClassApplicationDetail',
        queryFn: (applicationSeq) => 
            requestFunc(METHOD.GET, `${baseURL}/${applicationSeq}`),          
          
      },

      /**
       * 수업 수정
       */
      modify: {
        key: 'modifyClassApplication',
        queryFn: (applicationSeq, data) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${applicationSeq}`, data),
      },

      /**
       * 수업 승인
       */
       approve: {
        key: 'approveClassApplication',
        queryFn: (applicationSeq) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${applicationSeq}/approve`),
      },

      

      /**
       * 수업 삭제
       */
      delete: {
        key: 'deleteClassApplication',
        queryFn: (category_seq) =>
          requestFunc(METHOD.DELETE, `${baseURL}/${category_seq}`),
      },

    }
    
  };
};

export default ClassApplicationApi;
