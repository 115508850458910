import React, { useState, useCallback, useRef, useEffect } from 'react';
import {getTodayDateText, sendCacheRefresh} from 'common/utils';
import MDBox from 'components/MDBox';
import EmptyImage from 'assets/images/empty.png';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LAModal,
  LACard,
  LACardTitle,  
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LAFileAttachment,
  LATextEditor,  
  useInput,
  useDatePicker,
  useComboBox,
  useFileAttachment,
  useRadioGroup,
  LAImage
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import moment from 'moment';

const contentTypeItems = [
  
  {
    text: '이미지',
    value: 0,
  },
  {
    text: '텍스트',
    value: 1,
  },
]

const reservationTypeItems = [
  
  {
    text: '즉시',
    value: 0,
  },
  {
    text: '예약',
    value: 1,
  },
]

const hourItems = [
{ key: '01', value: '오전 1시' },
{ key: '02', value: '오전 2시' },
{ key: '03', value: '오전 3시' },
{ key: '04', value: '오전 4시' },
{ key: '05', value: '오전 5시' },
{ key: '06', value: '오전 6시' },
{ key: '07', value: '오전 7시' },
{ key: '08', value: '오전 8시' },
{ key: '09', value: '오전 9시' },
{ key: '10', value: '오전 10시' },
{ key: '11', value: '오전 11시' },
{ key: '12', value: '오후 12시' },
{ key: '13', value: '오후 1시' },
{ key: '14', value: '오후 2시' },
{ key: '15', value: '오후 3시' },
{ key: '16', value: '오후 4시' },
{ key: '17', value: '오후 5시' },
{ key: '18', value: '오후 6시' },
{ key: '19', value: '오후 7시' },
{ key: '20', value: '오후 8시' },
{ key: '21', value: '오후 9시' },
{ key: '22', value: '오후 10시' },
{ key: '23', value: '오후 11시' },
{ key: '00', value: '오전 12시' },
];

const minItems = [
{ key: '00', value: '정각' },
{ key: '05', value: '05분' },
{ key: '10', value: '10분' },
{ key: '15', value: '15분' },
{ key: '20', value: '20분' },
{ key: '25', value: '25분' },
{ key: '30', value: '30분' },
{ key: '35', value: '35분' },
{ key: '40', value: '40분' },
{ key: '45', value: '45분' },
{ key: '50', value: '50분' },
{ key: '55', value: '55분' },
];


function PopupBannerEditModal({ show, handleClose, bannerSeq }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [bannerInfo, setBannerInfo] = useState(undefined);

  const [modalTitle, setModalTitle] = useState('팝업 배너');
  const [bannerType, setBannerType] = useState(6);
  const bannerTitle = useInput('');
  const bannerLinkUrl = useInput('');
  const todayDateText = getTodayDateText('-');
  const pcImageFile = useFileAttachment(5 * 1024, {
    name: '',
  });
  const mobileImageFile = useFileAttachment(5 * 1024, {
    name: '',
  });

  const getTextEditorPCValue = useRef(null);
  const getTextEditorMobileValue = useRef(null);
  
  const contentType = useRadioGroup(0);
  const reservationType = useRadioGroup(0);
  const reservationStartDate = useDatePicker(todayDateText);
  const reservationStartHourValue = useComboBox('09');
  const reservationStartMinuteValue = useComboBox('00');
  const reservationEndDate = useDatePicker(
    moment().add(6, 'd').format('YYYY-MM-DD'),
  );
  const reservationEndHourValue = useComboBox('09');
  const reservationEndMinuteValue = useComboBox('00');
  

  const updateInputs = useCallback(
    (data) => {
      bannerTitle.update(data.banner_title);
      bannerLinkUrl.update(data.banner_link_url || '');
      
      if(data.content_type == 0){
        pcImageFile.update({
          file: data.image_url,
        });
        mobileImageFile.update({
          file: data.mobile_image_url,
        });
      }
      setBannerType(data.banner_type || 6)
      contentType.update(data.content_type || 0)
      reservationType.update(data.reservation_yn || 0)
      if(data.reservation_yn == 1){        
        const startMoment = moment(data.reservation_start_dt);
        const endMoment = moment(data.reservation_end_dt);
        reservationStartDate.select(startMoment.format('YYYY-MM-DD'));
        reservationStartHourValue.select(startMoment.format('HH'));
        reservationStartMinuteValue.select(startMoment.format('mm'));
        reservationEndDate.select(endMoment.format('YYYY-MM-DD'));
        reservationEndHourValue.select(endMoment.format('HH'));
        reservationEndMinuteValue.select(endMoment.format('mm'));
        
      }
      
    },
    [
      bannerTitle.update,
      bannerLinkUrl.update,
      pcImageFile.state.info,
      mobileImageFile.state.info,
      contentType.update,
      reservationType.update,
    ],
  );

  const { request: requestBannerInfo } = useCommonQuery({
    query: queries.Banner.getBannerInfo,
    params: {
      banner_seq: bannerSeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setBannerInfo(data.result_data);
          updateInputs(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '배너 상세 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestRegist } = useCommonMutation({
    query: queries.Banner.registBanner,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '배너가 등록되었습니다.', {
            confirmHandler: () => {
              bannerTitle.reset();
              bannerLinkUrl.reset();
              pcImageFile.reset();
              mobileImageFile.reset();
              handleClose();
            },
          });

          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '배너 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestUpdate } = useCommonMutation({
    query: queries.Banner.updateBanner,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '배너가 수정되었습니다.');

          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '배너 수정에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    bannerTitle.reset();
    bannerLinkUrl.reset();
    pcImageFile.reset();
    mobileImageFile.reset();

    setModalTitle('팝업 배너 등록');

    if (!bannerSeq) {
      return;
    }
    setModalTitle('팝업 배너 상세');
    requestBannerInfo();
  }, [bannerSeq]);

  const onPcImageFileChange = useCallback(
    (e) => {
      // setDeletedAdditionalImages((prev) => [...prev, pcImageFile.state.image_seq]);
      pcImageFile.handleChange(e);
    },
    [pcImageFile],
  );

  const onMobileImageFileChange = useCallback(
    (e) => {
      // setDeletedAdditionalImages((prev) => [...prev, pcImageFile.state.image_seq]);
      mobileImageFile.handleChange(e);
    },
    [mobileImageFile],
  );

  const onRegistClick = useCallback(() => {


    if (!bannerTitle?.state || bannerTitle.state.length === 0) {
      showAlert('알림', '배너 제목을 입력해주세요.');
      return;
    }
    if(reservationType.state > 0){
      let rsd = moment(`${reservationStartDate.state} ${reservationStartHourValue.state}:${reservationStartMinuteValue.state}`)
      let red = moment(`${reservationEndDate.state} ${reservationEndHourValue.state}:${reservationEndMinuteValue.state}`)
      
      if(moment(rsd).isAfter(red)){
        showAlert('알림', '예약 노출일을 확인해주세요');
        return;
      }
    }

    var contentHtmlForPC = '';
    var contentHtmlForMobile = '';
    if(contentType.state == 0){
      if (!pcImageFile?.state?.file || pcImageFile.state.file.length === 0) {
        showAlert('알림', '배너이미지(PC)을 선택해주세요.');
        return;
      }
  
      if (
        !mobileImageFile?.state?.file ||
        mobileImageFile.state.file.length === 0
      ) {
        showAlert('알림', '배너이미지(Mobile)을 선택해주세요.');
        return;
      }
    }else{
      if (!getTextEditorPCValue.current || getTextEditorPCValue.current().length == 0 ) {
        showAlert('알림', 'PC 내용을 입력해주세요.');
        return;
      } else {
        if (getTextEditorPCValue.current().length * 0.7 > 5000000) {
          showAlert(
            '알림',
            'PC 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.',
          );
          return;
        }
        contentHtmlForPC = `<div style="font-size: 16px; font-family: 노토산스;">${getTextEditorPCValue.current()}</div>`;
      }

      if (!getTextEditorMobileValue.current || getTextEditorMobileValue.current().length == 0 ) {
        showAlert('알림', '모바일 내용을 입력해주세요.');
        return;
      } else {
        if (getTextEditorMobileValue.current().length * 0.7 > 5000000) {
          showAlert(
            '알림',
            '모바일 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.',
          );
          return;
        }
        contentHtmlForMobile = `<div style="font-size: 16px; font-family: 노토산스;">${getTextEditorMobileValue.current()}</div>`;
      }
    }
    
    
    

    const formData = new FormData();
    formData.append('banner_title', bannerTitle.state);
    formData.append('banner_link_url', bannerLinkUrl.state);
    formData.append('content_type', contentType.state);
    formData.append('banner_type', bannerType);

    if(contentType.state == 0){
      if (pcImageFile.state.info) {
        formData.append('image_file', pcImageFile.state.info);
      }
      if (mobileImageFile.state.info) {
        formData.append('mobile_image_file', mobileImageFile.state.info);
      }
    }else{
      formData.append('content_pc', contentHtmlForPC);
      formData.append('content_mobile', contentHtmlForMobile);
    }
    formData.append('reservation_yn', reservationType.state);
    formData.append(
      'reservation_start_dt',
      `${reservationStartDate.state} ${reservationStartHourValue.state}:${reservationStartMinuteValue.state}`,
    );
    formData.append(
      'reservation_end_dt',
      `${reservationEndDate.state} ${reservationEndHourValue.state}:${reservationEndMinuteValue.state}`,
    );
    

    if (bannerSeq) {
      formData.append('banner_seq', bannerSeq);
      const params = {
        banner_seq: bannerSeq,
        data: formData,
      };
      requestUpdate(params);
    } else {
      requestRegist(formData);
    }
  }, [
    bannerSeq,
    bannerTitle.state,
    bannerLinkUrl.state,
    pcImageFile.state,
    mobileImageFile.state,
    contentType.state,
    bannerType,
    reservationType.state,
    reservationStartDate.state,
    reservationStartHourValue.state,
    reservationStartMinuteValue.state,
    reservationEndDate.state,
    reservationEndHourValue.state,
    reservationEndMinuteValue.state
  ]);

  return (
    <LAModal
      title={modalTitle}
      width="80%"
      height="90%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LACardTitle title="배너 정보" />

        <LAProperty name="이미지/텍스트" required>    
            <LARadioGroup
              props={contentTypeItems}
              value={contentType.state}
              onChange={contentType.handleChange}
            />
        </LAProperty>
        <LAProperty name="제목" required>
          <LAInput
            placeholder="제목을 입력해 주세요."
            value={bannerTitle.state}
            onChange={bannerTitle.handleChange}
          />
        </LAProperty>

        <LAProperty name="링크" required>
          <LAInput
            placeholder="링크를 입력해 주세요"
            value={bannerLinkUrl.state}
            onChange={bannerLinkUrl.handleChange}
          />
          <LAWarningLabel>
            이벤트 페이지 연결 시 : 이벤트 페이지가
            https://www.liveall/event/2인 경우 "/event/2"만 입력하세요.
          </LAWarningLabel>
          <LAWarningLabel>
            키워드 검색 페이지 연결 시 : "/search/result?keyword=원데이"만
            입력하고 해당 키워드를 "원데이"에 입력하세요.
          </LAWarningLabel>
        </LAProperty>

        {contentType.state == 0 ? (
          <>
            <LAProperty name="배너 이미지(PC)" required>
            <LAGridContainer>
              <LAImage wPercent={40} file={pcImageFile.state.file} />
              <LAGridItem wPercent={52} layouts={{ ml: 2 }}>
                <LAGridContainer layouts={{ ml: 1, pl: 1 }}>
                  <LAFileAttachment
                    hiddenLabel
                    placeholder=""
                    props={{
                      accept: 'image/jpg,image/png,image/jpeg,image/gif',
                    }}
                    value={pcImageFile.state.name}
                    onChange={onPcImageFileChange}
                  />
                </LAGridContainer>
                <MDBox color="text" fontSize="1rem" lineHeight={1}>
                  <LALabel
                    layouts={{ pl: 1, pt: 1 }}
                    props={{
                      variant: 'caption',
                      color: 'error',
                      fontWeight: 'regular',
                      verticalAlign: 'middle',
                      sx: {
                        color: '#ff0000',
                      },
                    }}
                  >
                    - 이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로
                    등록됩니다.
                    <br />
                    - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)
                    <br />
                    - 이미지 사이즈 : 1196 * 572 / 형식 : jpg, jpeg, png (jpeg
                    등록 권장)
                    <br />- 5MB 이하로 등록가능
                  </LALabel>
                </MDBox>
              </LAGridItem>
            </LAGridContainer>
            </LAProperty>

            <LAProperty name="배너 이미지(Mobile)" required>
              <LAGridContainer>
                <LAImage wPercent={40} file={mobileImageFile.state.file} />
                <LAGridItem wPercent={52} layouts={{ ml: 2 }}>
                  <LAGridContainer layouts={{ ml: 1, pl: 1 }}>
                    <LAFileAttachment
                      hiddenLabel
                      placeholder=""
                      props={{
                        accept: 'image/jpg,image/png,image/jpeg,image/gif',
                      }}
                      value={mobileImageFile.state.name}
                      onChange={onMobileImageFileChange}
                    />
                  </LAGridContainer>
                  <MDBox color="text" fontSize="1rem" lineHeight={1}>
                    <LALabel
                      layouts={{ pl: 1, pt: 1 }}
                      props={{
                        variant: 'caption',
                        color: 'error',
                        fontWeight: 'regular',
                        verticalAlign: 'middle',
                        sx: {
                          color: '#ff0000',
                        },
                      }}
                    >
                      - 이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로
                      등록됩니다.
                      <br />
                      - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)
                      <br />
                      - 이미지 사이즈 : 1196 * 572 / 형식 : jpg, jpeg, png (jpeg
                      등록 권장)
                      <br />- 5MB 이하로 등록가능
                    </LALabel>
                  </MDBox>
                </LAGridItem>
              </LAGridContainer>
            </LAProperty>
          </>
        
        ):(
          <>
            <LAProperty name="배너 텍스트 (PC)" required>
              <LATextEditor
                name="description_e"
                value={bannerInfo?.content_pc || ''}
                getEditorValue={getTextEditorPCValue}
              />
            </LAProperty>
            <LAProperty name="배너 텍스트 (Mobile)" required>
              <LATextEditor
                name="description_e_mo"
                value={bannerInfo?.content_mobile || ''}
                getEditorValue={getTextEditorMobileValue}
              />
            </LAProperty>
          </>
        
        )}
        
        <LAProperty name="예약 노출" required>
          <LAGridContainer>
            <LARadioGroup
              props={reservationTypeItems}
              value={reservationType.state}
              onChange={reservationType.handleChange}
            />
          </LAGridContainer>
          {reservationType.state == 1 && (
            <LAGridContainer>
              <LADatePicker
                wPercent={20}
                layouts={{ pr: { sm: 1 } }}
                value={reservationStartDate.state}
                onChange={reservationStartDate.handleChange}
              />
              <LAComboBox
                wPercent={13}
                items={hourItems}
                layouts={{ pr: { sm: 1 } }}
                value={reservationStartHourValue.state}
                onChange={reservationStartHourValue.handleChange}
              />
              <LAComboBox
                wPercent={13}
                items={minItems}
                layouts={{ pr: { sm: 1 } }}
                value={reservationStartMinuteValue.state}
                onChange={reservationStartMinuteValue.handleChange}
              />
              <LALabel wPercent={1}>~</LALabel>
              <LADatePicker
                wPercent={20}
                layouts={{ pl: { sm: 1 } }}
                value={reservationEndDate.state}
                onChange={reservationEndDate.handleChange}
              />
              <LAComboBox
                wPercent={13}
                items={hourItems}
                layouts={{ pl: { sm: 1 } }}
                value={reservationEndHourValue.state}
                onChange={reservationEndHourValue.handleChange}
              />
              <LAComboBox
                wPercent={13}
                items={minItems}
                layouts={{ pl: { sm: 1 } }}
                value={reservationEndMinuteValue.state}
                onChange={reservationEndMinuteValue.handleChange}
              />
            </LAGridContainer>
          )}
        </LAProperty>
      </LACard>

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ mr: 1 }}
          variant={'outlined'}
          btnPaddingY={12}
          onClick={handleClose}
        >
          목록
        </LAButton>
        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onRegistClick}>
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>
    </LAModal>
  );
}

export default PopupBannerEditModal;
