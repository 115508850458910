import React, { useMemo } from 'react';
import { useCodeContext } from 'common/context/CodeContext';

import { LARadioGroup } from 'modules/Widgets';

function LACodeRadioGroup({
  codeType,
  showAll = false,
  wPercent = 100,
  value,
  onChange,
  readOnly = false,
}) {
  const { action } = useCodeContext();

  const codes = useMemo(() => {
    let data = action.getRadioCodeList(codeType, showAll);
    return data;
  }, [action, codeType, showAll]);

  return (
    <>
      <LARadioGroup
        wPercent={wPercent}
        props={codes}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
    </>
  );
}

export default React.memo(LACodeRadioGroup);
