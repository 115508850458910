import React, { useCallback, useEffect } from 'react';

import {
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  useInput,
} from 'modules/Widgets';

import moment from 'moment';

function InquireAnswer({ answer, onModify, onDelete }) {
  const answerInput = useInput('');

  const onModifyClick = useCallback(() => {
    onModify(answer.answer_seq, answerInput.state);
  });

  const onDeleteClick = useCallback(() => {
    onDelete(answer.answer_seq, answer.delete_yn);
  });

  useEffect(() => {
    if (answer) {
      answerInput.update(answer.answer);
    }
  }, [answer]);

  return (
    <LAGridContainer>
      <LAProperty name="작성자">
        <LALabel wPercent={40}>
          {answer.member_name}({answer.member_type_name}){' '}
          {moment(answer.answer_dt).format('YYYY-MM-DD (dd) A hh:mm')}
        </LALabel>
      </LAProperty>
      <LAProperty name="내용">
        <LAGridContainer>
          <LAInput
            wPercent={70}
            value={answerInput.state}
            onChange={answerInput.handleChange}
          />
          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={onModifyClick}
          >
            수정
          </LAButton>
          <LAButton
            wPercent={10}
            layouts={{ pl: { sm: 1 } }}
            onClick={onDeleteClick}
          >
            {answer.delete_yn ? '노출' : '미노출'}
          </LAButton>
          {answer.delete_yn ? (
            <LALabel
              layouts={{ pl: { sm: 1 } }}
              props={{
                variant: 'caption',
                color: 'error',
                fontWeight: 'regular',
                verticalAlign: 'middle',
                sx: {
                  color: '#ff0000',
                },
              }}
            >
              {moment(answer.delete_dt).format('YYYY-MM-DD (dd) A hh:mm')} :
              미노출되었습니다
            </LALabel>
          ) : (
            ''
          )}
        </LAGridContainer>
      </LAProperty>
    </LAGridContainer>
  );
}

export default InquireAnswer;
