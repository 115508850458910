import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LASimpleTable,  
} from 'modules/Widgets';

function MainContentClassTable({ items, moveUpCallback,moveDownCallback, deleteItemCallback }) {
  
  const getTableRows = useCallback(() => {
    let rows = [];
    if(items && items.length > 0) {
      items.forEach((item, index) => {
        item.exposure_no = index + 1;
        rows.push([
          {
            text: item.class_seq,
          },
          {
            text: item.class_name,
            align: 'left',
          },
          {
            text: item.exposure_no,
          },
          {
            text: '▲',
            type: 'button',
            variant: 'outlined',
            callback: {
              onClick: ()=> moveUpCallback(item.exposure_no)
            },
          },
          {
            text: '▼',
            type: 'button',
            variant: 'outlined',
            callback: {
              onClick: ()=> moveDownCallback(item.exposure_no)
            },
          },
          {
            text: '삭제',
            type: 'button',
            callback: {
              onClick: ()=> deleteItemCallback(item.exposure_no, item.class_seq)
            },
          }
        ]);
      });
    }
    return rows;
  }, [items]);

  const getTableData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '순서',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '삭제',
        },
      ],
      wPercentList: [10, 50, 10, 10, 10, 10],
      rows: getTableRows(),
    };
  }, [items]);


  return (
    <>      
      <LACardTitle title="현재 노출 목록" />
        <LAGridContainer layouts={{ pt: 1 }}>
        <LASimpleTable tableData={getTableData} />
      </LAGridContainer>
      

      {/* <LAGridContainer layouts={{ pt: 2 }}>
        <LASimpleTable tableData={getTableData} />
      </LAGridContainer>       */}
    </>
  );  
}

export default React.memo(MainContentClassTable);
