import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/codes';

const CodeApi = (requestFunc) => {
  return {
    Code: {
      materials: {
        key: 'getMaterials',
        queryFn: (params) => requestFunc(METHOD.GET, `/admin/materials`, params),
      },
      getList: {
        key: 'getCodeList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/all`, params),
      },
      getDbCodeList: {
        key: 'getDbCodeList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/new`, params),
      },
      getTypeList: {
        key: 'getCodeTypeList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
    },
  };
};

export default CodeApi;
