import React, { useState, useCallback ,useEffect} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import ClassReviewDetailContents from './components/ClassReviewDetailContents';


function TeacherDetail() {
  

  return (
    <>
      <DashboardNavbar title="클레스 후기 상세" />
      <ClassReviewDetailContents />
    </>
  );
}

export default TeacherDetail;
