import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/classes';

const ClassApi = (requestFunc) => {
  return {
    Class: {
      /**
       * 전체 수업 조회
       */
      getList: {
        key: 'getClassList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },

      /**
       * 신규 수업 등록
       */
      regist: {
        key: 'registClass',
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}`, data),
      },

      /**
       * 수업 상세 조회
       */
      getDetail: {
        key: 'getClassDetail',
        queryFn: (class_seq) => requestFunc(METHOD.GET, `${baseURL}/${class_seq}`),
      },

      /**
       * 수업 수정
       */
      modify: {
        key: 'modifyClass',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.class_seq}`, params),
      },

      /**
       * 수업 삭제
       */
      delete: {
        key: 'deleteClass',
        queryFn: (classSeq) => requestFunc(METHOD.DELETE, `${baseURL}/${classSeq}`),
      },

      /**
       * 수업 수정
       */
      modifyAll: {
        key: 'modifyClassAll',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.class_seq}/modifyAll`, params.data),
      },

      /**
       * 수업 복사
       */
       copyAll: {
        key: 'copyClassAll',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/${params.class_seq}/copyAll`),
      },
      
      /**
       * 수업 승인
       */
      approve: {
        key: 'approveClass',
        queryFn: (class_seq) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${class_seq}/approve`),
      },

      /**
       * 수업 노출
       */
       changeActivate: {
        key: 'changeClassActivate',
        queryFn: (data) => requestFunc(METHOD.PATCH, `${baseURL}/${data.class_seq}/activate`, data),
      },

      /**
       * 수업 삭제
       */
      delete: {
        key: 'deleteClass',
        queryFn: (category_seq) => requestFunc(METHOD.DELETE, `${baseURL}/${category_seq}`),
      },

      /**
       * 수업별 과정 조회
       */
      getCurriculumns: {
        key: 'getClassSchedules',
        queryFn: (class_seq) => requestFunc(METHOD.GET, `${baseURL}/${class_seq}/curriculumns`),
      },

      /**
       * 수업별 과정 확정
       */
      confirmCurriculumn: {
        key: 'confirmClassCurriculumn',
        queryFn: (class_seq, curriculum_seq) => requestFunc(METHOD.PATCH, `${baseURL}/${class_seq}/curriculumns/${curriculum_seq}/confirm`),
      },

      /**
       * 수업별 과정 폐강
       */
      closeCurriculumn: {
        key: 'closeClassCurriculumn',
        queryFn: (class_seq, curriculum_seq) => requestFunc(METHOD.PATCH, `${baseURL}/${class_seq}/curriculumns/${curriculum_seq}/close`),
      },

      /**
       * 수업별 수업 일정 전체 조회
       */
      getSchedules: {
        key: 'getClassSchedules',
        queryFn: (class_seq) =>
          requestFunc(METHOD.GET, `${baseURL}/${class_seq}/schedules`),
      },

      /**
       * 수업별 수업 일정 등록
       */
      registSchedules: {
        key: 'registClassSchedules',
        queryFn: (class_seq, data) => requestFunc(METHOD.POST, `${baseURL}/${class_seq}/schedules`, data),
      },

      /**
       * 수업별 수업 일정 수정
       */
      modifySchedules: {
        key: 'modifyClassSchedules',
        queryFn: (class_seq, schedule_seq, data) => requestFunc(METHOD.PATCH, `${baseURL}/${class_seq}/schedules/${schedule_seq}`, data),
      },

      /**
       * 수업별 수업 일정 삭제
       */
      deleteSchedules: {
        key: 'deleteClassSchedules',
        queryFn: (class_seq, schedule_seq) => requestFunc(METHOD.DELETE, `${baseURL}/${class_seq}/schedule/${schedule_seq}`),
      },

      /**
       * 수업별 일정별 수강신청 목록
       */
      getSchedulesApplications: {
        key: 'getSchedulesApplications',
        queryFn: (class_seq) => requestFunc(METHOD.GET, `${baseURL}/${class_seq}/schedule/applications`),
      },

    },
  };
};

export default ClassApi;
