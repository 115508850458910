import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import React, { useMemo } from 'react';

function LACalendar({ events, contentHeight="auto" ,onSelected,onMonthChanged}) {
  const handleDateClick = (info) => {
  };
  const handleDateSelect = (info) => {
    onSelected(info);
  };
  const handleEventClick = (info) => {
    onSelected(info.event);
  };
  const handleEventDragStart = (info) => {
  };
  const handleEventDragStop = (info) => {
  };

  const handleMonthChangeEvent = (info) => {
    onMonthChanged(info)
  };

  return (
    <FullCalendar
      initialView="dayGridMonth"
      plugins={[interactionPlugin, dayGridPlugin]}
      events={events.map((event) => {
        return {
          id: event.id,
          title: event.title,
          start: `${event.date}`,
          
          // start: `${event.date}T${event.time}`,
        };
      })} // 이벤트 데이터 설정
      selectable={true} // select 이벤트 발생 여부
      longPressDelay={1} // select 이벤트 발생까지 지연시간 ms
      locale="ko" // 텍스트 로컬라이징
      contentHeight={contentHeight} // 컨텐츠 영역 높이 설정
      eventStartEditable={false} // 이벤트 이동 여부
      eventDurationEditable={false} // 이벤트의 끝점으로 리사이징 여부
      eventResizableFromStart={false} // 이벤트의 시작점으로 리사이징 여부
      dayMaxEvents={5} // 일 이벤트 최대값 설정
      dayMaxEventRows={false} // 일 이벤트 최대값 상관없이 표시 여부
      displayEventEnd={false} // 이벤트 종료 시간 표시 여부
      datesSet={handleMonthChangeEvent}
      buttonText={{
        today: '오늘',
        month: '월',
        week: '주',
        day: '일',
      }} // 버튼 텍스트 변경
      //   eventBackgroundColor="#ff0000" // 이벤트 배경 색상 설정
      //   eventBorderColor="#000000" // 이벤트 테두리 색상 설정
      //   eventTextColor="#ffff00" // 이벤트 텍스트 색상 설정
      views={{
        dayGridMonth: {
          // name of view
          //   titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
          // other view-specific options here
        },
      }}
      dateClick={handleDateClick} // 날짜 클릭 핸들러 설정
      select={handleDateSelect} // 날짜 클릭 및 드래그 핸들러 설정
      eventClick={handleEventClick} // 이벤트 클릭 핸들러 설정
      eventDragStart={handleEventDragStart} // 이벤트 드래그 시작 핸들러 설정
      eventDragStop={handleEventDragStop} // 이벤트 드래그 종료 핸들러 설정
    />
  );
}

export default React.memo(LACalendar);

// fullcalendar ducumentation
// https://fullcalendar.io/docs#toc
