import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import OrderHistoryTable from './components/OrderHistoryTable';

import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import { useApiContext } from 'common/context/ApiContext';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import OrderDetailContents from './components/OrderDetailContents';

import moment from 'moment';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  // LAGridItem,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  // LATable,
  LASimpleTable,
  useInput,
} from 'modules/Widgets';



function OrderDetail() {
  const { queries } = useApiContext();

  const receiverName = useInput('');
  const receiverMobile = useInput('');
  const receiverPhone = useInput('');
  const receiverZipCode = useInput('');
  const receiverAddress1 = useInput('');
  const receiverAddress2 = useInput('');
  const receiverMessage = useInput('');
  // const [editShipping, setEditShipping] = useState(false);

  const { showAlert, showConfirm } = usePopAlert();

  const { orderSeq } = useParams();
  const [ data, setData] = useState(null);

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1, { replace: true });
  };

  useEffect(() => {
    requestDetail();
  }, []);

  useEffect(() => {

    if(data) {
      if(data.delivery) {
        receiverName.update(data.delivery.receiver_name);
        receiverMobile.update(data.delivery.receiver_phone_no);
        receiverPhone.update(data.delivery.receiver_landline_no);
        receiverZipCode.update(data.delivery.address_zip);
        receiverAddress1.update(data.delivery.address_road);
        receiverAddress2.update(data.delivery.address_detail);
        receiverMessage.update(data.delivery.remarks);
      }
    }
  }, [data]);

  const { result : resultData , request : requestDetail} = useCommonQuery({
    query: queries.Order.getOrderDetail,
    params: orderSeq, 
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setData(data.result_data)
        } else {
          showAlert('알림', data.result_message, {
            confirmHandler: () => navigateBack(),
          });
        }
      },
      onError: (error) => {
        showAlert('알림', '결제 정보 조회에 실패하였습니다.', {
          confirmHandler: () => navigateBack(),
        });
      },
    },initEnabled:false
  });

  const { request : requestCancelOrder} = useCommonMutation({ 
    query: queries.Order.cancelOrder,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert('알림', '결제가 취소되었습니다.');
          requestDetail();
        } else {
          showAlert('알림', data.result_message, {
            confirmHandler: () => navigateBack(),
          });
        }
      },
      onError: (error) => {
        showAlert('알림', '결제 취소에 실패하였습니다.');
      },
    },initEnabled:false
  });  

  const onCancelClick = useCallback(() => {
    if(data && data.payment && data.order) {
      if(data.order.order_status==20) {
        showAlert('알림', '취소 가능한 상태가 아닙니다.');
        return;
      }
      showConfirm('확인', '결제 취소를 하시겠습니까?', {
        confirmHandler: () => {
          requestCancelOrder({order_seq:orderSeq, data:{cancel_reason:'관리자 취소'}});
        },
      });
    } else {
      showAlert('알림', '결제정보가 없습니다.');
    }
  }, [data]);

  const onDeliveryClick = useCallback(() => {
    showAlert('알림', '서비스 준비 중입니다.');
  }, [data]);

  const processHistoryTableData = {
    headers: [],
    wPercentList: [58, 12, 12, 18],
    rows: [
      [
        {
          text: '배송 완료 처리',
          align: 'left',
        },
        {
          text: '윤민지 (minji)',
        },
        {
          text: 'CS관리자',
        },
        {
          text: '2022-03-18 13:34',
        },
      ],
      [
        {
          text: '배송 중 처리',
          align: 'left',
        },
        {
          text: '윤민지 (minji)',
        },
        {
          text: 'CS관리자',
        },
        {
          text: '2022-03-18 13:34',
        },
      ],
    ],
    rowBackgroundColor: '#eeeeee',
  };

  const managementMemoTableData = {
    headers: [],
    wPercentList: [55, 10, 10, 15, 10],
    rows: [
      [
        {
          text: '클래스가 확정 처리 되었으나 고객이 환불 요청함',
          align: 'left',
        },
        {
          text: '윤민지 (minji)',
        },
        {
          text: 'CS관리자',
        },
        {
          text: '2022-03-18 13:34',
        },
        {
          text: '삭제',
          type: 'button',
          // callback: {
          //   onClick: () => console.log('click'),
          // },
        },
      ],
    ],
    rowBackgroundColor: '#eeeeee',
  };

  return (
    <>
      <DashboardNavbar title="결제 상세"/>
      <OrderDetailContents />
    </>
  );
}

export default OrderDetail;
