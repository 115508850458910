import React, { useState, useCallback, useRef, useEffect, useMemo, useContext } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

import {
    LAModal,
    LACard,
    LAButton,
    LAInput,
    useInput,
    useComboBox,
    LAProperty,
    LAWarningLabel,
    LAGridContainer
} from 'modules/Widgets';

import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import CategoryComboView from 'pages/Class/components/CategoryComboView';

function CategoryRegistModal({ type, show, handleClose, onCateRegist }) {
    const navigation = useContext(UNSAFE_NavigationContext).navigator;
    const { showAlert, showConfirm } = usePopAlert();

    const [selectedCate, setSelectedCate] = useState({ key: '', value: ''});
    const inputCateNoti = useInput('', 300);
    const inputKeyword = useInput('', 300);

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        if (navigation) {
            navigation.listen((locationListener) => {
                if (locationListener.action == 'POP') {
                    handleClose();
                }
            });
        }
    }, []);

    const onCategoryChanged = useCallback((item) => {
        setSelectedCate(item);
    }, [selectedCate]);

    const onRegistClick = useCallback((e) => {
        if(type === "CATEGORY" && !selectedCate) {
            showAlert('알림', '카테고리를 선택해 주세요.');
            return;
        }
        if(!inputCateNoti.state) {
            showAlert('알림', '카테고리 설명을 입력해 주세요.');
            return;
        }
        if(type === "KEYWORD" && !inputKeyword.state) {
            showAlert('알림', '키워드를 입력해 주세요.');
            return;
        }

        const cateData = {
            type: type,
            parent_category_seq: type === "CATEGORY" ? (selectedCate.key === 89 || selectedCate.key === 90) ? 30 : 10 : "",
            parent_category_name: type === "CATEGORY" ? (selectedCate.key === 89 || selectedCate.key === 90) ? '' : '라이브올 클래스' : "",
            category_seq: (type === "CATEGORY" ? selectedCate.key : ''),
            category_name: (type === "CATEGORY" ? selectedCate.value : ''),
            title: inputCateNoti.state,
            keyword: (type === "CATEGORY" ? '' : inputKeyword.state)
        }

        onCateRegist(e, cateData);

        inputCateNoti.reset();
        inputKeyword.reset();

        handleClose();
    },[selectedCate.state, inputCateNoti.state, inputKeyword.state]);

    return (
        <LAModal title="카테고리 등록" width="50%" height="45%" show={show} onClose={handleClose}>
            {type === "CATEGORY" ? (
                <LACard>
                    <LAProperty name="카테고리" required>
                        <LAGridContainer>
                            <CategoryComboView
                                onChange={onCategoryChanged}
                            />
                        </LAGridContainer>
                    </LAProperty>
                    <LAProperty name="카테고리 설명" required>
                        <LAInput 
                            placeholder="카테고리 설명을 입력해 주세요."
                            value={inputCateNoti.state}
                            onChange={inputCateNoti.handleChange}
                        />
                    </LAProperty>
                    <LAWarningLabel>카테고리가 포함된 모든 클래스가 노출됩니다.</LAWarningLabel>
                </LACard>
            ) : (
                <LACard>
                    <LAProperty name="키워드 입력" required>
                        <LAInput 
                            placeholder="키워드를 입력해 주세요."
                            value={inputKeyword.state}
                            onChange={inputKeyword.handleChange}
                        />
                    </LAProperty>
                    <LAProperty name="카테고리 설명" required>
                        <LAInput 
                            placeholder="카테고리 설명을 입력해 주세요."
                            value={inputCateNoti.state}
                            onChange={inputCateNoti.handleChange}
                        />
                    </LAProperty>
                    <LAWarningLabel>키워드가 포함된 모든 클래스가 노출됩니다.</LAWarningLabel>
                </LACard>
            )}
            <LAGridContainer layouts={{ mt: 3, sx: { justifyContent: "center" }}}>
                <LAButton wPercent={30} onClick={onRegistClick}>등록하기</LAButton>
            </LAGridContainer>
        </LAModal>
    );
}

export default CategoryRegistModal;
