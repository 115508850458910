import moment from 'moment';
import 'moment/locale/ko';

import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
    LAModal,
    LACard,
    LACardTitle,
    LAComboBox,
    LAGridContainer,
    LAButton,
    LASimpleTable,
    useComboBox,
} from 'modules/Widgets';
import { LAGridItem } from 'modules/Widgets';
import { LALabel } from 'modules/Widgets';

function ClassScheduleModal({
    yyyymm,
    memberSeq,
    addType,
    index,
    addSchedule,
    show,
    handleClose,
}) {
    const { queries } = useApiContext();

    const { showAlert, showConfirm } = usePopAlert();

    const queryDate = useMemo(() => moment(yyyymm));

    const thisYear = useMemo(() => queryDate.format('YYYY'), []);
    const thisMonth = useMemo(() => queryDate.format('MM'), []);

    const yearType = useComboBox(thisYear);
    const monthType = useComboBox(thisMonth);

    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        console.log(memberSeq, addType);
    }, [addType]);

    const clickSelectSchedule = useCallback((schedule_seq) => {
            console.log(schedule_seq, addType);
            addSchedule(schedule_seq, addType, index);
            handleClose();
        },[addType, index, addSchedule],
    );

    const getScheduleRows = useCallback(() => {
        const result = [];
        if (schedules && schedules.length > 0) {
            schedules.forEach((schedule, index) => {
                result.push([
                    {text: schedule.class_seq,},
                    {text: schedule.schedule_seq,},
                    {text: schedule.class_type_name,},
                    {text: schedule.class_name,},
                    {text: schedule.teacher_name,},
                    {text: moment(schedule.start_dt).format('YYYY-MM-DD (dd) A hh:mm'),},
                    {text: moment(schedule.end_dt).format('YYYY-MM-DD (dd) A hh:mm'),},
                    {text: schedule.class_time,},
                    {
                        text: '선택',
                        type: 'button',
                        callback: {
                            onClick: () => clickSelectSchedule(schedule.schedule_seq),
                        },
                    },
                ]);
            });
        }
        return result;
    }, [clickSelectSchedule, schedules]);

    const scheduleTableData = useMemo(() => {
        return {
            headers: [
                {text: '클래스 번호',},
                {text: '스케쥴 번호',},
                {text: '유형',},
                {text: '클래스명',},
                {text: '선생님',},
                {text: '수업 시작일',},
                {text: '수업 종료일',},
                {text: '수업 시간',},
                {text: '선택',},
            ],
            wPercentList: [10, 10, 6, 30, 9, 10, 10, 9, 6], // wPercentList: [10, 10, 6, 30, 9, 10, 10, 9, 6],
            rows: getScheduleRows(),
        };
    }, [getScheduleRows]);

    const getSchedules = useCallback(() => {
        requestSchedules();
    }, []);

    const { result: resultData, request: requestSchedules } = useCommonQuery({
        query: queries.Schedule.getListForTeacher,
        params: {
            start_dt: yearType.state + '-' + monthType.state + '-01',
            end_dt: yearType.state + '-' + monthType.state + '-31',
            teacher_seq: memberSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                console.log(data);
                if (data.result_code == '0000') {
                    console.log(data.result_data);
                    setSchedules(data.result_data);
                } else {
                    if (data.result_message) showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '목록 조회에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    return (
        <LAModal title="스케줄 일정 정보 조회" width="75%" height="70%" show={show} onClose={handleClose}>
            <LACard>
                <LAGridContainer vAlign="center" layouts={{ mt: 1, mb: 1 }}>
                    <LAComboBox
                        wPercent={15}
                        items={[
                            { key: '2023', value: '2023년' }, // 2023-03-03 2023년도 추가
                            { key: '2022', value: '2022년' }
                        ]}
                        value={yearType.state}
                        onChange={yearType.handleChange}
                    />
                    <LAComboBox
                        layouts={{ pl: { sm: 1 } }}
                        wPercent={15}
                        items={[
                            { key: '01', value: '1월' },
                            { key: '02', value: '2월' },
                            { key: '03', value: '3월' },
                            { key: '04', value: '4월' },
                            { key: '05', value: '5월' },
                            { key: '06', value: '6월' },
                            { key: '07', value: '7월' },
                            { key: '08', value: '8월' },
                            { key: '09', value: '9월' },
                            { key: '10', value: '10월' },
                            { key: '11', value: '11월' },
                            { key: '12', value: '12월' },
                        ]}
                        value={monthType.state}
                        onChange={monthType.handleChange}
                    />
                    <LAButton wPercent={15} layouts={{ pl: { sm: 1 } }} onClick={getSchedules}>조회</LAButton>
                </LAGridContainer>
                <LASimpleTable tableData={scheduleTableData} />
            </LACard>
        </LAModal>
    );
}

export default ClassScheduleModal;
