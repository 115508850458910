import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import moment from 'moment';
import 'moment/locale/ko';

import {
  LACard,
  LADataTable2,
  LAGridContainer,
  getTextCell,
  getNormalButton,
} from 'modules/Widgets';
import { useApiContext } from "../../../common/context/ApiContext";

function ClassScheduleList({
  onDetail,
  onClassDetail,
  defaultPage = 0,
  onChangePage = () => {},
  searchParams
}) {

  const priceSplitter = (number) =>
    number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const { queries } = useApiContext();

  const columns = useMemo(() => {
    return [
      {
        Header: '클래스번호',
        accessor: 'class_seq',
        width: '5%',
        align: 'center',
      },
      {
        Header: '일정 번호',
        accessor: 'schedule_seq',
        width: '5%',
        align: 'center',
      },
      { Header: '카테고리', accessor: 'category', align: 'center' },
      { Header: '유형', accessor: 'class_type', align: 'center' },
      { Header: '운영방식', accessor: 'brand_type', align: 'center' },
      { Header: '클래스명', accessor: 'class_name', align: 'left' },
      { Header: '회차', accessor: 'timetables_count', align: 'center' },
      { Header: '선생님', accessor: 'teacher_name', align: 'center' },
      { Header: '정원', accessor: 'children_count', align: 'center' },
      { Header: '신청자', accessor: 'application_count', align: 'center' },
      { Header: '수업시작일', accessor: 'start_dt', align: 'center' },
      { Header: '시작시간', accessor: 'time', align: 'center' },
      { Header: '종료시간', accessor: 'end_time', align: 'center' },
      { Header: '수업종료일', accessor: 'end_dt', align: 'center' },
      { Header: '상태', accessor: 'confirmation_yn', align: 'center' },

      { Header: '접수상태', accessor: 'reception_status', align: 'center' },
      { Header: '노출상태', accessor: 'activate', align: 'center' },
      // { Header: "종강", accessor: "finish_yn", align: "center" },
      // { Header: "삭제", accessor: "delete_yn", align: "center" },
      { Header: '상세', accessor: 'detail', width: '5%' },
    ];
  }, []);

  function onsClassDetail(no) {
    onClassDetail(no);
  }
  function onsDetailClick(no) {
    onDetail(no);
    // navigate(`detail/${no}`);
  }
  const getDateTime = useCallback(
    (schedule) => {
      const timetable = schedule.timetables.find(
        (t) => t.curriculum_times === 1,
      );
      return moment(`${timetable.schedule_date} ${timetable.schedule_time}`);
    },
    [],
  );

  const getTime = useCallback(
    (schedule) => {
      const timetable = schedule.timetables.find(
        (t) => t.curriculum_times === 1,
      );
      return moment(
        `${timetable.schedule_date} ${timetable.schedule_time}`,
      ).format('a hh:mm');
    },
    [],
  );

  const getClassType = useCallback((item) => {
    let result;
    switch(item.progress_type){
      case 0:
        result = '원데이'
        break;
      case 1:
        result = '순환'
        break;
      case 2:
        result = '패키지'
        break;
    }

    if(item.test_mode_yn){
      result += '[시크릿]'
    }
    return result
  },[]);

  const dataToRow = (item, index) => {
    console.log('item ::', item);
    return {
      class_seq: getTextCell(`${item.class_seq}`),
      schedule_seq: getTextCell(`${item.schedule_seq}`),
      category: getTextCell(`${item.category_name}`),
      class_name: getTextCell(
          `${item.class_name}`,
          `${item.class_seq}`,
          onsClassDetail,
      ),
      timetables_count: getTextCell(`총 ${item.curriculum_times}회`),
      class_type: getTextCell(getClassType(item)),
      brand_type: getTextCell(
          `${item.brand_type_name ? item.brand_type_name : ''}`,
      ),

      teacher_name: getTextCell(`${item.teacher_name}`),
      children_count: getTextCell(
          `${item.min_user_count}-${item.max_user_count}`,
      ),
      application_count: getTextCell(`${item.applications.length}`),
      start_dt: getTextCell(
          `${moment(item.start_dt).format('YYYY-MM-DD (dd)')}`,
      ),
      time: getTextCell(getTime(item)),
      end_time: getTextCell(
          moment(getDateTime(item))
              .add(item.class_time, 'minutes')
              .format('A hh:mm'),
      ),
      end_dt: getTextCell(
          `${moment(item.end_dt).format('YYYY-MM-DD (dd)')}`,
      ),
      confirmation_yn: getTextCell(
          `${
              item.close_yn ? '폐강' : item.confirmation_yn ? '확정' : '미확정'
          }`,
      ),
      reception_status: getTextCell(
          `${
              item.reception_status == 1
                  ? '접수중'
                  : item.reception_status == 2
                      ? '접수마감'
                      : '-'
          }`,
      ),
      activate: getTextCell(`${item.activate_yn == 1 ? '노출' : '미노출'}`),
      detail: getNormalButton(
          '변경',
          `${item.schedule_seq}`,
          onsDetailClick,
      ),
    }
  };


  /*const rows = useMemo(() => {
    if (list) {
      let rows = list.map((item) => {
        return {
          class_seq: getTextCell(`${item.class_seq}`),
          schedule_seq: getTextCell(`${item.schedule_seq}`),
          category: getTextCell(`${item.category_name}`),
          class_name: getTextCell(
            `${item.class_name}`,
            `${item.class_seq}`,
            onsClassDetail,
          ),
          timetables_count: getTextCell(`총 ${item.curriculum_times}회`),
          class_type: getTextCell(getClassType(item)),
          brand_type: getTextCell(
            `${item.brand_type_name ? item.brand_type_name : ''}`,
          ),

          teacher_name: getTextCell(`${item.teacher_name}`),
          children_count: getTextCell(
            `${item.min_user_count}-${item.max_user_count}`,
          ),
          application_count: getTextCell(`${item.applications.length}`),
          start_dt: getTextCell(
            `${moment(item.start_dt).format('YYYY-MM-DD (dd)')}`,
          ),
          time: getTextCell(getTime(item)),
          end_time: getTextCell(
            moment(getDateTime(item))
              .add(item.class_time, 'minutes')
              .format('A hh:mm'),
          ),
          end_dt: getTextCell(
            `${moment(item.end_dt).format('YYYY-MM-DD (dd)')}`,
          ),
          confirmation_yn: getTextCell(
            `${
              item.close_yn ? '폐강' : item.confirmation_yn ? '확정' : '미확정'
            }`,
          ),
          reception_status: getTextCell(
            `${
              item.reception_status == 1
                ? '접수중'
                : item.reception_status == 2
                ? '접수마감'
                : '-'
            }`,
          ),
          activate: getTextCell(`${item.activate_yn == 1 ? '노출' : '미노출'}`),
          detail: getNormalButton(
            '변경',
            `${item.schedule_seq}`,
            onsDetailClick,
          ),

          // confirm_yn: (
          //   <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          //     {
          //       item.confirm_yn? <Switch disabled defaultChecked/>
          //       :<Switch disabled/>
          //     }
          //   </MDTypography>
          // ),
          // close_yn: (
          //   <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">

          //     {
          //       item.close_yn? <Switch disabled defaultChecked/>
          //       :<Switch disabled/>
          //     }
          //   </MDTypography>
          // ),

          // teacher: <TeacherInfo teacher_seq={item.teacher_seq} teacher_name={item.teacher_name} />,
        };
      });
      return rows;
    } else {
      return [];
    }
  }, [list, getClassType]);
*/
  return (
    <LACard layouts={{ pt: { sm: 1 } }}>
      <LAGridContainer>
        <LADataTable2
          columns={columns}
          loadUrl={queries.Schedule.getList}
          params={ searchParams }
          dataToRow={dataToRow}
          defaultPage={defaultPage}
          onChangePage={onChangePage}
        />
      </LAGridContainer>
    </LACard>
  );
}

export default React.memo(ClassScheduleList);
