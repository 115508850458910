import React, { useState, useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import {
  LAModal,
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
} from 'modules/Widgets';
import { periodItems } from 'common/constants';
import { getTodayDateText } from 'common/utils';
import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import useCommonQuery from 'common/hooks/useCommonQuery';

function PointReasonModal({ show, handleClose, historySeq }) {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [pointDetail, setPointDetail] = useState(undefined);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    if (navigation) {
      navigation.listen((locationListener) => {
        if (locationListener.action == 'POP') {
          handleClose();
        }
      });
    }
  }, []);

  const { request: requestPointInfo } = useCommonQuery({
    query: queries.Point.historyInfo,
    params: {
      id: historySeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setPointDetail(data.result_data);
        } else {
          showAlert('알림', data.result_message);
          setPointDetail(undefined);
        }
      },
      onError: (error) => {
        showAlert('알림', '올머니 지급/차감 사유 조회에 실패하였습니다.');
        setPointDetail(undefined);
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    if (historySeq > 0) {
      requestPointInfo();
    }
  }, [historySeq]);

  return (
    <LAModal
      title="올머니 지급/차감 사유"
      width="50%"
      height="45%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LAProperty name="가입자 아이디">
          <LALabel>{pointDetail?.email || ''}</LALabel>
        </LAProperty>
        <LAProperty name="학습자명">
          <LALabel>{pointDetail?.member_name || ''}</LALabel>
        </LAProperty>
        <LAProperty name="항목">
          <LALabel>
            {pointDetail?.history_type_name
              ? pointDetail.history_type_name
              : pointDetail?.history_description
              ? pointDetail.history_description
              : ''}
            {pointDetail?.class_name?.length > 0
              ? ` (${pointDetail?.class_name})`
              : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="관리 메모">
          <LALabel>{pointDetail?.memo || ''}</LALabel>
        </LAProperty>
      </LACard>
    </LAModal>
  );
}

export default React.memo(PointReasonModal);
