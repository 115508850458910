import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useApiContext } from 'common/context/ApiContext';
import { getTodayDateText } from 'common/utils';
import moment from 'moment';
import { toCurrency } from 'common/utils';

import {
    LACard,
    LAGridContainer,
    LAProperty,
    LALabel,
    LAButton,
    LARadioGroup,
    useRadioGroup,
    LAComboBox,
    useComboBox,
    LAInput,
    useInput,
    LADatePicker,
    useDatePicker,
    LATabSelector,
    useTabSelector,
    LADataTable,
    LADataTable2,
    useModal,
    useModalPopup,
    LAMultiCheckbox,
    useMultiCheckbox,
    getTextCell,
    getLinkTextCell,
    getNormalButton,
} from 'modules/Widgets';

import OrderDetailModal from './components/OrderDetailModal'; // 상세 내역
import DeliveryTrackingModal from 'pages/Common/DeliveryTrackingModal'; // 배송 조회
import DeliveryRegistModal from './components/DeliveryRegistModal'; // 운송장 등록

/* TODO ( 2023-04-14  NOT SOLVE ) 
1. [확정] 된 수업List만 나오는 지 CHECK
2. 외부 배송 교재List만 나오는 지 CHECK
*/

const periodItems = [
    { key: 0, value: '1개월' },
    { key: 1, value: '3개월' },
    { key: 2, value: '6개월' },
    { key: 3, value: '1년' },
];

function minusMonth(input) {
    return moment(input).add(-1, 'M').format('YYYY-MM-DD');
}

function AllianceDeliveryManagement({}) {

    const classType = useRadioGroup(0);
    const testModeType = useRadioGroup(0);
    const payment_status = useRadioGroup(0);
    const deliveryStatusItems = [ // 배송상태
        {text: '전체', value: 0 },
        {text: '없음', value: 99999 },
        {text: '택배 배송', value: 1 },
        {text: '추가 택배 배송', value: 2 },
        {text: '교환 배송', value: 3 },
        {text: '반품 배송', value: 4 },
        {text: '기타', value: 99 }
    ];
    const delivery_status = useMultiCheckbox(
      deliveryStatusItems.map((item) => {
          return (item.value)
      }),
      deliveryStatusItems
    );
    const returnStatusItems = [ // 반송 상태
        {text: '전체', value: 0 },
        {text: '없음', value: 99999 },
        {text: '교환 회수', value: 1 },
        {text: '반품 회수', value: 2 },
        {text: '기타', value: 99 }
    ];
    const return_status = useMultiCheckbox(
      returnStatusItems.map((item) => {
          return (item.value)
      }),
      returnStatusItems
    );
    const searchDateType = useComboBox('pay_dt'); // 조회기간 셀렉트
    const searchPeriodType = useTabSelector(0); // 조회기간 탭
    const todayDateText = getTodayDateText('-');
    const start_dt = useDatePicker(minusMonth(todayDateText));
    const end_dt = useDatePicker(todayDateText);
    const searchTargetType = useComboBox('all');
    const searchText = useInput('');

    const { queries } = useApiContext();
    const [selectedOrderSeq, setSelectedOrderSeq] = useState(0);
    const [contextWaybill, setContextWaybill] = useState();
    const [contextOrder, setContextOrder] = useState();

    const orderDetailModal = useModal(false);
    const delivertTracking = useModal(false);

    const [rows, setRows] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [page, setPage] = useState(0); // 마지막 페이지네이션

    const getClassType = useCallback((item) => {
        let result;
        switch (item.class_type) {
            case 1:
                result = '원데이';
                break;
            case 2:
                result = '패키지';
                break;
        }
        if(item.test_mode_yn) {
            result += '[시크릿]';
        }
        return result;
    });

    const columns = useMemo(() => {
        return [
            { Header: 'No', accessor: 'no', width: '3%' },
            { Header: '결제번호', accessor: 'order_no', width: '7%', sort: true },
            { Header: '경량화 오더번호', accessor: 'lep_order_no', width: '8%' }, 
            { Header: '결제일시 / 취소일시', accessor: 'payment_dt', width: '7%', sort: true },
            { Header: '결제자', accessor: 'applicant_name', width: '3%' },
            { Header: '클래스 유형', accessor: 'class_type_name', width: '5%' },
            { Header: '클래스', accessor: 'class_name_book_name', width: '21%'},
            { Header: '교재명', accessor: 'book_name', width: '5%'},
            { Header: '결제 금액', accessor: 'payment_amount', width: '5%' },
            { Header: '결제 상태', accessor: 'payment_status', width: '3%'},
            { Header: '배송 상태', accessor: 'delivery_status', width: '5%'},
            { Header: '운송장 번호', accessor: 'waybill_number', width: '15%' },
            { Header: '반송 상태', accessor: 'return_status', width: '3%'},
            { Header: '반송장 번호', accessor: 'return_waybill_number', width: '15%' },
            { Header: '송장관리', accessor: 'waybill_ctrl', width: '3%' },
        ];
    }, []);

    const rowData = (data, idx) => {
        const date = moment(`${data.schedule_date} ${data.schedule_time}`);
        /*운송장 출력 start*/
        let waybillCell = [];
        let returnWaybillCell = [];

        if (!data.waybills) {
            waybillCell.push(getTextCell('없음'));
            returnWaybillCell.push(getTextCell('없음'));
        } else {
            data.waybills.forEach((item) => {

                if (waybillCell.length > 0) {
                    waybillCell.push(<div className="gutter"/>);
                    returnWaybillCell.push(<div className="gutter"/>); // 5px 떨어뜨려주면 됨.
                }
                // 운송장
                waybillCell.push(getLinkTextCell(`${item.S.delivery_company_type_name}(${item.S.waybill_no})`, null, delivertTrackingClick, item.S));
                // 반송장
                if (item.R) {
                    returnWaybillCell.push(getLinkTextCell(`${item.R.delivery_company_type_name}(${item.R.waybill_no})`, null, delivertTrackingClick, item.R));
                } else {
                    returnWaybillCell.push(getTextCell('없음'));
                }
            });
        }
        /*운송장 출력 end*/
        /*배송상태 출력 */
        let deliveryStatus = [];

        if (!data.waybills) {
            deliveryStatus.push(getTextCell('없음'));
        } else {
            data.waybills?.map((item) => {
                deliveryStatus.push(<div className="gutter"/>)
                deliveryStatus.push(getTextCell(`${item.S?.delivery_option_type_name}`))
            })
        }
        /*배송상태 출력 end */
        /*반송상태 출력 */
        let returnStatus = [];
        if(!data.waybills) {
            returnStatus.push(getTextCell('없음'));
        } else {
            data.waybills?.forEach((item) => {
                if (item.R) {
                    returnStatus.push(<div className="gutter"/>);
                    returnStatus.push(getTextCell(item.R.delivery_option_type_name));
                } else {
                    returnStatus.push(<div className="gutter"/>);
                    returnStatus.push(getTextCell('없음'));
                }
            });
        }
        /*반송상태 출력 end */

        return {
            no: getTextCell(data.order_seq),
            category: getTextCell(data.category_name),
            order_no: getTextCell((data.order_no === data.imp_uid ? `${data.order_no}`:`${data.order_no}\n${data.imp_uid}`), `${data.order_seq}`, onsDetailClick),
            lep_order_no: getTextCell(data.lep_order_no),
            payment_dt: getTextCell(`${moment(data.payment_dt).format('YYYY-MM-DD (dd) HH:mm:ss')}${data.payment_cancel_dt ? `\n${moment(data.payment_cancel_dt).format('YYYY-MM-DD (dd) HH:mm:ss')}` : ''}`),
            applicant_name: getTextCell(data.applicant_name),
            class_type_name: getTextCell(getClassType(data)),
            class_name_book_name: getTextCell(`${data.class_name}\n클래스 시작일 : ${date.format('YYYY-MM-DD (dd) HH:mm')}`),
            /* 2023-04-05 */
            book_name: getTextCell(data.options), // 교재명 data.book_name
            payment_amount: getTextCell(`${toCurrency(data.payment_amount)}원`), // 결제 금액
            payment_status: getTextCell(data.order_status_name), // 결제상태
            delivery_status: deliveryStatus, // 배송 상태
            waybill_number: waybillCell, // 운송장 번호
            return_status: returnStatus, // 반송 상태
            return_waybill_number: returnWaybillCell, // 반송장 번호
            waybill_ctrl: data.textbook_delivery_type == 1 ? <span>SAP 생성</span> :  getNormalButton('등록 / 수정', data, delivertRegistModalPopup.show), // 송장관리
        };
    }

    useEffect(() => {
        start_dt.selectByPeriod(searchPeriodType.state);
        end_dt.reset(searchPeriodType.state);
    }, [searchPeriodType.state, end_dt.reset]);

    useEffect(() => {
        if (page != 0) {
            setTablePage(page);
        }
    }, [rows]);

    const [searchParams, setSearchParams] = useState({});
    const onSearchClick = useCallback(() => {
        let searchParams = {
            textbook_delivery_type: 2,
            class_type: classType.state == 0 ? undefined : classType.state,
            test_mode_yn: testModeType.state == 0 ? undefined : testModeType.state,
            delivery_status: delivery_status.state,
            return_status: return_status.state,
            period_type: searchDateType.state,
            start_dt: start_dt.state,
            end_dt: end_dt.state,
            query_type: searchTargetType.state,
            query: searchText.state,
            payment_status: payment_status.state == 0 ? undefined : payment_status.state
        }
        setSearchParams(searchParams);
    }, [
        classType.state,
        testModeType.state,
        searchDateType.state,
        delivery_status.state,
        return_status.state,
        start_dt.state,
        end_dt.state,
        searchTargetType.state,
        searchText.state,
        payment_status.state
    ]);

    const onEnterKeyAtSearch = useCallback(() => {
        onSearchClick();
    });
    const onsDetailClick = useCallback((no) => {
        setSelectedOrderSeq(no);
        orderDetailModal.visible(true);
    });
    const onDetailClose = useCallback(() => {
        orderDetailModal.handleClose();
    });

    function delivertTrackingClick(data) { // 배송지추적
        setContextWaybill(data);
        delivertTracking.visible(true);
    }
    const delivertTrackingClose = useCallback(() => {
        delivertTracking.handleClose();
    },[delivertTracking]);

    // function delivertRegistClick(data) { // 배송지 등록
    //     setContextOrder(data);
    //     delivertRegist.visible(true);
    // }
    // const delivertRegistClose = useCallback(() => {
    //     delivertRegist.handleClose();
    // });

    const onChangeTablePage = useCallback((page) => {
        setPage(page);
    });
    const onResetClick = useCallback(() => {
        classType.reset();
        testModeType.reset();
        searchDateType.reset();
        delivery_status.reset();
        return_status.reset();
        start_dt.reset();
        end_dt.reset();
        searchTargetType.reset();
        searchText.reset();
        payment_status.reset();
    },[
        classType.reset,
        testModeType.reset,
        searchDateType.reset,
        delivery_status.reset,
        return_status.reset,
        start_dt.reset,
        end_dt.reset,
        searchTargetType.reset,
        searchText.reset,
        payment_status.reset
    ]);

/*    const delivertTrackingModalPopup = { // 배송추적
        state: useModalPopup(false),
        show: (data) => {
            setContextWaybill(data);
            delivertTrackingModalPopup.state.show();
        }
    }*/
    const delivertRegistModalPopup = {
        state: useModalPopup(false, (data) =>{
            onSearchClick();
            delivertRegistModalPopup.state.hide();
        }),
        show: (data) => {
            setContextOrder(data);
            delivertRegistModalPopup.state.show();
        }
    }

    return (
      <div className="deliveryManagement">
          <DashboardNavbar title="배송 관리(제휴)" />
          <LACard className="mainSearchOptionBoard">
              <LAProperty name="클래스 유형">
                  <LAGridContainer vAlign="center">
                      <LARadioGroup
                        props={[
                            {text: '전체', value: 0 },
                            {text: '원데이', value: 1 },
                            {text: '패키지', value: 2 }
                        ]}
                        value={classType.state}
                        onChange={classType.handleChange}
                      />
                  </LAGridContainer>
              </LAProperty>
              <LAProperty name="시크릿 유형">
                  <LAGridContainer vAlign="center">
                      <LARadioGroup
                        props={[
                            {text: '전체', value: 0 },
                            {text: '시크릿 포함', value: 1 },
                            {text: '시크릿 제외', value: 2 }
                        ]}
                        value={testModeType.state}
                        onChange={testModeType.handleChange}
                      />
                  </LAGridContainer>
              </LAProperty>
              <LAProperty name="결제 상태">
                  <LAGridContainer vAlign="center">
                      <LARadioGroup
                        props={[
                            {text: '전체', value: 0 },
                            {text: '결제완료', value: 10 },
                            {text: '결제취소', value: 20 }
                        ]}
                        value={payment_status.state}
                        onChange={payment_status.handleChange}
                      />
                  </LAGridContainer>
              </LAProperty>
              <LAProperty name="배송 상태">
                  <LAGridContainer vAlign="center">
                      <LAMultiCheckbox
                        items={deliveryStatusItems}
                        isSelected={delivery_status.isSelected}
                        onChange={delivery_status.handleChange}
                      />
                  </LAGridContainer>
              </LAProperty>
              <LAProperty name="반송 상태">
                  <LAGridContainer vAlign="center">
                      <LAMultiCheckbox
                        items={returnStatusItems}
                        isSelected={return_status.isSelected}
                        onChange={return_status.handleChange}
                      />
                  </LAGridContainer>
              </LAProperty>
              <LAProperty name="조회 기간">
                  <LAGridContainer vAlign="center">
                      <LAComboBox
                        wPercent={10}
                        items={[
                            { key: 'pay_dt', value: '결제일' },
                            { key: 'class_start_dt', value: '수업 시작일' },
                            { key: 'cancel_dt', value: '취소일' }
                        ]}
                        value={searchDateType.state}
                        onChange={searchDateType.handleChange}
                      />
                      <LATabSelector wPercent={40} layouts={{ ml: { sm: 1 } }} items={periodItems} value={searchPeriodType.state} onChange={searchPeriodType.handleChange} />
                      <LADatePicker wPercent={17} layouts={{ ml: { sm: 1 } }} value={start_dt.state} onChange={start_dt.handleChange} />
                      <LALabel wPercent={1}>~</LALabel>
                      <LADatePicker wPercent={17} value={end_dt.state} onChange={end_dt.handleChange} />
                  </LAGridContainer>
              </LAProperty>
              <LAProperty name="직접 입력">
                  <LAGridContainer>
                      <LAComboBox
                        wPercent={10}
                        items={[
                            { key: 'all', value: '통합검색' },
                            { key: 'order_no', value: '결제번호' },
                            { key: 'pay_name', value: '결제자 명' },
                            { key: 'class_name', value: '클래스 명' },
                            { key: 'waybill_no', value: '운송장 번호' },
                            { key: 're_waybill_no', value: '반송장 번호' },
                            { key: 'book_name', value: '교재명' }
                        ]}
                        value={searchTargetType.state}
                        onChange={searchTargetType.handleChange}
                      />
                      <LAInput wPercent={50} layouts={{ pl: { sm: 1 } }} value={searchText.state} onChange={searchText.handleChange} onEnterKeyPressed={onEnterKeyAtSearch} />
                  </LAGridContainer>
              </LAProperty>
              <LAGridContainer layouts={{ pt: 2 }}>
                  <LALabel wPercent={35}></LALabel>
                  <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onSearchClick}>검색</LAButton>
                  <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} variant="outlined" onClick={onResetClick}>초기화</LAButton>
                  <LALabel wPercent={35}></LALabel>
              </LAGridContainer>
          </LACard>
          {/* End Search Area */}

          {/* 리스트 목록 */}
          <LAGridContainer>
              <LADataTable2
                columns={columns}
                loadUrl={queries.Delivery.getAllianceDeliveryList}
                params={searchParams}
                dataToRow={rowData}
                defaultPage={tablePage}
                onChangePage={onChangeTablePage}
              />
          </LAGridContainer>

          <OrderDetailModal
            orderSeq={selectedOrderSeq}
            show={orderDetailModal.state}
            handleClose={onDetailClose}
          />

          <DeliveryTrackingModal
            context={contextWaybill}
            show={delivertTracking.state}
            handleClose={delivertTrackingClose}
            //handleSelect={delivertTracking}
          />

          <DeliveryRegistModal
            context={contextOrder}
            modalPopupState={ delivertRegistModalPopup.state }
          />
      </div>
    );
}

export default AllianceDeliveryManagement;
