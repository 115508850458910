import React from 'react';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import LAGridItem from './LAGridItem';
import Clear from '@mui/icons-material/Clear';
import MDBox from 'components/MDBox';

function LAReviewImageListItem({
  wPercent = 100,
  showDelete = false,
  index,
  seq,
  imageUrl,
  onClick,
  onRemoveClick,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={{display: "inline-block", marginRight: 1}}>
        {imageUrl && imageUrl.length > 0 ? (
          <img
            src={imageUrl}
            loading="lazy"
            width="500px"
            onClick={() => onClick(index)}
          />
        ) : (
          <MDBox
            sx={{
              border: '1px dashed #cccccc',
              backgroundColor: '#eeeeee',
              width: 120,
              height: 80,
            }}
          ></MDBox>
        )}
        {showDelete && (
          <ImageListItemBar
            sx={{
              background: 'transparent',
            }}
            position="top"
            actionIcon={
              <IconButton
                sx={{
                  background: '#222222',
                  opacity: 0.5,
                  width: 22,
                  height: 22,
                }}
                onClick={() => onRemoveClick(index, seq)}
              >
                <Clear sx={{ color: '#ffffff' }} />
              </IconButton>
            }
            actionPosition="right"
          />
        )}
    </LAGridItem>
  );
}

export default React.memo(LAReviewImageListItem);
