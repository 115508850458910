/** 
 Once you add a new route on this file it will be visible automatically on
the Sidenav.

For adding a new route you can follow the existing routes in the routes array.
1. The `type` key with the `collapse` value is used for a route.
2. The `type` key with the `title` value is used for a title inside the Sidenav. 
3. The `type` key with the `divider` value is used for a divider between Sidenav items.
4. The `name` key is used for the name of the route on the Sidenav.
5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
8. The `route` key is used to store the route location which is used for the react router.
9. The `href` key is used to store the external links location.
10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
10. The `component` key is used to store the component of its route.
*/

// layouts
// import TermsManagement from 'pages/MallManagement/ServiceInfo/TermsManagement';
// import PrivacyManagement from 'pages/MallManagement/ServiceInfo/PrivacyManagement';
// import RefundManagement from 'pages/MallManagement/ServiceInfo/RefundManagement';

import CategoryManagement from 'pages/Category/CategoryManagement';
import ClassSearch from 'pages/Class/ClassManagement/ClassSearch';
import ClassSchedule from 'pages/Class/ClassManagement/ClassSchedule';
import TogetherClass from 'pages/Together/TogetherClass';
import TogetherOrderManagement from 'pages/Together/TogetherOrderManagement';

// import ClassApplicationRegist from 'pages/Class/ClassManagement/ClassApplicationRegist';
// import ClassApplicationList from 'pages/Class/ClassManagement/ClassApplicationList';
// import ClassRegist from 'pages/Class/ClassManagement/ClassRegist';
import ClassCalendar from 'pages/Class/ClassManagement/ClassCalendar';
import OrderManagement from 'pages/Order/OrderManagement';
import OrderRefundManagement from 'pages/Order/ClaimManagement/OrderRefundManagement';
import OrderExchangeManagement from 'pages/Order/ClaimManagement/OrderExchangeManagement';
import DeliveryManagement from 'pages/Order/DeliveryManagement';
import AllianceDeliveryManagement from "./pages/Order/AllianceDeliveryManagement";

import MainContentsManagement from 'pages/ExhibitionManagement/Home/MainContentsManagement';
import TopMainBannerManagement from 'pages/ExhibitionManagement/Home/TopMainBannerManagement';
import MagazineContents from 'pages/ExhibitionManagement/Home/MagazineContents';
import BestClass from 'pages/ExhibitionManagement/Home/BestClass';
import DiscountClass from 'pages/ExhibitionManagement/Home/DiscountClass';
// import BeltBanner from 'pages/ExhibitionManagement/Home/BeltBanner';
import RecommendClass from 'pages/ExhibitionManagement/Home/RecommendClass';
import VedioBanner from 'pages/ExhibitionManagement/Home/VedioBanner';
import KeywordManagement from 'pages/ExhibitionManagement/Home/KeywordManagement';
import MiddleLineBannerManagement from 'pages/ExhibitionManagement/Home/MiddleLineBannerManagement';
import TopLineBannerManagement from 'pages/ExhibitionManagement/Home/TopLineBannerManagement';
import FloatingBannerManagement from 'pages/ExhibitionManagement/Home/FloatingBannerManagement';
import PopupBannerManagement from 'pages/ExhibitionManagement/Home/PopupBannerManagement';

// import TeacherIntroduce from 'pages/ExhibitionManagement/Home/TeacherIntroduce';
import EventSearch from 'pages/Event/EventManagement/EventSearch';
import MagazineSearch from 'pages/Event/MagazineManagement/MagazineSearch';

import CouponSearch from 'pages/Coupon/CouponSearch';

// import AdminNotice from 'pages/Board/Notice/AdminNotice';
// import NoticeRegist from 'pages/Board/Notice/NoticeRegist';

import ClassReviewManagement from 'pages/InquiryManagement/ClassReviewManagement';
import ClassInquiryManagement from 'pages/InquiryManagement/ClassInquiryManagement';
import ClassFeedbackManagement from 'pages/InquiryManagement/ClassFeedbackManagement';
import RecentFeedbackManagement from 'pages/InquiryManagement/RecentFeedbackManagement';
import FooterBoardManagement from 'pages/InquiryManagement/FooterBoardManagement';
import NoticeManagement from 'pages/InquiryManagement/NoticeManagement';

import TeacherSupportManagement from 'pages/AllianceManagement/TeacherSupportManagement';
import AllianceManagement from 'pages/AllianceManagement/AllianceManagement';
import AllianceInquiryManagement from 'pages/AllianceManagement/AllianceInquiryManagement';
import GroupInquiryManagement from 'pages/AllianceManagement/GroupInquiryManagement';

import AdminList from 'pages/AccountManagement/Admin/AdminList';
import TeacherList from 'pages/AccountManagement/Teacher/TeacherList';
import AccountList from 'pages/AccountManagement/User/AccountList';
import WithdrawalMemberList from 'pages/AccountManagement/User/WithdrawalMemberList';
import DormantMemberList from 'pages/AccountManagement/User/DormantMemberList';
import PointManagement from 'pages/AccountManagement/PointManagement/PointManagement';

import SignIn from 'pages/Authorization/SignIn';

import CalculateCommissionManagement from 'pages/Calculate/CalculateCommissionManagement';
import CalculateSettlementManagement from 'pages/Calculate/CalculateSettlementManagement';
import SapCostHandlingManagement from 'pages/Calculate/SapCostHandlingManagement';

// @mui icons
import Icon from '@mui/material/Icon';
import MainMenuManagement from 'pages/ExhibitionManagement/Home/MainMenuManagement';

import TogetherDeliveryManagement from 'pages/Together/TogetherDeliveryManagement';
import TogetherClassInquiryManagement from 'pages/Together/TogetherClassInquiryManagement';
import TogetherClassCancelManagement from 'pages/Together/TogetherClassCancelManagement';

const togetherRoutes = [
  {
    type: 'collapse',
    name: '로그인',
    key: 'sign-in',
    noCollapse: true,
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: '/auth',
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "투게더 클래스 관리",
    key: "togetherClass",
    icon: <Icon fontSize="medium">class</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 2 },
      { memberType: 1, authType: 5 }
    ],
    collapse: [
      {
        name: "투게더 개설 및 등록 관리",
        key: "together-class-management",
        icon: <Icon fontSize="medium">dns</Icon>,
        route: "/togetherClass/management",
        component: <TogetherClass />,
        auth: [{ memberType: 1, authType: 4 }]
      }
    ],
  },
  {
    type: "collapse",
    name: "투게더 주문 관리",
    key: "togetherOrder",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 1, authType: 5 },
      { memberType: 2, teacherType: 2 },
    ],
    collapse: [
      {
        name: "결제 관리",
        key: "together-order-management",
        icon: <Icon fontSize="medium">checklist_rtl</Icon>,
        route: "/togetherOrder/management",
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 1, authType: 5 },
          { memberType: 2, teacherType: 2 },
        ],
        component: <TogetherOrderManagement />,
      },
      {
        name: "배송 관리",
        key: "together-order-delivery",
        icon: <Icon fontSize="medium">local_shipping_outlined</Icon>,
        route: "/togetherOrder/delivery",
        auth: [
          { memberType: 1, authType: 4 },
          { memberType: 1, authType: 5 },
          { memberType: 2, teacherType: 2 },
        ],
        component: <TogetherDeliveryManagement />
      }
    ]
  },
  {
    type: "collapse",
    name: "투게더 문의 관리",
    key: "togetherInquiry",
    icon: <Icon fontSize="medium">live_help</Icon>,
    auth: [
      { memberType: 1, authType: 4 },
      { memberType: 2 },
      { memberType: 1, authType: 5 },
    ],
    collapse: [
      {
        name: "단순 문의",
        key: "together-inquiry",
        icon: <Icon fontSize="medium">rate_review</Icon>,
        route: "/togetherInquiry/inquiry",
        component: <TogetherClassInquiryManagement />
      },
      {
        name: "취소/환불 문의",
        key: "together-inquiry-cancel",
        icon: <Icon fontSize="medium">quiz</Icon>,
        route: "/togetherInquiry/cancel",
        component: <TogetherClassCancelManagement />
      }
    ],
  }
];

export default togetherRoutes;
