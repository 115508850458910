import { LASimpleTable } from 'modules/Widgets';

function OrderRefundHistoryTable({
  refundInfo,
  refundItems,
  refundSimpleTable,
  tableWidth = 1280,
}) {

  return (
    <>
      {/* <LATable
        tableWidth={tableWidth}
        tableData={orderRefundHistoryTableData}
        layouts={{ pt: 3 }}
      /> */}
      <LASimpleTable
        tableData={refundItems}
        isSelectedCell={refundSimpleTable.isSelectedCell}
        onSelectCell={refundSimpleTable.handleClick}
        layouts={{ pt: 3 }}
        // props={{
        //   borderTop: 'none',
        //   borderTopLeftRadius: 0,
        //   borderTopRightRadius: 0,
        // }}
      />
    </>
  );
}

export default OrderRefundHistoryTable;
