import React, { useCallback } from 'react';
import DaumPostcode, { useDaumPostcodePopup } from 'react-daum-postcode';

function useFindAddress(handleAddressFound) {
  const daumPostcodePopup = useDaumPostcodePopup();

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    handleAddressFound({
      address: fullAddress,
      zipCode: data.zonecode,
    });
  };

  const handleAddressPopup = useCallback(() => {
    daumPostcodePopup({ onComplete: handleComplete });
  }, [daumPostcodePopup]);

  return { handleAddressPopup };
}

export default useFindAddress;
