import { useState, useCallback } from 'react';

function useInputs(initialData) {
  const [data, setData] = useState(initialData);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }, []);

  const reset = useCallback(() => setData(initialData), [initialData]);
  return { data, onChange, reset };
}

export default useInputs;
