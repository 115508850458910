import React, { useState, useCallback ,useEffect,useMemo, useRef} from 'react';
import { useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import ClassDetailContents from '../components/ClassDetailContents';


function ClassDetail({}) {

  const navigate = useNavigate();

  const { classSeq } = useParams();

  const navigateBack = () => {
    navigate(-1, { replace: true });
  };

  return (
    <>
      <DashboardNavbar title="수업 상세" />
      <ClassDetailContents classSeq={classSeq} onClickList={navigateBack}/>
    </>
  );
 
}

export default ClassDetail;
