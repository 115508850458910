import { METHOD } from 'common/hooks/useAxios';

const baseMainURL = '/admin/main/video';

const MainVideoApi = (requestFunc) => {
  return {
    MainVideo: {
      getMainVideo: {
        key: 'getMainVideo',
        queryFn: () => requestFunc(METHOD.GET, `${baseMainURL}`),
      },
      updateMainVideo: {
        key: 'updateMainVideo',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseMainURL}/modify`, params),
      },
    },
  };
};

export default MainVideoApi;
