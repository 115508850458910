import React, { useState, useCallback, useEffect, useMemo } from "react";

import DashboardNavbar from "modules/Navbars/DashboardNavbar";

import { useNavigate } from "react-router-dom";

import { getTodayDateText, dateTimeToString } from "common/utils";

import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";

import OrderDetailModal from "../components/OrderDetailModal";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import moment from "moment";
import { periodItems } from "common/constants";

import {
    LACard,
    // LACardTitle,
    LADataTable,
    LAGridContainer,
    // LAGridItem,
    LAProperty,
    LALabel,
    LARadioGroup,
    LACodeRadioGroup,
    LAButton,
    // LAComboBox,
    LAInput,
    LACodeComboBox,
    LADatePicker,
    // LADayOfTheWeek,
    // LAImageListItem,
    // LAImageList,
    // LATextEditor,
    // LAKeywords,
    LATabSelector,
    useRadioGroup,
    useComboBox,
    useDatePicker,
    useTabSelector,
    useInput,
    useModal,
    getTextCell,
    getNormalButton
} from "modules/Widgets";

// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

function OrderRefundManagement() {
    const { showAlert } = usePopAlert();

    const onClassTypeChanged = useCallback((event) => {
        // setClassType(event.target.value);
    }, []);

    const onTabChanged = useCallback((event, newValue) => {
        // setClassType(event.target.value);
    }, []);

    const navigate = useNavigate();

    const classType = useRadioGroup(-1);
    const worksheetType = useRadioGroup(-1);

    const confirmType = useRadioGroup(-1);

    const orderStatusType = useRadioGroup(-1);

    const todayDateText = getTodayDateText("-");

    const payMethodType = useRadioGroup(0);
    const searchDateType = useComboBox(1);
    const searchPeriodType = useTabSelector(3);
    const searchStartDate = useDatePicker(todayDateText);
    const searchEndDate = useDatePicker(todayDateText);
    const searchTargetType = useComboBox(1);
    const searchText = useInput("");

    const { queries } = useApiContext();

    const [selectedOrderSeq, setSelectedOrderSeq] = useState(0);
    const orderRefundDetailModal = useModal(false);

    const [tablePage, setTablePage] = useState(0);
    const [lastVisitTablePage, setLastVisitTablePage] = useState(0);

    const [rows, setRows] = useState([]);

    function onColumnClickHandler(no) {
        navigate(`/order/claim/refund/detail?${no}`);
    }

    const columns = useMemo(() => {
        return [
            { Header: "No", accessor: "no", width: "300px" },
            { Header: "결제번호", accessor: "imp_uid", width: "12%" },
            { Header: "결제일시", accessor: "payment_dt", width: "10%" },
            { Header: "결제자 명", accessor: "applicant_name", width: "5%" },
            { Header: "클래스 유형", accessor: "class_type_name", width: "5%" },
            {
                Header: "클래스 명",
                accessor: "class_name_book_name",
                width: "20%",
                align: "left"
            },
            { Header: "학습자명", accessor: "user_name", width: "8%" },
            {
                Header: "회차 / 수량",
                accessor: "curriculum_count",
                width: "10%"
            },
            { Header: "교재", accessor: "worksheet_yn", width: "5%" },
            { Header: "확정 상태", accessor: "confirmation_yn", width: "10%" },
            { Header: "결제 금액", accessor: "payment_amount", width: "5%" },
            { Header: "배송비", accessor: "delivery_price", width: "5%" },
            { Header: "결제 상태", accessor: "order_status_name", width: "10%" },
            // { Header: '확정 상태', accessor: 'confirmation_yn', width: '10%' },
            { Header: "상세", accessor: "detail", width: "12%" }
        ];
    }, []);

    const dataToRow = (data, index) => {
        const date = moment(`${data.schedule_date} ${data.schedule_time}`);
        return {
            no: getTextCell(data.row_no),
            category: getTextCell(data.category_name),
            imp_uid: getTextCell(
                `${data.order_no}\n${data.imp_uid}`,
                `${data.order_seq}`,
                onsDetailClick
            ),
            payment_dt: getTextCell(
                moment(data.payment_dt).format("YYYY-MM-DD (dd) A hh:mm:ss")
            ),
            applicant_name: getTextCell(data.applicant_name),
            class_type_name: getTextCell(data.class_type_name),
            class_name_book_name: getTextCell(
                `${data.class_name}\n클래스 시작일 : ${date.format(
                    "YYYY-MM-DD (dd) A hh:mm"
                )}`
            ),
            user_name: getTextCell(data.user_name),
            round: getTextCell(`${data.curriculum_no} / ${data.curriculum_count}회`),
            curriculum_count: getTextCell(`${data.curriculum_count}회/1`),
            confirmation_yn: getTextCell(data.confirmation_yn ? "확정" : "미확정"),
            worksheet_yn: getTextCell(data.worksheet_yn),

            payment_amount: getTextCell(data.payment_amount),
            delivery_price: getTextCell(data.delivery_price),
            order_status_name: getTextCell(data.order_status_name),

            attendance: getNormalButton("결과"),
            detail: getNormalButton("상세", `${data.order_seq}`, onsDetailClick)
            // detail: getNormalButton('상세', `${item.schedule_seq}`, onsDetailClick),
        };
    };

    const { result, request: requestCancelOrders } = useCommonQuery({
        query: queries.Order.getCancelOrderList,
        params: {
            // category_seq: category ? category.key : undefined,
            class_type: classType.state < 0 ? undefined : classType.state,

            confirmation_yn: confirmType.state < 0 ? undefined : confirmType.state,

            period_type: searchDateType.state,
            start_dt: searchStartDate.state,
            end_dt: searchEndDate.state,

            query_type: searchTargetType.state,
            query: searchText.state,
            order_status: 20
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === "0000") {
                    setRows(
                        data.result_data
                            ? data.result_data.map((resultData, index) =>
                                dataToRow(resultData, index)
                            )
                            : []
                    );
                } else {
                    showAlert("알림", data.result_message);
                }
            },
            onError: (error) => {
                showAlert("알림", "목록 조회에 실패했습니다.");
            }
        },
        initEnabled: false
    });

    useEffect(() => {
        searchStartDate.selectByPeriod(searchPeriodType.state);
        // searchEndDate.reset();
        searchEndDate.reset(searchPeriodType.state);
    }, [searchPeriodType.state, searchEndDate.reset]);

    useEffect(() => {
        if (searchStartDate.state != searchEndDate.state) requestCancelOrders();
    }, [searchStartDate.state]);

    useEffect(() => {
        if (lastVisitTablePage != 0) {
            setTablePage(lastVisitTablePage);
        }
    }, [rows]);

    function onsDetailClick(no) {
        // showAlert('알림', '준비중입니다.');
        // navigate(`detail/${no}`);
        setSelectedOrderSeq(no);
        orderRefundDetailModal.visible(true);
    }

    const onSearchClick = useCallback(() => {
        setLastVisitTablePage(0);
        requestCancelOrders();
    }, []);

    const onEnterKeyAtSearch = useCallback(() => {
        onSearchClick();
    });

    const onDetailClose = useCallback(() => {
        requestCancelOrders();
        orderRefundDetailModal.handleClose();
    });

    const onChangeTablePage = useCallback((page) => {
        setLastVisitTablePage(page);
    });

    return (
        <>
            <DashboardNavbar title="클레임 관리 / 취소 관리" />

            {/* Start Search Area */}
            <LACard>
                <LAProperty name="클래스 유형">
                    <LACodeRadioGroup
                        codeType="CLASS_TYPE"
                        showAll={true}
                        value={classType.state}
                        onChange={classType.handleChange}
                    />
                </LAProperty>
                <LAProperty name="교재 여부">
                    <LACodeRadioGroup
                        codeType="WORKSHEET_TYPE"
                        showAll={true}
                        value={worksheetType.state}
                        onChange={worksheetType.handleChange}
                        // onChange={onWorkSheetChanged}
                    />
                </LAProperty>

                <LAProperty name="확정 여부">
                    <LACodeRadioGroup
                        codeType="CONFIRM_TYPE"
                        showAll={true}
                        value={confirmType.state}
                        onChange={confirmType.handleChange}
                        // onChange={onExposureChanged}
                    />
                </LAProperty>
                <LAProperty name="결제 상태">
                    <LACodeRadioGroup
                        codeType="ORDER_STATE"
                        showAll={true}
                        value={orderStatusType.state}
                        onChange={orderStatusType.handleChange}
                        // onChange={onExposureChanged}
                    />
                </LAProperty>
                <LAProperty name="결제 수단">
                    <LARadioGroup
                        props={[
                            {
                                text: "전체",
                                value: 0
                            },
                            {
                                text: "신용카드",
                                value: 1
                            },
                            {
                                text: "카카오페이",
                                value: 2
                            }
                        ]}
                        // callback={onClassTypeChanged}
                    />
                </LAProperty>
                <LAProperty name="결제 상태">
                    <LARadioGroup
                        props={[
                            {
                                text: "전체",
                                value: 0
                            },
                            {
                                text: "결제 취소 신청",
                                value: 1
                            },
                            {
                                text: "결제 취소",
                                value: 2
                            }
                        ]}
                        // callback={onClassTypeChanged}
                    />
                </LAProperty>
                <LAProperty name="조회 기간">
                    <LAGridContainer vAlign="center">
                        <LACodeComboBox
                            wPercent={20}
                            codeType="ORDER_QUERY_PERIOD_TYPE"
                            value={searchDateType.state}
                            onChange={searchDateType.handleChange}
                        />
                        <LATabSelector
                            wPercent={40}
                            layouts={{ ml: { sm: 1 } }}
                            items={periodItems}
                            value={searchPeriodType.state}
                            onChange={searchPeriodType.handleChange}
                        />
                        <LADatePicker
                            wPercent={17}
                            layouts={{ ml: { sm: 1 } }}
                            value={searchStartDate.state}
                            onChange={searchStartDate.handleChange}
                        />
                        <LALabel wPercent={1}>~</LALabel>
                        <LADatePicker
                            wPercent={17}
                            value={searchEndDate.state}
                            onChange={searchEndDate.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="직접 입력">
                    <LAGridContainer>
                        <LACodeComboBox
                            wPercent={20}
                            codeType="ORDER_QUERY_TYPE"
                            value={searchTargetType.state}
                            onChange={searchTargetType.handleChange}
                        />
                        <LAInput
                            wPercent={77}
                            layouts={{ pl: { sm: 1 } }}
                            value={searchText.state}
                            onChange={searchText.handleChange}
                            onEnterKeyPressed={onEnterKeyAtSearch}
                        />
                    </LAGridContainer>
                </LAProperty>

                <LAGridContainer layouts={{ pt: 2 }}>
                    <LALabel wPercent={35}></LALabel>
                    <LAButton
                        wPercent={15}
                        layouts={{ pr: { sm: 1 } }}
                        onClick={onSearchClick}
                    >
                        검색
                    </LAButton>
                    <LAButton
                        wPercent={15}
                        layouts={{ pl: { sm: 1 } }}
                        variant={"outlined"}
                    >
                        초기화
                    </LAButton>
                    <LALabel wPercent={35}></LALabel>
                </LAGridContainer>
            </LACard>
            {/* End Search Area */}

            <LACard layouts={{ pt: { sm: 1 } }}>
                <LAGridContainer>
                    <LADataTable
                        columns={columns}
                        rows={rows}
                        defaultPage={tablePage}
                        onChangePage={onChangeTablePage}
                    />
                </LAGridContainer>
            </LACard>

            <OrderDetailModal
                orderSeq={selectedOrderSeq}
                show={orderRefundDetailModal.state}
                handleClose={onDetailClose}
                handleSelect={orderRefundDetailModal}
            />
        </>
    );
}

export default OrderRefundManagement;
