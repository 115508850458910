import React, { useMemo } from 'react';
import DataTable from 'modules/Tables/DataTable';
import DataTable2 from 'modules/Tables/DataPager';
import LAGridItem from './LAGridItem';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import LADivider from './LADivider';
import Checkbox from '@mui/material/Checkbox';

export const getCheckCell = (text, no, onClickHandler, isChecked) => {
  return (
    <Checkbox
      checked={isChecked}
      onClick={() => {
        if(onClickHandler)onClickHandler(no);
      }}
    >
      {text}
    </Checkbox>
  );
};

export const getTextCell = (text, no, onClickHandler) => {
  return (
    <MDTypography
      component={no ? 'a' : 'p'}
      // href="#"
      variant="caption"
      color="text"
      fontWeight={no ? 'medium' : 'regular'}
      onClick={() => {
        if(onClickHandler)onClickHandler(no);
      }}
    >
      {text}
    </MDTypography>
  );
};

/**
 * Data 테이블 셀에 Anchor 표시
 * 
 * @param text 텍스트
 * @param link 이동할 경로
 * @param onClickHandler 클릭시 이벤트 제어
 * @param data onClickHandler로 넘겨줄 파라미터
 */
export const getLinkTextCell = (text, link, onClickHandler, data) => {
  return (
    <MDTypography
      component={'a'}
      href={ onClickHandler? '#': link }
      variant="caption"
      color="text"
      fontWeight={'medium'}
      onClick={() => {
        if(onClickHandler) {
          onClickHandler(data);
        }
      }}
    >
      {text}
    </MDTypography>
  );
};

export const getTwoTextCell = (text1, text2, no, onClickHandler) => {
  return (
    <>
      <MDBox pb={0.5}>
        <MDTypography
          component={no ? 'a' : 'p'}
          // href="#"
          variant="caption"
          color="text"
          fontWeight={no ? 'medium' : 'regular'}
          onClick={() => {
            if(onClickHandler)onClickHandler(no);
          }}
        >
          {text1}
        </MDTypography>
      </MDBox>
      <LADivider />
      <MDBox pt={0.5}>
        <MDTypography
          component={no ? 'a' : 'p'}
          // href="#"
          variant="caption"
          color="text"
          fontWeight={no ? 'medium' : 'regular'}
          onClick={() => {
            if(onClickHandler)onClickHandler(no);
          }}
        >
          {text2}
        </MDTypography>
      </MDBox>
    </>
  );
};

export const getNormalButton = (text, no, onClickHandler) => {
  return (
    <MDButton
      fullWidth
      variant="outlined"
      color="info"
      size="small"
      onClick={() => {
          if(onClickHandler)onClickHandler(no)
      }}
    >
      {text}
    </MDButton>
  );
};

export const getPrimaryButton = (text, no, onClickHandler) => {
  return (
    <MDButton
      fullWidth
      variant="gradient"
      color="info"
      size="small"
      onClick={() => {
        if(onClickHandler)onClickHandler(no);
      }}
    >
      {text}
    </MDButton>
  );
};

function LADataTable({
  wPercent = 100,
  layouts = {},
  props = {},
  columns,
  rows,
  defaultPage = 0, // 기본 페이지 번호 
  currPage, // 현재 페이지 번호 
  loadUrl,
  params,
  dataToRow,
  onChangePage=(()=>{}),
}) {

  const tableData = useMemo(() => {
    columns.forEach( (item) => {
      // 기본값 설정
      if (item.sort && !item.sortValue) {
        item.sortValue = 'none';
      }
    });

    return {columns, rows};
  }, [rows]);
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <DataTable2 {...props} table={tableData} defaultPage={defaultPage} loadUrl={loadUrl} params={params} 
      currPage={currPage} onChangePage={onChangePage} onDataLoadCallback={dataToRow}/>
    </LAGridItem>
  );
}

export default React.memo(LADataTable);
