import { createContext, useState, useCallback, useRef, useEffect } from "react";
import { getUseContext } from "common/utils";
import { LALoading } from "modules/Widgets";

const initialData = {
  state: {
    loading: false,
  },
  action: {
    showLoading: () => {},
    hideLoading: () => {},
  },
};

const SpinnerContext = createContext();

function SpinnerProvider({ children }) {
  const [state, setState] = useState(initialData.state);
  const timerRef = useRef(null);

  const showLoading = useCallback(() => {
    setState(() => ({ loading: true }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setState(() => ({ loading: false }));
    }, 1000 * 59);
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const hideLoading = useCallback(() => {
    setState(() => ({ loading: false }));
  }, []);

  const value = {
    state,
    action: { showLoading, hideLoading },
  };

  return (
    <SpinnerContext.Provider value={value}>
      {children}
      <LALoading isOpen={state.loading} />
    </SpinnerContext.Provider>
  );
}

export const useSpinnerContext = () => getUseContext(SpinnerContext);
export default SpinnerProvider;
