import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/notices';

const NoticeApi = (requestFunc) => {
  return {
    Notice: {
      getNotices: {
        key: 'getNotices',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      registNotice : {
        key: 'registNotice',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
      },
      getNoticeInfo: {
        key: 'getNoticeInfo',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.noticeSeq}`),
      },
      updateNotice : {
        key: 'updateNotice',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.noticeSeq}`, params.data),
      },
      deleteNotice : {
        key: 'deleteNotice',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.noticeSeq}`),
      },
    },
  };
};

export default NoticeApi;
