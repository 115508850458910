import React, { useState, useCallback, useMemo } from 'react';

import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LADatePicker,
  LATabSelector,
  LADataTable,
  getTextCell,
  getTwoTextCell,
} from 'modules/Widgets';

function OrderExchangeManagement() {
  const onClassTypeChanged = useCallback((event) => {
    // setClassType(event.target.value);
  }, []);

  const onTabChanged = useCallback((event, newValue) => {
    // setClassType(event.target.value);
  }, []);

  const columns = useMemo(() => {
    return [
      { Header: 'No', accessor: 'no', width: '10%' },
      { Header: '결제번호', accessor: 'order_id', width: '12%' },
      { Header: '결제일시', accessor: 'order_date', width: '12%' },
      { Header: '결제자 명', accessor: 'order_name', width: '15%' },
      { Header: '클래스 유형', accessor: 'class_type', width: '15%' },
      {
        Header: '클래스 명 / 교재명',
        accessor: 'class_name_book_name',
        width: '12%',
      },
      {
        Header: '회차 / 수량',
        accessor: 'class_no_time_count',
        width: '120px',
      },
      { Header: '교재', accessor: 'book', width: '12%' },
      { Header: '클래스 상태', accessor: 'class_state', width: '12%' },
      { Header: '결제 금액', accessor: 'order_amount', width: '12%' },
      { Header: '배송비', accessor: 'delivery_fee', width: '12%' },
      { Header: '결제 상태', accessor: 'order_state', width: '12%' },
      { Header: '결제 방법', accessor: 'order_method', width: '12%' },
    ];
  }, []);

  const rows = [];

  // useEffect(() => {
  //   searchStartDate.selectByPeriod(searchPeriodType.state);
  //   searchEndDate.reset();
  // }, [searchPeriodType.state, searchStartDate.selectByPeriod, searchEndDate.reset]);

  return (
    <>
      <DashboardNavbar title="클레임 관리 / 교환 관리" />

      {/* Start Search Area */}
      <LACard>
        <LAProperty name="클래스 유형">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '원데이',
                value: 1,
              },
              {
                text: '표준순환',
                value: 2,
              },
              {
                text: '개별맞춤',
                value: 3,
              },
            ]}
            callback={onClassTypeChanged}
          />
        </LAProperty>
        <LAProperty name="교재 여부">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '제공',
                value: 1,
              },
              {
                text: '미제공',
                value: 2,
              },
            ]}
            // callback={onClassTypeChanged}
          />
        </LAProperty>
        <LAProperty name="클래스 상태">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '확정',
                value: 1,
              },
              {
                text: '폐강',
                value: 2,
              },
              {
                text: '미정',
                value: 3,
              },
            ]}
            // callback={onClassTypeChanged}
          />
        </LAProperty>
        <LAProperty name="결제 수단">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '신용카드',
                value: 1,
              },
              {
                text: '카카오페이',
                value: 2,
              },
            ]}
            // callback={onClassTypeChanged}
          />
        </LAProperty>
        <LAProperty name="결제 상태">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '교환 준비 중',
                value: 1,
              },
              {
                text: '교환 배송 중',
                value: 2,
              },
              {
                text: '교환 배송 완료',
                value: 3,
              },
            ]}
            // callback={onClassTypeChanged}
          />
        </LAProperty>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '클래스 시작일' },
                { key: 1, value: '클래스 종료일' },
              ]}
              value={0}
              // onChange={onComboValueChanged}
            />
            <LATabSelector
              wPercent={45}
              items={[
                { key: 0, value: '1개월' },
                { key: 1, value: '3개월' },
                { key: 2, value: '6개월' },
                { key: 3, value: '1년' },
              ]}
              layouts={{ pl: 2 }}
              onChange={onTabChanged}
            />
            <LADatePicker
              wPercent={15}
              defaultValue="2022-06-03"
              layouts={{ ml: 2, mr: 2 }}
            />{' '}
            ~{' '}
            <LADatePicker
              wPercent={15}
              defaultValue="2022-06-03"
              layouts={{ ml: 2 }}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '결제번호' },
                { key: 2, value: '결제자 이름' },
                { key: 3, value: '결제자 연락처' },
                { key: 4, value: '클래스 명' },
                { key: 5, value: '학습자 명' },
              ]}
              value={0}
              // onChange={onComboValueChanged}
            />
            <LAInput wPercent={80} layouts={{ pl: { sm: 1 } }} />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }}>
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={'outlined'}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable columns={columns} rows={rows} />
        </LAGridContainer>
      </LACard>
    </>
  );
}

export default OrderExchangeManagement;
