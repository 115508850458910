import React, { useMemo } from 'react';
import usePopAlert from './hooks/usePopAlert';
import {
  DarkBackground,
  DialogBlock,
  ButtonGroup,
  Contents,
} from 'common/components/styled/StyledModal';

function PopAlert() {
  const { state, onConfirm, onCancel } = usePopAlert();
  const {
    title,
    description,
    confirmText,
    cancelText,
    visible,
    visibleCancel,
  } = state;

  const descList = useMemo(() => {
    return description.split('\n');
  }, [description]);

  if (!visible) return null;

  return (
    <DarkBackground onClick={onCancel}>
      <DialogBlock>
        {title && title.length > 0 && <h3>{title}</h3>}
        <Contents>
          <p>
            {descList &&
              descList.map((desc, index) => {
                return index > 0 ? (
                  <span key={index}>
                    <br />
                    {desc}
                  </span>
                ) : (
                  <span key={index}>{desc}</span>
                );
              })}
          </p>
        </Contents>
        <ButtonGroup>
          {visibleCancel && <button onClick={onCancel}>{cancelText}</button>}
          <button onClick={onConfirm}>{confirmText}</button>
        </ButtonGroup>
      </DialogBlock>
    </DarkBackground>
  );
}

export default React.memo(PopAlert);
