import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/orders';

const OrderApi = (requestFunc) => {
  return {
    Order: {
      getOrderList: {
        key: 'getOrderList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },      
      getOrderDetail: {
        key: 'getOrderDetail',
        queryFn: (order_seq) => requestFunc(METHOD.GET, `${baseURL}/detail/${order_seq}`),
      },      
      cancelOrder: {
        key: 'cancelOrder',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/detail/${params.order_seq}/payment-cancel`, params.data),
      },
      getCancelOrderList: {
        key: 'getCancelOrderList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/cancel`, params),
      },      
      getCancelOrderDetail: {
        key: 'getCancelOrderDetail',
        queryFn: (order_seq) => requestFunc(METHOD.GET, `${baseURL}/cancel/${order_seq}`),
      },


    },
    Delivery: {
      getJasaDeliveryList: {
        key: 'getJasaDeliveryList', // 자사
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/deliveries_jasa`, params)
      },
      getAllianceDeliveryList: {
        key: 'getAllianceDeliveryList', // 제휴
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/deliveries_partner`, params)
      },
      getWaybillList: {
        key: 'getWaybillList', 
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/waybills/${params.order_seq}`, params)
      },
      updateWaybillList: {
        key: 'updateWaybillList', 
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/waybills/${params.order_seq}`, params.data)
      },
      getDeliveryTrackingInfo: {
        key: 'getDeliveryTrackingInfo', 
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/tracking_info/${params.waybill_seq}`)
      },
      getDeliveryTrackingInfoByWaybillNo: {
        key: 'getDeliveryTrackingInfoByWaybillNo', 
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/tracking`, params)
      },
      changeAddress: {  //최초배송 배송지 변경
        key: 'changeAddress',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/detail/${params.order_seq}/change-address`,params.data),
      },
      exchangeAddress: {  //교환,반품 배송지 변경
        key: 'exchangeAddress',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/detail/${params.order_seq}/exchange_textbook/${params.return_textbook_seq}/change-address`,params.data),
      },
      recallOrder: { //회수 요청
        key: 'recallOrder',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/detail/${params.order_seq}/return_textbook`, params.data)
      },
      cancelRecallOrder: { //회수 요청 취소
        key: 'cancelRecallOrder',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/detail/${params.order_seq}/return_textbook/cancel/${params.return_textbook_seq}`, params)
      },
      exchangeTextbook: { //교환 요청
        key: 'exchangeTextbook',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/detail/${params.order_seq}/exchange_textbook`, params.data)
      },
      cancelExchangeTextbook: { //교환/반품 배송 요청 취소
        key: 'cancelExchangeTextbook',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/detail/${params.order_seq}/exchange_textbook/cancel/${params.return_textbook_seq}`)
      },
      getDeliveryAlarmtalksList: {  // 알림톡 이력 리스트 조회
        key: 'getDeliveryAlarmtalksList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/waybills/${params.order_seq}/alarmtalks`)
      },
      alarmtalkExchange:{ // 교환요청 알림 톡 발송
        key: 'alarmtalkExchange',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/waybills/${params.order_seq}/alarmtalk/exchange`)
      },
      alarmtalkReturn:{ // 반품요청 알림 톡 발송
        key: 'alarmtalkReturn',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/waybills/${params.order_seq}/alarmtalk/return`)
      },
      saleSend:{ // sap매출미처리 매출처리 요청
        key: 'saleSend',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/sap/class_send`, params)
      },
    }
  };
};

export default OrderApi;
