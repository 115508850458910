import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/together/admin';

const TogetherApi = (requestFunc) => {
  return {
    Together: {
        registClass: {
          key: 'registClass',
          queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}/classes`, data),
        },
        copyClass: {
          key: 'copyClass',
          queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/classes/${params.class_seq}/copyAll`),
        },
        modifyClass: {
          key: 'modifyClass',
          queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/classes/${params.class_seq}`, params.data),
        },
        getClassList: {
            key: 'getClassList',
            queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/classes`, params),
        },
        getClassDetail: {
          key: 'getClassDetail',
          queryFn: (class_seq) => requestFunc(METHOD.GET, `${baseURL}/classes/${class_seq}`),
        },
        getOrderList: {
          key: 'getOrderList',
          queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/orders`, params),
        },
        getOrderDetail: {
          key: 'getOrderDetail',
          queryFn: (order_seq) => requestFunc(METHOD.GET, `${baseURL}/orders/detail/${order_seq}`),
        },
        cancelOrder: {
          key: 'cancelOrder',
          queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/orders/detail/${params.order_seq}/payment-cancel`, params.data),
        },
        
        getDeliveryList: {
          key: 'getDeliveryList',
          queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/orders/deliveries`, params)
        },
        getInquiresList: {
          key: 'getInquiresList',
          queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/inquires/2`, params)
        },
        getCancelList: {
          key: 'getCancelList',
          queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/inquires/3`, params)
        },
        getInquiryDetail: {
          key: 'getInquiryDetail',
          queryFn: (inquiry_seq) => requestFunc(METHOD.GET, `${baseURL}/inquires/detail/${inquiry_seq}`)
        },
        saleSend:{ // sap매출미처리 매출처리 요청
          key: 'saleSend',
          queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/orders/sap/class_send`, params)
        },
      }
  };
};

export default TogetherApi;
