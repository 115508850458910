import React from 'react';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import pxToRem from 'assets/theme/functions/pxToRem';
import LAListItem from './LAListItem';
import LAGridItem from './LAGridItem';

function LAListView({ wPercent = 100, layouts = {}, headers, items }) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <List
        component="nav"
        aria-label="secondary mailbox folder"
        sx={{
          overflow: 'auto',
          border: '1px solid #d5d5d5',
          borderRadius: '5px',
          // maxHeight: 200,
        }}
        subheader={
          !!headers &&
          headers.length > 0 && (
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{ padding: 0, fontSize:pxToRem(12), }}
            >
              <ListItem
                disableGutters
                sx={{
                  alignItems: 'center',
                  textAlign: 'left',
                  borderBottom: '1px solid #dddddd',
                  height: pxToRem(35),
                }}
              >
                {!!headers &&
                  headers.map((header, index) => {
                    return (
                      <ListItemText
                        key={index}
                        primary={header}
                        primaryTypographyProps={{
                          sx: { 
                            paddingLeft:'8px', 
                            fontSize: pxToRem(12), 
                            fontWeight: '700' 
                          },
                        }}
                      />
                    );
                  })}
              </ListItem>
            </ListSubheader>
          )
        }
      >
        {!!items &&
          items.map((item, index) => {
            return <LAListItem 
                    key={index} 
                    item={item}
                     />;
          })}
      </List>
    </LAGridItem>
  );
}

export default React.memo(LAListView);
