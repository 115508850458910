import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SELECT':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useComboBox(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const select = useCallback(
    (value) => {
      if (state === value) {
        return;
      }

      dispatch({
        type: 'SELECT',
        value,
      });
    },
    [state],
  );

  const handleChange = useCallback(
    (item) => {
      select(item.key);
    },
    [select],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  return {
    state,
    handleChange,
    select,
    reset,
  };
}

export default useComboBox;
