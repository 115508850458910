import React, { useState, useMemo, useCallback } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";

import AdminDetailContents from './AdminDetailContents';

function AdminDetail() {
  const { seq } = useParams();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate('/user/admin/list');
  });

  return (
    <>
      <DashboardNavbar title="관리자 상세" />

      <AdminDetailContents memberSeq={seq} onClickList={handleClose}/>

    </>
  );
}

export default AdminDetail;
