import React from 'react';
import Grid from '@mui/material/Grid';
import MDTypography from 'components/MDTypography';
import Icon from '@mui/material/Icon';

function LAPropertyName({ children, required = false }) {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <MDTypography
          variant="button"
          fontWeight="medium"
          color="dark"
          minWidth="8rem"
        >
          {children}
        </MDTypography>
      </Grid>
      <Grid item ml={1} >
        {!!children && required && (
          <MDTypography mt={1} color="error" size="small">
            *
          </MDTypography>
        )}
      </Grid>
    </Grid>
  );
}

export default React.memo(LAPropertyName);
