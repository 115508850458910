// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Breadcrumbs({ icon, title, route, light }) {

  const routes = route.slice(0, -1);
  return (
    <MDBox pl={2} mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs sx={{"& .MuiBreadcrumbs-separator": {display: "none"}}}>
        <Link to="/">
          <MDTypography component="span" variant="body2" fontWeight="bold" color={light ? "white" : "dark"} sx={{ lineHeight: 0 }}>
            <Icon fontSize="medium">{icon}</Icon>
          </MDTypography>
        </Link>
        <MDTypography ml={2} fontWeight="bold" color={light ? "white" : "dark"} sx={{ lineHeight: 0 }}>
          {title === "together" ? "" : title}
        </MDTypography>
        <MDTypography ml={2} color={light ? "white" : "dark"} sx={{ lineHeight: 0 }}></MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
