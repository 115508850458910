import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  useRadioGroup,
  LARadioGroup,
  LAReviewScrore,
  LAReviewImageListItem,
} from 'modules/Widgets';

import moment from 'moment';

const exposureItems = [
  {
    text: '미노출',
    value: 0,
  },
  {
    text: '노출',
    value: 1,
  },
];
function TeacherDetail({ reviewSeq, onClickList }) {
  const { isLogin, isTeacher, action } = useLoginContext();
  const navigate = useNavigate();

  const { showAlert, showConfirm } = usePopAlert();

  const { queries } = useApiContext();
  //   const { reviewSeq } = useParams();
  const [data, setData] = useState(null);

  const exposureYn = useRadioGroup(0);

  //   const navigateBack = () => {
  //     navigate(-1, { replace: true });
  //   };

  const { result: resultData, request: requestDetail } = useCommonQuery({
    query: queries.Review.getReviewDetail,
    params: reviewSeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setData(data.result_data);
        } else {
          showAlert('알림', data.result_message, {
            confirmHandler: () => onClickList(),
          });
        }
      },
      onError: (error) => {
      },
    },
    initEnabled: false,
  });

  const { request: changeExposure } = useCommonMutation({
    query: queries.Review.changeExposure,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '노출 상태가 변경되었습니다.', {
            confirmHandler: () => {
              requestDetail();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '노출 상태 변경에 실패했습니다.');
      },
    },
  });
  useEffect(() => {
    if (!isLogin) {
      alert('로그인 후 이용할 수 있습니다.');
      navigate('/');
    }
    requestDetail();
  }, []);
  useEffect(() => {
    if (data) {
      exposureYn.update(data.exposure_yn);
    }
  }, [data]);

  const onModifyClick = useCallback(() => {
    showConfirm('확인', '노출 상태를 변경하시겠습니까?', {
      confirmHandler: () => {
        const params = {
          reviewSeq: reviewSeq,
          data: { exposure_yn: exposureYn.state },
        };
        changeExposure(params);
      },
    });
  }, [exposureYn.state]);

  return (
    <>
      {/* Start Info Area */}
      <LACard>
        <LACardTitle title="클래스 정보" />
        <LAProperty name="클래스 유형">
          <LALabel wPercent={40}>{!!data && data.class_type_name}</LALabel>
        </LAProperty>
        <LAProperty name="선생님 명">
          <LALabel wPercent={40}>{!!data && data.teacher_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 명">
          <LALabel wPercent={40}>{!!data && data.class_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 회차">
          <LALabel wPercent={40}>{!!data && data.curriculum_count}회</LALabel>
        </LAProperty>
        <LAProperty name="클래스 일정">
          <LALabel wPercent={40}>
            {!!data && moment(`${data.schedule_date} ${data.schedule_time}`).format('YYYY-MM-DD (dd) A hh:mm')}
          </LALabel>
        </LAProperty>
      </LACard>
      {/* End Info Area */}

      {/* Start Detail Info Area */}
      <LACard layouts={{ pt: 1 }}>
        <LACardTitle title="클래스 후기 정보" />
        {/* <LAProperty name="아이디">
          <LALabel wPercent={40} clickable="true" content={`woongjin`}>woong***</LALabel>
        </LAProperty> */}
        <LAProperty name="가입자">
          <LALabel wPercent={40}>{!!data && data.member_name}</LALabel>
        </LAProperty>
        <LAProperty name="학습자">
          <LALabel wPercent={40}>
            {!!data && data.user_name}/{!!data && data.user_age}세/{!!data && data.gender_type_name}
          </LALabel>
        </LAProperty>
        <LAProperty name="작성일">
          <LALabel wPercent={40}>
            {!!data &&
              moment(data.created_dt).format('YYYY-MM-DD (dd) A hh:mm')}
          </LALabel>
        </LAProperty>
        <LAProperty name="만족도">
          <LAReviewScrore wPercent={40} rating={!!data && data.star} />
        </LAProperty>
        <LAProperty name="클래스 후기">
          <LALabel wPercent={80}>{!!data && data.review}</LALabel>
        </LAProperty>
        <LAProperty>
          {!!data && data.photos.map((item) =>
              <LAReviewImageListItem key={item.image_seq} wPercent={80} imageUrl={item.file_url}/>)}
        </LAProperty>
      </LACard>
      {/* End Detail Info Area */}

      {/* Start Detail Info Area */}
      <LACard layouts={{ pt: 1 }}>
        <LACardTitle title="노출 여부" />

        <LAProperty name="노출 여부">
          {!isTeacher ? (
            <LARadioGroup
              props={exposureItems}
              value={exposureYn.state}
              onChange={exposureYn.handleChange}
            />
          ) : exposureYn.state ? (
            '노출'
          ) : (
            '비노출'
          )}
        </LAProperty>
      </LACard>
      {/* End Detail Info Area */}

      <LAGridContainer layouts={{ pt: { sm: 1 } }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ pr: { sm: 1 } }}
          variant={'outlined'}
          onClick={onClickList}
        >
          목록
        </LAButton>
        <LAButton
          wPercent={15}
          layouts={{ pl: { sm: 1 } }}
          onClick={onModifyClick}
        >
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>
    </>
  );
}

export default TeacherDetail;
