import React, { useCallback, useState, useRef, useMemo } from 'react';
import CommentList from './CommentList';
import Comment from './Comment';
// import ListEmpty from 'pages/listEmpty/ListEmpty';
import useComments from '../hooks/useComments';
import 'assets/css/classFeedback.css';

// CommentBox.js

function CommentBox({
  isEditable,
  classroomSeq,
  classmateSeq,
  userName,
}) {
  const { comments, handleRegist, handleReply, handleUpdate, handleDelete } =
    useComments({ classroomSeq, classmateSeq, userName });

  if (!userName) {
    return null;
  }

  return (
    <div className="commentBox-wrap">
      {isEditable && (
        <div className="classFeedback-inner">
          <div className="commentBox-top">
              <div className="comment-top">
                <div className="avatar"></div>
                <h1 className="name">
                  {userName}
                </h1>
              </div>
            <Comment userName={userName} handleRegist={handleRegist} isNew />
          </div>
        </div>
      )}

      {comments && comments.length > 0 && (
        <ul className="commentBox-list">
          {comments.map((comment, index) => (
            <CommentList
              key={index}
              userName={userName}
              comment={comment}
              // clickHandler={clickHandler}
              handleRegist={handleRegist}
              handleReply={handleReply}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

export default React.memo(CommentBox);
