import { useCallback, useEffect, useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSpinnerContext } from 'common/components/spinner/context/SpinnerContext';

// GET
export function useCommonQuery({
  query,
  params = undefined,
  callbacks = {},
  initEnabled = true,
}) {
  const [enabled, setEnabled] = useState(initEnabled);
  const { action } = useSpinnerContext();
  const { showLoading, hideLoading } = action;
  const result = useQuery([query.key, params], () => query.queryFn(params), {
    enabled,
    ...callbacks,
  });

  const status = useMemo(() => {
    return {
      isLoading: result.isLoading,
      isFetching: result.isFetching,
      isRefetching: result.isRefetching,
      isSuccess: result.isSuccess,
      isError: result.isError,
      isLoadingError: result.isLoadingError,
    };
  }, [
    result.isLoading,
    result.isFetching,
    result.isRefetching,
    result.isSuccess,
    result.isError,
    result.isLoadingError,
  ]);

  const { isLoading, isFetching, isRefetching } = result;

  useEffect(() => {

    if (isLoading || isFetching || isRefetching) showLoading();
    else hideLoading();
  }, [isLoading, showLoading, hideLoading]);

  const request = result.refetch;
  return { result, status, request };
}

export default useCommonQuery;
