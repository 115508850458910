import React from 'react'
import LASimpleTableCell from './LASimpleTableCell';
import LAGridContainer from './LAGridContainer';

function LASimpleTableRow({row, rowIndex, layouts, wPercentList, onSelectCell, isSelectedCell}) {
  return (
    <LAGridContainer key={rowIndex} vAlign="center" layouts={layouts}>
      {row && row.map((cell, colIndex) =>
          <LASimpleTableCell
          key={colIndex}
          cell={cell}
          rowIndex={rowIndex}
          colIndex={colIndex} 
          wPercentList={wPercentList}
          onSelectCell={onSelectCell}
          isSelectedCell={isSelectedCell} />
        )}
    </LAGridContainer>
  )
}

export default React.memo(LASimpleTableRow);