import React, {
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useState,
} from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import {
  LAModal,
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAComboBox,
  LACodeComboBox,
  LACodeRadioGroup,
  LAInput,
  LADatePicker,
  LATabSelector,
  LARadioGroup,
  LADataTable,
  LADivider,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useDatePicker,
  useTabSelector,
  getTextCell,
  getCheckCell,
} from 'modules/Widgets';
import { periodItems } from 'common/constants';
import { getTodayDateText, toCurrency } from 'common/utils';
import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import useCommonQuery from 'common/hooks/useCommonQuery';
import moment from 'moment';

const columns = [
  { Header: '선택', accessor: 'select', width: '6%' },
  { Header: 'No', accessor: 'no', width: '7%' },
  { Header: '가입자 아이디', accessor: 'id', width: '15%' },
  { Header: '이름', accessor: 'name', width: '10%' },
  { Header: '누적 구매금액', accessor: 'total_amount', width: '8%' },
  { Header: '누적 환불금액', accessor: 'refund_amount', width: '8%' },
  { Header: '포인트', accessor: 'point', width: '8%' },
  { Header: '최근 방문일', accessor: 'recent_date', width: '12%' },
  { Header: '가입일', accessor: 'join_date', width: '12%' },
  { Header: '상태', accessor: 'status', width: '7%' },
  { Header: '마케팅', accessor: 'makting', width: '7%' },
];

function PointMemberModal({
    show,
    handleClose,
    handleMemberChange,
    isSelectedMember,
}) {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const todayDateText = getTodayDateText('-');
  const [currPage, setCurrPage] = useState(0);
  const marketingType = useRadioGroup(0);
  const searchDateType = useComboBox(1);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const searchTargetType = useComboBox(0);
  const searchText = useInput('');
  const [members, setMembers] = useState([]);

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        if (navigation) {
            navigation.listen((locationListener) => {
                if (locationListener.action == 'POP') {
                handleClose();
                }
            });
        }
    }, []);

  const rows = useMemo(() => {
    return !!members && members?.map((item, index) => ({
      select: getCheckCell(
        '',
        item,
        handleMemberChange,
        isSelectedMember(item),
      ),
      no: getTextCell(item.member_seq),
      id: getTextCell(item.email),
      name: getTextCell(item.member_name),
      total_amount: getTextCell(
        item.order_price !== undefined && item.order_price !== null
          ? toCurrency(item.order_price)
          : '',
      ),
      refund_amount: getTextCell(
        item.refund_price !== undefined && item.refund_price !== null
          ? toCurrency(item.refund_price)
          : '',
      ),
      point: getTextCell(
        item.point !== undefined && item.point !== null
          ? toCurrency(item.point)
          : '',
      ),
      recent_date: getTextCell(
        item.signin_dt ? moment(item.signin_dt).format('YYYY-MM-DD') : '',
      ),
      join_date: getTextCell(
        item.created_dt ? moment(item.created_dt).format('YYYY-MM-DD') : '',
      ),
      status: getTextCell(item.state ? '정상' : '비정상'),
      makting: getTextCell(item.marketing_agree_yn ? '동의' : '미동의'),
    }));
  }, [members, handleMemberChange, isSelectedMember]);

  const { request: requestMembers } = useCommonQuery({
    query: queries.Member.getList,
    params: {
      member_state_type: -1,
      marketing_yn: marketingType.state,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state.replaceAll('-', ''),
      end_dt: searchEndDate.state.replaceAll('-', ''),
      query_type: searchTargetType.state,
      query: searchText.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data && data.result_code === '0000') {
          setMembers(data.result_data.data);
          setCurrPage(0);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '회원 목록 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    console.log(searchStartDate.state, searchEndDate.state);
    if (searchStartDate.state != searchEndDate.state) {
      requestMembers();
    }
  }, [searchStartDate.state, searchEndDate.state]);

  const onSearchClick = useCallback(() => {
    requestMembers();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  }, []);

  const onInitClick = useCallback(() => {
    marketingType.reset();
    searchDateType.reset();
    searchPeriodType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    marketingType.reset,
    searchDateType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  const onChangePage = useCallback((page) => {
    setCurrPage(page);
  }, []);

  return (
    <LAModal
      title="회원 검색"
      width="70%"
      height="90%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LAProperty name="마케팅 동의" required>
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '동의',
                value: 1,
              },
              {
                text: '미동의',
                value: 2,
              },
            ]}
            value={marketingType.state}
            onChange={marketingType.handleChange}
          />
        </LAProperty>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LACodeComboBox
              wPercent={15}
              codeType="MEMBER_QUERY_PERIOD_TYPE"
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
            />
            <LATabSelector
              wPercent={45}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={19}
              layouts={{ pl: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={2}>~</LALabel>
            <LADatePicker
              wPercent={19}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '이름' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              placeholder="검색어 입력"
              wPercent={85}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>

          <LAGridContainer layouts={{ pt: { sm: 1 } }}>
            <LALabel wPercent={35}></LALabel>
            <LAButton
              wPercent={15}
              layouts={{ pr: { sm: 1 } }}
              onClick={onSearchClick}
            >
              검색
            </LAButton>
            <LAButton
              wPercent={15}
              layouts={{ pl: { sm: 1 } }}
              variant={'outlined'}
              onClick={onInitClick}
            >
              초기화
            </LAButton>
            <LALabel wPercent={35}></LALabel>
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LACard next>
        <LADataTable
          columns={columns}
          rows={rows}
          currPage={currPage}
          onChangePage={onChangePage}
        />
      </LACard>
    </LAModal>
  );
}

export default React.memo(PointMemberModal);
