import React, { useState, useCallback, useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import PointManagementContents from './PointManagementContents';
import { LAModal, LACard, LACardTitle } from 'modules/Widgets';

function PointManagementModal({ show, handleClose, handleApply }) {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    if (navigation) {
      navigation.listen((locationListener) => {
        if (locationListener.action == 'POP') {
          handleClose();
        }
      });
    }
  }, []);

  return (
    <LAModal
      title="올머니 지급/차감"
      width="90%"
      height="95%"
      show={show}
      onClose={handleClose}
    >
      <PointManagementContents handleApply={handleApply} />
    </LAModal>
  );
}

export default React.memo(PointManagementModal);
