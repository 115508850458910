import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { getTodayDateText, dateTimeToString } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';

// import CategoryComboView from './CategoryComboView';
import CategoryComboView from 'pages/Class/components/CategoryComboView';

import ClassFeedbackDetailModal from './components/ClassFeedbackDetailModal';
import { periodItems } from 'common/constants';
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  useRadioGroup,
  LAButton,
  LAComboBox,
  useComboBox,
  LAInput,
  useInput,
  LADatePicker,
  useDatePicker,
  LATabSelector,
  LADataTable2,
  getTextCell,
  getCheckCell,
  getNormalButton,
  LACodeRadioGroup,
  LACodeComboBox,
  useTabSelector,
  LARadioGroup,
  useModal, LAAltRadioGroup
} from "modules/Widgets";

// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

const attachmentItems = [
  { key: 0, value: '전체' },
  { key: true, value: '첨부' },
  { key: false, value: '미첨부' },
];

function addMonth(input) {
  return moment(input).add(1, 'M').format('YYYY-MM-DD');
}

function ClassFeedbackManagement() {
  const navigate = useNavigate();
  const todayDateText = getTodayDateText('-');
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();

  // const [schedules, setSchedules] = useState([]);
  // const [rows, setRows] = useState([]);

  const [category, setCategory] = useState(0);
  const classType = useRadioGroup(-1);

  const attachmentYn = useRadioGroup(-1);
  const answerYn = useRadioGroup(-1);

  const searchDateType = useComboBox(1);

  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(addMonth(todayDateText)); // 한달 추가

  const searchTargetType = useComboBox(1);
  const searchText = useInput('');
  const testModeType = useRadioGroup('')

  const [selectedClassroomSeq, setSelectedClassroomSeq] = useState(0);
  const classFeedbackDetailModal = useModal(false);

  const [tablePage, setTablePage] = useState(0);
  const [lastVisitTablePage, setLastVisitTablePage] = useState(0);

  const [ searchParams, setSearchParams ] = useState({});

  const getTime = useCallback(
    (schedule) => {
      const timetable = schedule.timetables.find(
        (t) => t.curriculum_times === 1,
      );
      return moment(
        `${timetable.schedule_date} ${timetable.schedule_time}`,
      ).format('A hh:mm');
    },
    [],
  );

  const getClassType = useCallback((item) => {
    let result;
    switch(item.progress_type){
      case 0:
        result = '원데이'
        break;
      case 1:
        result = '순환'
        break;
      case 2:
        result = '패키지'
        break;
    }

    if(item.test_mode_yn){
      result += '[시크릿]'
    }
    return result
  })

  const columns = useMemo(() => {
    return [
      { Header: '#', accessor: 'row_no', width: '5%', align: 'center' },
      { Header: '카테고리', accessor: 'category', align: 'left' },
      { Header: '유형', accessor: 'class_type', align: 'center' },
      { Header: '주체', accessor: 'brand_type', align: 'center' },
      { Header: '클래스명', accessor: 'class_name', align: 'left' },
      { Header: '회차', accessor: 'timetables_count', align: 'center' },
      { Header: '선생님', accessor: 'teacher_name', align: 'center' },
      { Header: '정원', accessor: 'children_count', align: 'center' },
      { Header: '가입자', accessor: 'application_count', align: 'center' },
      { Header: '수업시작일', accessor: 'start_dt', align: 'center' },
      { Header: '수업시간', accessor: 'time', align: 'center' },
      { Header: '수업종료일', accessor: 'end_dt', align: 'center' },
      { Header: '상태', accessor: 'confirmation_yn', align: 'center' },
      { Header: '상세', accessor: 'detail', width: '12%' },
    ];
  }, []);

  const dataToRow = (item, index) => {
    return {
      row_no: getTextCell(`${item.row_no}`),
      category: getTextCell(`${item.category_name}`),
      class_name: getTextCell(
          `${item.class_name}`,
          `${item.classroom_seq}`,
          onDetailClick,
      ),
      timetables_count: getTextCell(`총 ${item.curriculum_times}회`),
      class_type: getTextCell(getClassType(item)),
      brand_type: getTextCell(
          `${item.brand_type_name ? item.brand_type_name : ''}`,
      ),

      teacher_name: getTextCell(`${item.teacher_name}`),
      children_count: getTextCell(
          `${item.min_user_count}-${item.max_user_count}`,
      ),
      application_count: getTextCell(`${item.applications.length}`),
      start_dt: getTextCell(
          `${moment(item.start_dt).format('YYYY-MM-DD (dd)')}`,
      ),
      time: getTextCell(getTime(item)),
      end_dt: getTextCell(
          `${moment(item.end_dt).format('YYYY-MM-DD (dd)')}`,
      ),
      confirmation_yn: getTextCell(
          `${
              item.close_yn ? '폐강' : item.confirmation_yn ? '확정' : '미확정'
          }`,
      ),
      reception_status: getTextCell(
          `${
              item.reception_status == 1
                  ? '접수중'
                  : item.reception_status == 2
                      ? '접수마감'
                      : '-'
          }`,
      ),

      detail: getNormalButton(
          '상세',
          `${item.classroom_seq}`,
          onDetailClick,
      ),
    }
  };


  const onCategoryChanged = useCallback((item) => {
    setCategory(item);
  }, []);

  const onSearchClick = useCallback(() => {
    let searchParams = {
      category_seq: category ? category.key : undefined,
      class_type: classType.state < 0 ? undefined : classType.state,
      test_mode_yn: testModeType.state < 0 ? undefined : testModeType.state,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      confirm_yn: undefined,
      query_type: searchTargetType.state,
      query: searchText.state,
    }
    setSearchParams(searchParams)
  }, [
    classType.state,
    testModeType.state,
    testModeType.state,
    searchDateType.state,
    searchStartDate.state,
    searchEndDate.state,
    searchTargetType.state,
    searchText.state
  ]);

  function onDetailClick(no) {
    setSelectedClassroomSeq(no);
    classFeedbackDetailModal.visible(true);
  }

  const onDetailClose = useCallback(() => {
    // requestFeedbacks();
    classFeedbackDetailModal.handleClose();
  });

  const onChangeTablePage = useCallback((page) => {
    setLastVisitTablePage(page);
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
    // onSearchClick();
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) onSearchClick();
  }, [searchStartDate.state]);

  useEffect(() => {
    if (lastVisitTablePage != 0) {
      setTablePage(lastVisitTablePage);
    }
  }, [dataToRow]);

  const onInitClick = useCallback(() => {
    classType.reset();
    attachmentYn.reset();
    answerYn.reset();
    searchDateType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
    testModeType.reset();
  }, [
    classType.reset,
    attachmentYn.reset,
    answerYn.reset,
    searchDateType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
    testModeType.reset,
  ]);

  return (
    <>
      <DashboardNavbar title="클래스 피드백 관리" />

      {/* Start Search Area */}
      <LACard>
        <LAProperty name="카테고리">
          <LAGridContainer>
            <CategoryComboView value={category} onChange={onCategoryChanged} />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LACodeRadioGroup
            codeType="CLASS_TYPE"
            showAll={true}
            value={classType.state}
            onChange={classType.handleChange}
          />
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LAAltRadioGroup
            showAll={true}
            positiveLabel={'시크릿 포함'}
            positiveValue={1}
            negativeLabel={'시크릿 제외'}
            negativeValue={0}
            value={testModeType.state}
            onChange={testModeType.handleChange}
          />
        </LAProperty>
        {/* <LAProperty name="첨부 파일">
          <LARadioGroup
            props={attachmentItems}
            value={attachmentYn.state}
            onChange={attachmentYn.handleChange}
            // callback={onAuthTypeChanged}
          />
        </LAProperty> */}
        {/* <LAProperty name="답변 여부">
        <LACodeRadioGroup
            codeType="ANSWER_BOOLEAN_TYPE"
            showAll={true}
            value={answerYn.state}
            onChange={answerYn.handleChange}
          />
        </LAProperty> */}
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LACodeComboBox
              wPercent={12}
              codeType="SCHEDULE_QUERY_PERIOD_TYPE"
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
              // code={queryPeriodType}
              // onChange={onQueryPeriodTypeChanged}
            />
            <LATabSelector
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={20}
              layouts={{ pl: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={2}>~</LALabel>
            <LADatePicker
              wPercent={20}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={[
                { key: 1, value: '통합검색' },
                { key: 2, value: '클래스명' },
                { key: 3, value: '선생님 이름' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={50}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onSearchClick}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 1 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable2
            columns={columns}
            loadUrl={ queries.Classroom.getList }
            params={ searchParams }
            dataToRow={ dataToRow }
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
          />
        </LAGridContainer>
      </LACard>

      <ClassFeedbackDetailModal
        classroomSeq={selectedClassroomSeq}
        show={classFeedbackDetailModal.state}
        handleClose={onDetailClose}
        handleSelect={classFeedbackDetailModal}
      />
    </>
  );
}

export default ClassFeedbackManagement;
