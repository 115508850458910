import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useApiContext } from 'common/context/ApiContext';
import { getTodayDateText } from 'common/utils';
import moment from 'moment';
import { toCurrency } from 'common/utils';

import {
    LACard,
    LAGridContainer,
    LAProperty,
    LALabel,
    LAButton,
    LARadioGroup,
    LAAltRadioGroup,
    useRadioGroup,
    LAComboBox,
    useComboBox,
    LAInput,
    useInput,
    LADatePicker,
    useDatePicker,
    LATabSelector,
    useTabSelector,
    LADataTable2,
    useModal,
    useModalPopup,
    LAMultiCheckbox,
    useMultiCheckbox,
    getTextCell,
    getLinkTextCell,
    getNormalButton
} from 'modules/Widgets';


import DeliveryTrackingModal from 'pages/Common/DeliveryTrackingModal';
import OrderDetailModal from 'pages/Order/components/OrderDetailModal';
import { LACodeComboBox } from 'modules/Widgets';
import TogetherOrderDetailModal from './components/TogetherOrderDetailModal';

const periodItems = [
    { key: 0, value: '1개월' },
    { key: 1, value: '3개월' },
    { key: 2, value: '6개월' },
    { key: 3, value: '1년' },
];

function minusMonth(input) {
    return moment(input).add(-1, 'M').format('YYYY-MM-DD');
}

function TogetherDeliveryManagement() {
    const testModeType = useRadioGroup(0);
    const payment_status = useRadioGroup(0);
    const deliveryTypeS = useRadioGroup(0);
    const deliveryStatusItems = [ // 배송현황
        {text: '전체', value: 0 },
        {text: '배송접수', value: 1 },
        {text: '배송준비중', value: 2 },
        {text: '배송중', value: 3 },
        {text: '배송완료', value: 4 }
    ];
    const delivery_status = useMultiCheckbox(
        deliveryStatusItems.map((item) => {
            return (item.value)
        }),
        deliveryStatusItems
    );
    const recallStatusItems = [ // 교환현황
        {text: '전체', value: 0 },
        {text: '교환접수', value: 1 },
        {text: '교환배송준비중', value: 2 },
        {text: '교환배송중', value: 3 },
        {text: '교환배송완료', value: 4 }
    ];
    const recall_status = useMultiCheckbox(
        recallStatusItems.map((item) => {
            return (item.value)
        }),
        recallStatusItems
    );
    const returnStatusItems = [ // 반송현황
        {text: '전체', value: 0 },
        {text: '반품접수', value: 1 },
        {text: '반품회수준비중', value: 2 },
        {text: '반품회수중', value: 3 },
        {text: `반품회수완료`, value: 4 },
        {text: '반품완료', value: 5 },
        {text: '반품불가', value: 6 }
    ];
    const return_status = useMultiCheckbox(
        returnStatusItems.map((item) => {
            return (item.value)
        }),
        returnStatusItems
    );
    const searchDateType = useComboBox('pay_dt'); // 조회기간 셀렉트
    const searchPeriodType = useTabSelector(0); // 조회기간 탭
    const todayDateText = getTodayDateText('-');
    const start_dt = useDatePicker(minusMonth(todayDateText));
    const end_dt = useDatePicker(todayDateText);
    const searchTargetType = useComboBox(1); // 직접입력 셀렉트
    const searchText = useInput('');

    const { queries } = useApiContext();

    const [selectedOrderSeq, setSelectedOrderSeq] = useState(0);
    const [contextWaybill, setContextWaybill] = useState('');
    const [orderDetailContentsShow, setOrderDetailContentsShow] = useState(false);

    const orderDetailModal = useModal(false);
    const delivertTracking = useModal(false);

    const [rows, setRows] = useState([]);
    
    const [tablePage, setTablePage] = useState(0);
    const [page, setPage] = useState(0); // 마지막 페이지네이션

    const classType = useRadioGroup(9);
    const togetherClassCode = useComboBox(-1);

    const getClassType = useCallback((item) => {
        let result = item.class_type_name;
 
        if(item.test_mode_yn) {
            result += '[시크릿]';
        }
        return result;
    });

    const columns = useMemo(() => {
        return [
            { Header: 'No', accessor: 'no', width: '4%' },
            { Header: '결제번호', accessor: 'order_no', width: '8%', sort: true },
            { Header: '경량화 오더번호', accessor: 'lep_order_no', width: '8%' }, 
            { Header: '결제일시 / 취소일시', accessor: 'payment_dt', width: '8%', sort: true },
            { Header: '결제자', accessor: 'applicant_name', width: '4%' },
            { Header: '클래스 유형', accessor: 'class_type_name', width: '6%' }, 
            { Header: '클래스 명', accessor: 'class_name_book_name', width: '14%'}, 
            { Header: '교재명', accessor: 'book_name', width: '12%'}, 
            { Header: '결제 금액', accessor: 'payment_amount', width: '4%'}, 
            { Header: '결제 상태', accessor: 'payment_status', width: '4%'},
            { Header: '배송/교환/반품 현황', accessor: 'delivery_status', width: '6%' },
            { Header: '운송장 번호', accessor: 'waybill_number', width: '12%' },
            { Header: '교환/반품 신청', accessor: 'return_recall', width: '8%' },
        ];
    }, []);

    const dataToRow = (data, index) => {
        const date = moment(`${data.schedule_date} ${data.schedule_time}`);

        return {
            no: getTextCell(data.order_seq),
            category: getTextCell(data.category_name),
            order_no: getTextCell((data.order_no === data.imp_uid ? `${data.order_no}`:`${data.order_no}\n${data.imp_uid}`), `${data.order_seq}`, onsDetailClick),
            lep_order_no: getTextCell(data.lep_order_no),
            payment_dt: getTextCell(`${moment(data.payment_dt).format('YYYY-MM-DD (dd) HH:mm:ss')} ${data.payment_cancel_dt ? `\n / ${moment(data.payment_cancel_dt).format('YYYY-MM-DD (dd) HH:mm:ss')}` : ''}`),
            applicant_name: getTextCell(data.applicant_name),
            class_type_name: getTextCell(getClassType(data)),
            class_name_book_name: getTextCell(`${data.class_name} \n 수업 시작일 : ${data.togetherFirstStudySchedule}`),
            book_name: getTextCell((data.options ? data.options : "-")), // 교재명
            payment_amount: getTextCell(`${toCurrency(data.payment_amount)}원`), // 결제 금액
            payment_status: data.order_status_name === '취소대기' ? getTextCell('취소대기', `${data.order_seq}`, onDetailCancelClick) : getTextCell(data.order_status_name), // 결제상태
            delivery_status: getTextCell(data.merge_delivery_status_name), // 배송 상태 data.delivery_status
            waybill_number: getLinkTextCell((data.merge_delivery_company_type_name ?`${data.merge_delivery_company_type_name}(${data.merge_waybill_no})`: '-'),null,delivertTrackingClick, data), // 운송장 번호
            return_recall: getNormalButton('상세', `${data.order_seq}`, onsDetailClick), // 상세
        };
    };


    useEffect(() => {
        start_dt.selectByPeriod(searchPeriodType.state);
        end_dt.reset(searchPeriodType.state);
    }, [searchPeriodType.state, end_dt.reset]);

    useEffect(() => {
        if (page != 0) {
            setTablePage(page);
        }
    }, [rows]);

    const [ searchParams, setSearchParams ] = useState({});

    const onSearchClick = useCallback(() => {
        let searchParams = {
            textbook_delivery_type: 1,
            class_type: 9,
            together_course_code: togetherClassCode.state <= 0 ? undefined : togetherClassCode.state,
            test_mode_yn: testModeType.state == 0 ? undefined : testModeType.state,
            delivery_type_S: deliveryTypeS.state  == 0 ? undefined : deliveryTypeS.state,
            delivery_status: delivery_status.state,
            recall_status: recall_status.state,
            return_status: return_status.state,
            payment_status: payment_status.state == 0 ?  undefined : payment_status.state,
            period_type: searchDateType.state,
            start_dt: start_dt.state,
            end_dt: end_dt.state,
            query_type: searchTargetType.state,
            query: searchText.state
        }
        setSearchParams(searchParams);
    }, [
        classType.state, 
        togetherClassCode.state,
        testModeType.state,
        deliveryTypeS.state, 
        delivery_status.state,
        recall_status.state,
        return_status.state,
        payment_status.state,
        searchDateType.state, 
        start_dt.state, 
        end_dt.state, 
        searchTargetType.state, 
        searchText.state
    ]);

    const onEnterKeyAtSearch = useCallback(() => {
        onSearchClick();
    });

    const onsDetailClick = useCallback((no) => {
        setSelectedOrderSeq(no);
        orderDetailModal.visible(true);
    },[orderDetailModal]);

    const onDetailCancelClick = useCallback((data) => {
        setSelectedOrderSeq(data);
        orderDetailModal.visible(true);
        setOrderDetailContentsShow(true);
    }, [orderDetailModal, setOrderDetailContentsShow]);

    const onDetailClose = useCallback(() => {
        orderDetailModal.handleClose();
        setOrderDetailContentsShow(false);
    },[orderDetailModal]);

    const delivertTrackingClick = useCallback((data) => {
        setContextWaybill(data);
        delivertTracking.visible(true);
    }, [setContextWaybill, delivertTracking]);


    const delivertTrackingClose = useCallback(() => {
        delivertTracking.handleClose();
    },[delivertTracking]);


    const onChangeTablePage = useCallback((page) => {
        setPage(page);
    });

    const onResetClick = useCallback(() => {
        classType.reset();
        togetherClassCode.reset();
        testModeType.reset();
        deliveryTypeS.reset();
        delivery_status.reset();
        recall_status.reset();
        return_status.reset();
        payment_status.reset();
        searchDateType.reset();
        start_dt.reset();
        end_dt.reset();
        searchTargetType.reset();
        searchText.reset();
    }, [
        classType.reset, 
        togetherClassCode.reset,
        testModeType.reset,
        deliveryTypeS.reset, 
        delivery_status.reset,
        recall_status.reset,
        return_status.reset,
        payment_status.reset,
        searchDateType.reset, 
        start_dt.reset, 
        end_dt.reset, 
        searchTargetType.reset, 
        searchText.reset
    ]);
   
    return (
        <div className="deliveryManagement">
        <DashboardNavbar title="배송 관리" />
            <LACard className="mainSearchOptionBoard">
                <LAProperty name="투게더 과목">
                    <LAGridContainer>
                        <LACodeComboBox
                            wPercent={15}
                            showAll={true}
                            codeType="TOGETHER_COURSE_CODE"
                            value={togetherClassCode.state}
                            onChange={togetherClassCode.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="클래스 유형">
                    <LARadioGroup
                        wPercent={15} 
                        props={[
                            { value: 9, text: '투게더' }
                        ]}
                        value={classType.state} 
                        onChange={classType.handleChange} 
                    />
                </LAProperty>
                <LAProperty name="시크릿 유형">
                    <LAGridContainer vAlign="center">
                        <LARadioGroup
                            props={[
                                {text: '전체', value: 0 },
                                {text: '시크릿 포함', value: 1 },
                                {text: '시크릿 제외', value: 2 }
                            ]}
                            value={testModeType.state}
                            onChange={testModeType.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="결제 상태">
                    <LAGridContainer vAlign="center">
                        <LARadioGroup
                            props={[
                                {text: '전체', value: 0 },
                                {text: '결제완료', value: 10 },
                                {text: '결제취소', value: 20 },
                                {text: '취소대기(반품검수)', value: 21 },
                            ]}
                            value={payment_status.state}
                            onChange={payment_status.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="운송장 여부">
                    <LAGridContainer vAlign="center">
                        <LARadioGroup
                          props={[
                              {text: '전체', value: 0 },
                              {text: '있음', value: 1 },
                              {text: '없음', value: 2 },
                          ]}
                          value={deliveryTypeS.state}
                          onChange={deliveryTypeS.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="배송 현황">
                    <LAGridContainer vAlign="center">
                        <LAMultiCheckbox
                            items={deliveryStatusItems}
                            isSelected={delivery_status.isSelected}
                            onChange={delivery_status.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="교환 현황">
                    <LAGridContainer vAlign="center">
                        <LAMultiCheckbox
                            items={recallStatusItems}
                            isSelected={recall_status.isSelected}
                            onChange={recall_status.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="반품 현황">
                    <LAGridContainer vAlign="center">
                        <LAMultiCheckbox
                            items={returnStatusItems}
                            isSelected={return_status.isSelected}
                            onChange={return_status.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="조회 기간">
                    <LAGridContainer vAlign="center">
                        <LAComboBox
                            wPercent={10}
                            items={[
                                { key: 'pay_dt', value: '결제일' },
                                { key: 'class_start_dt', value: '수업 시작일' },
                                { key: 'cancel_dt', value: '취소일' }
                            ]}
                            value={searchDateType.state}
                            onChange={searchDateType.handleChange}
                        />
                        <LATabSelector wPercent={40} layouts={{ ml: { sm: 1 } }} items={periodItems} value={searchPeriodType.state} onChange={searchPeriodType.handleChange} />
                        <LADatePicker wPercent={17} layouts={{ ml: { sm: 1 } }} value={start_dt.state} onChange={start_dt.handleChange} />
                        <LALabel wPercent={1}>~</LALabel>
                        <LADatePicker wPercent={17} value={end_dt.state} onChange={end_dt.handleChange} />
                    </LAGridContainer>
                </LAProperty>
                <LAProperty name="직접 입력">
                    <LAGridContainer>
                        {/* <LAComboBox
                            wPercent={10}
                            items={[
                                { key: 1, value: '통합검색' },
                                { key: 2, value: '결제번호' },
                                { key: 3, value: '결제자명' },
                                { key: 4, value: '클래스명' },
                                { key: 5, value: '북패드 계약' },
                                { key: 6, value: '학습자 정보' }
                            ]}
                            value={searchTargetType.state}
                            onChange={searchTargetType.handleChange}
                        /> */}
                        <LACodeComboBox
                            wPercent={20}
                            codeType="TOGETHER_ORDER_QUERY_TYPE"
                            value={searchTargetType.state}
                            onChange={searchTargetType.handleChange}
                        />
                        <LAInput
                            wPercent={50}
                            layouts={{ pl: { sm: 1 } }}
                            value={searchText.state}
                            onChange={searchText.handleChange}
                            onEnterKeyPressed={onEnterKeyAtSearch}
                        />
                    </LAGridContainer>
                </LAProperty>
                <LAGridContainer layouts={{ pt: 2 }}>
                    <LALabel wPercent={35}></LALabel>
                    <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onSearchClick}>검색</LAButton>
                    <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} variant="outlined" onClick={onResetClick}>초기화</LAButton>
                    <LALabel wPercent={35}></LALabel>
                </LAGridContainer>
            </LACard>

            <LACard layouts={{ pt: { sm: 1 } }}>

                {/* 배송 현황 업데이트 api 미처리로 화면 주석처리 23.05.31 */}
                <LAGridContainer layouts={{ sx: { position: "absolute", top: "45px", left: "10%", width: "100%"}}}>
                    <LALabel wPercent={85} layouts={{ mt: 0, pl: 8, sx: { color: "#949ca5"}}} props={{ fontSize: 10 }}>{`※ 배송 현황은 2시간 단위로 업데이트 됩니다.`}</LALabel>
                    {/*<LAButton wPercent={15} onClick={clickCheckUpdate}>배송 현황 업데이트</LAButton>*/}
                </LAGridContainer>

                <LAGridContainer>
                    <LADataTable2
                        columns={columns}
                        loadUrl={queries.Together.getDeliveryList}
                        params={searchParams}
                        dataToRow={dataToRow}
                        defaultPage={tablePage}
                        onChangePage={onChangeTablePage}
                    />
                </LAGridContainer>
            </LACard>

            <TogetherOrderDetailModal
                orderSeq={selectedOrderSeq}
                show={orderDetailModal.state}
                handleClose={onDetailClose}
            />

            <DeliveryTrackingModal
                context={contextWaybill}
                show={delivertTracking.state}
                handleClose={delivertTrackingClose}
                //handleSelect={delivertTracking}
            />
        </div>
    );
}

export default React.memo(TogetherDeliveryManagement);
