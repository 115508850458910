import React, { Fragment } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MDTypography from 'components/MDTypography';
import Typography from '@mui/material/Typography';
import pxToRem from 'assets/theme/functions/pxToRem';

function LAListItem({ item }) {
  return (
    <ListItemButton
      //   selected={selectedIndex === index}
      //   onClick={(event) => handleListItemClick(event, index)}
      sx={{
        '&:hover': {
          backgroundColor: '#eeeeee',
        },

        '&.Mui-focused, &.Mui-selected:hover, &.Mui-selected, &.Mui-selected.Mui-focused':
          {
            backgroundColor: '#e8f0fe',
          },
        height: pxToRem(30),
        padding: 0,
      }}
    >
      {!!item &&
        item.map((content, index) => {
          return (
            <ListItemText
              key={index}
              primary={content}
              primaryTypographyProps={{
                sx: { 
                  paddingLeft:'8px', 
                  fontSize: pxToRem(12), 
                  textAlign: 'left' 
                },
              }}
            />
          );
        })}
    </ListItemButton>
  );
}

export default React.memo(LAListItem);
