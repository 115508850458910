import React, { useCallback, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LAGridItem from './LAGridItem';
import LAGridContainer from './LAGridContainer';
import useCheckbox from './hooks/useCheckbox';
import MDTypography from 'components/MDTypography';
import { IosShare } from '@mui/icons-material';

function LAMultiCheckbox({
  wPercent = 100,
  layouts = {},
  props = {},
  items = [],
  isSelected,
  onChange,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <LAGridContainer>
        {items.map((item, index) => (
          <LAGridItem key={index}  wPercent={11}>
            <FormGroup>
              <FormControlLabel
                sx={{
                  fontWeight: 400,
                  minWidth: 195,
                  display: "flex"
                }}
                control={
                  <Checkbox
                    {...props}
                    value={item.value}
                    onChange={onChange}
                    checked={isSelected(item.value)}
                  />
                }
                label={
                  <MDTypography variant="button" fontWeight="regular">
                    {item.text}
                  </MDTypography>
                }
              />
            </FormGroup>
          </LAGridItem>
        ))}
      </LAGridContainer>
    </LAGridItem>
  );
}

export default React.memo(LAMultiCheckbox);
