import moment from 'moment';
import 'moment/locale/ko';

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import TimeTableList from './TimeTableList';

import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import { getDuplicateClassSchedules } from 'common/utils/EtcUtils';

import {
  LAGridContainer,
  LAWarningLabel,
  LAButton,
  LACard,
  LACardTitle,
  LAProperty,
  LALabel,
  LAModalDimmed,
} from 'modules/Widgets';

function ScheduleDetail({
  schedules,
  schedule,
  teacherSchedules,
  className,
  classTime,
  show,
  handleClose,
}) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const [newTimetables, setNewTimetables] = useState([]);

  const getTime = useMemo(() => {
    if (schedule) {
      if (schedule.timetables && schedule.timetables.length > 0)
        return moment(
          schedule.start_dt + ' ' + schedule.timetables[0].schedule_time,
        ).format('A hh:mm');
    } else {
    }

    return '00:00';
  }, [schedule]);

  const getDate = useMemo(() => {
    if (schedule) {
      return moment(schedule.start_dt).format('YYYY-MM-DD (dd)');
    }
    return '0000-00-00';
  });

  const onChangedTimetables = (timetables) => {
    const newData = {
      dates: timetables.map((timetable) => ({
        date: timetable.schedule_date,
        time: getTime,
      })),
      // startTime: getTime,
      className,
      classTime,
      scheduleSeq: schedule.schedule_seq,
    };


    let duplicatedMessage = getDuplicateClassSchedules(
      teacherSchedules,
      newData,
    );
    if (duplicatedMessage) {
      showAlert('알림', `중복되는 일정이 있습니다.\n\n${duplicatedMessage}`);
      return false;
    }

    /*
    let isDuplicated = false;
    let duplicatedDate = '';
    let duplicatedTime = '';
    if(schedules && timetables) {
      schedules.forEach((orgSchedule) => {
        if(orgSchedule.timetables) {
          orgSchedule.timetables.forEach((timetable) =>  {
            timetables.forEach((newTimetable) => {
              if(orgSchedule.schedule_seq != newTimetable.schedule_seq
                && newTimetable.schedule_date == timetable.schedule_date 
                && newTimetable.schedule_time == timetable.schedule_time) {
                isDuplicated = true;
                duplicatedDate = newTimetable.schedule_date;
                duplicatedTime = newTimetable.schedule_time;
              }
            });
          });
        }
      });
    }
    
    if(isDuplicated) {
      
      showAlert('알림', `기존 일정에 동일한 날짜가 포함되어 있습니다.\n ${duplicatedDate} ${duplicatedTime}`);
      return false;
    }
    */

    setNewTimetables(timetables);
  };

  const onDeleteSchedule = useCallback(async () => {
    requestDeleteSchedule({
      schedule_seq: schedule.schedule_seq,
    });
  });

  const { request: requestDeleteSchedule } = useCommonMutation({
    query: queries.Schedule.deleteCleanSchedule,
    // params: {schedule_seq:schedule.schedule_seq, timetables:schedule.timetables},
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '일정이 삭제되었습니다.', {
            confirmHandler: () => {
              handleClose();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '일정 삭제에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const onSaveSchedule = useCallback(async () => {
    // schedule.timetables = newTimetables;

    // let dates = newTimetables.map((timetable) => timetable.schedule_date);

    // schedule.start_dt = dates.sort()[0];
    // schedule.end_dt = dates.reverse()[0];

    // requestUpdateSchedules();
    requestUpdateTimetables({
      schedule_seq: schedule.schedule_seq,
      timetables: newTimetables,
    });
  }, [newTimetables]);

  const { request: requestUpdateTimetables } = useCommonMutation({
    query: queries.Schedule.updateTimetables,
    // params: {schedule_seq:schedule.schedule_seq, timetables:schedule.timetables},
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '일정이 수정되었습니다.', {
            confirmHandler: () => {
              handleClose(newTimetables);
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '일정 수정에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  return (
    <LAModalDimmed width="80%" height="80%" show={show}>
      <LACard layouts={{ p: { sm: 2 } }}>
        <LACardTitle title="클래스 일정 상세 정보" />
        <LAProperty name="클래스 시작일">
          <LALabel wPercent={40}>{getDate}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 시간">
          <LALabel wPercent={40}>{getTime}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 일정">
          {!!schedule && schedule.timetables ? (
            <TimeTableList
              start_dt={schedule.start_dt}
              class_time={schedule.class_time}
              timetables={schedule.timetables}
              onChange={onChangedTimetables}
            />
          ) : (
            ''
          )}
          <LAWarningLabel layouts={{ pt: { sx: 1 } }}>
            클래스 일정 변경은 해당 일정의 이전 일정 날짜와 이후 일정 날짜
            사이에서만 선택 가능합니다.
          </LAWarningLabel>
        </LAProperty>
        <LAGridContainer wPercent={100} layouts={{ pt: 4, pl: 2 }}>
          <LALabel wPercent={65}> </LALabel>
          <LAButton
            wPercent={15}
            layouts={{ mr: 1 }}
            btnPaddingY={12}
            onClick={handleClose}
          >
            닫기
          </LAButton>
          <LAButton wPercent={15} btnPaddingY={12} onClick={onSaveSchedule}>
            저장
          </LAButton>
          {/* <LAButton
            wPercent={15}
            btnPaddingY={12}
            onClick={onDeleteSchedule}
          >
            삭제
          </LAButton> */}
        </LAGridContainer>
      </LACard>
    </LAModalDimmed>
  );
}
export default React.memo(ScheduleDetail);
