import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';

import pxToRem from 'assets/theme/functions/pxToRem';

import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from "common/hooks/useCommonMutation";

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
    LAModal,
    LAButton,
    LACard,
    LACardTitle,
    LAGridItem,
    LAProperty,
    LALabel,
    LAInput,
    useInput,
    useModal,
    LAGridContainer,
    LADivider
} from 'modules/Widgets';

import ClassScheduleModal from './ClassScheduleModal';

function addComma(input) {
    if (input) {
        const parts = Math.floor(input).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    } else return 0;
}

function CommissionDetailContents({ commSeq, memberSeq, yyyymm }) {
    const { showAlert, showConfirm } = usePopAlert();
    const { queries } = useApiContext();

    const [isApproved, setIsApproved] = useState('N');
    const [penaltyInfo, setPenaltyInfo] = useState(undefined);
    const [addInfo, setAddInfo] = useState(undefined);
    const [commisionInfo, setCommisionInfo] = useState(undefined);
    const [totalDeamount, setTotalDeamount] = useState(0);

    const pgCommDeamount = useInput(0);
    const pgCommRate = useInput(0);

    const commRate = useInput(0);
    const commDeamount = useInput(0);
    const commCouponPrice = useInput(0); // 쿠폰 대행수수료
    const commPointDeamount = useInput(0); // 올머니 대행수수료
    const commBookClubPoint = useInput(0); // 클럽포인트 대행수수료
    const commBookClubMileage = useInput(0); // 클럽마일리지 대행수수료

    const [calcInfo, setCalcInfo] = useState(undefined);

    const [totalDiscountFinal, setTotalDiscountFinal] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalDiscountComm, setTotalDiscountComm] = useState(0);

    const [supplyValue, setSupplyValue] = useState(0);

    const vatValue = useInput(0);
    const incomeTax = useInput(0);
    const residentTax = useInput(0);

    const [totalSettlementAmount, setTotalSettlementAmount] = useState(0);

    const [totalPenalty, setTotalPenalty] = useState(0);
    const [totalAdd, setTotalAdd] = useState(0);

    const [newPenalty, setNewPenalty] = useState([]);
    const [newAdd, setNewAdd] = useState([]);

    const [selectedAddType, setSelectedAddType] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const classScheduleModal = useModal(false);
    const amountPenalty = useRef('');
    const amountAdd = useRef('');

    useEffect(() => {
        console.log(commSeq, memberSeq, yyyymm);
        if (memberSeq) {
            requestCommissionDetail();
        }
        return () => {
            setPenaltyInfo(undefined);
            setAddInfo(undefined);
            setCommisionInfo(undefined);
            setCalcInfo(undefined);
        }
    }, [memberSeq]);

    useEffect(() => {
        if (commisionInfo) {
            /* 대행수수료 */
            pgCommRate.update(commisionInfo.total_pg_comm_rate);
            pgCommDeamount.update(commisionInfo.total_pg_comm_deamount);
            commRate.update(commisionInfo.total_liveall_comm_rate); 
            commDeamount.update(commisionInfo.total_liveall_comm_deamount); 
            commCouponPrice.update(commisionInfo.total_liveall_comm_coupon_price_deamount);
            commPointDeamount.update(commisionInfo.total_liveall_comm_earned_point_deamount);
            commBookClubPoint.update(commisionInfo.total_liveall_comm_bookclub_point_deamount);
            commBookClubMileage.update((commisionInfo.total_liveall_comm_gm_mileage_deamount + commisionInfo.total_liveall_comm_online_mileage_deamount));

            vatValue.update(commisionInfo.vat);
            setSupplyValue(commisionInfo.supply_price);

            /* 본사 부담 할인 금액 */
            setTotalDiscount(commisionInfo.total_liveall_discount_amount);
            setTotalDiscountComm(commisionInfo.total_liveall_discount_comm_amount);
            setTotalDiscountFinal(commisionInfo.total_liveall_discount_amount - commisionInfo.total_liveall_discount_comm_amount);
            
            residentTax.update(commisionInfo.resident_tax);
            incomeTax.update(commisionInfo.income_tax);

            if (commisionInfo.commission_type == 2) {
                setTotalSettlementAmount(commisionInfo.supply_price + commisionInfo.vat);
            } else {
                setTotalSettlementAmount(commisionInfo.supply_price);
            }
        }

        if (addInfo) {
            let _newAdd = [];
            addInfo.map((add) => {
                _newAdd.push(add);
            });
            setNewAdd(_newAdd);
        }

        if (penaltyInfo) {
            let _newPenalty = [];
            penaltyInfo.map((penalty) => {
                _newPenalty.push(penalty);
            });
            setNewPenalty(_newPenalty);
        }

    }, [commisionInfo, addInfo, penaltyInfo]);

    useEffect(() => {
        let penalties = 0;
        newPenalty.map((penalty) => {
            penalties += parseInt(penalty.amount);
        });
        setTotalPenalty(penalties);

    }, [newPenalty]);

    useEffect(() => {
        let adds = 0;
        newAdd.map((add) => {
            adds += parseInt(add.amount);
        });
        setTotalAdd(adds);

    }, [newAdd]);

    useEffect(() => {
        if (commisionInfo) {
            if (commisionInfo.total_pg_comm_rate != pgCommRate.state)
                pgCommDeamount.update(commisionInfo.total_pg_amount * (pgCommRate.state / 100));
        }
    }, [pgCommRate.state]);

    useEffect(() => {
        setTotalDeamount(
            parseInt(pgCommDeamount.state.toString().replace(/,/g, '')) 
            + parseInt(commDeamount.state.toString().replace(/,/g, '')) 
            + parseInt(commCouponPrice.state.toString().replace(/,/g, ''))
            + parseInt(commPointDeamount.state.toString().replace(/,/g, '')) 
            + parseInt(commBookClubPoint.state.toString().replace(/,/g, '')) 
            + parseInt(commBookClubMileage.state.toString().replace(/,/g, ''))
        );
    }, [pgCommDeamount.state, commDeamount.state, commCouponPrice.state, commPointDeamount.state, commBookClubPoint.state, commBookClubMileage.state]);

    useEffect(() => {
        if (commisionInfo) {
            let settlementAmount = commisionInfo.total_amount - totalDeamount - totalPenalty + totalAdd + totalDiscountFinal;
            let vat = Math.round(settlementAmount / 11);
            let income_tax = Math.floor(settlementAmount * 3 / 1000) * 10;
            let resident_tax = Math.floor(settlementAmount * 3 / 10000) * 10

            setTotalSettlementAmount(settlementAmount);

            if (commisionInfo.commission_type == 2) {
                vatValue.update(vat);
                setSupplyValue(settlementAmount - vat);
            } else if (commisionInfo.commission_type == 2) {
                incomeTax.update(income_tax);
                residentTax.update(resident_tax);
                setSupplyValue(settlementAmount - income_tax - resident_tax);
            }
        }
    }, [commisionInfo, totalDeamount, totalPenalty, totalAdd, totalDiscountFinal])


    const { result: resultData, request: requestCommissionDetail } = useCommonQuery({
        query: queries.Calculate.getCommissionDetail,
        params: {
            yyyymm: yyyymm,
            memberSeq: memberSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                console.log(data);
                if (data.result_code == '0000') {
                    setPenaltyInfo(data.penaltyInfo);
                    setAddInfo(data.addInfo);
                    setCommisionInfo(data.commisionInfo);
                    setIsApproved(data.isApproved);
                } else {
                    if (data.result_code.result_message) showAlert('알림', data.result_code.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '목록 조회에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    const clickCommCalculate = useCallback(() => {
        requestCalculateCommission();
    }, []);

    const { result: resultData2, request: requestCalculateCommission } = useCommonQuery({
        query: queries.Calculate.getCommissionCalculate,
        params: {
            yyyymm: yyyymm,
            memberSeq: memberSeq,
            total_liveall_comm_rate: commRate.state,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code == '0000') {
                    setCalcInfo(data.calcInfo);
                    // setPenaltyInfo(data.penaltyInfo);
                    // setAddInfo(data.addInfo);
                    // setCommisionInfo(data.commisionInfo);
                } else {
                    if (data.result_code.result_message) showAlert('알림', data.result_code.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '목록 조회에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    useEffect(() => {
        console.log(calcInfo);
        if (calcInfo) {
            commDeamount.update(calcInfo.total_liveall_comm_deamount);
            commPointDeamount.update(calcInfo.total_liveall_comm_earned_point_deamount);

            setTotalDiscount(calcInfo.total_liveall_discount_amount);
            setTotalDiscountComm(calcInfo.total_liveall_discount_comm_amount);
            setTotalDiscountFinal(calcInfo.total_liveall_discount_amount - calcInfo.total_liveall_discount_comm_amount);

        }
    }, [calcInfo]);

    const clickPenaltyAdd = useCallback(() => {
        // setSelectedAddType(1);
        // classScheduleModal.visible(true);
        let _newPenalty = JSON.parse(JSON.stringify(newPenalty));
        console.log("clickPenaltyAdd -> ", _newPenalty);
        _newPenalty.push({
            add_type: '1',
            com_seq: commSeq,
            schedule_seq: 0,
            desc: '',
            amount: 0,
        });
        setNewPenalty(_newPenalty);
    }, [newPenalty]);

    const clickAddAdd = useCallback(() => {
        // setSelectedAddType(2);
        // classScheduleModal.visible(true);
        let _newAdd = JSON.parse(JSON.stringify(newAdd));
        console.log("clickAddAdd -> ", _newAdd);
        _newAdd.push({
            add_type: '2',
            com_seq: commSeq,
            schedule_seq: 0,
            desc: '',
            amount: 0,
        });
        setNewAdd(_newAdd);

    }, [newAdd]);

    const showScheduleList = useCallback((index, addType) => {
        console.log(index, addType);
        setSelectedAddType(addType);
        setSelectedIndex(index);
        classScheduleModal.visible(true);
    }, []);

    const addSchedule = useCallback((scheduleSeq, addType, index) => {
        console.log(scheduleSeq, addType, index);
        if (addType == 1) {
            let _newPenalty = JSON.parse(JSON.stringify(newPenalty));
            _newPenalty.forEach((penalty, ind) => {
                if (ind == index) {
                    penalty.schedule_seq = scheduleSeq
                }
            });

            setNewPenalty(_newPenalty);
        } else {
            let _newAdd = JSON.parse(JSON.stringify(newAdd));
            _newAdd.forEach((add, ind) => {
                if (ind == index) {
                    add.schedule_seq = scheduleSeq;
                }
            });
            setNewAdd(_newAdd);
        }
        classScheduleModal.handleClose();
    }, [newPenalty, newAdd]);

    const onPenaltyDescChange = useCallback((event, scheduleSeq, idx) => {
        setNewPenalty(newPenalty.map((penalty, index) => {
            if (penalty.schedule_seq === scheduleSeq) {
                if (idx === index) penalty.desc = event.target.value;
            }
            return penalty;
        }));
    }, [newPenalty]);

    const onPenaltyAmountChange = useCallback((event, scheduleSeq, idx) => {
        setNewPenalty(newPenalty.map((penalty, index) => {
            if (penalty.schedule_seq == scheduleSeq) {
                console.log("event.target.value :", event.target.value)
                if (idx === index) penalty.amount = event.target.value;
            }
            return penalty;
        }));
    }, [newPenalty]);

    const clickPenaltyDelete = useCallback((scheduleSeq, index) => {
        let _newPenalty = [];
        newPenalty.forEach((penalty, ind) => {
            if (ind != index) _newPenalty.push(penalty);
        });
        setNewPenalty(_newPenalty);
    }, [newPenalty]);

    const onAddDescChange = useCallback((event, scheduleSeq, idx) => {
        setNewAdd(newAdd.map((add, index) => {
            if (add.schedule_seq == scheduleSeq) {
                if (idx === index) add.desc = event.target.value;
            }
            return add;
        }));
    }, [newAdd]);

    const onAddAmountChange = useCallback((event, scheduleSeq, idx) => {
        console.log("event : ", event)
        setNewAdd(newAdd.map((add, index) => {
            if (add.schedule_seq == scheduleSeq) {
                if (idx === index) add.amount = event.target.value;
            }
            return add;
        }));
    }, [newAdd]);

    const clickAddDelete = useCallback((scheduleSeq, index) => {
        let _newAdd = [];
        newAdd.forEach((add, ind) => {
            if (ind != index) _newAdd.push(add);
        });
        setNewAdd(_newAdd);
    }, [newAdd]);

    useEffect(() => {
        console.log("=======================");
        console.log(newPenalty, " / ", newAdd);
        console.log("=======================");
    }, [newPenalty, newAdd]);

    const clickSaveCommission = useCallback(() => {

        let com_add_seq = [];
        let schedule_seq = [];
        let desc = [];
        let amount = [];
        let add_type = [];

        let canUsablePenalty = true;

        newPenalty.forEach((penalty) => {
            if (penalty.desc.length < 2 || !penalty.amount) {
                canUsablePenalty = false;
            }
            com_add_seq.push(penalty.com_add_seq);
            schedule_seq.push(penalty.schedule_seq);
            desc.push(penalty.desc);
            amount.push(penalty.amount);
            add_type.push('1');
        });

        if (!canUsablePenalty) {
            showAlert('알림', '패널티 정보를 입력하세요.');
            return;
        }

        let canUsableAdd = true;

        newAdd.forEach((add) => {
            if (add.desc.length < 2 || !add.amount) {
                canUsableAdd = false;
            }
            com_add_seq.push(add.com_add_seq);
            schedule_seq.push(add.schedule_seq);
            desc.push(add.desc);
            amount.push(add.amount);
            add_type.push('2');
        });

        if (!canUsableAdd) {
            showAlert('알림', '보전 정보를 입력하세요.');
            return;
        }

        let del_com_add_seq = [];

        penaltyInfo.forEach((pInfo) => {
            let isDeleted = true;
            newPenalty.forEach((penalty) => {
                if (pInfo.com_add_seq == penalty.com_add_seq) {
                    isDeleted = false;
                }
            });
            if (isDeleted) {
                del_com_add_seq.push(pInfo.com_add_seq);
            }
        });

        addInfo.forEach((aInfo) => {
            let isDeleted = true;
            newAdd.forEach((add) => {
                if (aInfo.com_add_seq == add.com_add_seq) {
                    isDeleted = false;
                }
            });
            if (isDeleted) {
                del_com_add_seq.push(aInfo.com_add_seq);
            }
        });


        let params = {
            com_seq: commSeq,
            total_liveall_comm_rate: commRate.state,
            total_liveall_comm_deamount: commDeamount.state,
            total_liveall_comm_earned_point_deamount: commPointDeamount.state,
            total_liveall_discount_amount: totalDiscount,
            total_liveall_discount_comm_amount: totalDiscountComm,
            total_deamount: totalDeamount,
            total_settlement_amount: totalSettlementAmount,
            resident_tax: residentTax.state,
            income_tax: incomeTax.state,
            vat: vatValue.state,
            supply_price: supplyValue,
            yyyymm: yyyymm,
            memberSeq: memberSeq,
            com_add_seq: com_add_seq,
            schedule_seq: schedule_seq,
            desc: desc,
            amount: amount,
            add_type: add_type,
            del_com_add_seq: del_com_add_seq,
        };
        console.log("params CHECK -> ", params);

        showConfirm('확인', '저장하시겠습니까?', {
            confirmHandler: () => {
                requestSaveCommission(params);
            },
        });
    }, [
        commRate.state,
        commDeamount.state,
        commPointDeamount.state,
        totalDiscount,
        totalDiscountComm,
        totalDeamount,
        totalSettlementAmount,
        residentTax.state,
        incomeTax.state,
        vatValue.state,
        supplyValue,
        newPenalty,
        newAdd,
        penaltyInfo,
        addInfo,
    ]);

    const { result: resultData3, request: requestSaveCommission } = useCommonMutation({
        query: queries.Calculate.saveCommission,
        callbacks: {
            onSuccess: (data) => {
                console.log(data);
                if (data.result_code == '0000') {
                    showAlert('알림', '저장했습니다.');
                } else {
                    if (data.result_code.result_message) showAlert('알림', data.result_code.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '저장에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    const clickApprove = useCallback(() => {
        showConfirm('확인', '거래명세서를 승인하시겠습니까?\n사용자에게 상세 정산 금액이 노출됩니다.', {
            confirmHandler: () => {
                commissionApprove();
            },
        });
    }, []);

    const { result: resultData4, request: commissionApprove } = useCommonQuery({
        query: queries.Calculate.commissionApprove,
        params: {
            yyyymm: yyyymm,
            memberSeq: memberSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code == '0000') {
                    showAlert('알림', '수수료 금액이 최종 승인되었습니다.');
                    requestCommissionDetail();
                } else {
                    if (data.result_code.result_message)
                        showAlert('알림', data.result_code.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '승인 처리에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    const clickApproveCancel = useCallback(() => {
        showConfirm('확인', '거래명세서를 취소하시겠습니까?', {
            confirmHandler: () => {
                commissionApproveCancel();
            },
        });
    }, []);

    const { result: resultData5, request: commissionApproveCancel } = useCommonQuery({
        query: queries.Calculate.commissionApproveCancel,
        params: {
            yyyymm: yyyymm,
            memberSeq: memberSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code == '0000') {
                    showAlert('알림', '승인이 취소되었습니다.');
                    requestCommissionDetail();
                } else {
                    if (data.result_code.result_message)
                        showAlert('알림', data.result_code.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '승인 취소 처리에 실패하였습니다.');
            },
        },
        initEnabled: false,
    });

    return (
        <>
            <LACard>
                <LACardTitle title="선생님 정보" />
                <LAProperty name="업체명">
                    <LALabel wPercent={30}>{commisionInfo ? commisionInfo.alliance_name : ''}</LALabel>
                </LAProperty>
                <LAProperty name="정산코드(SAP)">
                    <LALabel wPercent={30}>{commisionInfo ? commisionInfo.sap_erp_code : ''}</LALabel>
                </LAProperty>
                <LAProperty name="선생님">
                    <LALabel wPercent={30}>{commisionInfo ? commisionInfo.member_name : ''}</LALabel>
                </LAProperty>
                <LAProperty name="선생님 Email">
                    <LALabel wPercent={30}>{commisionInfo ? commisionInfo.email : ''}</LALabel>
                </LAProperty>
            </LACard>
            <LACard next>
                <LACardTitle title="총 매출액(실 수업분)" />
                <LAGridContainer layouts={{ sx: { border: '1px solid #dddddd', borderRadius: 1, overflow: 'auto', }, }}>
                    <LAGridContainer vAlign="center" layouts={{ px: 2, sx: { backgroundColor: '#eeeeee', borderBottom: '1px solid #dddddd', }, }}>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>목록</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>총 금액(원)</LALabel>
                        <LALabel wPercent={55} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>상세 항목</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>상세 금액(원)</LALabel>
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 매출액(실수업분)</LALabel>
                        <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_amount > 0)? addComma(commisionInfo.total_amount) : '-'}</LALabel>
                        <LAGridItem wPercent={55}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 카드 결제 금액</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 쿠폰 결제 금액</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 올머니 결제 금액</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 클럽 포인트 결제 금액</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 클럽 마일리지 결제 금액</LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={15}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {(commisionInfo && commisionInfo.total_pg_amount > 0) ? addComma(commisionInfo.total_pg_amount) : '-'}
                            </LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {(commisionInfo && commisionInfo.total_coupon_price_amount > 0) ? addComma(commisionInfo.total_coupon_price_amount) : '-'} 
                            </LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {(commisionInfo && commisionInfo.total_earned_point_amount > 0) ? addComma(commisionInfo.total_earned_point_amount) : '-'}
                            </LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {(commisionInfo && commisionInfo.total_bookclub_point_amount > 0) ? addComma(commisionInfo.total_bookclub_point_amount) : '-'} 
                            </LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {(commisionInfo && (commisionInfo.total_gm_mileage_amount > 0 || commisionInfo.total_online_mileage_amount > 0)) ? addComma(commisionInfo.total_gm_mileage_amount + commisionInfo.total_online_mileage_amount) : '-'} 
                            </LALabel>
                        </LAGridItem>
                    </LAGridContainer>
                </LAGridContainer>
            </LACard>
            <LACard next>
                <LACardTitle title="총 정산 대상 금액" />
                <LAGridContainer layouts={{ sx: { border: '1px solid #dddddd', borderRadius: 1, overflow: 'auto', }, }}>
                    <LAGridContainer vAlign="center" layouts={{ px: 2, sx: { backgroundColor: '#eeeeee', borderBottom: '1px solid #dddddd', }, }}>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>목록</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>총 금액(원)</LALabel>
                        <LALabel wPercent={40} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>상세 항목</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>수수료율</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>상세 금액(원)</LALabel>
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>공제 금액</LALabel>
                        <LAGridItem wPercent={15}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_pg_comm_deamount > 0) ? addComma(commisionInfo.total_pg_comm_deamount) : '-'}</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {commisionInfo && (
                                    commisionInfo.total_liveall_comm_deamount 
                                    + commisionInfo.total_liveall_comm_coupon_price_deamount
                                    + commisionInfo.total_liveall_comm_earned_point_deamount 
                                    + commisionInfo.total_liveall_comm_bookclub_point_deamount 
                                    + commisionInfo.total_liveall_comm_gm_mileage_deamount
                                    + commisionInfo.total_liveall_comm_online_mileage_deamount
                                ) > 0 ? 
                                    addComma(commisionInfo.total_liveall_comm_deamount 
                                        + commisionInfo.total_liveall_comm_coupon_price_deamount
                                        + commisionInfo.total_liveall_comm_earned_point_deamount 
                                        + commisionInfo.total_liveall_comm_bookclub_point_deamount 
                                        + commisionInfo.total_liveall_comm_gm_mileage_deamount
                                        + commisionInfo.total_liveall_comm_online_mileage_deamount
                                    ): '0'}
                            </LALabel>
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={40}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>카드수수료 (-)</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>카드 결제 건 대행 수수료 (-)</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>쿠폰 결제 건 대행 수수료(-)</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>올머니 결제 건 대행 수수료 (-)</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>클럽 포인트 결제 건 대행 수수료 (-)</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>클럽 마일리지 결제 건 대행 수수료 (-)</LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={15}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', lineHeight: 1.65 }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {commisionInfo ? commisionInfo.total_pg_comm_rate : '0'}%</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', lineHeight: 1.65 }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{commisionInfo ? commisionInfo.total_liveall_comm_rate : '0'}%</LALabel>
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                            <LALabel wPercent={100} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={15}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_pg_comm_deamount > 0) ? addComma(commisionInfo.total_pg_comm_deamount) : '-'}</LALabel>
                            <LADivider />
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_liveall_comm_deamount > 0) ? addComma(commisionInfo.total_liveall_comm_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_liveall_comm_coupon_price_deamount > 0) ? addComma(commisionInfo.total_liveall_comm_coupon_price_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_liveall_comm_earned_point_deamount > 0) ? addComma(commisionInfo.total_liveall_comm_earned_point_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_liveall_comm_bookclub_point_deamount > 0) ? addComma(commisionInfo.total_liveall_comm_bookclub_point_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {(commisionInfo && (commisionInfo.total_liveall_comm_gm_mileage_deamount > 0 || commisionInfo.total_liveall_comm_online_mileage_deamount > 0)) ? addComma(commisionInfo.total_liveall_comm_gm_mileage_deamount + commisionInfo.total_liveall_comm_online_mileage_deamount) : '-'}
                            </LALabel>
                        </LAGridItem>
                    </LAGridContainer>
                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>정산 금액</LALabel>
                        <LAGridItem wPercent={15}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                {commisionInfo?.total_pg_amount > 0 
                                    ? addComma(
                                        (commisionInfo.total_pg_amount - commisionInfo.total_liveall_comm_deamount - commisionInfo.total_pg_comm_deamount) // 카드 결제 건 정산 금액
                                        + (commisionInfo.total_coupon_price_amount - commisionInfo.total_liveall_comm_coupon_price_deamount) // 쿠폰 결제 건 정산 금액
                                        + (commisionInfo.total_earned_point_amount - commisionInfo.total_liveall_comm_earned_point_deamount) // 올머니 결제 건 정산 금액
                                        + (commisionInfo.total_bookclub_point_amount - commisionInfo.total_liveall_comm_bookclub_point_deamount) // 클럽 포인트 결제 건 정산 금액
                                        + ((commisionInfo.total_gm_mileage_amount + commisionInfo.total_online_mileage_amount) - (commisionInfo.total_liveall_comm_gm_mileage_deamount + commisionInfo.total_liveall_comm_online_mileage_deamount)) // 클럽 마일리지 결제 건 정산 금액
                                    ) : '-'}
                            </LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={40}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>카드 결제 건 정산 금액</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>쿠폰 결제 건 정산 금액</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>올머니 결제 건 정산 금액</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>클럽 포인트 결제 건 정산 금액</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>클럽 마일리지 결제 건 정산 금액</LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={15}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', lineHeight: 1.65 }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{commisionInfo ? (100 - commisionInfo.total_liveall_comm_rate) : '0'}%</LALabel>
                        </LAGridItem>
                        <LAGridItem wPercent={15}>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_pg_amount > 0) ? addComma(commisionInfo.total_pg_amount - commisionInfo.total_liveall_comm_deamount - commisionInfo.total_pg_comm_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_coupon_price_amount > 0) ? addComma(commisionInfo.total_coupon_price_amount - commisionInfo.total_liveall_comm_coupon_price_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_earned_point_amount > 0) ? addComma(commisionInfo.total_earned_point_amount - commisionInfo.total_liveall_comm_earned_point_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && commisionInfo.total_bookclub_point_amount > 0) ? addComma(commisionInfo.total_bookclub_point_amount - commisionInfo.total_liveall_comm_bookclub_point_deamount) : '-'}</LALabel>
                            <LALabel wPercent={100} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{(commisionInfo && (commisionInfo.total_gm_mileage_amount > 0 || commisionInfo.total_online_mileage_amount > 0)) ? addComma((commisionInfo.total_gm_mileage_amount + commisionInfo.total_online_mileage_amount) - (commisionInfo.total_liveall_comm_gm_mileage_deamount + commisionInfo.total_liveall_comm_online_mileage_deamount)) : '-'}</LALabel>
                        </LAGridItem>
                    </LAGridContainer>
                </LAGridContainer>
            </LACard>
            <LACard next>
                <LACardTitle title="패널티 금액 공제" />
                <LAGridContainer layouts={{ sx: { border: '1px solid #dddddd', borderRadius: 1, overflow: 'auto', }, }}>
                    <LAGridContainer vAlign="center" layouts={{ px: 2, sx: { backgroundColor: '#eeeeee', borderBottom: '1px solid #dddddd', }, }}>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>목록</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>총 금액(원)</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>스케줄번호</LALabel>
                        <LALabel wPercent={40} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>지급 사유</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>상세 금액(원)</LALabel>
                    </LAGridContainer>
                    {newPenalty.length > 0 ?
                        (<LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                            <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 패널티 금액</LALabel>
                            <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{addComma(totalPenalty)}</LALabel>
                            <LAGridItem wPercent={69.9}>
                                {newPenalty?.map((penalty, index) => {
                                    return (
                                        <LAGridContainer layouts={{ sx: { paddingTop: '10px', paddingBottom: '5px' } }}>
                                            <LALabel wPercent={20} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                                {penalty.schedule_seq ? penalty.schedule_seq : (<LAButton wPercent={100} layouts={{ pl: { sm: 1 } }} onClick={() => showScheduleList(index, '1')}>선택</LAButton>)}
                                            </LALabel>
                                            <LAInput wPercent={55} value={penalty.desc} onChange={(event) => onPenaltyDescChange(event, penalty.schedule_seq, index)} />
                                            <LAInput 
                                                wPercent={15} layouts={{ pl: { sm: 1 } }} 
                                                type="number" 
                                                value={penalty.amount} 
                                                onChange={(event) => onPenaltyAmountChange(event, penalty.schedule_seq, index)} 
                                            />
                                            <LAButton wPercent={10} layouts={{ pl: { sm: 0.45 } }} onClick={() => clickPenaltyDelete(penalty.schedule_seq, index)}>삭제</LAButton>
                                        </LAGridContainer>
                                    )
                                })}
                            </LAGridItem>
                            <LALabel wPercent={0.1} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}></LALabel>
                        </LAGridContainer>)
                        : (<></>)}
                </LAGridContainer>
                <LAGridContainer layouts={{ pt: 1 }}>
                    <LALabel wPercent={85} ></LALabel>
                    <LAButton wPercent={15} variant={'outlined'} onClick={clickPenaltyAdd}>패널티 추가</LAButton>
                </LAGridContainer>
            </LACard>
            <LACard next>
                <LACardTitle title="보전 금액" />
                <LAGridContainer layouts={{ sx: { border: '1px solid #dddddd', borderRadius: 1, overflow: 'auto', }, }}>
                    <LAGridContainer vAlign="center" layouts={{ px: 2, sx: { backgroundColor: '#eeeeee', borderBottom: '1px solid #dddddd', }, }}>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>목록</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>총 금액(원)</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>스케줄번호</LALabel>
                        <LALabel wPercent={40} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>지급 사유</LALabel>
                        <LALabel wPercent={15} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>상세 금액(원)</LALabel>
                    </LAGridContainer>

                    {newAdd.length > 0 ?
                        (<LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                            <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 보전 금액</LALabel>
                            <LALabel wPercent={15} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{addComma(totalAdd)}</LALabel>
                            <LAGridItem wPercent={69}>
                                {newAdd?.map((add, index) => {
                                    return (
                                        <LAGridContainer layouts={{ sx: { paddingTop: '10px', paddingBottom: '5px' } }}>
                                            <LALabel wPercent={20} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>
                                                {add.schedule_seq ? add.schedule_seq : (<LAButton wPercent={100} layouts={{ pl: { sm: 1 } }} onClick={() => showScheduleList(index, '2')}>선택</LAButton>)}
                                            </LALabel>
                                            <LAInput wPercent={55} value={add.desc} onChange={(event) => onAddDescChange(event, add.schedule_seq, index)} />
                                            <LAInput
                                                wPercent={15} 
                                                layouts={{ pl: { sm: 1 } }} 
                                                type="number"
                                                value={add.amount} 
                                                onChange={(event) => onAddAmountChange(event, add.schedule_seq, index)}
                                            />
                                            <LAButton wPercent={10} layouts={{ pl: { sm: 0.45 } }} onClick={() => clickAddDelete(add.schedule_seq, index)}>삭제</LAButton>
                                        </LAGridContainer>
                                    )
                                })}
                            </LAGridItem>
                            <LALabel wPercent={1} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(12) }}>&nbsp;</LALabel>
                        </LAGridContainer>)
                        : (<></>)}
                </LAGridContainer>
                <LAGridContainer layouts={{ pt: 1 }}>
                    <LALabel wPercent={85} ></LALabel>
                    <LAButton wPercent={15} variant={'outlined'} onClick={clickAddAdd}>보전 추가</LAButton>
                </LAGridContainer>
            </LACard>
            <LACard next>
                <LACardTitle title="본사 부담 할인 금액" />
                <LAGridContainer layouts={{ sx: { border: '1px solid #dddddd', borderRadius: 1, overflow: 'auto', }, }}>
                    <LAGridContainer vAlign="center" layouts={{ px: 2, sx: { backgroundColor: '#eeeeee', borderBottom: '1px solid #dddddd', }, }}>
                        <LALabel wPercent={25} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>목록</LALabel>
                        <LALabel wPercent={25} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>지급 금액(원)</LALabel>
                        <LALabel wPercent={25} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>대상 금액(원)</LALabel>
                        <LALabel wPercent={25} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>대행 수수료 금액(원)</LALabel>
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={25} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 금액</LALabel>
                        <LALabel wPercent={25} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalDiscountFinal > 0 ? addComma(totalDiscountFinal) : '-'}</LALabel>
                        <LALabel wPercent={25} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalDiscount > 0 ? addComma(totalDiscount) : '-'}</LALabel>
                        <LALabel wPercent={25} layouts={{ sx: { textAlign: 'center', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalDiscountComm > 0 ? addComma(totalDiscountComm) : '-'}</LALabel>
                    </LAGridContainer>
                </LAGridContainer>
            </LACard>
            <LACard next>
                <LACardTitle title="최종 입금 계산 금액" />
                <LAGridContainer layouts={{ sx: { border: '1px solid #dddddd', borderRadius: 1, overflow: 'auto', }, }}>
                    <LAGridContainer vAlign="center" layouts={{ px: 2, sx: { backgroundColor: '#eeeeee', borderBottom: '1px solid #dddddd', }, }}>
                        <LALabel wPercent={40} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>목록</LALabel>
                        <LALabel wPercent={60} layouts={{ px: 2, sx: { textAlign: 'center' } }} props={{ fontSize: pxToRem(13) }}>총 금액(원)</LALabel>
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 매출액(실수업분)</LALabel>
                        <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{commisionInfo?.total_amount > 0 ? addComma(commisionInfo.total_amount) : '-'}</LALabel>
                    </LAGridContainer>
                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 정산 대상 금액</LALabel>
                        <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{commisionInfo?.total_target_amount > 0 ? addComma(commisionInfo.total_target_amount) : '-'}</LALabel>
                    </LAGridContainer>
                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 공제 금액 (-)</LALabel>
                        <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalDeamount > 0 ? addComma(totalDeamount) : '-'}</LALabel>
                    </LAGridContainer>
                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 패널티 금액 (-)</LALabel>
                        <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalPenalty > 0 ? addComma(totalPenalty) : '-'}</LALabel>
                    </LAGridContainer>
                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 보전 금액 (+)</LALabel>
                        <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalAdd > 0 ? addComma(totalAdd) : '-'}</LALabel>
                    </LAGridContainer>
                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 본사부담 할인 금액 (+)</LALabel>
                        <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalDiscountFinal > 0 ? addComma(totalDiscountFinal) : '-'}</LALabel>
                    </LAGridContainer>
                    <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                        <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 정산 금액</LALabel>
                        <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{totalSettlementAmount > 0 ? addComma(totalSettlementAmount) : '-'}</LALabel>
                    </LAGridContainer>

                    {commisionInfo ? commisionInfo.commission_type == 2 ? (
                        <>
                            <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                                <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>부가가치세액</LALabel>
                                <LAInput wPercent={60} layouts={{ pl: { sm: 10 }, textAlign: 'right' }} value={addComma(vatValue.state)} onChange={vatValue.handleChange} />
                            </LAGridContainer>
                            <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                                <LALabel wPercent={40} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 공급가액</LALabel>
                                <LALabel wPercent={60} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12) }}>{supplyValue > 0 ? addComma(supplyValue) : '-'}</LALabel>
                            </LAGridContainer>
                        </>
                        ) : (
                            <>
                                <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                                    <LALabel wPercent={15} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>소득세</LALabel>
                                    <LAInput wPercent={20} value={incomeTax.state} onChange={incomeTax.handleChange} />
                                </LAGridContainer>
                                <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                                    <LALabel wPercent={15} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>주민세</LALabel>
                                    <LAInput wPercent={20} value={residentTax.state} onChange={residentTax.handleChange} />
                                </LAGridContainer>
                                <LAGridContainer vAlign="center" layouts={{ sx: { borderBottom: '1px solid #dddddd', }, px: 2, pb: 0.3, }}>
                                    <LALabel wPercent={15} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>총 입금액</LALabel>
                                    <LALabel wPercent={20} layouts={{ sx: { textAlign: 'right', fontWeight: 'regular', }, px: 2, }} props={{ fontSize: pxToRem(12), }}>{addComma(supplyValue.state - residentTax.state - incomeTax.state)}</LALabel>
                                </LAGridContainer>
                            </>
                        ) : '' }
                </LAGridContainer>
            </LACard>
            <LAGridContainer layouts={{ pt: { sm: 1 } }}>
                <LALabel wPercent={25}></LALabel>
                {isApproved == 'Y' ? (<LALabel wPercent={25}></LALabel>) : (<LAButton wPercent={25} layouts={{ pr: { sm: 1 } }} variant={'outlined'} onClick={clickSaveCommission}>저장</LAButton>)}
                {isApproved == 'Y' ? (
                    <LAButton wPercent={25} layouts={{ pl: { sm: 1 } }} onClick={clickApproveCancel}>거래명세서 취소</LAButton>
                ) : (
                        <LAButton wPercent={25} layouts={{ pl: { sm: 1 } }} onClick={clickApprove}>거래명세서 승인</LAButton>
                )}
                <LALabel wPercent={25}></LALabel>
            </LAGridContainer>

            <ClassScheduleModal 
                yyyymm={yyyymm} 
                memberSeq={memberSeq} 
                addType={selectedAddType} 
                index={selectedIndex} 
                addSchedule={addSchedule} 
                show={classScheduleModal.state} 
                handleClose={classScheduleModal.handleClose}
                handleSelect={classScheduleModal}
            />
        </>
    );
}

export default CommissionDetailContents;
