import React, { useCallback, useMemo, useState } from 'react';
import CommentReply from './CommentReply';
import { getAge } from 'common/utils';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

// CommentList.js

function ButtonMenu({ onReplyClick, onUpdateClick, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    if (e.target.value === 0) {
      onReplyClick(e);
    } else if (e.target.value === 1) {
      onUpdateClick(e);
    } else if (e.target.value === 2) {
      onDeleteClick(e);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        className="feedback-edit" //2022-07-21 css 중복 방지 클래스 추가
      >
        <MenuItem className="list" onClick={handleClose} value={0}>
          답글
        </MenuItem>
        <MenuItem className="list" onClick={handleClose} value={1}>
          수정
        </MenuItem>
        <MenuItem className="list" onClick={handleClose} value={2}>
          삭제
        </MenuItem>
      </Menu>
    </div>
  );
}

function CommentDefatul({
  userName,
  comment,
  parentId,
  handleRegist,
  handleReply,
  handleUpdate,
  handleDelete,
}) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const isTeacher = comment.member_seq != comment.constructor_seq;

  // const isTeacher = useMemo(() => {
  //   return (
  //     comment.classmate_seq === undefined || comment.classmate_seq === null
  //   );
  // }, [comment.classmate_seq]);

  const className = useMemo(() => {
    let value = isTeacher ? 'teacher' : 'default';
    value += parentId === undefined ? '' : ' reply';
    return value;
  }, [isTeacher, parentId]);

  const getDateTimeText = useCallback((dateTime) => {
    return moment(dateTime).format('YYYY-MM-DD (dd) A hh:mm');
  }, []);

  const onReplyClick = useCallback(
    (e) => {
      e.preventDefault();
      handleReply(e, parentId === undefined ? comment.feedback_seq : parentId);
    },
    [comment.feedback_seq, handleReply, parentId],
  );

  const onUpdateClick = useCallback(
    (e) => {
      e.preventDefault();
      handleUpdate(e, comment.feedback_seq, parentId);
    },
    [comment.feedback_seq, handleUpdate, parentId],
  );

  const onDeleteClick = useCallback(
    (e) => {
      e.preventDefault();
      handleDelete(e, comment.feedback_seq, parentId);
    },
    [comment.feedback_seq, handleDelete, parentId],
  );

  const onDownloadClick = useCallback(() => {
    requestDownloadMaterials();
  }, []);

  const { request: requestDownloadMaterials } = useCommonQuery({
    query: queries.Feedback.downloadFeedbackFiles,
    params: {
      feedback_seq: comment.feedback_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = comment.file_original_name;
        link.setAttribute('download', fileName);
        // document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      onError: (error) => {
        showAlert('알림', '다운로드에 실패했습니다.');
      },
    },
    initEnabled: false,
  });

  const getTeacherNickName = (data) => {
    let teacherNickName = data.teacher_nickname;
    let constructorName = data.constructor_name;

    if(!teacherNickName) {
      return constructorName;
    } else {
      return teacherNickName;
    }
  };

  return (
    // <div className={`${type} list`}>
    <>
      {comment.delete_yn ? (
        <div className={`${className} deleted`}>
          <p>댓글이 삭제되었습니다.</p>
        </div>
      ) : (
        <div className={`${className} list`}>
          {comment?.profile_image_url?.length > 0 ? (
            <img src={comment.profile_image_url} className="avatar" />
          ) : (
            <div className="avatar"></div>
          )}
          <div className="name">
            {/* teacher 일경우 이름만 노출 -> 2022-07-20 이름 뒤 '선생님' 표시 필수 */}
            <h4>
              {isTeacher
                ? `${getTeacherNickName(comment)} 선생님`
                : `${comment.constructor_name}(${getAge(comment.birth)}세)`}
            </h4>
            <p>{getDateTimeText(comment.modified_dt)}</p>
          </div>
          <div className="desc">
            <h5>{comment.contents}</h5>
            {/* file이 있을 때만 파일명 노출 */}
            {comment.file_original_name &&
              comment.file_original_name.length > 0 && (
                // <a onClick={onDownloadClick}>{comment.file_original_name}</a>
                <a href={comment.file_url} target="_blank" rel="noopener noreferrer">{comment.file_original_name}</a>
              )}
          </div>
          {/* type = teacher 아닐 경우 버튼 3개 노출, teacher일 경우 답글버튼만 노출 */}
          {isTeacher ? (
            <div className="button-wrap">
              <button onClick={onReplyClick} className="commentBox_button">
                답글
              </button>
              {comment.owner_yn && (
                <button onClick={onUpdateClick} className="commentBox_button">
                  수정
                </button>
              )}
              {comment.owner_yn && (
                <button
                  onClick={onDeleteClick}
                  className="commentBox_button grayBg"
                >
                  삭제
                </button>
              )}
            </div>
          ) : (
            <div className="button-wrap">
              <button onClick={onReplyClick} className="commentBox_button">
                답글
              </button>
            </div>
          )}
        </div>
      )}

      {comment?.childs?.length > 0 &&
        comment.childs.map((data, index) =>
          data.isReply ? (
            <CommentReply
              userName={userName}
              comment={data}
              key={index}
              parentId={comment.feedback_seq}
              handleRegist={handleRegist}
            />
          ) : (
            <CommentDefatul
              userName={userName}
              comment={data}
              key={index}
              parentId={comment.feedback_seq}
              handleRegist={handleRegist}
              handleReply={handleReply}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
          ),
        )}
    </>
  );
}

export default React.memo(CommentDefatul);
