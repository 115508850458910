import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { getTodayDateText } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
    LAModal,
    useModal,
    LACard,
    LACardTitle,
    LAGridContainer,
    LAGridItem,
    LAProperty,
    LALabel,
    LAWarningLabel,
    LAButton,
    LARadioGroup,
    useRadioGroup,
    LAComboBox,
    useComboBox,
    LADatePicker,
    useDatePicker,
    LATextEditor,
    LAFileAttachment,
    useFileAttachment,
    LAInput,
    useInput,
    LASimpleTable,
    LADataTable,
    getTextCell,
    getNormalButton,
  LAImage
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import moment from 'moment';

import EventClassSearchModal from "./EventClassSearchModal";
import CategoryRegistModal from '../components/CategoryRegistModal';

const hourItems = [
    { key: '01', value: '오전 1시' },
    { key: '02', value: '오전 2시' },
    { key: '03', value: '오전 3시' },
    { key: '04', value: '오전 4시' },
    { key: '05', value: '오전 5시' },
    { key: '06', value: '오전 6시' },
    { key: '07', value: '오전 7시' },
    { key: '08', value: '오전 8시' },
    { key: '09', value: '오전 9시' },
    { key: '10', value: '오전 10시' },
    { key: '11', value: '오전 11시' },
    { key: '12', value: '오후 12시' },
    { key: '13', value: '오후 1시' },
    { key: '14', value: '오후 2시' },
    { key: '15', value: '오후 3시' },
    { key: '16', value: '오후 4시' },
    { key: '17', value: '오후 5시' },
    { key: '18', value: '오후 6시' },
    { key: '19', value: '오후 7시' },
    { key: '20', value: '오후 8시' },
    { key: '21', value: '오후 9시' },
    { key: '22', value: '오후 10시' },
    { key: '23', value: '오후 11시' },
    { key: '00', value: '오전 12시' },
];

const minItems = [
    { key: '00', value: '정각' },
    { key: '05', value: '05분' },
    { key: '10', value: '10분' },
    { key: '15', value: '15분' },
    { key: '20', value: '20분' },
    { key: '25', value: '25분' },
    { key: '30', value: '30분' },
    { key: '35', value: '35분' },
    { key: '40', value: '40분' },
    { key: '45', value: '45분' },
    { key: '50', value: '50분' },
    { key: '55', value: '55분' },
];

// 이벤트 등록 모달창
function EventRegistModal({ show, handleClose, eventSeq }) {
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();
    const [eventInfo, setEventInfo] = useState(undefined);
    const todayDateText = getTodayDateText('-');
    const eventTitle = useInput('');
    const eventSubTitle = useInput('');

    const eventExposureDate = useDatePicker(todayDateText);
    const eventExposureHourValue = useComboBox('09');
    const eventExposureMinuteValue = useComboBox('00');

    const startDate = useDatePicker(todayDateText);
    const startHourValue = useComboBox('09');
    const startMinuteValue = useComboBox('00');
    const endDate = useDatePicker(moment().add(6, 'd').format('YYYY-MM-DD'));
    const endHourValue = useComboBox('09');
    const endMinuteValue = useComboBox('00');
    const thumbnailFile = useFileAttachment(1024, { name: '' });
    const getDescEditorValue = useRef();
    const getDescMoEditorValue = useRef();
    const visibleType = useRadioGroup(0);
    const contentType = useRadioGroup(0);
    const visibleStartDate = useDatePicker(todayDateText);
    const visibleStartHourValue = useComboBox('09');
    const visibleStartMinuteValue = useComboBox('00');
    const visibleEndDate = useDatePicker(moment().add(6, 'd').format('YYYY-MM-DD'));
    const visibleEndHourValue = useComboBox('09');
    const visibleEndMinuteValue = useComboBox('00');

    const classSearchModal = useModal(false);
    const [classTitle, setClassTitle] = useState([]);
    const [groupId, setGroupId] = useState(0);
    const [classGroup, setClassGroup] = useState([]);   // 클래스 그룹
    const lastClassGroup = classGroup[classGroup.length -1];    // 클래스 그룹 마지막 항목
    const [selectClass, setSelectClass] = useState([]); // 클래스 리스트
    const [selectedClassesInModal, setSelectedClassesInModal] = useState([]);   // 모달 내 선택된 클래스 리스트
    const [updateClass, setUpdateClass] = useState([]);
    const [addClass, setAddClass] = useState([]);

    const [deleteClass, setDeleteClass] = useState([]);

    const registCate = useModal(false);
    const [cateType, setCateType] = useState("");
    const [selectedCate, setSelectedCate] = useState([]);
    const [addCate, setAddCate] = useState([]);
    const [deleteCate, setDeleteCate] = useState([]);
    const [preData, setPreData] = useState({})
    
    const [modalTitle, setModalTitle] = useState('이벤트/기획전 등록');

    const updateInputs = useCallback((data) => {
        eventTitle.update(data.event_title);
        eventSubTitle.update(data.event_sub_title || '');

        if (data.exposure_dt) {
            let exposureDate = moment(data.exposure_dt);
            if (exposureDate.isValid()) {
                eventExposureDate.select(exposureDate.format('YYYY-MM-DD'));
                eventExposureHourValue.select(exposureDate.format('hh'));
                eventExposureMinuteValue.select(exposureDate.format('mm'));
            }
        }

        {
            const startMoment = data.period_start_dt ? moment(data.period_start_dt) : undefined;
            const endMoment = data.period_end_dt ? moment(data.period_end_dt) : undefined;
            if (startMoment) {
                startDate.select(startMoment.format('YYYY-MM-DD'));
                startHourValue.select(startMoment.format('hh'));
                startMinuteValue.select(startMoment.format('mm'));
            }
            if (endMoment) {
                endDate.select(endMoment.format('YYYY-MM-DD'));
                endHourValue.select(endMoment.format('hh'));
                endMinuteValue.select(endMoment.format('mm'));
            }
        }
        thumbnailFile.update({
            file: data.event_image_url,
        });

        contentType.update(data.content_type);

        visibleType.update(data.exposure_status);
        if (data.exposure_status == 2) {
            const startMoment = moment(data.reserved_exposure_start_dt);
            const endMoment = moment(data.reserved_exposure_end_dt);
            visibleStartDate.select(startMoment.format('YYYY-MM-DD'));
            visibleStartHourValue.select(startMoment.format('hh'));
            visibleStartMinuteValue.select(startMoment.format('mm'));
            visibleEndDate.select(endMoment.format('YYYY-MM-DD'));
            visibleEndHourValue.select(endMoment.format('hh'));
            visibleEndMinuteValue.select(endMoment.format('mm'));
        }

        if(data.content_type === 2) {
            let recommend = data.group_list.map((list) => {
                return list;
            });
            setPreData(recommend);

            setClassGroup(recommend.map((item) => {
                const add_class_list = item.class_list.map((list) => list.class_seq);

                return {
                    group_id: item.group_id,
                    title: item.title,
                    class_list: item.class_list,
                    add_class_list: add_class_list
                }; 
            }));

            setClassTitle(recommend.map((item) => { return item.title; }));

/*            let settingList = recommend.map((data) => {
                return data.class_list.map((list, idx) => {
                    return {
                        ...list,
                        group_id: data.group_id
                    };
                })
            }).map((data) => {
                return data.map((s) => {
                    return {
                        seq: s.class_seq,
                        data: s,
                        group_id: s.group_id
                    }
                })
            });
            console.log('settingList :', settingList);*/

            let settingList = recommend.flatMap((data) => {
                return data.class_list.map((list) => {
                    const { class_seq, ...restList } = list;
                    return {
                        data: restList,
                        group_id: data.group_id,
                        seq: class_seq,
                    };
                });
            });

            setSelectClass(settingList.map((item) => {
                return item;
            }));

        } else if(data.content_type === 3) {
            setSelectedCate(data.group_list.map((item) => { 
                return item;
            }));
        }
    }, []);

    const { request: requestEventInfo } = useCommonQuery({
        query: queries.Event.getEventInfo,
        params: {
            event_seq: eventSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setEventInfo(data.result_data);
                    updateInputs(data.result_data);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '이벤트 상세 조회에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const { request: requestRegist } = useCommonMutation({
        query: queries.Event.registEvent,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '이벤트가 등록되었습니다.', {
                        confirmHandler: handleClose,
                        cancelHandler: handleClose,
                    });
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '이벤트 등록에 실패했습니다.');
            },
        },
    });

    const { request: requestUpdate } = useCommonMutation({
        query: queries.Event.updateEvent,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '이벤트가 수정되었습니다.',{
                        confirmHandler: handleClose,
                        cancelHandler: handleClose,
                      });
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '이벤트 수정에 실패했습니다.');
            },
        },
    });

    useEffect(() => {
        if (!eventSeq) {
            return;
        }
        setModalTitle('이벤트/기획전 상세');
        requestEventInfo();
    }, [eventSeq]);

    useEffect(() => {
        if (classGroup[0]?.class_list?.length === 0 && classGroup.length > 1) {
            let newGroup = {
                group_id: 1,
                title: "",
                class_list: [],
                add_class_list: [],
                //select_class_data: []
            }
            setClassGroup([newGroup]);
            setSelectClass([])
        }
    },[classGroup])

    const onThumbnailFileChange = useCallback((e) => {
        // setDeletedAdditionalImages((prev) => [...prev, thumbnailFile.state.image_seq]);
        thumbnailFile.handleChange(e);
    }, [thumbnailFile]);

    /* 클래스 유형 */    // 추천 클래스 추가 버튼
    const onAddGroupCard = useCallback(() => {
        if(classGroup.length > 10)  {
            return showAlert('알림','10개 초과 할수 없음');
        }

        const maxGroupId = classGroup.reduce((max, group) =>{
            return group.group_id > max ? group.group_id : max;
        }, 0);

        const newGroupId = maxGroupId + 1;
        setGroupId(newGroupId);

        let newGroup = {
            group_id: newGroupId,
            title: "",
            class_list: [],
            add_class_list: [],
            new_check: "new"
            //select_class_data: []
        }
        setClassGroup((prev) => [...prev, newGroup]);
        //setSelectClass([]); //기존에 선택된 클래스 초기화 위함
    }, [classGroup]);

    // 추천 클래스 삭제 버튼
    const onDeleteGroupCard = useCallback((item) => {
        // let newGroup = classGroup.filter((data, idx) => {
        //     return idx !== parseInt(e.target.value) ? data : ''
        // });
        
        // 삭제한 클래스그룹 제외후 셋팅
        const newGroup = classGroup.filter((data) => data.group_id !== item.group_id);

        // 삭제한 클래스 리스트 제외후 셋팅
        setClassGroup(newGroup);
        setSelectClass((prev) => prev.filter((data) => data.group_id !== item.group_id));

        const eventClassCategorySeqArray = item.class_list.map(classItem => classItem.event_class_category_seq);
        setDeleteClass((prev) => [...prev, ...eventClassCategorySeqArray]);

    }, [classGroup, setDeleteClass]);

    // 클래스 검색 모달창 닫기 버튼
    const onSearchClassClose = useCallback((e) => {
        setClassGroup(prevClassGroups => {
            const updatedClassGroups = prevClassGroups.map(group => {
                if (group.group_id === groupId) {
                    const selectedSeqList = selectClass.filter(item => item.group_id === groupId).map(item => item.seq);
                    const selectClassData = selectClass.filter(item => item.group_id === groupId).map(item => item.data);

                    return {
                        ...group,
                        add_class_list: selectedSeqList,
                        class_list: selectClassData
                    };
                } else {
                    return group;
                }
            });
            return updatedClassGroups;
        });


        classSearchModal.handleClose(e);
    }, [classSearchModal.handleClose, groupId, selectClass]);

    /*const onGroupCardChange = useCallback((e, item) => {
        setClassTitle(e.target.name === item.group_id ? e.target.value : '');
    },[classTitle]);*/

    const onGroupCardChange = useCallback((e, item) => {
        const value = e.target.value;
        const group_id = item.group_id;

        setClassGroup(prevState => {
            const updatedClassGroup = prevState.map(group => {
                if (group.group_id === group_id) {
                    return { ...group, title: value };
                }
                return group;
            });

            return updatedClassGroup;
        });
    }, []);

    const isSelectedClass = useCallback((classSeq) => {
        return selectClass.find((item) => item.seq === classSeq && item.group_id === groupId) ? true : false;
    }, [selectClass, groupId]);

    const isDeleteClass = useCallback((classSeq) => {
        return deleteClass.find((item) => item === classSeq ) ? true : false;
    }, [deleteClass]);

    const isAddClass = useCallback((classSeq, group_id) => {
        return addClass.find((item) => item.seq === classSeq && item.group_id === group_id) ? true : false;
    }, [addClass]);

    const isUpdateClass = useCallback((classSeq) => {
        return updateClass.find((item) => item.seq == classSeq) ? true : false;
    }, [updateClass]);

    // 클래스 검색 버튼
    const onSearchClassClick = useCallback((groupId) => {
        setGroupId(groupId);

        // const selectedClassesInGroup = selectClass[0].filter(item => item.group_id === groupId);
        classSearchModal.visible(true);
    }, [classSearchModal.state, setGroupId, selectClass]);

    const onClassSelectClick = useCallback((item) => {
        if (isSelectedClass(item.class_seq)) {
            setSelectClass((prev) =>
              prev.filter((data) => data.seq !== item.class_seq)
            );

            if (eventSeq && isAddClass(item.class_seq, groupId)) {
                setAddClass((prev) =>
                    prev.filter((data) => data.seq != item.class_seq),
                );
            }

            const existingData = selectClass.find(data => data.seq === item.class_seq && data.group_id === groupId);
            if (eventSeq && isSelectedClass(item.class_seq) && !isDeleteClass(item.class_seq)) {
                setDeleteClass((prev) => [...prev, existingData.data.event_class_category_seq]);
            }
        } else {
            setSelectClass((prev) =>
                prev.concat({
                    data: item,
                    group_id: groupId,
                    seq: item.class_seq,
                }),
            );

            if (eventSeq && !isSelectedClass(item.class_seq) && !isAddClass(item.class_seq, groupId)) {
                setAddClass((prev) =>
                    prev.concat({
                        data: item,
                        group_id: groupId,
                        seq: item.class_seq,
                    }),
                );
            }

            if (eventSeq && isDeleteClass(item.class_seq, groupId)) {
                setDeleteClass((prev) =>
                  prev.filter((data) => data.seq !== item.class_seq || data.group_id !== groupId)
                );
            }

        }
    }, [ eventSeq, isSelectedClass, isDeleteClass, isAddClass, classGroup ]);

    // 클래스 그룹별 리스트에서 클래스 삭제
    const onClassDelete = useCallback((item, classGroupId) => {
        setSelectClass((prev) =>
          prev.filter((data) => data.seq !== item.class_seq || data.group_id !== classGroupId)
        );

        setClassGroup((prevClassGroups) => {
            const updatedGroups = prevClassGroups.map((group) =>
              group.group_id === classGroupId
                ? {
                    ...group,
                    add_class_list: group.add_class_list.filter((classSeq) => classSeq !== item.class_seq || classSeq !== item.class_or_category_seq),
                    class_list: group.class_list.filter((data) => data.class_seq !== item.class_seq || data.class_or_category_seq !== item.class_or_category_seq),
                }
                : group
            );
            return updatedGroups;
        });

        const eventClassCategorySeq = item.event_class_category_seq;
        setDeleteClass((prev) => [...prev, eventClassCategorySeq ]);
        /*setDeleteClass((prev) =>
          prev.concat({
              data: item,
              group_id: classGroupId,
              seq: item.class_seq
          }),
        );*/
    },[classGroup])


    /* 클래스 테이블 리스트 row데이타*/
    const getClassRows = useCallback((rowItem) => {
        const rowArray = Array.isArray(rowItem) ? rowItem : [rowItem];
        const result = [];
        if(!eventSeq ) {
           /* const filteredSelectedClass = selectClass.filter(item => item.group_id === groupId);
            console.log('filteredSelectedClass ::', filteredSelectedClass);*/
            rowArray.map((item) => {
                item.class_list?.map((add) => {
                    result.push([
                        { text: add.class_seq },
                        { text: add.category_name },
                        { text: add.class_type_name },
                        { text: add.class_name, align: "left" },
                        { text: `${add.times}회차` },
                        { text: add.all_age_yn ? '전연령' : `${add.min_target_age}~${add.max_target_age}세`},
                        { text: add.worksheet_yn ? 'Y' : 'N' },
                        { text: add.teacher_name },
                        {
                            text: '삭제',
                            type: 'button',
                            callback: {
                                onClick: () => onClassDelete(add, item.group_id),
                            },
                        },
                    ]);
                })
            });
        } else {
            rowArray?.map((item) => {
                item.class_list?.map((data) => {
                    result.push([
                        { text: data.class_seq || data.class_or_category_seq },
                        { text: data.category_name },
                        { text: data.class_type_name },
                        { text: data.class_name, align: "left" },
                        { text: `${data.times}회차` },
                        { text: data.all_age_yn ? '전연령' : `${data.min_target_age}~${data.max_target_age}세`},
                        { text: data.worksheet_yn ? 'Y' : 'N' },
                        { text: data.teacher_name },
                        {
                            text: '삭제',
                            type: 'button',
                            callback: {
                                onClick: () => onClassDelete(data, item.group_id),
                            },
                        },
                    ]);
                });
            });
        }
        return result;
    }, [selectClass, classGroup, groupId]);

    /* 클래스 등록 리스트 테이블 */
    const classTableData = useCallback((data) => {
        return {
            headers: [
                { text: '코드' },
                { text: '카테고리' },
                { text: '유형' },
                { text: '클래스명' },
                { text: '회차' },
                { text: '연령' },
                { text: '교재' },
                { text: '선생님' },
                { text: '' }
            ],
            wPercentList: [7, 10, 10, 35, 8, 8, 7, 7, 7],
            rows: getClassRows(data)
        };
    }, [getClassRows]);

    /* 카테고리 + 클래스 유형 */    
    const onAddCate = useCallback((e) => {
        if(selectedCate.length >= 10) {
            showAlert('알림', '최대 10개까지 등록 가능합니다.');
            return;
        }

        setCateType(e.target.name);
        registCate.visible(true);
    },[cateType.state, registCate.state]);

    const registCateModalClose = useCallback((e) => {
        registCate.handleClose(e);
    },[registCate.handleClose]);

    const onClickDeleteCate = useCallback((cateSeq) => {
        let settingList = selectedCate.filter((item) => {
            return item.event_class_category_seq !== cateSeq
        });
        setSelectedCate(settingList);

        let delList = [];
        delList.push(...deleteCate, cateSeq);
        setDeleteCate(delList);

    },[selectedCate, deleteCate]);

    const categoryTableRow = useCallback(() => {
        const result = [];
        if (selectedCate && selectedCate.length > 0) {
            selectedCate.forEach((cate) => {
                result.push([
                    {text: cate.type === "CATEGORY" ? `${cate.parent_category_name} > ${cate.category_name}` : `클래스 키워드 > ${cate.keyword}`, align: "left"},
                    {text: cate.title, align: "left"},
                    {
                        text: '삭제',
                        type: 'button',
                        callback: {
                            onClick: () => onClickDeleteCate(cate.event_class_category_seq)
                        },
                    }
                ]);
            });
        }
        return result;
    }, [selectedCate]);

    const categoryTableData = useMemo(() => {
        return {
            headers: [
                {text: "카테고리 정보", align: "left"},
                {text: "카테고리 설명", align: "left"},
                {text: ""}
            ],
            wPercentList: [30, 60, 10], 
            rows: categoryTableRow()
        };
    }, [categoryTableRow]);

    const handleCateList = useCallback((e, item) => {
        setAddCate((add) =>
            add.concat({
                type: item.type,
                parent_category_seq: item.parent_category_seq,
                parent_category_name: item.parent_category_name,
                category_seq: item.category_seq,
                category_name: item.category_name,
                title: item.title,
                keyword: item.keyword,
                event_class_category_seq: Math.floor(Math.random() * 100)
            })
        );
        setSelectedCate((prev) => 
            prev.concat({
                type: item.type,
                parent_category_seq: item.parent_category_seq,
                parent_category_name: item.parent_category_name,
                category_seq: item.category_seq,
                category_name: item.category_name,
                title: item.title,
                keyword: item.keyword,
                event_class_category_seq: Math.floor(Math.random() * 100)
            })
        )
    },[addCate]);

    const onRegistClick = useCallback(() => {

        if (!eventTitle?.state || eventTitle.state.length === 0) {
            showAlert('알림', '이벤트 제목을 입력해 주세요.');
            return;
        }

        let contentHtml = '';
        if (!getDescEditorValue.current || getDescEditorValue.current().length == 0) {
            showAlert('알림', 'PC 내용을 입력해 주세요.');
            return;
        } else {
            if (getDescEditorValue.current().length * 0.7 > 5000000) {
                showAlert('알림', 'PC 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.');
                return;
            }
            contentHtml = `<div style="font-size: 16px; font-family: 노토산스;">${getDescEditorValue.current()}</div>`;
        }

        let contentMoHtml = '';
        if (!getDescMoEditorValue.current || getDescMoEditorValue.current().length == 0) {
            showAlert('알림', 'Mobile 내용을 입력해 주세요.');
            return;
        } else {
            if (getDescMoEditorValue.current().length * 0.7 > 5000000) {
                showAlert('알림', 'Mobile 내용은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.');
                return;
            }
            contentMoHtml = `<div style="font-size: 16px; font-family: 노토산스;">${getDescMoEditorValue.current()}</div>`;
        }

        if (!thumbnailFile?.state?.file || thumbnailFile.state.file.length === 0) {
            showAlert('알림', '썸네일(메인)을 선택해 주세요.');
            return;
        }
        
        // if ((contentType.state === 3 || contentType.state === "3") && !selectedCate && addCate.length <= 0) {
        //     showAlert('알림', '클래스 카테고리를 추가해 주세요.');
        //     return;
        // }
        

        const formData = new FormData();
        formData.append('event_title', eventTitle.state);
        formData.append('event_sub_title', eventSubTitle.state);
        formData.append('exposure_dt', `${eventExposureDate.state} ${eventExposureHourValue.state}:${eventExposureMinuteValue.state}`);
        formData.append('period_start_dt', `${startDate.state} ${startHourValue.state}:${startMinuteValue.state}`);
        formData.append('period_end_dt', `${endDate.state} ${endHourValue.state}:${endMinuteValue.state}`);
        if (thumbnailFile.state.info) {
            formData.append('event_image_file', thumbnailFile.state.info);
        }
        formData.append('event_content_html', contentHtml);
        formData.append('event_content_mo_html', contentMoHtml);
        formData.append('content_type', contentType.state);
        formData.append('exposure_status', visibleType.state);
        formData.append('reserved_exposure_start_dt', `${visibleStartDate.state} ${visibleStartHourValue.state}:${visibleStartMinuteValue.state}`);
        formData.append('reserved_exposure_end_dt', `${visibleEndDate.state} ${visibleEndHourValue.state}:${visibleEndMinuteValue.state}`);

        // 컨텐츠 타입 : 클래스 유형
        if(contentType.state === '2' || contentType.state === 2) {
            let allConditionStatus = true;

            classGroup.forEach((group) => {
                if (group.title == '') {
                    showAlert('알림', '클래스 설명을 전부 입력해주세요.');
                    allConditionStatus = false;
                } else if (!group.class_list || group.class_list.length === 0) {
                    showAlert('알림', '클래스를 선택해주세요.');
                    allConditionStatus = false;
                }
            });

            if(!allConditionStatus) {
                return;
            }

            if (eventSeq) {
                //수정, 삭제 api요청
                let newGroupList = [];
                classGroup.forEach((group) => {
                    if (group.new_check) {
                        newGroupList.push({
                            title: group.title,
                            add_class_list: group.add_class_list,
                        });
                    };
                });

                let updateGroupList = [];
                const previousClassGroup = preData;

                classGroup.forEach((group, index) => {
                    if (!group.new_check) {
                        const previousGroup = previousClassGroup[index];
                        const updateObject = {};

                        // 타이틀 변경 여부 확인
                        const titleChanged = group.title !== previousGroup.title;

                        if (titleChanged) {
                            updateObject.group_id = group.group_id;
                            updateObject.title = group.title;
                        } else {
                            updateObject.group_id = group.group_id;
                            updateObject.title = previousGroup.title;
                        }

                        // 클래스 리스트 변경 여부 확인
                        const classListChanged = !group.add_class_list.every((value, index) =>
                          value === previousGroup?.class_list[index]?.class_seq
                        );

                        if (classListChanged) {
                            updateObject.add_class_list = addClass.map((seq) => seq.seq);
                        } else {
                            // updateObject.add_class_list = group.add_class_list
                            updateObject.add_class_list = [];
                        }
                        if (titleChanged || classListChanged) {
                            updateGroupList.push(updateObject);
                        }
                    }
                });
                let deleteGroupList = [];

                if (deleteClass.length > 0 ) {
                    deleteClass.forEach((item) => {
                        if (item && item !== undefined) {
                            deleteGroupList.push(item);
                        }
                    });
                };

                formData.append('event_seq', eventSeq);
                formData.append('new_group_list', JSON.stringify(newGroupList));
                formData.append('update_group_list', JSON.stringify(updateGroupList));
                formData.append('delete_group_list', JSON.stringify(deleteGroupList))
                const params = {
                    event_seq: eventSeq,
                    data: formData
                };
                requestUpdate(params);
            } else {
                // 등록 api 요청
                if(allConditionStatus) {
                    let newGroupList = [];
                    classGroup.forEach((group) => {
                        if (group.new_check) {
                            newGroupList.push({
                                title: group.title,
                                add_class_list: group.add_class_list,
                            });
                        };
                    });

                    formData.append('new_group_list', JSON.stringify(newGroupList));

                    requestRegist(formData);
                }
            }
        } else {
            // 컨텐츠 타입 : 클래스 유형 이외 처리
                // 수정,삭제
            if (eventSeq) {
                formData.append('event_seq', eventSeq);
                formData.append('new_group_list', JSON.stringify(addCate));
                // formData.append('update_group_list', (contentType.state === 3 || contentType.state === "3") ? JSON.stringify() : JSON.stringify());
                formData.append('delete_group_list', JSON.stringify(deleteCate));
                // formData.append('delete_class_list', (contentType.state === 3 || contentType.state === "3") ? JSON.stringify(addCate) : JSON.stringify());
                const params = {
                    event_seq: eventSeq,
                    data: formData
                };
                console.log("params ;", params);
                requestUpdate(params);
                // 등록
            } else {
                console.log("-------------------------")
                console.log("selectClass :: ", selectClass)
                console.log("deleteClass :: ", deleteClass)
                console.log("addClass :: ", addClass)
                console.log("-------------------------")
                const classData = [{
                    "title": classTitle,
                    "add_class_list": selectClass.map((item) => item.seq)
                }];

                formData.append('new_group_list', (JSON.stringify(addCate)));
                requestRegist(formData);
            }
        }
    }, [
        eventSeq,
        eventTitle.state,
        eventSubTitle.state,
        eventExposureDate.state,
        eventExposureHourValue.state,
        eventExposureMinuteValue.state,
        contentType.state,
        startDate.state,
        startHourValue.state,
        startMinuteValue.state,
        endDate.state,
        endHourValue.state,
        endMinuteValue.state,
        getDescEditorValue,
        getDescMoEditorValue,
        thumbnailFile.state,
        visibleType.state,
        visibleStartDate.state,
        visibleStartHourValue.state,
        visibleStartMinuteValue.state,
        visibleEndDate.state,
        visibleEndHourValue.state,
        visibleEndMinuteValue.state,
        classTitle,
        classGroup,
        selectClass,
        addClass,
        updateClass,
        deleteClass,
        selectedCate,
        addCate,
        deleteCate
    ]);

    return (
        <>
            <LAModal title={modalTitle} width="90%" height="95%" show={show} onClose={handleClose}>
                <LACard>
                    <LACardTitle title="이벤트 정보" />

                    <LAProperty name="제목" required>
                        <LAInput
                            placeholder="제목을 입력해주세요."
                            value={eventTitle.state}
                            onChange={eventTitle.handleChange}
                        />
                    </LAProperty>

                    <LAProperty name="부제목">
                        <LAInput
                            placeholder="부제목을 입력해주세요."
                            value={eventSubTitle.state}
                            onChange={eventSubTitle.handleChange}
                        />
                    </LAProperty>

                    <LAProperty name="게시 일시" required>
                        <LAGridContainer>
                            <LADatePicker
                                wPercent={15}
                                layouts={{ pr: { sm: 1 } }}
                                value={eventExposureDate.state}
                                onChange={eventExposureDate.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={hourItems}
                                layouts={{ pr: { sm: 1 } }}
                                value={eventExposureHourValue.state}
                                onChange={eventExposureHourValue.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={minItems}
                                layouts={{ pr: { sm: 1 } }}
                                value={eventExposureMinuteValue.state}
                                onChange={eventExposureMinuteValue.handleChange}
                            />
                        </LAGridContainer>
                        {/* <LAInput
                            placeholder="게시 일시를 입력해 주세요."
                            value={eventRegisterDate.state}
                            onChange={eventRegisterDate.handleChange}
                        /> */}
                        <LAWarningLabel>프론트에 표시되는 게시 일시를 입력하세요.</LAWarningLabel>
                    </LAProperty>

                    <LAProperty name="일정" required>
                        <LAGridContainer>
                            <LADatePicker
                                wPercent={15}
                                layouts={{ pr: { sm: 1 } }}
                                value={startDate.state}
                                onChange={startDate.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={hourItems}
                                layouts={{ pr: { sm: 1 } }}
                                value={startHourValue.state}
                                onChange={startHourValue.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={minItems}
                                layouts={{ pr: { sm: 1 } }}
                                value={startMinuteValue.state}
                                onChange={startMinuteValue.handleChange}
                            />
                            <LALabel wPercent={1}>~</LALabel>
                            <LADatePicker
                                wPercent={15}
                                layouts={{ pl: { sm: 1 } }}
                                value={endDate.state}
                                onChange={endDate.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={hourItems}
                                layouts={{ pl: { sm: 1 } }}
                                value={endHourValue.state}
                                onChange={endHourValue.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={minItems}
                                layouts={{ pl: { sm: 1 } }}
                                value={endMinuteValue.state}
                                onChange={endMinuteValue.handleChange}
                            />
                        </LAGridContainer>
                    </LAProperty>

                    <LAProperty name="썸네일" required>
                        <LAGridContainer>
                            {thumbnailFile.state.file ? (
                                <LAImage wPercent={30} file={thumbnailFile.state.file} />
                            ):('')}
                            <LAGridItem wPercent={60}>
                                <LAFileAttachment
                                    hiddenLabel
                                    placeholder=""
                                    props={{
                                        accept: 'image/jpg,image/png,image/jpeg,image/gif',
                                    }}
                                    value={thumbnailFile.state.name}
                                    onChange={onThumbnailFileChange}
                                />
                                <LALabel
                                    layouts={{ pl: 1, pt: 1, lineHeight: 1 }}
                                    props={{
                                        variant: 'caption',
                                        color: 'error',
                                        fontWeight: 'regular',
                                        verticalAlign: 'middle',
                                        sx: {
                                            color: '#ff0000',
                                        },
                                    }}
                                >
                                    - 이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로 등록됩니다.<br />
                                    - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)<br />
                                    - 이미지 사이즈 : 1196 * 572 / 형식 : jpg, jpeg, png (jpeg 등록 권장)<br />
                                    - 1MB 이하로 등록가능
                                </LALabel>
                            </LAGridItem>
                            
                        </LAGridContainer>
                    </LAProperty>

                    <LAProperty name="컨텐츠 타입" required>
                        <LAGridContainer>
                            <LARadioGroup
                                props={[
                                    { text: '텍스트 에디터', value: 0 },
                                    { text: '클래스 유형', value: 2 },
                                    { text: '카테고리+클래스 유형', value: 3 },
                                    { text: '커스텀 컨텐츠', value: 1 }
                                ]}
                                value={contentType.state}
                                onChange={contentType.handleChange}
                            />
                            <LAWarningLabel>텍스트 에디터: 에디터에서 입력한 내용을 화면에 표시합니다.</LAWarningLabel>
                            <LAWarningLabel>클래스 유형: 에디터 + 클래스 유형으로 화면에 표시됩니다.</LAWarningLabel>
                            <LAWarningLabel>카테고리+클래스 유형: 에디터 + 카테고리 + 클래스 유형으로 화면에 표시됩니다.</LAWarningLabel>
                            <LAWarningLabel>커스텀 컨텐츠: 별도 퍼블리싱한 내용을 표시합니다.</LAWarningLabel>
                        </LAGridContainer>
                    </LAProperty>

                    <LAProperty name="노출 여부" required>
                        <LAGridContainer>
                            <LARadioGroup
                                props={[
                                    { text: '미노출', value: 0 },
                                    { text: '노출', value: 1 },
                                ]}
                                value={visibleType.state}
                                onChange={visibleType.handleChange}
                            />
                        </LAGridContainer>

                    {visibleType.state == 2 && (
                        <LAGridContainer>
                            <LADatePicker
                                wPercent={15}
                                layouts={{ pr: { sm: 1 } }}
                                value={visibleStartDate.state}
                                onChange={visibleStartDate.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={hourItems}
                                layouts={{ pr: { sm: 1 } }}
                                value={visibleStartHourValue.state}
                                onChange={visibleStartHourValue.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={minItems}
                                layouts={{ pr: { sm: 1 } }}
                                value={visibleStartMinuteValue.state}
                                onChange={visibleStartMinuteValue.handleChange}
                            />
                            <LALabel wPercent={1}>~</LALabel>
                            <LADatePicker
                                wPercent={15}
                                layouts={{ pl: { sm: 1 } }}
                                value={visibleEndDate.state}
                                onChange={visibleEndDate.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={hourItems}
                                layouts={{ pl: { sm: 1 } }}
                                value={visibleEndHourValue.state}
                                onChange={visibleEndHourValue.handleChange}
                            />
                            <LAComboBox
                                wPercent={15}
                                items={minItems}
                                layouts={{ pl: { sm: 1 } }}
                                value={visibleEndMinuteValue.state}
                                onChange={visibleEndMinuteValue.handleChange}
                            />
                        </LAGridContainer>
                    )}
                    </LAProperty>

                    <LAProperty name="내용(PC)" required>
                        <LATextEditor
                            name="description_e"
                            value={eventInfo?.event_content_html || ''}
                            getEditorValue={getDescEditorValue}
                        />
                    </LAProperty>

                    <LAProperty name="내용(Mobile)" required>
                        <LATextEditor
                            name="description_e_mo"
                            value={eventInfo?.event_content_mo_html || ''}
                            getEditorValue={getDescMoEditorValue}
                        />
                    </LAProperty>
                </LACard>
                {(contentType.state === 2 || contentType.state === "2") && (
                    classGroup.length > 0 ? (
                        classGroup.map((item, idx) => 
                            <LACard layouts={{ mt: 2 }} key={idx}>
                                <LACardTitle 
                                    title={idx === 0 ? "추천 클래스" : `\n`}
                                    showDivider={idx === 0 ? true : false}
                                    customUi={idx === 0 
                                        ? (<LAButton
                                            wPercent={15}
                                            layouts={{ position: "absolute", right: "1.5%",  width: "11%" }}
                                            variant="contained"
                                            onClick={onAddGroupCard}
                                            disabled={lastClassGroup.class_list.length === 0 ? true : false}>
                                          추천 클래스 추가
                                        </LAButton>)
                                        : (<LAButton wPercent={15} layouts={{ position: "absolute", right: "1.5%",  width: "11%" }} variant="outlined" props={{ value: idx }} onClick={() => onDeleteGroupCard(item)}>추천 클래스 삭제</LAButton>)
                                    }
                                />
                                <LAProperty name="클래스 설명" required>
                                    <LAInput 
                                        placeholder="클래스 설명을 입력해 주세요."
                                        name={item.group_id}
                                        value={item.title}
                                        onChange={(e) => onGroupCardChange(e, item)}
                                    />
                                </LAProperty>
                                <LAProperty name="클래스 등록" required>
                                    <LAButton wPercent={15} variant="contained" props={{ value: item.group_id, name: idx }} onClick={() => onSearchClassClick(item.group_id)}>클래스 검색</LAButton>
                                    <LAGridContainer layouts={{ pt: 1 }}>
                                        <LASimpleTable tableData={classTableData(item)} />
                                    </LAGridContainer>
                                </LAProperty>
                            </LACard> 
                    )
                ) : (
                    <LACard layouts={{ mt: 2 }}>
                        <LACardTitle 
                            title="추천 클래스"
                            customUi={<LAButton wPercent={15} layouts={{ position: "absolute", right: "1.5%",  width: "11%" }} variant="contained" onClick={onAddGroupCard}>추천 클래스 추가</LAButton>}
                        />
                       {/* <LAProperty name="클래스 설명" required>
                            <LAInput 
                                placeholder="클래스 설명을 입력해 주세요."
                                value={classTitle.state}
                                onChange={(e) => setClassTitle(e.target.value)}
                            />
                        </LAProperty>
                        <LAProperty name="클래스 등록" required>
                            <LAButton wPercent={15} variant="contained" props={{ value: "", name: 0 }} onClick={(e) => onSearchClassClick(e)}>클래스 검색</LAButton>
                            <LAGridContainer layouts={{ pt: 1 }}>
                                {selectClass.length > 0 && (
                                    <LASimpleTable tableData={classTableData()} />
                                )}
                            </LAGridContainer>
                        </LAProperty>*/}
                    </LACard> 
                ))}

                {(contentType.state === 3 || contentType.state === "3") && (
                    <LACard layouts={{ mt: 2 }}>
                        <LACardTitle title="클래스 카테고리"/>
                        <LAProperty name="카테고리 추가" required>
                            <LAGridContainer layouts={{ flexWrap: "nowrap", alignItems: "flex-end"}}>
                                <LAGridContainer>
                                    <LAButton wPercent={15} variant="contained" layouts={{ p: 1 }} props={{ name: "CATEGORY" }} onClick={(e) => onAddCate(e)}>클래스 카테고리 추가</LAButton>
                                    <LAButton wPercent={15} variant="contained" layouts={{ p: 1 }} props={{ name: "KEYWORD" }} onClick={(e) => onAddCate(e)}>키워드 카테고리 추가</LAButton>
                                </LAGridContainer>
                                <LAWarningLabel wPercent={15} layouts={{ display: "flex" }}>최대 10개까지 등록 가능합니다.</LAWarningLabel>
                            </LAGridContainer>
                            {selectedCate.length > 0 && (
                                <LAGridContainer layouts={{ pt: 1 }}>
                                    <LASimpleTable tableData={categoryTableData} />
                                </LAGridContainer>
                            )}
                        </LAProperty>
                    </LACard>
                )}
                <LAGridContainer layouts={{ pt: 3 }}>
                    <LALabel wPercent={35}></LALabel>
                    <LAButton wPercent={15} layouts={{ mr: 1 }} variant={'outlined'} btnPaddingY={12} onClick={handleClose}>목록</LAButton>
                    <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onRegistClick}>저장</LAButton>
                    <LALabel wPercent={35}></LALabel>
                </LAGridContainer>
            </LAModal>

            {classSearchModal.state &&
              <EventClassSearchModal
                groupSeq={groupId}
                show={classSearchModal.state}
                handleClose={onSearchClassClose}
                handleClassSelect={onClassSelectClick}
                isSelectedClass={isSelectedClass}
              />}
            {registCate.state && <CategoryRegistModal type={cateType} show={registCate.state} handleClose={registCateModalClose} onCateRegist={handleCateList} />}
        </>
        
    );
}

export default EventRegistModal;
