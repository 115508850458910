import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';

import InquireAnswer from './InquiryAnswer';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  useInput,
} from 'modules/Widgets';

import moment from 'moment';

function TeacherDetail({ inquirySeq, onClickList }) {
  const { isLogin, loginState, action } = useLoginContext();
  const navigate = useNavigate();

  const { showAlert, showConfirm } = usePopAlert();

  const { queries } = useApiContext();
  //   const { inquirySeq } = useParams();
  const [data, setData] = useState(null);

  const answerInput = useInput('');

  const navigateBack = () => {
    navigate(-1, { replace: true });
  };
  const { result: resultData, request: requestDetail } = useCommonQuery({
    query: queries.Inquire.getInquireDetail,
    params: inquirySeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setData(data.result_data);
        } else {
          showAlert('알림', data.result_message, {
            confirmHandler: () => onClickList(),
          });
        }
      },
      onError: (error) => {
      },
    },
    initEnabled: false,
  });

  const { request: registRegist } = useCommonMutation({
    query: queries.Inquire.registAnswer,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '문의 답글이 등록되었습니다.', {
            confirmHandler: () => {
              answerInput.update('');
              requestDetail();
            },
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '문의 답글 등록에 실패했습니다.');
      },
    },
  });

  const { request: deleteAnswer } = useCommonMutation({
    query: queries.Inquire.deleteAnswer,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '문의 답글 노출 상태가 변경되었습니다.', {
            confirmHandler: () => requestDetail(),
            // cancelHandler: () => navigate(-1),
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '문의 답글 노출 상태 변경에 실패했습니다.');
      },
    },
  });

  const { request: modifyAnswer } = useCommonMutation({
    query: queries.Inquire.modifyAnswer,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '문의 답글이 수정되었습니다.', {
            confirmHandler: () => requestDetail(),
            // cancelHandler: () => navigate(-1),
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '문의 답글 수정에 실패했습니다.');
      },
    },
  });

  const onRegistAnswerClick = useCallback(() => {
    registRegist({
      inquirySeq: inquirySeq,
      data: { answer: answerInput.state },
    });
  }, [answerInput.state]);

  const onModifyClick = useCallback((answer_seq, newInput) => {
    showConfirm('확인', '문의 답변을 수정 하시겠습니까?', {
      confirmHandler: () => {
        modifyAnswer({
          inquirySeq: inquirySeq,
          answerSeq: answer_seq,
          data: { answer: newInput },
        });
      },
    });
  });

  const onDeleteClick = useCallback((answer_seq, delete_yn) => {
    let newDeleteYn = 1;
    let message = '문의 답변을 미노출로 변경하시겠습니까?';
    if (delete_yn == 1) {
      newDeleteYn = 0;
      message = '문의 답변을 노출로 변경하시겠습니까?';
    }
    showConfirm('확인', message, {
      confirmHandler: () => {
        deleteAnswer({
          inquirySeq: inquirySeq,
          answerSeq: answer_seq,
          delete_yn: newDeleteYn,
        });
      },
    });
  });

  useEffect(() => {
    if (!isLogin) {
      alert('로그인 후 이용할 수 있습니다.');
      navigate('/');
    }
    requestDetail();
  }, []);

  return (
    <>
      {/* Start Info Area */}
      <LACard>
        <LACardTitle title="클래스 정보" />
        <LAProperty name="클래스 유형">
          <LALabel wPercent={40}>{!!data && data.class_type_name}</LALabel>
        </LAProperty>
        <LAProperty name="선생님 명">
          <LALabel wPercent={40}>{!!data && data.teacher_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 명">
          <LALabel wPercent={40}>{!!data && data.class_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 회차">
          <LALabel wPercent={40}>{!!data && data.curriculum_count}회</LALabel>
        </LAProperty>
      </LACard>
      {/* End Info Area */}

      {/* Start Detail Info Area */}
      <LACard layouts={{ pt: 1 }}>
        <LACardTitle title="클래스 문의 정보" />
        {/* <LAProperty name="아이디">
          <LALabel wPercent={40} clickable="true" content={`woongjin`}>
            woog***
          </LALabel>
        </LAProperty> */}
        <LAProperty name="가입자">
          <LALabel wPercent={40}>{!!data && data.member_name}</LALabel>
        </LAProperty>
        <LAProperty name="제목">
          <LALabel wPercent={40}>{!!data && data.subject}</LALabel>
        </LAProperty>
        <LAProperty name="내용">
          <LALabel wPercent={100}>{!!data && data.content}</LALabel>
        </LAProperty>
        <LAProperty name="작성일">
          <LALabel wPercent={40}>
            {!!data &&
              moment(data.created_dt).format('YYYY-MM-DD (dd) A hh:mm')}
          </LALabel>
        </LAProperty>
      </LACard>

      {!!data && data.answers && data.answers.length > 0 ? (
        data.answers.map((answer) => {
          return (
            <LACard next>
              <LACardTitle title="문의 답변" />
              <InquireAnswer
                answer={answer}
                onModify={onModifyClick}
                onDelete={onDeleteClick}
              />
            </LACard>
          );
        })
      ) : (
        <>
          <LACard layouts={{ pt: 1 }}>
            <LACardTitle title="문의 답변 작성" />
            <LAProperty name="작성자">
              <LALabel
                wPercent={40}
                clickable
                handleClick={() => console.log('click')}
              >
                {loginState.member_name}
              </LALabel>
            </LAProperty>
            <LAProperty name="권한">
              <LALabel wPercent={40}>
                {loginState.auth.member_type_name}
              </LALabel>
            </LAProperty>
            <LAProperty name="내용" required>
              <LAInput
                wPercent={100}
                placeholder="답글을 입력하세요."
                value={answerInput.state}
                onChange={answerInput.handleChange}
              />
            </LAProperty>
            <LAProperty name="작성일">
              <LALabel wPercent={40}>
                {moment().format('YYYY-MM-DD (dd) A hh:mm')}
              </LALabel>
            </LAProperty>
          </LACard>
        </>
      )}
      {/* End Detail Info Area */}

      <LAGridContainer layouts={{ pt: { sm: 1 } }}>
        <LALabel wPercent={39}></LALabel>
        <LAButton wPercent={10} variant={'outlined'} onClick={onClickList}>
          목록
        </LAButton>
        {!!data && data.answers && data.answers.length > 0 ? (
          ''
        ) : (
          <LAButton
            wPercent={10}
            layouts={{ ml: { sm: 1 } }}
            onClick={onRegistAnswerClick}
          >
            저장
          </LAButton>
        )}
      </LAGridContainer>
    </>
  );
}

export default TeacherDetail;
