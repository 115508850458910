import { METHOD } from "common/hooks/useAxios";

const baseURL = "/admin/download-history";

const DownloadApi = (requestFunc) => {
  return {
    Download: {
      downloadList: {
        key: "downloadList",
        queryFn: (key) => requestFunc(METHOD.GET, `${baseURL}?type=${key}`),
      },
    },
  };
};

export default DownloadApi;
