import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/magazines';
const baseMainURL = '/admin/main/magazines';

const MagazineApi = (requestFunc) => {
  return {
    Magazine: {
      getMagazines: {
        key: 'getMagazines',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      getExposuredMagazines: {
        key: 'getExposuredMagazines',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/exposured`, params),
      },
      getOnlyExposuredMagazines: {
        key: 'getOnlyExposuredMagazines',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/onlyexposured`, params),
      },
      registMagazine: {
        key: 'registMagazine',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
      },
      getMagazineInfo: {
        key: 'getMagazineInfo',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.magazine_seq}`),
      },
      updateMagazine: {
        key: 'updateMagazine',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.magazine_seq}`, params.data),
      },
      deleteMagazine: {
        key: 'deleteMagazine',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.magazine_seq}`),
      },
      getMagazineClasses: {
        key: 'getMagazineClasses',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.magazine_seq}/classes`),
      },

      getMainMagazines: {
        key: 'getMainMagazines',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseMainURL}`, params),
      },
      modityMainMagazines: {
        key: 'modityMainMagazines',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseMainURL}`, params),
      }

    },
  };
};

export default MagazineApi;
