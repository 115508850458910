import React, {useMemo} from 'react';
import Modal from '@mui/material/Modal';
import { 
  LAModalTitle,
  LACardTitle,
  LALabel,
  LAGridContainer,
  LAGridItem,
  LAButton
 } from 'modules/Widgets';
import MDBox from 'components/MDBox';

function LAModal({
    title = 'title',
    show,
    width = '50%',
    height = '50%',
    onClose,
    children,
}) {

    const style = useMemo(() => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { width },
        height: { height },
        bgcolor: '#eeeeee',
        border: '1px solid #dddddd',
        borderRadius: 3,
        boxShadow: 24,
        paddingBottom: 2,
    }), [width, height]);

    const getCloseButton = (() => {
        return (
            <LAGridContainer>
                <LAGridItem wPercent={85}>
                    <LALabel>&nbsp;</LALabel>
                </LAGridItem>
                <LAGridItem wPercent={15}>
                    <LAButton onClick={onClose} sm={2}>닫기</LAButton>
                </LAGridItem>
            </LAGridContainer>
        )
    });

    return (
        <Modal open={show}>
            <MDBox p={1} sx={style}>
            {title ? (
                <>
                    <LAModalTitle layouts={{position:'static', display:''}}>
                        <LACardTitle title={title} showDivider={false} customUi={getCloseButton()}/>
                    </LAModalTitle> 
                    <MDBox p={3} sx={{overflow:'auto', height:'90%'}}>{children}</MDBox>
                </>
            ) : (<MDBox p={3} sx={{overflow:'auto', height:'100%'}}>{children}</MDBox>)}
            </MDBox>
        </Modal>
    );
}

export default React.memo(LAModal);
