import React, { useState, useCallback, useEffect, useMemo } from 'react';
// import LALoading from 'modules/Widgets/LALoading';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
// import { getTodayDateText, dateTimeToString } from 'common/utils';
// import { useNavigate, useParams } from 'react-router';
import {
    getTextCell,
    getNormalButton,
    // getPrimaryButton,
    LAModal,
    LACard,
    LACardTitle,
    LAGridContainer,
    LAProperty,
    LALabel,
    // LARadioGroup,
    LAButton,
    LAComboBox,
    LAInput,
    LADataTable,
    // useRadioGroup,
    // LATabSelector,
    // LADatePicker,
    // useTabSelector,
    // useDatePicker,
    useInput,
    useComboBox,
    LADivider,
} from 'modules/Widgets';

const columns = [
    { Header: 'No', accessor: 'no', width: '10%' },
    { Header: '코드번호', accessor: 'code', width: '20%' },
    { Header: '교재명', accessor: 'name', width: '40%' },
    { Header: '금액', accessor: 'price', width: '20%' },
    { Header: '선택', accessor: 'select', width: '10%' },
];

// const rows = [
//   {
//     id: getTextCell('3'),
//     code: getTextCell('코드번호'),
//     name: getTextCell('교재명교재명'),
//     price: getTextCell('15,000원'),
//     select: getPrimaryButton('선택'),
//   },
//   {
//     id: getTextCell('3'),
//     code: getTextCell('코드번호'),
//     name: getTextCell('교재명교재명'),
//     price: getTextCell('15,000원'),
//     select: getPrimaryButton('선택'),
//   },
//   {
//     id: getTextCell('3'),
//     code: getTextCell('코드번호'),
//     name: getTextCell('교재명교재명'),
//     price: getTextCell('15,000원'),
//     select: getPrimaryButton('선택'),
//   },
// ];

function TextbookSearchModal({ show, handleClose, handleSelect }) {
    const { queries } = useApiContext();
    const { showAlert } = usePopAlert();
    const searchType = useComboBox(1);
    const searchInput = useInput('');
    const [rows, setRows] = useState([]);

    const onTextbookSelect = (
        (data) => {
            handleSelect(data);
        }
    );

    const dataToRow = (data, index) => {
        return {
            no: getTextCell(`${index + 1}`),
            code: getTextCell(`${data.material_no ? data.material_no : ''}`),
            name: getTextCell(`${data.material_name ? data.material_name : ''}`),
            price: getTextCell(`${data.price ? data.price : ''}`),
            select: getNormalButton('선택', data, onTextbookSelect),
        };
    };

    const { request: requestTextbooks } = useCommonQuery({
        query: queries.Code.materials,
        params: {
          query_type: searchType.state,
          query: searchInput.state,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setRows(data.result_data ? data.result_data.map((resultData, index) => dataToRow(resultData, index)): []);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '교재 목록 조회에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    const onSearchClick = useCallback(() => {
        requestTextbooks();
    }, [requestTextbooks]);

    const onInitClick = useCallback(() => {
        searchType.reset();
        searchInput.reset();
    }, [searchType.reset, searchInput.reset]);

    return (
        <LAModal title="" width="70%" height="90%" show={show}>
            <LACard>
                <LACardTitle title="교재 검색" />
                <LAProperty name="직접 검색" wNameWidth={16}>
                    <LAGridContainer>
                        <LAComboBox
                            wPercent={20}
                            items={[
                                { key: 1, value: '통합검색' },
                                { key: 2, value: '교재코드' },
                                { key: 3, value: '교재명' },
                            ]}
                            value={searchType.state}
                            onChange={searchType.handleChange}
                        />
                        <LAInput
                            wPercent={80}
                            layouts={{ pl: 1 }}
                            value={searchInput.state}
                            onChange={searchInput.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>

                <LAGridContainer pt={2}>
                    <LAButton wPercent={50} layouts={{ pr: { sm: 0.5 } }} onClick={onSearchClick}>검색</LAButton>
                    <LAButton wPercent={50} layouts={{ pl: { sm: 0.5 } }} onClick={onInitClick}>초기화</LAButton>
                </LAGridContainer>
                <LADivider pt={4} />
                <LADataTable columns={columns} rows={rows} />
            </LACard>
            <LAGridContainer pt={2}>
                <LALabel wPercent={80} />
                <LAButton wPercent={20} layouts={{ pr: { sm: 0.5 } }} onClick={handleClose}>닫기</LAButton>
            </LAGridContainer>
        </LAModal>
    );
}

export default React.memo(TextbookSearchModal);
