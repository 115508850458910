import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/user-memos';

const UserMemoApi = (requestFunc) => {
  return {
    UserMemo: {
      
      getList: {
        key: 'getMemoList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },
      regist :{
        key: 'registMemo',
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}`, data),
      },
      delete: {
        key: 'deleteMemo',
        queryFn: (memoSeq) => requestFunc(METHOD.DELETE, `${baseURL}/${memoSeq}`),
      },      
    },
  };
};

export default UserMemoApi;
