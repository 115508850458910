import { METHOD } from 'common/hooks/useAxios';

const baseMainURL = '/admin/main/class';
const baseMainExposuresURL = '/admin/main/exposures';

const MainClassApi = (requestFunc) => {
  return {
    MainClass: {
      getMainExposures: {
        key: 'getMainExposures',
        queryFn: () => requestFunc(METHOD.GET, `${baseMainURL}/exposures`),
      },
      updateMainExposure: {
        key: 'updateMainExposure',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainURL}/exposures/${params.type}`, params),
      },
      updateAllMainExposure: {
        key: 'updateAllMainExposure',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainExposuresURL}`, params),
      },
      getMainClasses: {
        key: 'getMainClasses',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseMainURL}`, params),
      },
      registMainClasses: {
        key: 'registMainClasses',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseMainURL}`, params),
      },
      updateMainClasses: {
        key: 'updateMainClasses',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseMainURL}/modify/${params.type}`, params),
      },
      deleteMainClasses: {
        key: 'deleteMainClasses',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseMainURL}/${params.banner_seq}`),
      },
    },
  };
};

export default MainClassApi;
