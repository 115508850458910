import React, { useState } from 'react';
import LAGridItem from './LAGridItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

const Placeholder = styled('div')({
  color: '#aaaaaa',
});

function LASelect({
  placeholder = '선택',
  value = '',
  onChange,
  items,
  layouts = {},
  wPercent = 100,
  readOnly = false,
  disabled = false,
}) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <FormControl fullWidth size="small">
        <Select
          value={value}
          // onChange={onChange}
          readOnly={ readOnly? 'readOnly' : null }
          disabled={ disabled? 'disabled' : null }
          sx={{ fontSize: 25 }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected) => {           
            if (!selected || selected == '' ) {
              return <Placeholder>{placeholder}</Placeholder>;
            }

            const filterItem = items.filter((item) => item.key == selected);
            if (filterItem.length > 0) {
              return filterItem[0].value;
            }
            
            return <Placeholder>{placeholder}</Placeholder>;
          }}
          MenuProps={{
            PaperProps: { //Menu컴포넌트의 Paper컴포넌트 속성 설정하는데 사용됨.(여기서는 화면출력 갯수 컨트롤 목적으로 사용함)
              style: {
                maxHeight: 350,
              },
            },
          }}
        >
          {!!items &&
            items.map((item) => (
              <MenuItem
                key={item.key}
                value={item.key}
                onClick={() => onChange(item)}
              >
                {item.value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </LAGridItem>
  );
}

export default React.memo(LASelect);
