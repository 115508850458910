import { METHOD } from 'common/hooks/useAxios';

const baseMainExhibitionURL = '/admin/main-exhibitions';
const baseMainExhibitionBannersURL = '/admin/main-exhibition-banners';
const MainExhibitionApi = (requestFunc) => {
  return {
    MainExhibition: {
      getMainExhibitions: {
        key: 'getMainExhibitions',
        queryFn: () => requestFunc(METHOD.GET, `${baseMainExhibitionURL}`),
      },
      updateMainExhibition: {
        key: 'updateMainExhibition',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainExhibitionURL}/${params.mainSeq}`, params),
      },
      updateMainExhibitionExposure: {
        key: 'updateMainExhibitionExposure',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainExhibitionURL}/${params.mainSeq}/exposure`, params),
      },
      updateAllMainExhibition: {
        key: 'updateAllMainExhibition',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainExhibitionURL}`, params),
      },
      getMainExhibitionClasses: {
        key: 'getMainClasses',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseMainExhibitionURL}/${params.mainSeq}/classes`),
      },
      // registMainExhibitionClasses: {
      //   key: 'registMainClasses',
      //   queryFn: (params) => requestFunc(METHOD.POST, `${baseMainExhibitionURL}/classes/${params.mainSeq}`, params),
      // },
      modifyMainExhibitionClasses: {
        key: 'updateMainClasses',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainExhibitionURL}/${params.mainSeq}/classes`, params),
      },
      // deleteMainClasses: {
      //   key: 'deleteMainClasses',
      //   queryFn: (params) => requestFunc(METHOD.DELETE, `${baseMainExhibitionURL}/classes/${params.mainSeq}`),
      // },
      getMainExhibitionBanners: {
        key: 'getMainExhibitionBanners',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseMainExhibitionURL}/${params.mainSeq}/banners`),
      },
      modifyMainExhibitionBanners: {
        key: 'modifyMainExhibitionBanners',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainExhibitionURL}/${params.mainSeq}/banners`, params),
      },
      getExhibitionBanners: {
        key: 'getExhibitionBanners',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseMainExhibitionBannersURL}/${params.bannerType}/banners`),
      },
      modifyExhibitionBanners: {
        key: 'modifyExhibitionBanners',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseMainExhibitionBannersURL}/${params.bannerType}/banners`, params),
      },
    },
  };
};

export default MainExhibitionApi;
