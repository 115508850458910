import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // css import

import moment from 'moment';

function LAClassCalendar({onSelected}) {
    const [value, setValue] = useState(new Date());
    const onChange = (info) => {
        if(onSelected) {
            onSelected(moment(info).format("YYYY-MM-DD"));
        }
    }
    return (
        <>
            <Calendar 
                calendarType='US'
                formatDay={(locale, date) => moment(date).format("DD")}
                locale="ko-KR"
                minDetail="month"
                maxDetail="month"
                navigationLabel={null}
                showNeighboringMonth={false} //  이전, 이후 달의 날짜는 보이지 않도록 설정
                className="mx-auto w-full text-sm"
                onChange={onChange} 
                value={value} />
        </>
    )
}

export default React.memo(LAClassCalendar);