import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/room';

const RoomApi = (requestFunc) => {
    return {
        Room: {
            recordList: {
                key: 'recordList',
                queryFn: (timetableSeq) => requestFunc(METHOD.GET, `${baseURL}/record/list/${timetableSeq}`),
            },
            downloadRecordFile: {
                key: 'downloadRecordFile',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/record/fileDown/${params.fileName}/${params.downFileName}`),
            },
            playRecordFile: {
                key: 'playRecordFile',
                queryFn: (fileName) => requestFunc(METHOD.GET, `${baseURL}/record/file/${fileName}`),
            },
            updateRoomStatus: {
                key: 'updateRoomStatus',
                queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}/status/${data.classroom_seq}?ts=${data.timetable_seq}`, data),
            },
        },
    };
};

export default RoomApi;
