import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import LARadioButton from './LARadioButton';
import LAGridItem from './LAGridItem';

function LARadioGroup({
  wPercent = 100,
  layouts = {},
  props,
  value,
  onChange,
  disabled = false,
  readOnly = false,
}) {
  //   props = [
  //     {
  //       text: 'text',
  //       value: 0,
  //     },
  //     {
  //       text: 'text',
  //       value: 1,
  //     },
  //   ];

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <FormControl disabled={disabled}>
        <RadioGroup
          row
          defaultValue={0}
          value={value}
          onChange={readOnly ? null : onChange}
        >
          {!!props &&
            props.map((prop, index) => {
              return (
                <LARadioButton
                  key={index}
                  text={prop.text}
                  value={prop.value}
                ></LARadioButton>
              );
            })}
        </RadioGroup>
      </FormControl>
    </LAGridItem>
  );
}

export default React.memo(LARadioGroup);
