import React, { forwardRef } from 'react';
import LAGridItem from './LAGridItem';

import MDButton from 'components/MDButton';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ko } from 'date-fns/esm/locale';

function LADatePickerButton({
  wPercent = 100,
  layouts = {},
  value,
  variant = 'contained',
  defaultValue,
  onChange,
  handleClick,
  children,
  props = {},
  minDate = '',
  maxDate = '',
  color = 'info',
}) {
  const CustomButton = forwardRef(({ value, onClick }, ref) => (
    <MDButton
      variant={variant}
      size="small"
      color={color}
      onClick={() => (handleClick ? handleClick(onClick) : onClick())}
      ref={ref}
      fullWidth
      {...props}
    >
      {children}
    </MDButton>
  ));

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        className="react-datepicker"
        locale={ko}
        value={value}
        selected={value ? moment(value).toDate() : new Date()}
        onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
        customInput={<CustomButton />}
        minDate={minDate ? moment(minDate).toDate() : ''}
        maxDate={maxDate ? moment(maxDate).toDate() : ''}
        showDisabledMonthNavigation
      />
    </LAGridItem>
  );
}

export default React.memo(LADatePickerButton);
