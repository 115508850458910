import React, { useCallback, useEffect, useState, useMemo } from "react";

import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { useLoginContext } from "common/context/MemberContext";
import { getTodayDateText } from "common/utils";
import CategoryComboView from "../components/CategoryComboView";
import { periodItems } from "common/constants";

import moment from 'moment';

import {
    LACard,
    LAGridContainer,
    LAProperty,
    LALabel,
    LAButton,
    LAComboBox,
    LACodeComboBox,
    LACodeRadioGroup,
    LAInput,
    LADatePicker,
    LATabSelector,
    LAAltRadioGroup,
    useRadioGroup,
    useComboBox,
    useInput,
    useModal,
    useDatePicker,
    useTabSelector,
    LADataTable2,
    getTextCell,
    getNormalButton,
} from "modules/Widgets";

import { useApiContext } from "../../../common/context/ApiContext";

import ClassDetailModal from "../components/ClassDetailModal";
import ClassRegistModal from "../components/ClassRegistModal";
import CurriculumModal from "../components/CurriculumModal";

function getAgeItems() {
    const result = [];
    for (let i = 0; i < 20; i++) {
        result.push({ key: i + 1, value: `${i + 1}세` });
    }
    return result;
}

const targetAgeItems = getAgeItems();

export default function ClassSearch() {
    const { isLogin, isTeacher, isAlliance, loginState, action } = useLoginContext();
    const [selectedClassSeq, setSelectedClassSeq] = useState(0);
    const classDetailModal = useModal(false);
    const classRegistModal = useModal(false);

    const todayDateText = getTodayDateText("-");
    const [category, setCategory] = useState(0);
    const classType = useRadioGroup(-1);
    const ageType = useRadioGroup(-1);
    const minTargetAge = useComboBox(1);
    const maxTargetAge = useComboBox(1);
    const worksheetType = useRadioGroup(-1);
    const activateType = useRadioGroup(-1);
    const searchDateType = useComboBox(1);
    const searchPeriodType = useTabSelector(3);
    const searchStartDate = useDatePicker(todayDateText);
    const searchEndDate = useDatePicker(todayDateText);
    const searchTargetType = useComboBox(1);
    const searchText = useInput("");
    const testModeType = useRadioGroup("");
    const operateType = useRadioGroup("");

    const [rows, setRows] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [page, setPage] = useState(0); // 마지막 페이지네이션

    const { queries } = useApiContext();
    const [curriculumShow, setCurriculumShow] = useState(false);

    const priceSplitter = useCallback((number) => number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),[]);

    const authMemberType = useMemo(() => {
        return action.getMemberType();
    }, [action]);

    const onCategoryChanged = useCallback((item) => {
        setCategory(item);
    }, []);

    const getClassType = useCallback((item) => {
        let result;
        switch(item.progress_type){
            case 0:
                result = '원데이'
                break;
            case 1:
                result = '순환'
                break;
            case 2:
                result = '패키지'
                break;
        }

        if(item.test_mode_yn){
            result += '[시크릿]'
        }
        return result
    },[]);

    const dataToRow = (item, index) => {
        return {
            class_seq: getTextCell(`${item.class_seq}`),
            class_name: getTextCell(`${item.class_name}`, `${item.class_seq}`, onClickDetail),
            category: getTextCell(`${item.category_name}`),
            class_type: getTextCell(getClassType(item)),
            brand_type: getTextCell(`${item.brand_type_name ? item.brand_type_name : ''}`),
            children_count: getTextCell(`${item.min_user_count}~${item.max_user_count}`),
            textbook_delivery_type: getTextCell(`${item.textbook_delivery_type > 0 ? 'Y' : 'N'}`),
            file_count: getTextCell(`${item.file_count > 0 ? 'Y' : 'N'}`),
            price: getTextCell(`${priceSplitter(item.price)}`),
            discount_price: getTextCell(`${priceSplitter(item.discount_price)}`),
            recommended_age: getTextCell(
                item.all_age_yn === 1 ?
                `전연령`
                : item.all_age_yn === 2 ?
                    `학부모`
                : `${item.min_target_age}~${item.max_target_age}`,
            ),
            // curriculum_times: <Link onClick={onShowCurriculumClick} to=""> {getTextCell(`${item.times}`)}</Link>,
            curriculum_times: getTextCell(`${item.times}`),
            created_dt: getTextCell(`${moment(item.created_dt).format('YYYY-MM-DD (dd)')}`),
            // start_dt: getTextCell(`${item.start_dt?moment(item.start_dt).format('YYYY-MM-DD (dd)'):''}`),
            schedule_count: getTextCell(`${item.schedule_count}`),
            view_count: getTextCell(`${item.view_count}`),
            activate_yn: getTextCell(`${item.activate_yn ? 'Y' : 'N'}`),
            teacher: getTextCell(`${item.teacher_name ? item.teacher_name : ''}`),
            admin_name: getTextCell(`${item.admin_name ? item.admin_name : ''}`),
            // creator:getTextCell(`${item.creator_name?item.creator_name:''}`),
            // modifier:getTextCell(`${item.modifier_name?item.modifier_name:''}`),
            detail: getNormalButton('상세', `${item.class_seq}`,onClickDetail),
        };
    }
    
    useEffect(() => {
        searchStartDate.selectByPeriod(searchPeriodType.state);
        searchEndDate.reset(searchPeriodType.state);
    }, [searchPeriodType.state, searchEndDate.reset]);

    useEffect(() => {
        if (page != 0) {
            setTablePage(page);
        }
    }, [rows]);

    const [searchParams, setSearchParams] = useState({});

    const onSearchClick = useCallback(() => {
        let searchParams = {
            category_seq: category ? category.key : undefined,
            class_type: classType.state < 0 ? undefined : classType.state,
            test_mode_yn: testModeType.state < 0 ? undefined : testModeType.state,
            operate_type: operateType.state < 0 ? undefined : operateType.state,
            all_age_yn: ageType.state,
            min_target_age: ageType.state === "0" ? minTargetAge.state : undefined,
            max_target_age: ageType.state === "0" ? maxTargetAge.state : undefined,
            worksheet_yn: worksheetType.state < 0 ? undefined : worksheetType.state,
            activate_yn: activateType.state < 0 ? undefined : activateType.state == 1,
            period_type: searchDateType.state,
            start_dt: searchStartDate.state,
            end_dt: searchEndDate.state,
            confirm_yn: undefined,
            query_type: searchTargetType.state,
            query: searchText.state,
        };

        setSearchParams(searchParams);

    }, [
        category,
        classType.state,
        testModeType.state,
        operateType.state,
        ageType.state,
        worksheetType.state,
        activateType.state,
        searchDateType.state,
        searchStartDate.state,
        searchEndDate.state,
        searchTargetType.state,
        searchText.state,
        searchParams.state
    ]);


    const onEnterKeyAtSearch = useCallback(() => {
        onSearchClick();
    });

    useEffect(() => {
        if (searchStartDate.state != searchEndDate.state) {
            onSearchClick();
        }
    }, [searchStartDate.state, searchEndDate.state]);

    const onInitClick = useCallback(() => {
        classType.reset();
        ageType.reset();
        minTargetAge.reset();
        maxTargetAge.reset();
        worksheetType.reset();
        activateType.reset();
        searchDateType.reset();
        searchPeriodType.reset();
        searchStartDate.reset();
        searchEndDate.reset();
        searchTargetType.reset();
        searchText.reset();
        testModeType.reset();
        operateType.reset();
    }, [
        classType.reset,
        ageType.reset,
        minTargetAge.reset,
        maxTargetAge.reset,
        worksheetType.reset,
        activateType.reset,
        searchDateType.reset,
        searchPeriodType.reset,
        searchStartDate.reset,
        searchEndDate.reset,
        searchTargetType.reset,
        searchText.reset,
        testModeType.reset,
        operateType.reset,
    ]);

    const columns = useMemo(() => {
        return [
            { Header: '클래스번호', accessor: 'class_seq', width: '5%', align: 'center'},
            { Header: '카테고리', accessor: 'category', align: 'left'},
            { Header: '유형', accessor: 'class_type', align: 'center', width: '3%'},
            { Header: '운영방식', accessor: 'brand_type', align: 'center'},
            { Header: '클래스명', accessor: 'class_name', align: 'left', width: '15%'},
            { Header: '회차', accessor: 'curriculum_times', align: 'center', width: '3%'},
            { Header: '대상연령', accessor: 'recommended_age', align: 'center' },
            { Header: '교재', accessor: 'textbook_delivery_type', align: 'center', width: '3%',},
            { Header: '자료', accessor: 'file_count', align: 'center', width: '3%',},
            { Header: '정원', accessor: 'children_count', align: 'center' },
            { Header: '정상가', accessor: 'price', align: 'center' },
            { Header: '할인가', accessor: 'discount_price', align: 'center' },
            // { Header: "수업 시작일", accessor: "start_dt", align: "center" },
            { Header: '일정 수', accessor: 'schedule_count', align: 'center' },
            // { Header: "확정", accessor: "confirm_yn", align: "center" },
            // { Header: "폐강", accessor: "close_yn", align: "center" },
            { Header: '선생님', accessor: 'teacher', align: 'center' },
            { Header: '담당MD', accessor: 'admin_name', align: 'center' },
            { Header: '등록일', accessor: 'created_dt', align: 'center' },
            // { Header: "등록자", accessor: "creator", align: "center" },
            // { Header: "수정자", accessor: "modifier", align: "center" },
            { Header: '조회수', accessor: 'view_count', align: 'center', width: '3%' },
            { Header: '노출', accessor: 'activate_yn', align: 'center', width: '3%' },
            { Header: '상세', accessor: 'detail', width: '5%' },
            // { Header: '일정변경', accessor: 'edit_schedule', width: '5%' },
        ];
    }, []);

    const onExcelDownloadClick = useCallback(() => {}, []);


    const onClickRegist = useCallback(() => {
        classRegistModal.visible(true);
    });

    const onClickDetail = useCallback((class_seq) => {
        setSelectedClassSeq(class_seq);
        classDetailModal.visible(true);
    }, []);

    const onRegistered = useCallback(() => {
        classRegistModal.handleClose();
        onSearchClick();
        onInitClick();
    });

    const onDetailClosed = useCallback(() => {
        classDetailModal.handleClose();
    });

    const onChangeTablePage = useCallback((page) => {
        setPage(page);
    });


    return (
        <>
        <DashboardNavbar title="클래스 개설 및 등록 관리" />
            <LACard>
                {authMemberType === 1 ? (
                    <LAProperty name="카테고리">
                        <LAGridContainer>
                            <CategoryComboView
                                value={category}
                                onChange={onCategoryChanged}
                            />
                        </LAGridContainer>
                    </LAProperty>
                ) : (<></>)}

                {authMemberType === 1 ? (
                    <LAProperty name="클래스 유형">
                        <LACodeRadioGroup
                            codeType="CLASS_TYPE"
                            showAll={true}
                            value={classType.state}
                            onChange={classType.handleChange}
                        />
                    </LAProperty>
                ) : (<></>)}

                {authMemberType === 1 ? (
                    <LAProperty name="클래스 유형">
                        <LAAltRadioGroup
                            showAll={true}
                            positiveLabel={"시크릿 포함"}
                            positiveValue={1}
                            negativeLabel={"시크릿 제외"}
                            negativeValue={0}
                            value={testModeType.state}
                            onChange={testModeType.handleChange}
                        />
                    </LAProperty>
                ) : (<></>)}

                <LAProperty name="운영 방식">
                    <LAAltRadioGroup
                        showAll={true}
                        positiveLabel={"자사"}
                        positiveValue={1}
                        negativeLabel={"제휴"}
                        negativeValue={2}
                        value={operateType.state}
                        onChange={operateType.handleChange}
                    />
                </LAProperty>

                <LAProperty name="대상 연령">
                    <LAGridContainer>
                        <LACodeRadioGroup
                            codeType="TARGET_AGE_QUERY_TYPE"
                            // showAll={true}
                            value={ageType.state}
                            onChange={ageType.handleChange}
                        />
                        {ageType.state === "0" && (
                            <LAGridContainer>
                                <LAComboBox
                                    wPercent={20}
                                    items={targetAgeItems}
                                    layouts={{ pr: { sm: 1 } }}
                                    value={minTargetAge.state}
                                    onChange={minTargetAge.handleChange}
                                />
                                <LALabel wPercent={5} layouts={{ pr: { sm: 1 } }}>~</LALabel>
                                <LAComboBox
                                    wPercent={20}
                                    items={targetAgeItems}
                                    value={maxTargetAge.state}
                                    onChange={maxTargetAge.handleChange}
                                />
                            </LAGridContainer>
                        )}
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="교재 여부">
                    <LACodeRadioGroup
                        codeType="WORKSHEET_TYPE"
                        showAll={true}
                        positiveLabel={"있음"}
                        positiveValue={1}
                        negativeLabel={"없음"}
                        negativeValue={2}
                        value={worksheetType.state}
                        onChange={worksheetType.handleChange}
                    />
                </LAProperty>

                <LAProperty name="노출 여부">
                    <LACodeRadioGroup
                        codeType="EXPOSURE_TYPE"
                        showAll={true}
                        value={activateType.state}
                        onChange={activateType.handleChange}
                    />
                </LAProperty>

                <LAProperty name="조회 기간">
                    <LAGridContainer vAlign="center">
                        <LACodeComboBox
                            wPercent={12}
                            codeType="QUERY_PERIOD_TYPE"
                            value={searchDateType.state}
                            onChange={searchDateType.handleChange}
                        />
                        <LATabSelector
                            wPercent={46}
                            layouts={{ pl: { sm: 1 } }}
                            items={periodItems}
                            value={searchPeriodType.state}
                            onChange={searchPeriodType.handleChange}
                        />
                        <LADatePicker
                            wPercent={20}
                            layouts={{ pl: { sm: 1 } }}
                            value={searchStartDate.state}
                            onChange={searchStartDate.handleChange}
                        />
                        <LALabel wPercent={2}>~</LALabel>
                        <LADatePicker
                            wPercent={20}
                            value={searchEndDate.state}
                            onChange={searchEndDate.handleChange}
                        />
                    </LAGridContainer>
                </LAProperty>

                <LAProperty name="직접 검색">
                    <LAGridContainer>
                        <LACodeComboBox
                            wPercent={12}
                            codeType="CLASS_QUERY_TYPE"
                            value={searchTargetType.state}
                            onChange={searchTargetType.handleChange}
                        />
                        <LAInput
                            placeholder="검색어 입력"
                            wPercent={46}
                            layouts={{ pl: { sm: 1 } }}
                            value={searchText.state}
                            onChange={searchText.handleChange}
                            onEnterKeyPressed={onEnterKeyAtSearch}
                        />
                    </LAGridContainer>
                </LAProperty>

                <LAGridContainer pt={2}>
                    <LALabel wPercent="27" />
                    <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onSearchClick}>검색</LAButton>
                    <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onInitClick} variant={"outlined"}>초기화</LAButton>
                    {!isTeacher && !isAlliance ? (
                        <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }} onClick={onClickRegist}>개설하기</LAButton>
                    ) : (<></>)}
                </LAGridContainer>
            </LACard>

            <LACard layouts={{ pt: { sm: 1 } }}>
                <LAGridContainer>
                    <LADataTable2
                        columns={columns}
                        loadUrl={queries.Class.getList}
                        params={searchParams}
                        dataToRow={dataToRow}
                        defaultPage={tablePage}
                        onChangePage={onChangeTablePage}
                    />
                </LAGridContainer>
            </LACard>

            <CurriculumModal
                show={curriculumShow}
                handleClose={() => setCurriculumShow(false)}
            />

            <ClassDetailModal
                classSeq={selectedClassSeq}
                show={classDetailModal.state}
                handleClose={onDetailClosed}
            />

            <ClassRegistModal
                show={classRegistModal.state}
                handleClose={onRegistered}
            />
        </>
    );
}
