import { METHOD } from "common/hooks/useAxios";

const baseURL = "/admin/classrooms";

const ClassroomApi = (requestFunc) => {
  return {
    Classroom: {
      /**
       * 수업 종료 처리
       */
      done: {
        key: "classroomDone",
        queryFn: (params) =>
          requestFunc(
            METHOD.PATCH,
            `${baseURL}/${params.classroomSeq}/done`,
            params.data
          ),
      },

      /**
       * 과목 종료 처리
       */
      finish: {
        key: "classroomFinish",
        queryFn: (classroomSeq) =>
          requestFunc(METHOD.PATCH, `${baseURL}/${classroomSeq}/finish`),
      },

      /**
       * 수업별 학습결과 목록
       */
      getAttendances: {
        key: "getAttendances",
        queryFn: (classroomSeq) =>
          requestFunc(METHOD.GET, `${baseURL}/${classroomSeq}/attendances`),
      },
      /**
       * 수업별 학습자 목록
       */
      getClassmates: {
        key: "getClassmates",
        queryFn: (classroomSeq) =>
          requestFunc(METHOD.GET, `${baseURL}/${classroomSeq}/classmates`),
      },
      /**
       * 수업별 학습자 목록
       */
      enterClassRoom: {
        key: "enterClassRoom",
        queryFn: (params) =>
          requestFunc(
            METHOD.PATCH,
            `${baseURL}/${params.classroomSeq}/enter-room`,
            params.data
          ),
      },

      getList: {
        key: "getClassroomList",
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },

      getDetail: {
        key: "getClassroomDetail",
        queryFn: (classroomSeq) =>
          requestFunc(METHOD.GET, `${baseURL}/${classroomSeq}`),
      },

      getClassmateInfo: {
        key: "getClassmateInfo",
        queryFn: (params) =>
          requestFunc(
            METHOD.GET,
            `${baseURL}/${params.classroomSeq}/classmates/${params.classmateSeq}`
          ),
      },
      regist: {
        key: "registFeedback",
        queryFn: (params) =>
          requestFunc(
            METHOD.POST,
            `${baseURL}/${params.classroomSeq}/feedbacks`,
            params.data
          ),
      },
      registBatch: {
        key: "registFeedbackBatch",
        queryFn: (params) =>
          requestFunc(
            METHOD.POST,
            `${baseURL}/${params.classroomSeq}/feedbacks/batch`,
            params.data
          ),
      },

      registBatchOthers: {
        key: "registFeedbackBatchOthers",
        queryFn: (params) =>
          requestFunc(METHOD.POST, `/admin/feedbacks/batchothers`, params.data),
      },
      // getFeedbacks : {
      //   key: 'getClassmateFeedbacks',
      //   queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.classroomSeq}/feedbacks`, params),
      // },
    },
  };
};

export default ClassroomApi;
