import { useState, useCallback, useReducer } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'VISIBLE':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useModalPopup(initData = false, processCallback) {
  const [ state, dispatch ] = useReducer(reducer, initData);
  const [ dataChangeState, setDataChangeState ] = useState(false);
  const mainProcessCallback = processCallback;

  const show = useCallback(
    () => {
      dispatch({
        type: 'VISIBLE',
        value: true,
      });
    },
    [state],
  );

  const hide = useCallback(
    () => {
      dispatch({
        type: 'VISIBLE',
        value: false,
      });
    },
    [state],
  );

  const setVisible = useCallback(
    (value) => {
      if (state === value) {
        return;
      }

      dispatch({
        type: 'VISIBLE',
        value,
      });
    },
    [state],
  );

  return {
    isVisible: state,
    dataChangeState,
    setDataChangeState,
    show,
    hide,
    setVisible,
    processCallback: mainProcessCallback
  };
}

export default useModalPopup;
