import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LAWarningLabel,
  LASimpleTable,
  LARadioGroup,
  useInput, 
  useModal,
  useRadioGroup
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';


import MainContentMagazineTable from './MainContentMagazineTable';
import MainContentBannerTable from './MainContentBannerTable';
import MainContentClassTable from './MainContentClassTable';

import AddClassListModal from './AddClassListModal';
// import AddBannerListModal from './AddBannerListModal';
import AddMagazineModal from './AddMagazineModal';
import AddBannerModal from './AddBannerModal';
import MainContentVideo from './MainContentVideo';
import {sendCacheRefresh} from "../../../../common/utils";

function MainContentDetailModal({ show, handleClose, data , displayTitle}) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const [bannerType, setBannerType] = useState(3);  
  const [contentItems, setContentItems] = useState([]);
  
  const [videoInfo, setVideoInfo] = useState([]);

  const title = useInput('');
  const subTitle = useInput('');  
  const link = useInput('');
  const backgroundColor = useInput('#000000');

  const addBannerModal = useModal(false);
  const addClassModal = useModal(false);
  const addMagazineModal = useModal(false);
  const addVideoModal = useModal(false);

  console.log('data=>',data)

  const getTitle = useMemo(() => {
    switch(data?.main_type){
      case 1:
        return displayTitle?displayTitle:"중간 띠배너 전시관리"
      case 2:
        return "메인 매거진 전시관리"
      case 3:
        return "메인 클래스 전시관리"
      case 4:
        return "메인 영상배너 전시관리"
      case 5:
        return "메인 키워드 전시관리"        
      default:
        return "메인 전시관리"        
    }
  })
  const isShowTitle = useMemo(() => {
    if(data?.main_type == 3)return true
    else return false
  })
  const isShowSubTitle = useMemo(() => {
    if(data?.main_type == 3)return true
    else return false
  })

  const isShowLink = useMemo(() => {
    if(data?.main_type == 3)return true
    else return false
  })
  const isShowBackground = useMemo(() => {
    if(data?.main_type != 1)return true
    else return false
  })

  const isShowAdd = useMemo(() => {
    if(data?.main_type != 4)return true
    else return false
  })
  const getBackgroundGuideText = useMemo(() =>{
    if(data?.main_type === 1){
      return '배경색은 배너 이미지 외 공간 색을 헥사코드로 입력하세요.'
    }else {
      return '배경색은 헥사코드로 입력하세요'
    }
  })

  const addItemCallback = useCallback((item) => {
    console.log('addItemCallback.item => ',item);
    let isNew = !contentItems.some((oItem) => {
      return ((data?.main_type == 1 && oItem.banner_seq == item.banner_seq)
      || (data?.main_type == 2 && oItem.magazine_seq == item.magazine_seq)
      || (data?.main_type == 3 && oItem.class_seq == item.class_seq))
    })

    // let isNew = !contentItems.some((oItem) => (data?.main_type == 2 && oItem.magazine_seq == item.magazine_seq))
    console.log('MainContentDetailModal isNew => ',isNew)
    if(isNew) {
      let newItems = JSON.parse(JSON.stringify(contentItems));
      // let newItems = [...contentItems]
      newItems.push(item);
      console.log('MainContentDetailModal newItems => ',newItems)
      // setContentItems([...newItems]);
      setContentItems(newItems)
    }
  }, [contentItems]);

  const deleteItemCallback = useCallback((exposureNo,seq) => {
    console.log(`Delete : {exposureNo:${exposureNo},seq:${seq} `);    
    let newItems = contentItems.slice(0, exposureNo-1);
    console.log(newItems);
    contentItems.forEach((item, index) => {
      if(index > exposureNo-1) {
        newItems.push(item);
      }
    });
    
    setContentItems([...newItems]);

  }, [contentItems]);

  const moveUpCallback = useCallback((exposureNo) => {
    console.log("moveUp => exposureNo : ", exposureNo);
    if(exposureNo == 1) return;

    let newItems;
    if(exposureNo < 3)
      newItems = [];
    else 
      newItems = contentItems.slice(0, exposureNo-2);

      console.log("moveUp => newItems : ",newItems);
    
    newItems.push(contentItems[exposureNo-1]);
    newItems.push(contentItems[exposureNo-2]);

    contentItems.forEach((item, index) => {
      if(index > exposureNo-1) {
        newItems.push(item);
      }
    });

    console.log("moveUp => newItems : ",newItems);
    
    setContentItems([...newItems])
  }, [contentItems]);

  const moveDownCallback = useCallback((exposureNo) => {
    if(exposureNo == contentItems.length)
      return;
    let newItems;
    if(exposureNo-1 == 0)
      newItems = [];
    else 
      newItems = contentItems.slice(0, exposureNo-1);

    newItems.push(contentItems[exposureNo]);
    newItems.push(contentItems[exposureNo-1]);

    contentItems.forEach((item, index) => {
      if(index > exposureNo) {
        newItems.push(item);
      }
    });
    // setItems(newItems);
    
    setContentItems([...newItems])
  }, [contentItems]);

  const getItemsTable = useMemo(() =>{
    switch(data?.main_type){
      case 1:
        return <MainContentBannerTable items={contentItems} deleteItemCallback={deleteItemCallback} moveUpCallback={moveUpCallback} moveDownCallback={moveDownCallback}/>
      case 2:
        return <MainContentMagazineTable items={contentItems} deleteItemCallback={deleteItemCallback}  moveUpCallback={moveUpCallback} moveDownCallback={moveDownCallback}/>
      case 3:
        return <MainContentClassTable items={contentItems} deleteItemCallback={deleteItemCallback} moveUpCallback={moveUpCallback} moveDownCallback={moveDownCallback}/>
      case 4 : 
        return videoInfo?<MainContentVideo videoInfo={videoInfo}/>:''
      default:
        return <></>
    }
  },[contentItems,videoInfo,moveUpCallback,moveDownCallback])

  const { request: requestMainBanners } = useCommonMutation({
    query: queries.MainExhibition.getMainExhibitionBanners,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {          
          setContentItems(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 배너 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestMainMagazines } = useCommonMutation({
    query: queries.Magazine.getMainMagazines,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {     
          console.log('magazines => ',data.result_data)     
          setContentItems(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 배너 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestMainClasses } = useCommonMutation({
    query: queries.MainExhibition.getMainExhibitionClasses,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {          
          setContentItems(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 배너 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestMainKeywords } = useCommonMutation({
    query: queries.MainExhibition.getMainExhibitionClasses,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {          
          setContentItems(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 배너 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestMainVideo } = useCommonMutation({
    query: queries.MainVideo.getMainVideo,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setVideoInfo({...data.result_data});
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 영상 조회에 실패했습니다.');
      },
    },
  });

  const requestMainContents = useCallback((payload) => {
    switch(data?.main_type){
      case 1:
        requestMainBanners({mainSeq:data?.main_seq});
        break;
      case 2:
        requestMainMagazines({mainSeq:data?.main_seq});
        break;
      case 3:
        requestMainClasses({mainSeq:data?.main_seq});
        break;
      case 4:
        requestMainVideo({mainSeq:data?.main_seq});
        break;
      case 5:
        requestMainKeywords({mainSeq:data?.main_seq});
        break;
        
      default:
        requestMainBanners({mainSeq:data?.main_seq});
        break;
    }
  })

  const { request: requestModify } = useCommonMutation({
    query: queries.MainExhibition.updateMainExhibition,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          showAlert('알림', '저장되었습니다.');
          requestMainContents({mainSeq:data.main_seq});
          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 클래스 저장에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (show) {
      requestMainContents({mainSeq:data?.main_seq})      
    }
  }, [show]);

  useEffect(() => {
    if(data) {
      title.update(data?.title);
      subTitle.update(data?.sub_title);
      // bannerType.update(classInfo.banner_type);
      link.update(data?.link);
      
      backgroundColor.update(data?.background_color || '#000000');
      // classLink.update(classInfo.class_link);
    }
  }, [data]);
  

  const onAddClick = useCallback(() => {

    // addBannerModal.visible(false)
    // addMagazineModal.visible(false)
    // addClassModal.visible(false)

    switch(data?.main_type){
      case 1:
        addBannerModal.visible(true)
        break;
      case 2:
        addMagazineModal.visible(true)
        break;
      case 3:
        addClassModal.visible(true)
        break;     
      case 4:
        addVideoModal.visible(true)
        break;
        
      default:
        addClassModal.visible(true);
        break;
    }
  }, [data,addBannerModal,addMagazineModal,addClassModal]);

  const onSaveClick = useCallback(() => {

    if(isShowTitle && !title.state) {
      showAlert('알림', '제목을 입력하세요.');
      return;
    }

    if(isShowSubTitle && !subTitle.state) {
      showAlert('알림', '부제목을 입력하세요.');
      return;
    }

    if(isShowLink && !link.state) {
      showAlert('알림', '키워드/링크를 입력하세요.');
      return;
    }

    if(isShowBackground && !backgroundColor.state) {
      showAlert('알림', '배경색을 입력하세요.');
      return;
    }
    
    showConfirm('확인', '변경사항을 저장하시겠습니까?', {
      confirmHandler: () => {
        
        var payload = {
          mainSeq:data.main_seq,
          main_type : data.main_type          
        }
        if(isShowTitle){
          payload.title = title.state
        }
        if(isShowSubTitle){
          payload.sub_title = subTitle.state
        }
        if(isShowLink){
          payload.link = link.state
        }
        if(isShowBackground){
          payload.background_color = backgroundColor.state
        }

        // TODO : check contentItems changed
        payload.items = contentItems
        payload.video = videoInfo
        
        console.log('payload => ',payload)
        requestModify(payload);
      }
    });
  }, [contentItems, title.state, subTitle.state, link.state , backgroundColor.state])

  return (
    <LAModal
      title={getTitle}
      width="70%"
      height="90%"
      show={show}
      onClose={handleClose}
    >

      { (isShowTitle || isShowSubTitle || isShowLink || isShowBackground) ?
        <LACard>
        <LACardTitle title="전시 상세" />
        {
          isShowTitle ? 
          <LAProperty name="제목" required>
            <LAInput
              placeholder="제목을 입력해 주세요."
              value={title.state}
              onChange={title.handleChange}
            />
          </LAProperty>
          : ''
        }
        {
          isShowSubTitle ? 
          <LAProperty name="부제목" required>
            <LAInput
              placeholder="부제목을 입력해 주세요."
              value={subTitle.state}
              onChange={subTitle.handleChange}
            />
          </LAProperty> 
          :''
        }

        {
          isShowLink ? 
          <LAProperty name="키워드/링크" required>          
            <LAInput
              placeholder="키워드/링크를 입력해 주세요."
              value={link.state}
              onChange={link.handleChange}
            />
            <LAWarningLabel>URL 페이지 연결 시 : 페이지가 https://www.liveall/event/2인 경우 "/event/2"만 입력하세요.</LAWarningLabel>
            <LAWarningLabel layouts={{ pt: 0 }}>키워드 검색 페이지 연결 시 : "/search/result?keyword=원데이"만 입력하고 해당 키워드를 "원데이"에 입력하세요.</LAWarningLabel>          
          </LAProperty>  
          :''
        }

        {
          isShowBackground ?
          <LAProperty name="배경색" required>
            <LAInput
              placeholder={getBackgroundGuideText}
              value={backgroundColor.state}
              onChange={backgroundColor.handleChange}
            />
            <LAWarningLabel>{getBackgroundGuideText}</LAWarningLabel>
          </LAProperty>
          :''
        }
              
         
                     
      </LACard>
      :''
      }
      
      {/* <LACard next>
        <LACardTitle title="현재 노출 목록" />
          <LAGridContainer layouts={{ pt: 1 }}>            
            {getItemsTable}
          </LAGridContainer>
      </LACard> */}
      <LACard next>
        {getItemsTable}
      </LACard>
      

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={35}></LALabel>
        {
          isShowAdd ?
          <LAButton 
          wPercent={15} 
          layouts={{ ml: 1 }}
          variant={'outlined'}
          onClick={onAddClick}
        >
          추가
        </LAButton>
        :''
        }
        

        <LAButton 
          wPercent={15} 
          layouts={{ ml: 1 }}
          onClick={onSaveClick}
        >
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>

      {addClassModal && 
      <AddClassListModal
        show={addClassModal.state} 
        handleClose={addClassModal.handleClose} 
        addItemCallback={addItemCallback}
        registeredClass={contentItems}
      />}
      {addBannerModal && 
      <AddBannerModal
        show={addBannerModal.state} 
        handleClose={addBannerModal.handleClose} 
        addItemCallback={addItemCallback}
        registeredBanner={contentItems}
        bannerType={bannerType}
      />
      }
      <AddMagazineModal
      show={addMagazineModal.state} 
      handleClose={addMagazineModal.handleClose} 
      addItemCallback={addItemCallback}
      registeredMagazine={contentItems}
      />

    </LAModal>
  );  
}

export default MainContentDetailModal;
