import React, { useEffect, useRef } from 'react';
import LAGridItem from './LAGridItem';

function LATextEditor({
                        name = 'summernote',
                        wPercent = 100,
                        height = 300,
                        layouts = {},
                        value,
                        getEditorValue = {},
                        onChange,
                      }) {
  let htmlInput = useRef();
  // const [init, setInit] = useState(false);

  const getHtmlData = () => {
    return htmlInput.current.value;
  };

  let editorName = `sn_${name}`;

  let valueName = `value_${name}`;

  useEffect(() => {
    const listener = (e) => {
      if (onChange) onChange(e.target.value);
    };
    htmlInput.current.addEventListener('changeEditor', listener);

    return () => {
      // htmlInput.current.removeEventListener('changeEditor');
    };
  }, [onChange]);

  useEffect(() => {
    getEditorValue.current = getHtmlData;
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = `// window.console.log('in summernote...');

      $('.${editorName}').summernote({
        lang: 'ko-KR',
        placeholder: '',
        tabsize: 2,
        height: ${height},
        minHeight: 300,
        toolbar: [
          // ['style', ['style']],
          ['fontname', ['fontname']],
          ['fontsize', ['fontsize']],
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link', 'picture', 'video']],
          ['view', ['codeview']]
        ],
        // font 정의는 toolbar 밑에 있어야 됨.
        fontNames: ['노토산스', '나눔고딕', '나눔바른고딕', '나눔명조', '나눔바른펜', '나눔스퀘어', '나눔손글씨'],
        fontNamesIgnoreCheck: ['노토산스', '나눔고딕', '나눔바른고딕', '나눔명조', '나눔바른펜','나눔스퀘어', '나눔손글씨'],
        fontSizes: ['8', '9', '10', '11', '12', '14', '16', '20', '24', '36', '48' , '64'],
        popover: {
          image: [
              ['custom', ['imageAttributes']],
              ['imagesize', ['resizeFull', 'resizeHalf', 'resizeQuarter']],
              ['float', ['floatLeft', 'floatRight', 'floatNone']],
              ['remove', ['removeMedia']]
          ],
        },
        imageAttributes:{
          icon:'<i class="note-icon-link"/>',
          removeEmpty:true, // true = remove attributes | false = leave empty if present
          disableUpload: true // true = don't display Upload Options | Display Upload Options
        }
      });

      $('.note-editable').css("font-size", "16px"); // default font size
      $('.note-editable').css("font-family", "노토산스"); // default font

      // set html
      var markupStr = \`${value ? value : ''}\`;
      // console.log('html : ' + markupStr);
      $('.${editorName}').summernote('code', markupStr);
      $('.${valueName}').val($('.${editorName}').summernote('code')); 

      $('.${editorName}').on('summernote.change', function (e) {
        $('.${valueName}').val($('.${editorName}').summernote('code'));    
        // console.log($('.${editorName}').summernote('code'));
        $('.${valueName}')[0].dispatchEvent(new CustomEvent("changeEditor", {value: $('.${editorName}').summernote('code')}));
      });
  
      // get html
      // console.log($('.${editorName}').summernote('code'));`;
    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [value, editorName, valueName]);

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <div className={editorName}></div>
      <input
        name="html"
        className={valueName}
        style={{ display: 'none' }}
        ref={htmlInput}
      />
    </LAGridItem>
  );
}

export default React.memo(LATextEditor);
