import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { useKeywordContext } from "common/context/CodeContext";
import MDBox from "components/MDBox";
import MdSearchModal from "pages/Class/components/MdSearchModal";
import TeacherSearchModal from "pages/Class/components/TeacherSearchModal";
import TextbookSearchModal from "pages/Class/components/TextbookSearchModal";

import { useLoginContext } from "common/context/MemberContext";
import { useApiContext } from "common/context/ApiContext";
import { useImageContext } from "common/context/CodeContext";

import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import CategoryComboView from "pages/Class/components/CategoryComboView";
import { toCurrency } from "common/utils";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LACodeRadioGroup,
  LADeletableListView,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LAImageList,
  LATextEditor,
  useRadioGroup,
  useComboBox,
  useInput,
  LASimpleTable,
  useModalPopup,
  useKeywords,
  LAKeywords,
  useModal,
  LAFileAttachment,
  useFileAttachment,
  useCheckbox,
  LAImage,
} from "modules/Widgets";

import EmptyImage from "assets/images/empty.png";

import { LACheckbox } from "modules/Widgets";
import { LACodeComboBox } from "modules/Widgets";
import ClassMaterialCodeModal from "pages/Class/components/ClassMaterialCodeModal";

function TogetherClassRegistContents({ onClickList }) {
  const { isLogin, loginState, action } = useLoginContext();
  const { avatars, defaultClassImages } = useImageContext();

  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [teacher, setTeacher] = useState({});
  const [teacherSchedules, setTeacherSchedules] = useState([]);
  const category = useComboBox(20);
  const className = useInput("");
  const classSummary = useInput("");
  const classType = useRadioGroup(9);
  const classDuration = useComboBox(30);
  const classRoundType = useComboBox(1);
  const minUserCount = useComboBox(5);
  const maxUserCount = useComboBox(10);
  const allAgeYn = useRadioGroup(1);
  const minTargetAge = useComboBox(5);
  const maxTargetAge = useComboBox(8);

  const [curriculumName, setCurriculumName] = useState([""]);
  const [price, setPrice] = useState([0]);
  const [discountPrice, setDiscountPrice] = useState([0]);
  const discountType = useRadioGroup(0);

  const thumbnailType = useRadioGroup(0);
  const thumbnailFile = useFileAttachment(2048, { name: "" });

  const additionalFile = useFileAttachment(2048);
  const [additionalImages, setAdditionalImages] = useState([]);

  const materials = useInput("");
  const included = useInput("");

  const { keywordList } = useKeywordContext();
  const keywordsSelector = useKeywords([]);
  const keywordInput = useInput("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const teacherSearchModal = useModal(false);
  const textbookSearchModal = useModal(false);
  const classMaterialCodeModal = useModal(false);

  const classFile = useFileAttachment(100 * 1024);
  const [classFiles, setClassFiles] = useState([]);
  const [classFileInfos, setClassFileInfos] = useState([]);
  const [textbookDeliveryType, setTextbookDeliveryType] = useState(0); // 0: 없음, 1: 웅진씽크빅, 2: 선생님 개별 교재
  const [textbooks, setTextbooks] = useState([]);
  const [textbookInfos, setTextbookInfos] = useState([]);

  const favoriteCount = useInput(0); // useComboBox(0)
  const isTestMode = useCheckbox(false);

  const getInformEditorValue = useRef();
  const getCurriculumEditorValue = useRef();
  const getNoticeEditorValue = useRef();

  const [miraePointRate, setMiraePointRate] = useState(100); // 미래본부 클럽 포인트
  const [gyomunMileageRate, setGyomunMileageRate] = useState(0); // 교문본부 클럽 마일리지
  const [onlineMileageRate, setOnlineMileageRate] = useState(0); // 온라인본부 클럽 마일리지

  const togetherClassCode = useComboBox(-1); // 투게더 과목
  const togetherGradeCode = useComboBox(-1); // 투게더 학년 정보
  const togetherClassPeriod = useComboBox(-1); // 투게더 기간

  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  const navigateBack = () => {
    navigate(-1, { replace: true });
  };

  const { request: registTogetherClass } = useCommonMutation({
    query: queries.Together.registClass,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "클래스가 등록되었습니다.", {
            confirmHandler: onClickList,
          });
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "클래스 등록에 실패했습니다.");
      },
    },
  });

  const handleNameChange = useCallback((e, i) => {
    setCurriculumName((prev) =>
      prev.map((data, index) => (index === i ? e.target.value : data))
    );
  }, []);

  const handlePriceChange = useCallback((e, i) => {
    let value = 0;
    if (e.target.value.length > 0) {
      value = parseInt(e.target.value);
    }

    e.target.value = toCurrency(value);
    setPrice((prev) => prev.map((data, index) => (index === i ? value : data)));
    setDiscountPrice((prev) =>
      prev.map((data, index) => (index === i ? value : data))
    );
  }, []);

  const handleDiscountPriceChange = useCallback(
    (e, i) => {
      let value = 0;
      if (e.target.value.length > 0) {
        value = parseInt(e.target.value);
      }

      if (value > price[i]) {
        value = price[i];
      }

      e.target.value = toCurrency(value);
      setDiscountPrice((prev) =>
        prev.map((data, index) => (index === i ? value : data))
      );
    },
    [price]
  );

  useEffect(() => {
    if (classType.state == 1) {
      classRoundType.select(1);
    }
  }, [classType.state]);

  useEffect(() => {
    const count = parseInt(togetherClassPeriod.state * 4);
    setPrice(() => {
      const result = [];
      for (let i = 0; i < count; i++) {
        result.push(0);
      }
      return result;
    });
    setDiscountPrice(() => {
      const result = [];
      for (let i = 0; i < count; i++) {
        result.push(0);
      }
      return result;
    });
    setCurriculumName(() => {
      const result = [];
      for (let i = 0; i < count; i++) {
        result.push("");
      }
      return result;
    });
  }, [togetherClassPeriod.state]);

  const handleTextbookDiscountPriceChange = useCallback((e, materialNo) => {
    setTextbooks((prev) =>
      prev.map((item) => {
        return item.material_no == materialNo
          ? { ...item, discount_price: parseInt(e.target.value) }
          : item;
      })
    );
  }, []);

  const handleTextbookDeliveryFeeChange = useCallback((e, materialNo) => {
    setTextbooks((prev) =>
      prev.map((item) => {
        return item.material_no == materialNo
          ? { ...item, delivery_price: parseInt(e.target.value) }
          : item;
      })
    );
  }, []);

  const handleTextbookNameChange = useCallback((e, i) => {
    setTextbooks((prev) =>
      prev.map((item, index) => {
        return index === i ? e.target.value : item;
      })
    );
  }, []);

  const getDiscountRate = useCallback((srcPrice, discountPrice) => {
    if (srcPrice > 0 && discountPrice > 0) {
      let diffPrice = srcPrice - discountPrice;
      if (diffPrice > 0) return ((diffPrice / srcPrice) * 100).toFixed(0);
      else return 0;
    } else {
      return 0;
    }
  }, []);

  const getClassPriceRows = useCallback(() => {
    const result = [];

    if (
      price.length * 3 !==
      price.length + discountPrice.length + curriculumName.length
    ) {
      return result;
    }

    const count = price.length;
    for (let i = 0; i < count; i++) {
      const name = curriculumName[i];
      const srcPrice = price[i];
      const dcPrice = discountPrice[i];

      result.push([
        { text: `${i + 1}회차` },
        {
          text: "",
          type: "input",
          placeholder: "커리큘럼 이름을 입력해주세요.",
          callback: {
            value: name,
            onChange: (e) => handleNameChange(e, i),
          },
        },
        {
          text: "",
          type: "input",
          placeholder: "정상가를 입력해주세요.",
          callback: {
            value: srcPrice,
            type: "number",
            onChange: (e) => handlePriceChange(e, i),
          },
        },
        {
          text: "",
          type: "input",
          placeholder: "판매가를 입력해주세요.",
          callback: {
            value: dcPrice,
            type: "number",
            onChange: (e) => handleDiscountPriceChange(e, i),
          },
        },
        {
          text: `${
            !srcPrice ||
            !dcPrice ||
            srcPrice == 0 ||
            dcPrice == 0 ||
            dcPrice > srcPrice
              ? 0
              : Math.round((1.0 - dcPrice / srcPrice) * 100)
          }%`,
        },
      ]);
    }

    return result;
  }, [curriculumName, price, discountPrice]);

  const priceTableData = useMemo(() => {
    return {
      headers: [
        { text: "회차" },
        { text: "이름" },
        { text: "정상가" },
        { text: "판매가" },
        { text: "할인율" },
      ],
      wPercentList: [15, 30, 20, 20, 15],
      rows: getClassPriceRows(),
    };
  }, [getClassPriceRows]);

  const getRateRows = useCallback(() => {
    const result = [];

    result.push(
      [
        { text: `클럽 포인트` },
        {
          text: "%",
          type: "rate",
          style: {
            margin: "2.4px 5px 0 0",
            width: "29.5%",
          },
          callback: {
            name: "M",
            value: miraePointRate,
            type: "number",
            onChange: (e) => numberHandle(e),
          },
        },
        { text: "-" },
        { text: "-" },
      ],
      [
        { text: `클럽 마일리지` },
        { text: "-" },
        {
          text: "%",
          type: "rate",
          style: {
            margin: "2.4px 5px 0 0",
            width: "29.5%",
          },
          callback: {
            name: "G",
            value: gyomunMileageRate,
            type: "number",
            onChange: (e) => numberHandle(e),
          },
        },
        {
          text: "%",
          type: "rate",
          style: {
            margin: "2.4px 0 0 5px",
            width: "29.5%",
          },
          callback: {
            name: "O",
            value: onlineMileageRate,
            type: "number",
            onChange: (e) => numberHandle(e),
          },
        },
      ]
    );

    return result;
  }, [miraePointRate, gyomunMileageRate, onlineMileageRate]);

  const rateTableData = useMemo(() => {
    return {
      headers: [
        { text: "구분" },
        { text: "미래본부" },
        { text: "교문본부" },
        { text: "온라인본부" },
      ],
      wPercentList: [10, 30, 30, 30],
      rows: getRateRows(),
    };
  }, [getRateRows]);

  const getTextbookRows = useCallback(() => {
    const result = [];

    if (textbooks.length == 0) {
      return result;
    }

    if (textbookDeliveryType == 1) {
      textbooks.forEach((item, index) => {
        result.push([
          { text: item.material_name },
          { text: item.price },
          {
            text: "",
            type: "input",
            placeholder: "할인가를 입력해주세요.",
            callback: {
              value: item.discount_price,
              type: "number",
              onChange: (e) =>
                handleTextbookDiscountPriceChange(e, item.material_no),
            },
          },
          { text: `${getDiscountRate(item.price, item.discount_price)}%` },
          {
            text: "",
            type: "input",
            placeholder: "배송비를 입력해주세요.",
            disabled: true,
            callback: {
              value: item.delivery_price,
              type: "number",
              onChange: (e) =>
                handleTextbookDeliveryFeeChange(e, item.material_no),
            },
          },
          {
            text: "삭제",
            type: "button",
            callback: {
              onClick: () => onTextbookDelete(index),
            },
          },
        ]);
      });
    } else {
      textbooks.forEach((item, index) => {
        console.log(item);
        result.push([
          {
            text: "",
            type: "input",
            placeholder: "교재(상품)명을 입력해주세요",
            callback: {
              value: item.material_name,
              type: "text",
              onChange: (e) => handleTextbookNameChange(e, index),
              onEnterKeyPressed: (e) => console.log("e", e),
            },
          },
          {
            text: "삭제",
            type: "button",
            callback: {
              onClick: () => onTextbookDelete(index),
            },
          },
        ]);
      });
    }

    return result;
  }, [textbooks]);

  const textbookTableData = useMemo(() => {
    let tableDef = null;

    if (textbookDeliveryType == 1) {
      tableDef = {
        headers: [
          { text: "제목" },
          { text: "정상가" },
          { text: "할인가" },
          { text: "할인율" },
          { text: "배송비" },
          { text: "삭제" },
        ],
        wPercentList: [30, 15, 15, 15, 15, 10],
        rows: getTextbookRows(),
      };
    } else {
      tableDef = {
        headers: [{ text: "교재(상품)명" }, { text: "삭제" }],
        wPercentList: [90, 10],
        rows: getTextbookRows(),
      };
    }
    return tableDef;
  }, [getTextbookRows]);

  const onTextbookSelected = useCallback(
    (data) => {
      textbookSearchModal.handleClose();

      if (textbooks.length > 1) {
        showAlert("알림", "최대 1개까지 등록 가능합니다.");
        return;
      }

      let isOverlap = false;
      textbooks.forEach((textbook) => {
        isOverlap =
          textbook.material_no === data.material_no ? true : isOverlap;
      });

      if (isOverlap) {
        return;
      }

      setTextbookDeliveryType(1);
      setTextbooks((prev) => [
        ...prev,
        { ...data, discount_price: data.price },
      ]);
      setTextbookInfos((prev) => [
        ...prev,
        { value: `${data.material_name} | ${data.price}` },
      ]);
    },
    [textbooks, textbookInfos, textbookSearchModal.handleClose]
  );

  const addAppendix = useCallback(() => {
    if (textbooks.length > 1) {
      showAlert("알림", "최대 1개까지 등록 가능합니다.");
      return;
    }

    let newTextbook = { material_name: "" };
    setTextbookDeliveryType(2);
    setTextbooks((prev) => [...prev, newTextbook]);
    setTextbookInfos((prev) => [
      ...prev,
      { value: `${newTextbook.material_name}` },
    ]);
  }, [textbooks]);

  const onTextbookDelete = useCallback(
    (textbookIndex) => {
      let bookList = textbooks.filter((item, i) => i != textbookIndex);
      let infoList = textbookInfos.filter((item, i) => i != textbookIndex);

      setTextbooks(bookList);
      setTextbookInfos(infoList);

      if (bookList.length == 0) {
        setTextbookDeliveryType(0);
      }
    },
    [textbooks]
  );

  const onTeacherSelected = useCallback(
    async (newVal) => {
      teacherSearchModal.handleClose();
      await setTeacher(newVal);
      requestTeacherValidSchedules();
    },
    [teacherSearchModal.handleClose]
  );

  // 선생님의 수업 일정을 가져온다.
  const { request: requestTeacherValidSchedules } = useCommonQuery({
    query: queries.Teachers.teacherSchedules,
    params: {
      teacher_seq: teacher.member_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setTeacherSchedules(data.result_data);
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "선생님 일정 조회에 실패했습니다.");
      },
    },
    initEnabled: false,
  });

  const onThumbnailChange = useCallback(
    (e) => {
      if (e.target.value != 4) {
        thumbnailFile.reset(true);
      }
      thumbnailType.handleChange(e);
    },
    [thumbnailType.handleChange]
  );

  useEffect(() => {
    if (additionalFile.state.name.length === 0) {
      return;
    }

    setAdditionalImages((prev) => [...prev, additionalFile.state]);
  }, [additionalFile.state]);

  const onRemoveAdditionalImage = useCallback((index, image_seq) => {
    let newAdditionalImages = additionalImages.filter((image, i) => i != index);
    setAdditionalImages(newAdditionalImages);
  });

  const onAdditionalImageChange = useCallback(
    (e) => {
      if (additionalImages.length >= 10) {
        showAlert("알림", "최대 10개까지 등록 가능합니다.");
        return;
      }
      additionalFile.handleChange(e);
    },
    [additionalImages]
  );

  const onKeywordListChange = useCallback(
    (e) => {
      let checker = selectedKeywords.filter((data) => e.target.value != data);

      if (checker.length == selectedKeywords.length) {
        checker.push({ value: e.target.value });
        setSelectedKeywords(checker);
      }
    },
    [keywordsSelector.state, selectedKeywords]
  );

  const onAddKeywordClick = useCallback(() => {
    if (!keywordInput.state || keywordInput.state.length < 1) {
      showAlert("알림", "키워드는 한자 이상 입력하세요.");
      return;
    }

    let checker = selectedKeywords.filter(
      (data) => keywordInput.state != data.value
    );

    if (checker.length == selectedKeywords.length) {
      checker.push({ value: keywordInput.state });
      setSelectedKeywords(checker);
      keywordInput.update("");
    }
  }, [keywordInput.state, selectedKeywords]);

  const onDeleteKeyword = useCallback(
    (keywordIndex) => {
      let result = selectedKeywords.filter(
        (data, index) => index != keywordIndex
      );
      setSelectedKeywords(result);
    },
    [selectedKeywords]
  );

  const onEnterKeyAtKeyword = useCallback(() => {
    onAddKeywordClick();
  });

  useEffect(() => {
    if (classFile.state.name.length === 0) {
      return;
    }

    let isOverlap = false;
    classFileInfos.forEach((name) => {
      isOverlap = name === classFile.state.name ? true : isOverlap;
    });

    if (isOverlap) {
      return;
    }

    setClassFiles((prev) => [...prev, classFile.state]);
    setClassFileInfos((prev) => [...prev, { value: classFile.state.name }]);
  }, [classFile.state]);

  const onClassFileChange = useCallback(
    (e) => {
      if (classFiles.length >= 5) {
        showAlert("알림", "최대 5개까지 등록 가능합니다.");
        return;
      }

      classFile.handleChange(e);
    },
    [classFiles]
  );

  const onClassFileDelete = useCallback((fileIndex) => {
    setClassFiles((prev) => prev.filter((file, index) => index !== fileIndex));
    setClassFileInfos((prev) =>
      prev.filter((file, index) => index !== fileIndex)
    );
  }, []);

  const userCounts = useMemo(() => {
    let list = [];
    for (let i = 1; i < 51; i++) {
      list.push({ key: i, value: `${i}명` });
    }
    list.push({ key: 100, value: "100명" });
    list.push({ key: 200, value: "200명" });
    list.push({ key: 300, value: "300명" });
    list.push({ key: 400, value: "400명" });
    list.push({ key: 500, value: "500명" });
    list.push({ key: 600, value: "600명" });
    list.push({ key: 700, value: "700명" });
    list.push({ key: 800, value: "800명" });
    list.push({ key: 900, value: "900명" });
    list.push({ key: 1000, value: "1000명" });
    return list;
  }, []);

  const targetAgeItems = useMemo(() => {
    var list = [];
    for (let i = 1; i < 20; i++) {
      list.push({ key: i, value: `${i}세` });
    }
    return list;
  }, []);

  const classDurationItems = useMemo(() => {
    var list = [];
    for (let i = 2; i <= 36; i++) {
      list.push({ key: i * 5, value: `${i * 5}분` });
    }
    return list;
  }, []);

  const onRegistClick = useCallback(() => {
    console.log("classFile =>", classFile);
    console.log("classFileInfos =>", classFileInfos);

    const thumbnailImages = [];
    thumbnailImages.push(...additionalImages.map((data) => data.info));

    const materialFiles = classFiles.map((data) => data.info);

    if (materialFiles.length > 5) {
      showAlert("알림", "수업 자료는 최대 5까지 첨부 가능합니다.");
      return;
    }
    const classFileInfosSize = materialFiles.reduce(
      (acc, item) => acc + item.size,
      0
    );
    if ((classFileInfosSize / (1024 * 1024)).toFixed(2) > 100) {
      showAlert("알림", "전체 용량 100MB까지 첨부 가능합니다.");
      return;
    }

    const curriculums = price.map((data, index) => {
      return {
        curriculum_name: curriculumName[index],
        price: data,
        discount_price: discountPrice[index],
      };
    });

    let options = null;
    if (textbookDeliveryType == 1) {
      options = textbooks.map((data) => {
        return {
          option_name: data.material_name,
          price: data.price,
          discount_price: data.discount_price,
          delivery_price: data.delivery_price,
          sap_material_code: data.material_no,
        };
      });
    } else {
      options = textbooks.map((data) => {
        return {
          appendix_name: data,
        };
      });
    }

    if (!category?.state) {
      showAlert("알림", "카테고리를 선택해주세요.");
      return;
    }
    if (!className?.state || className.state.length === 0) {
      showAlert("알림", "클래스 명을 입력해주세요.");
      return;
    }

    if (!togetherClassCode?.state || togetherClassCode.state.length === 0) {
      showAlert("알림", "투게더 과목을 선택해주세요.");
      return;
    }

    if (!togetherClassPeriod?.state || togetherClassPeriod.state.length === 0) {
      showAlert("알림", "클래스 기간을 선택해주세요.");
      return;
    }

    if (!classSummary?.state || classSummary.state.length === 0) {
      showAlert("알림", "클래스 요약을 입력해주세요");
      return;
    }

    let valid = true;
    curriculumName.forEach((data) => {
      valid = data.length === 0 ? false : valid;
    });
    if (!valid) {
      showAlert("알림", "커리큘럼 이름을 입력해주세요.");
      return;
    }

    valid = true;
    price.forEach((data) => {
      /*`23.06.21 이벤트 클래스 0원 강좌를 위해 해당 로직 임시 통과처리*/
      valid = data === 0 ? valid : valid;
    });
    if (!valid) {
      showAlert("알림", "정상가를 입력해주세요.");
      return;
    }

    valid = true;
    discountPrice.forEach((data) => {
      /*`23.06.21 이벤트 클래스 0원 강좌를 위해 해당 로직 임시 통과처리*/
      valid = data === 0 ? valid : valid;
    });
    if (!valid) {
      showAlert("알림", "판매가를 입력해주세요.");
      return;
    }

    if (
      thumbnailType.state == 4 &&
      (!thumbnailFile?.state?.name || thumbnailFile.state.name.length === 0)
    ) {
      showAlert("알림", "클래스 이미지를 선택해주세요.");
      return;
    }

    let informHtml = "";
    if (
      !getInformEditorValue.current ||
      getInformEditorValue.current().length == 0
    ) {
      showAlert("알림", "클래스 소개를 입력해주세요.");
      return;
    } else {
      if (getInformEditorValue.current().length * 0.7 > 5000000) {
        showAlert(
          "알림",
          "클래스 소개는 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다."
        );
        return;
      }
      informHtml = `<div style="font-size: 16px; font-family: 노토산스;">${getInformEditorValue.current()}</div>`;
    }

    let noticeHtml = "";
    if (
      !getNoticeEditorValue.current ||
      getNoticeEditorValue.current().length == 0
    ) {
      showAlert("알림", '"클래스 전 꼭 확인해주세요" 항목를 입력해주세요.');
      return;
    } else {
      if (getNoticeEditorValue.current().length * 0.7 > 5000000) {
        showAlert(
          "알림",
          '"클래스 전 꼭 확인해주세요" 항목은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.'
        );
        return;
      }
      noticeHtml = `<div style="font-size: 16px; font-family: 노토산스;">${getNoticeEditorValue.current()}</div>`;
    }

    let curriculumHtml = "";
    if (
      !getNoticeEditorValue.current ||
      getNoticeEditorValue.current().length == 0
    ) {
      //
    } else {
      if (
        getCurriculumEditorValue.current &&
        getCurriculumEditorValue.current().length * 0.7 > 5000000
      ) {
        showAlert(
          "알림",
          '"준비물 & 유의사항" 항목은 이미지 크기 포함 전체 크기가 5M 이상 입력할 수 없습니다.'
        );
        return;
      }
      curriculumHtml = `<div style="font-size: 16px; font-family: 노토산스;">${getCurriculumEditorValue.current()}</div>`;
    }

    if (selectedKeywords.length === 0) {
      showAlert("알림", "키워드를 선택해주세요.");
      return;
    }

    const formData = new FormData();
    formData.append("teacher_seq", teacher ? teacher.member_seq : undefined);
    formData.append("category_seq", category.state);

    formData.append("together_class_code", togetherClassCode.state);
    formData.append("together_grade_code", togetherGradeCode.state);
    formData.append("together_class_month", togetherClassPeriod.state);

    // formData.append('admin_seq', md ? md.member_seq : undefined);
    formData.append("class_name", className.state);
    formData.append("class_description", classSummary.state);
    formData.append("class_type", 9);
    formData.append("discount_type", discountType.state);
    formData.append("brand_type", 1);
    formData.append("min_user_count", minUserCount.state);
    formData.append("max_user_count", maxUserCount.state);
    formData.append("all_age_yn", allAgeYn.state);
    formData.append("min_target_age", minTargetAge.state);
    formData.append("max_target_age", maxTargetAge.state);
    formData.append("class_time", classDuration.state);
    formData.append("inform_html", informHtml);
    formData.append("curriculum_html", curriculumHtml);
    formData.append("notice_html", noticeHtml);

    formData.append("thumbnail_image_file", thumbnailFile.state.info);

    // TODO : 기본 이미지 선택시 url
    if (thumbnailType.state != 4) {
      formData.append(
        "thumbnail_image_url",
        defaultClassImages[thumbnailType.state].file_url
      );
    }

    for (const file of thumbnailImages) {
      formData.append("image_files", file);
    }
    for (const file of materialFiles) {
      formData.append("material_files", file);
    }

    formData.append("materials", materials.state);
    formData.append("included", included.state);
    formData.append("curriculums", JSON.stringify(curriculums));

    formData.append("bookclub_point_max_rate", miraePointRate);
    formData.append("gm_mileage_max_rate", gyomunMileageRate);
    formData.append("online_mileage_max_rate", onlineMileageRate);

    formData.append("textbook_delivery_type", textbookDeliveryType);

    if (textbookDeliveryType == 1) {
      formData.append("options", JSON.stringify(options));
    } else {
      formData.append("appendixs", JSON.stringify(options));
    }

    formData.append(
      "keywords",
      JSON.stringify(selectedKeywords.map((sk) => sk.value))
    );
    formData.append("favorite_count", favoriteCount.state);
    formData.append("test_mode_yn", isTestMode.state ? 1 : 0);

    registTogetherClass(formData);
  }, [
    category.state,
    teacher,
    className.state,
    classSummary.state,
    classType.state,
    minUserCount.state,
    maxUserCount.state,
    allAgeYn.state,
    minTargetAge.state,
    maxTargetAge.state,
    classDuration.state,
    materials.state,
    included.state,
    thumbnailType.state,
    thumbnailFile.state,
    additionalImages,
    classFiles,
    textbooks,
    curriculumName,
    price,
    discountPrice,
    selectedKeywords,
    favoriteCount.state,
    isTestMode.state,
    miraePointRate,
    gyomunMileageRate,
    onlineMileageRate,
  ]);

  const numberHandle = useCallback(
    (e) => {
      let currentInput = e.target.name;
      let currentNum = e.target.value;

      if (currentInput === "G") {
        if (!currentNum) {
          return setGyomunMileageRate(0);
        }

        setGyomunMileageRate(toCurrency(currentNum));

        if (currentNum > 100) {
          return setGyomunMileageRate(100);
        }
        if (currentNum < 0) {
          return setGyomunMileageRate(0);
        }
      } else if (currentInput === "O") {
        if (!currentNum) {
          return setOnlineMileageRate(0);
        }

        setOnlineMileageRate(toCurrency(currentNum));

        if (currentNum > 100) {
          return setOnlineMileageRate(100);
        }
        if (currentNum < 0) {
          return setOnlineMileageRate(0);
        }
      } else {
        if (!currentNum) {
          return setMiraePointRate(0);
        }

        setMiraePointRate(toCurrency(currentNum));

        if (currentNum > 100) {
          return setMiraePointRate(100);
        }
        if (currentNum < 0) {
          return setMiraePointRate(0);
        }
      }
    },
    [miraePointRate, gyomunMileageRate, onlineMileageRate]
  );

  return (
    <>
      <LACard next>
        <LACardTitle title="클래스 기본 정보" />

        <LAProperty name="선생님" required>
          <LAGridContainer>
            {teacher && teacher.member_name && (
              <LALabel wPercent={8}>{teacher.member_name}</LALabel>
            )}
            <LAButton
              wPercent={15}
              onClick={() => teacherSearchModal.visible(true)}
            >
              선생님 검색
            </LAButton>
          </LAGridContainer>
        </LAProperty>

        {/* 2024-07-03 슈퍼팟 클래스 자제코드 추가  */}
        {/* <LAProperty name="클래스 자재 코드" required>
            <LAGridContainer>
                // {teacher && teacher.member_name && (<LALabel wPercent={8}>{teacher.member_name}</LALabel>)} *
                <LAButton wPercent={15} onClick={() => classMaterialCodeModal.visible(true)}>자재 코드 검색</LAButton>
            </LAGridContainer>
        </LAProperty> */}

        <LAProperty name="카테고리" required>
          <LAGridContainer>
            <CategoryComboView
              value={category.state}
              onChange={category.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="투게더 과목" required>
          <LAGridContainer>
            <LACodeComboBox
              wPercent={19.5}
              checkAll={true}
              codeType="TOGETHER_COURSE_CODE"
              value={togetherClassCode.state}
              onChange={togetherClassCode.handleChange}
            />
            <LALabel wPercent={0.5} />
            <LACodeComboBox
              wPercent={19.5}
              checkAll={true}
              codeType="TOGETHER_GRADE_CODE"
              value={togetherGradeCode.state}
              onChange={togetherGradeCode.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="클래스 명" required>
          <LAInput
            placeholder="클래스 명을 입력하세요."
            value={className.state}
            onChange={className.handleChange}
          />
        </LAProperty>

        <LAProperty name="클래스 요약" required>
          <LAInput
            placeholder="클래스 요약을 입력하세요."
            value={classSummary.state}
            onChange={classSummary.handleChange}
          />
        </LAProperty>

        <LAProperty name="클래스 유형" required>
          <LARadioGroup
            wPercent={15}
            props={[{ value: 9, text: "투게더" }]}
            value={classType.state}
            onChange={classType.handleChange}
          />
        </LAProperty>

        <LAProperty name="클래스 기간" required>
          <LACodeComboBox
            wPercent={25}
            checkAll={true}
            codeType="TOGETHER_PERIOD_TYPE"
            value={togetherClassPeriod.state}
            onChange={togetherClassPeriod.handleChange}
          />
        </LAProperty>
        <LAProperty name="클래스 시간" required>
          <LAComboBox
            wPercent={25}
            items={classDurationItems}
            value={classDuration.state}
            onChange={classDuration.handleChange}
          />
        </LAProperty>

        <LAProperty name="정원" required>
          <LAGridContainer>
            <LALabel wPercent={5}>최소</LALabel>
            <LAComboBox
              wPercent={20}
              items={userCounts}
              value={minUserCount.state}
              onChange={minUserCount.handleChange}
            />
            <LALabel wPercent={5} layouts={{ sx: { textAlign: "center" } }}>
              ~
            </LALabel>
            <LALabel wPercent={5}>최대</LALabel>
            <LAComboBox
              wPercent={20}
              items={userCounts}
              value={maxUserCount.state}
              onChange={maxUserCount.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="대상 연령" required>
          <LAGridContainer>
            <LACodeRadioGroup
              codeType="TARGET_AGE_QUERY_TYPE"
              value={allAgeYn.state}
              onChange={allAgeYn.handleChange}
            />
            {allAgeYn.state === "0" && (
              <LAGridContainer>
                <LALabel wPercent={5}>최소</LALabel>
                <LAComboBox
                  wPercent={20}
                  items={targetAgeItems}
                  value={minTargetAge.state}
                  onChange={minTargetAge.handleChange}
                />
                <LALabel wPercent={5} layouts={{ sx: { textAlign: "center" } }}>
                  ~
                </LALabel>
                <LALabel wPercent={5}>최대</LALabel>
                <LAComboBox
                  wPercent={20}
                  items={targetAgeItems}
                  value={maxTargetAge.state}
                  onChange={maxTargetAge.handleChange}
                />
              </LAGridContainer>
            )}
          </LAGridContainer>
        </LAProperty>
      </LACard>

      {authMemberType == 1 && authAdminAuthType == 1 ? (
        <LACard next component="div">
          <LACardTitle title="시크릿 모드(마스터 관리자 only)" />
          <LAProperty name="시크릿 모드">
            <LAGridContainer>
              <LACheckbox
                label={"시크릿 클래스 설정"}
                value={isTestMode.state}
                onClick={isTestMode.handleClick}
              />
              <LAWarningLabel wPercent={80} layouts={{ pl: { sm: 5 } }}>
                마스터 관리자만 수정 가능합니다.(마스터외 미노출)
              </LAWarningLabel>
            </LAGridContainer>
          </LAProperty>
        </LACard>
      ) : (
        ""
      )}

      {/* 클래스 금액 */}
      <LACard next>
        <LACardTitle title="클래스 금액" />

        <LAProperty name="클래스 금액" required>
          <LAWarningLabel>
            금액 입력 시, 할인율은 자동 계산되며 소수점인 경우, 버림으로
            계산되어 프런트에 노출됩니다.
          </LAWarningLabel>
          <LAGridContainer pt={2}>
            <LASimpleTable tableData={priceTableData} />
          </LAGridContainer>
          <LAGridContainer>
            <LAGridItem wPercent={40}>
              <LARadioGroup
                props={[
                  { text: "할인 없음", value: 0 },
                  { text: "선생님 할인", value: 1 },
                  { text: "라이브올 할인", value: 2 },
                ]}
                value={discountType.state}
                onChange={discountType.handleChange}
              />
            </LAGridItem>
            <LAWarningLabel>
              ‘선생님 할인’ 선생님이 부담하여 할인 진행, ‘라이브올 할인’
              라이브올에서 부담하여 할인 진행하는 것을 의미합니다.
            </LAWarningLabel>
          </LAGridContainer>
        </LAProperty>

        {/* 2023-08-18 마일리지 UI 추가 */}
        <LAProperty name="웅진 멤버십 사용">
          <LASimpleTable tableData={rateTableData} />
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 이미지 정보" />
        <LAProperty name="클래스 이미지 정보(섬네일)" required>
          <LARadioGroup
            props={[
              { text: "기본 이미지(손)", value: 0 },
              { text: "기본 이미지(우주)", value: 1 },
              { text: "기본 이미지(무지개)", value: 2 },
              { text: "기본 이미지(클래스)", value: 3 },
              { text: "직접 등록", value: 4 },
            ]}
            value={thumbnailType.state}
            onChange={onThumbnailChange}
          />
          <LAGridContainer>
            <LAImage
              wPercent={30}
              file={
                thumbnailType.state != 4
                  ? defaultClassImages[thumbnailType.state]
                    ? defaultClassImages[thumbnailType.state].file_url
                    : EmptyImage
                  : thumbnailFile.state.file
                  ? thumbnailFile.state.file
                  : EmptyImage
              }
            />
            {thumbnailType.state == 4 && (
              <LAGridItem wPercent={60} layouts={{ ml: 2 }}>
                <LAGridContainer layouts={{ ml: 1, pl: 1 }}>
                  <LAFileAttachment
                    hiddenLabel
                    placeholder=""
                    props={{
                      accept: "image/jpg,image/png,image/jpeg,image/gif",
                    }}
                    value={
                      thumbnailFile.state.name
                        ? encodeURIComponent(thumbnailFile.state.name)
                        : ""
                    }
                    onChange={thumbnailFile.handleChange}
                  />
                </LAGridContainer>
                <MDBox color="text" fontSize="1rem" lineHeight={1}>
                  <LALabel
                    layouts={{ pl: 1, pt: 1 }}
                    props={{
                      variant: "caption",
                      color: "error",
                      fontWeight: "regular",
                      verticalAlign: "middle",
                      sx: {
                        color: "#ff0000",
                      },
                    }}
                  >
                    - 이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로
                    등록됩니다. <br />
                    - RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가) <br />
                    - 이미지 사이즈 : 1000 * 1000 / 형식 : jpg, jpeg, png (jpeg
                    등록 권장) <br />- 2MB 이하로 등록가능
                  </LALabel>
                </MDBox>
              </LAGridItem>
            )}
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="추가 이미지">
          <LAGridContainer>
            <LAFileAttachment
              wPercent={20}
              hiddenLabel
              hiddenInput
              placeholder=""
              props={{ accept: "image/jpg,image/png,image/jpeg,image/gif" }}
              value={encodeURIComponent(additionalFile.state.name)}
              onChange={onAdditionalImageChange}
            />
            <LAWarningLabel
              wPercent={80}
              layouts={{ pl: 5 }}
              props={{
                variant: "caption",
                color: "error",
                fontWeight: "regular",
                verticalAlign: "middle",
                sx: {
                  fontSize: "1px",
                  color: "#ff0000",
                },
              }}
            >
              대표 이미지와 동일한 스펙으로 업로드 가능하며 최대 10개까지 등록
              가능합니다.
            </LAWarningLabel>
          </LAGridContainer>
          <LAImageList
            images={additionalImages}
            layouts={{ pt: 1, height: "160px" }}
            onRemoveClick={onRemoveAdditionalImage}
            showDelete
          />
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 소개" />
        <LAProperty name="클래스 소개" required>
          <LATextEditor name="inform" getEditorValue={getInformEditorValue} />
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="준비물 & 유의사항" />
        <LAProperty name="준비물 & 유의사항">
          <LATextEditor
            name="curriculum"
            getEditorValue={getCurriculumEditorValue}
          />
        </LAProperty>
        <LAProperty name="수업에 포함되어 있어요">
          <LAInput
            placeholder="수업에 포함된 내역(ex. 교재)"
            value={included.state}
            onChange={included.handleChange}
          />
        </LAProperty>
        <LAProperty name="따로 챙겨주세요">
          <LAInput
            placeholder="따로 챙겨야할 준비물 입력 (ex. 가위, 칼, 풀, 스케치북)"
            value={materials.state}
            onChange={materials.handleChange}
          />
        </LAProperty>
        <LAProperty name="클래스 전 꼭 확인해주세요" required>
          <LATextEditor name="notice" getEditorValue={getNoticeEditorValue} />
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 키워드" />

        <LAProperty name="키워드 선택">
          <LAKeywords
            keywords={keywordList}
            selectedKeywords={keywordsSelector.state}
            onClick={onKeywordListChange}
          />
        </LAProperty>
        <LAProperty name="키워드 직접 입력" required>
          <LAGridContainer>
            <LAInput
              wPercent={30}
              layouts={{ pr: { sm: 1 } }}
              placeholder="키워드 텍스트 입력 (ex. #라이브올 #섬세한 #재밌는 등)"
              value={keywordInput.state}
              onChange={keywordInput.handleChange}
              onEnterKeyPressed={onEnterKeyAtKeyword}
            />
            <LAInput wPercent={1} props={{ sx: { display: "none" } }} />
            <LAButton
              wPercent={10}
              layouts={{ pr: { sm: 1 } }}
              onClick={onAddKeywordClick}
            >
              추가
            </LAButton>
          </LAGridContainer>
          {selectedKeywords && selectedKeywords.length > 0 && (
            <LAGridItem wPercent={30}>
              <LADeletableListView
                layouts={{ pt: 1 }}
                items={selectedKeywords}
                onDeleteItem={onDeleteKeyword}
              />
            </LAGridItem>
          )}
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 학습자 다운로드 자료 정보" />

        <LAProperty name="자료 등록">
          <LAGridContainer>
            <LAFileAttachment
              wPercent={20}
              hiddenLabel
              hiddenInput
              placeholder=""
              props={{ accept: ".pdf" }}
              value={classFile.state.name}
              onChange={onClassFileChange}
            />
            <LAWarningLabel
              wPercent={80}
              props={{
                variant: "caption",
                color: "error",
                fontWeight: "regular",
                verticalAlign: "middle",
                sx: {
                  fontSize: "1px",
                  color: "#ff0000",
                },
              }}
            >
              pdf 확장자만 등록 가능하며, 최대 5건 / 전체 용량 100MB까지 첨부
              가능합니다.
            </LAWarningLabel>

            {classFiles && classFiles.length > 0 && (
              <LAGridItem wPercent={70} pt={1}>
                <LADeletableListView
                  layouts={{ pt: 1 }}
                  items={classFileInfos}
                  onDeleteItem={onClassFileDelete}
                />
              </LAGridItem>
            )}
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LACard next component="div">
        <LACardTitle title="클래스 교재 정보" />
        <LAProperty name="교재">
          <LAGridContainer>
            <LAButton
              wPercent={15}
              onClick={() => textbookSearchModal.visible(true)}
              props={{
                disabled:
                  textbookDeliveryType === 2 ||
                  textbookTableData.rows.length > 0,
              }}
            >
              웅진씽크빅 교재 발송
            </LAButton>
            <LAButton
              wPercent={15}
              onClick={addAppendix}
              layouts={{ ml: { sm: 1 } }}
              props={{
                disabled:
                  textbookDeliveryType === 1 ||
                  textbookTableData.rows.length > 0,
              }}
            >
              선생님 개별 교재 발송
            </LAButton>
            <LAWarningLabel wPercent={60} layouts={{ pl: { sm: 5 } }}>
              최대 1개까지 등록 가능합니다.
            </LAWarningLabel>
          </LAGridContainer>

          {textbooks.length > 0 && (
            <LAGridContainer layouts={{ pt: 1 }}>
              <LASimpleTable tableData={textbookTableData} />
            </LAGridContainer>
          )}
        </LAProperty>
      </LACard>

      {authMemberType == 1 && authAdminAuthType == 1 ? (
        <LACard next component="div">
          <LACardTitle title="클래스 즐겨찾기(마스터 관리자 only)" />
          <LAProperty name="클래스 즐겨찾기">
            <LAGridContainer>
              <LAInput
                wPercent={20}
                value={favoriteCount.state}
                onChange={favoriteCount.handleChange}
              />
              <LAWarningLabel wPercent={80} layouts={{ pl: { sm: 5 } }}>
                마스터 관리자만 수정 가능합니다.(마스터외 미노출)
              </LAWarningLabel>
            </LAGridContainer>
          </LAProperty>
        </LACard>
      ) : (
        ""
      )}

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ mr: 1 }}
          variant={"outlined"}
          btnPaddingY={12}
          onClick={onClickList}
        >
          목록
        </LAButton>
        <LAButton wPercent={15} layouts={{ ml: 1 }} onClick={onRegistClick}>
          등록 하기
        </LAButton>
      </LAGridContainer>

      <TeacherSearchModal
        show={teacherSearchModal.state}
        handleClose={teacherSearchModal.handleClose}
        handleSelect={onTeacherSelected}
      />

      <ClassMaterialCodeModal
        show={classMaterialCodeModal.state}
        handleClose={classMaterialCodeModal.handleClose}
        handleSelect={onTeacherSelected}
      />

      <TextbookSearchModal
        show={textbookSearchModal.state}
        handleClose={textbookSearchModal.handleClose}
        handleSelect={onTextbookSelected}
      />
    </>
  );
}

export default TogetherClassRegistContents;
