import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useCommonQuery from "common/hooks/useCommonQuery";

import { getTodayDateText } from "common/utils";

import { useLoginContext } from "common/context/MemberContext";
import { useApiContext } from "common/context/ApiContext";
import useCommonMutation from "common/hooks/useCommonMutation";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import { getDuplicateClassSchedules } from "common/utils/EtcUtils";
import pxToRem from "assets/theme/functions/pxToRem";
import moment from "moment";
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LACodeRadioGroup,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADeletableListView,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useModalPopup,
  useKeywords,
  useCheckbox,
  LAImage,
} from "modules/Widgets";

function UrlModal({
  requestScheduleDetail,
  timetable,
  classSeq,
  classroomSeq,
  show,
  handleClose,
}) {
  const { showAlert, showConfirm } = usePopAlert();
  const { queries } = useApiContext();

  const [url, setUrl] = useState(null);
  const urlRef = useRef(url);

  useEffect(() => {
    console.log(classroomSeq);
  }, []);

  useEffect(() => {
    urlRef.current = url;
  }, [url]);

  const { request: requestUrl } = useCommonQuery({
    query: queries.Url.editUrl,
    params: {
      schedule_seq: timetable.schedule_seq,
      timetable_seq: timetable.timetable_seq,
      classroom_seq: classroomSeq,
      class_seq: classSeq,
      origin_url: timetable.origin_url || null,
      modify_url: urlRef.current,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "url변경에 성공했습니다.");
          requestScheduleDetail();
        } else showAlert("알림", "url변경에 실패했습니다.");

        urlRef.current = null;
        handleClose();
      },
      onError: (error) => {
        showAlert("알림", "url변경에 실패했습니다.");
        urlRef.current = null;
        handleClose();
      },
    },
    initEnabled: false,
  });

  const cancleClick = () => {
    handleClose();
  };

  const okClick = () => {
    const afterUrl = urlRef.current;
    if (afterUrl) {
      showConfirm("알림", "수업 URL을 변경하시겠습니까?", {
        confirmHandler: () => {
          requestUrl();
        },
      });
    } else showAlert("알림", "변경 URL을 입력하세요.");
  };
  return (
    <LAModal title="" width="70%" height="450px" show={show}>
      <LACard>
        <LACardTitle title="수업방 참여 URL변경" />
        <LAProperty name="현재 URL">{timetable?.origin_url}</LAProperty>
        <LAProperty name="변경 URL">
          <LAInput
            placeholder="변경할 URL을 입력해 주세요."
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </LAProperty>
      </LACard>

      <LAGridContainer pt={3}>
        <LAGridItem wPercent={34} />
        <LAButton
          wPercent={15}
          layouts={{ pr: { sm: 0.5 } }}
          onClick={cancleClick}
        >
          취소
        </LAButton>
        <LAButton wPercent={15} layouts={{ pl: { sm: 0.5 } }} onClick={okClick}>
          변경 완료
        </LAButton>
      </LAGridContainer>
    </LAModal>
  );
}

export default React.memo(UrlModal);
