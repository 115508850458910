import React, { useState, useMemo, useCallback, useEffect } from 'react';
// import MDBox from 'components/MDBox';
import LAGridContainer from './LAGridContainer';
// import LAGridItem from './LAGridItem';
import LALabel from './LALabel';
// import LAButton from './LAButton';
// import LACheckbox from './LACheckbox';
// import LAInput from './LAInput';
import pxToRem from 'assets/theme/functions/pxToRem';
import { LADatePickerButton, LASimpleTableRow, LAPagination } from '.';

function LASimpleTable({
  tableData,
  isPagination = false,
  pagePerCount = 10,
  isSelectedCell,
  onSelectCell,
  props = {},
  initValue,
}) {
  const [page, setPage] = useState(1);
  const {
    headers,
    wPercentList,
    rows,
    headerBackgroundColor,
    rowBackgroundColor,
  } = tableData;

  useEffect(() => {
    setPage(1);
  }, [initValue]);

  const radiusValue = useMemo(() => {
    let count = 0;
    let radius = 1;
    count += headers && headers.length > 0 ? 1 : 0;
    count += rows && rows.length > 0 ? rows.length : 0;

    if (count <= 3) {
      radius = 1;
    } else {
      radius = 3;
    }
    return radius;
  }, [headers, rows]);

  const paginationRows = useMemo(() => {
    if (!isPagination) {
      return rows;
    }
    const index = (page - 1) * pagePerCount;
    // const index = page / pagePerCount;
    return rows.slice(index, index + pagePerCount);
  }, [rows, page, pagePerCount, isPagination]);

  const handlePageChange = useCallback((index) => {
    setPage(index);
  }, []);

  if (!tableData) {
    return null;
  }

  return (
    <>
      <LAGridContainer
        layouts={{
          sx: {
            border: '1px solid #dddddd',
            borderRadius: radiusValue,
            overflow: 'auto',
            ...props,
          },
        }}
      >
        {/* header */}
        <LAGridContainer
          vAlign="center"
          layouts={{
            px: 2,
            sx: {
              backgroundColor: headerBackgroundColor
                ? headerBackgroundColor
                : '#eeeeee',
              borderBottom:
                !!headers && headers.length > 0
                  ? '1px solid #dddddd'
                  : undefined,
            },
          }}
        >
          {headers &&
            headers.map((header, index) => (
              <LALabel
                key={index+''}
                wPercent={wPercentList[index]}
                layouts={{
                  px: 2,
                  sx: { textAlign: header.align ? header.align : 'center' },
                }}
                props={{
                  fontSize: pxToRem(13),
                }}
              >
                {header.text}
              </LALabel>
            ))}
        </LAGridContainer>

        {/* body */}
        {isPagination
          ? paginationRows &&
            paginationRows.map((row, rowIndex) => (
              <LASimpleTableRow
                key={rowIndex}
                row={row}
                rowIndex={rowIndex}
                wPercentList={wPercentList}
                onSelectCell={onSelectCell}
                isSelectedCell={isSelectedCell}
                layouts={{
                  sx: {
                    backgroundColor: rowBackgroundColor
                      ? rowBackgroundColor
                      : undefined,
                    borderBottom:
                      rowIndex === paginationRows.length - 1
                        ? undefined
                        : '1px solid #dddddd',
                    minHeight: "38px",
                  },
                  px: 2,
                  pb: 0.3,
                }}
              />
            ))
          : rows &&
            rows.map((row, rowIndex) => (
              <LASimpleTableRow
                key={rowIndex}
                row={row}
                rowIndex={rowIndex}
                wPercentList={wPercentList}
                onSelectCell={onSelectCell}
                isSelectedCell={isSelectedCell}
                layouts={{
                  sx: {
                    backgroundColor: rowBackgroundColor
                      ? rowBackgroundColor
                      : undefined,
                    borderBottom:
                      rowIndex === rows.length - 1
                        ? undefined
                        : '1px solid #dddddd',
                    minHeight: "38px",
                  },
                  px: 2,
                  pb: 0.3,
                }}
              />
            ))}
      </LAGridContainer>

      {isPagination && rows.length > pagePerCount && (
        <LAGridContainer
          layouts={{
            pt: 1,
            pr: 2,
          }}
        >
          <LAPagination
            items={rows}
            pagePerCount={pagePerCount}
            page={page}
            onPageChange={handlePageChange}
          />
        </LAGridContainer>
      )}
    </>
  );
}

export default React.memo(LASimpleTable);
