import React, { useState, useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";

import { useLoginContext } from "common/context/MemberContext";
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import ScheduleTimeTableList from "../components/ScheduleTimeTableList";
import ScheduleHistoryTableList from "../components/ScheduleHistoryTableList";
import ApplicationList from "../components/ApplicationList";

import TimetableModifyModal from "../components/TimetableModifyModal";

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  // LACodeRadioGroup,
  LARadioGroup,
  LAButton,
  // LAInput,
  LAWarningLabel,
  useRadioGroup,
  useModal,
} from "modules/Widgets";
import TimetableSupplementModal from "./TimetableSupplementModal";
import ClassmateAttendanceModal from "./ClassmateAttendanceModal";
import TimetableTerminatationModal from "./TimetableTerminatationModal";
import UrlModal from "./UrlModal";
import UrlHistoryTableList from "./UrlHistoryTableList";

function ClassScheduleDetailContents({ scheduleSeq, onClickList }) {
  const navigate = useNavigate();
  const { showAlert, showConfirm } = usePopAlert();
  const { queries } = useApiContext();

  const [data, setData] = useState(null);

  const [selectedTimetable, setSelectedTimetable] = useState({});
  const [statusQuery, setStatusQuery] = useState();

  const [classStatus, setClassStatus] = useState(0);
  const [newClassStatus, setNewClassStatus] = useState(0);

  const receptionStatus = useRadioGroup(0);

  const timetableModifyModal = useModal(false);
  const timetableSupplementModal = useModal(false);
  const timetableTerminationModal = useModal(false);
  const attendanceModal = useModal(false);

  const urlModal = useModal(false);

  const { action } = useLoginContext();
  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  const getTime = useCallback((schedule) => {
    if (schedule) {
      if (schedule.timetables && schedule.timetables.length > 0)
        return moment(
          schedule.start_dt + " " + schedule.timetables[0].schedule_time
        ).format("a hh:mm");
    } else return "00:00";
  });

  const getDate = useCallback((schedule) => {
    if (schedule) {
      return moment(schedule.start_dt).format("YYYY-MM-DD (dd)");
    }
    return "0000-00-00";
  });

  useEffect(() => {
    requestScheduleDetail();
  }, []);

  useEffect(() => {
    if (data) {
      if (data.reception_status) receptionStatus.update(data.reception_status);

      if (data.close_yn === 1) setClassStatus(3);
      else if (data.finish_yn === 1) setClassStatus(2);
      else if (data.confirmation_yn === 1) setClassStatus(1);
      else setClassStatus(0);
    }
  }, [data]);

  const { request: requestScheduleDetail } = useCommonQuery({
    query: queries.Schedule.getDetail,
    params: scheduleSeq,
    callbacks: {
      onSuccess: (data) => {
        console.log("ClassScheduleDetail.requestScheduleDetail =>", data);
        if (data.result_code === "0000") {
          setData(data.result_data);
        } else showAlert("알림", data.result_message);
      },
      onError: (err) => {
        showAlert("알림", "클래스 일정 조회에 실패하였습니다.");
      },
    },
  });

  const { request: changeScheduleState } = useCommonMutation(
    {
      query: statusQuery,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === "0000") {
            showAlert("알림", "클래스 상태가 변경되었습니다.");
            requestScheduleDetail();
          } else showAlert("알림", data.result_message);
        },
        onError: (error) => {
          showAlert("알림", "클래스 상태 변경에 실패하였습니다.");
        },
      },
    },
    [statusQuery]
  );

  const { request: changeReceptiontate } = useCommonMutation({
    query: queries.Schedule.changeReceptionState,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "클래스 접수 상태가 변경되었습니다.");
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "클래스 접수 상태 변경에 실패하였습니다.");
      },
    },
  });

  const { request: changeCurrentTimetable } = useCommonMutation(
    {
      query: queries.Schedule.changeCurrentTimetable,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === "0000") {
            showAlert("알림", "클래스 상태가 변경되었습니다.");
            requestScheduleDetail();
            // setClassStatus(newClassStatus);
          } else {
            showAlert("알림", data.result_message);
          }
        },
        onError: (error) => {
          showAlert("알림", "클래스 상태 변경에 실패하였습니다.");
        },
      },
    },
    [statusQuery]
  );

  const onStatusChangeClick = useCallback(
    (newtatus) => {
      let message = "확정 처리 하시겠습니까?";
      let query = queries.Schedule.confirmSchedule;
      if (newtatus === 0) {
        message = "미확정 처리 하시겠습니까?";
        query = queries.Schedule.confirmSchedule;
      } else if (newtatus === 3) {
        message =
          "폐강 처리 하시겠습니까?\n폐강 처리시 신청서는 모두 취소됩니다.";
        query = queries.Schedule.closeSchedule;
      }

      setStatusQuery(query);
      showConfirm("확인", message, {
        confirmHandler: () => {
          setNewClassStatus(newtatus);
          if (data) {
            changeScheduleState({
              schedule_seq: data.schedule_seq,
              data: { isForce: true },
            });
          }
        },
      });
    },
    [data]
  );

  const onReceptionChangeClick = useCallback(() => {
    let message = "접수 상태를 변경하시겠습니까?";
    if (receptionStatus.state === 2) {
      message =
        "접수마담 상태가되면 더이상 신청 접수를 받을 수 없습니다.\n접수마감 상태로 변경하시겠습니까?";
    }
    showConfirm("확인", message, {
      confirmHandler: () => {
        changeReceptiontate({
          schedule_seq: data.schedule_seq,
          data: { isForce: true, state: receptionStatus.state },
        });
      },
    });
  }, [data, receptionStatus.state]);

  const onScheduleModify = useCallback(() => {
    requestScheduleDetail();
  });

  const onChangedTimetables = (timetable) => {
    showAlert(
      "알림",
      "클래스를 신청한 고객과 사전 협의 후 일정을 변경해주세요.\n무단변경 시 패널티가 부여될 수 있습니다.",
      {
        confirmHandler: () => {
          setSelectedTimetable(timetable);
          if (timetable.abnormal_yn === 1)
            timetableSupplementModal.visible(true);
          else timetableModifyModal.visible(true);
        },
      }
    );
  };

  const onChangeCurrentTimetable = useCallback(
    (timetable_seq) => {
      showConfirm("확인", "선택한 수업을 현재 수업으로 지정하시겠습니까?", {
        confirmHandler: () => {
          changeCurrentTimetable({
            schedule_seq: data.schedule_seq,
            data: {
              classroom_seq: data.classroom?.classroom_seq,
              timetable_seq: timetable_seq,
            },
          });
        },
      });
    },
    [data]
  );

  const onViewTimetableResult = (timetable) => {
    setSelectedTimetable(timetable);
    attendanceModal.visible(true);
  };

  const onTimetableDone = (timetable) => {
    setSelectedTimetable(timetable);
    timetableTerminationModal.visible(true);
  };

  const [urlData, setUrlData] = useState(null);

  const onUrlEdit = async (timetable) => {
    const isConfirm = data.confirmation_yn; //0,1

    if (isConfirm) {
      setUrlData(timetable);
      urlModal.visible(true);
    } else showAlert("알림", "확정된 수업만 변경할 수 있습니다.");
  };

  return (
    <>
      <LACard next>
        <LACardTitle title="클래스 기본 정보" />
        <LAProperty name="클래스 명">
          <LALabel wPercent={100}>{!!data && data.class.class_name}</LALabel>
        </LAProperty>
        <LAProperty name="카테고리">
          <LALabel wPercent={100}>{!!data && data.class.category_name}</LALabel>
        </LAProperty>
        <LAProperty name="클래스 유형">
          <LALabel>{!!data && data.class.class_type_name}</LALabel>
        </LAProperty>
        <LAProperty name="선생님">
          <LALabel wPercent={100}>{!!data && data.class.teacher_name}</LALabel>
        </LAProperty>
      </LACard>
      <LACard next>
        <LACardTitle title="일정 상태 정보" />
        <LAProperty name="일정 상태">
          <LAGridContainer vAlign="center">
            <LALabel wPercent={12}>
              {data && data.close_yn
                ? "폐강 상태"
                : data && data.confirmation_yn
                ? "확정 상태"
                : "미확정 상태"}
            </LALabel>

            {authMemberType && authAdminAuthType ? (
              <>
                <LAButton
                  wPercent={12}
                  color={
                    data && !data.confirmation_yn && !data.close_yn
                      ? "info"
                      : "light"
                  }
                  onClick={() =>
                    data && !data.confirmation_yn && !data.close_yn
                      ? onStatusChangeClick(2)
                      : {}
                  }
                  layouts={{ mr: { sm: 1 } }}
                >
                  확정
                </LAButton>
                <LAButton
                  wPercent={12}
                  color={data && !data.close_yn ? "info" : "light"}
                  onClick={() =>
                    data && !data.close_yn ? onStatusChangeClick(3) : {}
                  }
                  layouts={{ mr: { sm: 1 } }}
                >
                  폐강
                </LAButton>
              </>
            ) : (
              ""
            )}
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="접수 상태">
          <LAGridContainer vAlign="center">
            <LAGridItem wPercent={37.5}>
              <LARadioGroup
                props={[
                  {
                    text: "접수 대기",
                    value: 0,
                  },
                  {
                    text: "접수 중",
                    value: 1,
                  },
                  {
                    text: "접수 마감",
                    value: 2,
                  },
                ]}
                value={receptionStatus.state}
                onChange={receptionStatus.handleChange}
              />
            </LAGridItem>
            <LAGridItem wPercent={12}>
              <LAButton onClick={onReceptionChangeClick}>변경</LAButton>
            </LAGridItem>
          </LAGridContainer>
        </LAProperty>
      </LACard>
      <LACard next>
        <LACardTitle title="클래스 일정 상세 정보" />

        <LAProperty name="일정 생성일">
          <LALabel wPercent={40}>
            {data && data.created_dt
              ? moment(data.created_dt).format("YYYY-MM-DD (dd) A hh:mm")
              : ""}
          </LALabel>
        </LAProperty>

        <LAProperty name="일정 시작일">
          <LALabel wPercent={40}>{getDate(data)}</LALabel>
        </LAProperty>

        <LAProperty name="수업 시간">
          <LALabel wPercent={40}>{getTime(data)}</LALabel>
        </LAProperty>
        <LAProperty name="상세 수업 일정">
          {!!data ? (
            <ScheduleTimeTableList
              timetables={!!data && data.timetables}
              onChange={onChangedTimetables}
              onViewResult={onViewTimetableResult}
              onDone={onTimetableDone}
              schedule={data}
              onChangeTimetable={onChangeCurrentTimetable}
              onUrlEdit={onUrlEdit}
            />
          ) : (
            ""
          )}
          <LAWarningLabel>
            이미 지난 클래스 변경 시 ’보강’, 아직 지나지 않은 클래스 변경 시
            ‘변경’으로 구분됩니다. 프론트는 보강, 변경 구분없이 보강으로
            표시됩니다.
          </LAWarningLabel>
        </LAProperty>
        <LAProperty name="수업 일정 변경 이력">
          {!!data ? (
            <ScheduleHistoryTableList histories={!!data && data.histories} />
          ) : (
            ""
          )}
        </LAProperty>
        <LAProperty name="수업 URL 변경 이력">
          {!!data ? (
            <UrlHistoryTableList histories={!!data && data.urlHistory} />
          ) : (
            ""
          )}
        </LAProperty>
      </LACard>
      <LACard next>
        <LACardTitle title="일정 신청자 목록" />
        <LAGridContainer layouts={{ mt: { sm: 1 } }}>
          <ApplicationList applications={!!data && data.applications} />
        </LAGridContainer>
      </LACard>
      <LAGridContainer layouts={{ pt: { sm: 2 }, pb: { sm: 1 } }}>
        <LALabel wPercent={44}></LALabel>
        <LAButton
          wPercent={12}
          layouts={{ pr: { sm: 1 } }}
          variant={"outlined"}
          onClick={onClickList}
        >
          목록
        </LAButton>
      </LAGridContainer>
      <TimetableModifyModal
        timetables={!!data && data.timetables}
        selectedTimetable={selectedTimetable}
        createdDate={!!data && data.created_dt}
        teacherSchedules={data && data.teacherSchedules}
        className={data && data.class.class_name}
        classTime={data && data.class.class_time}
        show={timetableModifyModal.state}
        handleClose={timetableModifyModal.handleClose}
        onChange={onScheduleModify}
      />
      <TimetableSupplementModal
        timetables={!!data && data.timetables}
        selectedTimetable={selectedTimetable}
        createdDate={!!data && data.created_dt}
        teacherSchedules={data && data.teacherSchedules}
        className={data && data.class.class_name}
        classTime={data && data.class.class_time}
        show={timetableSupplementModal.state}
        handleClose={timetableSupplementModal.handleClose}
        onChange={onScheduleModify}
      />
      <ClassmateAttendanceModal
        timetableSeq={selectedTimetable.timetable_seq}
        show={attendanceModal.state}
        handleClose={attendanceModal.handleClose}
      />
      <TimetableTerminatationModal
        classType={data?.class?.class_type}
        timetable={selectedTimetable}
        show={timetableTerminationModal.state}
        onChange={onScheduleModify}
        handleClose={timetableTerminationModal.handleClose}
      />
      {urlData && (
        <UrlModal
          requestScheduleDetail={requestScheduleDetail}
          timetable={urlData}
          classSeq={data?.class_seq}
          classroomSeq={data.classroom?.classroom_seq}
          show={urlModal.state}
          handleClose={urlModal.handleClose}
        />
      )}
    </>
  );
}
export default ClassScheduleDetailContents;
