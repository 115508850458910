import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import useCommonMutation from "./useCommonMutation";
import { useApiContext } from "common/context/ApiContext";

export default function usePasswordReset() {
  const { showAlert, showConfirm } = usePopAlert();
  const { queries } = useApiContext();

  const { request: requestInitAccountPassword } = useCommonMutation({
    query: queries.Account.initAccountPassword,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000")
          showAlert(
            "알림",
            `비밀번호 초기화가 완료 되었습니다.\n초기비밀번호는 1111 입니다.`
          );
        else showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "관리자 정보 변경에 실패했습니다.");
      },
    },
  });

  const passwordReset = (id, member_seq) => {
    showConfirm(
      "알림",
      `${id} 계정 비밀번호 초기화를 진행합니다.\n진행하시겠습니까?`,
      {
        confirmHandler: async () => {
          await requestInitAccountPassword({ member_seq });
        },
      }
    );
  };

  return passwordReset;
}
