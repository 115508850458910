import React, { useState, useCallback } from 'react';

import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import AdminRegistContents from './AdminRegistContents';

function AdminRegist() {

  return (
    <>
      <DashboardNavbar title="관리자 신규 등록" />

      <AdminRegistContents />

    </>
  );
}

export default AdminRegist;
