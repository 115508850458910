import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/mainMenu';

const MainMenuApi = (requestFunc) => {
    return {
        Menu : {
            getMainMenuList: {
                key: 'getMainMenuList',
                queryFn: () => requestFunc(METHOD.GET, `${baseURL}`)
            },
            saveMainMenuList: {
                key: 'saveMainMenuList',
                queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}/saveAll`, data)
            },
        }
    };
};

export default MainMenuApi;
