import React from 'react';
import LAGridContainer from './LAGridContainer';
import LAPropertyName from './LAPropertyName';
import LAGridItem from './LAGridItem';

function LAProperty({ pt=1.5, pb=0, wNameWidth = 15, required = false, name, children }) {
  return (
    <>
      <LAGridContainer pt={pt} pb={pb}>
        <LAGridItem wPercent={wNameWidth}>
          <LAPropertyName required={required}>{name}</LAPropertyName>
        </LAGridItem>
        <LAGridItem wPercent={100 - wNameWidth}>{children}</LAGridItem>
      </LAGridContainer>
    </>
  );
}

export default React.memo(LAProperty);
