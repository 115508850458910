import moment from "moment";

export function toCurrency(value) {
  if (typeof value !== "number") {
    value = parseInt(value);
  }

  const formatter = new Intl.NumberFormat("en-KR", {
    minimumFractionDigits: 0,
  });

  return formatter.format(value);
}

export function toPhone(value) {
  if (!value) return "";
  value = value.replace(/[^0-9]/g, "");

  let result = "";
  if (value.length < 4) {
    result = value;
  } else if (value.length < 7) {
    result = value.replace(/(\d{3})(\d{3})/, "$1-$2");
  } else if (value.length === 8) {
    result = value.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else if (value.length < 10) {
    if (value.substr(0, 2) === "02") {
      //02-123-5678
      result = value.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
    } else {
      result = value.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
    }
  } else if (value.length < 11) {
    if (value.substr(0, 2) === "02") {
      //02-1234-5678
      result = value.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      //010-123-4567
      result = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  } else if (value.length > 11) {
    result = value.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
  } else {
    //010-1234-5678
    result = value.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return result;
}

export function toTime(value, splitter = ":") {
  if (!value) return "";

  if (typeof value === "object") {
    return `${value.getHours()}${splitter}${value.getMinutes()}${splitter}${value.getSeconds()}`;
  }

  let result = "";
  if (value.length === 4) {
    result = value.replace(/(\d{2})(\d{2})/, "$1:$2");
    return result;
  } else if (value.length === 6) {
    result = value.replace(/(\d{2})(\d{2})(\d{2})/, "$1:$2:$3");
    return result;
  }
  return value;
}

export function toDate(value, splitter = ".") {
  if (!value) return "";

  if (typeof value === "object") {
    const values = value.toLocaleDateString().trim().split(".");
    let year = values[0].trim();
    let month = values[1].trim();
    let day = values[2].trim();
    month = month.length < 2 ? `0${month}` : month;
    day = day.length < 2 ? `0${day}` : day;
    return `${year}${splitter}${month}${splitter}${day}`;
  }

  let result = "";
  if (value.length === 8) {
    result = value.replace(
      /(\d{4})(\d{2})(\d{2})/,
      `$1${splitter}$2${splitter}$3`
    );
    return result;
  } else if (value.length === 6) {
    result = value.replace(
      /(\d{2})(\d{2})(\d{2})/,
      `$1${splitter}$2${splitter}$3`
    );
    return result;
  }
  return result;
}

export function toDateTime(value) {
  if (!value) return "";

  let result = "";
  if (value.length === 12) {
    result = value.replace(
      /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/,
      "$1.$2.$3 $4:$5"
    );
    return result;
  } else if (value.length === 10) {
    result = value.replace(
      /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      "$1.$2.$3 $4:$5"
    );
    return result;
  }
  return value;
}

export function toCardNumber(value) {
  if (!value) return "";

  let result = "";
  if (value.length === 16) {
    result = value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
    return result;
  }
  return value;
}

export function getTodayDateText(splitter = "") {
  return dateToString(new Date(), splitter);
}

export function getTodayDateTime() {
  const currDate = new Date();
  const dateText = toDate(currDate, "-");
  const timeText = toTime(currDate, ":");
  return dateText + " " + timeText;
}

export function dateToString(date, splitter = "") {
  if (!date || typeof date !== "object") {
    return "";
  }

  const year = `${date.getFullYear()}`;
  const month =
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  return year + splitter + month + splitter + day;
}

export function dateTimeToString(date, dateSplitter = "-", timeSplitter = ":") {
  if (!date || typeof date !== "object") {
    return "";
  }

  const year = `${date.getFullYear()}`;
  const month =
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  const hour =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  return (
    year +
    dateSplitter +
    month +
    dateSplitter +
    day +
    " " +
    hour +
    timeSplitter +
    minutes
  );
}

export function getHiddenNameText(name, startIndex = 1) {
  if (!name || name.length === 0) {
    return "";
  }

  let hiddenName = "";
  for (let i = startIndex; i < name.length; i++) {
    hiddenName += "*";
  }
  return name.substring(0, startIndex) + hiddenName;
}

export const getDateText = (value) => {
  return dateToString(new Date(value.replaceAll("Z", "")));
};

export const getDateTimeText = (value) => {
  return dateTimeToString(new Date(value.replaceAll("Z", "")));
};

export function getAge(birth) {
  if (!birth) {
    return 0;
  }

  const today = moment();
  const birthDate = moment(birth);
  const age =
    parseInt(today.format("YYYY")) - parseInt(birthDate.format("YYYY")) + 1;
  return age;
}

export const getDaysInMonth = (year, month) => {
  const month_ = parseInt(month);
  return new Date(year, month_, 0).getDate().toString().padStart(2, "0");
};
