import React from 'react';
import List from '@mui/material/List';
import LAGridItem from './LAGridItem';
import LADeletableListItem from './LADeletableListItem';

function LADeletableListView({ 
  wPercent = 100, 
  layouts = {}, 
  items,
  onDeleteItem }) {
  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <List
        component="nav"
        sx={{
          overflow: 'auto',
          border: '1px solid #d5d5d5',
          borderRadius: '5px',
        }}
       >
        {!!items &&
          items.map((item, index) => {
            return <LADeletableListItem 
                    seq={item.seq}
                    key={index}
                    index={index} 
                    item={item.value}
                    onDeleteItem={onDeleteItem}
                    />;
          })}
      </List>
    </LAGridItem>
  );
}

export default React.memo(LADeletableListView);
