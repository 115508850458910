import React, { useCallback } from 'react'
import LAGridItem from './LAGridItem';
import LALabel from './LALabel';
import LAButton from './LAButton';
import LACheckbox from './LACheckbox';
import LAInput from './LAInput';
import pxToRem from 'assets/theme/functions/pxToRem';
import LADatePickerButton from './LADatePickerButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

function LASimpleTableCell({cell, rowIndex, colIndex, wPercentList, onSelectCell, isSelectedCell}) {
    const getItemByType = useCallback(
      (cell, rowIndex, colIndex) => {
        if (!cell.type || cell.type === 'text') {
          return (
            <LALabel
              key={colIndex}
              wPercent={wPercentList[colIndex]}
              color={cell.color}
              layouts={{
                sx: {
                  textAlign: cell.align ? cell.align : 'center',
                  fontWeight: cell.weight ? cell.weight : 'regular',
                },
                px: 2,
              }}
              props={{
                color: cell.color,
                fontSize: pxToRem(12),
              }}
            >
              {cell.text}
            </LALabel>
          );
        } else if (cell.type === 'button') {
          return (
            <LAButton
              key={colIndex}
              wPercent={wPercentList[colIndex]}
              {...cell.callback}
              props={{
                fontSize: pxToRem(12),
              }}
              variant={cell.variant?cell.variant:'contained'}
              layouts={{
                px: 2,
              }}
            >
              {cell.text}
            </LAButton>
          );
        } else if (cell.type === 'date_button') {
          return (
            <LADatePickerButton
              key={colIndex}
              value={cell.value}
              wPercent={wPercentList[colIndex]}
              {...cell.callback}
              props={{
                fontSize: pxToRem(12),
              }}
              minDate={cell.minDate?cell.minDate:''}
              maxDate={cell.maxDate?cell.maxDate:''}
              layouts={{
                px: 2,
              }}
            >
              {cell.text}
            </LADatePickerButton>
          );
        } else if (cell.type === 'rate') {
            return (
                <OutlinedInput
                    key={colIndex}
                    wPercent={wPercentList[colIndex]}
                    placeholder={cell.placeholder}
                    {...cell.callback}
                    props={{
                        fontSize: pxToRem(12)
                    }}
                    endAdornment={<InputAdornment position="end">{cell.text}</InputAdornment>}
                    disabled={cell.disabled?cell.disabled:false}
                    size="small"
                    style={cell.style}
                />
            );
          } else if (cell.type === 'input') {
          return (
            <LAInput
              key={colIndex}
              wPercent={wPercentList[colIndex]}
              placeholder={cell.placeholder}
              {...cell.callback}
              props={{
                fontSize: pxToRem(12),
              }}
              disabled={cell.disabled?cell.disabled:false}
              layouts={{
                px: 2,
                pt: 0.3,
              }}
              // value={cell.text}
            />
          );
        } else if (cell.type.indexOf('select') === 0) {
          const values = cell.type.split(' ');
          let disabled = false;
  
          values.forEach((value) => {
            // checked = value === 'checked' ? true : checked;
            disabled = value === 'disabled' ? true : disabled;
          });
  
          return (
            <LACheckbox
              key={colIndex}
              wPercent={wPercentList[colIndex]}
              {...cell.callback}
              onClick={() => onSelectCell(rowIndex, colIndex)}
              value={isSelectedCell(rowIndex, colIndex)}
              props={{
                fontSize: pxToRem(12),
                disabled: disabled,
              }}
              layouts={{
                px: 2,
              }}
            />
          );
        }
  
        return null;
      },
      [isSelectedCell, onSelectCell, wPercentList],
    );
  return <>{getItemByType(cell, rowIndex, colIndex)}</>;
}

export default React.memo(LASimpleTableCell);