import { METHOD } from "common/hooks/useAxios";

const baseURL = "/admin/auth";

const AuthApi = (requestFunc) => {
  return {
    Auth: {
      // 인증 : accessToken 으로 로그인 진행
      join: {
        key: "join",
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/join`, params),
      },
      login: {
        key: "login",
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/login`, params),
      },
      kakaoLogin: {
        key: "kakaoLogin",
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}/kakao`, data),
      },
      loginout: {
        key: "loginout",
        queryFn: () => requestFunc(METHOD.GET, `${baseURL}/loginout`),
      },
      authorize: {
        key: "authorize",
        queryFn: () => requestFunc(METHOD.POST, `${baseURL}/authorize`),
      },
      changePassword: {
        key: "changePassword",
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/login/changePassword`, params),
      },
      getAuthCode: {
        key: "getAuthCode",
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/login/renew-auth-code`, params),
      },

      finalLogin: {
        key: "finalLogin",
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/login/additional`, params),
      },

      checkId: {
        key: "checkId",
        queryFn: (params) =>
          requestFunc(METHOD.POST, `${baseURL}/authorize`, params),
      },
    },
  };
};

export default AuthApi;
