import React, { useMemo, useEffect, useState, useCallback } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from 'react-table';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';

// components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';

// examples
import DataTableHeadCell from 'modules/Tables/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'modules/Tables/DataTable/DataTableBodyCell';
import DataTableRowItem from 'modules/Tables/DataTable/DataTableRowItem';

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  defaultPage = 0,
  currPage,
  onChangePage = () => {},
}) {
  const defaultValue = useMemo(() => {
    return entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  }, [entriesPerPage.defaultValue]);
  const entries = useMemo(() => {
    return entriesPerPage.entries
      ? entriesPerPage.entries.map((el) => el.toString())
      : ['5', '10', '15', '20', '25'];
  }, [entriesPerPage.entries]);
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => {
    setPageSize(defaultValue || 10);
    gotoPage(defaultPage || 0);
  }, [defaultValue, defaultPage]);

  useEffect(() => {
    if (currPage && currPage != pageIndex) {
      gotoPage(currPage);
    }
  }, [table, currPage, pageIndex]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = useCallback((value) => setPageSize(value), []);

  // Render the paginations
  const renderPagination = useMemo(() => {
    return pageOptions.map((option) => (
      <MDPagination
        item
        key={option}
        onClick={() => {
          gotoPage(Number(option));
          onChangePage(Number(option));
        }}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  // Handler for the input to set the pagination index
  const handleInputPagination = useCallback(
    ({ target: { value } }) => {

      if (value > pageOptions.length || value < 0) {
        gotoPage(0);
        onChangePage(0);
      } else {
        gotoPage(Number(value));
        onChangePage(Number(value));
      }
    },
    [pageOptions],
  );

  // Customized page options starting from 1
  const customizedPageOptions = useMemo(
    () => pageOptions.map((option) => option + 1),
    [pageOptions],
  );

  // Setting value for the pagination input
  const handleInputPaginationValue = useCallback(({ target: value }) => {

    gotoPage(Number(value.value - 1));
    onChangePage(Number(value.value - 1));
  }, []);

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = useCallback((column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  }, []);

  // Setting the entries starting point
  const entriesStart = useMemo(
    () => (pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1),
    [pageIndex, pageSize],
  );

  // Setting the entries ending point
  const entriesEnd = useMemo(() => {
    if (pageIndex === 0) {
      return pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
      return rows.length;
    } else {
      return pageSize * (pageIndex + 1);
    }
  }, [pageIndex, pageSize, pageOptions, rows]);

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;개씩 보기
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps(),
                  )}
                  width={column.width ? column.width : 'auto'}
                  // align={column.align ? column.align : 'center'}
                  align="center"
                  sorted={setSortedValue(column)}
                  handleChange={column.handleChange}
                  isChecked={column.allChecked}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page?.map((row, key) => {
            prepareRow(row);
            return (
              <DataTableRowItem
                rows={rows}
                row={row}
                key={key}
                noEndBorder={noEndBorder}
              />
            );
          })}
        </TableBody>
      </Table>
      <MDBox
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="caption"
              color="secondary"
              fontWeight="regular"
            >
              {entriesStart}에서{' '}
              {entriesEnd < rows.length ? entriesEnd : rows.length} 총{' '}
              {rows.length} 개
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : 'gradient'}
            color={pagination.color ? pagination.color : 'info'}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  previousPage();
                  onChangePage(pageIndex - 1);
                }}
              >
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 10 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: 'number',
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  nextPage();
                  onChangePage(pageIndex + 1);
                }}
              >
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 20, 50] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default React.memo(DataTable);
