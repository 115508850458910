import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import _ from "lodash";

import useApiQuery from "common/hooks/useApiQuery";
import PropTypes from "prop-types";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

import DataPagerHeadCell from "modules/Tables/DataPager/headCell";
import DataPagerBodyCell from "modules/Tables/DataPager/bodyCell";
import { LASelect, useComboBox } from "modules/Widgets";

function DataPager({
  entriesPerPage, // 페이지 사이즈 콤보 : { defaultValue: 10, entries: [5, 10, 20, 50]}
  showTotalEntries, // 페이지 사이즈 콤보 표시 여부
  loadUrl, // 검색할 URL
  params, //
  onDataLoadCallback, // 데이터 가공 return 값은 데이터여야 함.
  onPageChanged, // 페이지 변경시 페이지 이동 Callback으로 쓰일거임.
  table,
  pagination,
}) {
  // -------------------------------------------------------------------------------------------------------
  const { showAlert } = usePopAlert();

  const entries = useMemo(() => {
    let entries = entriesPerPage.entries
      ? entriesPerPage.entries
      : [5, 10, 15, 20, 25];
    return entries.map((entry) => {
      return { key: entry, value: entry };
    });
  }, [entriesPerPage.entries]);

  const [columns, setColumns] = useState(table.columns);
  const [pageData, setPageData] = useState([]);
  const [pageOptionList, setPageOptionList] = useState([]);
  const pageSize = useComboBox(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [previousPageActiveStatus, setPreviousPageActiveStatus] =
    useState(true);
  const [nextPageActiveStatus, setNextPageActiveStatus] = useState(true);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [orderType, setOrderType] = useState([]);

  const [tempCurrentPage, setTempCurrentPage] = useState(1);
  const [tempPageSize, setTempPageSize] = useState(10);
  const [tempOrderType, setTempOrderType] = useState([]);

  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    if (!searchParams) {
      return;
    }
    // setTempCurrentPage(1);
    setSearchParams({ ...params, page_per_count: tempPageSize });
  }, [params]);

  useEffect(() => {
    if (!searchParams) {
      return;
    }
    let sortKeyList = tempOrderType.map((item) => {
      return item.sortKey;
    });

    load({
      ...searchParams,
      current_page: tempCurrentPage,
      page_per_count: tempPageSize,
      orderType: sortKeyList,
    });
  }, [searchParams, tempCurrentPage, tempPageSize, tempOrderType]);

  useEffect(() => {
    let totalCount = setTotalPage();
    let pageList = [];

    for (let i = 0; i < totalCount; i++) {
      pageList.push(i + 1);
    }
    setPageOptionList(pageList);
  }, [totalItemCount, pageSize.state]);

  const setTotalPage = () => {
    let totalCount = Math.ceil(totalItemCount / pageSize.state);
    setTotalPageCount(totalCount);
    return totalCount;
  };

  useEffect(() => {
    setPreviousPageActiveStatus(currentPage !== 1);
    setNextPageActiveStatus(currentPage !== totalPageCount);
  }, [currentPage, totalPageCount]);

  const entriesStart = useMemo(
    () =>
      currentPage === 1 ? currentPage : (currentPage - 1) * pageSize.state,
    [currentPage, pageSize.state]
  );

  const entriesEnd = useMemo(() => {
    return currentPage == totalPageCount
      ? totalItemCount
      : (currentPage - 1) * pageSize.state + pageSize.state;
  }, [currentPage, pageSize.state, totalItemCount, totalPageCount]);

  const goToPage = useCallback((page) => {
    setTempCurrentPage(page);
  }, []);

  const goNextPage = useCallback(() => {
    if (!nextPageActiveStatus) {
      return;
    }

    setTempCurrentPage(currentPage + 1);
  }, [nextPageActiveStatus, currentPage]);

  const goPreviousPage = useCallback(() => {
    if (!previousPageActiveStatus) {
      return;
    }

    setTempCurrentPage(currentPage - 1);
  }, [previousPageActiveStatus, currentPage]);

  const changePageSize = useCallback((size) => {
    setTempPageSize(size);
  }, []);

  const updateColumnState = useCallback(() => {
    let tempOrderTypeSize = tempOrderType.length;

    let updateColumns = columns.map((item, $index) => {
      if (!item.sort) {
        return item;
      }

      if (!tempOrderTypeSize) {
        item.sortValue = "none";
        return item;
      }

      let filterData = tempOrderType.find((item) => item.index == $index);
      if (filterData) {
        item.sortValue = filterData.sortValue;
      } else {
        item.sortValue = "none";
      }

      return item;
    });

    setColumns([...updateColumns]);
  }, [columns, tempOrderType]);

  const { request: load } = useApiQuery({
    query: loadUrl,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code !== "0000") {
          showAlert("알림", data.result_message ?? "조회에 실패했습니다.");
          return;
        }
        console.log("load ::", data);
        setPageData(data.result_data.data);
        setTotalItemCount(data.result_data.total_count);
        setCurrentPage(tempCurrentPage);
        updateColumnState();
        pageSize.select(tempPageSize);
        setOrderType(tempOrderType);

        if (onPageChanged) {
          onPageChanged();
        }
      },
      onError: (error) => {
        showAlert("알림", "조회에 실패했습니다.");
      },
    },
  });

  const renderPagination = useMemo(() => {
    return pageOptionList.map((option) => (
      <MDPagination
        item
        key={option}
        onClick={() => {
          goToPage(option);
        }}
        active={currentPage === option}
      >
        {option}
      </MDPagination>
    ));
  }, [pageOptionList, currentPage]);

  const generateRow = (rowData, $index) => {
    let row = [];
    let procData = onDataLoadCallback(rowData, $index);

    for (let i = 0; i < columns.length; i++) {
      let { accessor, align } = columns[i];
      row.push(
        <DataPagerBodyCell
          key={accessor + "_" + $index}
          className={accessor}
          align={align ? align : "center"}
        >
          <>{procData[accessor]}</>
        </DataPagerBodyCell>
      );
    }
    return row;
  };

  const onSortChanged = useCallback(
    (column, $index) => {
      if (!column.sort) {
        return;
      }

      let targetSortValue = "none";
      if (column.sortValue == "none") {
        targetSortValue = "asc";
      } else if (column.sortValue == "asc") {
        targetSortValue = "desc";
      }

      let tempOrderTypeData;
      if (tempOrderType.find((item) => item.index == $index)) {
        tempOrderTypeData = tempOrderType.reduce((result, item) => {
          if (item.index != $index) {
            result.push(item);
            return result;
          }

          if (targetSortValue == "none") {
            return result;
          }

          item.sortValue = targetSortValue;
          item.sortKey = _.camelCase(column.accessor + "-" + targetSortValue);
          result.push(item);

          return result;
        }, []);
      } else {
        tempOrderTypeData = [
          ...tempOrderType,
          {
            index: $index,
            sortKey: _.camelCase(column.accessor + "-" + targetSortValue),
            sortValue: targetSortValue,
          },
        ];
      }

      setTempOrderType(tempOrderTypeData);
    },
    [columns, tempOrderType]
  );

  const clearOrder = useCallback(() => {
    setTempOrderType([]);
    setSearchParams({ ...searchParams, orderType: [] });
  }, [searchParams]);

  return (
    <TableContainer className={"dataPager"} sx={{ boxShadow: "none" }}>
      {entriesPerPage ? (
        <MDBox display="flex" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox className="cbEntriesWrap">
              <LASelect
                className="cbEntry"
                size="small"
                layout={{ width: "5rem" }}
                items={entries}
                value={pageSize.state}
                onChange={(option) => {
                  changePageSize(parseInt(option.value, 10));
                }}
              />
              <MDTypography
                className="label"
                variant="caption"
                color="secondary"
              >
                &nbsp;&nbsp;개씩 보기
              </MDTypography>
            </MDBox>
          )}
          {orderType.length > 0 && (
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={clearOrder}
            >
              정렬해제
            </MDButton>
          )}
        </MDBox>
      ) : null}
      <Table>
        <MDBox component="thead">
          <TableRow>
            {columns.map((column, $index) => (
              <DataPagerHeadCell
                key={$index}
                width={column.width ? column.width : "auto"}
                align={column.align ? column.align : "center"}
                sort={column.sortValue}
                onClick={() => {
                  onSortChanged(column, $index);
                }}
              >
                {column.Header}
              </DataPagerHeadCell>
            ))}
          </TableRow>
        </MDBox>
        <TableBody>
          {pageData?.map((row, key) => {
            // prepareRow(row);
            return <TableRow key={key}>{generateRow(row, key)}</TableRow>;
          })}
        </TableBody>
      </Table>

      {/*********************************** Pager ***********************************/}
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptionList.length === 1 ? 0 : 3}
      >
        {showTotalEntries && totalPageCount > 0 && (
          <MDBox mb={{ xs: 3, sm: 0 }} className={"entriesArea"}>
            <MDTypography
              variant="caption"
              color="secondary"
              fontWeight="regular"
            >
              Page <b>{currentPage}</b> / {totalPageCount}{" "}
              <span className="txtBar"></span> List{" "}
              <b>
                {entriesStart}~{entriesEnd}
              </b>{" "}
              / {totalItemCount}
            </MDTypography>
          </MDBox>
        )}

        {pageOptionList.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {previousPageActiveStatus && (
              <MDPagination
                item
                onClick={() => {
                  goPreviousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {totalPageCount > 10 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: totalPageCount,
                  }}
                  value={currentPage}
                  onChange={(event) => {
                    let nextPage = event.target.value;
                    if (nextPage > totalPageCount) {
                      return;
                    }
                    setTempCurrentPage(nextPage);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {nextPageActiveStatus && (
              <MDPagination
                item
                onClick={() => {
                  goNextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
      {/*********************************** Pager ***********************************/}
    </TableContainer>
  );
}

DataPager.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 20, 50] },
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
};

DataPager.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
};

export default React.memo(DataPager);
