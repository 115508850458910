import React from 'react';
import LAKeywordItem from './LAKeywordItem';
import LAGridItem from './LAGridItem';

function LAKeywords({
  wPercent = 100,
  layouts = {},
  props = {},
  keywords,
  selectedKeywords,
  isSelected,
  onClick,
}) {
  const isSelectedKeyword = (key) => {
    if (selectedKeywords) {
      const found = selectedKeywords.find((keyword) => {
        return keyword === key;
      });

      return found !== undefined;
    }
    return false;
  };

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      {keywords &&
        keywords.map((keyword) => (
          <LAKeywordItem
            key={keyword}
            keyword={keyword}
            isSelected={isSelectedKeyword(keyword)}
            onClick={onClick}
            props={props}
          />
        ))}
    </LAGridItem>
  );
}

export default React.memo(LAKeywords);
