import React, { useState, useCallback ,useEffect,useMemo, useRef} from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import ClassFeedbackDetailContents from './components/ClassFeedbackDetailContents';


function TeacherDetail() {


  return (
    <>
      <DashboardNavbar title="클레스 피드백 상세" />
      <ClassFeedbackDetailContents />
    </>
  );
}

export default TeacherDetail;
