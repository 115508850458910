import { createContext, useMemo } from "react";
import { getUseContext } from "common/utils";

import AllianceApi from "api/AllianceApi";
import AccountApi from "api/AccountApi";
import AuthApi from "api/AuthApi";
import CategoryApi from "api/CategoryApi";
import ClassApi from "api/ClassApi";
import CodeApi from "api/CodeApi";
import MemberApi from "api/MemberApi";
import TeacherApi from "api/TeacherApi";
import ClassApplicationApi from "api/ClassApplicationApi";
import KeywordApi from "api/KeywordApi";
import TermsApi from "api/TermsApi";
import ScheduleApi from "api/ScheduleApi";
import TimetableApi from "api/TimetableApi";
import CalendarApi from "api/CalendarApi";
import OrderApi from "api/OrderApi";
import ImageApi from "api/ImageApi";
import ReviewApi from "api/ReviewApi";
import InquireApi from "api/InquireApi";
import FeedbackApi from "api/FeedbackApi";
import CurriculumApi from "api/CurriculumApi";
import MagazineApi from "api/MagazineApi";
import EventApi from "api/EventApi";
import ClassroomApi from "api/ClassroomApi";
import MainBannerApi from "api/MainBannerApi";
import MainClassApi from "api/MainClassApi";
import MainVideoApi from "api/MainVideoApi";
import MainMenuApi from "api/MainMenuApi";
import RoomApi from "api/RoomApi";
import PointApi from "api/PointApi";
import CalculateApi from "api/CalculateApi";
import UserMemoApi from "api/UserMemoApi";
import CouponApi from "api/CouponApi";
import MainExhibitionApi from "api/MainExhibitionApi";
import NoticeApi from "api/NoticeApi";
import useAxios from "common/hooks/useAxios";
import BannerApi from "api/BannerApi";
import TogetherApi from "api/TogetherApi";
import UrlApi from "api/UrlApi";
import DownloadApi from "api/DownloadApi";

const ApiContext = createContext();

function ApiProvider({ children }) {
  const { requestFunc } = useAxios();

  const queries = useMemo(() => {
    return {
      ...AllianceApi(requestFunc),
      ...AccountApi(requestFunc),
      ...AuthApi(requestFunc),
      ...CategoryApi(requestFunc),
      ...ClassApi(requestFunc),
      ...CodeApi(requestFunc),
      ...MemberApi(requestFunc),
      ...TeacherApi(requestFunc),
      ...ClassApplicationApi(requestFunc),
      ...KeywordApi(requestFunc),
      ...TermsApi(requestFunc),
      ...ScheduleApi(requestFunc),
      ...TimetableApi(requestFunc),
      ...CalendarApi(requestFunc),
      ...OrderApi(requestFunc),
      ...ImageApi(requestFunc),
      ...ReviewApi(requestFunc),
      ...InquireApi(requestFunc),
      ...FeedbackApi(requestFunc),
      ...CurriculumApi(requestFunc),
      ...ClassroomApi(requestFunc),
      ...MagazineApi(requestFunc),
      ...EventApi(requestFunc),
      ...MainBannerApi(requestFunc),
      ...MainMenuApi(requestFunc),
      ...MainClassApi(requestFunc),
      ...MainVideoApi(requestFunc),
      ...RoomApi(requestFunc),
      ...CalculateApi(requestFunc),
      ...UserMemoApi(requestFunc),
      ...PointApi(requestFunc),
      ...CouponApi(requestFunc),
      ...MainExhibitionApi(requestFunc),
      ...BannerApi(requestFunc),
      ...NoticeApi(requestFunc),
      ...TogetherApi(requestFunc),
      ...UrlApi(requestFunc),
      ...DownloadApi(requestFunc),
    };
  }, [requestFunc]);

  const value = useMemo(() => {
    return { queries };
  }, [queries]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

export const useApiContext = () => getUseContext(ApiContext);
export default ApiProvider;
