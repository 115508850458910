import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import moment from "moment";
import pxToRem from "assets/theme/functions/pxToRem";
import { styled } from "@mui/material";

import {
    LAGridItem,
    LALabel,
    LACard,
    LACardTitle,
    LAGridContainer,
    LAButton,
    useModalPopup,
    useModal,
    LAProperty,
    LAPropertyName,
    getNormalButton,
    getPrimaryButton,
    getLinkTextCell
} from "modules/Widgets";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import useCommonMutation from "common/hooks/useCommonMutation";
import { useApiContext } from "common/context/ApiContext";

import DeliveryTrackingModal from "pages/Common/DeliveryTrackingModal";
import ChangeDeliveryAddressModal from "./ChangeDeliveryAddressModal";
import ExchangeDeliveryAddressModal from "./ExchangeDeliveryAddressModal";
import OrderDetailDeliveryRegistModal from "./OrderDetailDeliveryRegistModal";
import { getTodayDateText } from "../../../common/utils";
import useCommonQuery from "../../../common/hooks/useCommonQuery";

function OrderDeliveryHistoryTable({
                                       data,
                                       tableWidth = 1500,
                                       exchangeStatus,
                                       returnStatus,
                                       onDeleteReturnRequest,
                                       onDeleteExchangeRequest
                                   }) {
    const { queries } = useApiContext();
    const [contextWaybill, setContextWaybill] = useState("");
    const [contextOrder, setContextOrder] = useState("");
    const delivertRegist = useModal(false);
    const delivertTracking = useModal(false);
    const today = getTodayDateText();
    const changeAddressModal = useModal(false); // 최초배송 변경 모달
    const exchangeAddressModal = useModal(false); // 교환,배송변경 모달
    const [exchangeDeliveryAddress, setExchangeDeliveryAddress] = useState({});
    const [exchangeDeliveryStatus, setExchangeDeliveryStatus] = useState(false);
    const [isAlertClosed, setAlertClosed] = useState(false);  // 교환/반품 취소얼랏창 닫힘동작 제어
    const [currentData, setCurrentData] = useState(data);

    const { showAlert, showConfirm } = usePopAlert();

    useEffect(() => {
        if (isAlertClosed) {
            setAlertClosed(false);
            requestDetail(); // 취소된 후에 최신화된 데이터를 가져오기 위해 호출
        }
    }, [isAlertClosed]);

    useEffect(() => {
        if (exchangeStatus) {
            requestDetail();
        }
        if (returnStatus) {
            requestDetail();
        }
        if (exchangeDeliveryStatus) {
            requestDetail();
            setExchangeDeliveryStatus(false);
        }
    }, [exchangeStatus, returnStatus, exchangeDeliveryStatus]);

    const delivertTrackingClick = useCallback((data) => {
        setContextWaybill(data);
        delivertTracking.visible(true);
    }, [setContextWaybill, delivertTracking]);

    const delivertTrackingClose = useCallback(() => {
        delivertTracking.handleClose();
    });

    const delivertRegistModalPopup = {
        state: useModalPopup(false, (data) => {
            delivertRegistModalPopup.state.hide();
        }),
        show: () => {
            setContextOrder(data);
            delivertRegistModalPopup.state.show();
        }
    };

    // 최초배송 정보 변경
    const onChangeDeliveryInfoClick = useCallback(() => {
        if (data && data.application && data.application.confirmation_yn === 1) {
            showAlert("알림", "확정된 주문은 배송지를 변경 할 수 없습니다.");
            return;
        }
        changeAddressModal.visible(true);
    }, [data, changeAddressModal]);

    // 교환,반품배송 정보 변경
    const exchangeDeliveryInfoClick = useCallback((item) => {
        if (moment(item.created_dt).format("YYYYMMDD") !== today) {
            showAlert("알림", "등록일 이후 주문은 배송지를 변경 할 수 없습니다.");
            return;
        }
        setExchangeDeliveryAddress(item);
        exchangeAddressModal.visible(true);
    }, [today, exchangeAddressModal]);

    /* 반품 삭제 요청 로직 */
    const onRequestReturnCancelClick = useCallback((seq) => {
        showConfirm("안내", "교재 회수 요청을 취소하시겠습니까?", {
            confirmHandler: () => {
                requestCancelRecallOrder({
                    order_seq: data?.order.order_seq,
                    return_textbook_seq: seq
                });
            }
        });
    }, [data?.order.order_seq]);

    const { request: requestCancelRecallOrder } = useCommonMutation({
        query: queries.Delivery.cancelRecallOrder,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === "0000") {
                    showAlert("알림", "교재 회수 요청을 취소했습니다.", {
                        confirmHandler: () => {
                            setAlertClosed(true); // 알림 닫힘 상태로 변경
                        }
                    });
                } else {
                    showAlert("알림", data.result_message);
                }
            },
            onError: (error) => {
                showAlert("알림", "회수 요청취소 실패.");
            }
        },
        initEnabled: false
    });
    /* 반품 삭제 요청 로직 end */

    /* 교환 삭제 요청 로직 */
    const onRequestExchangeCancelClick = useCallback((seq) => {
        showConfirm("알림", "배송 요청을 취소하시겠습니까?",
            {
                confirmHandler: () => requestDelete({
                    return_textbook_seq: seq,
                    order_seq: data?.order.order_seq
                })
            }
        );
    }, [data?.order.order_seq]);

    const { request: requestDelete } = useCommonMutation({
        query: queries.Delivery.cancelExchangeTextbook,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === "0000") {
                    showAlert("안내", "취소되었습니다.", {
                        confirmHandler: () => {
                            setAlertClosed(true); // 알림 닫힘 상태로 변경
                            console.log("isAlertClosed  3:", isAlertClosed);
                        }
                    });
                } else {
                    showAlert("알림", data.result_message);
                }
            },
            onError: (error) => {
                showAlert("알림", data.result_message);
            }
        }
    });
    /* 교환 삭제 요청 로직 end*/

    const { request: requestDetail } = useCommonQuery({
        query: queries.Order.getOrderDetail,
        params: data.order.order_seq,
        callbacks: {
            onSuccess: (response) => {
                if (response.result_code === "0000") {
                    setCurrentData(response.result_data);
                } else {
                    showAlert("알림", response.result_message, {
                    });
                }
            },
            onError: (error) => {
                showAlert("알림", "결제 정보 조회에 실패하였습니다.", {
                    //confirmHandler: () => onClickList(),
                });
            }
        },
        initEnabled: false
    });


    return (
        <>
            {/*자사 클래스 인경우*/}
            {currentData.class?.textbook_delivery_type == 1 &&
                <>
                    <LACardTitle title="배송 정보" />
                    {/*최초 배송 delivery */}
                    <LAGridContainer vAlign="center" layouts={{
                        sx: {
                            backgroundColor: "#eeeeee",
                            border: "1px solid #dddddd",
                            borderBottom: "0px solid #dddddd",
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                            mt: 2
                        }
                    }}>
                        <LALabel wPercent={20} layouts={{ sx: { textAlign: "center" } }}
                                 props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>구분</LALabel>
                        <LALabel wPercent={50} layouts={{ sx: { textAlign: "center" } }}
                                 props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>항목</LALabel>
                        <LALabel wPercent={30} layouts={{ sx: { textAlign: "center" } }}
                                 props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>등록 일시 / 처리자</LALabel>
                        {/*<LALabel wPercent={10} layouts={{ sx: { textAlign: 'center' }}} props={{ fontSize: pxToRem(13) }}></LALabel>*/}
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{
                        sx: {
                            border: "1px solid #dddddd",
                            borderBottomLeftRadius: "6px",
                            borderBottomRightRadius: "6px",
                            overflow: "auto"
                        }
                    }}>
                        <LAGridContainer vAlign="center"
                                         layouts={{ sx: { pt: 0.5, pb: 0.5, backgroundColor: "#e5eff7" } }}>
                            <LALabel wPercent={20} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>배송유형</LALabel>
                            <LAGridItem wPercent={50}>
                                <LALabel wPercent={100}
                                         layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                         props={{ fontSize: pxToRem(12) }}>최초 배송</LALabel>
                            </LAGridItem>
                            <LALabel wPercent={30} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                     props={{ fontSize: pxToRem(12) }}>
                                {`${moment(currentData.delivery.created_dt).format("YYYY-MM-DD (dd) A hh:mm")} / 시스템`}
                            </LALabel>
                        </LAGridContainer>

                        <LAGridContainer vAlign="center"
                                         layouts={{ sx: { borderBottom: "1px solid #dddddd", pt: 0.5, pb: 0.5 } }}>
                            <LALabel wPercent={20} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>교재명</LALabel>
                            <LAGridItem wPercent={50}>
                                {currentData.class.textbooks !== null && (currentData.class.textbooks.map((item) => {
                                    return (
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                                 props={{ fontSize: pxToRem(12) }}>{`${item.option_name}`}</LALabel>
                                    );
                                }))}
                            </LAGridItem>
                            <LAGridItem wPercent={30}>
                                <LALabel wPercent={100}
                                         layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                         props={{ fontSize: pxToRem(12) }}>-</LALabel>
                            </LAGridItem>
                        </LAGridContainer>

                        <LAGridContainer vAlign="center"
                                         layouts={{ sx: { borderBottom: "1px solid #dddddd", pt: 0.5, pb: 0.5 } }}>
                            <LALabel wPercent={20} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>송장번호</LALabel>
                            <LALabel wPercent={50} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>
                                {getLinkTextCell(currentData.delivery.waybill_company_name ? `${currentData.delivery.waybill_company_name}(${currentData.delivery.waybill_no})` : "-", null, delivertTrackingClick, currentData.delivery)}
                            </LALabel>
                            <LALabel wPercent={30} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                     props={{ fontSize: pxToRem(12) }}>
                                {currentData.delivery.waybill_dt ? `${moment(currentData.delivery.waybill_dt).format("YYYY-MM-DD (dd) A hh:mm")} / 시스템` : ""}
                            </LALabel>
                        </LAGridContainer>

                        <LAGridContainer vAlign="center"
                                         layouts={{ sx: { borderBottom: "1px solid #dddddd", pt: 0.5, pb: 0.5 } }}>
                            <LALabel wPercent={8} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>배송지</LALabel>
                            <LAButton
                                wPercent={12}
                                layouts={{ pr: { sm: 1 }, pl: { sm: 1 } }}
                                props={{
                                    disabled: currentData.application.confirmation_yn === 1 ? true : false,
                                    style: {
                                        backgroundColor: currentData.application.confirmation_yn === 1 ? "rgb(180, 180, 180)" : "",
                                        color: "#ffffff"
                                    }
                                }}
                                onClick={onChangeDeliveryInfoClick}
                            >
                                정보 변경
                            </LAButton>
                            <LALabel wPercent={50} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>
                                {`${currentData.delivery.receiver_name} / ${currentData.delivery.receiver_phone_no} / (${currentData.delivery.address_zip}) ${currentData.delivery.address_road}${currentData.delivery.address_detail}`}
                            </LALabel>
                            <LALabel wPercent={30} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                     props={{ fontSize: pxToRem(12) }}>-</LALabel>
                            {/*<LALabel wPercent={45} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }} props={{ fontSize: pxToRem(12)}}>{`${moment(currentData.delivery.modified_dt).format('YYYY-MM-DD (dd) A hh:mm')} / 현재 데이터 없음`}</LALabel>*/}
                        </LAGridContainer>

                        <LAGridContainer vAlign="center" layouts={{ pt: 0.5, pb: 0.5 }}>
                            <LALabel wPercent={20} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>배송지 변경 이력</LALabel>
                            <LAGridItem wPercent={50}>
                                {currentData.deliveryLog && currentData.deliveryLog.length > 0 ? (
                                    currentData.deliveryLog.map((item) => (
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                                 props={{ fontSize: pxToRem(12) }}>
                                            {`${item.receiver_name} / ${item.receiver_phone_no} / (${item.address_zip}) ${item.address_road} ${item.address_detail}`}
                                        </LALabel>
                                    ))
                                ) : (
                                    <LALabel wPercent={100}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>-</LALabel>
                                )}
                            </LAGridItem>

                            <LAGridItem wPercent={30}>
                                {currentData.deliveryLog && (currentData.deliveryLog.map((item) => {
                                    return (
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                                 props={{ fontSize: pxToRem(12) }}>
                                            {`${moment(item.modified_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${item.constructor_name}`}
                                        </LALabel>
                                    );
                                }))}
                            </LAGridItem>
                        </LAGridContainer>
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{ pt: 0.5, pb: 0.5 }}></LAGridContainer>

                    {/* 교환 배송 */}
                    {currentData.exchangeTextbooks?.length !== 0 && currentData.exchangeTextbooks?.filter((item) => item.process_type === "EXCHANGE").map((item) => (
                        <React.Fragment key={item.id}>
                            <LAGridContainer vAlign="center" layouts={{
                                sx: {
                                    backgroundColor: "#eeeeee",
                                    borderTopLeftRadius: "6px",
                                    borderTopRightRadius: "6px",
                                    mt: 2
                                }
                            }}>
                                <LALabel wPercent={20} layouts={{ sx: { textAlign: "center" } }}
                                         props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>구분</LALabel>
                                <LALabel wPercent={50} layouts={{ sx: { textAlign: "center" } }}
                                         props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>항목</LALabel>
                                <LALabel wPercent={30} layouts={{ sx: { textAlign: "center" } }}
                                         props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>등록 일시 / 처리자</LALabel>
                                {/*<LALabel wPercent={10} layouts={{ sx: { textAlign: 'center' }}} props={{ fontSize: pxToRem(13) }}></LALabel>*/}
                            </LAGridContainer>

                            <LAGridContainer vAlign="center" layouts={{
                                sx: {
                                    border: "1px solid #dddddd",
                                    borderBottomLeftRadius: "6px",
                                    borderBottomRightRadius: "6px",
                                    overflow: "auto"
                                }
                            }}>
                                <LAGridContainer vAlign="center"
                                                 layouts={{ sx: { pt: 0.5, pb: 0.5, backgroundColor: "#e5eff7" } }}>
                                    <LALabel wPercent={20}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>배송유형</LALabel>
                                    <LAGridItem wPercent={50}>
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                                 props={{ fontSize: pxToRem(12) }}>
                                            {item.return_reason ? `교환 배송(사유: ${item.return_reason})` : ""}
                                        </LALabel>
                                    </LAGridItem>
                                    <LALabel wPercent={30}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                             props={{ fontSize: pxToRem(12) }}>{`${moment(item.created_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${item.member_name}`}</LALabel>
                                </LAGridContainer>
                                <LAGridContainer vAlign="center" layouts={{
                                    sx: {
                                        borderBottom: "1px solid #dddddd",
                                        pt: 0.5,
                                        pb: 0.5
                                    }
                                }}>
                                    <LALabel wPercent={20}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>교재명</LALabel>
                                    <LAGridItem wPercent={50}>
                                        {currentData.class.textbooks !== null && (currentData.class.textbooks.map((item) => {
                                            return (<LALabel wPercent={100} layouts={{
                                                sx: {
                                                    textAlign: "left",
                                                    fontWeight: "regular"
                                                }, pl: 4
                                            }} props={{ fontSize: pxToRem(12) }}>{`${item.option_name}`}</LALabel>);
                                        }))}
                                    </LAGridItem>
                                    <LAGridItem wPercent={30}>
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                                 props={{ fontSize: pxToRem(12) }}>-</LALabel>
                                    </LAGridItem>
                                </LAGridContainer>
                                <LAGridContainer vAlign="center" layouts={{
                                    sx: {
                                        borderBottom: "1px solid #dddddd",
                                        pt: 0.5,
                                        pb: 0.5
                                    }
                                }}>
                                    <LALabel wPercent={20}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>송장번호</LALabel>
                                    <LALabel wPercent={50}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>
                                        {/*{item.waybill_company_name && item.waybill_no ? getLinkTextCell(`${item.waybill_company_name}(${item.waybill_no})`, null, alert(1), item.S) : ''}*/}
                                        {getLinkTextCell(item.waybill_company_name ? `${item.waybill_company_name}(${item.waybill_no})` : "-", null, delivertTrackingClick, item)}
                                    </LALabel>
                                    <LALabel wPercent={30}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                             props={{ fontSize: pxToRem(12) }}>
                                        {item.waybill_dt ? `${moment(item.waybill_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${item.member_name}` : ""}
                                    </LALabel>
                                </LAGridContainer>
                                <LAGridContainer vAlign="center" layouts={{
                                    sx: {
                                        borderBottom: "1px solid #dddddd",
                                        pt: 0.5,
                                        pb: 0.5
                                    }
                                }}>
                                    <LALabel wPercent={8}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>배송지</LALabel>
                                    <LAButton
                                        wPercent={12}
                                        layouts={{ pr: { sm: 1 }, pl: { sm: 1 } }}
                                        props={{
                                            disabled: moment(item.created_dt).format("YYYYMMDD") !== today ? true : false,
                                            style: {
                                                backgroundColor: moment(item.created_dt).format("YYYYMMDD") !== today ? "rgb(180, 180, 180)" : "",
                                                color: "#ffffff" // 글자색 지정
                                            }
                                        }}
                                        onClick={() => exchangeDeliveryInfoClick(item)}
                                        //onClick={exchangeDeliveryInfoClick}
                                    >
                                        정보 변경
                                    </LAButton>

                                    <LALabel wPercent={50}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>
                                        {`${item.receiver_name} / ${item.receiver_phone_no} / (${item.address_zip}) ${item.address_road} ${item.address_detail}`}
                                    </LALabel>
                                    <LALabel wPercent={30}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                             props={{ fontSize: pxToRem(12) }}>-</LALabel>
                                    {/*<LALabel wPercent={45} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }} props={{ fontSize: pxToRem(12)}}>{`${moment(data.delivery.modified_dt).format('YYYY-MM-DD (dd) A hh:mm')} / 현재 데이터 없음`}</LALabel>*/}
                                </LAGridContainer>

                                <LAGridContainer vAlign="center" layouts={{ pt: 0.5, pb: 0.5 }}>
                                    <LALabel wPercent={18}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>배송지 변경 이력</LALabel>
                                    <LAGridItem wPercent={55}>
                                        {item.is_delivery_address_logs > 0 ? (
                                            item.delivery_address_logs.map((logs) => (
                                                <LALabel
                                                    wPercent={100}
                                                    layouts={{
                                                        sx: { textAlign: "left", fontWeight: "regular" },
                                                        pl: 8
                                                    }}
                                                    props={{ fontSize: pxToRem(12) }}
                                                    key={logs.id}
                                                >
                                                    {`${logs.receiver_name} / ${logs.receiver_phone_no} / (${logs.address_zip}) ${logs.address_road} ${logs.address_detail}`}
                                                </LALabel>
                                            ))
                                        ) : (
                                            <LALabel
                                                wPercent={100}
                                                layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 8 }}
                                                props={{ fontSize: pxToRem(12) }}
                                            >
                                                -
                                            </LALabel>
                                        )}
                                    </LAGridItem>
                                    <LAGridItem wPercent={15}>
                                        {item.is_delivery_address_logs > 0 && (item.delivery_address_logs.map((logs) => {
                                            return (
                                                <LALabel wPercent={100} layouts={{
                                                    sx: { textAlign: "left", fontWeight: "regular" },
                                                    pl: 0
                                                }} props={{ fontSize: pxToRem(12) }}>
                                                    {`${moment(logs.created_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${logs.constructor_name}`}
                                                </LALabel>
                                            );
                                        }))}
                                    </LAGridItem>
                                </LAGridContainer>
                            </LAGridContainer>
                            {moment(item.created_dt).format("YYYYMMDD") === today &&
                                <LAGridContainer>
                                    <LALabel wPercent={40}></LALabel>
                                    <LAButton wPercent={20} layouts={{ pr: { sm: 1 }, pl: { sm: 1 }, pt: { sm: 2 } }}
                                              props={{ disabled: false }}
                                              onClick={() => onRequestExchangeCancelClick(item.return_textbook_seq)}>
                                        교환 배송 요청 삭제
                                    </LAButton>
                                    <LALabel wPercent={40}></LALabel>
                                </LAGridContainer>
                            }
                        </React.Fragment>
                    ))}

                    {/* 배송유형 구분 여백을 주기 위함 */}
                    <LAGridContainer vAlign="center" layouts={{ pt: 0.5, pb: 2.5 }}></LAGridContainer>

                    {/* 반품 회수 */}
                    {data.exchangeTextbooks?.length !== 0 && data.exchangeTextbooks?.filter((item) => item.process_type === "RETURN").map((item) => (
                        <React.Fragment key={item.id}>
                            <LAGridContainer vAlign="center" layouts={{
                                sx: {
                                    backgroundColor: "#eeeeee",
                                    borderTopLeftRadius: "6px",
                                    borderTopRightRadius: "6px",
                                    mt: 2
                                }
                            }}>
                                <LALabel wPercent={20} layouts={{ sx: { textAlign: "center" } }}
                                         props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>구분</LALabel>
                                <LALabel wPercent={50} layouts={{ sx: { textAlign: "center" } }}
                                         props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>항목</LALabel>
                                <LALabel wPercent={30} layouts={{ sx: { textAlign: "center" } }}
                                         props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>등록 일시 / 처리자</LALabel>
                                {/*<LALabel wPercent={10} layouts={{ sx: { textAlign: 'center' }}} props={{ fontSize: pxToRem(13) }}></LALabel>*/}
                            </LAGridContainer>

                            <LAGridContainer vAlign="center" layouts={{
                                sx: {
                                    border: "1px solid #dddddd",
                                    borderBottomLeftRadius: "6px",
                                    borderBottomRightRadius: "6px",
                                    overflow: "auto"
                                }
                            }}>
                                <LAGridContainer vAlign="center"
                                                 layouts={{ sx: { pt: 0.5, pb: 0.5, backgroundColor: "#e5eff7" } }}>
                                    <LALabel wPercent={20}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>배송유형</LALabel>
                                    <LAGridItem wPercent={50}>
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                                 props={{ fontSize: pxToRem(12) }}>
                                            {item.return_reason !== "" ? `반품 회수(사유: ${item.return_reason})` : "반품 회수"}
                                        </LALabel>
                                    </LAGridItem>
                                    <LALabel wPercent={30}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                             props={{ fontSize: pxToRem(12) }}>
                                        {`${moment(item.created_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${item.member_name}`}
                                    </LALabel>
                                </LAGridContainer>

                                <LAGridContainer vAlign="center" layouts={{
                                    sx: {
                                        borderBottom: "1px solid #dddddd",
                                        pt: 0.5,
                                        pb: 0.5
                                    }
                                }}>
                                    <LALabel wPercent={20}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>교재명</LALabel>
                                    <LAGridItem wPercent={50}>
                                        {data.class.textbooks !== null && (data.class.textbooks.map((item) => {
                                            return (<LALabel wPercent={100} layouts={{
                                                sx: {
                                                    textAlign: "left",
                                                    fontWeight: "regular"
                                                }, pl: 4
                                            }} props={{ fontSize: pxToRem(12) }}>{`${item.option_name}`}</LALabel>);
                                        }))}
                                    </LAGridItem>
                                    <LAGridItem wPercent={30}>
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                                 props={{ fontSize: pxToRem(12) }}>-</LALabel>
                                    </LAGridItem>
                                </LAGridContainer>

                                <LAGridContainer vAlign="center" layouts={{
                                    sx: {
                                        borderBottom: "1px solid #dddddd",
                                        pt: 0.5,
                                        pb: 0.5
                                    }
                                }}>
                                    <LALabel wPercent={20}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>송장번호</LALabel>
                                    <LALabel wPercent={50}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>
                                        {/*{item.waybill_company_name && item.waybill_no ? getLinkTextCell(`${item.waybill_company_name}(${item.waybill_no})`, null, alert(1), item.S) : ''}*/}
                                        {getLinkTextCell(item.waybill_company_name ? `${item.waybill_company_name}(${item.waybill_no})` : "-", null, delivertTrackingClick, item)}
                                    </LALabel>
                                    <LALabel wPercent={30}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                             props={{ fontSize: pxToRem(12) }}>
                                        {item.waybill_dt ? `${moment(item.waybill_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${item.member_name}` : ""}
                                    </LALabel>
                                </LAGridContainer>

                                <LAGridContainer vAlign="center" layouts={{
                                    sx: {
                                        borderBottom: "1px solid #dddddd",
                                        pt: 0.5,
                                        pb: 0.5
                                    }
                                }}>
                                    <LALabel wPercent={8}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>배송지</LALabel>
                                    <LAButton
                                        wPercent={12}
                                        layouts={{ pr: { sm: 1 }, pl: { sm: 1 } }}
                                        props={{
                                            disabled: moment(item.created_dt).format("YYYYMMDD") !== today ? true : false,
                                            style: {
                                                backgroundColor: moment(item.created_dt).format("YYYYMMDD") !== today ? "rgb(180, 180, 180)" : "",
                                                color: "#ffffff" // 글자색 지정
                                            }
                                        }}
                                        onClick={() => exchangeDeliveryInfoClick(item)}
                                    >
                                        정보 변경
                                    </LAButton>
                                    <LALabel wPercent={50}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>
                                        {`${item.receiver_name} / ${item.receiver_phone_no} / (${item.address_zip}) ${item.address_road} ${item.address_detail}`}
                                    </LALabel>
                                    <LALabel wPercent={30}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                             props={{ fontSize: pxToRem(12) }}>-</LALabel>
                                    {/*<LALabel wPercent={45} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }} props={{ fontSize: pxToRem(12)}}>{`${moment(data.delivery.modified_dt).format('YYYY-MM-DD (dd) A hh:mm')} / 현재 데이터 없음`}</LALabel>*/}
                                </LAGridContainer>

                                <LAGridContainer vAlign="center" layouts={{ pt: 0.5, pb: 0.5 }}>
                                    <LALabel wPercent={18}
                                             layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                             props={{ fontSize: pxToRem(12) }}>배송지 변경 이력</LALabel>
                                    <LAGridItem wPercent={55}>
                                        {item.is_delivery_address_logs > 0 ? (
                                            item.delivery_address_logs.map((logs) => (
                                                <LALabel
                                                    wPercent={100}
                                                    layouts={{
                                                        sx: { textAlign: "left", fontWeight: "regular" },
                                                        pl: 8
                                                    }}
                                                    props={{ fontSize: pxToRem(12) }}
                                                    key={logs.id}
                                                >
                                                    {`${logs.receiver_name} / ${logs.receiver_phone_no} / (${logs.address_zip}) ${logs.address_road} ${logs.address_detail}`}
                                                </LALabel>
                                            ))
                                        ) : (
                                            <LALabel
                                                wPercent={100}
                                                layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 8 }}
                                                props={{ fontSize: pxToRem(12) }}
                                            >
                                                -
                                            </LALabel>
                                        )}
                                    </LAGridItem>

                                    <LAGridItem wPercent={15}>
                                        {item.is_delivery_address_logs > 0 && (item.delivery_address_logs.map((logs) => {
                                            return (
                                                <LALabel wPercent={100} layouts={{
                                                    sx: { textAlign: "left", fontWeight: "regular" },
                                                    pl: 0
                                                }} props={{ fontSize: pxToRem(12) }}>
                                                    {`${moment(logs.created_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${logs.constructor_name}`}
                                                </LALabel>
                                            );
                                        }))}
                                    </LAGridItem>
                                </LAGridContainer>
                            </LAGridContainer>
                            {/*당일만 아래 버튼 화면 출력*/}
                            {moment(item.created_dt).format("YYYYMMDD") === today &&
                                <LAGridContainer>
                                    <LALabel wPercent={40}></LALabel>
                                    <LAButton wPercent={20} layouts={{ pr: { sm: 1 }, pl: { sm: 1 }, pt: { sm: 2 } }}
                                              props={{ disabled: false }}
                                              onClick={() => onRequestReturnCancelClick(item.return_textbook_seq)}>
                                        반품 배송 요청 삭제
                                    </LAButton>
                                    <LALabel wPercent={40}></LALabel>
                                </LAGridContainer>
                            }
                        </React.Fragment>
                    ))}
                </>
            }

            {/* 외부 클래스인 경우*/}
            {currentData.class?.textbook_delivery_type == 2 &&
                <>
                    <LACardTitle title="배송 정보" />
                    {/* 최초배송 */}
                    <LAGridContainer vAlign="center" layouts={{
                        sx: {
                            backgroundColor: "#eeeeee",
                            border: "1px solid #dddddd",
                            borderBottom: "0px solid #dddddd",
                            borderTopLeftRadius: "6px",
                            borderTopRightRadius: "6px",
                            mt: 2
                        }
                    }}>
                        <LALabel wPercent={20} layouts={{ sx: { textAlign: "center" } }}
                                 props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>구분</LALabel>
                        <LALabel wPercent={50} layouts={{ sx: { textAlign: "center" } }}
                                 props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>항목</LALabel>
                        <LALabel wPercent={30} layouts={{ sx: { textAlign: "center" } }}
                                 props={{ fontSize: pxToRem(13), fontWeight: "bold" }}>등록 일시 / 처리자</LALabel>
                        {/*<LALabel wPercent={10} layouts={{ sx: { textAlign: 'center' }}} props={{ fontSize: pxToRem(13) }}></LALabel>*/}
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{
                        sx: {
                            border: "1px solid #dddddd",
                            borderBottomLeftRadius: "6px",
                            borderBottomRightRadius: "6px",
                            overflow: "auto"
                        }
                    }}>
                        <LAGridContainer vAlign="center"
                                         layouts={{ sx: { borderBottom: "1px solid #dddddd", pt: 0.5, pb: 0.5 } }}>
                            <LALabel wPercent={8} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>배송지</LALabel>
                            <LAButton
                                wPercent={12}
                                layouts={{ pr: { sm: 1 }, pl: { sm: 1 } }}
                                props={{ disabled: currentData.application.confirmation_yn === 1 ? true : false }}
                                onClick={onChangeDeliveryInfoClick}
                            >
                                정보 변경
                            </LAButton>
                            <LALabel wPercent={50} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>
                                {`${currentData.delivery.receiver_name} / ${currentData.delivery.receiver_phone_no} / (${currentData.delivery.address_zip}) ${currentData.delivery.address_road} ${currentData.delivery.address_detail}`}
                            </LALabel>
                            <LALabel wPercent={30} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                     props={{ fontSize: pxToRem(12) }}>
                                {`${moment(currentData.delivery.created_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${currentData.delivery.receiver_name}`}
                            </LALabel>
                            {/*<LALabel wPercent={45} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }} props={{ fontSize: pxToRem(12)}}>{`${moment(currentData.delivery.modified_dt).format('YYYY-MM-DD (dd) A hh:mm')} / 현재 데이터 없음`}</LALabel>*/}
                        </LAGridContainer>

                        <LAGridContainer vAlign="center" layouts={{ pt: 0.5, pb: 0.5 }}>
                            <LALabel wPercent={20} layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                     props={{ fontSize: pxToRem(12) }}>배송지 변경 이력</LALabel>
                            <LAGridItem wPercent={50}>
                                {currentData.deliveryLog && (currentData.deliveryLog.map((item) => {
                                    return (
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 4 }}
                                                 props={{ fontSize: pxToRem(12) }}>
                                            {`${item.receiver_name} / ${item.receiver_phone_no} / (${item.address_zip}) ${item.address_road} ${item.address_detail}`}
                                        </LALabel>
                                    );
                                }))}
                            </LAGridItem>
                            <LAGridItem wPercent={30}>
                                {currentData.deliveryLog && (currentData.deliveryLog.map((item) => {
                                    return (
                                        <LALabel wPercent={100}
                                                 layouts={{ sx: { textAlign: "left", fontWeight: "regular" }, pl: 2 }}
                                                 props={{ fontSize: pxToRem(12) }}>
                                            {`${moment(item.created_dt).format("YYYY-MM-DD (dd) A hh:mm")} / ${item.constructor_name}`}
                                        </LALabel>
                                    );
                                }))}
                            </LAGridItem>
                        </LAGridContainer>
                    </LAGridContainer>

                    <LAGridContainer vAlign="center" layouts={{ pt: 0.5, pb: 0.5 }}></LAGridContainer>

                    <LAGridContainer>
                        <LALabel wPercent={40}></LALabel>
                        <LAButton wPercent={20} layouts={{ pr: { sm: 1 }, pl: { sm: 1 }, pt: { sm: 2 } }}
                                  props={{ disabled: false }} onClick={delivertRegistModalPopup.show}>송장 관리</LAButton>
                        <LALabel wPercent={40}></LALabel>
                    </LAGridContainer>
                </>
            }

            <OrderDetailDeliveryRegistModal
                context={contextOrder}
                modalPopupState={delivertRegistModalPopup.state}
                dataOrderSeq={currentData.order.order_seq}
            />

            <DeliveryTrackingModal
                context={contextWaybill}
                show={delivertTracking.state}
                handleClose={delivertTrackingClose}
                // handleSelect={delivertTracking}
            />

            <ChangeDeliveryAddressModal
                show={changeAddressModal.state}
                handleClose={() => {
                    changeAddressModal.handleClose();
                    requestDetail();
                }}
                deliveryInfo={!!currentData && { ...currentData.delivery, order_seq: currentData.order.order_seq }}
            />

            <ExchangeDeliveryAddressModal
                show={exchangeAddressModal.state}
                handleClose={() => {
                    exchangeAddressModal.handleClose();
                    setExchangeDeliveryStatus(true);
                }}
                deliveryInfo={!!currentData && { ...currentData.delivery, order_seq: currentData.order.order_seq }}
                exchangeDeliveryAddress={exchangeDeliveryAddress}
            />
        </>
    );
}

export default OrderDeliveryHistoryTable;
