import React, { useState, useCallback, useEffect, useContext } from 'react';

import { UNSAFE_NavigationContext } from "react-router-dom";

import TeacherDetailContents from './TeacherDetailContents';

import { LAModal, LACard, LACardTitle } from 'modules/Widgets';

function TeacherDetailModal({teacherSeq, show, handleClose }) {
    const navigation = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
            window.history.pushState(null, null, window.location.pathname);
            if (navigation) {
            navigation.listen((locationListener) => {
                if(locationListener.action == 'POP') {
                    handleClose();
                }
            });
        }
    }, []);

    return (
    <LAModal
      title="선생님 상세"
      width="90%"
      height="95%"
      show={show}
      onClose={handleClose}
    >
        <TeacherDetailContents teacherSeq={teacherSeq} onClickList={handleClose}/>
    </LAModal>
  );
}

export default React.memo(TeacherDetailModal);
