import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/ko';

import { LAListView } from 'modules/Widgets';
function ScheduleList({ schedules, curriculums }) {
  const headers = ['No', '클래스 일정', '요일', '시간', '삭제'];

  // const curriculumName = useCallback((idx) =>{

  //   console.log(curriculums,idx)
  //   if(curriculums && curriculums.length > 0){
  //     const curriculum = curriculums.findIndex(idx)
  //     return curriculum.curriculum_name
  //   }else{
  //     return ''
  //   }
  // },[])

  const scheduleDate = useCallback((schedule) => {
    if (schedule && schedule.length == 1) {
      const first = schedule[0].schedule_date;
      return `${moment(first).format('YYYY-MM-DD (dd)')}`;
    } else {
      const first = schedule[0].schedule_date;
      const last = schedule[schedule.length - 1].schedule_date;

      return `${moment(first).format('YYYY-MM-DD (dd)')}~${moment(last).format(
        'YYYY-MM-DD (dd)',
      )}`;
    }
  });

  const scheduleDayOfWeek = useCallback((schedule) => {
    if (schedule && schedule.length > 0) {
      return schedule.map((s) => moment(s.schedule_date).format('ddd'));
    }
    return '';
  });

  const scheduleTime = useCallback((schedule) => {
    if (schedule && schedule.length > 0) {
      const time = schedule[0];
      return moment(time.schedule_time, 'HHmm').format('A hh:mm');
    }
    return '';
  });

  var items = [];
  if (schedules) {
    items = [];
    schedules.forEach((item, index) => {
      items.push([
        index + 1,
        // ,curriculumName(index)
        scheduleDate(item),
        scheduleDayOfWeek(item),
        scheduleTime(item),
        '삭제',
      ]);
    });
  }
  return (
    <>
      <LAListView headers={headers} items={items} />
    </>
  );
}
export default React.memo(ScheduleList);
