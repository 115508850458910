import React, { useState, useCallback, useEffect, useMemo } from "react";
// import { useFilePicker } from 'use-file-picker';
import { useNavigate } from "react-router-dom";

import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import TeacherSearchModal from "../components/TeacherSearchModal";
import ClassScheduleModal from "../components/ClassScheduleModal";
import TextbookSearchModal from "../components/TextbookSearchModal";
// import ArrowForward from '@mui/icons-material/ArrowForward';

import { useLoginContext } from "common/context/MemberContext";
// import { useCodeContext } from 'common/context/CodeContext';
import { useApiContext } from "common/context/ApiContext";

// import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import CategoryComboView from "../components/CategoryComboView";

// import OneDayScheduleView from '../components/OneDayScheduleView';
// import PackageScheduleView from '../components/PackageScheduleView';
import CurriculumView from "../components/CurriculumView";
import ScheduleView from "../components/ScheduleView";

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LACodeRadioGroup,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAListView,
  LAKeywords,
  useRadioGroup,
  useComboBox,
  useInput,
} from "modules/Widgets";

const keywords = [
  "취향독서",
  "과학실험",
  "북미1:1",
  "신체",
  "바다동물",
  "여름방학",
  "반려동물",
  "바이러스",
  "게임",
  "습관",
  "일기쓰기",
  "한글",
  "코딩",
  "한국사",
  "여행",
  "음악",
  "미술",
  "운동",
  "직업",
];

const imageUrl =
  "https://images.unsplash.com/photo-1481277542470-605612bd2d61?w=248&fit=crop&auto=format&dpr=2";

const classTimeItems = [
  { key: 1, value: "1회" },
  { key: 2, value: "2회" },
  { key: 3, value: "3회" },
  { key: 4, value: "4회" },
  { key: 5, value: "5회" },
  { key: 6, value: "6회" },
  { key: 7, value: "7회" },
  { key: 8, value: "8회" },
  { key: 9, value: "9회" },
  { key: 10, value: "10회" },
];

function ClassApplicationRegist() {
  const { isLogin } = useLoginContext();
  const navigate = useNavigate();

  // const [openFileSelector, { materialFiles ,loading,errors}] = useFilePicker({
  //   accept:'image/jpg,image/png,image/jpeg,image/gif'   ,
  // });

  // const [data,setData] = useState({})
  const { queries } = useApiContext();

  const { requestAsync: registAsync } = useCommonMutation({
    query: queries.ClassApplication.regist,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
        } else {
        }
      },
      onError: (error) => {},
    },
  });

  // const [thumbnailType, setThumbnailType] = useState(0);
  // const [comboValue, setComboValue] = useState(0);
  // const [selectedKeyword, setSelectedKeyword] = useState([]);
  // const [selectedDayOfTheWeek, setSelectedDayOfTheWeek] = useState([]);

  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [materialFiles, setMaterialFiles] = useState([]);

  const [teacherSearchShow, setTeacherSearchShow] = useState(false);
  const [classScheduleShow, setClassScheduleShow] = useState(false);
  const [textbookSearchShow, setTextbookSearchShow] = useState(false);

  const [teacher, setTeacher] = useState({});
  const category = useComboBox(0);

  const className = useInput("");
  const classDescription = useInput("");
  const classType = useRadioGroup(1);

  const classTime = useComboBox(1);

  const ageType = useRadioGroup(true);
  const minTargetAge = useComboBox(5);
  const maxTargetAge = useComboBox(8);
  const minUserCount = useComboBox(5);
  const maxUserCount = useComboBox(10);

  const [curriculums, setCurriculums] = useState([]);
  const [schedules, setSchedule] = useState([]);
  const classPrice = useInput(0);
  const discountPrice = useInput(0);

  const classSummary = useInput("");
  const [informHtml, setInformHtml] = useState("");
  const [curriculumHtml, setCurriculumHtml] = useState("");
  const [noticeHtml, setNoticeHtml] = useState("");
  // const textbooks = useState([])       //교재(최대3개)
  // const [attachment,setAttachment] = useState()//첨부파일(수업자료) 10MB
  const materials = useInput(""); //준비물

  const [options, setOptions] = useState([]); //교재
  // const [keywords , setKeywords] = useState([])

  const [selectedKeyword, setSelectedKeyword] = useState([]);

  useEffect(() => {
    if (!isLogin) {
      alert("로그인 후 이용할 수 있습니다.");
      navigate("/");
    }
    return () => {};
  }, []);

  const userCounts = useMemo(() => {
    var list = [];
    for (let i = 1; i < 31; i++) {
      list.push({ key: i, value: `${i}명` });
    }
    return list;
  });

  const targetAgeItems = useMemo(() => {
    var list = [];
    for (let i = 1; i < 20; i++) {
      list.push({ key: i, value: `${i}세` });
    }
    return list;
  });

  const materialItems = useMemo(() => {
    var list = [];
    // if(materialFiles && materialFiles.length > 0){

    //   materialFiles.map((file, index) =>{
    //     list.push(
    //       [++index
    //         ,file.name
    //         ,file.size
    //     ])
    //   })
    // }

    materialFiles.forEach((mf, idx) => {
      list.push([++idx, mf.name, mf.size]);
    });
    return list;
  });

  const totalPrice = useMemo(() => {
    if (schedules && schedules.length > 0)
      // return schedules.reduce((s, o) => s.subject_price + o.subject_price)
      return schedules.reduce((acc, item) => acc + item.subject_price, 0);
    else return 0;
  }, [schedules]);

  const discountRate = useMemo(() => {
    if (classPrice.state > 0 && discountPrice.state > 0) {
      let diffPrice = classPrice.state - discountPrice.state;
      if (diffPrice > 0)
        if (diffPrice > 0)
          return ((diffPrice / classPrice.state) * 100).toFixed(2);
        else return 0;
    } else {
      return 0;
    }
  }, [classPrice.state, discountPrice.state]);

  const onChangeClassType = useCallback(
    (e) => {
      if (e.target.value == 1) {
        if (
          (schedules && schedules.length > 1) ||
          (curriculums && curriculums.length > 1)
        ) {
          var ret = window.confirm(
            "등록된 커리큘럼 및 스케쥴 정보가 삭제됩니다. 변경하시겠습니까?"
          );
          if (ret) {
            setSchedule([]);
            setCurriculums([]);
            classType.handleChange(e);
          }
        } else {
          classType.handleChange(e);
        }
      } else {
        classType.handleChange(e);
      }

      if (e.target.value == 1) {
        classTime.select(1);
      } else {
        classTime.select(2);
      }
    },
    [classType, classTime, schedules, curriculums]
  );

  const onChangeDiscountPrice = useCallback(
    (e) => {
      discountPrice.handleChange(e);
    },
    [classPrice.state, discountPrice]
  );

  const onChangeThumbnailImage = useCallback(
    (e) => {
      var [file] = e.target.files;
      file.imageUrl = URL.createObjectURL(file);
      const images = [...thumbnailImages, file];
      setThumbnailImages(images);
    },
    [thumbnailImages]
  );
  const onChangeAttachment = useCallback(
    (e) => {
      var [file] = e.target.files;

      const curSize = materialFiles.reduce((a, b) => a + b.size, 0);
      if (curSize + file.size > 10 * 1048576) {
        alert("최대 2MB까지 등록 가능합니다.");
        return;
      }

      file.imageUrl = URL.createObjectURL(file);
      const files = [...materialFiles, file];
      setMaterialFiles(files);
    },
    [materialFiles]
  );

  const onRegist = useCallback(
    async (data) => {
      try {
        const res = await registAsync(data);
        if (res.result_code === "0000") {
          alert(res.result_message);
          navigate(-1);
        } else {
          alert(res.result_message);
        }
      } catch (e) {
        console.error("수정 실패 =>", e);
        if (e.response && e.response.status === 401) {
          alert("로그인 후 이용할 수 있습니다.");
          return;
        }

        alert("카테고리 수정에 실패하였습니다.");
      }
    },
    [navigate, registAsync]
  );

  const onRegistClick = useCallback(() => {
    // TODO : validation

    const formData = new FormData();

    formData.append("category_seq", category.state);
    formData.append("teacher_seq", teacher.teacher_seq);
    formData.append("class_name", className.state);
    formData.append("class_description", classDescription.state);
    formData.append("class_type", classType.state);
    formData.append("brand_type", teacher.teacher_type);
    // formData.append('price',classType.state === 1? parseInt(classPrice.state) : parseInt(totalPrice))
    // formData.append('discount_price',discountPrice.state)
    formData.append("min_user_count", minUserCount.state);
    formData.append("max_user_count", maxUserCount.state);
    formData.append("all_age_yn", ageType.state);
    formData.append("min_target_age", minUserCount.state);
    formData.append("max_target_age", maxUserCount.state);

    formData.append("class_time", classTime.state);

    // formData.append('summary',classSummary.state)
    formData.append("inform_html", informHtml);
    formData.append("curriculum_html", curriculumHtml);
    formData.append("notice_html", noticeHtml);
    // formData.append('attachment',attachment.state)
    formData.append("materials", materials.state);
    // formData.append('textbooks',textbooks)

    if (classType.state == 1) {
      formData.append(
        "curriculum_json",
        JSON.stringify([
          {
            curriculum_name: className.state,
            price: classPrice.state,
            discount_price: discountPrice.state,
          },
        ])
      );
    } else {
      var curriculum = [];

      // [{"curriculum_name":"리액트 초급","price":12000,"discount_price":11000},{"curriculum_name":"리액트 중급","price":12000,"discount_price":11000}]
    }

    formData.append("schedule_json", JSON.stringify(schedules));
    formData.append("option_json", JSON.stringify(options));
    formData.append("keywords", JSON.stringify(selectedKeyword));

    // formData.append('schedules',JSON.stringify(schedules))

    if (thumbnailImages) {
      thumbnailImages.forEach((img) => {
        formData.append("thumbnail_image", img);
      });
    }

    if (materialFiles) {
      materialFiles.forEach((file) => {
        formData.append("material_file", file);
      });
    }

    onRegist(formData);
  }, [
    category.state,
    teacher.teacher_seq,
    teacher.teacher_type,
    className.state,
    classDescription.state,
    classType.state,
    classPrice.state,
    // totalPrice,
    // discountPrice.state,
    classTime.state,
    minUserCount.state,
    maxUserCount.state,
    ageType.state,
    minTargetAge.state,
    maxTargetAge.state,

    informHtml,
    curriculumHtml,
    noticeHtml,

    materials.state,
    selectedKeyword,
    schedules,
    options,
    thumbnailImages,
    materialFiles,
    onRegist,
  ]);

  const onTeacherSelected = useCallback((e, newVal) => {
    setTeacherSearchShow(false);
    setTeacher({ ...newVal });
  }, teacherSearchShow);

  const onScheduleChanged = useCallback(
    (e) => {
      setSchedule(e);
      if (classType === 2) {
      }
    },
    [classType]
  );

  const onCurriculumChanged = useCallback((e) => {
    setCurriculums(e);
  }, []);

  const onInformChanged = useCallback((e) => {
    setInformHtml(e);
  }, []);

  const onCurriculumHtmlChanged = useCallback((e) => {
    setCurriculumHtml(e);
  }, []);

  const onNoticeChanged = useCallback((e) => {
    setNoticeHtml(e);
  }, []);

  const isSelectedKeyword = (key) => {
    const found = selectedKeyword.find((keyword) => {
      return keyword === key;
    });
    return found !== undefined;
  };

  const onSelectedKeyword = (e, value) => {
    if (isSelectedKeyword(value)) {
      setSelectedKeyword(
        selectedKeyword.filter((keyword) => keyword !== value)
      );
    } else {
      setSelectedKeyword(selectedKeyword.concat(value));
    }
  };

  return (
    <>
      <DashboardNavbar title="신규 수업 신청" />

      <LACard>
        <LACardTitle title="수업 기본 정보" />

        <LAProperty name="선생님" required>
          <LAGridContainer>
            <LALabel wPercent={8}>{!!teacher && teacher.teacher_name}</LALabel>
            <LAButton wPercent={15} onClick={() => setTeacherSearchShow(true)}>
              선생님 검색
            </LAButton>
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="카테고리">
          <LAGridContainer>
            <CategoryComboView
              value={category.state}
              onChange={category.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="클래스 명">
          <LAInput
            placeholder="책을 즐기며 읽는 법을 배우는 클래스"
            value={className.state}
            onChange={className.handleChange}
          />
        </LAProperty>
        <LAProperty name="클래스 요약">
          <LAInput
            placeholder="클래스 요약을 입력하세요."
            value={classDescription.state}
            onChange={classDescription.handleChange}
          />
        </LAProperty>

        <LAProperty name="클래스 유형">
          <LACodeRadioGroup
            codeType="CLASS_TYPE"
            value={classType.state}
            onChange={onChangeClassType}
          />
          {/* <LARadioGroup
          props={classTypeCodes}
          value={classType.state}
          onChange={onChangeClassType}
          />           */}
        </LAProperty>

        {/* <LAProperty name="클래스 회차">
          <LAComboBox
            wPercent={50}
            items={comboItems}
            value={comboValue}
            onChange={onComboValueChanged}
          />
        </LAProperty> */}
        <div>
          {classType.state > 1 ? (
            <LAProperty name="클래스 회차">
              <LAComboBox
                wPercent={20}
                items={classTimeItems}
                value={classTime.state}
                onChange={classTime.handleChange}
              />
            </LAProperty>
          ) : (
            <></>
          )}
        </div>

        <LAProperty name="수업 시간">
          <LAInput
            wPercent={10}
            placeholder="수업시간"
            value={classTimeItems.state}
            onChange={classTimeItems.handleChange}
          />
        </LAProperty>
        <LAProperty name="정원">
          <LAGridContainer>
            <LALabel wPercent={5}>최소</LALabel>
            <LAComboBox
              wPercent={20}
              items={userCounts}
              value={minUserCount.state}
              onChange={minUserCount.handleChange}
            />
            <LALabel wPercent={5}>~</LALabel>
            <LALabel wPercent={5}>최대</LALabel>
            <LAComboBox
              wPercent={20}
              items={userCounts}
              value={maxUserCount.state}
              onChange={maxUserCount.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="대상 연령">
          <LAGridContainer>
            <LACodeRadioGroup
              codeType="TARGET_AGE_QUERY_TYPE"
              value={ageType.state}
              onChange={ageType.handleChange}
            />
            {/* <LARadioGroup
              props={ageTypeCodes}
              value={ageType.state}
              onChange={ageType.handleChange}
            /> */}
            <LAGridContainer>
              <LALabel wPercent={5}>최소</LALabel>
              <LAComboBox
                wPercent={20}
                items={targetAgeItems}
                value={minTargetAge.state}
                onChange={minTargetAge.handleChange}
              />
              <LALabel wPercent={7}>~</LALabel>
              <LALabel wPercent={5}>최대</LALabel>
              <LAComboBox
                wPercent={20}
                items={targetAgeItems}
                value={maxTargetAge.state}
                onChange={maxTargetAge.handleChange}
              />
            </LAGridContainer>
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <CurriculumView
        classTime={classTime.state}
        curriculums={curriculums}
        onChange={onCurriculumChanged}
      />
      <ScheduleView
        schedules={schedules}
        curriculums={curriculums}
        onChange={onScheduleChanged}
      />
      {/* <div>
      { 
        classType.state === 1 
        ?<OneDayScheduleView schedules={schedules} onChange={onScheduleChanged}/>         
        :<PackageScheduleView schedules={schedules} onChange={onScheduleChanged}/>          
      }
      </div> */}

      {/* 클래스 금액 */}
      {/* <LACard next>
        <LACardTitle title="클래스 금액" />

        <LAProperty name="수업 금액">
          <LAGridContainer>
            <LAListView
              wPercent={30}
              headers={['정상가']}
              // items={[['120,000 원']]}
            />
            <LAGridItem wPercent={10}>

              <div>
              {
                classType.state === 1 
                ?
                <LAInput
                type='number'                 
                placeholder="정상가"                            
                name="price"
                value={classPrice.state}
                onChange={classPrice.handleChange}                                
                />
                :
                <LAInput
                type='number'                 
                placeholder="정상가"                            
                name="price"                
                value={totalPrice}
                // onChange={onChange} 
                />
              }  
              </div>
              
              
            </LAGridItem>
            
            <LAGridItem wPercent={10}>
              <ArrowForward color="inherit" />
              <LALabel>할인율 {discountRate}%</LALabel>
            </LAGridItem>
            <LAListView
              wPercent={30}
              headers={['할인가']}
              // items={[['90,000 원']]}
            />
            <LAGridItem wPercent={10}>
              <LAInput
                type='number'                 
                placeholder="할인가"                            
                name="discount_price"
                value={discountPrice.state}
                onChange={onChangeDiscountPrice}    
                // onChange={onChange} 
                />
            </LAGridItem>
          </LAGridContainer>
          <LAWarningLabel>
            금액 입력 시, 할인율은 자동 계산되며 소수점인 경우, 버림으로
            계산되어 프런트에 노출됩니다.
          </LAWarningLabel>
        </LAProperty>
      </LACard> */}
      <LACard next>
        <LACardTitle title="클래스 이미지 정보" />

        <LAProperty name="클래스 이미지 정보(섬네일)">
          <LARadioGroup
            props={[
              {
                text: "기본 이미지 1",
                value: 0,
              },
              {
                text: "기본 이미지 2",
                value: 1,
              },
              {
                text: "기본 이미지 3",
                value: 2,
              },
              {
                text: "기본 이미지 4",
                value: 3,
              },
              {
                text: "직접 등록",
                value: 4,
              },
            ]}
            // callback={onThumbnailTypeChanged}
          />
        </LAProperty>

        <LAProperty name="">
          <LAGridContainer>
            <LAImageListItem wPercent={13} imageUrl={imageUrl} />
            <LAGridItem wPercent={85}>
              <LAGridContainer>
                <LAInput
                  wPercent={50}
                  layouts={{ pr: { sm: 1 } }}
                  type="file"
                  accept="image/jpg,image/png,image/jpeg,image/gif"
                  onChange={onChangeThumbnailImage}
                />
                {/* <LAButton wPercent={15}>파일 선택</LAButton> */}
              </LAGridContainer>
              <MDBox color="text" fontSize="1rem" lineHeight={1}>
                <LALabel
                  props={{
                    variant: "caption",
                    color: "error",
                    fontWeight: "regular",
                    verticalAlign: "middle",
                  }}
                >
                  (이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로
                  등록됩니다.
                  <br />
                  RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)
                  <br />
                  이미지 사이즈 : 1000 * 1000 / 형식 : jpg, jpeg, png (jpeg 등록
                  권장)
                  <br />
                  1MB 이하로 등록가능)
                </LALabel>
              </MDBox>
            </LAGridItem>
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="추가 이미지">
          <LAGridContainer>
            <LAButton wPercent={20}>파일 선택</LAButton>
            <LAImageList images={thumbnailImages} layouts={{ pt: 1 }} />
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 소개" />

        <LAProperty name="클래스 소개">
          <LATextEditor onChange={onInformChanged}>클래스 소개</LATextEditor>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="커리큘럼 & 유의사항" />

        <LAProperty name="커리큘럼">
          <LATextEditor onChange={onCurriculumHtmlChanged}>
            커리큘럼
          </LATextEditor>
        </LAProperty>

        <LAProperty name="따로 챙겨주세요">
          <LAInput
            placeholder="따로 챙겨야할 준비물 입력 (ex. 가위, 칼, 풀, 스케치북)"
            value={materials.state}
            onChange={materials.handleChange}
          />
        </LAProperty>

        <LAProperty name="클래스 전 꼭 확인해주세요">
          <LATextEditor onChange={onNoticeChanged}>
            클래스 전 꼭 확인해주세요.
          </LATextEditor>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 키워드" />

        <LAProperty name="키워드 선택">
          <LAKeywords
            keywords={keywords}
            // isSelectedKeyword={isSelectedKeyword}
            selectedKeywords={selectedKeyword}
            // onSelectedKeyword={onSelectedKeyword}
            onClick={onSelectedKeyword}
          />
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 자료 정보" />

        <LAProperty name="자료 등록">
          <LAGridContainer>
            {/* <LAButton wPercent={15} onClick={() => openFileSelector()}>파일 선택</LAButton> */}
            <LAInput
              wPercent={50}
              layouts={{ pr: { sm: 1 } }}
              type="file"
              onChange={onChangeAttachment}
            />
            <LAListView
              layouts={{ pt: 1 }}
              headers={["#", "파일명", "파일사이즈"]}
              items={materialItems}
            />

            <br />
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 교재 정보" />

        <LAProperty name="교재">
          <LAGridContainer>
            <LAInput wPercent={30} layouts={{ pr: { sm: 1 } }} />
            <LAButton wPercent={15} onClick={() => setTextbookSearchShow(true)}>
              교재 선택
            </LAButton>
            <LAGridContainer>
              <LAListView
                layouts={{ pt: 1 }}
                headers={[]}
                items={[["코드 번호", "교재명교재명", "17,000원", "삭제"]]}
              />
            </LAGridContainer>
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LAButton wPercent={15} onClick={onRegistClick}>
        등록 하기
      </LAButton>

      <TeacherSearchModal
        show={teacherSearchShow}
        handleClose={() => setTeacherSearchShow(false)}
        handleSelect={onTeacherSelected}
      />

      <ClassScheduleModal
        show={classScheduleShow}
        handleClose={() => setClassScheduleShow(false)}
      />

      <TextbookSearchModal
        show={textbookSearchShow}
        handleClose={() => setTextbookSearchShow(false)}
      />
    </>
  );
}

export default ClassApplicationRegist;
