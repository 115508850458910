import React, { useState, useCallback, useEffect, useContext } from 'react';


// import { useParams } from 'react-router-dom';
// import { UNSAFE_NavigationContext } from "react-router-dom";

import AllianceDetailContents from './AllianceDetailContents';

import { LAModal} from 'modules/Widgets';

function AllianceDetailModal({allianceSeq, show, handleClose }) {

    // const navigation = useContext(UNSAFE_NavigationContext).navigator;

    // useEffect(() => {
    //         window.history.pushState(null, null, window.location.pathname);
    //         if (navigation) {
    //         navigation.listen((locationListener) => {
    //             if(locationListener.action == 'POP') {
    //                 handleClose();
    //             }
    //         });
    //     }
    // }, []);

    return (
    <LAModal
      title="제휴/협력사 상세"
      width="60%"
      height="auto"
      show={show}
      onClose={handleClose}
    >
        <AllianceDetailContents allianceSeq={allianceSeq} onClickList={handleClose}/>
    </LAModal>
  );
}

export default React.memo(AllianceDetailModal);
