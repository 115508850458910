import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { UNSAFE_NavigationContext } from "react-router-dom";
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import mcss from 'assets/css/modal.module.scss';
import { useNavigate, useParams } from 'react-router';
import {
    getTextCell,
    getNormalButton,
    LAModal,
    LAModalPopup,
    LACard,
    LACardTitle,
    LAGridContainer,
    LAProperty,
    LAButton,
    LAInput,
    LADataTable,
    useInput,
    useComboBox,
    LADivider, LALabel
} from "modules/Widgets";

const columns = [
    { Header: 'No', accessor: 'no', width: '15%' },
    { Header: '아이디', accessor: 'loginId', width: '35%' },
    { Header: '이름', accessor: 'name', width: '30%' },
    { Header: '선택', accessor: 'select', width: '20%' },
];

function MdSearchModal({ show, handleClose, handleSelect }){
    const { queries } = useApiContext();
    const { showAlert } = usePopAlert();
    const searchInput = useInput('');

    const [filterInput, setFilterInput] = useState('');

    const [resultData, setResultData] =  useState([]);
    const [rows, setRows] = useState([]);

    const navigation = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
            window.history.pushState(null, null, window.location.pathname);
            if (navigation) {
            navigation.listen((locationListener) => {
                if(locationListener.action == 'POP') {
                    handleClose();
                }
            });
        }
    }, []);

    useEffect(() => {
            if (!show) {
                setFilterInput('');
                return;
            }
            requestMasters();
    }, [])

    useEffect(() => {
        filterList();
    }, [resultData, filterInput])

    const filterList = useCallback(() => {
        let filterKeyword = filterInput.trim();
        let filterData = resultData;
        
        if (filterKeyword != '') {
            filterData = resultData.filter((item) => {
                return item.member_name.indexOf(filterKeyword) >= 0;
            });
        }
        
        setRows(filterData.map((resultData, index) => dataToRow(resultData, index)));
    }, [resultData, filterInput])

    const select = ((item) => {
        // modalPopupState.processCallback(item);
        handleSelect(item)
    });

    const dataToRow = (data, index) => {
        return {
            no: getTextCell(`${index + 1}`),
            loginId: getTextCell(`${data.login_id ? data.login_id : ''}`),
            name: getTextCell(`${data.member_name ? data.member_name : ''}`),
            select: getNormalButton('선택', data, select),
        };
    };

    const { request: requestMasters } = useCommonQuery({
        query: queries.Account.masters,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setResultData(data.result_data);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', 'MD 목록 조회에 실패했습니다.');
                handleClose();
            },
        },
        initEnabled: false,
    });

    const onSearchClick = useCallback(() => {
        requestMasters();
    }, [requestMasters]);

    const onInitClick = useCallback(() => {
        setFilterInput('');
       /* searchInput.reset();*/
    }, [setFilterInput]);

  return (
    // <LAModal title="MD 검색" height="80%" width="850px" className={mcss.mdSearchModal} show={show} handClose={handleClose}>
    <LAModal title="" height="80%" width="850px" show={show} >
        <LACard component="div">
            <LACardTitle title="MD 검색" />
            <LAProperty name="직접 검색" wNameWidth={16}>
                <LAGridContainer>
                    <LAInput
                        wPercent={60}
                        layouts={{ pl: 1 }}
                        value={filterInput}
                        onChange={(e)=> { 
                            setFilterInput(e.target.value)
                        }}
                    />
                    <LAButton
                      wPercent={20}
                      layouts={{ pl: { sm: 0.5 } }}
                      onClick={onSearchClick}
                    >
                        검색
                    </LAButton>
                    <LAButton
                      wPercent={20}
                      layouts={{ pl: { sm: 0.5 } }}
                      onClick={() => onInitClick()}>초기화
                    </LAButton>
                </LAGridContainer>
            </LAProperty>
            <LADivider pt={4} />
            <LADataTable columns={columns} rows={rows} />
        </LACard>
        <LAGridContainer pt={2}>
            <LALabel wPercent={80} />
            <LAButton
              wPercent={20}
              layouts={{ pr: { sm: 0.5 } }}
              onClick={handleClose}
            >
                닫기
            </LAButton>
        </LAGridContainer>
    </LAModal>
  );
}

export default React.memo(MdSearchModal);
