import { createContext, useState, useCallback } from 'react';
import { getUseContext } from 'common/utils';

const initialData = {
  state: {
    title: '타이틀',
    description: '내용',
    confirmText: '확인',
    cancelText: '취소',
    visible: false,
    visibleConfirm: true,
    visibleCancel: false,
  },
  action: {
    showAlert: () => {},
    showConfirm: () => {},
    confirm: () => {},
    cancel: () => {},
  },
  callback: {
    confirmHandler: () => {},
    cancelHandler: () => {},
  },
};

const PopAlertContext = createContext();

function PopAlertProvider({ children }) {
  const [state, setState] = useState(initialData.state);
  const [callback, setCallback] = useState(initialData.callback);
  const { confirmHandler, cancelHandler } = callback;

  const showAlert = useCallback(
    (title, description, handlers = {}, props = {}) => {
      if (!description) {
        return;
      }

      setState(() => ({
        ...initialData.state,
        ...props,
        title,
        description,
        visible: true,
      }));
      setCallback(() => ({ ...initialData.callback, ...handlers }));
    },
    [],
  );

  const showConfirm = useCallback(
    (title, description, handlers = {}, props = {}) => {
      if (!description) {
        return;
      }

      setState(() => ({
        ...initialData.state,
        ...props,
        title,
        description,
        visible: true,
        visibleCancel: true,
      }));
      setCallback(() => ({ ...initialData.callback, ...handlers }));
    },
    [],
  );

  const confirm = useCallback(() => {
    setState(() => initialData.state);
    if (confirmHandler && typeof confirmHandler === 'function') {
      confirmHandler();
      setCallback(() => initialData.callback);
    }
  }, [confirmHandler]);

  const cancel = useCallback(() => {
    setState(() => initialData.state);
    if (cancelHandler && typeof cancelHandler === 'function') {
      cancelHandler();
      setCallback(() => initialData.callback);
    }
  }, [cancelHandler]);

  const value = {
    state,
    action: { showAlert, showConfirm, confirm, cancel },
  };

  return (
    <PopAlertContext.Provider value={value}>
      {children}
    </PopAlertContext.Provider>
  );
}

export const usePopAlertContext = () => getUseContext(PopAlertContext);
export default PopAlertProvider;
