import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/ko';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import { LASimpleTable, LADatePickerButton } from 'modules/Widgets';

function TimeTableList({ start_dt,class_time, timetables, onChange }) {
  const { showAlert } = usePopAlert();
  const [newTimetables, setNewTimetables] = useState([]);

  function calculateAvailabeDate(inputTimetables) {
    let tempTimetables = JSON.parse(JSON.stringify(inputTimetables));

    tempTimetables.forEach((timetable, index) => {
      if (index == 0) {
        timetable.minDate = start_dt;
      } else {
        timetable.minDate = moment(tempTimetables[index - 1].schedule_date)
          .add(1, 'days')
          .format('YYYYMMDD');
      }

      if (index == tempTimetables.length - 1) {
        timetable.maxDate = '';
      } else {
        timetable.maxDate = moment(tempTimetables[index + 1].schedule_date)
          .add(-1, 'days')
          .format('YYYYMMDD');
      }
    });

    onChange(tempTimetables);
    setNewTimetables(tempTimetables);
  }

  useEffect(() => {
    calculateAvailabeDate(timetables);
  }, [timetables]);

  const onModifyClick = useCallback(
    (handleClick) => {
      showAlert(
        '알림',
        '클래스를 신청한 고객과 사전 협의 후 일정을 변경해주세요.\n무단변경 시 패널티가 부여될 수 있습니다.',
        {
          confirmHandler: () => handleClick(),
        },
      );
    },
    [newTimetables],
  );

  const onScheduleChange = useCallback(
    (date, index) => {
      let tempTimetables = [];
      newTimetables.forEach((timetable, i) => {
        if (i == index) {
          timetable.schedule_date = moment(date).format('YYYYMMDD');
        }
        tempTimetables.push(timetable);
      });

      calculateAvailabeDate(tempTimetables);
    },
    [newTimetables],
  );

  const getScheduleRows = useCallback(() => {
    const result = [];
    if (newTimetables && newTimetables.length > 0) {

      for (const [index, timetable] of Object.entries(newTimetables))
        result.push([
          {
            text: `${parseInt(timetable.curriculum_times)}`,
          },
          {
            text: timetable.supplementary_yn == 1 ? '보강' : '정규',
          },
          {
            text: moment(timetable.schedule_date).format('YYYY-MM-DD (dd)'),
          },
          {
            text: moment(
              timetable.schedule_date + ' ' + timetable.schedule_time,
            ).format('A hh:mm'),
          },
          {
            text: moment(timetable.schedule_date + ' ' + timetable.schedule_time).add(class_time,'minutes').format('A hh:mm'),
          },
          {
            text: timetable.suspended_yn == 1 ? '중지' : '진행',
          },
          {
            text: '',
          },
          {
            text: '변경',
            type: 'date_button',
            value: timetable.schedule_date,
            minDate: timetable.minDate ? timetable.minDate : '',
            maxDate: timetable.maxDate ? timetable.maxDate : '',
            callback: {
              handleClick: onModifyClick,
              onChange: (date) => onScheduleChange(date, index),
            },
          },
        ]);
    }

    return result;
  }, [newTimetables]);

  const scheduleTableData = useMemo(() => {
    return {
      headers: [
        {
          text: '회차',
        },
        {
          text: '구분',
        },
        {
          text: '클래스 일정',
        },
        {
          text: '시작 시간',
        },
        {
          text: '종료 시간',
        },
        {
          text: '진행 여부',
        },
        {
          text: '종료 여부',
        },
        {
          text: '변경',
        },
      ],
      // wPercentList: [10, 37, 38, 15],
      wPercentList: [8, 8, 20, 10,10, 15, 15, 10],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  return (
    <>
      {/* <LACard next>         */}
      <LASimpleTable tableData={scheduleTableData} />
      {/* </LACard> */}
    </>
  );
}
export default React.memo(TimeTableList);
