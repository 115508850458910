import useInput from "modules/Widgets/hooks/useInput";
import useFileInput from "common/hooks/useFileInput";
import React, { useCallback, useEffect } from "react";
import BlueCloseIcon from "assets/images/BlueCloseIcon.png";
import { LAButton } from "modules/Widgets";

// Comment.js

function Comment({
  userName,
  handleRegist,
  comment = undefined,
  parentId = undefined,
  isNew = false,
}) {
  const commentInput = useInput(comment ? comment.contents : "");
  const fileInput = useFileInput(10000, {
    name: comment?.file_original_name ? comment.file_original_name : "",
    file: undefined,
    info: {},
  });
  const { feedback_seq } = comment ? comment : {};

  const onRegistClick = useCallback(
    (e) => {
      const data = {
        text: commentInput.state,
        file: fileInput.state.file ? fileInput.state : null,
      };
      handleRegist(
        e,
        data,
        feedback_seq,
        parentId,
        comment !== undefined && !comment.isTemp
      );
      if (isNew) {
        commentInput.reset();
        fileInput.reset();
      }
    },
    [
      commentInput,
      fileInput,
      handleRegist,
      feedback_seq,
      parentId,
      comment,
      isNew,
    ]
  );

  const onRemoveFileClick = useCallback(() => {
    fileInput.reset(true);
  }, []);

  return (
    <div className="comment">
      <div className="comment_textWrap">
        <textarea
          maxLength={3000}
          value={commentInput.state}
          placeholder={
            isNew
              ? `※ 수업 관련 과제와 피드백 등을 학습자와 1:1로 주고받을 수 있습니다. \n※ 수업과 관련 없는 내용은 통보 없이 삭제 처리될 수 있습니다. \n※ 첨부파일은 1개 등록 가능하며 용량은 최대 2MB로 제한됩니다.`
              : ""
          }
          onChange={commentInput.handleChange}
        />
        {/* 2022-08-04 피드백 파일 삭제 버튼 추가 시작 */}
        <div className="comment-file-wrap">
          <span className="file">{fileInput.state.name}</span>
          {fileInput.state.name.length > 0 && (
            <button className="delete-btn-wrap" onClick={onRemoveFileClick}>
              <img src={BlueCloseIcon} alt="삭제 버튼 이미지" />
            </button>
          )}
        </div>
        {/* 2022-08-04 끝 */}
        <span className="len">({commentInput.state.length}/3000)</span>
      </div>

      <div className="comment_bottom">
        <input
          onChange={fileInput.handleChange}
          type="file"
          id={`task_file ${feedback_seq || (comment?.isTemp ? "temp" : "")}`}
        />
        <label
          htmlFor={`task_file ${
            feedback_seq || (comment?.isTemp ? "temp" : "")
          }`}
        ></label>
        {/* <button onClick={onRegistClick}>등록</button> */}
        <LAButton onClick={onRegistClick}>등록</LAButton>
      </div>
    </div>
  );
}

export default React.memo(Comment);
