import { createContext, useState, useCallback, useMemo } from 'react';
import { getUseContext } from 'common/utils/ContextUtils';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';

const initialData = {
  code: [],
  dbCode: [],
  category: [],
  keywords: [],
  avatars: [],
  defaultClassImages: [],
};

const CodeContext = createContext();
const CategoryContext = createContext();
const KeywordContext = createContext();

const ImageContext = createContext();

function CodeProvider({ children }) {
  const { queries } = useApiContext();

  const [codeList, setCodeList] = useState(initialData.codes);
  const [dbCodeList, setDbCodeList] = useState([]);
  const [categoryList, setCategoryList] = useState(initialData.categories);
  const [keywordList, setKeywordList] = useState(initialData.avakeywordstars);
  const [avatars, setAvatars] = useState(initialData.avatars);

  const [defaultClassImages, setDefaultClassImages] = useState(
    initialData.defaultClassImages,
  );

  const { result: resultCodes } = useCommonQuery({
    query: queries.Code.getList,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setCodeList(data.result_data);
        } else {
        }
      },
      onError: (error) => {
      },
    },
  },[]);

  const { result: resultDbCodes } = useCommonQuery({
    query: queries.Code.getDbCodeList,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code !== '0000') {
          return;
        }

        setDbCodeList(data.result_data);
      },
      onError: (error) => {
      },
    },
  },[]);

  const { result: resultCategories } = useCommonQuery({
    query: queries.Category.getList,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setCategoryList(data.result_data);
        } else {
        }
      },
      onError: (error) => {
      },
    },
  },[]);

  const { result: resultKeywords } = useCommonQuery({
    query: queries.Keyword.getList,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          if (data.result_data && data.result_data.length) {
            let gKeywords = [];
            data.result_data.forEach((keyword) => {
              gKeywords.push(keyword.keyword);
            });
            setKeywordList(gKeywords);
          }
          // setKeywordList(data.result_data)
        } else {
        }
      },
      onError: (error) => {
      },
    },
  },[]);

  const { result: resultAvatars } = useCommonQuery({
    query: queries.Image.getDefaultImages,
    params: 0,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setAvatars(data.result_data);
        } else {
        }
      },
      onError: (error) => {
      },
    },
  },[]);
  // const { result : resultData , request : requestDetail} = useCommonQuery({
  const { result: resultDetaultImages } = useCommonQuery({
    query: queries.Image.getDefaultImages,
    params: 1,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setDefaultClassImages(data.result_data);
        } else {
        }
      },
      onError: (error) => {
      },
    },
  },[]);

  const getCodeFilter = useCallback((type) => {
    if (codeList && codeList.length > 0) {
      return codeList.filter(
        (c) => c && c.group === type && c.isDisplay === true,
      );
    } else return [];
  },[codeList]);

  const getDbCodeFilter = useCallback((type) => {
    if (dbCodeList && dbCodeList.length > 0) {
      return dbCodeList.filter((c) => c && c.group === type);
    } else {
      return [];
    }
  },[dbCodeList]);

  const getRadioCodeList = useCallback((type, showAll) => {
    let list = getCodeFilter(type).map((c) => {
      return { value: c.code, text: c.name };
    });
    if (showAll) list.unshift({ text: '전체', value: -1 });
    return list;
  },[getCodeFilter]);

  const getComboCodeList = useCallback((type, showAll, checkAll) => {
    var list = getCodeFilter(type).map((c) => {
      return { key: c.code, value: c.name };
    });
    if (showAll) list.unshift({ value: '전체', key: -1 });
    else if (checkAll) list.unshift({ value: '선택', key: -1 });
    return list;
  },[getCodeFilter]);

  const getComboDbCodeList = useCallback((type) => {
    return getDbCodeFilter(type).map((c) => {
      return { key: c.code, value: c.name };
    });
  },[getDbCodeFilter]);

  const getCheckboxCodeList = useCallback((type, showAll) => {
    console.log(type);
    let list = getCodeFilter(type).map((c) => {
      return { value: c.code, text: c.name };
    });
    if (showAll) list.unshift({ text: '전체', value: -1 });
    return list;
  },[getCodeFilter]);

  const codeValue = useMemo(() => {
    return {
      codeList,
      action: {
        getRadioCodeList,
        getComboCodeList,
        getComboDbCodeList,
        getCheckboxCodeList,
      },
    };
  }, [
    codeList,
    getRadioCodeList,
    getComboCodeList,
    getComboDbCodeList,
    getCheckboxCodeList,
  ]);

  const categpryValue = useMemo(() => {
    return { categoryList };
  }, [categoryList]);

  const keywordValue = useMemo(() => {
    return { keywordList };
  }, [keywordList]);

  const imageValue = useMemo(() => {
    return { avatars, defaultClassImages };
  }, [avatars, defaultClassImages]);

  return (
    <CodeContext.Provider value={codeValue}>
      <CategoryContext.Provider value={categpryValue}>
        <KeywordContext.Provider value={keywordValue}>
          <ImageContext.Provider value={imageValue}>
            {children}
          </ImageContext.Provider>
        </KeywordContext.Provider>
      </CategoryContext.Provider>
    </CodeContext.Provider>
  );
}

export const useCodeContext = () => getUseContext(CodeContext);
export const useCategoryContext = () => getUseContext(CategoryContext);
export const useKeywordContext = () => getUseContext(KeywordContext);
export const useImageContext = () => getUseContext(ImageContext);
export default CodeProvider;
