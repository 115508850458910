import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { getTodayDateText, dateTimeToString } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';

import ClassInquiryDetailModal from './components/ClassInquiryDetailModal';
import { periodItems } from 'common/constants';
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  useRadioGroup,
  LAButton,
  LAComboBox,
  useComboBox,
  LAInput,
  useInput,
  LADatePicker,
  useDatePicker,
  LATabSelector,
  LADataTable2,
  getTextCell,
  getNormalButton,
  LACodeRadioGroup,
  LACodeComboBox,
  useTabSelector,
  useModal,
} from 'modules/Widgets';
// const periodItems = [
//   { key: 0, value: '1개월' },
//   { key: 1, value: '3개월' },
//   { key: 2, value: '6개월' },
//   { key: 3, value: '1년' },
// ];

function ClassInquirywManagement() {
  const todayDateText = getTodayDateText('-');
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();

  const [rows, setRows] = useState([]);

  const answerYn = useRadioGroup(-1);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);

  const searchTargetType = useComboBox(0);
  const searchText = useInput('');

  const [selectedInquirySeq, setSelectedInquirySeq] = useState(0);
  const classInquiryDetailModal = useModal(false);

  const [tablePage, setTablePage] = useState(0);
  const [lastVisitTablePage, setLastVisitTablePage] = useState(0);

  const [ searchParams, setSearchParams ] = useState({});

  const columns = useMemo(() => {
    return [
      { Header: 'No', accessor: 'no', width: '10%' },
      {
        Header: '클래스 명',
        accessor: 'class_name',
        width: '20%',
        align: 'left',
      },
      {
        Header: '문의 제목',
        accessor: 'inquiry_title',
        width: '20%',
        align: 'left',
      },
      { Header: '작성자', accessor: 'writer', width: '10%' },
      { Header: '작성일', accessor: 'regist_date', width: '12%' },
      { Header: '답변', accessor: 'answer_yn', width: '8%' },
      { Header: '선생님', accessor: 'teacher_name', width: '10%' },
      { Header: '상세', accessor: 'detail', width: '10%' },
    ];
  }, []);

  const dataToRow = (data, index) => {
    return {
      no: getTextCell(data.inquiry_seq),
      class_name: getTextCell(data.class_name),
      inquiry_title: getTextCell(
        data.subject.length > 20
          ? data.subject.slice(0, 20) + ' ...'
          : data.subject,
        `${data.inquiry_seq}`,
        onDetailClick,
      ),
      writer: getTextCell(data.member_name),
      teacher_name: getTextCell(data.teacher_name),
      answer_yn: getTextCell(data.answer_yn ? '답변' : '미답변'),
      regist_date: getTextCell(
        moment(data.created_dt).format('YYYY-MM-DD (dd) A hh:mm:ss'),
      ),

      detail: getNormalButton('상세', `${data.inquiry_seq}`, onDetailClick),
      // detail: getNormalButton('상세', `${item.schedule_seq}`, onsDetailClick),
    };
  };

  function onDetailClick(no) {
    // showAlert('알림', '준비중입니다.');
    // navigate(`detail/${no}`);
    setSelectedInquirySeq(no);
    classInquiryDetailModal.visible(true);
  }

  const onSearchClick = useCallback(() => {
    let searchParams = {
      answer_yn: answerYn.state < 0 ? undefined : answerYn.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      query_type: searchTargetType.state,
      query: searchText.state,
    }
    setSearchParams(searchParams);
  }, [
    answerYn.state,
    searchStartDate.state,
    searchEndDate.state,
    searchTargetType.state,
    searchText.state,
  ]);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  const onDetailClose = useCallback(() => {
    onSearchClick();
    classInquiryDetailModal.handleClose();
  });

  const onChangeTablePage = useCallback((page) => {
    setLastVisitTablePage(page);
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) onSearchClick();
  }, [searchStartDate.state]);

  useEffect(() => {
    if (lastVisitTablePage != 0) {
      setTablePage(lastVisitTablePage);
    }
  }, [rows]);

  const onInitClick = useCallback(() => {
    answerYn.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    answerYn.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  return (
    <>
      <DashboardNavbar title="클래스 문의 관리" />

      {/* Start Search Area */}
      <LACard>
        <LAProperty name="답변 여부">
          <LACodeRadioGroup
            codeType="ANSWER_BOOLEAN_TYPE"
            showAll={true}
            value={answerYn.state}
            onChange={answerYn.handleChange}
          />
        </LAProperty>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LATabSelector
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={20}
              layouts={{ pl: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={2}>~</LALabel>
            <LADatePicker
              wPercent={20}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={[
                { key: 0, value: '통합검색' },
                { key: 1, value: '작성자' },
                { key: 2, value: '문의 제목' },
                { key: 3, value: '클래스 명' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={50}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 1 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable2
            columns={columns}
            loadUrl={ queries.Inquire.getInquireList }
            params={ searchParams }
            dataToRow={ dataToRow }
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
          />
        </LAGridContainer>
      </LACard>

      <ClassInquiryDetailModal
        inquirySeq={selectedInquirySeq}
        show={classInquiryDetailModal.state}
        handleClose={onDetailClose}
        handleSelect={classInquiryDetailModal}
      />
    </>
  );
}

export default ClassInquirywManagement;
