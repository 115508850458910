import React, {useMemo} from 'react';

import { useCodeContext } from 'common/context/CodeContext';

import {  
  LAComboBox,  
  LASelect,
} from 'modules/Widgets';

function LACodeComboBox({
    placeholder,
    dbCodeStatus = false, 
    codeType, 
    wPercent = 100, 
    layouts = {},
    showAll = false, 
    checkAll = false, 
    value, 
    onChange,
    readOnly = false,
    disabled = false,
    defaultOption,
    options
  }){

  const { action } = useCodeContext();

  const codes = useMemo(() => {
    let codes = dbCodeStatus? action.getComboDbCodeList(codeType) : action.getComboCodeList(codeType,showAll,checkAll);
    if (defaultOption) {
      codes.unshift(defaultOption);
    }
    return codes;
  }, [action, codeType, showAll, checkAll])

  return (
    <>
      {dbCodeStatus ?
        <LASelect
          wPercent={ wPercent }
          layouts={ layouts }
          placeholder={placeholder}
          items={ codes }
          value={ value }
          onChange={ onChange }
          readOnly={ readOnly }
          disabled={ disabled }
        />
        :
        <LAComboBox
          placeholder={placeholder}
          wPercent={ wPercent }
          layouts={ layouts }
          items={ !!codes && codes }
          value={ !!value && value }
          onChange={ onChange }
          readOnly={ readOnly }
          disabled={ disabled }
          options={options}
        />
      }
    </>
  )

}

export default React.memo(LACodeComboBox);