import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import MainMagazineModal from './components/MainMagazineModal';
import MainClassListModal from './components/MainClassListModal';
import MainMiddleBannerListModal from './components/MainMiddleBannerListModal';
import MainVideoBannerModal from './components/MainVideoBannerModal';

import MainContentDetailModal from './components/MainContentDetailModal';

import { sendCacheRefresh } from 'common/utils/EtcUtils';



import { DOMAIN } from 'api/AppConfig';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LALabel,
  LAButton,
  LASimpleTable,
  useModal,
} from 'modules/Widgets';


function MainContentsManagement() {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const navigate = useNavigate();
  
  const [selectedMainData, setSelectedMainData] = useState(null);

  const mainMagazineModal = useModal(false);
  const mainVideoBannerModal = useModal(false);
  const mainClassListModal = useModal(false);
  const mainMiddleBannerListModal = useModal(false);

  const mainContentDetailModal = useModal(false);

  // const mainMiddleBannerModal = useModal(false);
  const [mainExposures, setMainExposures] = useState([]);
  const [classType, setClassType] = useState(0);
  const [savable, setSavable] = useState(false);
  const [renderFlag, setRenderFlag] = useState(0);

  const { request: requestMainExposureChange } = useCommonMutation({
    query: queries.MainExhibition.updateMainExhibitionExposure,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          requestMainExposures();

          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '노출관리 정보 처리에 실패했습니다.');
      },
    },
  });

  const onClickChangeExpose = useCallback(
    (item) => {
      if (mainExposures?.length > 8) {
        showConfirm(
          '확인',
          '노출 상태를 변경하시겠습니까?\n프론트에 즉시 반영됩니다.',
          {
            confirmHandler: () => {
              requestMainExposureChange({
                mainSeq: item.main_seq,
                exposure_yn: !item.exposure_yn,
              });
            },
          },
        );
      } else {
        showAlert('알림', '데이터가 없습니다.');
      }
    },
    [mainExposures],
  );


  const onClickSetting = useCallback((item) =>{
    // mainTopBannerModal.visible(false);
    mainMagazineModal.visible(false);
    mainClassListModal.visible(false);
    mainMiddleBannerListModal.visible(false);
    mainVideoBannerModal.visible(false);
    setSelectedMainData(item);

    if(item.main_type == 1){ //배너
      // mainMiddleBannerListModal.visible(true);   
      mainContentDetailModal.visible(true)   
    }else if(item.main_type == 2){ //매거진
      // mainMagazineModal.visible(true);  
      mainContentDetailModal.visible(true)    
    }else if(item.main_type == 3){ //클래스      
      setClassType(item.main_type);
      // mainClassListModal.visible(true);      
      mainContentDetailModal.visible(true)
    }else if(item.main_type == 4){ //영상배너
      mainVideoBannerModal.visible(true);
      // mainContentDetailModal.visible(true)
    }else if(item.main_type == 5){ //키워드
      navigate('/main/home/keyword');    
    }
  }, []);

  const onDetailClosed = useCallback(() =>{

    mainContentDetailModal.visible(false)
    mainVideoBannerModal.visible(false)
    requestMainExposures();
  })
  const onMoveUpClick = useCallback((index) => {
    if (index < 1) {
      return;
    }
    setMainExposures((prev) => {
      const targetItem = prev.splice(index, 1);
      prev.splice(index - 1, 0, ...targetItem);
      return prev.map((item, index) => ({
        ...item,
        exposure_no: index + 1,
      }));
    });
    setSavable(true);
    setRenderFlag((prev) => prev + 1);
  }, []);

  const onMoveDownClick = useCallback(
    
    (index) => {

      if (index >= mainExposures?.length) {
        return;
      }
      setMainExposures((prev) => {
        const targetItem = prev.splice(index, 1);
        prev.splice(index + 1, 0, ...targetItem);
        return prev.map((item, index) => ({
          ...item,
          exposure_no: index + 1,
        }));
      });
      setSavable(true);
      setRenderFlag((prev) => prev + 1);
    },
    [mainExposures],
  );

  const onApplyClick = useCallback(() => {
    showConfirm(
      '알림',
      '메인 노출 순서를 변경하시겠습니까?\n프론트에 즉시 반영됩니다.',
      {
        confirmHandler: () => {
          console.log(mainExposures);
          requestUpdateAllMainExposures(mainExposures);
        },
      },
    );
  }, [mainExposures]);

  const onClassListModalClosed = useCallback((e) =>{

    console.log('onClosedModal => ',e)
    mainClassListModal.visible(false)
    
    requestMainExposures();
  })
  const onGoFrontClick = useCallback(() => {
    window.open(`${DOMAIN}`, `_blank`);
  }, []);

  const managementTableData = useMemo(() => {
    return {
      headers: [
        { text: '순서' },
        { text: '분류' },
        { text: '타이틀' },
        { text: '노출 여부' },
        { text: '노출 버튼' },
        { text: '바로가기' },
        { text: '' },
        { text: '' },
      ],
      wPercentList: [5, 10 , 30, 10, 10, 10, 10, 10],
      rows: mainExposures.map((item, index) => [
        { text: `${item.exposure_no}` },
        { text: item.main_type_name },
        { text: item.title },
        { text: item.exposure_yn ? 'Y' : 'N' },
        {
          text: item.exposure_yn ? '미노출' : '노출',
          type: 'button',
          variant: item.exposure_yn ? 'outlined' : 'contained',
          callback: { onClick: () => onClickChangeExpose(item) },
        },
        {
          text: item.buttonText,
          type: 'button',
          callback: { onClick: () => onClickSetting(item) },
        },
        ...([
              {
                text: '▲',
                type: 'button',
                variant: 'outlined',
                callback: { onClick: () => onMoveUpClick(index) },
              },
              {
                text: '▼',
                type: 'button',
                variant: 'outlined',
                callback: { onClick: () => onMoveDownClick(index) },
              },
            ]
          ),
      ]),
    };
  }, [mainExposures, renderFlag]);

  const { request: requestMainExposures } = useCommonMutation({
    query: queries.MainExhibition.getMainExhibitions,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setMainExposures(
            data.result_data.map((item) => {
              return {
                ...item,
                buttonText: '설정',
              };

              // if (item.type === 1) {
              //   return { ...item, title: '상단 배너', buttonText: '배너 설정' };
              // } else if (item.type === 2) {
              //   return {
              //     ...item,
              //     title: '메인 매거진',
              //     buttonText: '매거진 설정',
              //   };
              // } else if (item.type === 3) {
              //   return {
              //     ...item,
              //     title: '클래스 목록 1 (가로형)',
              //     buttonText: '클래스 목록 1 설정',
              //   };
              // } else if (item.type === 4) {
              //   return {
              //     ...item,
              //     title: '클래스 목록 2 (세로형)',
              //     buttonText: '클래스 목록 2 설정',
              //   };
              // } else if (item.type === 5) {
              //   return {
              //     ...item,
              //     title: '클래스 목록 3 (가로형)',
              //     buttonText: '클래스 목록 3 설정',
              //   };
              // } else if (item.type === 6) {
              //   return {
              //     ...item,
              //     title: '클래스 목록 4 (세로형)',
              //     buttonText: '클래스 목록 4 설정',
              //   };
              // } else if (item.type === 7) {
              //   return {
              //     ...item,
              //     title: '클래스 목록 5 (가로형)',
              //     buttonText: '클래스 목록 5 설정',
              //   };
              // } else if (item.type === 8) {
              //   return {
              //     ...item,
              //     title: '영상 배너',
              //     buttonText: '영상 배너 설정',
              //   };
              // } else if (item.type === 9) {
              //   return { ...item, title: '키워드', buttonText: '키워드 관리' };
              // } else {
              //   return item;
              // }
            }),
          );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '노출관리 정보 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestUpdateAllMainExposures } = useCommonMutation({
    query: queries.MainExhibition.updateAllMainExhibition,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setSavable(false);
          
          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );

        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '노출관리 정보 조회에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    requestMainExposures();
  }, []);

  return (
    <>
      <DashboardNavbar title="전시 노출 관리" />

      <LACard>
        <LACardTitle title="메인 노출 관리" />
        <LAGridContainer layouts={{ pt: 2 }}>
          <LASimpleTable tableData={managementTableData} />
        </LAGridContainer>
        <LAGridContainer layouts={{ pt: 3 }}>
          <LALabel wPercent={75}></LALabel>
          <LAButton
            wPercent={10}
            layouts={{ pr: 1 }}
            onClick={onApplyClick}
            disabled={!savable}
          >
            저장
          </LAButton>
          <LAButton wPercent={15} variant={'outlined'} onClick={onGoFrontClick}>
            전시화면 바로가기
          </LAButton>
        </LAGridContainer>
      </LACard>

      {/* <LACard next>
        <LACardTitle title="변경 이력" />
        <LAGridContainer layouts={{ pt: 2 }}>
          <LADataTable columns={columns} rows={rows} defaultPage={tablePage} onChangePage={onChangeTablePage}/>
        </LAGridContainer>
      </LACard> */}

      {/* <MainTopBannerModal
        show={mainTopBannerModal.state}
        handleClose={mainTopBannerModal.handleClose}
      /> */}

      {/* <MainMiddleBannerListModal
      show={mainMiddleBannerListModal.state}
      handleClose={mainMiddleBannerListModal.handleClose}
      mainData={selectedMainData}
      /> */}

      {/* <MainMagazineModal
        show={mainMagazineModal.state}
        handleClose={mainMagazineModal.handleClose}
      /> */}

      {/* <MainClassListModal
        show={mainClassListModal.state}
        // handleClose={mainClassListModal.handleClose}
        handleClose={onClassListModalClosed}
        classType={classType}
        mainData={selectedMainData}
      /> */}

      {mainVideoBannerModal.state &&
      <MainVideoBannerModal
        show={mainVideoBannerModal.state}
        mainData={selectedMainData}
        handleClose={onDetailClosed}
      />
    }
      {mainContentDetailModal.state &&
      <MainContentDetailModal
        show={mainContentDetailModal.state}
        handleClose={onDetailClosed}
        data={selectedMainData}/>
      }
      

      {/* <MainMiddleBannerModal
        show={mainMiddleBannerModal.state}
        handleClose={mainMiddleBannerModal.handleClose}
      /> */}
    </>
  );
}
export default MainContentsManagement;
