import { createContext, useState, useCallback, useMemo } from "react";
import { getUseContext } from "common/utils/ContextUtils";

const initialData = {
  accessToken: "",
  loginState: false,
  login: {
    state: {
      member_seq: 0,
      member_name: "",
      expired_yn: false,
      expired_dt: null,
      resigned_yn: false,
      dormancy_yn: false,
      dormancy_dt: null,
      // accessToken: '',
      auth: {
        member_type: 0,
        member_type_name: "",
        auth_type: 0,
        auth_type_name: "",
      },
    },
    action: {
      setLoginData: () => {},
    },
  },
  member: {
    state: {
      member_type_code: 0,
      member_type_value: "",
      grade_type_code: 0,
      grade_type_value: "",
      point: {
        earned_point: 0,
        charged_point: 0,
      },
      coupon_count: 0,
    },
    action: {
      setMemberData: () => {},
    },
  },
  // kakao: {
  //   state: {
  //     accessToken: '',
  //     idToken: '',
  //     refreshToken: '',
  //   },
  //   action: {},
  // },
};

const LoginContext = createContext();
const MemberContext = createContext();
const KakaoContext = createContext();

function MemberProvider({ children }) {
  const [accessTokenData, setAccessTokenData] = useState(
    initialData.accessToken
  );
  const [isLogin, setLogin] = useState(initialData.loginState);
  const [loginState, setLoginState] = useState(initialData.login.state);
  const [memberState, setMemberState] = useState(initialData.member.state);
  // const [kakaoState, setKakaoState] = useState(initialData.kakao.state);

  const setLoginData = useCallback((data) => {
    setLogin(true);
    setLoginState(() => ({ ...data }));

    // setLogin((data.member_name && data.member_name.length > 0))
  }, []);

  const setAccessToken = useCallback((data) => {
    setAccessTokenData(() => data);
  }, []);

  const resetLoginData = useCallback(() => {
    setLogin(false);
    setLoginState(() => ({ ...initialData.login.state }));
    // let guestId = (new Date()).getTime();
    // guestId = guestId + '' + Math.floor(Math.random() * 100);

    window.ChannelIO("hideMessenger");
    window.ChannelIO(
      "boot",
      {
        pluginKey: "86d9261d-0a09-4a51-9d3a-edb61d9e105e",
        customLauncherSelector: "",
        hideChannelButtonOnBoot: true,
        language: "ko",
        // memberId: `${guestId}`, //fill with user id
        // profile: {
        //   name: `게스트`, //fill with user name
        // },
      },
      function onBoot(error, user) {
        if (error) {
          console.error(error);
        } else {
        }
      }
    );
    // window.ChannelIO('shutdown');
  }, []);

  const hasAccessToken = useMemo(
    () => !!accessTokenData && accessTokenData.length > 0,
    [accessTokenData]
  );

  // const isLogin = useMemo(
  //   () => login ,//loginState.member_name && loginState.member_name.length > 0,
  //   [login],
  // );

  const setMemberData = useCallback((data) => {
    setMemberState(() => ({ ...data }));
  }, []);

  const resetMemberData = useCallback(() => {
    setMemberState(() => ({ ...initialData.member.state }));
  }, []);

  const getMemberType = useCallback(() => {
    if (loginState.auth) return loginState.auth.member_type;
    else return undefined;
  }, [loginState.auth]);

  const getAuthType = useCallback(() => {
    if (loginState.auth) return loginState.auth.auth_type;
    else return undefined;
  }, [loginState.auth]);

  const getAccessToken = useCallback(() => {
    if (!accessTokenData) return localStorage.getItem("accessToken");
    // return sessionStorage.getItem('accessToken');
    else return accessTokenData;
  }, [accessTokenData]);

  const isTeacher = useMemo(() => {
    return loginState.auth && loginState.auth.member_type == 2;
  }, [loginState.auth]);

  const isAlliance = useMemo(() => {
    return (
      loginState.auth &&
      loginState.auth.member_type == 1 &&
      loginState.auth.auth_type == 5
    );
  }, [loginState.auth]);

  const loginValue = useMemo(() => {
    return {
      isLogin,
      hasAccessToken,
      accessTokenData,
      loginState,
      isTeacher,
      isAlliance,
      action: {
        setLoginData,
        resetLoginData,
        getMemberType,
        getAuthType,
        getAccessToken,
        setAccessToken,
      },
    };
  }, [
    isLogin,
    hasAccessToken,
    accessTokenData,
    loginState,
    isTeacher,
    isAlliance,
    setLoginData,
    resetLoginData,
    getMemberType,
    getAuthType,
    getAccessToken,
    setAccessToken,
  ]);

  const memberValue = useMemo(() => {
    return {
      memberState,
      action: { setMemberData, resetMemberData },
    };
  }, [memberState, resetMemberData, setMemberData]);

  return (
    <LoginContext.Provider value={loginValue}>
      <MemberContext.Provider value={memberValue}>
        {/* <KakaoContext.Provider value={kakaoValue}> */}
        {children}
        {/* </KakaoContext.Provider> */}
      </MemberContext.Provider>
    </LoginContext.Provider>
  );
}

export const useLoginContext = () => getUseContext(LoginContext);
export const useMemberContext = () => getUseContext(MemberContext);
export const useKakaoContext = () => getUseContext(KakaoContext);
export default MemberProvider;
