import React, { useState, useCallback, useRef, useEffect } from 'react';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  useInput,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';


function KeywordRegistModal({ show, handleClose }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const newKeywordText = useInput('');

  const { request: requestRegist } = useCommonMutation({
    query: queries.Keyword.addKeyword,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '키워드가 등록되었습니다.', {
            confirmHandler: () => {
              newKeywordText.reset();
              handleClose();
            }
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '키워드 등록에 실패했습니다.');
      },
    },
  });

  const onRegistClick = useCallback(() => {
    if (!newKeywordText?.state || newKeywordText.state.length === 0) {
      showAlert('알림', '키워드를 입력해주세요.');
      return;
    }

    requestRegist({keyword: newKeywordText.state})
  }, [newKeywordText.state]);

  return (
    <LAModal
      title="키워드 추가"
      width="45%"
      height="30%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
      <LAProperty name="키워드" required>
        <LAInput 
          value={newKeywordText.state}
          onChange={newKeywordText.handleChange}
        />
      </LAProperty>
      </LACard>

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={38}></LALabel>
        <LAButton 
          wPercent={20} 
          layouts={{ ml: 1 }}
          onClick={onRegistClick}
        >
          저장
        </LAButton>
      </LAGridContainer>

    </LAModal>
  );  
}

export default KeywordRegistModal;
