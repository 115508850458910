import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { getTodayDateText } from 'common/utils';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import { getDuplicateClassSchedules } from 'common/utils/EtcUtils';

import moment from 'moment';

import {
  LAModal,
  LACard,
  LACardTitle,
  LAComboBox,
  LADatePicker,
  LAGridContainer,
  LAGridItem,
  LAButton,
  LAInput,
  LALabel,
  LAProperty,
  useComboBox,
  useDatePicker,
  useInput,
} from 'modules/Widgets';

const hourItems = [
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '05', value: '05분' },
  { key: '10', value: '10분' },
  { key: '15', value: '15분' },
  { key: '20', value: '20분' },
  { key: '25', value: '25분' },
  { key: '30', value: '30분' },
  { key: '35', value: '35분' },
  { key: '40', value: '40분' },
  { key: '45', value: '45분' },
  { key: '50', value: '50분' },
  { key: '55', value: '55분' },
];

function TimetableModifyModal({
  timetables,
  selectedTimetable,
  teacherSchedules,
  className,
  classTime,
  createdDate,
  onChange,
  show,
  handleClose,
}) {
  const { action } = useLoginContext();
  const { queries } = useApiContext();

  const [newTimetable, setNewTimetable] = useState([]);
  const { showAlert, showConfirm } = usePopAlert();

  const modifyReason = useInput('');
  const todayDateText = getTodayDateText('-');
  const newScheduleDate = useDatePicker(todayDateText);
  const newStartHour = useComboBox('08');
  const newStartMin = useComboBox('00');

  const [possibleDate, setPossibleDate] = useState(todayDateText);

  const authMemberType = useMemo(() => {
    return action.getMemberType();
  }, [action]);

  const authAdminAuthType = useMemo(() => {
    return action.getAuthType();
  }, [action]);

  useEffect(() => {
    if (selectedTimetable) {
      let selectedDate = moment(todayDateText + ' 08:00');
      if (
        selectedTimetable &&
        selectedTimetable.schedule_date &&
        selectedTimetable.schedule_time
      ) {
        selectedDate = moment(
          `${selectedTimetable.schedule_date} ${selectedTimetable.schedule_time}`,
        );
      }

      if (selectedDate.isValid()) {
        newScheduleDate.select(selectedDate.format('YYYY-MM-DD'));
        newStartHour.select(selectedDate.format('HH'));
        newStartMin.select(selectedDate.format('mm'));
      }

      let today = moment(new Date());
      let created_dt = moment(new Date());
      if (createdDate) {
        created_dt = moment(createdDate);
      }
      let possible_date = today.format('YYYY-MM-DD');
      if (today.diff(createdDate, 'hours') < 0) {
        possible_date = created_dt.format('YYYY-MM-DD');
      }
      setPossibleDate(possible_date);
    }

    setNewTimetable(selectedTimetable);

    modifyReason.update('');
  }, [selectedTimetable]);

  const { request: modifyTimetable } = useCommonMutation({
    query: queries.Timetable.modifyTimetable,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          onChange();
          handleClose();
        } else {
        }
      },
      onError: (error) => {
      },
    },
  });

  const onTimetableChanged = useCallback(
    (selectedDate) => {
      newScheduleDate.handleChange(selectedDate);
    },
    [newStartHour.state, newStartMin.state],
  );

  const onStartHourChanged = useCallback(
    (event) => {
      newStartHour.handleChange(event);
    },
    [newScheduleDate.state, newStartMin.state],
  );

  const onStartMinChanged = useCallback(
    (event) => {
      newStartMin.handleChange(event);
    },
    [newScheduleDate.state, newStartHour.state],
  );

  const onModifyClick = useCallback(() => {
    if (
      selectedTimetable.schedule_date ==
        newScheduleDate.state.replaceAll('-', '') &&
      `${newStartHour.state}${newStartMin.state}` ==
        selectedTimetable.schedule_time
    ) {
      showAlert('알림', '기존 일정과 동일합니다.');
      return;
    }

    if (modifyReason.state === '') {
      showAlert('알림', '변경사유를 입력하세요.');
      return;
    }

    const newData = {
      dates: [
        {
          timetableSeq: selectedTimetable.timetable_seq,
          date: newScheduleDate.state,
          time: newStartHour.state + ':' + newStartMin.state,
        },
      ],
      // startTime: newStartHour.state + ':' + newStartMin.state,
      className,
      classTime,
    };

    let duplicatedMessage = getDuplicateClassSchedules(
      teacherSchedules,
      newData,
    );
    if (duplicatedMessage) {
      showAlert('알림', `중복되는 일정이 있습니다.\n\n${duplicatedMessage}`);
      return;
    }

    let confirmMessage = '일정을 변경하시겠습니까?';
    if (authMemberType != 1 || authAdminAuthType != 1) {
      confirmMessage = `일정을 변경하시겠습니까?\n\n클래스를 신청한 고객과 사전 협의 후 일정을 변경해주세요.\n무단 변경 시 패널티가 부여될 수 있습니다.`;
    }

    showConfirm('알림', confirmMessage, {
      confirmHandler: () => {
        let changedTimetable = {
          timetable_seq: 0,
          data: {},
        };
        changedTimetable.timetable_seq = newTimetable.timetable_seq;
        changedTimetable.data.schedule_date = newScheduleDate.state.replaceAll(
          '-',
          '',
        );
        changedTimetable.data.schedule_time = `${newStartHour.state}${newStartMin.state}`;
        changedTimetable.data.reason = modifyReason.state;

        // if(moment(`${selectedTimetable.schedule_date} ${selectedTimetable.schedule_time}`).diff(moment(new Date()), 'seconds') < 0) {
        //   changedTimetable.data.supplementary_yn = 1;
        // } else {
        changedTimetable.data.supplementary_yn = 0;
        // }

        modifyTimetable(changedTimetable);
      },
    });
  }, [
    newScheduleDate.state,
    newStartHour.state,
    newStartMin.state,
    modifyReason.state,
  ]);

  return (
    <LAModal title="" width="70%" height="480px" show={show}>
      <LACard>
        <LACardTitle title="클래스 일정 변경" />
        <LAProperty name="선택한 클래스 수업 일시">
          <LALabel>
            {selectedTimetable
              ? moment(
                  `${selectedTimetable.schedule_date} ${selectedTimetable.schedule_time}`,
                ).format('YYYY-MM-DD (dd) A hh:mm')
              : ''}
          </LALabel>
        </LAProperty>
        <LAProperty name="변경할 날짜" required>
          <LAGridContainer>
            <LADatePicker
              wPercent={31}
              value={newScheduleDate.state}
              onChange={onTimetableChanged}
              minDate={possibleDate}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="변경할 수업 시간" required>
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              layouts={{ mr: { sm: 1 } }}
              items={hourItems}
              value={newStartHour.state}
              onChange={onStartHourChanged}
            />
            <LAComboBox
              wPercent={15}
              items={minItems}
              value={newStartMin.state}
              onChange={onStartMinChanged}
            />
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="변경 사유" required>
          <LAInput
            placeholder="일정 변경 사유를 입력하세요."
            value={modifyReason.state}
            onChange={modifyReason.handleChange}
          />
        </LAProperty>
      </LACard>

      <LAGridContainer pt={3}>
        <LAGridItem wPercent={34} />
        <LAButton
          wPercent={15}
          layouts={{ pr: { sm: 0.5 } }}
          onClick={handleClose}
        >
          취소
        </LAButton>
        <LAButton
          wPercent={15}
          layouts={{ pl: { sm: 0.5 } }}
          onClick={onModifyClick}
        >
          변경 완료
        </LAButton>
      </LAGridContainer>
    </LAModal>
  );
}

export default React.memo(TimetableModifyModal);
