import React, { useMemo } from 'react';
import moment from 'moment';

import {
  LASimpleTable,
} from 'modules/Widgets';

function DeliveryTrackingSimpleTable({ data, className }) {
  const tableDefs = useMemo(() => {
    return {
      headers: [
        {
          text: '시간',
        },
        {
          text: '현재 위치',
        },
        {
          text: '배송 상태',
        },
      ],
      wPercentList: [30, 20, 50],
      rows: data,
    };
  }, [data]);

  return (
    <div className={className}>
      <LASimpleTable tableData={tableDefs} />
    </div>
  );
}

export default DeliveryTrackingSimpleTable;
