import React, { useState, useCallback, useEffect, useMemo } from 'react';
import LALoading from 'modules/Widgets/LALoading';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import { getTodayDateText, dateTimeToString } from 'common/utils';
import { useNavigate, useParams } from 'react-router';
import {
  getTextCell,
  getNormalButton,
  getPrimaryButton,
  LAModal,
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LADataTable,
  useRadioGroup,
  LATabSelector,
  LADatePicker,
  useTabSelector,
  useDatePicker,
  useInput,
  useComboBox,
  LADivider,
} from 'modules/Widgets';
import { LAGridItem } from 'modules/Widgets';

function RejectconfirmModal({show, handleClose,handleConfirm}){
  
  const rejectReason = useInput('');


  const onConfirm = useCallback(() =>{
    handleConfirm(rejectReason.state)
  },[handleConfirm,rejectReason])

  return (
    
    <LAModal
      title=""
      width="50%"
      height="25%"
      show={show}
    >
      <LACard layouts={{ pb: { sm: 0.5 } }}>
        <LAProperty name="거절 사유" wNameWidth={16}>
          <LAInput
              wPercent={80}
              layouts={{ pl: 1 }}
              value={rejectReason.state}
              onChange={rejectReason.handleChange}
            />
        </LAProperty>
      </LACard>
      <LAGridContainer layouts={{ mt: { sm: 2 } }}>
          <LAGridItem wPercent={29}></LAGridItem>
          <LAButton
              wPercent={20}
              layouts={{ pr: { sm: 0.5 } }}
              onClick={handleClose}
            >
              취소
            </LAButton>
            <LAButton
              wPercent={20}
              layouts={{ pr: { sm: 0.5 } }}
              onClick={onConfirm}
            >
              확인
            </LAButton>
        </LAGridContainer>

    </LAModal>
    
  )
    
}
export default React.memo(RejectconfirmModal);