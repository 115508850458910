import React, { useCallback, useRef, useState, useEffect } from 'react'
import LAGridItem from './LAGridItem';
import EmptyImage from 'assets/images/empty.png';

function LAImage({
    wPercent = 100,
    height = undefined,
    minHeight = undefined,
    maxHeight = undefined,
    file = EmptyImage,
    alt = "섬네일"
}) {
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef();

  const getMeta = useCallback((url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  }, []);

  useEffect(() => {
    getMeta(file, (err, img) => {
      setImageWidth(img.naturalWidth);
      setImageHeight(img.naturalHeight);
    });
  }, [file])

  const ratio = imageWidth === 0 ? 0 : imageRef.current.offsetWidth / imageWidth;
  const realHeight = height || imageHeight * ratio;

  return (    
    <LAGridItem wPercent={wPercent} layouts={{ height: `${realHeight}px`, minHeight: minHeight ? `${minHeight}px` : "inherit" , maxHeight: maxHeight ? `${maxHeight}px` : "inherit" }}>
      <img ref={imageRef} src={ file ? file : EmptyImage } display="block" height="100%" width="100%" alt={alt} />
    </LAGridItem>
  )
}

export default LAImage