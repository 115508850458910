import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/alliance';

const AllianceApi = (requestFunc) => {
  return {
    Alliance:{
      /**
       *  등록
       */
       regist: {
        key: 'registAlliance',
        queryFn: (data) => requestFunc(METHOD.POST, `${baseURL}`, data),
      },

      /**
       * 전체 목록
       */
      getList: {
        key: 'getAllianceList',
        queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
      },

      /**
       *  상세
       */
      getDetail: {
        key: 'getAllianceDetail',
        queryFn: (alliance_seq) => requestFunc(METHOD.GET, `${baseURL}/${alliance_seq}`),
      },

      /**
       *  수정
       */
      modify: {
        key: 'modifyAlliance',
        queryFn: (data) => requestFunc(METHOD.PATCH, `${baseURL}/${data.alliance_seq}`, data),
      },
      getAllianceCodes : {
        key: 'getAllianceCodes',
        queryFn: () => requestFunc(METHOD.GET, `${baseURL}/codes/1`),
      }

    }
  };
};

export default AllianceApi;
