import React, { useState, useCallback, useMemo, useEffect } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { getTodayDateText } from 'common/utils';
import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LASimpleTable,
  LATabSelector,
  getTextCell,
  getNormalButton,getPrimaryButton,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useDatePicker,
  useTabSelector,
} from 'modules/Widgets';
import MagazineRegistModal from './MagazineRegistModal';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';
import { periodItems } from 'common/constants';

function MagazineSearch() {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [selectedMagazineSeq, setSelectedMagazineSeq] = useState(undefined);

  const [exposuredMagazines, setExposuredMagazines] = useState([]);
  const [magazines, setMagazines] = useState([]);
  
  const todayDateText = getTodayDateText('-');
  const searchDateType = useComboBox(0);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const visibleType = useRadioGroup(-1);
  const searchTargetType = useComboBox(0);
  const searchText = useInput('');
  const magazineRegistModal = useModal(false);

  const { result : resultData, request: requestMagazines } = useCommonQuery({
    query: queries.Magazine.getExposuredMagazines,
    params: {
      exposure_yn: undefined,
      // period_type:searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      query_type: searchTargetType.state,
      query: searchText.state,
      exposure_status: visibleType.state < 0 ? undefined : visibleType.state
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setExposuredMagazines(data.result_data.exposuredMagazines);
          setMagazines(data.result_data.searchMagazines);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '목록 조회에 실패하였습니다.');
      },
    },
    initEnabled: false,
  });

  const { request: requestDelete } = useCommonMutation({
    query: queries.Magazine.deleteMagazine,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '매거진이 삭제되었습니다.');
          requestMagazines();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '매거진 삭제에 실패했습니다.');
      },
    },
  });

  const onMagazineUpdateClick = useCallback((magazineSeq) => {
    setSelectedMagazineSeq(magazineSeq);
    magazineRegistModal.visible(true);
  }, []);

  const onMagazineDeleteClick = useCallback((magazineSeq) => {
    showConfirm("알림", "정말 삭제하시겠습니까?", {
      confirmHandler: () => requestDelete({
        magazine_seq: magazineSeq
      })
    })
  }, []);

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "7%", align: "center" },
      { Header: "제목", accessor: "title", width: "33%", align: "left" },
      { Header: "조회수", accessor: "views", width: "10%", align: "center" },
      { Header: "노출", accessor: "visible", width: "7%", align: "center" },
      { Header: "등록자", accessor: "regist_user", width: "10%", align: "center" },
      { Header: "등록일", accessor: "regist_date", width: "13%", align: "center" },
      { Header: "수정", accessor: "update", width: "10%", align: "center" },
      { Header: "삭제", accessor: "delete", width: "10%", align: "center" },
    ]
  }, []);

  const rows = useMemo(() => {
    return magazines.map((item) => {
      let visibleText = "미노출";
      if (item.exposure_status == 1) {
        visibleText = "노출";
      } else if (item.exposure_status == 2) {
        visibleText = "예약노출";
      } 

      return {        
        no: getTextCell(`${item.magazine_seq}`),
        title: getTextCell(`${item.magazine_title}`),
        views: getTextCell(`${item.view_count}`),
        visible: getTextCell(visibleText),
        regist_user: getTextCell(`${item.constructor_name}`),
        regist_date: getTextCell(item.created_dt ? moment(item.created_dt).format("YYYY-MM-DD") : ""),
        update: getPrimaryButton("수정", `${item.magazine_seq}`, onMagazineUpdateClick),
        delete: getNormalButton("삭제", `${item.magazine_seq}`, onMagazineDeleteClick),
      }
    })
  },[magazines])

  const onChangePage = useCallback((page) => {
  }, []);

  const onInitClick = useCallback(() => {
    searchDateType.reset();
    searchPeriodType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    searchDateType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  const onSearchClick = useCallback(() => {
    requestMagazines();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  }, []);

  const onRegistClick = useCallback(() => {
    magazineRegistModal.visible(true);
  });

  const onRegistered = useCallback(() => {
    magazineRegistModal.handleClose();
    setSelectedMagazineSeq(undefined);
    onSearchClick();
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
    // onSearchClick();
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(()=> {
    if(searchStartDate.state != searchEndDate.state)    
      requestMagazines();
  }, [searchStartDate.state, searchEndDate.state, visibleType.state]);


  const { request: requestUpdate } = useCommonMutation({
    query: queries.Magazine.updateMagazine,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '매거진이 미노출되었습니다.', {
            confirmHandler: () => {
              requestMagazines();
            }
          });
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '매거진 미노출에 실패했습니다.');
      },
    },
  });

  const deleteExposuredMagazine = useCallback((magazineSeq) => {
    showConfirm('확인', `${magazineSeq} 매거진을 미노출하시겠습니까?\n실시간으로 프론트에 반영됩니다.`, {
      confirmHandler: () => {
        const formData = new FormData();
        formData.append('magazine_seq', magazineSeq);
        formData.append('exposure_status', '0');
        const params = {
          magazine_seq: magazineSeq,
          data: formData
        }
  
        requestUpdate(params);        
      }

    });
  }, [exposuredMagazines]);

  const getExposuredRows = useCallback(() => {
    let rows = [];

    console.log(exposuredMagazines);

    if(exposuredMagazines && exposuredMagazines.length > 0) {
      exposuredMagazines.forEach((magazine, index) => {
        rows.push([
          {
            text: magazine.magazine_seq,
          },
          {
            text: magazine.magazine_title,
            align: 'left',
          },
          {
            text: magazine.exposure_dt,
          },
          {
            text: '미노출',
            type: 'button',
            callback: {
              onClick: ()=> deleteExposuredMagazine(magazine.magazine_seq)
            },
          },
          {
            text: '수정',
            type: 'button',
            callback: {
              onClick: ()=> onMagazineUpdateClick(magazine.magazine_seq)
            },
          }
        ]);
      });
    }
    return rows;
  }, [exposuredMagazines]);

  const exposuredData = useMemo(() => {
    return {
      headers: [
        {
          text: 'No',
        },
        {
          text: '제목',
        },
        {
          text: '게시 일시',
        },
        {
          text: '',
        },
        {
          text: '',
        },
      ],
      wPercentList: [10, 50, 20, 10, 10],
      rows: getExposuredRows(),
    };
  }, [exposuredMagazines]);

  return (
    <>
      <DashboardNavbar title="매거진 관리" />

      <LACard>
        <LACardTitle title="노출 매거진 관리" />
        <LAGridContainer layouts={{ pt: 2 }}>
          <LASimpleTable tableData={exposuredData} />
        </LAGridContainer>
        <LAWarningLabel>게시일시 역순으로 프론트에 표시됩니다.</LAWarningLabel>
      </LACard>

      {/* Start Search Area */}
      <LACard next>
        <LACardTitle title="매거진 검색" />
        <LAProperty name="노출 여부">
          <LAGridContainer>
            <LARadioGroup
              props={[
                {
                  text: '전체',
                  value: -1,
                },
                {
                  text: '미노출',
                  value: 0,
                },
                {
                  text: '노출',
                  value: 1,
                },
                // {
                //   text: '예약 노출',
                //   value: 2,
                // },
              ]}
              value={visibleType.state}
              onChange={visibleType.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            {/* <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '매거진 시작일' },
                { key: 1, value: '매거진 종료일' },
              ]}
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
            /> */}
            <LATabSelector
              wPercent={45}
              items={periodItems}
              // layouts={{ pl: 2 }}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2, mr: 2 }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />{' '}
            ~{' '}
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2 }}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '통합검색' },
                // { key: 1, value: '제목' },
                // { key: 2, value: '등록자 명' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput wPercent={80} layouts={{ pl: { sm: 1 } }} 
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={28}></LALabel>
          <LAButton wPercent={15} layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}>
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LAButton
            wPercent={15}
            onClick={onRegistClick}
          >
            등록
          </LAButton>
          <LALabel wPercent={27}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable columns={columns} rows={rows} defaultPage={0} onChangePage={onChangePage}/>
        </LAGridContainer>
      </LACard>

      {magazineRegistModal.state && 
        <MagazineRegistModal show={magazineRegistModal.state} handleClose={onRegistered} magazineSeq={selectedMagazineSeq} />
      }
    </>
  );
}

export default MagazineSearch;