import React, { useState, useCallback, useEffect, useContext } from 'react';

import { UNSAFE_NavigationContext } from "react-router-dom";

import { LAModal, LACard, LACardTitle } from 'modules/Widgets';

import ClassScheduleDetailContents from './ClassScheduleDetailContents';

function ClassScheduleDetailModal({scheduleSeq, show, handleClose }) {
    const navigation = useContext(UNSAFE_NavigationContext).navigator;

    useEffect(() => {
            window.history.pushState(null, null, window.location.pathname);
            if (navigation) {
            navigation.listen((locationListener) => {
                if(locationListener.action == 'POP') {
                    handleClose();
                }
            });
        }
    }, []);

    return (
    <LAModal
      title="클래스 일정 상세"
      width="90%"
      height="95%"
      show={show}
      onClose={handleClose}
    >
        <ClassScheduleDetailContents scheduleSeq={scheduleSeq} onClickList={handleClose}/>
    </LAModal>
  );
}

export default React.memo(ClassScheduleDetailModal);
