import React, { useCallback, useEffect, useState, useMemo } from 'react';
// import moment from 'moment';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';

// import LALoading from 'modules/Widgets/LALoading';
// import ClassScheduleFilter from '../components/ClassScheduleFilter';
// import { useApiContext } from 'common/context/ApiContext';
// import useCommonQuery from 'common/hooks/useCommonQuery';
import ClassScheduleList from '../components/ClassScheduleList';

import { LAAltRadioGroup, useModal } from "modules/Widgets";
import ClassScheduleDetailModal from '../components/ClassScheduleDetailModal';

import { useApiContext } from 'common/context/ApiContext';
import { useNavigate } from 'react-router-dom';
import useCommonQuery from 'common/hooks/useCommonQuery';
// import useCommonMutation from 'common/hooks/useCommonMutation';
import { getTodayDateText, dateTimeToString } from 'common/utils';
import CategoryComboView from '../components/CategoryComboView';

//로그인 컨텍스트
import { useLoginContext } from 'common/context/MemberContext';

import moment from 'moment';
import { periodItems } from 'common/constants';
import {
  LACard,
  LAGridContainer,
  LAProperty,
  LALabel,
  LARadioGroup,
  LACodeRadioGroup,
  LAButton,
  LACodeComboBox,
  LAComboBox,
  LAInput,
  LADatePicker,
  LATabSelector,
  LADivider,
  useRadioGroup,
  useComboBox,
  useInput,
  useDatePicker,
  useTabSelector,
  LAMultiCheckbox,
  useMultiCheckbox,
  LACodeMultiCheckbox,
} from 'modules/Widgets';
import ClassDetailModal from '../components/ClassDetailModal';

const targetAgeItems = getAgeItems();

function getAgeItems() {
  const result = [];
  for (let i = 0; i < 20; i++) {
    result.push({ key: i + 1, value: `${i + 1}세` });
  }
  return result;
}

function minusMonth(input) {
  return moment(input).add(-1, 'M').format('YYYY-MM-DD');
}

const receptionTypeItems = [
  {
    text: '전체',
    value: -1,
  },
  {
    text: '대기',
    value: 0,
  },
  {
    text: '접수중',
    value: 1,
  },
  {
    text: '접수마감',
    value: 2,
  },
];

export default function ClassSchedule() {
  const [selectedClassSeq, setSelectedClassSeq] = useState(0);
  const classDetailModal = useModal(false);

  const [selectedScheduleSeq, setSelectedScheduleSeq] = useState(0);
  // const [schedules, setSchedules] = useState([]);

  const scheduleDetailModal = useModal(false);

  //로그인 컨텍스트
  const { isLogin, isTeacher, action } = useLoginContext();

  const todayDateText = getTodayDateText('-');
  const [category, setCategory] = useState(0);


  const classType = useRadioGroup(-1);
  const ageType = useRadioGroup(1);
  const minTargetAge = useComboBox(1);
  const maxTargetAge = useComboBox(1);
  const worksheetType = useRadioGroup(-1);
  const statusType = useRadioGroup(0);
  const receptionType = useMultiCheckbox(
    receptionTypeItems.map((item) => item.value),
    receptionTypeItems,
  );
  const activateType = useRadioGroup(-1);

  const searchDateType = useComboBox(1);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(minusMonth(todayDateText));
  const searchEndDate = useDatePicker(todayDateText);
  const searchTargetType = useComboBox(1);
  const searchText = useInput('');
  const testModeType = useRadioGroup('')
  const operateType = useRadioGroup('')

  const [rows, setRows] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [page, setPage] = useState(0); // 마지막 페이지네이션
  // const [lastVisitTablePage, setLastVisitTablePage] = useState(0);

  const [ searchParams, setSearchParams ] = useState({});

  const onSearchClick = useCallback(() => {
    let searchParams = {
      category_seq: category ? category.key : undefined,
      class_type: classType.state < 0 ? undefined : classType.state,
      test_mode_yn: testModeType.state < 0 ? undefined : testModeType.state,
      operate_type: operateType.state < 0 ? undefined : operateType.state,
      min_target_age: ageType.state === "0" ? minTargetAge.state : undefined,
      max_target_age: ageType.state === "0" ? maxTargetAge.state : undefined,
        // worksheet_yn: worksheetType.state < 0 ? undefined : worksheetType.state,
      // worksheet_yn: worksheetType.state == 0 ? undefined : worksheetType.state == 1,
      confirmation_yn: statusType.state == 0 || statusType.state == 3 ? undefined : statusType.state - 1,
      close_yn: statusType.state != 3 ? undefined : 1,
      reception_type:
          receptionType.state.findIndex((item) => item === -1) >= 0
              ? undefined
              : receptionType.state,
      activate_yn:
          activateType.state < 0 ? undefined : activateType.state == 1 ? 1 : 0,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      confirm_yn: undefined,
      query_type: searchTargetType.state,
      query: searchText.state,
    }
    setSearchParams(searchParams);
  },[
    category,
    classType.state,
    testModeType.state,
    operateType.state,
    ageType.state,
    statusType.state,
    receptionType.state,
    activateType.state,
    searchDateType.state,
    searchStartDate.state,
    searchEndDate.state,
    searchTargetType.state,
    searchText.state,
  ]);

  /*const { status, request: requestSchedules } = useCommonQuery({
    query: queries.Schedule.getList,
    params: {
      category_seq: category ? category.key : undefined,
      class_type: classType.state < 0 ? undefined : classType.state,
      test_mode_yn: testModeType.state < 0 ? undefined : testModeType.state,
      operate_type: operateType.state < 0 ? undefined : operateType.state,
      min_target_age: ageType.state == 2 ? minTargetAge.state : undefined,
      max_target_age: ageType.state == 2 ? maxTargetAge.state : undefined,
      // worksheet_yn: worksheetType.state == 0 ? undefined : worksheetType.state == 1,
      confirmation_yn:
        statusType.state == 0 || statusType.state == 3
          ? undefined
          : statusType.state - 1,
      close_yn: statusType.state != 3 ? undefined : 1,
      reception_type:
        receptionType.state.findIndex((item) => item === -1) >= 0
          ? undefined
          : receptionType.state,
      activate_yn:
        activateType.state < 0 ? undefined : activateType.state == 1 ? 1 : 0,
      period_type: searchDateType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      confirm_yn: undefined,
      query_type: searchTargetType.state,
      query: searchText.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          onSearch(data.result_data);
        } else {
        }
      },
      onError: (error) => {
      },
    },
    initEnabled: false,
  });*/

  const onCategoryChanged = useCallback((item) => {
    setCategory(item);
  }, []);

  useEffect(() => {
    // searchStartDate.selectByPeriod(searchPeriodType.state);
    // searchEndDate.reset();
    // searchStartDate.reset();

    searchEndDate.selectByPeriodAfter(searchPeriodType.state);
    searchStartDate.reset(searchPeriodType.state);
  }, [searchPeriodType.state, searchStartDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) onSearchClick();
  }, [searchStartDate.state, searchEndDate.state]);

  useEffect(() => {
    if (page != 0) {
      setTablePage(page);
    }
  }, [rows]);

  const onChangeTablePage = useCallback((page) => {
    setPage(page);
  });

/*  const onSearchClick = useCallback(() => {
    setLastVisitTablePage(0);
    requestSchedules();
  });*/

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  });

  const onInitClick = useCallback(() => {
    // setCategory(0);
    statusType.reset();
    receptionType.reset();
    searchDateType.reset();
    searchPeriodType.reset();
    searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
    testModeType.reset();
    operateType.reset();
  }, [
    statusType.reset,
    receptionType.reset,
    searchDateType.reset,
    searchPeriodType.reset,
    searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
    testModeType.reset,
    operateType.reset,
  ]);

  const onExcelDownloadClick = useCallback(() => {}, []);

  /*const onSearch = useCallback(async (schedules) => {
    setSchedules(schedules);
  }, []);*/

  const onClickDetail = useCallback((scheduleSeq) => {
    setSelectedScheduleSeq(scheduleSeq);
    scheduleDetailModal.visible(true);
  });

  const onCloseDetail = useCallback(() => {
    onSearchClick();
    scheduleDetailModal.handleClose();
  });

  const onClassDetail = useCallback((classSeq) => {
    setSelectedClassSeq(classSeq);
    classDetailModal.visible(true);
  });

  const onClassClose = useCallback(() => {
    onSearchClick();
    classDetailModal.handleClose();
  });

  return (
    <>
      <DashboardNavbar title="클래스 일정 및 판매 변경" />
      <LACard>
        <LAProperty name="카테고리">
          <LAGridContainer>
            <CategoryComboView value={category} onChange={onCategoryChanged} />
          </LAGridContainer>
        </LAProperty>
        {!isTeacher ? (
          <LAProperty name="클래스 유형">
            <LACodeRadioGroup
              codeType="CLASS_TYPE"
              showAll={true}
              value={classType.state}
              onChange={classType.handleChange}
            />
          </LAProperty>
        ) : (
          ''
        )}

        <LAProperty name="클래스 유형">
          <LAAltRadioGroup
            showAll={true}
            positiveLabel={'시크릿 포함'}
            positiveValue={1}
            negativeLabel={'시크릿 제외'}
            negativeValue={0}
            value={testModeType.state}
            onChange={testModeType.handleChange}
          />
        </LAProperty>

        <LAProperty name="운영 방식">
          <LAAltRadioGroup
            showAll={true}
            positiveLabel={'자사'}
            positiveValue={1}
            negativeLabel={'제휴'}
            negativeValue={2}
            value={operateType.state}
            onChange={operateType.handleChange}
          />
        </LAProperty>

        {/* <LAProperty name="대상 연령">
          <LAGridContainer>
            <LACodeRadioGroup
              codeType="TARGET_AGE_QUERY_TYPE"
              showAll={false}
              value={ageType.state}
              onChange={ageType.handleChange}
            />
            {ageType.state === "0" && (
              <LAGridContainer>
                <LAComboBox
                  wPercent={20}
                  // value={!!minTargetAge && minTargetAge.state}
                  items={targetAgeItems}
                  layouts={{ pr: { sm: 1 } }}
                  value={minTargetAge.state}
                  onChange={minTargetAge.handleChange}
                  // onChange={onMinAgeChanged}
                />
                <LALabel wPercent={5} layouts={{ pr: { sm: 1 } }}>
                  ~
                </LALabel>
                <LAComboBox
                  wPercent={20}
                  items={targetAgeItems}
                  value={maxTargetAge.state}
                  onChange={maxTargetAge.handleChange}
                  // value={!!maxTargetAge && maxTargetAge.state}
                  // onChange={onMaxAgeChanged}
                />
              </LAGridContainer>
            )}
          </LAGridContainer>
        </LAProperty> */}

        {/* <LAProperty name="교재 여부">
          <LACodeRadioGroup
            codeType="WORKSHEET_TYPE"
            showAll={true}
            value={worksheetType.state}
            onChange={worksheetType.handleChange}
            // onChange={onWorkSheetChanged}
          />
        </LAProperty> */}

        <LAProperty name="클래스 상태">
          <LARadioGroup
            props={[
              {
                text: '전체',
                value: 0,
              },
              {
                text: '확정',
                value: 2,
              },
              {
                text: '미확정',
                value: 1,
              },
              {
                text: '폐강',
                value: 3,
              },
            ]}
            value={statusType.state}
            onChange={statusType.handleChange}
            // onChange={onExposureChanged}
          />
        </LAProperty>

        <LAProperty name="접수 상태">
          {/* <LACodeMultiCheckbox
            codeType="RECEPTION_STATE"
            showAll={true}
            isSelected={receptionType.isSelected}
            onChange={receptionType.handleChange}
          /> */}
          <LAMultiCheckbox
            items={receptionTypeItems}
            isSelected={receptionType.isSelected}
            onChange={receptionType.handleChange}
          />
        </LAProperty>

        <LAProperty name="노출 여부">
          <LACodeRadioGroup
            codeType="EXPOSURE_TYPE"
            showAll={true}
            value={activateType.state}
            onChange={activateType.handleChange}
            // onChange={onExposureChanged}
          />
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LACodeComboBox
              wPercent={12}
              codeType="SCHEDULE_QUERY_PERIOD_TYPE"
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
              // code={queryPeriodType}
              // onChange={onQueryPeriodTypeChanged}
            />
            <LATabSelector
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              items={periodItems}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={17}
              layouts={{ ml: { sm: 1 } }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />
            <LALabel wPercent={1}>~</LALabel>
            <LADatePicker
              wPercent={17}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LACodeComboBox
              wPercent={12}
              codeType="SCHEDULE_QUERY_TYPE"
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              placeholder="검색어 입력"
              wPercent={46}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer pt={2}>
          <LALabel wPercent="32" />
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onInitClick}
            variant={'outlined'}
          >
            초기화
          </LAButton>

          {/* <LAButton
            wPercent={20}
            onClick={onExcelDownloadClick}
            variant={'outlined'}
          >
            엑셀 다운로드
          </LAButton> */}
        </LAGridContainer>
      </LACard>

      {/* <ClassScheduleFilter onSearch={onSearch} /> */}
      <MDBox>
        {/* {response.result_data && <ClassList list={response.result_data} />} */}
          <ClassScheduleList
            // list={schedules}
            onDetail={onClickDetail}
            onClassDetail={onClassDetail}
            defaultPage={tablePage}
            onChangePage={onChangeTablePage}
            searchParams={searchParams}
          />
      </MDBox>

      <ClassScheduleDetailModal
        scheduleSeq={selectedScheduleSeq}
        show={scheduleDetailModal.state}
        handleClose={onCloseDetail}
        handleSelect={scheduleDetailModal}
      />

      <ClassDetailModal
        classSeq={selectedClassSeq}
        show={classDetailModal.state}
        handleClose={onClassClose}
        handleSelect={classDetailModal}
      />
    </>
  );
}
