import React, { useState, useEffect, useRef } from "react";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  // LAGridItem,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LAModal,
  LASimpleTable,
  useInput,
  useModal,
} from "modules/Widgets";
import { LATextEditor } from "modules/Widgets";
import { useApiContext } from "common/context/ApiContext";
import useCommonMutation from "common/hooks/useCommonMutation";
import { useLoginContext } from "common/context/MemberContext";
import { API_BASE_URL } from "api/AppConfig";
import { Modal } from "@mui/material";
import { ReactComponent as LoadingSvg } from "./loading.svg";

const baseURL = "admin";
const couponUrl = "admin/coupon-policy";
const saleUrl = "admin/settlement";

function DownloadReasonModal({
  show,
  handleClose,
  downKey,
  yyyymm,
  urlQueries,
  couponSeq,
}) {
  const { isLogin, isTeacher, loginState, action } = useLoginContext();
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();

  //쿼리는 어디로 들어가야하지..
  const requestDownload = async (params) => {
    setLoading(true);
    const token = localStorage.getItem("accessToken");

    if (token && loginState.auth && loginState.auth.member_type === 1) {
      const headers = { Authorization: `Bearer ${token}` };
      const url =
        downKey === "couponUsedList"
          ? `${API_BASE_URL}/${getUrl(downKey, "", couponSeq)}`
          : `${API_BASE_URL}/${getUrl(downKey, yyyymm, couponSeq)}`;

      try {
        const isFee =
          downKey === "teacherDetailFee" || downKey === "classDetailFee"
            ? { tp: "comm" }
            : {};

        const response = await fetch(url, {
          method: "POST",
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...urlQueries,
            ...params,
            ...isFee,
          }),
        });

        const blob = await response.blob();
        const downUrl = await window.URL.createObjectURL(
          new Blob([blob], { type: "text/csv;charset=utf-8" })
        );
        const link = document.createElement("a");
        link.href = downUrl;
        link.setAttribute("download", `${getFileName(downKey)} 다운로드.csv`);
        document.body.appendChild(link);
        link.click();

        setLoading(false);
        handleClose();
      } catch (e) {
        console.error("Error while downloading CSV file:", e);
      }
    }
  };

  const handleDownload = () => {
    const reason = inputRef.current?.value;

    if (reason.length > 4) requestDownload({ reason: reason });
    else showAlert("알림", "최소 5글자 이상 작성해주세요.");
  };

  console.log(downKey);

  //나중에 string 전부 상수로 빼기
  const getUrl = (downKey, yyyymm, couponSeq) => {
    if (downKey === "memberList") return `${baseURL}/members/csv`;
    else if (downKey === "couponList") return `${couponUrl}/download/csv`;
    else if (downKey === "allMoneyList")
      return `${baseURL}/points/history-download`;
    else if (downKey === "classDetail")
      return `${saleUrl}/csv/classdetail/${yyyymm}/${loginState.member_seq}`;
    else if (downKey === "teacherDetail")
      return `${saleUrl}/csv/teacherdetail/${yyyymm}/${loginState.member_seq}`;
    else if (downKey === "classDetailFee")
      return `${saleUrl}/csv/classdetail/${yyyymm}/${loginState.member_seq}`;
    else if (downKey === "classDetailPayout")
      return `${saleUrl}/csv/classdetail/${yyyymm}/${loginState.member_seq}`;
    else if (downKey === "teacherDetailFee")
      return `${saleUrl}/csv/teacherdetail/${yyyymm}/${loginState.member_seq}`;
    else if (downKey === "teacherDetailPayout")
      return `${saleUrl}/csv/teacherdetail/${yyyymm}/${loginState.member_seq}`;
    else if (downKey === "togetherPayment")
      return `${saleUrl}/together/csv/settlement/${yyyymm}`;
    else if (downKey === "togetherSales")
      return `${saleUrl}/together/csv/sales/${yyyymm}`;
    else if (downKey === "sales") return `${saleUrl}/csv/sales/${yyyymm}`;
    else if (downKey === "teacher")
      return `${saleUrl}/csv/settlement/${yyyymm}`;
    else if (downKey === "randomCouponList")
      return `${couponUrl}/${couponSeq}/random-numbers/download/csv`;
    else if (downKey === "couponUsedList")
      return `${couponUrl}/${couponSeq}/used-coupons/download/csv`;
  };

  const getFileName = (downKey) => {
    if (downKey === "memberList") return `회원 내역`;
    else if (downKey === "eventList") return "이벤트 참여 내역";
    else if (downKey === "couponList") return "쿠폰 내역";
    else if (downKey === "classDetail") return "클래스별 수수료 내역";
    else if (downKey === "teacherDetail")
      return urlQueries ? "선생님/업체별 수수료 내역" : "선생님 수수료 내역";
    else if (downKey === "classDetailFee") return "클래스별 수수료 내역";
    else if (downKey === "classDetailPayout") return "클래스별 수수료 내역";
    else if (downKey === "teacherDetailFee") return "선생님/업체별 수수료 내역";
    else if (downKey === "teacherDetailPayout")
      return "선생님/업체별 수수료 내역";
    else if (downKey === "togetherPayment") return "투게더 결제 내역";
    else if (downKey === "togetherSales") return "투게더 매출 내역";
    else if (downKey === "allMoneyList") return "올머니 내역";
    else if (downKey === "sales") return "매출 내역";
    else if (downKey === "teacher") return "결제 내역";
    else if (downKey === "randomCouponList") return "난수 쿠폰";
    else if (downKey === "couponUsedList") return "쿠폰 사용 내역";
  };

  useEffect(() => {
    let timeout;
    if (loading) {
      timeout = setTimeout(() => {
        setLoading(false);
      }, 300000); //최대 5분
    }
    return () => clearTimeout(timeout);
  }, [loading]);

  return loading ? (
    <Modal open={show}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSvg />
      </div>
    </Modal>
  ) : (
    <LAModal
      title="사유 입력"
      width="30%"
      height="30%"
      show={show}
      onClose={handleClose}
    >
      <textarea
        style={{ width: "100%", height: "80%" }}
        placeholder={"다운로드 사유를 입력해주세요.(최소 5자 이상)"}
        ref={inputRef}
      />

      <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <LAButton
          wPercent={20}
          layouts={{ pl: { sm: 0.5 } }}
          onClick={handleClose}
        >
          취소
        </LAButton>

        <LAButton
          wPercent={20}
          layouts={{ pl: { sm: 0.5 } }}
          onClick={handleDownload}
        >
          확인
        </LAButton>
      </div>
    </LAModal>
  );
}
export default React.memo(DownloadReasonModal);
