import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/events';

const EventApi = (requestFunc) => {
    return {
        Event: {
            getExposuredEvents: {
                key: 'getExposuredEvents',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/exposured`, params),
            },
            getEvents: {
                key: 'getEvents',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
            },
            registEvent: {
                key: 'registEvent',
                queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
            },
            getEventInfo: {
                key: 'getEventInfo',
                queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.event_seq}`),
            },
            updateEvent: {
                key: 'updateEvent',
                queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.event_seq}`, params.data),
            },
            deleteEvent: {
                key: 'deleteEvent',
                queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.event_seq}`),
            },
        },
    };
};

export default EventApi;
