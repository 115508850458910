import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import RejectConfirmModal from './RejectConfirmModal';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  useModal,
  LAInput,
  LATextEditor,
  LARadioGroup,
} from 'modules/Widgets';

function TeacherSupportDetail({ teacherSeq, onClickList }) {
  const { isLogin } = useLoginContext();
  // const navigate = useNavigate();

  const { queries } = useApiContext();
  // const { teacherSeq } = useParams();
  const [data, setData] = useState(null);
  const { showAlert, showConfirm } = usePopAlert();

  const rejectConfirmModal = useModal(false);

  const { result: resultData, request: requestDetail } = useCommonQuery({
    query: queries.Teachers.getSupportDetail,
    params: teacherSeq,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setData(data.result_data);
        } else {
          showAlert('알림', data.result_message);
          onClickList();
        }
      },
      onError: (err) => {
        showAlert('알림', '조회에 실패하였습니다.');
        onClickList();
      },
    },
  });

  const { requestAsync: approveAsync } = useCommonMutation(
    {
      query: queries.Teachers.approve,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            showAlert('알림', '승인되었습니다.');
            requestDetail();
          } else {
            showAlert('알림', data.result_message);
          }
        },
        onError: (error) => {
          showAlert('알림', '승인 처리에 실패하였습니다.');
        },
      },
    },
    [requestDetail, teacherSeq],
  );

  const { requestAsync: rejectAsync } = useCommonMutation(
    {
      query: queries.Teachers.reject,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            showAlert('알림', '거절 처리 되었습니다.');
            rejectConfirmModal.visible(false);
            requestDetail();
          } else {
            showAlert('알림', data.result_message);
          }
        },
        onError: (error) => {
          showAlert('알림', '거절 처리에 실패하였습니다.');
        },
      },
    },
    [requestDetail, teacherSeq],
  );

  const onApprove = useCallback(async () => {
    showConfirm('확인', '승인하시겠습니까?', {
      confirmHandler: () => {
        approveAsync(teacherSeq);
      },
    });
  }, [teacherSeq]);

  const onReject = useCallback(async () => {
    rejectConfirmModal.visible(true);

    // showConfirm(
    //   '확인',
    //   '거절 하시겠습니까?',
    //   {
    //     confirmHandler: () => {
    //       rejectAsync(teacherSeq)
    //     }
    //   })
  }, [teacherSeq]);

  const getDate = (date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD (dd)');
    }
    return '0000-00-00';
  };

  const getDateTime = (date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD (dd) A hh:mm:ss');
    }
    return '0000-00-00 : 00:00:00';
  };

  const onConfirmReject = (e) => {
    if (e) {
      rejectAsync({ applicationSeq: teacherSeq, reject_reason: e });
    }
  };

  useEffect(() => {
    requestDetail();
  }, []);

  return (
    <>
      {data ? (
        <LACard>
          <LACardTitle title="선생님 지원 정보" />
          <LAProperty name="이름">
            <LALabel wPercent={80}>{data.teacher_name}</LALabel>
          </LAProperty>
          <LAProperty name="닉네임">
            <LALabel wPercent={80}>{data.teacher_nickname}</LALabel>
          </LAProperty>
          <LAProperty name="연락처">
            <LALabel wPercent={80} clickable="true">
              {data.mobile_no}
            </LALabel>
          </LAProperty>
          <LAProperty name="이메일">
            <LALabel wPercent={80} clickable="true">
              {data.email}
            </LALabel>
          </LAProperty>
          <LAProperty name="수업 경력">
            {!!data && data.class_experience && data.class_experience.length > 0
              ? data.class_experience.map((ce) => (
                  <LALabel wPercent={80}> # {ce}</LALabel>
                ))
              : ''}
          </LAProperty>
          <LAProperty name="지원 경로">
            <LALabel wPercent={80}>{data.support_path}</LALabel>
          </LAProperty>
          <LAProperty name="소속 코드">
            <LALabel wPercent={80}>{data.affiliation_code}</LALabel>
          </LAProperty>
          <LAProperty name="개설 분야">
            <LALabel wPercent={80}>{data.class_topic}</LALabel>
          </LAProperty>
          <LAProperty name="수강 대상">
            {/* <LALabel wPercent={40}>{data.target_ages_value}</LALabel> */}
            {!!data &&
            data.target_ages_value &&
            data.target_ages_value.length > 0
              ? data.target_ages_value.map((ce) => (
                  <LALabel wPercent={80}>
                    {' '}
                    # {ce}{' '}
                    {ce == '기타' && data.etc_ages ? ` : ${data.etc_ages}` : ''}
                  </LALabel>
                ))
              : ''}
            {/* {
            !!data && data.etc_ages ? <LALabel wPercent={80}> -> {data.etc_ages}</LALabel>:''
          } */}
          </LAProperty>
          <LAProperty name="클래스 특징">
            <LALabel wPercent={80}>{data.class_features}</LALabel>
          </LAProperty>
          <LAProperty name="궁금한 점">
            <LALabel wPercent={80}>{data.questions}</LALabel>
          </LAProperty>
          <LAProperty name="연락이 어려운 일자">
            <LALabel wPercent={80}>{data.other_schedule}</LALabel>
          </LAProperty>
          <LAProperty name="작성일">
            <LALabel wPercent={80}>{getDateTime(data.created_dt)}</LALabel>
          </LAProperty>
        </LACard>
      ) : (
        ''
      )}

      {!!data && data.approved_yn == 0 && data.rejected_yn == 0 ? (
        <LACard layouts={{ pt: 1 }}>
          <LACardTitle title="승인/거절 처리" />
          <LAGridContainer layouts={{ pt: { sm: 1 } }}>
            <LAButton
              wPercent={10}
              layouts={{ mr: { sm: 0.5 } }}
              onClick={onApprove}
            >
              승인 처리
            </LAButton>
            <LAButton
              wPercent={10}
              layouts={{ ml: { sm: 0.5 } }}
              onClick={onReject}
            >
              거절 처리
            </LAButton>
          </LAGridContainer>
        </LACard>
      ) : (
        <LACard layouts={{ pt: 1 }}>
          <LACardTitle title="승인/거절 상태" />

          {!!data && data.approved_yn == 1 ? (
            <LACard>
              <LAProperty name="승인 완료">
                <LAGridContainer vAlign="center">
                  <LALabel wPercent={25}>
                    {getDateTime(data.approved_dt)}
                  </LALabel>
                  <LALabel wPercent={15}>{data.approver_name}</LALabel>
                </LAGridContainer>
              </LAProperty>
            </LACard>
          ) : (
            <>
              {!!data && data.rejected_yn == 0 ? (
                <LAButton wPercent={10} onClick={onApprove}>
                  승인 처리
                </LAButton>
              ) : (
                ''
              )}
            </>
          )}

          {!!data && data.rejected_yn == 1 ? (
            <LACard>
              <LAProperty name="거절 완료">
                <LAGridContainer vAlign="center">
                  <LALabel wPercent={25}>
                    {getDateTime(data.rejected_dt)}
                  </LALabel>
                  <LALabel wPercent={15}>{data.rejector_name}</LALabel>
                  <LALabel wPercent={60}>
                    <b>거절 사유 : </b>
                    {data.rejecte_reason}
                  </LALabel>
                </LAGridContainer>
              </LAProperty>
            </LACard>
          ) : (
            <>
              {!!data && data.approved_yn == 0 ? (
                <LAButton wPercent={10} onClick={onReject}>
                  거절 처리
                </LAButton>
              ) : (
                ''
              )}
            </>
          )}
        </LACard>
      )}

      <LAGridContainer layouts={{ pt: { sm: 1 } }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ pr: { sm: 1 } }}
          variant={'outlined'}
          onClick={onClickList}
        >
          목록
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>

      <RejectConfirmModal
        show={rejectConfirmModal.state}
        handleClose={rejectConfirmModal.handleClose}
        handleConfirm={onConfirmReject}
      />
    </>
  );
}

export default TeacherSupportDetail;
