import React, { useState, useCallback ,useEffect,useMemo} from 'react';
import { useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import TeacherSearchModal from '../components/TeacherSearchModal';
import ClassScheduleModal from '../components/ClassScheduleModal';
import TextbookSearchModal from '../components/TextbookSearchModal';
import ArrowForward from '@mui/icons-material/ArrowForward';

import { useLoginContext } from 'common/context/MemberContext';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";

import CategoryComboView from "../components/CategoryComboView"
import OneDayScheduleView from '../components/OneDayScheduleView';
import PackageScheduleView from '../components/PackageScheduleView';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LACodeRadioGroup,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LAListView,
  useRadioGroup,
  useComboBox,
  useInput,
  useTabSelector,
} from 'modules/Widgets';
import { Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch } from '@mui/material';

const keywords = [
  '취향독서',
  '과학실험',
  '북미1:1',
  '신체',
  '바다동물',
  '여름방학',
  '반려동물',
  '바이러스',
  '게임',
  '습관',
  '일기쓰기',
  '한글',
  '코딩',
  '한국사',
  '여행',
  '음악',
  '미술',
  '운동',
  '직업',
];

const imageUrl =
  'https://images.unsplash.com/photo-1481277542470-605612bd2d61?w=248&fit=crop&auto=format&dpr=2';

const images = [
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
  { imageUrl: imageUrl },
];

const comboItems = [
  { key: 0, value: '값1' },
  { key: 1, value: '값2' },
  { key: 2, value: '값3' },
  { key: 3, value: '값4' },
  { key: 4, value: '값5' },
  { key: 5, value: '값6' },
  { key: 6, value: '값7' },
  { key: 7, value: '값8' },
  { key: 8, value: '값9' },
];

function ClassApplicationDetail() {
  const {isLogin} = useLoginContext()
  const navigate = useNavigate();

  const { queries } = useApiContext();  
  const { applicationSeq } = useParams();
  const [ data, setData] = useState(null);    

  const [teacherSearchShow, setTeacherSearchShow] = useState(false);
  const [classScheduleShow, setClassScheduleShow] = useState(false);
  const [textbookSearchShow, setTextbookSearchShow] = useState(false);

  // const [approveDialogShow , setApproveDialogShow] = useState=(false);
  // const [approveWithExposure, setApproveWithExposure] = useState=(false);

  const category = useComboBox(0)

  const className = useInput('')
  const classDescription = useInput('')
  const classType = useRadioGroup(0)
  const ageType = useRadioGroup(false)
  const minTargetAge = useComboBox(0)
  const maxTargetAge = useComboBox(0)
  const minUserCount = useComboBox(0)
  const maxUserCount = useComboBox(0)


  const [schedules ,setSchedules] = useState([])
  const classPrice = useInput(0)
  const discountPrice = useInput(0)  
  const [thumbnailImage,setThumbnailImage] = useState()
  const classSummary = useInput('')
  const [informHtml,setInformHtml] = useState('')
  const [curriculumHtml,setCurriculumHtml] = useState('')
  const [noticeHtml,setNoticeHtml] = useState('')  
  const textbooks = useState([])       //교재(최대3개)  
  const [attachment,setAttachment] = useState()//첨부파일(수업자료) 10MB 
  const materials = useInput('')         //준비물


  const [selectedSchedule,setSelectedSchedule] = useState()       //교재(최대3개)  


  const userCounts = useMemo(() => {
    var list = []
    for(let i = 1;i<31;i++){
      list.push({ key: i, value: `${i}명` })
    }
    return list
  })
  
  const targetAgeItems = useMemo(() => {
    var list = []
    for(let i = 1;i<20;i++){
      list.push({ key: i, value: `${i}세` })
    }
    return list
  })

  const totalPrice = useMemo(() =>{    
    if(schedules && schedules.length > 0)
      // return schedules.reduce((s, o) => s.subject_price + o.subject_price)
      return schedules.reduce((acc,item) => acc + item.subject_price,0)
    else return 0
  },[schedules])
  
  
  const discountRate = useMemo(() =>{

    if(classPrice.state > 0 && discountPrice.state > 0){
      let diffPrice = classPrice.state - discountPrice.state
      if(diffPrice > 0)
        return ((diffPrice / classPrice.state) * 100).toFixed(0);        
      else return 0
    }else{
      return 0
    }
  },[classPrice.state,discountPrice.state])
  
  const onChangeDiscountPrice = useCallback(
    (e) => {

      // if(parseInt(classPrice.state) < parseInt(e.target.value)){
      //   alert('정상가보다 할인가가 클수 없습니다.')
      //   return
      // }
      discountPrice.handleChange(e)
    },
    [classPrice.state, discountPrice],
  );

  const onChangeThumbnailImage = useCallback(
    (e) => {
      setThumbnailImage(e.target.files[0])
    },
    [],
  );
  const onChangeAttachment = useCallback(
    (e) => {
      setAttachment(e.target.files[0])
    },
    [],
  );

  useEffect(() =>{
    if(!isLogin){
      alert('로그인 후 이용할 수 있습니다.')
      navigate("/auth")
    }
    requestDetail()
    return () =>{
    }
  },[])
  useEffect(() => {

    if(data){
      category.select(data.category_seq)
      
      className.update(data.class_name)
      classDescription.update(data.class_description)
      
      classType.update(data.class_type)

      minUserCount.select(data.min_user_count)
      maxUserCount.select(data.max_user_count)


      ageType.update(data.all_age_yn)
      minTargetAge.select(data.min_target_age)
      maxTargetAge.select(data.max_target_age)

      classPrice.update(data.price)
      discountPrice.update(data.discount_price)

      setCurriculumHtml(data.curriculum_html)
      setNoticeHtml(data.notice_html)
      setInformHtml(data.inform_html)

      setSchedules(data.schedules)      
    }
    
    return () =>{
    }
  },[data])

  const { result : resultData , request : requestDetail} = useCommonQuery({ query: queries.ClassApplication.getDetail,params:applicationSeq, callbacks: {
    onSuccess: (data) => {
      
      if (data.result_code === "0000") {
        setData(data.result_data)
      } else {
      }
    },
    onError: (error) => {
    },
  },initEnabled:false});

  const onScheduleChanged = useCallback((e) =>{
    setSchedules(e)
    if(classType === 2){

    }
  },[classType])


  const {requestAsync:approveAsync} = useCommonMutation({query:queries.ClassApplication.approve,callbacks:{
    onSuccess: (data) => {
      if (data.result_code === '0000') {
      } else {
      }
    },
    onError: (error) => {
    },
  }});
  

  const onApprove = useCallback(async () => {
    try {
      const res = await approveAsync(applicationSeq);
      if (res.result_code === '0000') {
        alert(res.result_message);
      } else {
        alert(res.result_message);
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        alert('로그인 후 이용할 수 있습니다.');
        return;
      }

      alert('클래스 승인에 실패하였습니다.');
    }
  })

  

  const onModify = useCallback(async () => {
    
    
    // try {
    //   const res = await modifyAsync(classApplicationSeq,data);
    //   console.log('ClassApplicationDetail.onModify => res : ', res);
    //   if (res.result_code === '0000') {
    //     alert(res.result_message);
    //   } else {
    //     alert(res.result_message);
    //   }
    // } catch (e) {
    //   console.error('수정 실패 =>', e);
    //   if (e.response && e.response.status === 401) {
    //     alert('로그인 후 이용할 수 있습니다.');
    //     return;
    //   }

    //   alert('클래스 수정에 실패하였습니다.');
    // }
  })



  const onApproveClick = useCallback(() => {

    onApprove()
    // setApproveDialogShow(true)
  })


  // useEffect(() =>{
  //   console.log('ClassApplicationDetail.useEffect => Start 1');   
     
  //   if(ClassApplicationDetail. > 0){
  //     onChangeExposure()
  //   }

  //   return () =>{
  //     console.log('ClassApplicationDetail.useEffect => End 1');
  //   }
  // },[ exposureState, onChangeExposure])

  
  const onInformChanged = useCallback((e) => {

    setInformHtml(e)
  },[])

  const onCurriculumChanged = useCallback((e) => {
    setCurriculumHtml(e)
  },[])

  const onNoticeChanged = useCallback((e) => {
    setNoticeHtml(e)
  },[])

  // const onApproveWithExposureChange = useCallback((e) => {
  //   setApproveWithExposure(
  //     e.target.value,
  //   );
  // })

  // if (resultData.isLoading) return <h1>로딩중...</h1>;
  // if (resultData.isError) return <h1>Error: {resultData.error.message}</h1>;

  return (
    <>
      <DashboardNavbar title="신규 수업 상세" />

      <LACard>
        <LACardTitle title="수업 기본 정보" />

        <LAProperty name="선생님" required>
          <LAGridContainer>
          
            <LALabel wPercent={8}>{!!data && data.teacher_name}</LALabel>
            <LAButton wPercent={15} onClick={() => setTeacherSearchShow(true)}>
              선생님 검색
            </LAButton>
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="클래스 코드">
          <LALabel wPercent={8}>121</LALabel>
        </LAProperty>

        <LAProperty name="카테고리">          
          <LAGridContainer>          
          {/* <CategoryComboView category={!!data && data.category_seq} onChange={onCategoryChanged}/>  */}
          <CategoryComboView value={category.state} onChange={category.handleChange}/> 
          </LAGridContainer>          
        </LAProperty>

        <LAProperty name="클래스 명" required>
          <LAInput placeholder="책을 즐기며 읽는 법을 배우는 클래스" value={className.state} onChange={className.handleChange}/>
        </LAProperty>

        <LAProperty name="클래스 요약">
          <LAInput placeholder="클래스 요약을 입력하세요." value={classDescription.state} onChange={classDescription.handleChange}/>
        </LAProperty>

        <LAProperty name="클래스 유형">
          {/* <LALabel>{!!data && data.class_type_name}</LALabel>
           */}
           <LACodeRadioGroup codeType="CLASS_TYPE"
            value={classType.state}
            onChange={classType.handleChange}
            // value={classType}
            // onChange={onClassTypeChanged}
          />  
        </LAProperty>

        {/* <LAProperty name="클래스 회차">
          <LALabel>{!!data && data.max_user_count}회</LALabel>
        </LAProperty> */}

        <LAProperty name="정원">
          {/* <LALabel>최소 {!!data && data.min_user_count}명 ~ 최대 {!!data && data.max_user_count}명</LALabel> */}
          <LAGridContainer>
            <LALabel wPercent={5}>최소</LALabel>
            <LAComboBox wPercent={20} items={userCounts} value={minUserCount.state} onChange={minUserCount.handleChange}/>
            <LALabel wPercent={5}>~</LALabel>
            <LALabel wPercent={5}>최대</LALabel>
            <LAComboBox wPercent={20} items={userCounts} value={maxUserCount.state} onChange={maxUserCount.handleChange}/>
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="대상 연령">
          <LAGridContainer>
            <LACodeRadioGroup
              codeType="TARGET_AGE_QUERY_TYPE"
              value={ageType.state}        
              onChange={ageType.handleChange}              
            />
            
            <LAGridContainer>
              <LALabel wPercent={5}>최소</LALabel>
              <LAComboBox wPercent={20} items={targetAgeItems} 
                value={minTargetAge.state}
                onChange={maxTargetAge.handleChange}/>
              <LALabel wPercent={7}>~</LALabel>
              <LALabel wPercent={5}>최대</LALabel>
              <LAComboBox wPercent={20} items={targetAgeItems} 
                value={maxTargetAge.state}
                onChange={maxTargetAge.handleChange}/>
            </LAGridContainer>
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 금액" />

        <LAProperty name="수업 금액">
          <LAGridContainer>
            <LAListView
              wPercent={30}
              headers={['정상가']}
              // items={[['120,000 원']]}
            />
            <LAGridItem wPercent={10}>

              {/* <div>
              {
                classType.state === 1 
                ? */}
                <LAInput
                type='number'                 
                placeholder="정상가"                            
                name="price"
                value={classPrice.state}
                onChange={classPrice.handleChange}                
                />
                {/* :
                <LAInput
                type='number'                 
                placeholder="정상가"                            
                name="price"                
                value={totalPrice}
                // onChange={onChange} 
                />
              }  
              </div> */}
              
              
            </LAGridItem>
            
            <LAGridItem wPercent={10}>
              <ArrowForward color="inherit" />
              <LALabel>할인율 {discountRate}%</LALabel>
            </LAGridItem>
            <LAListView
              wPercent={30}
              headers={['할인가']}
              // items={[['90,000 원']]}
            />
            <LAGridItem wPercent={10}>
              <LAInput
                type='number'                 
                placeholder="할인가"                            
                name="discount_price"
                value={discountPrice.state}
                onChange={onChangeDiscountPrice}    
                // onChange={onChange} 
                />
            </LAGridItem>
          </LAGridContainer>
          <LAWarningLabel>
            금액 입력 시, 할인율은 자동 계산되며 소수점인 경우, 버림으로
            계산되어 프런트에 노출됩니다.
          </LAWarningLabel>
        </LAProperty>
      </LACard>

      <div>
      { 
        classType.state === 1 
        ?<OneDayScheduleView schedules={schedules} onChange={onScheduleChanged}/>
        :<PackageScheduleView schedules={schedules} onChange={onScheduleChanged}/>          
      }
      </div>
      
      {/* 클래스 이미지 정보 */}
      <LACard next>
        <LACardTitle title="클래스 이미지 정보" />

        <LAProperty name="클래스 이미지 정보(섬네일)" required>
          <LARadioGroup
            props={[
              {
                text: '기본 이미지 1',
                value: 0,
              },
              {
                text: '기본 이미지 2',
                value: 1,
              },
              {
                text: '기본 이미지 3',
                value: 2,
              },
              {
                text: '기본 이미지 4',
                value: 3,
              },
              {
                text: '직접 등록',
                value: 4,
              },
            ]}
          />
        </LAProperty>

        <LAProperty name="">
          <LAGridContainer>
            <LAImageListItem wPercent={13} imageUrl={imageUrl} />
            <LAGridItem wPercent={85}>
              <LAGridContainer>
                <LAInput wPercent={50} layouts={{ pr: { sm: 1 } }} />
                <LAButton wPercent={15}>파일 선택</LAButton>
              </LAGridContainer>
              <MDBox color="text" fontSize="1rem" lineHeight={1}>
                <LALabel
                  props={{
                    variant: 'caption',
                    color: 'error',
                    fontWeight: 'regular',
                    verticalAlign: 'middle',
                  }}
                >
                  (이미지 등록 시, 리스트, 상품 상세 이미지(대표)로 자동으로
                  등록됩니다.
                  <br />
                  RGB 버전 이미지만 등록 가능 (CMYK 버전 업로드 불가)
                  <br />
                  이미지 사이즈 : 1000 * 1000 / 형식 : jpg, jpeg, png (jpeg 등록
                  권장)
                  <br />
                  2MB 이하로 등록가능)
                </LALabel>
              </MDBox>
            </LAGridItem>
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="추가 이미지" required>
          <LAGridContainer>
            <LAButton wPercent={20}>파일 선택</LAButton>
            <LAImageList images={images} layouts={{ pt: 1 }} />
          </LAGridContainer>
        </LAProperty>
      </LACard>

      {/* 클래스 소개 */}
      <LACard next>
        <LACardTitle title="클래스 소개" />

        <LAProperty name="클래스 소개">
          {/* <LATextEditor>{informHtml}</LATextEditor> */}
          <LATextEditor value={informHtml} onChange={onInformChanged}></LATextEditor>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="커리큘럼 & 유의사항" />

        <LAProperty name="커리큘럼" required>
          {/* <LATextEditor>
            {curriculumHtml}
          </LATextEditor> */}
          <LATextEditor value={curriculumHtml} onChange={onCurriculumChanged}></LATextEditor>
        </LAProperty>

        {/* <LAProperty name="회차별 클래스 명" required>
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={comboItems}
              layouts={{ pr: { sm: 1 } }}
            />
            <LAInput
              wPercent={60}
              placeholder="회차 명을 입력해 주세요."
              layouts={{ pr: { sm: 1 } }}
            />
            <LAButton wPercent={20}>추가</LAButton>
          </LAGridContainer>
        </LAProperty> */}

        <LAProperty name="따로 챙겨주세요" required>
        <LAInput placeholder="따로 챙겨야할 준비물 입력 (ex. 가위, 칼, 풀, 스케치북)" value={materials.state} onChange={materials.handleChange}/>
        </LAProperty>

        <LAProperty name="클래스 전 꼭 확인해주세요" required>
          <LATextEditor value={noticeHtml} onChange={onNoticeChanged}></LATextEditor>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 키워드" />

        <LAProperty name="키워드 선택">
          <LAKeywords
            keywords={keywords}
            // isSelectedKeyword={isSelectedKeyword}
            // onSelectedKeyword={onSelectedKeyword}
          />
        </LAProperty>

        <LAProperty name="키워드 직접 입력">
          <LAGridContainer>
            <LALabel wPercent={2}>#</LALabel>
            <LAInput
              wPercent={50}
              placeholder="키워드 텍스트 입력 (ex. #라이브올 #섬세한 #재밌는 등)"
              layouts={{ pr: { sm: 1 } }}
            />
            <LAButton wPercent={10}>추가</LAButton>
          </LAGridContainer>
          <LAListView
            layouts={{ pt: 1 }}
            headers={[]}
            items={[
              ['#라이브올', '삭제'],
              ['#섬세한', '삭제'],
            ]}
          />
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 자료 정보" />

        <LAProperty name="자료 등록">
          <LAGridContainer>
            <LAInput                   
              wPercent={50} 
              layouts={{ pr: { sm: 1 } }} 
              type="file"                                    
              onChange={onChangeAttachment}
              />
            {/* <LAButton wPercent={15}>파일 선택</LAButton>
            <LALabel
              wPercent={85}
              props={{ variant: 'caption', color: 'error' }}
            >
              최대 3개까지 등록 가능하며 각 10MB까지 첨부 가능합니다.
            </LALabel>
            <LAGridContainer>
              <LAListView
                layouts={{ pt: 1 }}
                headers={[]}
                items={[
                  ['동물을 그림으로 알아보자.pdf', '삭제'],
                  ['곤충을 그림으로 알아보자.pdf', '삭제'],
                ]}
              />
            </LAGridContainer> */}
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 교재 정보" />

        <LAProperty name="교재">
          <LAGridContainer>
            <LAButton wPercent={15} onClick={() => setTextbookSearchShow(true)}>
              교재 선택
            </LAButton>
            <LALabel
              wPercent={85}
              props={{ variant: 'caption', color: 'error' }}
            >
              최대 3개까지 등록 가능합니다.
            </LALabel>
            <LAGridContainer>
              <LAListView
                layouts={{ pt: 1 }}
                headers={[]}
                items={[
                  ['동물을 그림으로 알아보자.pdf', '삭제'],
                  ['곤충을 그림으로 알아보자.pdf', '삭제'],
                ]}
              />
            </LAGridContainer>
          </LAGridContainer>
        </LAProperty>
      </LACard>

      <LACard next>
        <LACardTitle title="클래스 승인/노출" />
        <div>
        {
          !!data && !data.approved_yn?
            <LAProperty name="승인">
              <LAButton wPercent={15} onClick={onApproveClick} >승인</LAButton>
            </LAProperty>
            :''
          
        }
        </div>
      </LACard>

      <TeacherSearchModal
        show={teacherSearchShow}
        handleClose={() => setTeacherSearchShow(false)}
      />

      <ClassScheduleModal
        show={classScheduleShow}
        handleClose={() => setClassScheduleShow(false)}
      />

      <TextbookSearchModal
        show={textbookSearchShow}
        handleClose={() => setTextbookSearchShow(false)}
      />
      {/* <Dialog

        open={approveDialogShow}
        handleClose={() => setApproveDialogShow(false)}
        >
          
          <DialogTitle>수업 승인</DialogTitle>
          <DialogContent></DialogContent>
          <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
          <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={approveWithExposure} onChange={onApproveWithExposureChange} />
              }
              label="승인 후 노출"
            />
      </Dialog> */}
    </>
  );

  
}

export default ClassApplicationDetail;
