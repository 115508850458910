import React, { useState, useEffect, useRef, useMemo } from "react";
import moment from "moment";
import useCommonQuery from "common/hooks/useCommonQuery";
import { useApiContext } from "common/context/ApiContext";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  getTextCell,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LAModal,
  LASimpleTable,
  useInput,
  useModal,
  LADataTable,
  LATextEditor,
} from "modules/Widgets";
// MEMBER: 회원 관리 페이지
// ORDER: 결제 관리 페이지
// TOGETHER_ORDER: 투게더 결제 관리 페이지
// SETTLEMENT: 정산 관리 (수수료 관리, 정산 금액 확인 페이지)
// EVENT: 이벤트 관리 페이지
// COUPON: 쿠폰 관리 페이지
// POINT: 올머니 관리 페이지

function DownloadListModal({ show, handleClose, type }) {
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (show) requestDownloadList();
  }, [show]);

  const columns = useMemo(() => {
    return [
      { Header: "구분", accessor: "division", width: "10%" },
      { Header: "일시", accessor: "date", width: "10%" },
      { Header: "아이디", accessor: "id", width: "10%" },
      { Header: "이름", accessor: "name", width: "15%" },
      { Header: "권한", accessor: "authority", width: "15%" },
      { Header: "사유", accessor: "reason", width: "40%" },
    ];
  }, []);

  const { request: requestDownloadList } = useCommonQuery({
    query: queries.Download.downloadList,
    params: type,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setRows(
            data.data
              ? data.data.map((resultData, index) =>
                  dataToRow(resultData, index)
                )
              : []
          );
        } else showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "다운로드 목록 조회에 실패했습니다.");
      },
    },
    initEnabled: false,
  });

  const dataToRow = (data, index) => {
    return {
      division: getTextCell(`${data.type_detail ? data.type_detail : ""}`),
      date: getTextCell(
        `${moment(data.created_dt).format("YYYY-MM-DD HH:mm")}`
      ),
      id: getTextCell(`${data.admin_id}`),
      name: getTextCell(`${data.member_name}`),
      authority: getTextCell(`${data.auth_name}`),
      reason: getTextCell(`${data.reason}`),
    };
  };

  return (
    <LAModal
      title="다운로드 이력"
      width="60%"
      height="800px"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LADataTable columns={columns} rows={rows} />
      </LACard>
    </LAModal>
  );
}
export default React.memo(DownloadListModal);
