import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from "moment";
import "moment/locale/ko";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import { LASimpleTable, LADatePickerButton } from "modules/Widgets";

function TimeTableList({
  timetables,
  onChange,
  onViewResult,
  onDone,
  onChangeTimetable,
  schedule,
  onUrlEdit,
}) {
  const isShowTerminate = useCallback(
    (timetable) => {
      if (schedule.confirmation_yn) {
        if (timetable.abnormal_yn || timetable.done_yn) return false;
        return true;
      } else return false;
    },
    [schedule]
  );

  const { showAlert, showConfirm } = usePopAlert();

  const getScheduleRows = useCallback(() => {
    const result = [];
    if (timetables && timetables.length > 0) {
      timetables.forEach((timetable, index) => {
        result.push([
          timetable.timetable_seq === schedule.classroom?.timetable_seq
            ? {
                text: "▶▶▶",
                color: "#FFFF00",
              }
            : "",
          { text: `${timetable.timetable_seq}` },
          { text: `${timetable.curriculum_times}` },
          {
            text: timetable.supplementary_yn
              ? `${timetable.holiday_yn ? "보강(공휴일)" : "보강"}`
              : `${timetable.holiday_yn ? "정규(공휴일)" : "정규"}`,
          },
          { text: moment(timetable.schedule_date).format("YYYY-MM-DD (dd)") },
          {
            text: moment(
              timetable.schedule_date + " " + timetable.schedule_time
            ).format("A hh:mm"),
          },
          {
            text: timetable.curriculum_name,
            align: "left",
          },
          {
            text: timetable.suspended_yn
              ? "중지"
              : timetable.begin_yn
              ? "진행중"
              : "-",
          },
          {
            text: timetable.suspended_yn
              ? "중지"
              : timetable.abnormal_yn
              ? `비정상 종료${
                  timetable.abnormal_reason
                    ? `\n${timetable.abnormal_reason}`
                    : ""
                }`
              : timetable.done_yn
              ? "종료"
              : "-",
          },

          timetable.abnormal_yn
            ? timetable.supplement_seq
              ? {
                  text: moment(
                    `${timetable.supplement_schedule_date} ${timetable.supplement_schedule_time}`
                  ).format("YYYY-MM-DD (dd) A hh:mm"),
                }
              : {
                  text: "보강",
                  type: "button",
                  callback: {
                    onClick: () => onChange(timetable),
                  },
                }
            : !timetable.done_yn
            ? {
                text: "수업일 변경",
                type: "button",
                callback: {
                  onClick: () => onChange(timetable),
                },
              }
            : {
                text: "-",
              },
          {
            text: "URL 변경",
            type: "button",
            callback: {
              onClick: () => {
                onUrlEdit(timetable);
              },
            },
          },
          timetable.done_yn
            ? {
                text: "결과표",
                type: "button",
                callback: {
                  onClick: () => onViewResult(timetable),
                },
              }
            : {
                text: "-",
              },
          {
            text: "종료",
            type: "button",
            callback: {
              onClick: () => onDone(timetable),
            },
            // }:'',
          },
          timetable.timetable_seq === schedule.classroom?.timetable_seq
            ? "-"
            : isShowTerminate(timetable)
            ? {
                text: "현재수업 지정",
                type: "button",
                callback: {
                  onClick: () => onChangeTimetable(timetable.timetable_seq),
                },
              }
            : "",
        ]);
      });
    }

    return result;
  }, [timetables, schedule]);

  const scheduleTableData = useMemo(() => {
    return {
      headers: [
        { text: "현재수업" },
        { text: "수업번호" },
        { text: "회차" },
        { text: "구분" },
        { text: "클래스 일정" },
        { text: "수업 시간" },
        { text: "수업명" },
        { text: "진행 여부" },
        { text: "종료 여부" },
        { text: "변경/보강" },
        { text: "수업방 URL 변경" },
        { text: "수업 결과" },
        { text: "종료 처리" },
        { text: "수업 지정" },
      ],
      wPercentList: [5, 5, 5, 5, 10, 8, 10, 6, 6, 8, 8, 10, 7, 7],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  return <LASimpleTable tableData={scheduleTableData} />;
}
export default React.memo(TimeTableList);
