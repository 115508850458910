import React from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import OrderRefundDetailContents from '../components/OrderRefundDetailContents';

function OrderRefundDetail({ refundComplete = false }) {

  return (
    <>
      <DashboardNavbar title="클레임 관리 / 취소 관리 / 상세" />

      <OrderRefundDetailContents />
    </>
  );
}

export default OrderRefundDetail;
