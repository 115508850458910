import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/timetables';

const TimetableApi = (requestFunc) => {
  return {    
    Timetable: {
      /**
       * 수업별 수업 일정 Timetable 수정
       */
       modifyTimetable: {
        key: 'modifyScheduleTimetable',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.timetable_seq}`, params.data),
      },
      /**
       * 비정상 수업 보강처리
       */
       addSupplement: {
        key: 'addSupplement',
        queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}/${params.timetable_seq}/supplement`, params.data),
      },

    /**
     * 수업별 수업 일정 Timetable 수정
     */
      deleteTimetable: {
        key: 'deleteScheduleTimetable',
        queryFn: (params) => requestFunc(METHOD.DELETE, `${baseURL}/${params.timetable_seq}`, params.data),
      },

      /**
       * 수업별 학습결과 목록
       */
       attendances: {
        key: 'attendances',
        queryFn: (timetableSeq) => requestFunc(METHOD.GET, `${baseURL}/${timetableSeq}/attendances`),
      },
      
      /**
       * 수업별 학습자 목록
       */
       classmates: {
        key: 'classmates',
        queryFn: (timetableSeq) => requestFunc(METHOD.GET, `${baseURL}/${timetableSeq}/classmates`),
      },     
      /**
       * 수업별 학습자 목록
       */
       enterRoom: {
        key: 'enterRoom',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.timetableSeq}/enter-room`,params.data),
      },      

      /**
       * 종료처리
       */
       termination: {
        key: 'termination',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.timetable_seq}/termination`, params.data),
      },

      /**
       * 비정상 종료처리
       */
       abnormalTermination: {
        key: 'abnormalTermination',
        queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.timetable_seq}/abnormalTermination`, params.data),
      },

        /**
         * 수업 결과표
         */
        studyResult: {
            key: 'studyResult',
            queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.timetableSeq}/${params.classroomSeq}/studyResult`),
        },

        /**
         * 수업 이력
         */
        studyHistory: {
            key: 'studyHistory',
            queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}/${params.timetableSeq}/${params.classroomSeq}/${params.selectDate}/studyHistory`),
        },
    },
  };
};

export default TimetableApi;
