import React, { useMemo } from 'react';
import moment from 'moment';

import { useLoginContext } from 'common/context/MemberContext';

import {
    LAGridItem,
    LALabel,
    LAButton,
    LASimpleTable,
    LATable,
} from 'modules/Widgets';

function OrderHistoryTable({ orderInfo, tableWidth = 1500 }) {
    const { action } = useLoginContext();
    const authMemberType = useMemo(() => {
        return action.getMemberType();
    }, [action]);

    const authAdminAuthType = useMemo(() => {
        return action.getAuthType();
    }, [action]);

    const orderHistoryTableData = useMemo(() => {
        console.log('OrderHistoryTable.orderInfo => ', orderInfo);
        return {
            headers: [
                { text: '판매가' },
                { text: '쿠폰 사용' },
                { text: '올머니 사용'},
                { text: '클럽 포인트 사용' },
                { text: '클럽 마일리지 사용' },
                { text: '배송비' },
                { text: '카드 결제 금액' },
                { text: '클래스 상태' },
                // { text: '적립금' },
                // { text: '결제 수단' },
                { text: '결제 상태' },
                // { text:'취소 금액' },
                { text: '상태 관리' },
                { text: '배송 관리' },
            ],
            wPercentList: [9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 10],
            rows: [[
                { text: (<div>{orderInfo.payPrice}</div>) },
                { text: (<div>{orderInfo.couponDiscountPrice}</div>)},
                { text: (<div>{orderInfo.useEarnedPoint}</div>)},
                { text: (<div>{orderInfo.useBookclubPoint}</div>)},
                { text: (<div>{orderInfo.useBookclubMilage}</div>)},
                { text: orderInfo.deliveryFee == '0원' ? (<div>없음</div>) : (<div>{orderInfo.deliveryFee}</div>)},
                { text: (<div style={{ fontWeight: "bold" }}>{orderInfo.totalPrice}</div>)},
                { text: orderInfo.confirmationYn ? (<div>확정</div>) : (<div>미확정</div>)},
                    // { text: orderInfo.payMethod },
                { text: (<div>{orderInfo.payStatus}</div>) },
                orderInfo.statusButtonText && authMemberType == 1 && (authAdminAuthType == 1 || authAdminAuthType == 4) && orderInfo.orderStatus === 10
                    ? {text: <LAButton onClick={orderInfo.cancelButtonOnClick}>결제 취소 및 반품</LAButton>}
                    :  orderInfo.statusButtonText && authMemberType == 1 && (authAdminAuthType == 1 || authAdminAuthType == 4) && orderInfo.orderStatus === 21
                    ? {text: <LAButton onClick={orderInfo.cancelButtonOnClick}>취소 대기<br/>(반품 검수)</LAButton>}
                    : {text: <div>취소완료</div>},
            /*     orderInfo.deliveryInfoInvoice
                    ? {text: <LAButton onClick={orderInfo.deliveryButtonOnClick}>{orderInfo.deliveryInfoText}</LAButton>}
                    : orderInfo.textbookYn === true && orderInfo.payStatus === '결제완료'
                    ? {text: <LAButton onClick={orderInfo.changeButtonOnClick}>교환 발송 요청</LAButton>}
                    : {text: '배송 정보 없음'}*/
                orderInfo.textbookYn === true
                    ? {text: <LAButton onClick={orderInfo.changeButtonOnClick}>교환 발송 요청</LAButton>}
                    : {text: <div>배송 없음</div>}
                // orderInfo.deliveryButtonText
                //   ? {
                //       text: (
                //         <LAButton onClick={orderInfo.deliveryButtonOnClick}>
                //           {orderInfo.deliveryButtonText}
                //         </LAButton>
                //       ),
                //     }
                //   : {text:''},
                ],
                // [
                //   {
                //     text: orderInfo.textbookName,
                //     align: 'left',
                //     wPercent: 28,
                //   },
                //   {
                //     text: orderInfo.textbookCount,
                //     wPercent: 6,
                //   },
                //   {
                //     text: orderInfo.textbookPrice,
                //     wPercent: 6,
                //   },
                // ],
            ]
        };
    }, [orderInfo]);

    

    return (
        <>
            <LASimpleTable tableData={orderHistoryTableData} />
            {/* <LATable
                tableWidth={tableWidth}
                tableData={orderHistoryTableData}
                layouts={{ pt: 3 }}
            /> */}
            <LAGridItem
                layouts={{
                    sx: {
                        border: '1px solid #dddddd',
                        borderTop: '0px solid #dddddd',
                        textAlign: 'center',
                        backgroundColor: '#eeeeee',
                        minHeight: "38px"
                    },
                }}
            >
                <LALabel grid={false}>{'판매가 - 쿠폰 - 올머니 - 클럽 포인트 - 클럽 마일리지 + 배송비 = '}</LALabel>
                <LALabel grid={false} props={{ fontWeight: 'bold' }}>카드 결제 금액</LALabel>
            </LAGridItem>
            <LAGridItem
                layouts={{
                    sx: {
                        border: '1px solid #dddddd',
                        borderTop: '0px solid #dddddd',
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        textAlign: 'center',
                    },
                }}
            >
                <LALabel grid={false}>{`${orderInfo.totalCalculator} = `}</LALabel>
                <LALabel grid={false} props={{ fontWeight: 'bold' }}>{orderInfo.totalPrice}</LALabel>
            </LAGridItem>
        </>
    );
}

export default OrderHistoryTable;
