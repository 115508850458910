import React, { useState, useCallback, useEffect, useMemo } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
// import { useNavigate } from 'react-router-dom';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import { useLoginContext } from 'common/context/MemberContext';
import { getTodayDateText, dateTimeToString } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import TeacherDetailModal from './TeacherDetailModal';
import { periodItems } from 'common/constants';
import {
    LACard,
    LAGridContainer,
    LAProperty,
    LALabel,
    LAButton,
    LAComboBox,
    LAInput,
    LADataTable2,
    getTextCell,
    getNormalButton,
    LATabSelector,
    LADatePicker,
    useTabSelector,
    useDatePicker,
    useInput,
    useComboBox,
    useModal,
    LACodeComboBox
} from 'modules/Widgets';
import moment from 'moment';

function TeacherList() {
    const todayDateText = getTodayDateText('-');
    // const navigate = useNavigate();

    const { action } = useLoginContext();

    const { queries } = useApiContext();
    const { showAlert } = usePopAlert();
    const periodType = useComboBox('1');
    const periodTab = useTabSelector(3);
    const startDate = useDatePicker(todayDateText);
    const endDate = useDatePicker(todayDateText);
    const searchType = useComboBox('1');
    const searchInput = useInput('');

    const startDateText = useMemo(() => {
        return startDate.state.replaceAll('-', '');
    }, [startDate.state]);
    const endDateText = useMemo(() => {
        return endDate.state.replaceAll('-', '');
    }, [endDate.state]);

    const [rows, setRows] = useState([]);
    const [selectedTeacherSeq, setSelectedTeacherSeq] = useState(0);
    const teacherDetailModal = useModal(false);
    const [tablePage, setTablePage] = useState(0);
    const [page, setPage] = useState(0); // 마지막 페이지네이션

    const searchTypes = [
        { key: 1, value: '통합검색' },
        { key: 2, value: '소속 코드' },
        { key: 3, value: '소속 회사' },
        { key: 4, value: '이름(닉네임)' },
    ];
    const allianceSearchTypes = [
        { key: 1, value: '통합검색' },
        { key: 4, value: '이름(닉네임)' },
    ];

    const authAdminAuthType = useMemo(() => {
        return action.getAuthType();
    }, [action]);

    const getQueryType = useMemo(() => {
        if (authAdminAuthType == 5) {
            return allianceSearchTypes;
        } else {
            return searchTypes;
        }
    });

    const onTeacherDetailClick = useCallback((no) => {
        // navigate(`${no}`);
        // navigate(`/user/teacher/list/detail/${no}`);
        setSelectedTeacherSeq(no);
        teacherDetailModal.visible(true);
    });

    const onTeacherDetailClose = useCallback(() => {
        onSearchClick();
        teacherDetailModal.handleClose();
    });
    

    const [ searchParams, setSearchParams ] = useState({});


    const dataToRow = (data, index) => {
        return {
            no: getTextCell(`${data.member_seq}`),
            company: getTextCell(`${data.alliance_name ? data.alliance_name : ''}`),
            // login_id: getTextCell(`${data.login_id ? data.login_id : ''}`),
            user_name: getTextCell(`${data.member_name ? data.member_name : ''}`, `${data.member_seq}`, onTeacherDetailClick),
            nick_name: getTextCell(`${data.nick_name ? data.nick_name : ''}`),
            last_visit: getTextCell(`${data.signin_dt ? moment(data.signin_dt).format('YYYY-MM-DD (dd) A hh:mm') : ''}`),
            login_ip: getTextCell(`${data.remote_address ? data.remote_address : ''}`),
            reg_date: getTextCell(`${data.created_dt ? moment(data.created_dt).format('YYYY-MM-DD (dd) A hh:mm') : ''}`),
            approval: getTextCell(`${data.approved_yn ? 'Y' : 'N'}`),
            detail: getNormalButton('상세', `${data.member_seq}`, onTeacherDetailClick),
        };
    };


    useEffect(() => {
        startDate.selectByPeriod(periodTab.state);
        endDate.reset(periodTab.state);
    }, [periodTab.state, endDate.reset]);


    useEffect(() => {
        if (page != 0) {
            setTablePage(page);
        }
    }, [rows]);


    const onSearchClick = useCallback(() => {
        let searchParams = {
            period_type: periodType.state,
            start_dt: startDateText,
            end_dt: endDateText,
            query_type: searchType.state,
            query: searchInput.state,
        }
        setSearchParams(searchParams);
    },[
        periodType.state,
        startDateText,
        endDateText,
        searchType.state,
        searchInput.state,
    ]);

    const onEnterKeyAtSearch = useCallback(() => {
        onSearchClick();
    });

    const onInitializeClick = useCallback(() => {
        periodType.reset();
        periodTab.reset();
        startDate.reset();
        endDate.reset();
        searchType.reset();
        searchInput.reset();
      },[
        periodType.reset,
        periodTab.reset,
        startDate.reset,
        endDate.reset,
        searchType.reset,
        searchInput.reset
      ]);

      const columns = useMemo(() => {
        return [
            { Header: 'No', accessor: 'no', width: '8%' },
            { Header: '소속', accessor: 'company', width: '12%' },
            { Header: '이름', accessor: 'user_name', width: '12%' },
            // { Header: '아이디', accessor: 'login_id', width: '12%' },
            { Header: '닉네임', accessor: 'nick_name', width: '10%' },
            { Header: '최근 방문일시', accessor: 'last_visit', width: '12%' },
            { Header: '로그인 IP', accessor: 'login_ip', width: '12%' },
            { Header: '가입일', accessor: 'reg_date', width: '12%' },
            // { Header: '승인', accessor: 'approval', width: '10%' },
            { Header: '상세', accessor: 'detail', width: '10%' },
        ];
    }, []);

    const onChangeTablePage = useCallback((page) => {
        setPage(page);
    });

    useEffect(() => {
        if (startDate.state != endDate.state) onSearchClick();
    }, [startDate.state]);

  return (
    <>
      <DashboardNavbar title="선생님 관리" />

      {/* Start Search Area */}
      <LACard>
        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            <LACodeComboBox
              wPercent={15}
              codeType="MEMBER_QUERY_PERIOD_TYPE"
              value={periodType.state}
              onChange={periodType.handleChange}
            />
            <LATabSelector
              wPercent={45}
              items={periodItems}
              layouts={{ pl: 2 }}
              value={periodTab.state}
              onChange={periodTab.handleChange}
            />
            <LADatePicker
              wPercent={15}
              // defaultValue="2022-06-03"
              layouts={{ ml: 2, mr: 2 }}
              value={startDate.state}
              onChange={startDate.handleChange}
            />{' '}
            ~{' '}
            <LADatePicker
              wPercent={15}
              // defaultValue="2022-06-03"
              layouts={{ ml: 2 }}
              value={endDate.state}
              onChange={endDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 검색">
          <LAGridContainer>
            <LAComboBox
              wPercent={20}
              items={getQueryType}
              value={searchType.state}
              onChange={searchType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: { sm: 1 } }}
              value={searchInput.state}
              onChange={searchInput.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: { sm: 1 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pl: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitializeClick}
          >
            초기화
          </LAButton>
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      <LACard layouts={{ pt: { sm: 1 } }}>
        <LADataTable2
          columns={columns}
          loadUrl={ queries.Teachers.teachers }
          params={ searchParams }
          dataToRow={ dataToRow }
          defaultPage={tablePage}
          onChangePage={onChangeTablePage}
        />
      </LACard>

      <TeacherDetailModal
        teacherSeq={selectedTeacherSeq}
        show={teacherDetailModal.state}
        handleClose={onTeacherDetailClose}
        handleSelect={teacherDetailModal}
      />
    </>
  );
}

export default TeacherList;
