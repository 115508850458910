import React, { useState, useCallback, useMemo, useEffect } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';
import { getTodayDateText } from 'common/utils';
import {
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LASimpleTable,
  LATabSelector,
  getTextCell,
  getNormalButton,
  getPrimaryButton,
  useRadioGroup,
  useComboBox,
  useInput,
  useModal,
  useDatePicker,
  useTabSelector,
} from 'modules/Widgets';
import NoticeRegistModal from './NoticeRegistModal';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import moment from 'moment';
import { periodItems } from 'common/constants';
import { useLoginContext } from 'common/context/MemberContext';

function NoticeManagement() {
  const { loginState } = useLoginContext();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [selectedFooterSeq, setSelectedFooterSeq] = useState(undefined);
  const [footers, setFooters] = useState([]);

  const todayDateText = getTodayDateText('-');

  const searchDateType = useComboBox(0);
  const searchPeriodType = useTabSelector(3);
  const searchStartDate = useDatePicker(todayDateText);
  const searchEndDate = useDatePicker(todayDateText);
  const visibleType = useRadioGroup(-1);
  const searchTargetType = useComboBox(1);
  const searchText = useInput('');
  const footerRegistModal = useModal(false);

  const { result: resultData, request: requestFooters } = useCommonQuery({
    query: queries.Notice.getNotices,
    params: {
      exposure_yn: visibleType.state < 0 ? undefined : visibleType.state,
      start_dt: searchStartDate.state,
      end_dt: searchEndDate.state,
      keyword: searchText.state,
      query_type: searchTargetType.state,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setFooters(data.result_data);
        } else {
          showAlert('알림', data.result_message);
          setFooters([]);
        }
      },
      onError: (error) => {
        showAlert('알림', '목록 조회에 실패하였습니다.');
        setFooters([]);
      },
    },
    initEnabled: false,
  });

  const { request: requestDelete } = useCommonMutation({
    query: queries.Notice.deleteNotice,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          showAlert('알림', '삭제가 완료되었습니다.');
          requestFooters();
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '삭제에 실패했습니다.');
      },
    },
  });

  const onFooterUpdateClick = useCallback((noticeSeq) => {
    setSelectedFooterSeq(noticeSeq);
    footerRegistModal.visible(true);
  }, []);

  const onFooterDeleteClick = useCallback((noticeSeq) => {
    showConfirm('알림', '해당 공지사항을 삭제 하겠습니까?', {
      confirmHandler: () =>
        requestDelete({
          noticeSeq: noticeSeq,
        }),
    });
  }, []);

  const columns = useMemo(() => {
    return [
      { Header: 'No', accessor: 'no', width: '7%', align: 'center' },
      { Header: '제목', accessor: 'title', width: '30%', align: 'left' },
      { Header: '조회수', accessor: 'views', width: '7%', align: 'center' },
      { Header: '노출', accessor: 'visible', width: '7%', align: 'center' },
      {
        Header: '등록자',
        accessor: 'regist_user',
        width: '7%',
        align: 'center',
      },
      {
        Header: '등록일',
        accessor: 'regist_date',
        width: '10%',
        align: 'center',
      },
      { Header: '수정', accessor: 'update', width: '7%', align: 'center' },
      { Header: '삭제', accessor: 'delete', width: '7%', align: 'center' },
    ];
  }, []);

  const rows = useMemo(() => {
    return footers.map((item) => {
      return {
        no: getTextCell(`${item.notice_seq}`),
        title: getTextCell(
          item.title.length > 20
            ? item.title.slice(0, 30) + ' ...'
            : item.title,
          `${item.notice_seq}`,
          onFooterUpdateClick,
        ),
        views: getTextCell(item.view_count || 0),
        visible: getTextCell(item.exposure_yn ? '노출' : '미노출'),
        regist_user: getTextCell(item.constructor_name || ''),
        regist_date: getTextCell(
          item.created_dt ? moment(item.created_dt).format('YYYY-MM-DD') : '',
        ),
        update: getPrimaryButton(
          '수정',
          `${item.notice_seq}`,
          onFooterUpdateClick,
        ),
        delete: getNormalButton(
          '삭제',
          `${item.notice_seq}`,
          onFooterDeleteClick,
        ),
      };
    });
  }, [footers]);

  const onChangePage = useCallback((page) => {
  }, []);

  const onInitClick = useCallback(() => {
    searchDateType.reset();
    searchPeriodType.reset();
    // searchStartDate.reset();
    searchEndDate.reset();
    searchTargetType.reset();
    searchText.reset();
  }, [
    searchDateType.reset,
    searchPeriodType.reset,
    // searchStartDate.reset,
    searchEndDate.reset,
    searchTargetType.reset,
    searchText.reset,
  ]);

  const onSearchClick = useCallback(() => {
    requestFooters();
  }, []);

  const onEnterKeyAtSearch = useCallback(() => {
    onSearchClick();
  }, []);

  const onRegistClick = useCallback(() => {
    footerRegistModal.visible(true);
  });

  const onRegistered = useCallback(() => {
    footerRegistModal.handleClose();
    setSelectedFooterSeq(undefined);
    onSearchClick();
  });

  useEffect(() => {
    searchStartDate.selectByPeriod(searchPeriodType.state);
    searchEndDate.reset(searchPeriodType.state);
    // onSearchClick();
  }, [searchPeriodType.state, searchEndDate.reset]);

  useEffect(() => {
    if (searchStartDate.state != searchEndDate.state) {
      requestFooters();
    }
  }, [searchStartDate.state, searchEndDate.state, visibleType.state]);

  return (
    <>
      <DashboardNavbar title="공지사항 관리" />

      {/* Start Search Area */}
      <LACard next>
        <LAProperty name="노출 여부">
          <LAGridContainer>
            <LARadioGroup
              props={[
                {
                  text: '전체',
                  value: -1,
                },
                {
                  text: '노출',
                  value: 1,
                },
                {
                  text: '미노출',
                  value: 0,
                },
                // {
                //   text: '예약 노출',
                //   value: 2,
                // },
              ]}
              value={visibleType.state}
              onChange={visibleType.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="조회 기간">
          <LAGridContainer vAlign="center">
            {/* <LAComboBox
              wPercent={15}
              items={[
                { key: 0, value: '이벤트 시작일' },
                { key: 1, value: '이벤트 종료일' },
              ]}
              value={searchDateType.state}
              onChange={searchDateType.handleChange}
            /> */}
            <LATabSelector
              wPercent={45}
              items={periodItems}
              // layouts={{ pl: 2 }}
              value={searchPeriodType.state}
              onChange={searchPeriodType.handleChange}
            />
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2, mr: 2 }}
              value={searchStartDate.state}
              onChange={searchStartDate.handleChange}
            />{' '}
            ~{' '}
            <LADatePicker
              wPercent={15}
              layouts={{ ml: 2 }}
              value={searchEndDate.state}
              onChange={searchEndDate.handleChange}
            />
          </LAGridContainer>
        </LAProperty>

        <LAProperty name="직접 입력">
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              items={[
                { key: 1, value: '통합검색' },
                { key: 2, value: '제목' },
                { key: 3, value: '등록자 명' },
              ]}
              value={searchTargetType.state}
              onChange={searchTargetType.handleChange}
            />
            <LAInput
              wPercent={80}
              layouts={{ pl: { sm: 1 } }}
              value={searchText.state}
              onChange={searchText.handleChange}
              onEnterKeyPressed={onEnterKeyAtSearch}
            />
          </LAGridContainer>
        </LAProperty>

        <LAGridContainer layouts={{ pt: 2 }}>
          <LALabel wPercent={28}></LALabel>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            onClick={onSearchClick}
          >
            검색
          </LAButton>
          <LAButton
            wPercent={15}
            layouts={{ pr: { sm: 1 } }}
            variant={'outlined'}
            onClick={onInitClick}
          >
            초기화
          </LAButton>
          <LAButton wPercent={15} onClick={onRegistClick}>
            등록
          </LAButton>
          <LALabel wPercent={27}></LALabel>
        </LAGridContainer>
      </LACard>
      {/* End Search Area */}

      {/* 이벤트 참여 내역 다운로드*/}
      <LACard layouts={{ pt: { sm: 1 } }}>
        <LAGridContainer>
          <LADataTable
            columns={columns}
            rows={rows}
            defaultPage={0}
            onChangePage={onChangePage}
          />
        </LAGridContainer>
      </LACard>

      {footerRegistModal.state && (
        <NoticeRegistModal
          show={footerRegistModal.state}
          handleClose={onRegistered}
          footerSeq={selectedFooterSeq}
        />
      )}
    </>
  );
}

export default NoticeManagement;
