import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LASimpleTable,
} from 'modules/Widgets';

function ApplicationList({ applications }) {

  const getDate = useCallback((date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD (dd) A hh:mm:SS');
    }
    return '0000-00-00';
  });

  const getScheduleRows = useCallback(() => {
    const result = [];
    if (applications && applications.length > 0) {
      // for (let i = 0; i < schedules.length; i++) {

      for (const application of applications) {
        let cancelStatus =
          application.cancel_yn == 1 || application.order_status > 10;
        let cancelReason = cancelStatus
          ? application.cancel_reason != undefined
            ? application.cancel_reason
            : application.payment_cancel_reason
          : '';
        result.push([
          {
            text: `${application.row_no}`,
          },
          {
            text: application.applicant_name,
          },
          {
            text: application.user_name,
          },
          {
            text: getDate(application.created_dt),
          },
          {
            text: application.confirmation_yn == 1 ? '확정' : '미확정',
          },
          {
            text: cancelStatus ? '취소' : '결제완료',
          },
          {
            text: cancelReason,
          },
          {
            text: getDate(application.payment_cancel_dt),
          },
        ]);
      }
    }

    return result;
  }, [applications]);
  
  const tableData = useMemo(() => {
    return {
      headers: [
        {
          text: '#',
        },
        {
          text: '신청자',
        },
        {
          text: '학습자',
        },
        {
          text: '신청일자',
        },
        {
          text: '확정 상태',
        },
        {
          text: '결제 상태',
        },
        {
          text: '취소 사유',
        },
        {
          text: '취소 일자',
        },
      ],

      wPercentList: [5, 10, 10, 20, 8, 8, 10, 20],
      rows: getScheduleRows(),
    };
  }, [getScheduleRows]);

  return (
    <>
      {/* <LACard next>         */}
      <LASimpleTable tableData={tableData} />
      {/* </LACard> */}
    </>
  );
}
export default React.memo(ApplicationList);
