import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LAModal,
  LACard,
  LACardTitle,
  LAGridContainer,
  LALabel,
  LAButton,
  LAInput,
  LAProperty,
  LAWarningLabel,
  LATextEditor,    
  useInput,
} from 'modules/Widgets';
import { useApiContext } from 'common/context/ApiContext';
import useCommonMutation from 'common/hooks/useCommonMutation';
import {sendCacheRefresh} from "../../../../common/utils";


function MainVideoBannerModal({ show,mainData, handleClose }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [videoInfo, setVideoInfo] = useState([]);

  const getTextEditorTitleValue = useRef(null);
  const backgroundColor = useInput('#000000');
  const videoUrl = useInput('');

  const getBackgroundGuideText = useMemo(() =>{    
    return '배경색은 헥사코드로 입력하세요'    
  })

  const { request: requestMainVideo } = useCommonMutation({
    query: queries.MainVideo.getMainVideo,
    callbacks: {
      onSuccess: (data) => {
        console.log(data);
        if (data.result_code === '0000') {
          console.log(data.result_data);
          setVideoInfo(data.result_data);
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 영상 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestModify } = useCommonMutation({
    query: queries.MainExhibition.updateMainExhibition,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          console.log(data.result_data);
          showAlert('알림', '저장되었습니다.');
          /**CACHE REFRESH : MAIN 갱신*/
          sendCacheRefresh( '/v1/api/main' );
        } else {
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        showAlert('알림', '메인 클래스 저장에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (show) {
      // videoTitle.reset();
      videoUrl.reset();
      backgroundColor.reset()
      requestMainVideo();
    }
  }, [show]);

  useEffect(() => {
    if(videoInfo && videoInfo.video_title) {      
      getTextEditorTitleValue.current.value = videoInfo.video_title
      videoUrl.update(videoInfo.video_link_url||'');
      backgroundColor.update(mainData?.background_color || '#000000');
    }
  }, [videoInfo]);

  const onSaveClick = useCallback(() => {
    if (!getTextEditorTitleValue?.current || getTextEditorTitleValue?.current().length == 0 ) {
      showAlert('알림', '영상 제목을 입력해주세요.');
      return;
    } else{
      if (!videoUrl?.state || videoUrl.state.length === 0) {
        showAlert('알림', '영상 URL을 입력해주세요.');
        return;
      }
  
      if(!backgroundColor.state) {
        showAlert('알림', '배경색을 입력하세요.');
        return;
      }
      
      var contentHtmlVideoTitle = `<div style="font-size: 16px; font-family: 노토산스;">${getTextEditorTitleValue.current()}</div>`;
      
    }

    
    // requestUpdate({video_title: contentHtmlVideoTitle, video_url: videoUrl.state});

    var payload = {
      mainSeq:mainData?.main_seq,
      main_type : mainData?.main_type,
      background_color : backgroundColor.state,
      video : {video_seq:videoInfo.video_seq, video_title: contentHtmlVideoTitle, video_url: videoUrl.state}
    }
    console.log('payload => ',payload)
    requestModify(payload);

  }, [mainData,videoUrl.state,backgroundColor.state])

  return (
    <LAModal
      title="메인 영상 배너 관리"
      width="70%"
      height="80%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LACardTitle title="메인 영상 정보" />
        <LAProperty name="제목" required>          
          <LATextEditor
              name="description_e_title"
              value={videoInfo?.video_title || ''}
              getEditorValue={getTextEditorTitleValue}
              />

        </LAProperty>
        <LAProperty name="배경색" required>
            <LAInput
              placeholder={getBackgroundGuideText}
              value={backgroundColor.state}
              onChange={backgroundColor.handleChange}
            />
            <LAWarningLabel>{getBackgroundGuideText}</LAWarningLabel>
          </LAProperty>
        <LAProperty name="영상 URL" required>
          <LAInput
            placeholder="영상 URL을 입력해 주세요."
            value={videoUrl.state}
            onChange={videoUrl.handleChange}
          />
          <LAWarningLabel>YouTube 동영상만 링크 가능하며 https://www.youtube.com/embed/content_id 형식으로 입력하세요.</LAWarningLabel>
          <Box>
            <iframe
                width={'560'}
                height={'315'}
                src={videoUrl.state}
            />
          </Box>

        </LAProperty>
      </LACard>

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={42}></LALabel>
        <LAButton 
          wPercent={15} 
          layouts={{ ml: 1 }}
          onClick={onSaveClick}
        >
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel>
      </LAGridContainer>

    </LAModal>
  );  
}

export default MainVideoBannerModal;
