import React, { useEffect, useCallback, useMemo, useState } from "react";
import OrderRefundHistoryTable from "./OrderRefundHistoryTable";
import OrderRefundPriceListView from "./OrderRefundPriceListView";
import pxToRem from "assets/theme/functions/pxToRem";
import useCommonMutation from "common/hooks/useCommonMutation";
import { useApiContext } from "common/context/ApiContext";

import usePopAlert from "common/components/popAlert/hooks/usePopAlert";

import {
  LACardTitle,
  LAProperty,
  LAGridContainer,
  LAComboBox,
  LAButton,
  LADivider,
  LAWarningLabel,
  LALabel,
  LAModal,
  useModal,
  LACard,
  useSimpleTable,
  LAInput,
  useInput,
  useComboBox,
  LATextField,
  useTextField,
  getLinkTextCell,
} from "modules/Widgets";
import { getTodayDateText, toCurrency } from "common/utils";
import moment from "moment";
import DeliveryTrackingModal from "pages/Common/DeliveryTrackingModal";
import useCommonQuery from "../../../common/hooks/useCommonQuery";

const comboItems = [
  { key: 0, value: "값1" },
  { key: 1, value: "값2" },
  { key: 2, value: "값3" },
  { key: 3, value: "값4" },
  { key: 4, value: "값5" },
  { key: 5, value: "값6" },
  { key: 6, value: "값7" },
  { key: 7, value: "값8" },
  { key: 8, value: "값9" },
];

function OrderRefundModal({
  show,
  handleClose,
  isAdmin = false,
  data,
  refreshData,
}) {
  const {
    application,
    delivery,
    cancelable,
    class: classInfo,
    order,
    orderOptions,
    payment,
    paymentCancel,
    exchangeTextbooks,
    returnTextbook,
  } = data || {};

  console.log("classInfo?.class_type :: ", classInfo?.class_type);
  const [cancelOrderOptions, setCancelOrderOptions] = useState(orderOptions);

  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();

  const cancelAmount = useInput(0);
  const numericCancelAmount = isNaN(parseInt(cancelAmount.state, 10))
    ? cancelAmount.update(0)
    : parseInt(cancelAmount.state, 10); // 문자열을 정수로 변환
  const cancelReason = useInput("");

  const refundType = useComboBox("");

  const imputType = useComboBox("");
  const refundReason = useTextField("");
  const refundFeedbackType = useComboBox("");

  const refundFeedback = useInput("", 3000);
  const [disabledType, setDisabledType] = useState(true);
  const [currentData, setCurrentData] = useState(data);
  const [btnStatus, setBtnStatus] = useState(""); //회수요청중인 btn상태제어

  //하루 체크하여 버튼 제어
  useEffect(() => {
    const returnData = exchangeTextbooks?.filter(
      (item) => item.process_type === "RETURN"
    );
    const createdDt = moment(returnData?.[0]?.created_dt).format("YYYYMMDD");
    const today = getTodayDateText();
    if (createdDt == today) {
      return setBtnStatus("1");
    }
    return setBtnStatus("2");
  }, [exchangeTextbooks]);

  const delivertTrackingModalPopup = useModal(false);
  const [contextWaybill, setContextWaybill] = useState();

  // 매출처리 요청 api (classType이 9가 아닌 경우)
  const { request: requestSaleSend } = useCommonMutation({
    query: queries.Delivery.saleSend,
    callbacks: {
      onSuccess: (data) => {
        if (data === "0000")
          showAlert("알림", "매출처리에 성공하였습니다.", {
            confirmHandler: refreshData,
          });
        else showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "매출 처리에 실패하였습니다.");
      },
    },
  });

  //classType이 9인 경우
  const { request: requestTogetherSaleSend } = useCommonMutation({
    query: queries.Together.saleSend,
    callbacks: {
      onSuccess: (data) => {
        if (data === "0000")
          showAlert("알림", "매출처리에 성공하였습니다.", {
            confirmHandler: () => refreshData(),
          });
        else showAlert("알림", data.result_message);
      },
      onError: (error) => {
        showAlert("알림", "매출 처리에 실패하였습니다.");
      },
    },
  });
  // 매출처리 요청
  const saleProcess = useCallback(
    (item, confirmationYn, classType) => {
      if (confirmationYn !== 1) {
        return showAlert(
          "알림",
          "확정되지 않은 수업은 매출처리를 할 수 없습니다."
        );
      }

      showConfirm(
        "안내",
        "매출 처리를 진행하시겠습니까? \n 처리 후 취소할 수 없습니다.",
        {
          confirmHandler: () => {
            if (classType !== 9) {
              requestSaleSend({
                option_seq: item.option_seq,
                order_seq: item.order_seq,
              });
            } else {
              requestTogetherSaleSend({
                option_seq: item.option_seq,
                order_seq: item.order_seq,
              });
            }
          },
        }
      );
    },
    [requestSaleSend, requestTogetherSaleSend]
  );

  const refundItems = useMemo(() => {
    return {
      headers: [
        { text: "" },
        { text: "회차/교재" },
        { text: "회차명/교재명" },
        { text: "정상 금액" },
        { text: "카드 결제 금액" },
        { text: "쿠폰" },
        { text: "올머니" },
        { text: "클럽 포인트" },
        { text: "클럽 마일리지" },
        { text: "클래스 일시" },
        { text: "매출 여부" },
        { text: "진행 여부" },
        { text: "" },
      ],
      wPercentList: [1, 8, 15, 8, 8, 8, 8, 8, 8, 10, 8, 8, 1],
      rows: orderOptions
        ? orderOptions.map((item, index) => [
            // { type: item.cancelable && item.lep_order_no !== null && item.option_type === 1 ? 'select disabled' : 'select' },
            { type: item.cancelable ? "select" : "select" },
            { text: item.option_type == 2 ? "교재" : `${index + 1}` },
            { text: item.option_name || "", align: "left" },
            {
              text: item.option_price
                ? `${toCurrency(item.option_price)}원`
                : "0원",
            },
            {
              text: item.payment_price
                ? `${toCurrency(item.payment_price)}원`
                : "0원",
            },
            {
              text: item.use_coupon_price
                ? `${toCurrency(item.use_coupon_price)}원`
                : "0원",
            },
            {
              text:
                item.use_earned_point || item.use_recharged_point
                  ? `${toCurrency(
                      item.use_earned_point + item.use_recharged_point
                    )}원`
                  : "0원",
            },
            {
              text: item.use_bookclub_point
                ? `${toCurrency(item.use_bookclub_point)}원`
                : "0원",
            },
            {
              text: item.use_club_mileage_sum
                ? `${toCurrency(item.use_club_mileage_sum)}원`
                : "0원",
            },
            { text: item.scheduleMonth ? item.scheduleMonth : "" },
            {
              text: item.lep_order_no ? (
                "매출 처리 완료"
              ) : (
                <>
                  매출 미처리
                  {(item.option_type === 1 || item.option_type === 3) && (
                    <LAButton
                      wPercent={100}
                      style={{ minWidth: "86px" }}
                      onClick={() =>
                        saleProcess(
                          item,
                          application.confirmation_yn,
                          classInfo?.class_type
                        )
                      }
                    >
                      매출 처리
                    </LAButton>
                  )}
                </>
              ),
            },
            {
              text:
                item.option_type == 2 ? "" : item.begin_yn ? "진행" : "미진행",
            },
          ])
        : [],
    };
  }, [orderOptions]);

  /*  const initialData = useMemo(() => {
    if (isAdmin || !refundItems?.rows) {
      return [];
    }

    return refundItems.rows.map((item, index) => {
      return {
        rowIndex: index,
        colIndex: 0,
      };
    });
  }, [refundItems?.rows]);*/

  const initialData = useMemo(() => {
    if (!cancelOrderOptions) {
      return [];
    }

    return cancelOrderOptions?.map((item, index) => {
      return {
        rowIndex: index,
        colIndex: 0,
      };
    });
  }, [cancelOrderOptions]);

  const refundSimpleTable = useSimpleTable({ selected: initialData }); // 셀렉션 초기화 예

  useEffect(() => {
    if (cancelOrderOptions?.length > 0) {
      orderOptions?.forEach((item, index) => {
        if (cancelOrderOptions?.includes(item.option_seq)) {
          refundSimpleTable.select(index, 0);
        }
      });
    }
  }, [cancelOrderOptions]);

  const selectedItems = useMemo(() => {
    const list = [];
    if (refundSimpleTable.state) {
      const selectedIndex = refundSimpleTable.state.selected.map(
        (s) => s.rowIndex
      );
      selectedIndex.forEach((si) => {
        list.push(orderOptions[si]);
      });
    }

    return list;
  }, [refundSimpleTable]);

  const options = selectedItems.map((o) => o.option_seq);

  const textbooksName = useMemo(() => {
    if (!classInfo?.textbooks) {
      return "";
    }

    let result = "";
    classInfo.textbooks.forEach((data, index) => {
      result += index > 0 ? ", " + data.option_name : data.option_name;
    });
    return result;
  }, [classInfo?.textbooks]);

  const selectPayPrice = useMemo(() => {
    let price = 0;
    if (selectedItems && application?.confirmation_yn) {
      price = selectedItems.reduce(
        (acc, item) => acc + (item.option_type == 1 ? item.payment_price : 0),
        0
      );
    }
    return price;
  }, [selectedItems]);

  const selectPoint = useMemo(() => {
    let price = 0;
    // if(selectedItems && application?.confirmation_yn){
    price = selectedItems.reduce(
      (acc, item) =>
        acc +
        (parseInt(item.use_earned_point) +
          parseInt(item.use_recharged_point) +
          parseInt(item.use_bookclub_point)),
      0
    );
    // }
    return price;
  }, [selectedItems]);

  const selectedPaymentClassPrice = useMemo(() => {
    let price = selectedItems.reduce(
      (acc, item) => acc + (item.option_type == 1 ? item.payment_price : 0),
      0
    );
    return price;
  }, [selectedItems]);

  const deliveryFee = useMemo(() => {
    let price = 0;
    if (
      !selectedItems.some(
        (item) => item.option_type == 2 && (item.lep_order_no ? false : true)
      )
    ) {
      price = order?.delivery_price;
    }
    return price;
  }, [selectedItems, order]);

  // const deliveryFee = useMemo(() =>{
  //   return application?.confirmation_yn == 1?order?.delivery_price:0
  // },[application])

  const totalTextbookPrice = useMemo(() => {
    let totalPrice = 0;
    totalPrice = orderOptions?.reduce(
      (acc, item) => acc + (item.option_type == 2 ? item.option_price : 0),
      0
    );
    return totalPrice;
  });

  const selectedTextbookPrice = useMemo(() => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedTotalOptionPrice = selectedItems.reduce(
        (acc, item) => acc + (item.option_type === 2 ? item.option_price : 0),
        0
      );
      return selectedTotalOptionPrice;
    } else {
      return 0; // 또는 다른 기본값 설정
    }
  }, [selectedItems]);

  const attendancesCount = useMemo(() => {
    if (orderOptions) {
      return orderOptions.filter(
        (oo) => oo.done_yn == 1 || oo.attendance_yn == 1
      ).length;
    }

    return 0;
  }, [orderOptions]);

  const refundClassPrice = useMemo(() => {
    const totalOptionPrice = orderOptions?.reduce(
      (acc, item) => acc + (item.option_type == 1 ? item.payment_price : 0),
      0
    );
    // const totalOptionPrice = orderOptions?.reduce((acc,item) => acc + (item.option_type == 1?item.option_price:0),0)
    // const selectedOptionPrice = selectedItems.reduce((acc,item) => acc + item.option_price,0)
    return totalOptionPrice;
  }, [orderOptions]);

  const totalClassPrice = useMemo(() => {
    const totalOptionPrice = orderOptions?.reduce(
      (acc, item) => acc + (item.option_type == 1 ? item.option_price : 0),
      0
    );
    // const totalOptionPrice = orderOptions?.reduce((acc,item) => acc + (item.option_type == 1?item.option_price:0),0)
    // const selectedOptionPrice = selectedItems.reduce((acc,item) => acc + item.option_price,0)
    return totalOptionPrice;
  }, [orderOptions]);

  const cancelablePriceFormula = useMemo(() => {
    let formual = "";
    selectedItems.map((o) => {
      if (formual !== "") formual = formual + " + ";
      formual += toCurrency(o.payment_price) + "원";
    });

    return formual;
  }, [selectedItems]);

  const cancelablePrice = useMemo(() => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedOptionPrice = selectedItems.reduce(
        (acc, item, idx) => acc + item.payment_price,
        0
      );
      return selectedOptionPrice;
    }

    return 0;
  }, [selectedItems]);

  const cancelablePriceValue = isNaN(cancelablePrice) ? 0 : cancelablePrice;

  // 교재만 seq전달
  const cancel_option = useMemo(() => {
    const cancel_options = selectedItems
      .filter((item) => item.option_type == 2)
      .map((item) => item.option_seq);
    return cancel_options;
  }, [selectedItems]);

  const refundInfo = useMemo(() => {
    // var selectedTotalOptionPrice = 0
    // if(refundItems){
    //   selectedTotalOptionPrice = selectedItems.reduce((acc,item) => acc + item.payment_price,0)
    // }
    return {
      confirmation_yn: application?.confirmation_yn,
      class_type: classInfo?.class_type || "",
      class_type_name: classInfo?.class_type_name || "",
      className: classInfo?.class_name || "",
      textbookName: textbooksName.length > 0 ? textbooksName : "-",
      // classStartDate: classInfo?.start_dt || '',
      classRound: classInfo?.curriculum_count ? classInfo?.curriculum_count : 0, // 회차
      textbookCount: classInfo?.class_type_name?.textbooks?.length
        ? classInfo.class_type_name.textbooks.length
        : 0,
      // textbookYn: classInfo?.class_type_name?.textbooks?.length > 0 ? 'Y' : 'N',
      textbookYn:
        classInfo?.textbooks?.length > 0 || classInfo?.appendix?.length > 0
          ? "Y"
          : "N",
      classStatus: application?.confirmation_yn ? "확정" : "미정",
      textbookPrice: `${toCurrency(
        totalTextbookPrice - selectedTextbookPrice
      )}원`,
      // point:  application?.confirmation_yn == 1 ? '': order?.use_earned_point ? `${toCurrency(order.use_earned_point + order.use_recharged_point)}원` : "0원",
      // deliveryFee: application?.confirmation_yn ?"0원":order?.delivery_price ? `${toCurrency(order?.delivery_price)}원` : "0원",
      deliveryFee: `${toCurrency(deliveryFee)}원`,
      totalPaymentAmount: payment?.amount ? payment?.amount : 0,
      totalClassPrice: totalClassPrice,
      confirmationYn: application?.confirmation_yn,
      payMethod: "신용카드",
      options: orderOptions,
      refundReason: isAdmin ? (
        <>
          <LAComboBox items={comboItems} />
          <LAInput layouts={{ pt: 1 }} placeholder="상세 사유 입력" />
        </>
      ) : (
        <LALabel props={{ fontWeight: "bold", fontSize: pxToRem(13) }}>
          {paymentCancel?.cancel_reason}
        </LALabel>
      ),
      currClassRound: `${attendancesCount}회`, //'수강횟수 데이터',
      selectedCurriculumCount: selectedItems.reduce(
        (acc, item) => acc + (item.option_type == 1 ? 1 : 0),
        0
      ),
      selectedTextbookCount: selectedItems.reduce(
        (acc, item) => acc + (item.option_type == 2 ? 1 : 0),
        0
      ),
      selectedOptionCount: selectedItems.length,
      refundPriceFormula: cancelablePriceFormula,
      refundPrice: cancelablePrice > 0 ? cancelablePrice : 0,
    };
  }, [cancelablePriceFormula, cancelablePrice, selectedItems]);

  // 교재 취소 요청
  const { request: requestCancelOrder } = useCommonMutation({
    query: queries.Order.cancelOrder,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "결제가 취소되었습니다.");
          handleClose();
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "결제 취소에 실패하였습니다.");
      },
    },
    initEnabled: false,
  });

  const { request: requestTogetherCancelOrder } = useCommonMutation({
    query: queries.Together.cancelOrder,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "결제가 취소되었습니다.");
          handleClose();
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "결제 취소에 실패하였습니다.");
      },
    },
    initEnabled: false,
  });

  // 교재 회수 요청 api
  const { request: requestRecall } = useCommonMutation({
    query: queries.Delivery.recallOrder,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "교재 회수 요청 완료");
          handleClose();
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "회수 요청 실패.");
      },
    },
    initEnabled: false,
  });

  // 교재 회수 요청 취소 api
  const { request: requestCancelRecallOrder } = useCommonMutation({
    query: queries.Delivery.cancelRecallOrder,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert("알림", "교재 회수 요청을 취소했습니다.");
          handleClose();
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        showAlert("알림", "회수 요청취소 실패.");
      },
    },
    initEnabled: false,
  });

  const onCancelClick = useCallback(() => {
    // if(selectedTextbookPrice > 0 ) {}
    if (!selectedItems || selectedItems.length === 0) {
      showAlert("알림", `환불 대상 회차 및 교재를 한개 이상 선택하세요.`);
      return;
    }

    //const refundAmount = imputType.state === '3' ? (cancelAmount.state - 6000) : imputType.state === '4' ? (cancelAmount.state - 3000) : (cancelAmount.state);
    if (numericCancelAmount < 0) {
      showAlert("알림", "환불금액이 마이너스가 될 수 없습니다.");
      return;
    }

    if (isNaN(numericCancelAmount)) {
      showAlert("알림", "유효한 숫자 값을 입력하세요.");
      cancelAmount.update(0);
      return;
    }

    if (cancelablePrice - numericCancelAmount < 0) {
      showAlert(
        "알림",
        "카드 결제 금액보다 환불 금액이 크므로 취소할 수 없습니다."
      );
      return;
    }

    if (selectedItems.length > 0 && application.confirmation_yn === 1) {
      const hasInvalidOrder = selectedItems.some(
        (item) => !item.lep_order_no && item.option_type === 1
      );
      if (hasInvalidOrder) {
        showAlert(
          "알림",
          "매출 처리가 안된 주문건이 남아 있습니다.\n매출처리 후 다시 시도해 주세요."
        );
        return;
      }
    }

    // if (textbookSelected == 1 ) {
    //   showAlert('알림', '교재를 선택해주세요.')
    //   return;
    // }
    //
    // if (textbookSelected == 2 ) {
    //   showAlert('알림', '교재선택을 풀어주세요.')
    //   return;
    // }

    if (cancelReason.state.length < 2) {
      showAlert("알림", `환불 사유를 2자 이상 입력하세요.`);
      return;
    }

    let selected_cancel_amount = selectedItems.reduce(
      (acc, item) => acc + item.payment_price,
      0
    );
    //const options = selectedItems.map((o) => o.option_seq);

    const hasTextbook = selectedItems.some((item) => item.option_type === 2);

    if (hasTextbook) selected_cancel_amount += order?.delivery_price;
    if (payment && order) {
      if (order.order_status == 20) {
        showAlert("알림", "취소 가능한 상태가 아닙니다.");
        return;
      }

      const isAll = payment.amount == cancelAmount.state;

      // if (imputType.state === '') {
      //   showAlert('알림', `귀책 대상을 선택해 주세요.`);
      //   return;
      // }
      //
      // if (refundFeedbackType.state === '') {
      //   showAlert('알림', `검수 결과를 선택해 주세요.`);
      //   return;
      // }
      //
      // if (refundFeedback.state === '') {
      //   showAlert('알림', `검수 피드백을 입력해 주세요.`);
      //   return;
      // }

      if (refundType.state === "") {
        showAlert("알림", `반품 옵션을 선택해 주세요.`);
        return;
      } else if (refundType.state === "1") {
        //즉시 반품
        if (imputType.state === "") {
          showAlert("알림", `귀책 대상을 선택해 주세요.`);
          return;
        }
        if (!refundReason.state) {
          showAlert("알림", `반품 사유를 입력해주세요.`);
          return;
        }
      } else if (refundType.state === "2") {
        // 교재 회수 후 취소
        if (
          exchangeTextbooks?.some(
            (item) => item.process_type === "RETURN" && item.return_status === 1
          )
        ) {
          // 1. 교재 회수 신청 중인경우
          if (imputType.state === "") {
            showAlert("알림", `귀책 대상을 선택해 주세요.`);
            return;
          }
          if (refundFeedbackType.state === "") {
            showAlert("알림", `검수 결과를 선택해 주세요.`);
            return;
          }

          if (refundFeedback.state === "") {
            showAlert("알림", `검수 피드백을 입력해 주세요.`);
            return;
          }
        } else {
          if (imputType.state === "") {
            showAlert("알림", `귀책 대상을 선택해 주세요.`);
            return;
          }
        }
      }

      if (numericCancelAmount > payment.amount) {
        showAlert(
          "알림",
          `취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${toCurrency(
            cancelablePriceValue
          )}원\n - 취소 요청금액 : ${toCurrency(cancelAmount.state)}원`
        );
        return;
      }

      if (numericCancelAmount > cancelable?.cancelable_amount) {
        showAlert(
          "알림",
          `취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${toCurrency(
            selected_cancel_amount
          )}원\n - 취소 요청금액 : ${toCurrency(cancelAmount.state)}원`
        );
        return;
      }

      if (numericCancelAmount > selected_cancel_amount) {
        showAlert(
          "알림",
          `취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${toCurrency(
            selected_cancel_amount
          )}원\n - 취소 요청금액 : ${toCurrency(numericCancelAmount)}원`
        );
        return;
      }
      /* 교재 회수 요청 상태 값이 있을 때, */
      /* if(refundFeedbackType.state === '') {
                showAlert('알림', '반품 검수 결과를 선택해주세요.');
                return
            }
            if(refundFeedback.state === '') {
                showAlert('알림', '반품 검수 피드백을 입력해 주세요.');
                return
            } */

      // 환불 옵션이 '즉시 반품'일 때
      if (refundType.state === "1") {
        showConfirm(
          "확인",
          `결제 취소를 하시겠습니까?\n 
                            - 취소 요청금액 : ${cancelAmount.state}원(${
            isAll ? "전액" : "부분"
          })\n
                            - 환불 사유 : ${cancelReason.state}\n
                            - 반품 옵션 : 즉시 반품\n
                            - 귀책 대상 : ${
                              imputType.state == "1"
                                ? "회사 귀책"
                                : imputType.state == "2"
                                ? "강사 귀책"
                                : imputType.state == "3"
                                ? "고객 귀책(왕복 배송비 고객 부담)"
                                : imputType.state == "4"
                                ? "고객 귀책(배송비 고객 부담)"
                                : imputType.state == "5"
                                ? "고객 귀책(배송비 부담 없음)"
                                : ""
                            }\n
                            - 사유 입력 : ${refundReason.state || ""}
                            `,
          {
            confirmHandler: () => {
              if (classInfo.class_type === 9) {
                requestTogetherCancelOrder({
                  order_seq: order.order_seq,
                  data: {
                    cancel_reason: cancelReason.state, //환불사유
                    cancel_amount: numericCancelAmount, //환불금액
                    cancel_options: JSON.stringify(options), //환불옵션(option_seq)
                    return_option: refundType.state, // 1
                    faults_target: imputType.state, // 귀책대상
                    return_reason: refundReason.state, // 반품사유
                    delivery_price:
                      imputType.state == "3"
                        ? 6000 //귀책대상에 따른 배송비
                        : imputType.state == "4"
                        ? 3000
                        : 0,
                  },
                });
              } else {
                requestCancelOrder({
                  order_seq: order.order_seq,
                  data: {
                    cancel_reason: cancelReason.state, //환불사유
                    cancel_amount: numericCancelAmount, //환불금액
                    cancel_options: JSON.stringify(options), //환불옵션(option_seq)
                    return_option: refundType.state, // 1
                    faults_target: imputType.state, // 귀책대상
                    return_reason: refundReason.state, // 반품사유
                    delivery_price:
                      imputType.state == "3"
                        ? 6000 //귀책대상에 따른 배송비
                        : imputType.state == "4"
                        ? 3000
                        : 0,
                  },
                });
              }
            },
          }
        );
        // 환불 옵션이 '교재 회수 후 취소'일 때
      } else if (refundType.state === "2") {
        showConfirm(
          "확인",
          `결제 취소를 하시겠습니까?\n 
                            - 취소 요청금액 : ${cancelAmount.state}원(${
            isAll ? "전액" : "부분"
          })\n
                            - 환불 사유 : ${cancelReason.state}\n
                            - 반품 옵션 : 교재 회수 후 취소\n
                            - 귀책 대상 : ${
                              imputType.state == "1"
                                ? "회사 귀책"
                                : imputType.state == "2"
                                ? "강사 귀책"
                                : imputType.state == "3"
                                ? "고객 귀책(왕복 배송비 고객 부담)"
                                : imputType.state == "4"
                                ? "고객 귀책(배송비 고객 부담)"
                                : imputType.state == "5"
                                ? "고객 귀책(배송비 부담 없음)"
                                : ""
                            }\n
                            - 반품 검수 결과 : ${
                              refundFeedbackType.state == "1"
                                ? "반품 가능"
                                : "반품불가"
                            }\n
                            - 반품 검수 피드백 : ${refundFeedback.state || ""}
                            `,
          {
            confirmHandler: () => {
              if (classInfo.class_type === 9) {
                requestTogetherCancelOrder({
                  order_seq: order.order_seq,
                  data: {
                    cancel_reason: cancelReason.state, //환불사유
                    cancel_amount: numericCancelAmount, //환불금액
                    cancel_options: JSON.stringify(options), //환불옵션(option_seq)
                    return_option: refundType.state, // 1
                    faults_target: imputType.state, // 귀책대상
                    return_reason: refundReason.state, // 반품사유
                    delivery_price:
                      imputType.state == "3"
                        ? 6000 //귀책대상에 따른 배송비
                        : imputType.state == "4"
                        ? 3000
                        : 0,
                  },
                });
              } else {
                requestCancelOrder({
                  order_seq: order.order_seq,
                  data: {
                    cancel_reason: cancelReason.state, //환불사유
                    cancel_amount: numericCancelAmount, //환불금액
                    cancel_options: JSON.stringify(options), //환불옵션
                    faults_target: imputType.state, //귀책대상
                    return_feedback_result: refundFeedbackType.state, //반품 검수 결과
                    return_feedback_desc: refundFeedback.state, // 반품 검수 내용
                    // return_textbook_seq: exchangeTextbooks?.find((item) => item.process_type === "RETURN" && item.return_status === 1).return_textbook_seq,  // 회수요청 seq
                    return_textbook_seq: returnTextbook?.return_textbook_seq,
                    delivery_price:
                      imputType.state == "3"
                        ? 6000
                        : imputType.state == "4"
                        ? 3000
                        : 0,
                  },
                });
              }
            },
          }
        );
      }
    } else {
      showAlert("알림", "결제정보가 없습니다.");
    }
  }, [order, cancelablePrice, selectedItems, cancelAmount, cancelReason]);

  /* 교재선택없이 클래스만 취소할경우(결제취소)기존 로직 분리*/
  const onClassCancelClick = useCallback(() => {
    if (!selectedItems || selectedItems.length === 0) {
      showAlert("알림", `환불 대상 회차 및 교재를 한개 이상 선택하세요.`);
      return;
    }

    if (selectedItems.length > 0 && application.confirmation_yn === 1) {
      const hasInvalidOrder = selectedItems.some(
        (item) => !item.lep_order_no && item.option_type === 1
      );
      if (hasInvalidOrder) {
        showAlert(
          "알림",
          "매출 처리가 안된 주문건이 남아 있습니다.\n매출처리 후 다시 시도해 주세요."
        );
        return;
      }
    }

    /*    if (orderOptions.length > 0 ) {
        orderOptions.forEach((item) => {
            if (!item.lep_order_no) {
            showAlert('알림', '매출 처리가 안된 주문건이 남아 있습니다.\n 매출처리후 다시 시도해 주세요.');
            return;
            }
        });
        return;
        }*/

    if (cancelReason.state.length < 2) {
      showAlert("알림", `환불 사유를 2자 이상 입력하세요.`);
      return;
    }

    // 0원 취소 필요해서 해당 로직 주석처리
    // if (cancelAmount.state == 0) {
    //   showAlert('알림', '환불 금액을 입력하세요.');
    //   return;
    // }

    //const cancelable_amount = orderOptionData.reduce((acc,item) =>  acc + (item.cancelable?item.option_price:0),0)
    let selected_cancel_amount = selectedItems.reduce(
      (acc, item) => acc + item.payment_price,
      0
    );
    const options = selectedItems.map((o) => o.option_seq);
    let hasTextbook = false;
    selectedItems.forEach((item) => {
      if (item.option_type == 2) hasTextbook = true;
    });

    if (hasTextbook) selected_cancel_amount += order?.delivery_price;

    if (payment && order) {
      if (order.order_status != 10) {
        showAlert("알림", "취소 가능한 상태가 아닙니다.");
        return;
      }

      const isAll = payment.amount == cancelAmount.state;

      if (cancelAmount.state > payment.amount) {
        showAlert(
          "알림",
          `취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${toCurrency(
            cancelablePriceValue
          )}원\n - 취소 요청금액 : ${toCurrency(cancelAmount.state)}원`
        );
        return;
      }

      if (cancelAmount.state > cancelable?.cancelable_amount) {
        showAlert(
          "알림",
          `취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${toCurrency(
            selected_cancel_amount
          )}원\n - 취소 요청금액 : ${toCurrency(cancelAmount.state)}원`
        );
        return;
      }

      if (cancelAmount.state > selected_cancel_amount) {
        showAlert(
          "알림",
          `취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${toCurrency(
            selected_cancel_amount
          )}원\n - 취소 요청금액 : ${toCurrency(cancelAmount.state)}원`
        );
        return;
      }

      // if(cancelAmount.state > (selected_cancel_amount - panaltyPrice)){
      //   showAlert('알림', `3.취소 가능한 금액을 초과하였습니다.\n - 취소 가능금액 : ${selected_cancel_amount - panaltyPrice}원\n - 위약금 : ${panaltyPrice}원\n - 취소 요청금액 : ${cancelAmount.state}원`);
      //   return
      // }

      showConfirm(
        "확인",
        `결제 취소를 하시겠습니까?\n - 취소 요청금액 : ${
          cancelAmount.state
        }원(${isAll ? "전액" : "부분"})\n - 사유 : ${cancelReason.state}`,
        {
          confirmHandler: () => {
            if (classInfo.class_type === 9) {
              requestTogetherCancelOrder({
                order_seq: order.order_seq,
                data: {
                  cancel_reason: cancelReason.state,
                  cancel_amount: cancelAmount.state,
                  cancel_options: JSON.stringify(options),
                },
              });
            } else {
              requestCancelOrder({
                order_seq: order.order_seq,
                data: {
                  cancel_reason: cancelReason.state,
                  cancel_amount: cancelAmount.state,
                  cancel_options: JSON.stringify(options),
                },
              });
            }
          },
        }
      );
    } else {
      showAlert("알림", "결제정보가 없습니다.");
    }
  }, [order, cancelablePrice, selectedItems, cancelAmount, cancelReason]);

  const clickToTracking = useCallback((item) => {
    // 배송추적
    setContextWaybill(item);
    delivertTrackingModalPopup.visible(true);
  }, []);

  const trackingClose = useCallback(() => {
    delivertTrackingModalPopup.handleClose();
  }, [delivertTrackingModalPopup]);

  const onRecallClick = useCallback(() => {
    // 교재 회수 요청
    /*   if(delivery?.lep_delivery_status !== '140') {
        showAlert('알림', '완료되지 않은 배송이므로 반품할 수 없습니다.')
        return;
        }*/

    if (selectedItems.length > 0 && application.confirmation_yn === 1) {
      const hasInvalidOrder = selectedItems.some(
        (item) => !item.lep_order_no && item.option_type === 1
      );
      if (hasInvalidOrder) {
        showAlert(
          "알림",
          "매출 처리가 안된 주문건이 남아 있습니다.\n매출처리 후 다시 시도해 주세요."
        );
        return;
      }
    }

    if (!cancel_option || cancel_option.length === 0) {
      showAlert("알림", "교재를 먼저 선택하세요.");
      return;
    }

    // if (cancelReason.state.length < 2) {
    //     showAlert('알림', `환불 사유를 2자 이상 입력하세요.`);
    //     return;
    // }

    if (refundType.state === "") {
      showAlert("알림", `반품 옵션을 선택해 주세요.`);
      return;
    } else if (refundType.state === "2") {
      if (imputType.state === "") {
        showAlert("알림", `귀책 대상을 선택해 주세요.`);
        return;
      }
    }
    // const refundAmount = imputType.state === '3' ? (cancelAmount.state - 6000) : imputType.state === '4' ? (cancelAmount.state - 3000) : (cancelAmount.state);
    if (cancelablePrice - cancelAmount.state < 0) {
      showAlert(
        "알림",
        "카드 결제 금액보다 환불 금액이 크므로 취소할 수 없습니다."
      );
      return;
    }

    showConfirm(
      "안내",
      `
                교재 회수를 요청합니다.
                교재 회수 요청 취소는 당일만 가능하며,
                요청일 다음날 회수 송장이 발행됩니다.

                상품 검수가 완료되면 취소/반품
                승인을 진행해주세요.
                `,
      {
        confirmHandler: () => {
          requestRecall({
            order_seq: order.order_seq,
            data: {
              return_option: refundType.state, // 반품 옵션
              faults_target: imputType.state, // 귀책 대상
              cancel_options: JSON.stringify(options), // 교재 seq
              cancel_amount: cancelAmount.state, // 환불 금액
              cancel_reason: cancelReason.state, // 환불사유
            },
          });
        },
      }
    );
  }, [order, cancelablePrice, selectedItems, cancelAmount, cancelReason]);

  const onRequestCancelClick = useCallback(() => {
    // 교재 회수 요청 취소
    showConfirm("안내", "교재 회수 요청을 취소하시겠습니까?", {
      confirmHandler: () => {
        // API requset
        requestCancelRecallOrder({
          order_seq: order.order_seq,
          return_textbook_seq: exchangeTextbooks?.find(
            (item) => item.process_type === "RETURN" && item.return_status === 1
          ).return_textbook_seq,
        });
      },
    });
  });

  // 회수요청중 상태인경우 반품옵션,귀책대상 값 셋팅 / 회수요청 승인 케이스 검수 피드백 활성화
  useEffect(() => {
    if (returnTextbook) {
      imputType.select(returnTextbook?.faults_target);
      refundType.select("2");
      setCancelOrderOptions(
        returnTextbook?.order_cancel_temp_json?.cancel_options
      );
    }

    if (order?.return_textbook_complete_yn === "Y") {
      setDisabledType(false);
    }
  }, [returnTextbook]);

  const refundMethodText = useMemo(() => {
    let result = "";

    if (data?.payment?.pay_method) {
      result +=
        `${data.payment.pay_method}` +
        (data.payment.card_name?.length > 0 &&
        data.payment.card_number?.length > 0
          ? ` - ${data.payment.card_name}(${data.payment.card_number})`
          : "");
    }

    /*5.23 올머니 환불 정보에서 노출 제외
        if (data?.order?.use_earned_point > 0) {
        result += result.length > 0 ? ", 올머니" : "올머니"
        }*/

    if (data?.order?.coupon) {
      result += result.length > 0 ? ", 쿠폰" : "쿠폰";
      if (data?.order?.coupon?.coupon_name)
        result += ` - ${data?.order?.coupon?.coupon_name} (${data?.order?.coupon?.coupon_no})`;
    }

    if (data?.order?.use_bookclub_point > 0) {
      result += result.length > 0 ? ", 클럽포인트" : "클럽포인트";
    }

    if (data?.order?.use_club_mileage_sum > 0) {
      result += result.length > 0 ? ", 클럽마일리지" : "클럽마일리지";
    }

    return result;
  }, [data]);

  const payMethodText = useMemo(() => {
    let result = "";
    if (data?.payment?.pay_method) {
      result +=
        `${data.payment.pay_method}` +
        (data.payment.card_name?.length > 0 &&
        data.payment.card_number?.length > 0
          ? ` - ${data.payment.card_name}(${data.payment.card_number})`
          : "");
    }

    if (data?.order?.coupon) {
      result += result.length > 0 ? ", 쿠폰" : "쿠폰";
      if (data?.order?.coupon?.coupon_name)
        result += ` - ${data?.order?.coupon?.coupon_name} (${data?.order?.coupon?.coupon_no})`;
    }

    if (data?.order?.use_earned_point > 0) {
      result += result.length > 0 ? ", 올머니" : "올머니";
    }

    if (data?.order?.use_bookclub_point > 0) {
      result += result.length > 0 ? ", 클럽포인트" : "클럽포인트";
    }

    if (data?.order?.use_club_mileage_sum > 0) {
      result += result.length > 0 ? ", 클럽마일리지" : "클럽마일리지";
    }

    return result;
  }, [data]);

  const handleReset = useCallback(() => {
    // 취소 처리 로직
    cancelAmount.reset(); // cancelAmount 초기화
    cancelReason.reset(); // cancelReason 초기화
    refundType.reset();
    imputType.reset();
    refundFeedbackType.reset();
    refundFeedback.reset();
    refundSimpleTable.reset();
  }, []);

  const handleCloseModal = () => {
    if (refundType.state === "2" && order?.order_status === 21) {
      handleClose();
      cancelAmount.reset();
      refundSimpleTable.reset();
    } else {
      handleReset();
      handleClose();
    }
  };

  return (
    <>
      <LAModal
        title="카드 결제 취소"
        width="80%"
        height="90%"
        show={show}
        onClose={handleCloseModal}
      >
        <LACard>
          <LACardTitle title="결제 정보" />

          <LAProperty name="결제번호">
            <LALabel>
              {data && data.payment ? data.payment.merchant_uid : ""}{" "}
              {data && data.payment && data.payment.imp_uid
                ? `(${data.payment.imp_uid})`
                : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="결제일시">
            <LALabel>
              {data && data.payment
                ? moment(data.payment.created_dt).format(
                    "YYYY-MM-DD (dd) A hh:mm"
                  )
                : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="결제자 명">
            <LALabel>
              {data && data.payment ? data.payment.buyer_name : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="결제자 연락처">
            <LALabel>
              {data && data.payment ? data.payment.buyer_tel : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="이메일">
            <LALabel>
              {data && data.payment ? data.payment.buyer_email : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="카드 결제 금액">
            <LALabel>
              {data && data.order ? `${data.order.payment_price}원` : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="결제 수단">
            <LALabel>{payMethodText}</LALabel>
          </LAProperty>
          <LADivider pt={2} />
          <LAProperty name="클래스명">
            <LALabel>
              {data && data.class ? `${data.class.class_name}` : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="클래스 유형">
            <LALabel>
              {data && data.class ? `${data.class.class_type_name}` : ""}
            </LALabel>
          </LAProperty>
          <LAProperty name="클래스 확정 여부">
            <LALabel>
              {data && data.application && data.application.confirmation_yn == 1
                ? "확정"
                : "미확정"}
            </LALabel>
          </LAProperty>
        </LACard>

        <LACard next>
          <LACardTitle title="결제 회차 및 교재" />
          <LAGridContainer layouts={{ mt: 2 }}>
            <OrderRefundHistoryTable
              refundInfo={refundInfo}
              refundItems={refundItems}
              refundSimpleTable={refundSimpleTable}
            />
          </LAGridContainer>
        </LACard>

        <LACard next>
          <LACardTitle
            title="카드 환불 금액"
            warning="자동 계산된 결제금액을 확인 후 최종 취소금액을 정확히 입력해 주세요."
          />
          <LAGridContainer pt={2}>
            <OrderRefundPriceListView
              refundInfo={refundInfo}
              refundItems={selectedItems}
              cancelAmount={cancelAmount}
              cancelReason={cancelReason}
              imputType={imputType.state === "" ? "" : imputType.state}
              refundFeedbackType={
                refundFeedbackType.state === "" ? "" : refundFeedbackType.state
              }
              isAdmin={isAdmin}
              data={data}
            />
          </LAGridContainer>
        </LACard>

        {(selectedTextbookPrice > 0 ||
          exchangeTextbooks?.filter(
            (item) => item.process_type === "RETURN" && item.return_status == 1
          ).length > 0) && (
          <LACard next>
            <LACardTitle title="교재 반품" />
            <LAGridContainer layouts={{ ml: 2 }}>
              <LAProperty name="반품 옵션" required>
                <LAComboBox
                  wPercent={30}
                  placeholder={"반품 옵션 선택"}
                  items={[
                    { key: "1", value: "즉시 반품" },
                    { key: "2", value: "교재 회수 후 취소" },
                  ]}
                  value={
                    exchangeTextbooks?.find(
                      (item) => item.process_type === "RETURN"
                    )
                      ? "2"
                      : refundType.state
                  }
                  onChange={refundType.handleChange}
                  disabled={
                    exchangeTextbooks?.find(
                      (item) => item.process_type === "RETURN"
                    )
                      ? true
                      : false
                  } // 반품 상태 (1:요청, 2:취소, 3:처리중, 4:승인)
                />
              </LAProperty>
              <LAProperty name="귀책 대상" required>
                <LAComboBox
                  wPercent={30}
                  placeholder={"귀책 대상 선택"}
                  items={[
                    { key: "1", value: "회사 귀책" },
                    { key: "2", value: "강사 귀책" },
                    { key: "3", value: "고객 귀책(왕복 배송비 고객 부담)" },
                    { key: "4", value: "고객 귀책(배송비 고객 부담)" },
                    { key: "5", value: "고객 귀책(배송비 부담 없음)" },
                  ]}
                  value={imputType.state}
                  onChange={imputType.handleChange}
                />
              </LAProperty>

              {refundType.state === "1" && (
                <LAProperty name="반품 입력" required>
                  <LATextField
                    wPercent={50}
                    placeholder="사유를 입력해주세요."
                    layouts={{
                      sx: {
                        width: "60rem",
                        overflowWrap: "break-word",
                        maxLength: 3000,
                      },
                    }}
                    value={refundReason.state}
                    onChange={refundReason.handleChange}
                  />
                </LAProperty>
              )}
              {/*반품 옵션 (1: 즉시반품, 2;교재 회수 후 취소)*/}
              {refundType.state === "2" && (
                <>
                  <LAProperty name="반품 회수 송장">
                    <LALabel>
                      {exchangeTextbooks
                        ?.filter((item) => item.process_type === "RETURN")
                        .map((item) => {
                          const content = item.waybill_company_name
                            ? `${item.waybill_company_name}(${
                                item.waybill_no || "자동 생성 예정"
                              })`
                            : "-";
                          return getLinkTextCell(
                            content,
                            null,
                            clickToTracking,
                            item
                          );
                        })}
                      {/*{!exchangeTextbooks?.filter((item) => item.process_type === "RETURN").length && (
                                                <span>CJ대한통운(자동 생성 예정)</span>
                                            )} */}
                    </LALabel>
                  </LAProperty>
                  <LAProperty name="반품 검수 피드백" required>
                    <LAGridContainer>
                      <LAComboBox
                        wPercent={15}
                        placeholder={"검수 결과"}
                        items={[
                          { key: "1", value: "반품 가능" },
                          { key: "2", value: "반품 불가" },
                        ]}
                        value={refundFeedbackType.state}
                        onChange={refundFeedbackType.handleChange}
                        disabled={disabledType}
                      />
                      <LAInput
                        wPercent={80}
                        layouts={{ pl: { sm: 1 } }}
                        value={refundFeedback.state}
                        onChange={refundFeedback.handleChange}
                        disabled={disabledType}
                      />
                    </LAGridContainer>
                    <LAWarningLabel wPercent={80} layouts={{ pl: { sm: 0 } }}>
                      교재 회수 완료 후 입력 가능
                    </LAWarningLabel>
                  </LAProperty>
                </>
              )}
            </LAGridContainer>
          </LACard>
        )}

        <LACard next>
          <LACardTitle
            title="환불 정보"
            warning="환불은 결제 방법으로 처리됩니다."
          />
          <LAProperty name="환불 수단">
            <LALabel>{refundMethodText}</LALabel>
          </LAProperty>
        </LACard>

        <LAGridContainer layouts={{ pt: { sm: 3 } }}>
          <LALabel wPercent={35}></LALabel>
          <LAButton
            wPercent={10}
            variant={"outlined"}
            layouts={{ pr: { sm: 0.5 } }}
            onClick={() => {
              if (refundType.state === "2" && order?.order_status === 21) {
                handleClose();
                cancelAmount.reset();
                refundSimpleTable.reset();
              } else {
                handleReset();
                handleClose();
              }
            }}
          >
            취소
          </LAButton>

          {/* 교재 선택없이 클래스만 결제취소 */}
          {selectedItems?.length > 0 && selectedTextbookPrice == 0 && (
            <LAButton
              wPercent={10}
              layouts={{ pl: { sm: 0.5 } }}
              onClick={onClassCancelClick}
            >
              결제 취소
            </LAButton>
          )}
          {/* 즉시 반품 */}
          {refundType?.state === "1" &&
            selectedTextbookPrice !== undefined &&
            selectedTextbookPrice > 0 && (
              <LAButton
                wPercent={15}
                layouts={{ pl: { sm: 0.5 } }}
                onClick={onCancelClick}
              >
                결제 취소 및 반품 신청
              </LAButton>
            )}
          {/* 교재 회수 요청*/}
          {refundType?.state === "2" &&
            order?.order_status == 10 &&
            selectedTextbookPrice !== undefined &&
            selectedTextbookPrice > 0 && (
              <LAButton
                wPercent={15}
                layouts={{ pl: { sm: 0.5 } }}
                onClick={onRecallClick}
              >
                교재 회수 요청
              </LAButton>
            )}
          {/* 교재 회수 요청 취소(당일만 가능) */}
          {exchangeTextbooks?.some((item) => item.process_type === "RETURN") &&
            btnStatus == "1" && (
              <LAButton
                wPercent={15}
                layouts={{ pl: { sm: 0.5 } }}
                onClick={onRequestCancelClick}
              >
                교재 회수 요청 취소
              </LAButton>
            )}
          {/*취소반품 승인 : 하루 체크로 관리*/}
          {/*{exchangeTextbooks?.some((item) => item.process_type === "RETURN" && item.return_status === 1) && btnStatus=='2' && //2로 바꿔주기*/}
          {/*  (<LAButton wPercent={10} layouts={{ pl: { sm: 0.5 } }} onClick={onCancelClick}>취소/반품 승인</LAButton>)*/}
          {/*}*/}
          {/*취소반품 승인 : data로 관리*/}
          {order?.return_textbook_complete_yn === "Y" &&
            order?.order_status == 21 && (
              <LAButton
                wPercent={15}
                layouts={{ pl: { sm: 0.5 } }}
                onClick={onCancelClick}
              >
                취소/반품 승인
              </LAButton>
            )}
          <LALabel wPercent={35}></LALabel>
        </LAGridContainer>
      </LAModal>

      <DeliveryTrackingModal
        context={contextWaybill}
        show={delivertTrackingModalPopup.state}
        handleClose={trackingClose}
      />
    </>
  );
}

export default OrderRefundModal;
