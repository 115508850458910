import React, { useMemo } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import LAGridItem from './LAGridItem';
import LAGridContainer from './LAGridContainer';
import WarnningIcon from '@mui/icons-material/ErrorOutline';

function LAWarningLabel({
  wPercent = 100,
  layouts = {},
  props = {},
  color = 'success',
  children,
}) {
  const textList = useMemo(() => {
    if (!children) {
      return '';
    }
    return children.split('\n');
  }, [children]);

  return (
    <LAGridItem wPercent={wPercent} layouts={layouts}>
      <MDBox verticalAlign="center">
        <WarnningIcon
          sx={{ pt: 1, mr: 0.2, width: 18, height: 18 }}
          color={color}
        />
        {textList.map((item, index) =>
          index > 0 ? (
            <LAGridContainer key={index} layouts={{ pl: 2.4, pt: index === 1 ? 3 : 2 }}>
              <MDTypography
                mt={-5}
                variant="overline"
                fontWeight="bold"
                color={color}
              >
                {item}
              </MDTypography>
            </LAGridContainer>
          ) : (
            <MDTypography key={index} variant="overline" fontWeight="bold" color={color}>
              {item}
            </MDTypography>
          ),
        )}
      </MDBox>
    </LAGridItem>
  );
}

export default React.memo(LAWarningLabel);
