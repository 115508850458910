import React, { useState, useCallback, useMemo, useEffect } from "react";
import DashboardNavbar from "modules/Navbars/DashboardNavbar";
import { getTodayDateText } from "common/utils";
import MDBox from "components/MDBox";
import EmptyImage from "assets/images/empty.png";
import usePopAlert from "common/components/popAlert/hooks/usePopAlert";
import { API_BASE_URL } from "api/AppConfig";
import {
  LAModal,
  LACard,
  LACardTitle,
  LADataTable,
  LAGridContainer,
  LAGridItem,
  LAProperty,
  LALabel,
  LARadioGroup,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  LADayOfTheWeek,
  LAImageListItem,
  LAImageList,
  LATextEditor,
  LAKeywords,
  LATabSelector,
  LAFileAttachment,
  useInput,
  useDatePicker,
  useComboBox,
  useFileAttachment,
  useRadioGroup,
  getTextCell,
  getNormalButton,
  useModal,
} from "modules/Widgets";
import { useApiContext } from "common/context/ApiContext";
import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from "common/hooks/useCommonMutation";
import OrderDetailModal from "pages/Order/components/OrderDetailModal";
import moment from "moment";
import { useLoginContext } from "common/context/MemberContext";
import DownloadReasonModal from "pages/Order/components/DownloadReasonModal";

function CouponDetailModal({ show, handleClose, couponSeq, couponName }) {
  const { queries } = useApiContext();
  const { loginState } = useLoginContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [rows, setRows] = useState([]);
  const [selectedOrderSeq, setSelectedOrderSeq] = useState(0);
  const orderDetailModal = useModal(false);
  const downloadReasonModal = useModal(false);

  const onOrderDetailClick = useCallback(
    (data) => {
      setSelectedOrderSeq(data.order_seq);
      orderDetailModal.visible(true);
    },
    [orderDetailModal.visible]
  );

  const clickDownload = () => {
    downloadReasonModal.visible(true);
  };

  const columns = useMemo(() => {
    return [
      { Header: "No", accessor: "no", width: "4%" },
      { Header: "등록 일시", accessor: "regist_date", width: "10%" },
      { Header: "쿠폰 번호", accessor: "coupon_no", width: "8%" },
      { Header: "결제 번호", accessor: "order_no", width: "8%" },
      { Header: "결제 일시", accessor: "order_date", width: "10%" },
      { Header: "주체", accessor: "brand_type", width: "6%" },
      { Header: "클래스 명", accessor: "class_name", width: "17%" },
      { Header: "결제자 명", accessor: "payer_name", width: "8%" },
      { Header: "학습자 명", accessor: "learner_name", width: "8%" },
      { Header: "쿠폰 상태", accessor: "coupon_status", width: "8%" },
      { Header: "취소 이력", accessor: "cancel_yn", width: "8%" },
      { Header: "상세", accessor: "detail", width: "5%" },
    ];
  }, []);

  const dataToRow = useCallback(
    (data, index) => {
      return {
        no: getTextCell(`${index}`),
        regist_date: getTextCell(
          data.issuance_dt
            ? moment(data.issuance_dt).format("YYYY-MM-DD(dd) HH:mm")
            : ""
        ),
        coupon_no: getTextCell(data.coupon_no),
        order_no: getTextCell(data.order_no),
        order_date: getTextCell(
          data.pay_dt ? moment(data.pay_dt).format("YYYY-MM-DD(dd) HH:mm") : ""
        ),
        class_name: getTextCell(data.class_name),
        brand_type: getTextCell(data.brand_type_name || ""),
        payer_name: getTextCell(data.buyer_name),
        learner_name: getTextCell(data.user_name),
        coupon_status: getTextCell(
          data.coupon_status == 0
            ? "미사용"
            : data.coupon_status == 1
            ? "사용"
            : "만료"
        ),
        cancel_yn: getTextCell(data.cancel_yn ? "O" : "X"),
        detail: getNormalButton("상세", data, onOrderDetailClick),
      };
    },
    [onOrderDetailClick]
  );

  const { request: requestUsedCoupons } = useCommonQuery({
    query: queries.Coupon.getUsedCouponList,
    params: {
      id: couponSeq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data && data.result_code === "0000") {
          console.log(data.result_data);
          setRows(
            data.result_data
              ? data.result_data.map((resultData, index) =>
                  dataToRow(resultData, data.result_data.length - index)
                )
              : []
          );
        } else {
          showAlert("알림", data.result_message);
          setRows([]);
        }
      },
      onError: (error) => {
        showAlert("알림", "사용한 쿠폰 목록 조회에 실패했습니다.");
        setRows([]);
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    requestUsedCoupons();
  }, []);

  const [yyyymm, setYYYYMM] = useState("YYYYMM");

  return (
    <LAModal
      title="쿠폰 사용 수"
      width="90%"
      height="80%"
      show={show}
      onClose={handleClose}
    >
      <LACard>
        <LACardTitle
          title={couponName}
          customUi={
            <LAGridContainer>
              <LALabel wPercent={70}></LALabel>
              <LAButton
                wPercent={30}
                onClick={clickDownload}
                disabled={rows.length === 0}
              >
                엑셀 다운로드
              </LAButton>
            </LAGridContainer>
          }
        />

        <LAGridContainer>
          <LADataTable columns={columns} rows={rows} />
        </LAGridContainer>
      </LACard>

      <LAGridContainer layouts={{ pt: 3 }}>
        <LALabel wPercent={43}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ mr: 1 }}
          variant={"outlined"}
          btnPaddingY={12}
          onClick={handleClose}
        >
          닫기
        </LAButton>
        <LALabel wPercent={42}></LALabel>
      </LAGridContainer>

      <OrderDetailModal
        orderSeq={selectedOrderSeq}
        show={orderDetailModal.state}
        handleClose={orderDetailModal.handleClose}
      />

      <DownloadReasonModal
        show={downloadReasonModal.state}
        handleClose={downloadReasonModal.handleClose}
        downKey={"couponUsedList"}
        couponSeq={couponSeq}
      />
    </LAModal>
  );
}

export default CouponDetailModal;
