

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// components
import MDBox from "components/MDBox";


function BasicLayout({ image, children }) {
  return (
    <>
      <MDBox px={1} width="100%" height="85vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item sm={8}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  // image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
