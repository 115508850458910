import React, { useState, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import pxToRem from 'assets/theme/functions/pxToRem';
import { LAButton, LAGridContainer, LALabel } from 'modules/Widgets';
import { LAInput } from 'modules/Widgets';

function CategoryListItem({
    title,
    index,
    selectedIndex,
    handleListItemClick,
    onMoveUp,
    onMoveDown,
    onDeleteClick
}) {
    {/* <LAGridContainer layouts={{ sx: { display: "flex", alignItems: "center", p: "0.3rem" }}}>
            <ListItemButton 
                selected={selectedIndex.category_seq === index} 
                onClick={(event) => handleListItemClick(event, selectedIndex)}
                sx={{ 
                    '&:hover': { 
                        backgroundColor: '#eeeeee' 
                    },
                    '&.Mui-focused, &.Mui-selected:hover, &.Mui-selected, &.Mui-selected.Mui-focused': {
                        backgroundColor: '#e8f0fe',
                    },
                    width: "50%",
                    height: "2rem",
                    mt: "0.5rem",
                    mb: "0.25rem"
                }}
            >
                <ListItemText primary={title} primaryTypographyProps={{ sx: { fontSize: "0.8rem", minWidth: "50rem" }}} />
            </ListItemButton>
            <LAButton variant="outlined" layouts={{ backgroundColor: "#ffffff", fontSize: "0.8rem", maxWidth: "5rem !important", pl: "1rem"}} onClick={(event) => onMoveUp(event, selectedIndex.category_seq)}>▲</LAButton>
            <LAButton variant="outlined" layouts={{ backgroundColor: "#ffffff", fontSize: "0.8rem", maxWidth: "5rem !important", pl: "0.1rem", pr: "1rem"}} onClick={(event) => onMoveDown(event, selectedIndex.category_seq)}>▼</LAButton>
            <LAButton layouts={{ fontSize: "0.8rem", maxWidth: "5rem !important", pl: "0.1rem"}} onClick={(event) => onDeleteClick(event, selectedIndex.category_seq)}>삭제</LAButton>
        </LAGridContainer> */}

    return (
        <ListItemButton 
            selected={selectedIndex.category_seq === index} 
            onClick={(event) => handleListItemClick(event, selectedIndex)}
            sx={{ 
                '&:hover': { 
                    backgroundColor: '#eeeeee' 
                },
                '&.Mui-focused, &.Mui-selected:hover, &.Mui-selected, &.Mui-selected.Mui-focused': {
                    backgroundColor: '#e8f0fe',
                },
                height: "2.5rem",
            }}
        >
            <ListItemText primary={title} primaryTypographyProps={{ sx: { fontSize: "0.8rem", minWidth: "50rem" }}} />
        </ListItemButton>
    );
}

export default React.memo(CategoryListItem);
