import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import pxToRem from 'assets/theme/functions/pxToRem';
import CategoryLinkItem from './CategoryLinkItem';

function CategoryLinkView({
    key,
    selectedIndex,
    child,
    handleListItemClick,
    onDeleteClick
}) {
    
    return (
        <List 
            key={key}
            sx={{
                overflow: 'hidden',
                borderBottom: "0.5px solid #d5d5d5" 
                // maxHeight: 200,
            }}
        >
            <CategoryLinkItem
                index={selectedIndex}
                title={child.menu_title}
                selectedIndex={child}
                handleListItemClick={handleListItemClick}
                onDeleteClick={onDeleteClick}
            />
        </List>
    );
}

export default React.memo(CategoryLinkView);
