import { METHOD } from 'common/hooks/useAxios';

const baseURL = '/admin/terms';

const TermsApi = (requestFunc) => {
  return {
    Terms: {
        getTerms: {
          key: 'getTerms',
          queryFn: (params) => requestFunc(METHOD.GET, `${baseURL}`, params),
        },
        registTerms: {
          key: 'registTerms',
          queryFn: (params) => requestFunc(METHOD.POST, `${baseURL}`, params),
        },
        updateTerms: {
          key: 'updateTerms',
          queryFn: (params) => requestFunc(METHOD.PATCH, `${baseURL}/${params.terms_seq}`, params),
        },
      }
  };
};

export default TermsApi;