import React, { useState, useCallback, useEffect } from 'react';
import DashboardNavbar from 'modules/Navbars/DashboardNavbar';

import { useParams, useNavigate } from 'react-router-dom';
import { useApiContext } from 'common/context/ApiContext';
import useCommonQuery from 'common/hooks/useCommonQuery';

import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';

import { getTodayDateText, dateTimeToString } from 'common/utils';

import ProfileList from './ProfileList';

import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LALabel,
  LAButton,
  LAInput,
  LATextEditor,
  LARadioGroup,
  LASimpleTable,
  LAListView,
} from 'modules/Widgets';

const managementMemoTableData = {
  headers: [],
  wPercentList: [55, 10, 10, 15, 10],
  rows: [
    [
      {
        text: '마케팅 미동의로 변경 요청 - 변경 완료',
        align: 'left',
      },
      {
        text: '윤민지 (minji)',
      },
      {
        text: 'CS관리자',
      },
      {
        text: '2022-03-18 13:34',
      },
      {
        text: '삭제',
        type: 'button',
        // callback: {
        //   onClick: () => console.log('click'),
        // },
      },
    ],
  ],
  rowBackgroundColor: '#eeeeee',
};

function AccountListDetail() {
  let { seq } = useParams();
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [member, setMember] = useState({});
  const [profiles, setProfiles] = useState({});

  useEffect(() => {
    requestMember();
  }, []);

  function setMemberInfo(member) {
    setMember(member);
    if(member.users)
      setProfiles(member.users);
  }

  const naivgateBack = () => {
    navigate(-1, { replace: true });
  };

  const getDateTimeText = (value) => {
    return new Date(value.replaceAll('Z', ''));
  };

  const { request: requestMember } = useCommonQuery({
    query: queries.Member.getProfile,
    params: {
      member_seq: seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data && data.result_code === '0000') {
          if(data.result_data) {
            setMemberInfo(data.result_data);
          }
        } else {
          if(data && data.result_message) {
            showAlert('알림', data.result_message);
          } else {
            // showAlert('알림', '회원 목록 조회에 실패했습니다.');
          }
        }
      },
      onError: (error) => {
        showAlert('알림', '회원 목록 조회에 실패했습니다.');
      },
    },
    initEnabled: false,
  });
  
  return (
    <>
      <DashboardNavbar title="회원 상세" />

      <LACard>
        <LACardTitle title="회원 기본 정보" />
        <LAProperty name="이름">
          <LALabel wPercent={40}>{member.member_name?member.member_name:''}</LALabel>
        </LAProperty>
        <LAProperty name="연락처">
          <LALabel wPercent={40} clickable="false">
            {member&&member.phone?member.phone:''}
          </LALabel>
        </LAProperty>
        {/* <LAProperty name="아이디">
          <LALabel wPercent={40} clickable="true" content={`kimw1234@1234`}>
          {member&&member.email?member.email:''}
          </LALabel>
        </LAProperty> */}
        {/* <LAProperty name="회원 등급">
              <LALabel wPercent={40}>어썸 올</LALabel>
            </LAProperty> */}
        <LAProperty name="회원 상태">
          <LALabel wPercent={40}>{
        member.state 
          ? '정상' 
          : member.dormancy_yn
            ? '휴면'
            : member.expired_yn
            ? ''
            : member.resigned_yn
            ? ''
            : ''} 회원</LALabel>
        </LAProperty>
        <LAProperty name="가입일">
          <LALabel wPercent={40}>{member.created_dt ? dateTimeToString(getDateTimeText(member.created_dt)) : ''}</LALabel>
        </LAProperty>
        <LAProperty name="마케팅 동의">
          <LALabel wPercent={40}>{member.marketing_agree_yn ? '동의' : '미동의'}</LALabel>
        </LAProperty>
        <LAProperty name="최근 방문일시">
          <LALabel wPercent={40}>{member.signin_dt ? dateTimeToString(getDateTimeText(member.signin_dt)) : '' }</LALabel>
        </LAProperty>
        {/* <LAProperty name="로그인 IP">
          <LALabel wPercent={40}>175.124.154.63</LALabel>
        </LAProperty> */}
        {/* <LAProperty name="최근 방문기기">
          <LALabel wPercent={40}>PC</LALabel>
        </LAProperty> */}
        {/* <LAProperty name="약관 동의">
          <LALabel wPercent={40}>동의 (변경일: 2022-04-15)</LALabel>
        </LAProperty> */}
      </LACard>

      <LACard layouts={{ pt: 1 }}>
        <LACardTitle title="결제 정보" />
        <LAProperty name="누적 구매 금액">
          <LALabel wPercent={100}>{member.order_price ? member.order_price : 0}원</LALabel>
        </LAProperty>
        <LAProperty name="누적 환불 금액">
          <LALabel wPercent={100}>{member.refund_price ? member.refund_price : 0}원</LALabel>
        </LAProperty>
        <LAProperty name="현재 포인트">
          <LALabel wPercent={100}>{member.point ? member.point : 0} 포인트</LALabel>
        </LAProperty>
      </LACard>

      {
        profiles.length > 0?<ProfileList profiles={profiles} />:''
      }
      

      <LACard layouts={{ pt: 1 }}>
        <LACardTitle title="관리 메모" />
        <LAProperty name="관리 메모">
          <LAGridContainer>
            <LAInput
              wPercent={90}
              placeholder="관리 메모 입력"
              layouts={{ pr: 1 }}
            />
            <LAButton wPercent={10}>등록</LAButton>
          </LAGridContainer>
        </LAProperty>
        <LAGridContainer pt={1}>
          <LASimpleTable tableData={managementMemoTableData} />
        </LAGridContainer>
      </LACard>

      <LAGridContainer layouts={{ pt: { sm: 1 } }}>
        <LALabel wPercent={35}></LALabel>
        <LAButton
          wPercent={15}
          layouts={{ pr: { sm: 1 } }}
          variant={'outlined'}
          onClick={naivgateBack}
        >
          목록
        </LAButton>
        {/* <LAButton wPercent={15} layouts={{ pl: { sm: 1 } }}>
          저장
        </LAButton>
        <LALabel wPercent={35}></LALabel> */}
      </LAGridContainer>
    </>
  );
}

export default AccountListDetail;
