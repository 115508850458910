import React, { useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import OneDayScheduleList from './OneDayScheduleList';
import { getTodayDateText } from 'common/utils';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
  LACard,
  LACardTitle,
  LAGridContainer,
  LAProperty,
  LAButton,
  LAComboBox,
  LAInput,
  LAWarningLabel,
  LADatePicker,
  useComboBox,
  useInput,
  useDatePicker,
  LASimpleTable,
} from 'modules/Widgets';
import { LALabel } from 'modules/Widgets';
import { end } from 'bfj/src/events';

const hourItems = [
  { key: '07', value: '오전 7시' },
  { key: '08', value: '오전 8시' },
  { key: '09', value: '오전 9시' },
  { key: '10', value: '오전 10시' },
  { key: '11', value: '오전 11시' },
  { key: '12', value: '오후 12시' },
  { key: '13', value: '오후 1시' },
  { key: '14', value: '오후 2시' },
  { key: '15', value: '오후 3시' },
  { key: '16', value: '오후 4시' },
  { key: '17', value: '오후 5시' },
  { key: '18', value: '오후 6시' },
  { key: '19', value: '오후 7시' },
  { key: '20', value: '오후 8시' },
  { key: '21', value: '오후 9시' },
];

const minItems = [
  { key: '00', value: '정각' },
  { key: '05', value: '05분' },
  { key: '10', value: '10분' },
  { key: '15', value: '15분' },
  { key: '20', value: '20분' },
  { key: '25', value: '25분' },
  { key: '30', value: '30분' },
  { key: '35', value: '35분' },
  { key: '40', value: '40분' },
  { key: '45', value: '45분' },
  { key: '50', value: '50분' },
  { key: '55', value: '55분' },
];

function OneDayScheduleView({ schedules, onChange, onDelete }) {
  const { showAlert, showConfirm } = usePopAlert();
  const tempScheduleDate = useDatePicker(
    moment().add(7, 'day').format('YYYY-MM-DD'),
  );
  const tempScheduleStartHour = useComboBox('08');
  const tempScheduleStartMin = useComboBox('00');
  // const tempScheduleEndHour = useComboBox('09');
  // const tempScheduleEndMin = useComboBox('00');

  const getScheduleRows = useCallback(() => {
    const result = [];
    for (let i = 0; i < schedules.length; i++) {
      result.push([
        {
          text: `${i + 1}`,
        },
        {
          text: schedules[i].date,
        },
        {
          // text: `${schedules[i].startTime} ~ ${schedules[i].endTime}`,
          text: `${schedules[i].startTime}`,
        },
        // {
        //   text: `${schedules[i].duration}분`,
        // },
        {
          text: '삭제',
          type: 'button',
          callback: {
            onClick: () => onDelete(schedules[i]),
          },
        },
      ]);
    }
    return result;
  }, [schedules]);

  const classTableData = {
    headers: [
      {
        text: 'No',
      },
      {
        text: '클래스 일정',
      },
      {
        text: '클래스 시간',
      },
      // {
      //   text: '총 클래스 시간',
      // },
      {
        text: '삭제',
      },
    ],
    // wPercentList: [10, 20, 35, 20, 15],
    wPercentList: [10, 37, 38, 15],
    rows: getScheduleRows(),
  };

  const getDuration = useCallback((startTime, endTime) => {
    const date = getTodayDateText('-');
    const t1 = moment(date + ' ' + startTime);
    const t2 = moment(date + ' ' + endTime);
    return Math.round(moment.duration(t2.diff(t1)).asMinutes());
  }, []);

  // const durationText = useMemo(() => {
  //   const startTime =
  //     tempScheduleStartHour.state + ':' + tempScheduleStartMin.state;
  //   const endTime = tempScheduleEndHour.state + ':' + tempScheduleEndMin.state;
  //   const duration = getDuration(startTime, endTime);
  //   const hour = parseInt(duration / 60);
  //   const minute = duration % 60;
  //   return `총 ${hour}시간 ${minute}분`;
  // }, [
  //   tempScheduleStartHour.state,
  //   tempScheduleStartMin.state,
  //   tempScheduleEndHour.state,
  //   tempScheduleEndMin.state,
  // ]);

  const onAddSchedule = useCallback(() => {
    const date = tempScheduleDate.state;
    const startTime =
      tempScheduleStartHour.state + ':' + tempScheduleStartMin.state;
    // const endTime = tempScheduleEndHour.state + ':' + tempScheduleEndMin.state;

    // if (getDuration(startTime, endTime) === 0) {
    //   showAlert('알림', '클래스 시간을 설정해주세요.');
    //   return;
    // }

    let isOverlap = false;
    schedules.forEach((data) => {
      isOverlap =
        data.date === date && data.startTime === startTime
          ? // && data.endTime === endTime
            true
          : isOverlap;
    }, []);

    if (isOverlap) {
      showAlert('알림', '이미 추가된 일정입니다.');
      return;
    }

    const data = {
      date,
      startTime,
      // endTime,
      // duration: getDuration(startTime, endTime),
    };

    onChange(data);
  }, [
    schedules,
    tempScheduleDate.state,
    tempScheduleStartHour.state,
    tempScheduleStartMin.state,
    // tempScheduleEndHour.state,
    // tempScheduleEndMin.state,
  ]);

  return (
    <>
      <LACard next>
        <LACardTitle title="클래스 일정 정보" />
        <LAProperty name="클래스 시작일" required>
          <LAGridContainer>
            <LADatePicker
              wPercent={30}
              value={tempScheduleDate.state}
              onChange={tempScheduleDate.handleChange}
            />
            <LAWarningLabel layouts={{ pl: 2 }} wPercent={50}>
              최대 3개월까지 선택 가능합니다.
            </LAWarningLabel>
          </LAGridContainer>
        </LAProperty>
        <LAProperty name="클래스 시간" required>
          <LAGridContainer>
            <LAComboBox
              wPercent={15}
              layouts={{ pr: { sm: 0.5 } }}
              items={hourItems}
              value={tempScheduleStartHour.state}
              onChange={tempScheduleStartHour.handleChange}
            />
            <LAComboBox
              wPercent={15}
              // layouts={{ pr: { sm: 0.5 } }}
              items={minItems}
              value={tempScheduleStartMin.state}
              onChange={tempScheduleStartMin.handleChange}
            />
            {/* <LALabel wPercent={5}>~</LALabel>
            <LAComboBox
              wPercent={15}
              layouts={{ pr: { sm: 0.5 } }}
              items={hourItems}
              value={tempScheduleEndHour.state}
              onChange={tempScheduleEndHour.handleChange}
            />
            <LAComboBox
              wPercent={15}
              layouts={{ pr: { sm: 0.5 } }}
              items={minItems}
              value={tempScheduleEndMin.state}
              onChange={tempScheduleEndMin.handleChange}
            /> */}
          </LAGridContainer>
          {/* <LAGridContainer>
            <LALabel>{durationText}</LALabel>
          </LAGridContainer> */}
        </LAProperty>

        <LAProperty name="일정" required>
          <LAGridContainer>
            <LAButton wPercent={15} onClick={onAddSchedule}>
              {/* <LAButton wPercent={10} onClick={() => setClassScheduleShow(true)}> */}
              일정 추가
            </LAButton>
          </LAGridContainer>
          {schedules && schedules.length > 0 && (
            <LAGridContainer pt={1}>
              <LASimpleTable tableData={classTableData} />
            </LAGridContainer>
          )}
          <LAWarningLabel>
            패키지(표준순환, 개별 맞춤) : 클래스 종료일은 클래스 기본 정보에서
            선택한 클래스 회차에 따라 자동 계산되어 표시됩니다.
          </LAWarningLabel>
        </LAProperty>
      </LACard>
    </>
  );
}

export default React.memo(OneDayScheduleView);
