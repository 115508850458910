import { useState, useEffect } from "react";

export default function useTimer(time) {
  const [timer, setTimer] = useState(time);
  const [timerState, setTimerState] = useState("not start");

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer_) => {
        const prevTimer = getTotalSeconds(prevTimer_);
        if (prevTimer <= 1) {
          clearInterval(interval);
          setTimerState("end");
          return 0;
        } else {
          setTimerState("start");
          return formatTime(prevTimer - 1);
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}분 ${String(seconds).padStart(
      2,
      "0"
    )}초`;
  };

  const getTotalSeconds = (timeString) => {
    const [minutes, seconds] = timeString
      .split("분")
      .map((str) => str.trim().replace("초", ""));
    const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
    return totalSeconds;
  };

  return { timer, timerState };
}
