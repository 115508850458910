import React, { useReducer, useState, useMemo, useEffect, useContext, useCallback,  } from 'react';
import { UNSAFE_NavigationContext } from "react-router-dom";
import produce from 'immer';
import MDButton from 'components/MDButton';
import IconAddCircleOutline from '@mui/icons-material/Add';
import IconCheck from '@mui/icons-material/Check';
import moment from 'moment';

import mcss from 'assets/css/modal.module.scss';
import DeliveryTrackingSimpleTable from 'pages/Common/components/DeliveryTrackingSimpleTable';

import { useApiContext } from 'common/context/ApiContext';
import usePopAlert from 'common/components/popAlert/hooks/usePopAlert';
import {
    LALabel,
    LAButton,
    LACard,
    LAInput,
    LAGridContainer,
    LAModalPopup,
    LAModalCardTitle,
    LACodeComboBox,
    LAGridItem,
    LATextInputButtonGroup,
} from 'modules/Widgets';

import useCommonQuery from "common/hooks/useCommonQuery";
import useCommonMutation from 'common/hooks/useCommonMutation';
import { useLoginContext } from "../../../common/context/MemberContext";

class Waybill {
    constructor ( waybillSeq, waybillNo, sendType, deliveryCompanyType, deliveryOptionType, deliveryMsg, returnMsg ) {
        this.counter = getLastCounter();
        this.dataState = waybillSeq? 'N': 'I'; // N: 기존, I: 신규, U:업데이트, D: 삭제
        this.waybillSeq = waybillSeq;
        this.sendType = sendType;
        this.deliveryCompanyType = deliveryCompanyType ? deliveryCompanyType : '';
        this.deliveryOptionType = deliveryOptionType ? deliveryOptionType : '';
        this.waybillNo = waybillNo;
        this.deliveryMsg = deliveryMsg;
        this.returnMsg = returnMsg;
        this.returnWaybill = null;
    }

    setDataState(state) {
        this.dataState = state;
    }

    addReturnWaybill(waybill) {
        this.returnWaybill = waybill;
    }

    removeReturnWaybill() {
        this.returnWaybill = null;
    }
}

let _waybillLastCounter = 0;

function getLastCounter () {
    return _waybillLastCounter++;
}

function reducer(state, action) {
    const { index, name, value } = action;

    switch (action.type) {
        case "ADD_WAYBILL": {
            return produce(state, draft => {
                if (!value) {
                    draft.unshift(new Waybill());
                    return;
                }

                draft.push(value);
            });
        }
        case "REMOVE_WAYBILL": {
            return produce(state, draft => {
                draft.splice(action.value, 1);
            });
        }
        case "ADD_RETURN_WAYBILL": {
            let waybill = action.value ? action.value : new Waybill();
            waybill.preWaybillSeq = state[action.index].waybillSeq;

            return produce(state, draft => {
                draft[action.index] = {...draft[action.index], returnWaybill : waybill};
            });
        }
        case "REMOVE_RETURN_WAYBILL": {
            return produce(state, draft => {
                let {returnWaybill} = draft[action.index];
                if (!returnWaybill) {
                    return;
                }

                draft[action.index] = {...draft[action.index], returnWaybill : null}; // 추후에 draft[action.index].removeWaybill() 유효한지 확인
            });
        }
        case "UPDATE_WAYBILL": {
            return produce(state, draft => {
                draft[index] = {...draft[index], dataState: 'U', [name]: value}
            });
        }
        case "UPDATE_RETURN_WAYBILL": {
            return produce(state, draft => {
                draft[index].returnWaybill[name] = value;
                draft[index].returnWaybill.dataState = 'U';
                draft[index] = {...draft[index]};
            });
        }
        case "UPDATE_WAYBILL_TRACKING_HISTORY": {
            return produce(state, draft => {
                draft[index] = {...draft[index], trackingHistory: value};
            });
        }
        case "UPDATE_RETURN_WAYBILL_TRACKING_HISTORY": {
            return produce(state, draft => {
                let returnWaybill = draft[index].returnWaybill;
                returnWaybill.trackingHistory = value;
                draft[index] = {...draft[index], returnWaybill: returnWaybill};
            });
        }
        case "CLEAR": {
            return produce(state, draft => {
                draft.splice(0, draft.length);
            });
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function OrderDetailDeliveryRegistModal( { context, modalPopupState, dataOrderSeq }){
    const { queries } = useApiContext();
    const { action } = useLoginContext();
    const { showAlert, showConfirm } = usePopAlert();
    const [ orderSeq, setOrderSeq ] = useState(-1);
    const [ waybillListState, dispatch ] = useReducer(reducer, []);
    const [ deleteSeqList, setDeleteSeqList ] = useState([]);
    const [ alarmtalksList, setAlarmtalksList ] = useState([]); // 알람톡 이력 리스트

    const authMemberType = useMemo(() => {
        return action.getMemberType();
    }, [action]);

    const authAdminAuthType = useMemo(() => {
        return action.getAuthType();
    }, [action]);

    const navigation = useContext(UNSAFE_NavigationContext).navigator;
    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        if (navigation) {
            navigation.listen((locationListener) => {
                if(locationListener.action == 'POP') {
                    modalPopupState.hide();
                }
            });
        }
    }, []);

    const { request: requestWaybillList } = useCommonQuery({
        query: queries.Delivery.getWaybillList,
        params: {
            order_seq: dataOrderSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code !== '0000') {
                    showAlert('알림', '목록 조회에 실패했습니다.');
                    modalPopupState.hide();
                    return;
                }

                if (data.result_data.length == 0) {
                    dispatch({
                        type: 'ADD_WAYBILL',
                    });
                    return;
                }

                data.result_data?.map((item, index) => {
                    console.log('item: ', item);
                    let context = new Waybill(item.waybill_seq, item.waybill_no, item.send_type, item.delivery_company_type, item.delivery_option_type, item.delivery_msg);

                    if (item.re_waybill_no && item.re_waybill_no != '') {
                        context.returnWaybill = new Waybill(item.re_waybill_seq, item.re_waybill_no, item.re_send_type, item.re_delivery_company_type, item.re_delivery_option_type, item.re_delivery_msg, item.re_return_msg);
                        context.returnWaybill.preWaybillSeq = context.waybillSeq;
                    }

                    dispatch({
                        type: 'ADD_WAYBILL',
                        value: context
                    });
                });
            },
            onError: (error) => {
                showAlert('알림', '목록 조회에 실패했습니다.');
            },
        },
        initEnabled: false
    });

    // API 조회 : 운송장 업데이트
    const { request: updateWaybillList } = useCommonMutation({
        query: queries.Delivery.updateWaybillList,
        // params: waybillReqParams,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code == "1002") {
                    let dupWaybillList = data.waybills.map((item) => {
                        return `${item.delivery_company_type_name}(${item.waybill_no})`;
                    })

                    showConfirm('알림', dupWaybillList.join(', ') + "가 중복된 송장입니다.\n중복 등록하시겠습니까?", {
                        confirmHandler: () => {
                            submit(true);
                        },
                    });
                    return;
                } else if (data.result_code !== "0000") {
                    showAlert('알림', '운송장을 업데이트를 하지 못했습니다.');
                    return;
                } else if (data.result_code === '0000') {
                    showAlert('알림', '저장되었습니다.')
                }

                modalPopupState.processCallback();
                modalPopupState.hide();
            },
            onError: (error) => {
                showAlert('알림', '운송장을 조회를 하지 못했습니다.');
            },
        },
        initEnabled:false
    });

    /* 교환 요청 알림 톡 발송 */
    const { request: requestAlarmtalkExchange} = useCommonMutation({
       query: queries.Delivery.alarmtalkExchange,
       callbacks: {
           onSuccess: (data) => {
               if (data.result_code === '0000') {
                   showAlert('안내', '교환 알림톡 발송 완료');
                   setAlarmtalksList([...alarmtalksList, data]);
               } else {
                   showAlert('안내', '교환 알림톡 발송 실패');
               }
           },
           onError: (error) => {
               showAlert('알림', '교환 요청 알림톡 발송을 하지 못했습니다.');
           }
       },
        initEnabled: false,
    });

    /* 반품 요청 알림톡 발송*/
    const { request: requestAlarmtalkReturn} = useCommonMutation({
        query: queries.Delivery.alarmtalkReturn,
        callbacks: {
            onSuccess: (data) => {
                if(data.result_code === '0000') {
                    showAlert('안내','반품 알림톡 발송 완료' );
                    setAlarmtalksList([...alarmtalksList, data]);
                } else {
                    showAlert('안내','반품 알림톡 발송 실패' );
                }
            },
            onError: (error) => {
                showAlert('알림', '반품 요청 알림톡 발송을 하지 못했습니다.');
                return;
            }
        },
        initEnabled: false
    });

    /* 알림톡 이력 조회 */
    const {request: requestAlarmtalks } = useCommonQuery({
        query: queries.Delivery.getDeliveryAlarmtalksList,
        params: {
            order_seq: dataOrderSeq,
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code = '0000') {
                    setAlarmtalksList(data.result_data);
                } else if (data.result_code !== "0000") {
                    showAlert('알림', '알림톡 이력을 조회를 하지 못했습니다.');
                    return;
                }
            },
            onError: (error) => {
                showAlert('알림', '알림톡 이력을 조회를 하지 못했습니다.');
            },
        },
        initEnabled: false
    });

    /* 모달창 렌더링시 가장먼저 실행되는 부분 */
    useEffect(() => {
        if (!context) {
            return;
        }

        //setOrderSeq(context.order_seq);
        if(context?.class.textbook_delivery_type === 2){
            requestWaybillList();
        }

        return () => {
            context = null;
            //setOrderSeq('');
            setDeleteSeqList([]);
            dispatch({
                type: 'CLEAR',
            });
        };
    }, [context, dataOrderSeq]);

    /* 변경이력 DATA변경시 이력API 재요청하여 화면 최신화 */
    useEffect(() => {
        if(modalPopupState.isVisible===true) {
            requestAlarmtalks()
        }
    },[modalPopupState.isVisible, alarmtalksList])

    const titleHtml = ()=>  {
        const optionButtons = [
            {
                text: '운송장 추가',
                endIcon: <IconAddCircleOutline/>,
                onClick: addWaybill
            },
            {
                text: '저장',
                variant: 'contained',
                endIcon: <IconCheck/>,
                onClick: () => submit(false)
            }
        ]

        return (
          <LAModalCardTitle
            title = "배송 정보 등록"
            layouts = {{position:'static', display:''}}
            optionButtons = {optionButtons}
            modalPopupState = {modalPopupState}
          />
        );
    };




    const addWaybill = () => {
        if (waybillListState.length == 10) {
            showAlert('알림', "운송장 추가는 최대 10개까지만 추가할 수 있습니다.");
            return;
        }

        dispatch({
            type: 'ADD_WAYBILL'
        });
    };

    const removeWaybill = (index, confirmSkipStatus) => {
        let context = waybillListState[index];

        if (context.dataState != 'I') {
            if (!confirmSkipStatus) {
                showConfirm('알림', '등록된 송장을 삭제하시겠습니까?\n저장 시 사용자 화면에서 송장 정보가 삭제됩니다.', {
                    confirmHandler: () => {
                        removeWaybill(index, true);
                    },
                });

                return;
            }

            setDeleteSeqList([...deleteSeqList, context.waybillSeq]);
        }

        dispatch({
            type: 'REMOVE_WAYBILL',
            index: index
        });
    };

    const addReturnWaybill = (index) => {
        dispatch({
            type: 'ADD_RETURN_WAYBILL',
            index: index,
        });
    };

    const removeReturnWaybill = (index, confirmSkipStatus) => {
        let context = waybillListState[index].returnWaybill;

        if (context.dataState != 'I') {
            if (!confirmSkipStatus) {
                showConfirm('알림', '등록된 송장을 삭제하시겠습니까?\n저장 시 사용자 화면에서 송장 정보가 삭제됩니다.', {
                    confirmHandler: () => {
                        removeReturnWaybill(index, true);
                    },
                });

                return;
            }
            setDeleteSeqList([...deleteSeqList, context.waybillSeq]);
        }

        dispatch({
            type: 'REMOVE_RETURN_WAYBILL',
            index: index
        });
    }

    // 하단에 데이터 고정함
    const waybill = {
        getTrackingInfo: (index, companyCode, waybillNo) => {
            if (!companyCode || companyCode == '') {
                showAlert('알림', '택배사를 선택해 주세요.');
                return;
            }

            if (!waybillNo || waybillNo.trim() == '') {
                showAlert('알림', '운송장을 입력해 주세요.');
                return;
            }

            let reqParams = {
                companyCode: companyCode.toString().padStart(2, '0'),
                waybillNo: waybillNo,
            }

            // 상태가 중간에 바뀔 수도 있는 위험이 있어서 Hook을 통한 요청을 제외 시킨다.
            queries.Delivery.getDeliveryTrackingInfoByWaybillNo.queryFn(reqParams)
              .then(function (res) {
                  if (res.result_code !== "0000") {
                      showAlert('알림', '운송장을 조회하지 못했습니다.');
                      return;
                  }

                  let dataList = [];
                  res.result_data?.trackingDetails?.map((item, index) => {
                      dataList.unshift([
                          {
                              text: item.timeString,
                          },
                          {
                              text: item.where,
                          },
                          {
                              text: item.kind,
                          },
                      ])
                  });

                  dispatch({
                      type: 'UPDATE_WAYBILL_TRACKING_HISTORY',
                      index: index,
                      value: dataList
                  });
              })
              .catch(function (error) {
                  showAlert('알림', '운송장을 조회하지 못했습니다.');
              });
        },
        onValueChanged: (index, name, value) => {
            dispatch ({
                type: 'UPDATE_WAYBILL',
                index: index,
                name,
                value
            })
        },
    }

    const returnWaybill = {
        getTrackingInfo: (index, companyCode, waybillNo) => {
            if (!companyCode || companyCode == '') {
                showAlert('알림', '택배사를 선택해 주세요.');
                return;
            }

            if (!waybillNo || waybillNo.trim() == '') {
                showAlert('알림', '운송장을 입력해 주세요.');
                return;
            }

            let reqParams = {
                companyCode: companyCode.toString().padStart(2, '0'),
                waybillNo: waybillNo,
            }

            // 상태가 중간에 바뀔 수도 있는 위험이 있어서 Hook을 통한 요청을 제외 시킨다.
            queries.Delivery.getDeliveryTrackingInfoByWaybillNo.queryFn(reqParams)
              .then(function (res) {
                  if (res.result_code !== "0000") {
                      showAlert('알림', '배송 정보를 조회하지 못했습니다.');
                      return;
                  }

                  let dataList = [];
                  res.result_data?.trackingDetails?.map((item, index) => {
                      dataList.unshift([
                          {
                              text: item.timeString,
                          },
                          {
                              text: item.where,
                          },
                          {
                              text: item.kind,
                          },
                      ])
                  });

                  console.log("반송장::::::", dataList);

                  dispatch({
                      type: 'UPDATE_RETURN_WAYBILL_TRACKING_HISTORY',
                      index: index,
                      value: dataList
                  });
              })
              .catch(function (error) {
                  showAlert('알림', '배송 정보를 조회하지 못했습니다.');
              });
        },
        onValueChanged: (index, name, value) => {
            dispatch ({
                type: 'UPDATE_RETURN_WAYBILL',
                index: index,
                name,
                value
            })
        },
    }

    const submit = useCallback((byPass) => {
        function generateWaybill(item) {
            console.log("generateWaybill ::", item);
            let temp = {
                waybill_seq: item.waybillSeq,
                delivery_option_type: item.deliveryOptionType,
                delivery_company_type: item.deliveryCompanyType,
                waybill_no: item.waybillNo,
                delivery_status: item.deliveryStatus,
                delivery_msg: item.deliveryOptionType == 99 ? item.deliveryMsg : '',
                return_msg: (item.deliveryOptionType == 1 || item.deliveryOptionType == 2 ) ? item.returnMsg: '',
                pre_waybill_seq: item.preWaybillSeq? item.preWaybillSeq: undefined
            }

            return temp;
        }

        function generateData(waybill, returnWaybill) {
            return {
                S: waybill,
                R: returnWaybill
            }
        }

        let waybillParams = [];

        for (let i = 0; i < waybillListState.length; i++ ) {
            let waybill = undefined;
            let returnWaybill = undefined;

            let ctxWaybill = waybillListState[i];

            console.log('ctxWaybill::', ctxWaybill);
            let ctxReturnWaybill = ctxWaybill.returnWaybill;

            if (ctxWaybill.dataState != 'N') {
                // Validate
                if (!ctxWaybill.deliveryCompanyType
                  || ctxWaybill.deliveryCompanyType == '') {
                    showAlert('알림', "택배사를 선택해 주세요.");
                    return;
                }

                if (!ctxWaybill.deliveryOptionType
                  || ctxWaybill.deliveryOptionType == '') {
                    showAlert('알림', "배송 유형을 선택해 주세요.");
                    return;
                }

                if (ctxWaybill.deliveryOptionType == 99
                  && (!ctxWaybill.deliveryMsg || ctxWaybill.deliveryMsg.trim() == '')) {
                    showAlert('알림', "배송 유형의 기타 사유를 입력해 주세요.");
                    return;
                }

                if (!ctxWaybill.waybillNo
                  || (!ctxWaybill.waybillNo || ctxWaybill.waybillNo.trim() == '')) {
                    showAlert('알림', "송장 번호를 입력해주세요");
                    return;
                }

                waybill = generateWaybill(ctxWaybill);
            }

            if (ctxReturnWaybill && ctxReturnWaybill.dataState != 'N') {
                if (!ctxReturnWaybill.deliveryCompanyType
                  || ctxReturnWaybill.deliveryCompanyType == '') {
                    showAlert('알림', "택배사를 선택해 주세요.");
                    return;
                }

                if (!ctxReturnWaybill.deliveryOptionType
                  || ctxReturnWaybill.deliveryOptionType == '') {
                    showAlert('알림', "배송 유형을 선택해 주세요.");
                    return;
                }

                if (ctxReturnWaybill.deliveryOptionType == 99
                  && (!ctxReturnWaybill.deliveryMsg || ctxReturnWaybill.deliveryMsg.trim() == '')) {
                    showAlert('알림', "배송 유형의 기타 사유를 입력해 주세요.");
                    return;
                }

                if (!ctxReturnWaybill.waybillNo
                  || (!ctxReturnWaybill.waybillNo || ctxReturnWaybill.waybillNo.trim() == '')) {
                    showAlert('알림', "송장 번호를 입력해주세요");
                    return;
                }

                returnWaybill = generateWaybill(ctxReturnWaybill);
            }

            if (waybill || returnWaybill) {
                waybillParams.push(generateData(waybill, returnWaybill));
            }
        }


        console.log("보내기전 데이터 byPass::", byPass);

        let params = {
            waybills: waybillParams,
            textbook_delivery_type: 2,
            delete_waybills: deleteSeqList,
            bp: byPass
        }

        updateWaybillList({
            order_seq: dataOrderSeq,
            data: params
        });
    })

    /* TODO 2023-04-18
   1. 알림톡 버튼 클릭 시, 전송데이터 값
   2. API 연동
   */

    const clickToChangeNoti = useCallback(() => {
        showConfirm('알림','확인 버튼 선택시, \n 강사에게 알림톡이 발송됩니다.', {
            confirmHandler: () => {
                // API requset
                requestAlarmtalkExchange({
                    order_seq: dataOrderSeq,
                });
            }
        });
    })

    const clickToRefundNoti = useCallback(() => {
        showConfirm('알림','확인 버튼 선택시, \n 강사에게 알림톡이 발송됩니다.', {
            confirmHandler: () => {
                // API requset
                requestAlarmtalkReturn({
                    order_seq: dataOrderSeq,
                });
            }
        });
    })

    return (
      <LAModalPopup
        width="850px"
        height="800px"
        className={mcss.deliveryRegistModal}
        titleHtml={titleHtml()}
        modalPopupState={modalPopupState}
      >
          <LACard next>
              <LAGridContainer layouts={{ pb: { sm : 1.5 }, borderBottom: "1px solid #dddddd"}}>
                  <LALabel wPercent={50} props={{ fontSize: 16, fontWeight: "bold" }}>교환/반품 알림톡 발송 이력(관리자 → 강사)</LALabel>
                  { (authMemberType == 1 && authAdminAuthType == 1) &&
                    <>
                        <LAButton wPercent={25} layouts={{pr: {sm: 1}}} onClick={clickToChangeNoti}>교환 요청 알림톡 발송</LAButton>
                        <LAButton wPercent={25} layouts={{pr: {sm: 1}}} onClick={clickToRefundNoti}>반품 요청 알림톡 발송</LAButton>
                    </>
                  }
              </LAGridContainer>

              <LAGridContainer>
                  <LALabel wPercent={30} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 4 }} props={{ fontSize: 12 }}>교환 알림톡 발송 이력</LALabel>
                  <LAGridItem wPercent={40}>
                      {alarmtalksList?.filter((item) => item.send_type === 'exchange').map((item) => (
                        <LALabel
                          wPercent={100}
                          layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }}
                          props={{ fontSize: 12 }}
                          key={item.alarmtalk_seq}
                        >
                            {`${moment(item.alarmtalk_create_dt).format('YYYY-MM-DD (dd) A hh:mm:ss')}`}
                        </LALabel>
                      ))}
                      {alarmtalksList?.filter((item) => item.send_type === 'exchange').length === 0 && (
                        <LALabel
                          wPercent={100}
                          layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }}
                          props={{ fontSize: 12 }}
                        >
                            없음
                        </LALabel>
                      )}
                  </LAGridItem>
                  <LAGridItem wPercent={30}>
                      {alarmtalksList?.map((item) => (
                        <LALabel
                          wPercent={100}
                          layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }}
                          props={{ fontSize: 12 }}
                          key={item.alarmtalk_seq}
                        >
                            {item.send_type === 'exchange' ? `처리자: ${item.constructor_name}` : ''}
                        </LALabel>
                      ))}
                  </LAGridItem>
              </LAGridContainer>

              <LAGridContainer vAlign="center" layouts={{ sx: {marginTop: '9px', borderTop: '1px solid #dddddd', borderBottom: '1px solid #dddddd', pt: 0.5, pb: 0.5 }}}>
                  <LALabel wPercent={30} layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 4 }} props={{ fontSize:12 }}>반품 알림톡 발송 이력</LALabel>
                  <LAGridItem wPercent={40}>
                      {alarmtalksList?.filter((item) => item.send_type === 'return').map((item) => (
                        <LALabel
                          wPercent={100}
                          layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }}
                          props={{ fontSize: 12 }}
                          key={item.alarmtalk_seq}
                        >
                            {`${moment(item.alarmtalk_create_dt).format('YYYY-MM-DD (dd) A hh:mm:ss')}`}
                        </LALabel>
                      ))}
                      {alarmtalksList?.filter((item) => item.send_type === 'return').length === 0 && (
                        <LALabel
                          wPercent={100}
                          layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }}
                          props={{ fontSize: 12 }}
                        >
                            없음
                        </LALabel>
                      )}
                  </LAGridItem>
                  <LAGridItem wPercent={30}>
                      {alarmtalksList?.map((item) => (
                        <LALabel
                          wPercent={100}
                          layouts={{ sx: { textAlign: 'left', fontWeight: 'regular' }, pl: 2 }}
                          props={{ fontSize: 12 }}
                          key={item.alarmtalk_seq}
                        >
                            {item.send_type === 'return' ? `처리자: ${item.constructor_name}` : ''}
                        </LALabel>
                      ))}
                  </LAGridItem>
              </LAGridContainer>
          </LACard>

          { waybillListState && waybillListState.map ((item, $index)=>
            <LACard next className={ mcss.card } key={ item.counter }>

                <div className={mcss.waybill}>

                    <div className={mcss.titleArea}>
                        <h6>운송장({ waybillListState.length - $index}) 등록</h6>
                        <div className={mcss.optionArea}>
                            { !item.returnWaybill &&
                              <MDButton
                                className={mcss.btnAddReturnWaybill}
                                variant="text"
                                color="info"
                                size="small"
                                onClick={() => addReturnWaybill($index) }
                              >
                                  반송장 추가
                              </MDButton>
                            }

                            <MDButton
                              className={mcss.btnDelete}
                              variant="text"
                              color="info"
                              size="small"
                              onClick={() => removeWaybill($index)}>
                                삭제
                            </MDButton>
                        </div>
                    </div>
                    <div>
                        <LAGridContainer mt={1} mb={1}>
                            <LACodeComboBox
                              wPercent={25}
                              layouts={{ pr: { sm: 0.5 } }}
                              dbCodeStatus={true}
                              codeType="DELIVERY_COMPANY_TYPE"
                              value={item.deliveryCompanyType}
                              placeholder="택배사 선택"
                              defaultOption={{key: '', value: '택배사 선택'}}
                              onChange={ (e) => waybill.onValueChanged($index, 'deliveryCompanyType', e.key) }
                              style={{ maxHeight: '150px', overflowY: 'auto' }} // 콤보박스의 높이 제한 및 스크롤 추가
                            />
                            <LACodeComboBox
                              wPercent={30}
                              layouts={{ pr: { sm: 0.5 } }}
                              dbCodeStatus={true}
                              codeType="DELIVERY_OPTION_TYPE"
                              value={item.deliveryOptionType}
                              placeholder="배송 유형"
                              onChange={ (e) => waybill.onValueChanged($index, 'deliveryOptionType', e.key) }
                            />
                            { item.deliveryOptionType == 99 &&
                              <LAInput
                                wPercent={30}
                                layouts={{ pr: { sm: 0.5 } }}
                                value={item.deliveryMsg}
                                placeholder="(필수)상세 사유를 입력해주세요"
                                onChange={ (e) => waybill.onValueChanged($index, 'deliveryMsg', e.target.value) }
                              />
                            }
                        </LAGridContainer>
                        <LATextInputButtonGroup
                          value={item.waybillNo}
                          type='number'
                          placeholder={"송장 번호 - 없이 입력"}
                          onChange={e => waybill.onValueChanged($index, 'waybillNo', e.target.value)}
                          onCleared= { () => waybill.onValueChanged($index, 'waybillNo', '') }
                          buttonGroup={[
                              {
                                  type: 'icon',
                                  icon: 'search',
                                  text: '찾기',
                                  onClick: (e, data) => {
                                      waybill.getTrackingInfo($index, item.deliveryCompanyType, item.waybillNo);
                                  }
                              }
                          ]} />
                        {/*운송장등록시 사유를 적을 필요가 없을것으로 판단 우선 주석처리 */}
                        {/*{(item.returnWaybill?.deliveryOptionType !== '') ?*/}
                        {/*    (*/}
                        {/*      <LAInput*/}
                        {/*        layouts={{pt: { sm: 0.5 }}}*/}
                        {/*        value={item.returnWaybill?.deliveryMsg}*/}
                        {/*        placeholder="(필수)상세 사유를 입력해주세요"*/}
                        {/*        onChange={ (e) => returnWaybill.onValueChanged($index, 'deliveryMsg', e.target.value) }*/}
                        {/*      />*/}
                        {/*    ) :*/}
                        {/*    ('')*/}
                        {/*}*/}
                    </div>
                    { item.trackingHistory &&
                      <DeliveryTrackingSimpleTable className={mcss.deliveryTrackingTable} data={item.trackingHistory} />
                    }

                </div>
                {
                  item.returnWaybill &&
                  <div className={mcss.returnWaybill}>
                      <div className={mcss.titleArea}>
                          <h6>반송장({ $index + 1})</h6>
                          <div className={mcss.optionArea}>
                              <MDButton
                                className={mcss.btnDelete}
                                variant="text"
                                color="info"
                                size="small"
                                onClick={() => removeReturnWaybill($index)}>
                                  삭제
                              </MDButton>
                          </div>
                      </div>
                      <div>
                          <LAGridContainer mt={1} mb={1}>
                              <LACodeComboBox
                                wPercent={25}
                                layouts={{ pr: { sm: 0.5 } }}
                                dbCodeStatus={true}
                                codeType="DELIVERY_COMPANY_TYPE"
                                value={item.returnWaybill.deliveryCompanyType}
                                placeholder="택배사 선택"
                                defaultOption={{key: '', value: '택배사 선택'}}
                                onChange={ (e) => returnWaybill.onValueChanged($index, 'deliveryCompanyType', e.key) }
                              />
                              <LACodeComboBox
                                wPercent={30}
                                layouts={{ pr: { sm: 0.5 } }}
                                dbCodeStatus={true}
                                codeType="RE_DELIVERY_OPTION_TYPE"
                                value={item.returnWaybill.deliveryOptionType}
                                placeholder="배송 유형"
                                onChange={ (e) => returnWaybill.onValueChanged($index, 'deliveryOptionType', e.key) }
                              />

                          </LAGridContainer>
                          <LATextInputButtonGroup
                            value={item.returnWaybill.waybillNo}
                            placeholder={"송장 번호 - 없이 입력"}
                            onChange={e => returnWaybill.onValueChanged($index, 'waybillNo', e.target.value)}
                            onCleared= { () => returnWaybill.onValueChanged($index, 'waybillNo', '') }
                            buttonGroup={[
                                {
                                    type: 'icon',
                                    icon: 'search',
                                    text: '찾기',
                                    onClick: (e, data) => {
                                        returnWaybill.getTrackingInfo($index, item.returnWaybill.deliveryCompanyType, item.returnWaybill.waybillNo);
                                    }
                                }
                            ]} />
                          { item.returnWaybill?.deliveryOptionType == 99 &&
                            <LAInput
                              layouts={{ pt: { sm: 0.5 } }}
                              value={item.returnWaybill.deliveryMsg}
                              placeholder="(필수)상세 사유를 입력해주세요"
                              onChange={ (e) => returnWaybill.onValueChanged($index, 'deliveryMsg', e.target.value) }
                            />
                          }
                          {(item.returnWaybill?.deliveryOptionType === 1 || item.returnWaybill?.deliveryOptionType === 2) &&
                            <LAInput
                              layouts={{pt: { sm: 0.5 }}}
                              value={item.returnWaybill?.returnMsg}
                              placeholder="(검수 피드백) 상품 회수 후 반품일 경우, 검수 피드백을 입력해 주세요. 작성된 내용은 관리 메모로만 사용됩니다."
                              onChange={ (e) => returnWaybill.onValueChanged($index, 'returnMsg', e.target.value) }
                            />
                          }
                      </div>
                      { item.returnWaybill.trackingHistory &&
                        <DeliveryTrackingSimpleTable className={mcss.deliveryTrackingTable} data={item.returnWaybill.trackingHistory}/>
                      }

                  </div>
                }

            </LACard>
          )


          }
      </LAModalPopup>
    )
}
export default React.memo(OrderDetailDeliveryRegistModal);